import React from "react";
import { ISleepData } from "../../dashboard/eventDataTypes";
import { AnalyticsChartTypes, SleepStatus } from "../../../helpers/constants";
import { DateTimeFromMillis, DateTimeNow } from "../../../helpers/datetime";
import useCommunityUnitTimezone from "../../dashboard/hooks/useCommunityUnitTimezone";
import { useAppSelector } from "../../app/appHooks";
import { UnitChartHeader } from "./UnitChartHeader";
import FloatBarChartY from "../../common/FloatBarChartY";
import AnalyticsChartContainer from "./AnalyticsChartContainer";
import "../../../index.css";
import { CHART_DATA_LIMIT_MINIMIZED } from "../AnalyticsViewer";
import { CHART_DATA_LIMIT_MAXIMIZED } from "../AnalyticsViewer";
import { DateTime } from "luxon";
import { ChartMode } from "../AnalyticsViewerCharts";

// Obtains the min and max for the unit activity chart
const getChartMinMaxByEvent = (
  sleepData: ISleepData[],
  communityUnitTimezone?: string
) => {
  let maxDateTime = DateTimeNow(communityUnitTimezone).set({
    hour: 6,
    minute: 0,
    second: 0,
  });
  // Init min date at the current day at 23:00 h
  let minDateTime = maxDateTime.minus({ hours: 7 });

  const hasData = sleepData.some(
    (sleepItem) => sleepItem.gtb_time || sleepItem.uaa_time
  );
  // If there is not usable data, return default
  if (!hasData) return { minDateTime, maxDateTime };

  const earliestHour = sleepData.reduce(
    (earliestHour: DateTime, sleepItem: ISleepData) => {
      if (sleepItem.gtb_time === null) return earliestHour;
      let uuaDatetime = DateTimeFromMillis(
        sleepItem.uaa_time || 0,
        communityUnitTimezone
      );
      let gtbDatetime = DateTimeFromMillis(
        sleepItem.gtb_time || 0,
        communityUnitTimezone
      );
      // If the gtb time is in the same as de uua time, return
      if (gtbDatetime.day < uuaDatetime.day) {
        gtbDatetime = gtbDatetime.set({
          year: earliestHour.year,
          month: earliestHour.month,
          day: earliestHour.day,
        });
      } else {
        return earliestHour;
      }

      if (gtbDatetime < earliestHour) {
        earliestHour = gtbDatetime;
      }
      return earliestHour;
    },
    minDateTime
  );

  const latestHour = sleepData.reduce(
    (latestHour: DateTime, sleepItem: ISleepData) => {
      if (sleepItem.uaa_time === null) return latestHour;
      let uuaDatetime = DateTimeFromMillis(
        sleepItem.uaa_time || 0,
        communityUnitTimezone
      );
      uuaDatetime = uuaDatetime.set({
        year: latestHour.year,
        month: latestHour.month,
        day: latestHour.day,
      });

      if (uuaDatetime > latestHour) {
        latestHour = uuaDatetime;
      }
      return latestHour;
    },
    maxDateTime
  );

  maxDateTime = maxDateTime.set({ hour: latestHour.hour + 2, minute: 0 });
  minDateTime = minDateTime.set({ hour: earliestHour.hour - 1, minute: 0 });

  return { minDateTime, maxDateTime };
};

export function UnitSleepChart(props: IProps) {
  const { chartData, mode, lastUpdatedTime, isPrintable, pagination } = props;

  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const unitID = useAppSelector((state) => state.analyticsState.selectedUnit);
  const currentTime = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.currentTime
  );
  const maxChartLimit = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.end
  );
  const minChartLimit = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.start
  );

  /* Hooks */
  const communityUnitTimezone = useCommunityUnitTimezone(communityID, unitID);

  const dereferencedData = JSON.parse(
    JSON.stringify(chartData)
  ) as ISleepData[];

  const filterWithPagination = (fullData: ISleepData[]) => {
    if (pagination) {
      const startTime = DateTime.fromISO(pagination.startTime);
      const endTime = DateTime.fromISO(pagination.endTime);
      return fullData.filter((sleepItem) => {
        const sleepDate = DateTime.fromISO(sleepItem.date);
        return sleepDate >= startTime && sleepDate < endTime;
      });
    }
    return fullData;
  };

  const filterData = filterWithPagination(dereferencedData);

  const orderedSleepData = filterData
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .slice(
      0,
      mode === ChartMode.maximized
        ? CHART_DATA_LIMIT_MAXIMIZED
        : CHART_DATA_LIMIT_MINIMIZED
    );

  const sleepLabels = orderedSleepData.reduce((result: string[], element) => {
    result.push(element.date || "");
    return result;
  }, []);
  const labels = [...sleepLabels];

  const sleepStatus = orderedSleepData.reduce((result: string[], element) => {
    result.push(element.status || "normal");
    return result;
  }, []);
  const status = [...sleepStatus];

  const { minDateTime, maxDateTime } = getChartMinMaxByEvent(
    orderedSleepData,
    communityUnitTimezone
  );

  const getMiddleTime = (minTime: DateTime, maxTime: DateTime) => {
    const dateDiff = maxDateTime.diff(minDateTime);

    return minDateTime.plus(dateDiff.toMillis() / 2);
  };

  const middleTime = getMiddleTime(minDateTime, maxDateTime);

  const getDataSets = (
    sleepData: ISleepData[],
    communityUnitTimezone?: string
  ) => {
    let dataSets: string[][] = [[""]];

    // Obtain datasets for motion intervals
    const sleepDataset: string[][] = sleepData.map((sleepItem) => {
      const defaultData = ["", "", SleepStatus.noData];

      if (sleepItem.gtb_time === null || sleepItem.uaa_time === null) {
        let sleepItemStartDate = DateTimeNow(communityUnitTimezone);
        sleepItemStartDate = sleepItemStartDate.set({
          hour: middleTime.hour,
          minute: middleTime.minute,
          second: middleTime.second,
        });

        const dataPoint = [
          sleepItemStartDate.toISO(),
          sleepItemStartDate.toISO(),
          sleepItem.status,
        ];

        return dataPoint;
      }

      const startDate = DateTimeFromMillis(
        sleepItem.gtb_time || 0,
        communityUnitTimezone
      );

      const endDate = DateTimeFromMillis(
        sleepItem.uaa_time || 0,
        communityUnitTimezone
      );

      let sleepItemStartDate = DateTimeNow(communityUnitTimezone);
      let sleepItemEndDate = DateTimeNow(communityUnitTimezone);
      if (startDate.startOf("day") < endDate.startOf("day")) {
        sleepItemStartDate = sleepItemStartDate.minus({ day: 1 });
      }
      sleepItemStartDate = sleepItemStartDate.set({
        hour: startDate.hour,
        minute: startDate.minute,
        second: startDate.second,
      });
      sleepItemEndDate = sleepItemEndDate.set({
        hour: endDate.hour,
        minute: endDate.minute,
        second: endDate.second,
      });

      const dataPoint = [
        sleepItemStartDate.toISO(),
        sleepItemEndDate.toISO(),
        sleepItem.status,
      ];

      if (dataPoint.length > 0) return dataPoint;

      return defaultData;
    });
    if (sleepDataset.length > 0) dataSets = sleepDataset;

    return dataSets;
  };

  const datasets = getDataSets(orderedSleepData, communityUnitTimezone);

  const isCurrent =
    currentTime !== undefined && currentTime === pagination?.endTime;

  return (
    <React.Fragment>
      <UnitChartHeader
        type={AnalyticsChartTypes.sleep}
        mode={mode}
        data={orderedSleepData}
        isPrintable={isPrintable}
        lastUpdatedTime={lastUpdatedTime}
        isCurrent={mode === ChartMode.maximized ? isCurrent : undefined}
      />
      <AnalyticsChartContainer isPrintable={isPrintable}>
        <FloatBarChartY
          max={maxDateTime}
          min={minDateTime}
          labels={labels}
          status={status}
          values={datasets}
          communityUnitTimezone={communityUnitTimezone}
          animation={false}
          maxChartLimit={maxChartLimit}
          minChartLimit={minChartLimit}
          panEnabled={mode === ChartMode.maximized}
          chartMode={mode}
        />
      </AnalyticsChartContainer>
    </React.Fragment>
  );
}

interface IProps {
  chartData: ISleepData[];
  mode: ChartMode;
  lastUpdatedTime?: string;
  isPrintable: boolean;
  pagination?: { startTime: string; endTime: string };
}
