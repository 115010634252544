import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../helpers/store";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Theme,
  Typography,
  Link,
} from "@material-ui/core";
import { ContactIcons } from "../../../helpers/iconImports";
import { getResidentContacts } from "../../common/commonSlice";
import { IResidentContact } from "../../../services/common.services";
import {
  capitalize,
  ContactTypes,
  RoleTypes,
  SettingListKeys,
  Views,
} from "../../../helpers/constants";
import { IResident } from "../../../services/dashboard.services";
import { EmailField } from "../../common/EmailField";
import { PhoneNumberField } from "../../common/PhoneNumberField";
import {
  getEmergencyAppUsers,
  isEmergencyContact,
  sortContacts,
  sortEmergencyContacts,
} from "../../settings/settingsHelpers";
import { useHistory, Link as RouterLink } from "react-router-dom";
import {
  setSettingsOption,
  setSettingsUnit,
  setUnitConfigOpt,
} from "../../settings/settingsSlice";
import { unitWithResidentOptions } from "../../settings/ResidentUnitsList";
import { getEmergencyContacts } from "../../settings/settingsThunks";
import { IResidentPhoneNumber } from "../../../services/header.services";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    buttonRoot: {
      textTransform: "unset",
    },
    buttonText: {
      padding: "0px",
      paddingRight: "2px",
    },
    contactIcon: {
      display: "flex",
      margin: theme.spacing(0, 0.5),
      height: "18px",
    },
    popoverTitle: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(1, 0),
      fontFamily: theme.typography.tertiaryFontFamily,
    },
    popover: {
      border: `2px solid ${theme.palette.primary.main}`,
      overflowY: "hidden",
      backgroundColor: theme.palette.primary.main,
      padding: "0.1px",
    },
    popoverContent: {
      height: "100%",
      maxHeight: "550px",
      minWidth: "300px",
      overflowY: "auto",
      overflowX: "hidden",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      backgroundColor: theme.palette.light.light,
      color: "#2B2B2B",
      fontSize: "0.95rem",
    },
    contactRow: {
      verticalAlign: "top",
    },
    residentContacts: {
      borderSpacing: "0px",
    },
    residentName: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1rem",
      paddingBottom: theme.spacing(1),
    },
    sectionTitle: {
      paddingBottom: theme.spacing(0.5),
    },
    section: {
      paddingBottom: theme.spacing(1),
    },
    buttonLabel: {
      fontFamily: "GothamRoundedBook",
      fontSize: "inherit",
    },
    boldText: {
      fontWeight: "bold",
    },
    iconButton: {
      padding: "0",
      paddingLeft: "4px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      width: "20px",
      height: "20px",
    },
    link: {
      cursor: "pointer",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: 500,
    },
  })
);

export const labelByContactType = (contactType: string) => {
  switch (contactType) {
    case ContactTypes.FAMILY:
      return "contact_type_family";
    case ContactTypes.FRIEND:
      return "contact_type_friend";
    case ContactTypes.NEIGHBOR:
      return "contact_type_neighbor";
    case ContactTypes.DOCTOR:
      return "contact_type_doctor";
    case ContactTypes.NURSE:
      return "contact_type_nurse";
    case ContactTypes.USER:
      return "contact_type_family";
    case ContactTypes.CAREGIVER:
      return "caregiver";
    default:
      return "";
  }
};

const getFullName = (firstName?: string | null, lastName?: string | null) => {
  let _firstName = firstName ?? "";
  let _lastName = lastName ?? "";
  let fullName = "";
  if (firstName !== "") {
    fullName = capitalize(_firstName);
  }
  if (lastName !== "") {
    if (fullName !== "") {
      fullName = fullName.concat(" ", _lastName);
    } else {
      fullName = _lastName;
    }
  }
  return fullName;
};

const getAddress = (resident?: IResident) => {
  if (resident === undefined) return "";
  const addressFirst = [];
  const addressSecond = [];

  if (resident.street) addressFirst.push(resident.street);
  if (resident.street2) addressFirst.push(resident.street2);
  if (resident.city) addressFirst.push(resident.city);
  if (resident.state) addressSecond.push(resident.state);
  if (resident.postal_code) addressSecond.push(resident.postal_code);

  return `${
    addressFirst.length > 0
      ? `${addressFirst.join(", ")}${addressSecond.length > 0 ? ", " : ""}`
      : ""
  }${addressSecond.length > 0 ? `${addressSecond.join(" ")}` : ""}`;
};

const getPosition = (contact: IResidentContact) => {
  if (contact.position === undefined) {
    return -1;
  }

  return contact.position + 1;
};

export const ContactCardSection = (props: {
  sectionTitle: string;
  unitID: string;
  redirect?: string;
  children: ReactNode;
  contactLength?: number;
}) => {
  const { sectionTitle, unitID, redirect, children, contactLength } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleRedirect = () => {
    if (redirect) {
      contactRedirect();
      history.push(Views.SETTINGS);
    }
  };

  const contactRedirect = () => {
    if (redirect) {
      dispatch(setSettingsOption(SettingListKeys.units_residents));
      dispatch(setSettingsUnit(unitID));
      dispatch(setUnitConfigOpt(redirect));
    }
  };

  const getRedirectContactString = () => {
    if (redirect === unitWithResidentOptions.emergency_contacts) {
      return t("add_emergency_contact");
    } else if (redirect === unitWithResidentOptions.resident_contacts) {
      return t("add_resident_contact");
    } else {
      return "";
    }
  };

  const selectedCommunity = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity
  );

  // Determine the role of the user
  const isCommunityAdmin = !!selectedCommunity?.role.includes(RoleTypes.admin);
  let isOrgAdmin = false;
  if (selectedCommunity) {
    isOrgAdmin = selectedCommunity.role.includes(RoleTypes.org);
  }

  const isAdmin = isCommunityAdmin || isOrgAdmin;

  return (
    <div className={classes.section}>
      <Typography className={classes.sectionTitle} variant="body2">
        {sectionTitle.toUpperCase()}
        {isAdmin && redirect && (
          <IconButton onClick={handleRedirect} className={classes.iconButton}>
            {
              <img
                src={ContactIcons.GoToSettingsIcon}
                alt={t("resident_contacts")}
                draggable={false}
                className={classes.icon}
              />
            }
          </IconButton>
        )}
      </Typography>
      {children}
      {redirect && contactLength === 0 && (
        <div style={{ textAlign: "center" }}>
          <Link
            className={classes.link}
            onClick={contactRedirect}
            color="primary"
            to={Views.SETTINGS}
            component={RouterLink}
          >
            <span>{getRedirectContactString()}</span>
          </Link>
        </div>
      )}
    </div>
  );
};

export const Contacts = (props: { contacts: IResidentContact[] }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <table className={classes.residentContacts}>
      <tbody>
        {props.contacts.map((contact) => {
          const fullName = getFullName(contact.first_name, contact.last_name);
          const contactType = labelByContactType(contact.contact_type);
          const position = getPosition(contact);
          const phoneNumbers = contact.phone_numbers ?? [];
          const email = contact.email ?? "";
          const userType =
            contact.contact_type === undefined ||
            contact.contact_type === "user" ||
            contact.contact_type === "caregiver"
              ? t("user")
              : t("contact");
          const width = position >= 0 ? "180px" : "200px";
          return (
            <tr key={contact.id} className={classes.contactRow}>
              {position >= 0 && (
                <td
                  style={{
                    fontWeight: "bold",
                    paddingRight: "10px",
                  }}
                >
                  {position}
                </td>
              )}
              <td
                style={{
                  minWidth: width,
                  fontWeight: "bold",
                  paddingBottom: "5px",
                }}
              >
                {fullName}
                <div style={{ fontWeight: "Normal", fontSize: "0.75rem" }}>
                  {userType}
                </div>
              </td>
              <td style={{ minWidth: "90px" }}>{t(contactType)}</td>
              <td>
                {email !== "" && <EmailField email={email} />}
                {phoneNumbers.map((phoneNumber) => (
                  <PhoneNumberField
                    key={phoneNumber.id}
                    phoneNumber={phoneNumber}
                  />
                ))}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default function ResidentContacts(props: IProps) {
  const { residentID, communityID, unitID } = props;
  /* ---- State ---- */
  const [anchorEl, setAnchorEl] = useState(null);

  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* ---- Selectors ---- */
  const resident = useSelector((state: IAppState) => {
    return state.commonState.contactInformation[residentID];
  });

  const currentEmergencyContacts = useSelector((state: IAppState) => {
    return state.settingsState.residentEmergencyContacts;
  });

  //Returns users that can be used as emergency contacts
  const emergencyAppUsers = useSelector((state: IAppState) => {
    return getEmergencyAppUsers(state.headerState.users ?? [], unitID);
  });

  /* ---- Methods ---- */
  const handleOpenDropdown = (event: any) => {
    setAnchorEl(event.currentTarget);

    if (communityID !== undefined) {
      dispatch(
        getResidentContacts({
          community_id: communityID,
          resident_id: residentID,
        })
      );
      dispatch(getEmergencyContacts(residentID));
    }
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const residentName =
    resident !== undefined
      ? getFullName(resident.first_name, resident.last_name)
      : "";

  const residentAddress = getAddress(resident);

  let emergencyContacts: IResidentContact[] = [];
  let residentContacts: IResidentContact[] = [];
  if (resident !== undefined) {
    if (currentEmergencyContacts) {
      const contacts = currentEmergencyContacts.filter(
        (item) => item.resident_id
      );
      emergencyContacts = contacts.sort((a, b) =>
        sortEmergencyContacts(a, b)
      ) as IResidentContact[];
    }

    if (resident.contacts && resident.contacts.length > 0)
      residentContacts = resident.contacts
        .filter((contact) => !isEmergencyContact(contact))
        .filter((contact) => contact.first_name);

    if (emergencyAppUsers) {
      emergencyAppUsers.forEach((user) => {
        const emContact = emergencyContacts.find(
          (contact) => contact.user_id && contact.user_id === user.id
        );
        if (!emContact && user.first_name) {
          residentContacts.push({
            id: user.id,
            resident_id: residentID,
            community_id: communityID,
            first_name: user.first_name,
            last_name: user.last_name,
            contact_type:
              user.role.name === ContactTypes.CAREGIVER
                ? ContactTypes.CAREGIVER
                : ContactTypes.USER,
            email: user.email,
            emergency_contact: false,
            phone_numbers: user.phone_numbers as IResidentPhoneNumber[],
          });
        }
      });
    }
    residentContacts = residentContacts.sort((a, b) => sortContacts(a, b));
  }

  return (
    <React.Fragment>
      <Button
        id="contacts"
        classes={{
          root: classes.buttonRoot,
          textPrimary: classes.buttonText,
          label: classes.buttonLabel,
        }}
        color="primary"
        variant="text"
        onClick={handleOpenDropdown}
      >
        <img
          src={ContactIcons.AddressBook}
          alt={t("alt_text_resident_contacts")}
          draggable={false}
          className={classes.contactIcon}
        />

        <Box fontWeight="fontWeightBold">{t("contact")}</Box>
      </Button>
      {resident !== undefined && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          classes={{
            paper: classes.popover,
          }}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ zIndex: 1200 }}
        >
          <React.Fragment>
            <Box className={classes.popoverTitle} fontSize="large">
              {t("contact_summary")}
            </Box>
            <Box className={classes.popoverContent}>
              <div className={classes.container}>
                <div className={classes.residentName}>{residentName}</div>
                {residentAddress !== "" && (
                  <ContactCardSection
                    sectionTitle={t("address")}
                    unitID={unitID}
                    redirect={""}
                  >
                    <div className={classes.boldText}>{residentAddress}</div>
                  </ContactCardSection>
                )}

                {resident.phone_numbers && (
                  <div>
                    <ContactCardSection
                      sectionTitle={t("phone")}
                      unitID={unitID}
                      redirect={""}
                    >
                      {resident.phone_numbers.map((phoneNumber) => (
                        <PhoneNumberField
                          key={phoneNumber.id}
                          phoneNumber={phoneNumber}
                          noTruncate={true}
                        />
                      ))}
                    </ContactCardSection>
                  </div>
                )}
                {residentContacts.length !== 0 && (
                  <ContactCardSection
                    sectionTitle={t("emergency_contacts")}
                    unitID={unitID}
                    redirect={unitWithResidentOptions.emergency_contacts}
                    contactLength={emergencyContacts.length}
                  >
                    <Contacts contacts={emergencyContacts} />
                  </ContactCardSection>
                )}
                {
                  <ContactCardSection
                    sectionTitle={t("resident_contacts")}
                    unitID={unitID}
                    redirect={unitWithResidentOptions.resident_contacts}
                    contactLength={residentContacts.length}
                  >
                    <Contacts contacts={residentContacts} />
                  </ContactCardSection>
                }
              </div>
            </Box>
          </React.Fragment>
        </Popover>
      )}
    </React.Fragment>
  );
}

interface IProps {
  residentID: string;
  communityID: string;
  unitID: string;
}
