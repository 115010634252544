import { useEffect, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { SettingListKeys } from "../../helpers/constants";
import { Grid, List } from "@material-ui/core";
import { SettingsListItem } from "./common/SettingsListItem";
import { useTranslation } from "react-i18next";
import { getUnitDisplayName } from "./common/helpers";
import { isHelpAtHome } from "../dashboard/dashboardSlice";
import { addUnit, setSettingsUnit, setUnitConfigOpt } from "./settingsSlice";
import clsx from "clsx";
import { AddNewItemButton } from "./common/AddNewItemButton";
import { getEmergencyContacts, getResidentInfo } from "./settingsThunks";

const useStyles = <
  T extends { hasResidents: boolean; selectedUnit: string | undefined }
>(
  props: T
) =>
  makeStyles((theme: Theme) =>
    createStyles({
      mainContainer: {
        position: "relative",
        height: "100%",
        width: !!props.selectedUnit ? "calc(100% / 3)" : "50%",
      },
      container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: theme.spacing(3, 0, 0, 0),
        overflowY: "auto",
        background: theme.palette.light.main,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        paddingBottom: theme.spacing(8),
      },
      residentUnitsContainer: {
        width: "100%",
      },
      residentOptionsContainer: {
        width: !!props.selectedUnit ? "calc(100% / 3)" : "50%",
      },
      listItem: {
        background: "#FFF",
        height: props.hasResidents ? "50px" : "unset",
      },
      optionsContainer: {
        width: "100%",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(3),
      },
      column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      },
      primaryText: {
        fontSize: theme.typography.subtitle2.fontSize,
      },
      secondaryText: {
        fontSize: "0.625rem",
      },
      alignRight: {
        textAlign: "right",
      },
      truncateEllipsis: {
        whiteSpace: "nowrap",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "-o-text-overflow": "ellipsis",
      },
    })
  );

const unitWithoutResidentOptions = {
  add_resident: "add_resident",
};
export const unitWithResidentOptions = {
  resident_settings: "resident_settings",
  resident_contacts: "resident_contacts",
  emergency_contacts: "emergency_contacts",
};
const unitWithInstallerOptions = {
  resident_settings: "resident_settings",
};

export const unitSettings = {
  pause_stackcare: "pause_stackcare",
  unit_settings: "unit_settings",
};

export const unitOptions = {
  ...unitWithoutResidentOptions,
  ...unitWithResidentOptions,
  ...unitSettings,
  ...unitWithInstallerOptions,
};

export const ResidentUnitsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Refs */
  const ref = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLUListElement | null>(null);
  const listEl = useRef<HTMLDivElement>(null);

  /* Selectors */
  const selectedOption = useAppSelector(
    (state) => state.settingsState.selectedOption
  );
  const selectedUnit = useAppSelector(
    (state) => state.settingsState.selectedUnit
  );
  const selectedConfigOpt = useAppSelector(
    (state) => state.settingsState.selectedUnitConfigOpt
  );
  const units = useAppSelector((state) => state.headerState.units);

  const unit = units?.find((unit) => unit.id === selectedUnit);

  const residentID = useAppSelector((state) => {
    const selectedUnit = state.settingsState.selectedUnit;
    if (selectedUnit !== undefined) {
      const unit = state.headerState.units?.find(
        (unit) => unit.id === selectedUnit
      );
      if (unit && unit.residents && unit.residents.length > 0) {
        return unit.residents[0].id;
      }
    }
    return undefined;
  });

  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const hasResidents =
    units?.some((unit) => getUnitDisplayName(unit).secondary !== "") ?? false;
  const classes = useStyles({ hasResidents, selectedUnit })();
  /* Methods */
  const handleSelectUnit = (unitID: string) => {
    dispatch(setSettingsUnit(unitID));
  };
  const handleAddUnit = () => {
    dispatch(addUnit());
  };

  /* Selectors */
  useEffect(() => {
    if (listEl.current && ref2.current) {
      const rect = listEl.current.getBoundingClientRect();
      if (
        !(
          rect.top >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
        )
      ) {
        listEl.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [selectedUnit]);
  const handleSelectOption = (configOption: string) => {
    dispatch(setUnitConfigOpt(configOption));
  };

  useEffect(() => {
    if (
      residentID !== undefined &&
      communityID !== undefined &&
      !installerRole
    ) {
      dispatch(
        getResidentInfo({
          community_id: communityID,
          resident_id: residentID,
        })
      );
      dispatch(getEmergencyContacts(residentID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit]);

  const installerRole = useAppSelector(
    (state) => state.headerState.installerRole
  );

  const unitInstalled =
    unit !== undefined
      ? unit.gateway_id !== null && unit.gateway_id !== undefined
      : false;

  const residentOptions = installerRole
    ? unitWithInstallerOptions
    : unitWithResidentOptions;

  return (
    <>
      {selectedOption === SettingListKeys.units_residents && (
        <div className={classes.mainContainer}>
          <div
            ref={ref}
            className={clsx(classes.container, classes.residentUnitsContainer)}
          >
            <List ref={ref2} className={classes.optionsContainer}>
              {units?.map((unit) => {
                let unitDisplayName = getUnitDisplayName(unit);
                const isHelpAtHomeUnit = isHelpAtHome(unit);
                return (
                  <SettingsListItem
                    button
                    key={unit.id}
                    ref={unit.id === selectedUnit ? listEl : undefined}
                    divider
                    disableGutters
                    onClick={() => handleSelectUnit(unit.id)}
                    selected={unit.id === selectedUnit}
                    className={classes.listItem}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item xs={7} className={classes.column}>
                        <div
                          className={clsx(
                            classes.primaryText,
                            classes.truncateEllipsis
                          )}
                        >
                          {unitDisplayName.primary}
                        </div>
                        <div
                          className={clsx(
                            classes.secondaryText,
                            classes.truncateEllipsis
                          )}
                        >
                          {hasResidents
                            ? unitDisplayName.secondary || "‎"
                            : unitDisplayName.secondary}
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div
                          className={clsx(
                            classes.secondaryText,
                            classes.alignRight
                          )}
                        >
                          {isHelpAtHomeUnit
                            ? t("unit_type_hah_title")
                            : t("unit_type_resident_title")}
                        </div>
                      </Grid>
                    </Grid>
                  </SettingsListItem>
                );
              })}
            </List>
          </div>
          <AddNewItemButton parentRef={ref} handleAddAction={handleAddUnit} />
        </div>
      )}
      {selectedOption === SettingListKeys.units_residents &&
        selectedUnit !== undefined && (
          <div
            className={clsx(
              classes.container,
              classes.residentOptionsContainer
            )}
          >
            <List className={classes.optionsContainer}>
              {residentID !== undefined &&
                Object.keys(residentOptions).map((option) => {
                  return (
                    <SettingsListItem
                      button
                      key={option}
                      divider
                      disableGutters
                      onClick={() => handleSelectOption(option)}
                      selected={option === selectedConfigOpt}
                      className={classes.listItem}
                    >
                      <div
                        className={clsx(
                          classes.primaryText,
                          classes.truncateEllipsis
                        )}
                      >
                        {t(option)}
                      </div>
                    </SettingsListItem>
                  );
                })}
              {residentID === undefined &&
                Object.keys(unitWithoutResidentOptions).map((option) => {
                  return (
                    <SettingsListItem
                      button
                      key={option}
                      divider
                      disableGutters
                      onClick={() => handleSelectOption(option)}
                      selected={option === selectedConfigOpt}
                      className={classes.listItem}
                    >
                      <div
                        className={clsx(
                          classes.primaryText,
                          classes.truncateEllipsis
                        )}
                      >
                        {t(option)}
                      </div>
                    </SettingsListItem>
                  );
                })}
            </List>
            <List className={classes.optionsContainer}>
              {unitInstalled && (
                <SettingsListItem
                  button
                  key={unitSettings.pause_stackcare}
                  divider
                  disableGutters
                  onClick={() =>
                    handleSelectOption(unitSettings.pause_stackcare)
                  }
                  selected={unitSettings.pause_stackcare === selectedConfigOpt}
                  className={classes.listItem}
                >
                  <div
                    className={clsx(
                      classes.primaryText,
                      classes.truncateEllipsis
                    )}
                  >
                    {t(unitSettings.pause_stackcare)}
                  </div>
                </SettingsListItem>
              )}
              <SettingsListItem
                button
                key={unitSettings.unit_settings}
                divider
                disableGutters
                onClick={() => handleSelectOption(unitSettings.unit_settings)}
                selected={unitSettings.unit_settings === selectedConfigOpt}
                className={classes.listItem}
              >
                <div
                  className={clsx(
                    classes.primaryText,
                    classes.truncateEllipsis
                  )}
                >
                  {t(unitSettings.unit_settings)}
                </div>
              </SettingsListItem>
            </List>
          </div>
        )}
    </>
  );
};
