import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  OutlinedInput,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { environmentSettingsInit } from "./loginSlice";
import { setEnv, setVer } from "../app/appSlice";

import { Views, TEST, STAGING, PRODUCTION } from "../../helpers/constants";
import { useHistory } from "react-router-dom";
import { StackLogo } from "./StackLogo";
import { ActionBarBottom } from "./accountCreation/ActionBarBottom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import firebase from "firebase/app";
import { currentEnv, currentVer } from "../../helpers/utils";
import { getFirebaseConfig } from "../../firebaseConfig";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: "380px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    subtitleForm: {
      marginTop: "28px",
      marginBottom: "-15px",
    },
    textFieldUser: {
      marginTop: "32px",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
    },
    secondaryButtons: {
      color: theme.palette.paused.light,
    },
    versionInput: {
      width: "100%",
      marginBottom: "15px",
    },
  })
);

export default function ResetPasswordForm() {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  /* ---- State ---- */
  const [environment, setEnvironment] = useState("");
  const [version, setVersion] = useState("");
  const [currVersion, setCurrVersion] = useState("");

  const disabledSubmit =
    environment === currentEnv() && version === currVersion;

  // Set the environment
  useEffect(() => {
    setEnvironment(currentEnv());
    setVersion(currentVer());
    setCurrVersion(currentVer());
  }, []);

  const handleProjectIdChange = (e: React.ChangeEvent<any>): void => {
    const { value } = e.target;
    setEnvironment(value);
  };

  const handleSubmitProjectId = () => {
    dispatch(setEnv(environment));
    dispatch(setVer(version));
    setCurrVersion(version);
    // Firebase initialization
    firebase
      .app("[DEFAULT]")
      .delete()
      .then(function () {
        firebase.initializeApp(getFirebaseConfig(environment));
      });
  };

  const handleVersionInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setVersion(value.replace(/\s+/g, ""));
  };

  /* ---- Utils ---- */
  const handleBackToLogin = () => {
    dispatch(environmentSettingsInit());
    history.push(Views.LOGIN);
  };

  return (
    <React.Fragment>
      <StackLogo />
      <React.Fragment>
        <Typography
          variant="body1"
          align="center"
          className={classes.subtitleForm}
        >
          {"Select the environment from the dropdown menu."}
        </Typography>
        <form
          id="request-code-form"
          noValidate
          className={classes.formContainer}
          autoComplete="off"
        >
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.textFieldUser}
          >
            <Select onChange={handleProjectIdChange} value={environment}>
              <MenuItem value={TEST}>{TEST}</MenuItem>
              <MenuItem value={STAGING}>{STAGING}</MenuItem>
              <MenuItem value={PRODUCTION}>{PRODUCTION}</MenuItem>
            </Select>
            <br />
          </FormControl>
          <OutlinedInput
            name="optional_version"
            id="optional_version"
            onChange={handleVersionInput}
            value={version}
            placeholder={"version (optional)"}
            multiline={true}
            notched={false}
            autoComplete="off"
            className={classes.versionInput}
          />
          <Button
            onClick={handleSubmitProjectId}
            variant="contained"
            color="primary"
            size="large"
            disabled={disabledSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {"Submit"}
          </Button>
        </form>
        <ActionBarBottom>
          <Button
            onClick={handleBackToLogin}
            startIcon={<ArrowBackIosIcon />}
            className={classes.secondaryButtons}
          >
            {t("action_back")}
          </Button>
        </ActionBarBottom>
      </React.Fragment>
    </React.Fragment>
  );
}
