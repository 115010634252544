import { useState, useEffect, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { deletePlacementPhoto } from "./devicesThunks";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { DateTime } from "luxon";
import clsx from "clsx";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { useAppDispatch, useAppSelector } from "../app/appHooks";
import {
  capitalize,
  DeviceType,
  SensorStatus,
  SensorType,
  truncate,
} from "../../helpers/constants";
import {
  DateTimeNow,
  DateTimeLong,
  DateFromISOStringUTC,
} from "../../helpers/datetime";
import { Device } from "../../services/devices.services";
import { IUnit } from "../../services/dashboard.services";
import {
  BatteryState,
  deviceDisplayTextByDeviceType,
  DeviceStatus,
  formatMacAddress,
  sensorDisplayTextBySensorType,
  StatusColor,
  StatusColors,
} from "./deviceHelpers";
import { DeviceIcon } from "./DeviceIcon";
import { DevicePlacementPhoto } from "./DevicePlacementPhoto";
import {
  deleteDevice,
  identifyDevice,
  loadDevicesData,
  updateLQI,
} from "./devicesThunks";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { DevicesIcons } from "../../helpers/iconImports";
import { ErrorCodes } from "../../services/constants";
import { SoftNotificationTypes } from "../common/AppSnack";
import { handledError, showSoftNotification } from "../app/appSlice";
import { DeviceExpandedField } from "./common/DeviceExpandedField";
import RenameRoomDialog from "./RenameRoomDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    devicesViewerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      minWidth: "1000px",
    },
    listTitle: {
      margin: 0,
      fontSize: theme.typography.subtitle1.fontSize,
      textTransform: "uppercase",
      marginBottom: theme.spacing(1.5),
    },
    listHeader: {
      paddingRight: "56px",
      "& > *": {
        fontSize: theme.typography.caption.fontSize,
        textTransform: "uppercase",
        fontWeight: "bold",
      },
    },
    rowContainer: {
      "& > *": {
        display: "flex",
        alignItems: "center",
        fontSize: theme.typography.caption.fontSize,
      },
    },
    iconCell: {
      justifyContent: "center",
      textTransform: "uppercase",
      fontWeight: "bold",
      gap: "8px",
    },
    green: {
      color: theme.palette.primary.main,
    },
    red: {
      color: theme.palette.error.main,
    },
    yellow: {
      color: theme.palette.warning.main,
    },
    unassigned: {
      fontStyle: "italic",
      color: theme.palette.error.main,
    },
    accordionDetailsPanel: {
      display: "flex",
      width: "100%",
      alignItems: "baseline",
      paddingLeft: theme.spacing(4),
    },
    columnGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr",
      rowGap: theme.spacing(1),
    },
    refreshButton: {
      paddingTop: "10px",
    },
    menuList: {
      paddingTop: 16,
      paddingBottom: 16,
    },
    menuItem: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    offlineDevicePanel: {
      display: "flex",
      width: "100%",
      alignItems: "baseline",
      paddingLeft: theme.spacing(6),
      paddingBottom: theme.spacing(2),
      whiteSpace: "pre-line",
    },
    offlineDeviceText: {
      fontSize: "0.75rem",
      maxWidth: "80%",
      margin: "0 auto",
      lineHeight: "1.2",
    },
    linkText: {
      color: theme.palette.primary.main,
    },
  })
);

const Accordion = withStyles((theme: Theme) => ({
  root: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "none",
    width: "100%",
    "&$expanded": {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    "&$expanded": {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      minHeight: 48,
    },
  },
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "0",
    },
  },
  expandIcon: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingRight: 0,
  },
}))(MuiAccordionDetails);

const getDeviceStatus = ({
  isConnected,
  batteryState,
}: {
  isConnected: Device["is_connected"];
  batteryState: Device["battery_state"];
}): DeviceStatus => {
  if (!isConnected) return { text: "offline", color: StatusColors.red };
  if (batteryState === BatteryState.low)
    return { text: "battery_low", color: StatusColors.yellow };
  if (batteryState === BatteryState.good)
    return { text: "online", color: StatusColors.green };
  return { text: "", color: StatusColors.green };
};

const getDeviceStatusExpanded = ({
  isConnected,
}: {
  isConnected: Device["is_connected"];
}): DeviceStatus => {
  if (!isConnected) {
    return { text: "offline", color: StatusColors.red };
  }
  return { text: "online", color: StatusColors.green };
};

const getLastContactStatus = (
  lastContactTime: string,
  userTimezone?: string | null
): DeviceStatus => {
  let contactTime = DateFromISOStringUTC(lastContactTime, userTimezone);

  const timeDiffMinutes = DateTime.now().diff(contactTime, "minutes").toObject()
    .minutes;

  if (userTimezone) {
    contactTime = contactTime.setZone(userTimezone).setZone(userTimezone);
  } else {
    contactTime = contactTime.setZone(DateTimeNow().zoneName);
  }

  let color: StatusColor = StatusColors.green;
  if (timeDiffMinutes !== undefined && timeDiffMinutes > 30) {
    color = StatusColors.yellow;
  }

  return {
    text: DateTimeLong(contactTime),
    color,
  };
};

const getLastMotionStatus = (
  lastMotionTime: string,
  userTimezone?: string | null
): DeviceStatus => {
  let motionTime = DateFromISOStringUTC(lastMotionTime, userTimezone);

  const timeDiffHours = DateTime.now().diff(motionTime, "hours").toObject()
    .hours;

  let color: StatusColor = StatusColors.green;
  if (timeDiffHours !== undefined && timeDiffHours >= 24) {
    color = StatusColors.yellow;
  }

  return {
    text: DateTimeLong(motionTime),
    color,
  };
};

const getBatteryStatus = (
  deviceType: Device["device_type"],
  batteryPercentageRemaining?: number | null,
  batteryVoltage?: number | null
): DeviceStatus => {
  let batteryStatus: DeviceStatus = {
    text: "",
    color: StatusColors.green,
  };

  if (deviceType === DeviceType.outlet || deviceType === DeviceType.bulb) {
    return batteryStatus;
  }

  if (batteryVoltage !== null && batteryVoltage !== undefined) {
    batteryStatus.text = `${truncate(batteryVoltage / 10)}V`;
  }
  return batteryStatus;
};

const getSignalStatus = (lqiValue?: number | null) => {
  let signalStatus: DeviceStatus = {
    text: "",
    color: StatusColors.green,
  };

  if (lqiValue !== null && lqiValue !== undefined) {
    // Signal: "Good" Green >= 40  |  "Poor" Red < 40  (lqi_value)
    if (lqiValue >= 40) {
      signalStatus.text = "good";
    } else {
      signalStatus = {
        text: "poor",
        color: StatusColors.red,
      };
    }
  }
  return signalStatus;
};

const getBatteryStateStatus = (
  batteryState: Device["battery_state"],
  deviceType: Device["device_type"]
) => {
  let batteryStatus: DeviceStatus = {
    text: "",
    color: StatusColors.green,
  };

  if (
    batteryState !== null &&
    batteryState !== undefined &&
    deviceType !== DeviceType.outlet &&
    deviceType !== DeviceType.bulb
  ) {
    if (batteryState === BatteryState.low) {
      batteryStatus = {
        text: BatteryState.low,
        color: StatusColors.yellow,
      };
    } else if (batteryState === BatteryState.good) {
      batteryStatus.text = BatteryState.good;
    }
  }

  return batteryStatus;
};

export const DeviceList = (props: IProps) => {
  const { selectedUnit, devices } = props;

  /* State */
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [device, setDevice] = useState<null | Device>(null);
  const [deleteDeviceToDelete, setDeleteDeviceToDelete] = useState("");
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState("");
  const [deleteDevicePhotoToDelete, setDeleteDevicePhotoToDelete] = useState(
    ""
  );
  const [confirmPhotoDeleteMessage, setConfirmPhotoDeleteMessage] = useState(
    ""
  );
  const [isDialogOpen, setDialogOpen] = useState(false);

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const userTimezone = useAppSelector(
    (state) => state.headerState.user?.time_zone
  );

  const handleRefreshDevicesList = () => {
    if (selectedUnit !== undefined) {
      dispatch(loadDevicesData(selectedUnit.id));
    }
  };

  const errorCode = useAppSelector((state) => state.devicesState.errorCode);
  const [photoStatus, setPhotoStatus] = useState<{ [key: string]: boolean }>(
    {}
  );

  // Initialize photo status when the component mounts or the devices array changes
  useEffect(() => {
    const initialStatus: { [key: string]: boolean } = {};
    if (devices) {
      devices.forEach((device) => {
        initialStatus[device.id] = false;
      });
      setPhotoStatus(initialStatus);
    }
  }, [devices]);

  const handlePhotoLoadSuccess = useCallback((deviceID: string) => {
    setPhotoStatus((prevState) => ({
      ...prevState,
      [deviceID]: true,
    }));
  }, []);

  const setPhotoStatusFalse = useCallback((deviceID: string) => {
    setPhotoStatus((prevState) => ({
      ...prevState,
      [deviceID]: false,
    }));
  }, []);

  /* ---- Effects ---- */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.GATEWAY_NOT_CONNECTED:
          dispatch(handledError());
          dispatch(showSoftNotification(SoftNotificationTypes.GATEWAY_OFFLINE));
          break;
        case ErrorCodes.ZONE_NAME_CONFLICT:
          dispatch(handledError());
          dispatch(
            showSoftNotification(SoftNotificationTypes.ZONE_NAME_CONFLICT)
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  // Menu methods
  const handleOpenMoreMenu = (device: Device) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedDevice(device.id);
    setDevice(device);
  };

  const handleCloseMoreMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuExited = () => {
    setSelectedDevice("");
    setDevice(null);
  };

  const openRenameRoomDialog = () => {
    setMenuAnchorEl(null);
    setDialogOpen(true);
  };

  const closeRenameRoomDialog = () => {
    setDialogOpen(false);
  };

  const handleIdentifyDevice = async () => {
    setMenuAnchorEl(null);
    if (
      selectedUnit !== undefined &&
      selectedUnit.gateway_id &&
      selectedDevice
    ) {
      dispatch(
        identifyDevice({
          unitID: selectedUnit.id,
          gatewayID: selectedUnit.gateway_id,
          deviceID: selectedDevice,
        })
      );

      const identifyDeviceResult = await dispatch(
        identifyDevice({
          unitID: selectedUnit.id,
          gatewayID: selectedUnit.gateway_id,
          deviceID: selectedDevice,
        })
      );

      if (identifyDevice.fulfilled.match(identifyDeviceResult)) {
        dispatch(
          showSoftNotification(getIdentifyDeviceSucessMessage(selectedDevice))
        );
      }
    }
    setSelectedDevice("");
    setDevice(null);
  };

  const handleUpdateSignal = () => {
    setMenuAnchorEl(null);
    if (selectedUnit !== undefined && selectedUnit.gateway_id) {
      dispatch(
        updateLQI({
          unitID: selectedUnit.id,
          gatewayID: selectedUnit.gateway_id,
        })
      );
    }
    setSelectedDevice("");
    setDevice(null);
  };

  const getIdentifyDeviceSucessMessage = (deviceID: string) => {
    const device = devices?.find((device) => device.id === deviceID);
    let confirmIdentifyMessage = "";
    if (device !== undefined) {
      const macAddressFormatted = formatMacAddress(device.mac_address);
      confirmIdentifyMessage = t("device_flash_info", {
        deviceType: device.device_type ? capitalize(device.device_type) : "",
        macAddress: macAddressFormatted,
      });
    }
    return confirmIdentifyMessage;
  };

  const getDeleteMessage = (deviceID: string) => {
    const device = devices?.find((device) => device.id === deviceID);
    let confirmDeleteMessage = "";
    if (device !== undefined) {
      const isDeviceAssigned = !!device.zone_id;
      const macAddressFormatted = formatMacAddress(device.mac_address);

      if (isDeviceAssigned) {
        confirmDeleteMessage = t("confirm_delete_device", {
          deviceType: device.device_type ? capitalize(device.device_type) : "",
          macAddress: macAddressFormatted,
          zone: device.zone_name ?? "",
        });
      } else {
        confirmDeleteMessage = t("confirm_delete_unassigned_device", {
          deviceType: device.device_type ? capitalize(device.device_type) : "",
          macAddress: macAddressFormatted,
        });
      }
    }
    return confirmDeleteMessage;
  };

  const getDeletePlacementPhotoMessage = (deviceID: string) => {
    const device = devices?.find((device) => device.id === deviceID);
    let confirmDeleteMessage = "";
    if (device !== undefined) {
      const macAddressFormatted = formatMacAddress(device.mac_address);
      confirmDeleteMessage = t("confirm_delete_placement_photo", {
        deviceType: device.device_type ? capitalize(device.device_type) : "",
        macAddress: macAddressFormatted,
      });
    }
    return confirmDeleteMessage;
  };

  const handleConfirmDeleteDevice = () => {
    if (selectedUnit !== undefined && selectedDevice) {
      setDeleteDeviceToDelete(selectedDevice);
      setConfirmDeleteMessage(getDeleteMessage(selectedDevice));
    }
    setMenuAnchorEl(null);
    setSelectedDevice("");
    setDevice(null);
  };

  const handleDeleteDevice = (deviceID: string) => {
    if (selectedUnit !== undefined && deviceID) {
      dispatch(
        deleteDevice({
          unitID: selectedUnit.id,
          deviceID: deviceID,
        })
      );
    }
    setDeleteDeviceToDelete("");
    setConfirmDeleteMessage("");
  };

  const handleCloseDropdown = () => {
    setMenuAnchorEl(null);
    setDeleteDeviceToDelete("");
    setConfirmDeleteMessage("");
  };

  const handleConfirmDeletePhoto = () => {
    if (selectedUnit !== undefined && selectedDevice) {
      setDeleteDevicePhotoToDelete(selectedDevice);
      setConfirmPhotoDeleteMessage(
        getDeletePlacementPhotoMessage(selectedDevice)
      );
    }
    setMenuAnchorEl(null);
  };

  const handleDeletePhoto = (deviceID: string) => {
    if (selectedUnit !== undefined && deviceID) {
      dispatch(deletePlacementPhoto(deviceID));
      setPhotoStatusFalse(deviceID);
    }
    setDeleteDevicePhotoToDelete("");
    setConfirmPhotoDeleteMessage("");
  };

  const handleClosePhotoDropdown = () => {
    setMenuAnchorEl(null);
    setDeleteDevicePhotoToDelete("");
    setConfirmPhotoDeleteMessage("");
  };

  const getSensorStatusTranslation = (status: String | null | undefined) => {
    switch (status) {
      case SensorStatus.monitoring:
        return t("monitoring");
      case SensorStatus.learning:
        return t("learning");
      case SensorStatus.test:
        return t("test");
      case SensorStatus.silent:
        return t("silent");
      default:
        return "";
    }
  };

  const getOfflineDeviceText = (
    sensorType: string | null | undefined,
    deviceType: string | null | undefined
  ) => {
    let translationKey = "";
    let deviceTypeText = "";

    switch (sensorType) {
      case SensorType.radar:
        translationKey = "vayyar_not_connected_web";
        break;
      case SensorType.bulb:
        translationKey = "bulb_not_connected_web";
        deviceTypeText = deviceDisplayTextByDeviceType(deviceType, sensorType);
        break;
      case SensorType.outlet:
        translationKey = "outlet_not_connected_web";
        break;
      case SensorType.motion:
      case SensorType.contact:
      case SensorType.contact_count:
      case SensorType.exterior_door:
      case SensorType.help:
        deviceTypeText = deviceDisplayTextByDeviceType(deviceType, sensorType);
        translationKey = "sensor_not_connected_web";
        break;
      default:
        return <></>;
    }

    return (
      <Trans
        i18nKey={translationKey}
        values={{ sensorType: deviceTypeText }}
        components={{
          1: (
            <a
              className={classes.linkText}
              href={`https://${t("support_link")}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("support_link")}
            </a>
          ),
        }}
      ></Trans>
    );
  };

  return (
    <div>
      <h2 className={classes.listTitle}>
        {t("devices")}
        <IconButton
          className={classes.refreshButton}
          onClick={handleRefreshDevicesList}
        >
          <img
            src={DevicesIcons.Refresh}
            alt={"alt_text_refresh"}
            draggable={false}
          />
        </IconButton>
      </h2>
      <div className={classes.devicesViewerContainer}>
        {selectedUnit !== undefined && devices !== undefined && (
          <>
            {devices.length === 0 ? (
              <span>{t("no_devices")}</span>
            ) : (
              <Grid container spacing={1} className={classes.listHeader}>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  {t("room")}
                </Grid>
                <Grid item xs={2}>
                  {t("device")}
                </Grid>
                <Grid item xs={2}>
                  {t("type")}
                </Grid>
                <Grid item xs={1}>
                  {t("status")}
                </Grid>
                <Grid item xs={2}>
                  {t("last_contact")}
                </Grid>
                <Grid item xs={2}>
                  {t("last_activity")}
                </Grid>
                <Grid item xs={1}>
                  {t("battery")}
                </Grid>
              </Grid>
            )}
            {devices.length > 0 &&
              devices.map((device) => {
                const deviceType = deviceDisplayTextByDeviceType(
                  device.device_type,
                  device.sensor_type
                );

                const type = device.sensor_type
                  ? t(sensorDisplayTextBySensorType(device.sensor_type))
                  : device.device_type
                  ? t(sensorDisplayTextBySensorType(device.device_type))
                  : "";

                const isDeviceAssigned = !!device.zone_id;

                const room = isDeviceAssigned
                  ? device.zone_name
                  : t("unassigned");

                const status = getDeviceStatus({
                  isConnected: device.is_connected,
                  batteryState: device.battery_state,
                });

                const statusExpanded = getDeviceStatusExpanded({
                  isConnected: device.is_connected,
                });

                const lastContactStatus = device.last_contact_time
                  ? getLastContactStatus(device.last_contact_time, userTimezone)
                  : ({
                      text: "",
                      color: StatusColors.green,
                    } as DeviceStatus);

                const lastMotionStatus = device.last_motion_time
                  ? getLastMotionStatus(device.last_motion_time, userTimezone)
                  : ({
                      text: "",
                      color: StatusColors.green,
                    } as DeviceStatus);

                const batteryStatus = getBatteryStatus(
                  device.device_type,
                  device.battery_percentage_remaining,
                  device.battery_voltage
                );

                const batteryState = device.battery_state === BatteryState.low;

                // Accordion detail fields
                const macAddressFormatted = formatMacAddress(
                  device.mac_address
                );

                const signalStatus = getSignalStatus(device.lqi_value);

                const batteryStateStatus = getBatteryStateStatus(
                  device.battery_state,
                  device.device_type
                );

                const isMotionSensor = device.sensor_type === SensorType.motion;

                const isPoweredDevice =
                  device.device_type === DeviceType.outlet ||
                  device.device_type === DeviceType.bulb ||
                  device.sensor_type === SensorType.radar;
                return (
                  <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    key={device.id}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                      <Grid
                        container
                        spacing={1}
                        className={classes.rowContainer}
                      >
                        <Grid item xs={1} className={classes.iconCell}>
                          <DeviceIcon
                            deviceType={device.device_type}
                            sensorType={device.sensor_type}
                            manufacturer={device.manufacturer}
                            iconAlt={t("alt_device_icon", {
                              sensor_type: device.sensor_type,
                            })}
                            batteryLow={batteryState}
                          />
                          {device.mac_address.slice(-2)}
                        </Grid>
                        <Grid item xs={1}>
                          <div
                            className={clsx(
                              !device.zone_id && classes.unassigned
                            )}
                          >
                            {room}
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          {deviceType}
                        </Grid>
                        <Grid item xs={2}>
                          {type}
                        </Grid>
                        <Grid item xs={1}>
                          <div className={classes[status.color]}>
                            {t(status.text)}
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div className={classes[lastContactStatus.color]}>
                            {lastContactStatus.text}
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          {isMotionSensor ? (
                            <div className={classes[lastMotionStatus.color]}>
                              {lastMotionStatus.text}
                            </div>
                          ) : (
                            lastMotionStatus.text
                          )}
                        </Grid>
                        {!isPoweredDevice && (
                          <Grid item xs={1}>
                            <div className={classes[batteryStateStatus.color]}>
                              {t(batteryStateStatus.text)}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.accordionDetailsPanel}>
                        <Grid container spacing={1}>
                          <Grid item xs={9}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <div className={classes.columnGrid}>
                                  <DeviceExpandedField
                                    label={t("room")}
                                    value={room}
                                  />
                                  <DeviceExpandedField
                                    label={t("device")}
                                    value={deviceType}
                                  />
                                  <DeviceExpandedField
                                    label={t("type")}
                                    value={type}
                                  />
                                  <DeviceExpandedField
                                    label={t("mac_address")}
                                    value={macAddressFormatted}
                                  />
                                  <DeviceExpandedField
                                    label={t("model")}
                                    value={device.model}
                                  />
                                  <DeviceExpandedField
                                    label={t("status")}
                                    value={t(statusExpanded.text)}
                                    color={statusExpanded.color}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={classes.columnGrid}>
                                  <DeviceExpandedField
                                    label={t("last_contact")}
                                    value={lastContactStatus.text}
                                  />
                                  <DeviceExpandedField
                                    label={t("last_activity")}
                                    value={lastMotionStatus.text}
                                  />
                                  {!isPoweredDevice && (
                                    <>
                                      <DeviceExpandedField
                                        label={t("battery_level")}
                                        value={batteryStatus.text}
                                      />
                                      <DeviceExpandedField
                                        label={t("battery_state")}
                                        value={
                                          batteryStateStatus.text !== ""
                                            ? t(batteryStateStatus.text)
                                            : ""
                                        }
                                        color={batteryStateStatus.color}
                                      />
                                    </>
                                  )}
                                  {device.sensor_type === SensorType.radar && (
                                    <DeviceExpandedField
                                      label={t("device_state")}
                                      value={getSensorStatusTranslation(
                                        device.status
                                      )}
                                    />
                                  )}
                                  {device.sensor_type !== SensorType.radar && (
                                    <DeviceExpandedField
                                      label={t("signal")}
                                      value={
                                        signalStatus.text !== ""
                                          ? t(signalStatus.text)
                                          : ""
                                      }
                                      color={signalStatus.color}
                                    />
                                  )}
                                  <DeviceExpandedField
                                    label={t("firmware")}
                                    value={device.firmware_vs}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={3} key={device.id}>
                            <DevicePlacementPhoto
                              deviceID={device.id}
                              photoStatus={photoStatus}
                              onPhotoLoadSuccess={handlePhotoLoadSuccess}
                              setPhotoStatusFalse={setPhotoStatusFalse}
                            />
                          </Grid>
                        </Grid>
                        <IconButton
                          aria-label="more user options"
                          aria-haspopup="true"
                          onClick={handleOpenMoreMenu(device)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </div>
                    </AccordionDetails>
                    {device && device.is_connected === false && (
                      <div className={classes.offlineDevicePanel}>
                        <Typography className={classes.offlineDeviceText}>
                          {getOfflineDeviceText(
                            device.sensor_type,
                            device.device_type
                          )}
                        </Typography>
                      </div>
                    )}
                  </Accordion>
                );
              })}
            <Menu
              anchorEl={menuAnchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuAnchorEl)}
              onClose={handleCloseMoreMenu}
              classes={{
                list: classes.menuList,
              }}
              TransitionProps={{
                onExited: handleMenuExited,
              }}
            >
              <MenuItem
                className={classes.menuItem}
                onClick={handleIdentifyDevice}
              >
                {t("identify")}
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  openRenameRoomDialog();
                }}
              >
                {t("rename_room")}
              </MenuItem>
              {device && device?.sensor_type !== SensorType.radar && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleUpdateSignal}
                >
                  {t("update_signal")}
                </MenuItem>
              )}
              <MenuItem
                className={classes.menuItem}
                onClick={handleConfirmDeleteDevice}
              >
                {t("delete")}
              </MenuItem>
              {device && photoStatus[device.id] && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleConfirmDeletePhoto}
                >
                  {t("delete_photo")}
                </MenuItem>
              )}
            </Menu>
            <ConfirmDialog
              dialogType={DialogTypes.warning}
              toggle={deleteDeviceToDelete !== ""}
              title={t("confirm_delete_device_title")}
              message={confirmDeleteMessage}
              possitiveText={t("yes")}
              negativeText={t("no")}
              positiveAction={() => handleDeleteDevice(deleteDeviceToDelete)}
              negativeAction={() => void 0}
              onClose={handleCloseDropdown}
            />
            <ConfirmDialog
              dialogType={DialogTypes.warning}
              toggle={deleteDevicePhotoToDelete !== ""}
              title={t("confirm_delete_placement_photo_title")}
              message={confirmPhotoDeleteMessage}
              possitiveText={t("yes")}
              negativeText={t("no")}
              positiveAction={() =>
                handleDeletePhoto(deleteDevicePhotoToDelete)
              }
              negativeAction={() => void 0}
              onClose={handleClosePhotoDropdown}
            />
            <RenameRoomDialog
              toggle={isDialogOpen}
              unitID={selectedUnit.id}
              selectedDevice={device}
              onClose={closeRenameRoomDialog}
            />
          </>
        )}
      </div>
    </div>
  );
};

interface IProps {
  selectedUnit?: IUnit;
  devices?: Device[];
}
