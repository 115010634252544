import { makeStyles, createStyles } from "@material-ui/core/styles";

import { DevicesIcons } from "../../helpers/iconImports";
import { iconByDeviceType, iconBySensorType } from "./deviceHelpers";
import { DeviceType } from "../../helpers/constants";

const useStyles = makeStyles(() =>
  createStyles({
    iconContainer: {
      position: "relative",
      top: 0,
      left: 0,
    },
    lowBatteryOverlay: {
      position: "absolute",
      top: -15,
      left: -12,
    },
  })
);

export const DeviceIcon = (props: IProps) => {
  const { deviceType, sensorType, iconAlt, batteryLow, manufacturer } = props;

  /* Hooks */
  const classes = useStyles();
  let deviceIcon = "";
  if (sensorType) {
    // Handles bulbs with sensor type of motion
    if (deviceType && deviceType === DeviceType.bulb) {
      deviceIcon = iconByDeviceType(deviceType);
    } else {
      deviceIcon = iconBySensorType(sensorType, manufacturer);
    }
  } else if (deviceType) {
    deviceIcon = iconByDeviceType(deviceType);
  }

  return (
    <div className={classes.iconContainer}>
      {deviceIcon && (
        <img src={deviceIcon} alt={iconAlt} draggable={false} height={25} />
      )}
      {batteryLow && (
        <img
          src={DevicesIcons.BatteryLow}
          alt={iconAlt}
          className={classes.lowBatteryOverlay}
          draggable={false}
          height={40}
        />
      )}
    </div>
  );
};

interface IProps {
  deviceType?: string | null;
  sensorType?: string | null;
  iconAlt: string;
  batteryLow?: boolean;
  manufacturer?: string | null;
}
