import { Grid, IconButton, Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import clsx from "clsx";
import TextField from "@mui/material/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { DateTime } from "luxon";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { getCombinedDisplayName } from "./common/helpers";
import { StackSwitch } from "./common/StackSwitch";
import {
  setSecurityNotificationPause,
  setUnitPauseExpiration,
  toggleSettingsPauseUnit,
} from "./settingsThunks";
import {
  DateFromISOStringUTC,
  DateTimeNow,
  timeConfigurations,
} from "../../helpers/datetime";
import { appTheme, TimeFormat } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pauseStackCarePaper: {
      padding: theme.spacing(6, 4),
      width: "550px",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    sectionDescription: {
      whiteSpace: "pre-line",
      fontWeight: "bold",
      fontSize: theme.typography.h6.fontSize,
    },
    fieldDescription: {
      fontWeight: "bold",
      fontSize: theme.typography.h6.fontSize,
      width: "100%",
      margin: 0,
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      gap: theme.spacing(3),
    },

    formDisableableSection: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      gap: theme.spacing(3),
    },
    gridContainer: {
      alignItems: "center",
    },
    scheduleSwitch: {
      float: "right",
    },
    descriptionText: {
      fontWeight: "bold",
      fontSize: "15px",
    },
    removeDate: {
      color: theme.palette.dark.light,
      height: "20px",
      width: "20px",
    },
    removeDateIconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    removeDateIcon: {
      padding: theme.spacing(1),
    },
    textFieldBorder: {
      border: `0.5px solid ${theme.palette.dark.light}`,
      borderRadius: "5px",
      fontWeight: "bold",
      width: "95%",
      padding: theme.spacing(1.5, 2),
    },
    expirationContainer: {
      cursor: "pointer",
      padding: theme.spacing(0, 2),
    },
    expirationPadding: {
      paddingRight: theme.spacing(1),
    },
    disabled: {
      opacity: 0.5,
    },
    disableCursor: {
      cursor: "unset",
    },
    optional: {
      fontWeight: "normal",
    },
  })
);

const calendarTheme = {
  typography: { ...appTheme.typography },
  components: {
    MuiClock: {
      styleOverrides: {
        pin: {
          backgroundColor: appTheme.palette.primary.main,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: appTheme.palette.primary.main,
          },
          "&.Mui-selected:hover": {
            backgroundColor: appTheme.palette.primary.main,
          },
          "&:focus.Mui-selected": {
            backgroundColor: appTheme.palette.primary.main,
          },
        },
      },
    },

    MuiClockPointer: {
      styleOverrides: {
        root: {
          backgroundColor: appTheme.palette.primary.main,
        },
        thumb: {
          backgroundColor: appTheme.palette.primary.main,
          border: `16px solid ${appTheme.palette.primary.main}`,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: appTheme.palette.primary.main,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: appTheme.palette.primary.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: appTheme.palette.primary.main,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: appTheme.palette.primary.main,
          "&.MuiPickersDay-root.Mui-selected": {
            backgroundColor: appTheme.palette.primary.main,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },
          "&:focus-visible": {
            outline: "none",
          },
        },
        input: {
          cursor: "pointer",
          padding: "13.5px 14px",
          textAlign: "right",
          "&:focus-visible": {
            outline: "none",
          },
          fontWeight: "bold",
          paddingRight: 0,
        },
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
} as any;

const theme = createTheme(calendarTheme);

export const PauseStackCare = () => {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState({
    title: "",
    description: "",
  });

  /* Selectors */
  const selectedUnit = useAppSelector((state) =>
    state.headerState.units?.find(
      (unit) => unit.id === state.settingsState.selectedUnit
    )
  );

  const userTimezone = useAppSelector(
    (state) => state.headerState.user?.time_zone
  );

  /* Methods */

  const handleTogglePauseUnit = () => {
    if (selectedUnit !== undefined) {
      const pauseVal = !selectedUnit.is_notifications_paused;

      const toggleUnitData = {
        unitID: selectedUnit.id,
        pauseValue: pauseVal,
        resetExpiration:
          selectedUnit.pause_notifications_expiration === null
            ? false
            : !pauseVal,
      };

      dispatch(toggleSettingsPauseUnit(toggleUnitData));
    }
  };

  const handleChange = () => {
    if (selectedUnit !== undefined) {
      let unitDisplayName = getCombinedDisplayName(selectedUnit);
      if (selectedUnit.is_notifications_paused) {
        // if unit is paused then unpause
        setConfirmMessage({
          title: t("resume_stackcare"),
          description: t("resume_stackcare_for", {
            unit: unitDisplayName,
          }),
        });
      } else {
        setConfirmMessage({
          title: t("pause_stackcare"),
          description: t("pause_stackcare_for", {
            unit: unitDisplayName,
          }),
        });
      }
      setConfirmToggle(true);
    }
  };

  const handleExpirationDate = (acceptedVal: DateTime | null) => {
    if (selectedUnit !== undefined) {
      const expiration =
        acceptedVal !== null ? acceptedVal.toUTC().toISO() : null;

      const expirationUnitData = {
        unitID: selectedUnit.id,
        pauseNotificationsExpiration: expiration,
      };
      dispatch(setUnitPauseExpiration(expirationUnitData));
    }
  };

  const handleResetExpirationDate = async (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.stopPropagation();
    if (selectedUnit !== undefined) {
      const expirationUnitData = {
        unitID: selectedUnit.id,
        pauseNotificationsExpiration: null,
      };
      const resultPause = await dispatch(
        setUnitPauseExpiration(expirationUnitData)
      );

      if (setUnitPauseExpiration.fulfilled.match(resultPause)) {
        setExpirationDate(null);
      }
    }
  };

  const handleToggleSecurityNotification = () => {
    if (selectedUnit !== undefined) {
      const securityNotificationData = {
        unitID: selectedUnit.id,
        securityNotificationEnabled: !selectedUnit.security_notification_enabled,
      };
      dispatch(setSecurityNotificationPause(securityNotificationData));
    }
  };

  const {
    locale,
    timeFormat,
    timeZone,
    configuredTimeFormat,
  } = timeConfigurations();

  const displayTimeFormat = `LLL d, ${timeFormat}`;
  const is24HourTime = configuredTimeFormat === TimeFormat.TIME24H;

  const pauseNotificationsExpiration =
    selectedUnit !== undefined
      ? selectedUnit.pause_notifications_expiration
      : null;

  useEffect(() => {
    setExpirationDate(
      pauseNotificationsExpiration !== null
        ? DateFromISOStringUTC(pauseNotificationsExpiration, userTimezone)
        : pauseNotificationsExpiration
    );
  }, [pauseNotificationsExpiration, userTimezone]);

  const [open, setOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState<DateTime | null>(
    pauseNotificationsExpiration !== null
      ? DateFromISOStringUTC(pauseNotificationsExpiration, userTimezone)
      : pauseNotificationsExpiration
  );

  const isPaused = !!selectedUnit?.is_notifications_paused;

  const maxDateTime = DateTimeNow()
    .setZone(userTimezone ?? timeZone)
    .plus({ years: 5 });
  return (
    <Paper className={classes.pauseStackCarePaper} elevation={3} square>
      <h2
        onClick={() => {
          setOpen(true);
        }}
        className={classes.sectionTitle}
      >
        {t("pause_stackcare")}
      </h2>
      <p className={classes.sectionDescription}>
        {t("pause_stackcare_description")}
      </p>
      {selectedUnit !== undefined && (
        <form
          id="edit-community-unit-form"
          noValidate
          autoComplete="off"
          className={classes.formContainer}
        >
          <fieldset className={classes.textFieldBorder}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={8}>
                <span className={classes.descriptionText}>
                  {t("stackcare_paused")}
                </span>
              </Grid>
              <Grid item xs={4}>
                <StackSwitch
                  className={classes.scheduleSwitch}
                  checked={selectedUnit.is_notifications_paused}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </fieldset>
          <div
            className={clsx(
              classes.formDisableableSection,
              !isPaused && classes.disabled
            )}
          >
            <p className={clsx(classes.fieldDescription)}>
              {`${t("expiration")} `}
              <span className={classes.optional}>{t("optional")}</span>
            </p>
            <fieldset
              className={clsx(
                classes.textFieldBorder,
                classes.expirationContainer,
                classes.gridContainer,
                !isPaused && classes.disableCursor,
                expirationDate !== null && classes.expirationPadding
              )}
            >
              <Grid
                container
                className={classes.gridContainer}
                onClick={isPaused ? () => setOpen(true) : undefined}
              >
                <Grid item xs={4}>
                  <span className={classes.descriptionText}>
                    {t("expires")}
                  </span>
                </Grid>
                <Grid item xs={expirationDate !== null ? 7 : 8}>
                  <LocalizationProvider
                    adapterLocale={locale}
                    dateAdapter={AdapterLuxon}
                  >
                    <ThemeProvider theme={theme}>
                      <MobileDateTimePicker
                        open={open}
                        value={expirationDate}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            setExpirationDate(
                              newValue.setZone(userTimezone ?? timeZone)
                            );
                          } else {
                            setExpirationDate(newValue);
                          }
                        }}
                        onOpen={isPaused ? () => setOpen(true) : undefined}
                        onAccept={(acceptedVal) => {
                          handleExpirationDate(acceptedVal);
                        }}
                        ampm={!is24HourTime}
                        disablePast
                        maxDateTime={maxDateTime}
                        minutesStep={15}
                        onClose={() => {
                          setOpen(false);
                        }}
                        DialogProps={{
                          onClose: (event, reason) => void undefined,
                          PaperProps: { onClick: (e) => e.stopPropagation() },
                          onBackdropClick: (e) => e.stopPropagation(),
                        }}
                        minDateTime={DateTimeNow().setZone(
                          userTimezone ?? timeZone
                        )}
                        inputFormat={displayTimeFormat}
                        renderInput={(params) => {
                          let val = t("never");
                          if (params.inputProps && params.inputProps.value) {
                            val = params.inputProps.value;
                          }

                          return (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                disabled: !isPaused,
                                value: val,
                              }}
                              InputProps={{
                                readOnly: true,
                                disabled: !isPaused,
                              }}
                            />
                          );
                        }}
                      />
                    </ThemeProvider>
                  </LocalizationProvider>
                </Grid>
                {isPaused && expirationDate !== null && (
                  <Grid className={classes.removeDateIconContainer} item xs={1}>
                    <IconButton
                      onClick={(e) => handleResetExpirationDate(e)}
                      aria-label="remove date"
                      className={classes.removeDateIcon}
                    >
                      <CloseIcon className={classes.removeDate} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </fieldset>
            <p className={classes.fieldDescription}>
              {`${t("security_notification")} `}
              <span className={classes.optional}>{t("optional")}</span>
            </p>
            <p className={clsx(classes.fieldDescription, classes.optional)}>
              {t("security_notification_description")}
            </p>
            <fieldset className={classes.textFieldBorder}>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={8}>
                  <span className={classes.descriptionText}>
                    {`${t("security_notification_enabled")} `}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <StackSwitch
                    className={classes.scheduleSwitch}
                    checked={!!selectedUnit.security_notification_enabled}
                    disabled={!isPaused}
                    onChange={handleToggleSecurityNotification}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </div>
        </form>
      )}
      <ConfirmDialog
        dialogType={
          selectedUnit?.is_notifications_paused
            ? DialogTypes.primary
            : DialogTypes.warning
        }
        toggle={confirmToggle}
        title={confirmMessage.title}
        message={confirmMessage.description}
        possitiveText={t("yes")}
        negativeText={t("no")}
        positiveAction={handleTogglePauseUnit}
        negativeAction={() => void 0}
        onClose={() => setConfirmToggle(false)}
      />
    </Paper>
  );
};
