import {
  Button,
  FormHelperText,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Paper,
  Popover,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormRow } from "./common/FormRow";
import { sortAlphabetical, UnitTypes } from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { GenericPicker } from "./common/GenericPicker";
import { deleteCommunityUnit, updateCommunityUnit } from "./settingsThunks";
import { isHelpAtHome } from "../dashboard/dashboardSlice";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ErrorCodes } from "../../services/constants";
import { handledError } from "../app/appSlice";
import { DropdownInputWithTooltip, InputRow } from "./AddUnitForm";
import { OptionsType, timezoneFormated } from "./CommunityInfo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editCommunityUnit: {
      padding: theme.spacing(6, 7),
      width: "550px",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    fieldList: {
      listStyle: "none",
      padding: 0,
      paddingRight: "50px",
      width: "100%",
    },
    inputWidth: {
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1.2em .5em 1.4em",
      },
    },
    moreButton: {
      position: "absolute",
      right: 0,
      top: "-12px",
    },
    helperText: {
      minHeight: "20px",
      lineHeight: "1.2",
      color: theme.palette.error.main,
    },
    flagIcon: {
      marginRight: theme.spacing(0.5),
    },
    rowInput: {
      width: "58.33%",
    },
    subheaderRoot: {
      lineHeight: "16px",
    },
  })
);

enum FormFieldIDs {
  unitType = "unit-type",
  unitName = "unit-name",
  timeZone = "time-zone",
}

type FormState = {
  [FormFieldIDs.unitType]?: string | null;
  [FormFieldIDs.unitName]?: string | null;
  [FormFieldIDs.timeZone]?: OptionsType | null;
};

export const EditCommunityUnit = () => {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const units = useAppSelector((state) => state.headerState.units);
  const hasUnits = useAppSelector(
    (state) =>
      state.headerState.units !== undefined &&
      state.headerState.units.length > 0
  );
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const communityTimezone = useAppSelector(
    (state) => state.headerState.selectedCommunity?.time_zone
  );
  const selectedUnit = useAppSelector((state) =>
    state.headerState.units?.find(
      (unit) => unit.id === state.settingsState.selectedUnit
    )
  );

  const handleChangeDropdown = (key: string, value: OptionsType | null) => {
    setFormValues((prevVal) => ({
      ...prevVal,
      [FormFieldIDs.timeZone]: value,
    }));
  };

  const errorCode = useAppSelector((state) => state.settingsState.errorCode);

  /* ---- Effects ---- */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.NAME_CONFLICT:
          dispatch(handledError());
          setFormError((prevVal) => ({
            ...prevVal,
            name_conflict: t("unit_name_conflict"),
          }));
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  const allHelpAtHome = units?.every((unit) => isHelpAtHome(unit));

  /* State */
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [popoverID, setPopoverID] = useState("");
  const [formError, setFormError] = useState({
    [FormFieldIDs.unitName]: "",
    name_conflict: "",
  });

  const [formValues, setFormValues] = useState<FormState>({
    [FormFieldIDs.unitType]: null,
    [FormFieldIDs.unitName]: null,
    [FormFieldIDs.timeZone]: null,
  });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [deleteUnit, setDeleteUnit] = useState(false);
  /* Effects */
  // Initialize unit type in the form
  useEffect(() => {
    if (hasUnits) {
      setFormValues((prevVal) => ({
        ...prevVal,
        [FormFieldIDs.unitType]: allHelpAtHome
          ? UnitTypes.HELP_AT_HOME
          : UnitTypes.RESIDENT,
      }));
    } else {
      setFormValues((prevVal) => ({
        ...prevVal,
        [FormFieldIDs.unitType]: UnitTypes.RESIDENT,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUnit && selectedUnit.unit_type === UnitTypes.RESIDENT) {
      setFormValues((prevVal) => ({
        ...prevVal,
        [FormFieldIDs.unitName]: selectedUnit.name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedUnit !== undefined) {
      let timezoneOption: OptionsType | null = null;
      if (selectedUnit?.time_zone !== null) {
        timezoneOption =
          timezoneFormated.find(
            (time_zone) => time_zone.id === selectedUnit?.time_zone
          ) ?? null;
      }

      if (timezoneOption === null && communityTimezone !== undefined) {
        timezoneOption =
          timezoneFormated.find(
            (time_zone) => time_zone.id === communityTimezone
          ) ?? null;
      }
      setFormValues((prevVal) => ({
        ...prevVal,
        [FormFieldIDs.timeZone]: timezoneOption,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit]);

  /* Methods */
  // Menu methods
  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleDeleteUnitClick = () => {
    setDeleteUnit(true);
  };

  const handleCloseDropdown = () => {
    setMenuAnchorEl(null);
    setDeleteUnit(false);
  };

  const handleDeleteUnit = async () => {
    if (selectedUnit) {
      dispatch(deleteCommunityUnit(selectedUnit.id));
      setDeleteUnit(false);
      setMenuAnchorEl(null);
    }
  };

  // Open dropdown list for some of the input elements
  const handleOpenFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(id);
  };

  const handleChange = (value: string, formField: FormFieldIDs) => {
    setFormValues((prevVal) => ({
      ...prevVal,
      [formField]: value.trimStart(),
    }));
    setFormError((prevVal) => ({
      ...prevVal,
      name_conflict: "",
    }));
    setAnchorEl(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const unitType = formValues[FormFieldIDs.unitType];
    if (communityID === undefined) return;
    if (unitType === undefined || unitType === null) return;

    if (selectedUnit) {
      if (unitType === UnitTypes.RESIDENT) {
        if (formValues[FormFieldIDs.unitName] === "") return;
        if (formValues[FormFieldIDs.timeZone] === null) {
          const updatedUnitData = {
            id: selectedUnit.id,
            name: formValues[FormFieldIDs.unitName] ?? "",
          };
          dispatch(updateCommunityUnit(updatedUnitData));
        } else {
          const updatedUnitData = {
            id: selectedUnit.id,
            name: formValues[FormFieldIDs.unitName] ?? "",
            time_zone: formValues[FormFieldIDs.timeZone]?.id,
          };
          dispatch(updateCommunityUnit(updatedUnitData));
        }
      } else {
        if (formValues[FormFieldIDs.timeZone] !== null) {
          const updatedUnitData = {
            id: selectedUnit.id,
            time_zone: formValues[FormFieldIDs.timeZone]?.id,
          };
          dispatch(updateCommunityUnit(updatedUnitData));
        }
      }
    }
  };
  /* List values */
  const unitTypesList = [
    { id: UnitTypes.HELP_AT_HOME, label: "unit_type_hah_title" },
    { id: UnitTypes.RESIDENT, label: "unit_type_resident_title" },
  ].sort((a, b) => sortAlphabetical(t(a.label), t(b.label)));

  const getUnitTypeLabel = (unitTypeID: string | null) => {
    if (unitTypeID === null) return "-";
    const unitType = unitTypesList.find(
      (unitType) => unitType.id === unitTypeID
    );
    return unitType?.label ?? "-";
  };

  // Validate if required field has value
  const validateNotEmpty = (id: FormFieldIDs) => {
    if (formValues[id] === null || formValues[id] === "") {
      setFormError((prevVal) => ({ ...prevVal, [id]: "requiredVal" }));
    } else {
      setFormError((prevVal) => ({ ...prevVal, [id]: "" }));
    }
  };

  // Enable save button
  const checkFormValidity = (values: FormState) => {
    let enabled = false;
    if (values[FormFieldIDs.unitType] === UnitTypes.RESIDENT) {
      if (
        selectedUnit &&
        selectedUnit.name !== values[FormFieldIDs.unitName] &&
        values[FormFieldIDs.unitName] !== null &&
        values[FormFieldIDs.unitName] !== ""
      ) {
        enabled = true;
      }
      if (
        selectedUnit &&
        values[FormFieldIDs.timeZone] !== null &&
        values[FormFieldIDs.timeZone]?.id !== selectedUnit.time_zone
      ) {
        enabled = true;
      }
    }

    if (values[FormFieldIDs.unitType] === UnitTypes.HELP_AT_HOME) {
      if (!hasUnits) {
        enabled = true;
      }
      if (
        selectedUnit &&
        values[FormFieldIDs.timeZone] !== null &&
        values[FormFieldIDs.timeZone]?.id !== selectedUnit.time_zone
      ) {
        enabled = true;
      }
    }

    return enabled;
  };

  const enableSubmit = checkFormValidity(formValues);

  return (
    <Paper className={classes.editCommunityUnit} elevation={3} square>
      <h2 className={classes.sectionTitle}>
        {t("unit_settings")}

        <IconButton
          className={classes.moreButton}
          aria-label="more user options"
          aria-haspopup="true"
          onClick={handleOpenMoreMenu}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={menuAnchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMoreMenu}
        >
          <MenuItem onClick={handleDeleteUnitClick}>
            {t("delete_unit")}
          </MenuItem>
        </Menu>
        <ConfirmDialog
          dialogType={DialogTypes.warning}
          toggle={deleteUnit}
          title={t("delete_unit")}
          message={t("delete_unit_confirm_message")}
          possitiveText={t("yes")}
          negativeText={t("no")}
          positiveAction={handleDeleteUnit}
          negativeAction={() => void 0}
          onClose={handleCloseDropdown}
        />
      </h2>
      <form
        id="edit-community-unit-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.fieldList}>
          <FormRow inputKey="unit_type">
            <DropdownInputWithTooltip
              id={FormFieldIDs.unitType}
              value={
                formValues[FormFieldIDs.unitType]
                  ? t(
                      getUnitTypeLabel(
                        formValues[FormFieldIDs.unitType] as string
                      )
                    )
                  : undefined
              }
              disabled={hasUnits}
              handleClick={handleOpenFilter}
            />
          </FormRow>
          {formValues[FormFieldIDs.unitType] === UnitTypes.RESIDENT && (
            <FormRow inputKey="name" required>
              <Input
                id={FormFieldIDs.unitName}
                type={"text"}
                className={classes.inputWidth}
                value={formValues[FormFieldIDs.unitName] ?? ""}
                onChange={(e) => {
                  handleChange(e.currentTarget.value, FormFieldIDs.unitName);
                }}
                error={
                  (formError[FormFieldIDs.unitName] !== "" &&
                    formError[FormFieldIDs.unitName] !== null) ||
                  formError["name_conflict"] !== ""
                }
                onBlur={() => validateNotEmpty(FormFieldIDs.unitName)}
              />
              {formError["name_conflict"] && (
                <FormHelperText className={classes.helperText}>
                  {formError["name_conflict"]}
                </FormHelperText>
              )}
            </FormRow>
          )}
          <InputRow
            inputKey="time_zone"
            value={formValues[FormFieldIDs.timeZone] ?? ""}
            select
            options={timezoneFormated}
            onChangeSelect={handleChangeDropdown}
          />
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("save")}
          </Button>
        </div>
      </form>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1200 }}
      >
        <>
          {popoverID === FormFieldIDs.unitType && (
            <GenericPicker
              selectedValue={formValues["unit-type"] ?? null}
              optionList={unitTypesList}
              localize={true}
              handleChange={(option) =>
                handleChange(option, FormFieldIDs.unitType)
              }
            />
          )}
          {popoverID === "" && null}
        </>
      </Popover>
    </Paper>
  );
};
