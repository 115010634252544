import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";

import { ReducerStates, SensorType } from "../../helpers/constants";
import { IUnit } from "../../services/dashboard.services";
import {
  changeSelectedCommunity,
  setAllNotifications,
} from "../header/headerSlice";
import { logout } from "../login/loginSlice";
import { IFailedRequest } from "../../services/login.services";
import {
  loadDevicesIssues,
  loadDevicesData,
  loadGatewaysData,
  loadDevicePlacementPhoto,
  updateLQI,
  identifyDevice,
  deleteDevice,
  loadDeviceIssuesBadge,
  updateZoneName,
} from "./devicesThunks";
import { Device, DeviceIssue, Gateway } from "../../services/devices.services";

export const sortDevices = (a: Device, b: Device) => {
  const priorityBySensorType: { [key: string]: number } = {
    [SensorType.help]: 1,
    [SensorType.contact]: 2,
    [SensorType.contact_count]: 3,
    [SensorType.motion]: 4,
    [SensorType.outlet]: 5,
  };
  const hasZoneA = a.zone_id;
  const hasZoneB = b.zone_id;

  if (hasZoneA === null || hasZoneA === undefined) return -1;
  if (hasZoneB === null || hasZoneB === undefined) return 1;

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const sensorTypePriorityA = priorityBySensorType[a.sensor_type ?? ""];
  const sensorTypePriorityB = priorityBySensorType[b.sensor_type ?? ""];

  const zoneNameA = a.zone_name ?? "";
  const zoneNameB = b.zone_name ?? "";

  if (collator.compare(zoneNameA, zoneNameB) !== 0) {
    return collator.compare(zoneNameA, zoneNameB);
  }

  if (sensorTypePriorityA > sensorTypePriorityB) {
    return 1;
  }
  if (sensorTypePriorityA < sensorTypePriorityB) {
    return -1;
  }

  return 0;
};

const countDeviceIssues = (issues: DeviceIssue[]) => {
  let systemIssues = 0;
  issues.forEach((issue) => {
    systemIssues += issue.hub_status ? 1 : 0;
    systemIssues += issue.offline_devices ? issue.offline_devices : 0;
    systemIssues += issue.low_battery_devices ? issue.low_battery_devices : 0;
  });
  return systemIssues;
};

export interface IDevicesState {
  units?: IUnit[];
  selectedUnit?: string;
  devicesIssues?: DeviceIssue[];
  devices?: Device[];
  gateways?: Gateway[];
  state: ReducerStates;
  firebaseState: ReducerStates;
  errorCode: string;
  errorMessage?: string;
  systemIssues?: number;
}

export const initialDevicesState: IDevicesState = {
  units: undefined,
  selectedUnit: undefined,
  devicesIssues: undefined,
  devices: undefined,
  gateways: undefined,
  state: ReducerStates.IDLE,
  firebaseState: ReducerStates.IDLE,
  errorCode: "",
  errorMessage: "",
  systemIssues: 0,
};

const devicesAsyncThunks = [
  loadDevicesIssues,
  loadDevicesData,
  loadGatewaysData,
  loadDevicePlacementPhoto,
  updateLQI,
  identifyDevice,
  deleteDevice,
  updateZoneName,
] as const;

// Check the state for every async thunk
const isAPendingAction = isPending(...devicesAsyncThunks);

const isAFulfilledAction = isFulfilled(...devicesAsyncThunks);

const isARejectedAction = isRejected(...devicesAsyncThunks);

const devicesSlice = createSlice({
  name: "devices",
  initialState: initialDevicesState,
  reducers: {
    setSelectedUnit(state, action: PayloadAction<string>) {
      state.selectedUnit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeSelectedCommunity, (state) => {
        state.units = initialDevicesState.units;
        state.selectedUnit = initialDevicesState.selectedUnit;
        state.devicesIssues = initialDevicesState.devicesIssues;
        state.devices = initialDevicesState.devices;
        state.gateways = initialDevicesState.gateways;
        state.systemIssues = 0;
      })
      .addCase(setAllNotifications, (state) => {
        state.units = initialDevicesState.units;
        state.selectedUnit = initialDevicesState.selectedUnit;
        state.devicesIssues = initialDevicesState.devicesIssues;
        state.devices = initialDevicesState.devices;
        state.gateways = initialDevicesState.gateways;
        state.systemIssues = 0;
      })
      .addCase(loadDevicesIssues.fulfilled, (state, action) => {
        state.devicesIssues = action.payload;
        state.systemIssues = countDeviceIssues(action.payload);
      })
      // Another thunk was created to prevent this request from generating a loading screen
      .addCase(loadDeviceIssuesBadge.fulfilled, (state, action) => {
        state.devicesIssues = action.payload;
        state.systemIssues = countDeviceIssues(action.payload);
      })
      .addCase(loadDevicesData.fulfilled, (state, action) => {
        const devices = action.payload.sort((a, b) => sortDevices(a, b));
        state.devices = devices;
      })
      .addCase(loadGatewaysData.fulfilled, (state, action) => {
        state.gateways = action.payload;
      })
      .addCase(updateZoneName.fulfilled, (state, action) => {
        state.devices = state.devices?.map((device) =>
          device.zone_id === action.payload.id
            ? { ...device, zone_name: action.payload.name }
            : device
        );
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        const deletedDeviceIndex = state.devices?.findIndex(
          (device) => device.id === action.meta.arg.deviceID
        );

        if (deletedDeviceIndex !== undefined && deletedDeviceIndex !== -1) {
          state.devices?.splice(deletedDeviceIndex, 1);
        }
      })
      .addCase(logout.type, (state) => {
        return initialDevicesState;
      })
      .addMatcher(isAPendingAction, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialDevicesState.errorCode;
        state.errorMessage = initialDevicesState.errorMessage;
      })
      .addMatcher(isAFulfilledAction, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addMatcher(isARejectedAction, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorCode = action.error.code ?? "";
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      });
  },
});

const { reducer } = devicesSlice;

export const { setSelectedUnit } = devicesSlice.actions;

export default reducer;
