import React from "react";
import {
  IKitchenCountData,
  IUnitKitchenData,
} from "../../dashboard/eventDataTypes";
import { AnalyticsChartTypes } from "../../../helpers/constants";
import { UnitChartHeader } from "./UnitChartHeader";
import StackedBarChartB from "../../common/StackedBarChartB";
import AnalyticsChartContainer from "./AnalyticsChartContainer";
import { DateTime } from "luxon";
import { CHART_DATA_LIMIT_MINIMIZED } from "../AnalyticsViewer";
import { ChartMode } from "../AnalyticsViewerCharts";
import { timeConfigurations } from "../../../helpers/datetime";

export function UnitKitchenActivity(props: IProps) {
  const { chartData, mode, lastUpdatedTime, isPrintable, pagination } = props;

  const { locale } = timeConfigurations();

  const dereferencedData = JSON.parse(
    JSON.stringify(chartData.weekly_count || [])
  ) as IKitchenCountData[];

  let data = dereferencedData.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const filterWithPagination = (fullData: IKitchenCountData[]) => {
    if (pagination) {
      const startTime = DateTime.fromISO(pagination.startTime).startOf("day");
      const endTime = DateTime.fromISO(pagination.endTime).endOf("day");
      return fullData.filter((item) => {
        const date = DateTime.fromISO(item.date);
        return date >= startTime && date <= endTime;
      });
    }
    return fullData;
  };

  const filteredData = filterWithPagination(dereferencedData);

  // Reference dates
  let dates = undefined;
  let mappedMin = undefined;
  let mappedMax = undefined;

  if (pagination && mode === ChartMode.maximized) {
    dates = data.map((item) => item.date);
    mappedMin = dates.indexOf(pagination.startTime);
    mappedMax = dates.indexOf(pagination.endTime);
  }

  data =
    mode === ChartMode.maximized
      ? data
      : data.slice(-CHART_DATA_LIMIT_MINIMIZED);

  const valuesNight: number[] = [];
  const valuesMorning: number[] = [];
  const valuesAfternoon: number[] = [];
  const valuesEvening: number[] = [];
  const valuesTotals: number[] = [];
  const labels: (string | string[])[] = [];
  let max = 0;

  data.map((item, index) => {
    valuesNight.push(item.night);
    valuesMorning.push(item.morning);
    valuesAfternoon.push(item.afternoon);
    valuesEvening.push(item.evening);

    valuesTotals.push(
      item.night + item.morning + item.afternoon + item.evening
    );
    const dateObj = DateTime.fromISO(item.date).setLocale(locale);
    labels.push([dateObj.toFormat("EEE"), dateObj.toFormat("dd")]);
    max = Math.max(
      max,
      item.night + item.morning + item.afternoon + item.evening
    );
    return index;
  });
  max = Math.ceil((max + 5) / 10) * 10;
  const headerData = {
    fridge_count: chartData.fridge_count,
    weekly_count: filteredData,
  };
  return (
    <React.Fragment>
      <UnitChartHeader
        type={AnalyticsChartTypes.kitchen}
        mode={mode}
        data={headerData}
        isPrintable={isPrintable}
        lastUpdatedTime={lastUpdatedTime}
      />
      <AnalyticsChartContainer isPrintable={isPrintable}>
        <StackedBarChartB
          labels={labels}
          valuesNight={valuesNight}
          valuesMorning={valuesMorning}
          valuesAfternoon={valuesAfternoon}
          valuesEvening={valuesEvening}
          valuesTotals={valuesTotals}
          mappedMin={mappedMin}
          mappedMax={mappedMax}
          dateReference={dates}
          max={max}
          animation={false}
          panEnabled={mode === ChartMode.maximized}
        />
      </AnalyticsChartContainer>
    </React.Fragment>
  );
}

interface IProps {
  chartData: IUnitKitchenData;
  mode: ChartMode;
  lastUpdatedTime?: string;
  isPrintable: boolean;
  pagination?: { startTime: string; endTime: string };
}
