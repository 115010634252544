import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  List,
  ListItemText,
  Popover,
  withStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useTranslation } from "react-i18next";

import MuiListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      textTransform: "none",
    },
    buttonOutlined: {
      padding: theme.spacing(0.5, 1),
    },
    timeframeSelector: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: "120px",
      backgroundColor: "#fff",
      position: "absolute",
      right: 0,
      top: 0,
    },
    timeframePopover: {
      minWidth: "118px",
    },
    bold: {
      fontWeight: "bolder",
    },
  })
);

const ListItem = withStyles(({ palette, spacing }) => ({
  root: {
    paddingBottom: spacing(0.5),
    paddingTop: spacing(0.5),
    "&$selected": {
      backgroundColor: palette.light.light,
      color: palette.primary.main,
      fontWeight: "bold",
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
      color: palette.light.light,
    },
    "&:hover": {
      backgroundColor: palette.primary.main,
      color: palette.light.light,
    },
  },
  selected: {},
}))(MuiListItem);

const EventTimeframes = [
  { id: 0, label: "today" },
  { id: 1, label: "day_2" },
  { id: 2, label: "day_3" },
  { id: 3, label: "day_5" },
  { id: 4, label: "week_1" },
  { id: 5, label: "week_2" },
  { id: 6, label: "month_1" },
];

export const TimeframeSelector = (props: IProps) => {
  const { selected, handleSelect } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  /* ---- State ---- */
  const [anchorEl, setAnchorEl] = React.useState(null);

  /* ---- Methods ---- */
  const handleOpenDropdown = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const communityDropdownID = open ? "timeframe-dropdown" : undefined;

  const timeframeLabel = EventTimeframes.find(
    (timeframe) => timeframe.id === selected
  )?.label;
  return (
    <React.Fragment>
      <Button
        classes={{
          root: classes.buttonRoot,
          outlined: classes.buttonOutlined,
        }}
        variant="outlined"
        className={classes.timeframeSelector}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleOpenDropdown}
      >
        {t(timeframeLabel ?? "")}
      </Button>
      <Popover
        id={communityDropdownID}
        open={open}
        classes={{
          paper: classes.timeframePopover,
        }}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List aria-label="change events timeframe">
          {EventTimeframes.map((timeframeItem) => {
            const timeframeLabelItem = EventTimeframes.find(
              (timeframe) => timeframe.id === timeframeItem.id
            )?.label;
            return (
              <ListItem
                key={timeframeItem.id}
                button
                selected={timeframeItem.id === selected}
                onClick={() => {
                  handleSelect(timeframeItem.id);
                  handleCloseDropdown();
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    classes: {
                      root: timeframeItem.id === selected ? classes.bold : "",
                    },
                  }}
                  primary={t(timeframeLabelItem ?? "")}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </React.Fragment>
  );
};

interface IProps {
  selected: number;
  handleSelect: (id: number) => void;
}
