import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Divider, List, ListItem } from "@material-ui/core";
import clsx from "clsx";
import { ICommunityGroup } from "../../../services/header.services";
import { ApplyFilterButton } from "../../notifications/filters/ApplyFilterButton";
import { GreenCheckbox } from "../../notifications/filters/GreenCheckBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverHeader: {
      display: "flex",
    },
    listRoot: {
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
    },
    popoverContainer: {
      maxWidth: "360px",
      minWidth: "340px",
      maxHeight: "350px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    optionTitle: {
      display: "flex",
    },
    optionDescription: {
      display: "flex",
      marginTop: theme.spacing(0.5),
      marginBottom: 0,
    },
    contentContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(0.5),
    },
    itemBorder: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    titleHeaderBorder: {
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
  })
);

export const NotificationGroupsPicker = (props: IProps) => {
  const { value, communityGroups, handleChange } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  // Create controlled list options
  let defaultState: { [key: string]: boolean } = {};
  if (communityGroups !== undefined) {
    communityGroups.forEach((group) => {
      if (value !== null) {
        defaultState[group.id] = value.includes(group.id);
      } else {
        defaultState[group.id] = false;
      }
    });
  }

  /* ---- State ---- */
  const [selection, setSelection] = useState(defaultState);

  /* ---- Methods ---- */
  const handleChangeSelection = (groupID: string) => {
    setSelection({
      ...selection,
      [groupID]: !selection[groupID],
    });
  };

  const handleApplyButton = () => {
    const values = Object.keys(selection).filter((key) => selection[key]);

    handleChange(values);
  };

  return (
    <React.Fragment>
      <div className={classes.popoverHeader}>
        <ListItem
          className={clsx(classes.listRoot, classes.titleHeaderBorder)}
          key={"popover-header-notification-group"}
          id={"popover-header-notification-group"}
        >
          {t("notification_groups").toUpperCase()}
        </ListItem>

        <ApplyFilterButton
          defaultOptions={defaultState}
          currentOptions={selection}
          onApplyFilter={handleApplyButton}
        />
      </div>
      <Divider />
      <List
        aria-label="notification group type list"
        className={classes.popoverContainer}
      >
        {communityGroups?.map((option, index, arr) => {
          return (
            <ListItem
              classes={{ root: classes.listRoot }}
              key={option.id}
              id={option.id}
              button
              onClick={() => handleChangeSelection(option.id)}
            >
              <div
                className={clsx(
                  classes.contentContainer,
                  index < arr.length - 1 && classes.itemBorder
                )}
              >
                <div className={classes.optionTitle}> {option.name}</div>

                <GreenCheckbox
                  edge="start"
                  checked={!!selection[option.id]}
                  tabIndex={-1}
                  disableRipple
                />
              </div>
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  value: string[] | null;
  communityGroups?: ICommunityGroup[];
  handleChange: (ids: string[]) => void;
}
