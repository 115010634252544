import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { iconByDashboardItemType } from "../../../helpers/constants";
import Grid from "@material-ui/core/Grid/Grid";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: "flex",
      width: "100%",
      height: "80px",

      [theme.breakpoints.up("md")]: {
        width: "80%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "50%",
      },
    },
    eventIcon: {
      display: "flex",
      width: "40px",
      height: "40px",
      opacity: "0.85",
    },
    eventContainer: {},
    iconContainer: {
      display: "flex",
      position: "relative",
      alignItems: "center",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "x-large",
      whiteSpace: "nowrap",
      fontFamily: theme.typography.secondaryFontFamily,
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.27rem",
      },
      "@media (min-width:1600px)": {
        fontSize: "x-large",
      },
    },
    subTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "smaller",
      fontFamily: "GothamRoundedBook",
    },
  })
);

export default function UnitDashboardItem(props: IProps) {
  const { icon, line1, line2, textColor } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  // Get icon to display
  const iconD = iconByDashboardItemType(icon);

  return (
    <div className={`${classes.rootContainer}`}>
      <Grid container spacing={1} alignItems="center">
        <Grid className={classes.iconContainer} item xs={4}>
          {icon !== "" && (
            <React.Fragment>
              <img
                src={iconD}
                alt={t("alt_text_dashboard_icon", { status: icon })}
                draggable={false}
                className={classes.eventIcon}
              />
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={8} className={classes.eventContainer}>
          <Box color={textColor} className={classes.titleContainer}>
            {line1}
          </Box>
          <Box color={textColor} className={classes.subTitleContainer}>
            {line2}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

interface IProps {
  icon: string;
  line1: string;
  line2: string;
  textColor: string;
}
