import { IUnitActivityData } from "../features/dashboard/eventDataTypes";
import { IFailedRequest } from "./login.services";
import { request } from "./utils";
import { IResidentContact } from "./common.services";
import { IPhoneNumber, IResidentPhoneNumber } from "./header.services";

export const dashboardServices = {
  getUnits,
  getCommunityUnits,
  getCommunityEvents,
  getUnitEvents,
  markAllWarningsRead,
  assignEventResponder,
  resolveEvent,
  postNewComment,
  getUnitDashboard,
  getUnitActivityData,
  togglePauseNotifications,
  getEvent,
  toggleWatchedFlag,
};

export interface IOrgRole {
  community_id: string | null;
  all_units: boolean | null;
  email: string | null;
  name: string | null;
  user_id: string | null;
  time_created: string | null;
}

export interface IResident {
  first_name: string | null;
  id: string;
  last_name: string | null;
  city?: string | null;
  postal_code?: string | null;
  state?: string | null;
  street?: string | null;
  street2?: string | null;
  contacts?: IResidentContact[] | null;
  unit_id?: string | null;
  phone_numbers?: IResidentPhoneNumber[] | IPhoneNumber[] | null;
  date_of_birth?: string | null;
}

export interface IUnit {
  community_id: string | null;
  custom_name: string | null;
  id: string;
  is_notifications_paused: boolean;
  is_occupied: boolean | null;
  is_watched: boolean;
  last_motion_time: string | null;
  name: string;
  residents: IResident[] | null;
  site_id: string | null;
  unit_number: number;
  unit_type: string | null;
  time_updated: string;
  time_created?: string | null;
  gateway_id?: string | null;
  security_notification_enabled: boolean | null;
  pause_notifications_expiration: string | null;
  time_zone: string;
}

export interface IZone {
  id: string;
  unit_id: string;
  name: string | null;
  zone_type: string | null;
}

export interface IReportStatus {
  id: string;
  community_id: string;
  unit_id: string;
  report_date: string;
  report_type: string;
  status: string;
  time_created: string;
  time_queued?: string | null;
  time_started?: string | null;
  time_completed?: string | null;
  time_last_updated?: string | null;
}

export interface IReport {
  id: string;
  community_id: string;
  unit_id: string;
  report_date: string;
  report_type: string;
  report: string;
}

export interface IEventNotes {
  description: string | null;
  event_id: string;
  id: string;
  name: string | null;
  timestamp: string | null;
  type: string | null;
  user_id: string | null;
}

export interface IEventNotesData {
  description?: string;
  event_id: string;
}
export interface IWatchedFlagData {
  unitID: string;
  isWatched: boolean;
}

export interface IEvent {
  // Firebase retrieved
  firebaseKey?: string;
  event_type: string;
  id: string;
  unit_id: string;
  responder_id: string | null;
  zone_id: string | null;
  status: string;
  severity: string;
  time_created: string;
  data: string;
  notification_type: string | null;
  // API retrieved
  community_id?: string | null;
  email?: string;
  event_notes?: IEventNotes[];
  event_resolution_id?: string | null;
  event_status?: null;
  first_name: string;
  last_name?: string | null;
  level?: number | null;
  read_status: string | null;
  time_assigned?: string | null;
  time_resolved?: string | null;
  system_issues?: boolean | null;
  is_delayed?: boolean | null;
}
export interface IAssignEventData {
  eventID: string;
  responderID: string;
}

export interface IResolveEventData {
  eventID: string;
  resolutionID: string;
}
export interface ITogglePauseNotificationData {
  unitId: string;
  eventId: string;
  enabled: boolean;
}
export interface IToggleResponseData {
  unit: IUnit;
  event: IEvent;
}
export interface IUnitDashboard {
  motion_data: {
    zone_name: string | null;
    last_motion_time: string | null;
  } | null;
  bathroom_data: {
    category: string | null;
    visits: number;
  } | null;
  heat_index_data: {
    category: string | null;
    celsius: number;
  } | null;
  sleep_data: {
    duration: number;
  } | null;
  help_data: {
    events: string[];
    help_button_press_time: string;
  } | null;
  kitchen_data: {
    last_motion_event_time: string | null;
  } | null;
  local_time_updated: string | null;
}

export interface SystemStatus {
  planned_maintenance: {
    end_time: string;
    start_time: string;
    maintenance_type: string;
  };
  status: string;
}
/**
 * Loads the units given a community id
 * @param {string} community_id Community id to retrieve units *
 * @returns {IUnit[]} An array containing the communities linked to the user
 */

async function getUnits(community_id: string): Promise<IUnit[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/units?community_id=${community_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUnit[]);
  }
}

/**
 * Loads the units for a user's communities
 * @returns {IUnit[]} An array containing the communities linked to the user
 */

async function getCommunityUnits(): Promise<IUnit[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(`/units?all_communities=1`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUnit[]);
  }
}

/**
 * Loads the events for the community units
 * @param {string} community_id Community id to retrieve units *
 * @returns {IUnit[]} An array containing the communities linked to the user
 */

async function getCommunityEvents(community_id: string): Promise<IEvent[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/events?community_id=${community_id}&status=unassigned,assigned&order=desc`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEvent[]);
  }
}

/**
 * Events for a single unit
 */
async function getUnitEvents(unitId: string): Promise<IEvent[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/events?unit_id=${unitId}&status=unassigned,assigned,resolved&order=desc`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEvent[]);
  }
}

/**
 * Get a specific event
 * @param {string} event_id event id
 * @returns {IEvent} The event
 */

async function getEvent(event_id: string): Promise<IEvent> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/events/${event_id}?mark_as_read=1`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEvent);
  }
}

/**
 * Marks all warnings as read
 * @param {string} community_id Community ID
 * @returns {boolean} Indicates the succesful completion of the API call
 */

async function markAllWarningsRead(community_id: string): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      notification_type: "soft",
    }),
  };

  const data = await request(
    `/event_status/mark_unread?community_id=${community_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(true);
  }
}

/**
 * Assigns an event to a responder
 * @param {string} community_id Community ID
 * @returns {IEvent} The updated event
 */

async function assignEventResponder(
  assignData: IAssignEventData
): Promise<IEvent> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      responder_id: assignData.responderID,
    }),
  };

  const data = await request(
    `/events/${assignData.eventID}/assign`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEvent);
  }
}

/**
 * Add a new comment on an event
 * @param {string} event_id event id
 * @param {string} description the content of the comment
 * @returns {IEventNotes} The updated event with the new comment
 */

async function postNewComment(
  eventNotesData: IEventNotesData
): Promise<IEventNotes> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      event_id: eventNotesData.event_id,
      description: eventNotesData.description,
    }),
  };

  const data = await request(`/event_notes`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * Resolve an event with a resolution id
 * @param {IResolveEventData} resolveData Event id and resolution id
 * @returns {IEvent} The updated event
 */

async function resolveEvent(resolveData: IResolveEventData): Promise<IEvent> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      event_resolution_id: resolveData.resolutionID,
    }),
  };

  const data = await request(
    `/events/${resolveData.eventID}/resolve`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEvent);
  }
}

/**
 * Resume or "un-pause" notifications for a unit
 * @param {ITogglePauseNotificationData} toggleData unit id and wether notifications should be enabled
 * @returns {IUnit} The updated event
 */

async function togglePauseNotifications(
  toggleData: ITogglePauseNotificationData
): Promise<IUnit> {
  const ans: { [key: string]: any } = {};
  ans["is_notifications_paused"] = toggleData.enabled;
  if (!toggleData.enabled) {
    ans["pause_notifications_expiration"] = null;
  }
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify(ans),
  };

  const data = await request(`/units/${toggleData.unitId}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUnit);
  }
}

/**
 * Load the unit status or "dashboard"
 * @param {string} unit_id unit id
 * @returns {IUnitDashboard} The status
 */

async function getUnitDashboard(unit_id: string): Promise<IUnitDashboard> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(`/units/${unit_id}/dashboard`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUnitDashboard);
  }
}
/**
 * Load the unit activity data
 * @param {string} unit_id unit id
 * @returns {IUnitActivityData} The status
 */

async function getUnitActivityData(
  unit_id: string,
  startTime: string,
  endTime: string
): Promise<IUnitActivityData> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/units/${unit_id}/activity_data?start_time=${startTime}&end_time=${endTime}&version=2&contact_count=1`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUnitActivityData);
  }
}

/**
 * Toggles the watched parameter for units
 * @param {string} unitID unit id
 * @param {is_watched} isWatched indicated if unit is being watched or not
 * @returns {IUnit} The status
 */

async function toggleWatchedFlag(
  watchedFlagData: IWatchedFlagData
): Promise<IUnit> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      is_watched: watchedFlagData.isWatched,
    }),
  };

  const data = await request(
    `/units/${watchedFlagData.unitID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUnit);
  }
}
