import { Grid, ListItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  convertTimeHourMinute,
  DateTimeNowHourMinute,
} from "../../helpers/datetime";
import { GreenCheckbox } from "../notifications/filters/GreenCheckBox";
import { EventConfigLabel } from "./common/EventConfigLabel";
import { getFormatWithMinutes } from "./EventConfigForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unitItem: {
      display: "flex",
      backgroundColor: theme.palette.light.light,
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(1, 1.5),
      borderRadius: "5px",
      border: `1px solid #D6D6D6`,
      paddingRight: 30,
      alignItems: "flex-start",
    },
    columnRow: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(2),
    },
    columnRowMultiple: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(4),
    },
    titleContainer: {
      display: "flex",
      gap: theme.spacing(2),
      fontSize: theme.typography.body1.fontSize,
      alignItems: "center",
      overflow: "hidden",
    },
    secondaryText: {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      flex: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    columnRowMultipleItem: {
      display: "flex",
      gap: theme.spacing(2),
    },
    enabledCheckbox: {
      paddingRight: theme.spacing(1),
      padding: 0,
    },
    firstSection: {
      flexBasis: "30%",
      fontWeight: "bold",
      display: "inline-flex",
      minWidth: 0,
    },
    formContainer: {
      display: "flex",
      flexBasis: "70%",
      "&>*": {
        alignItems: "center",
      },
    },
    gridItem: {
      display: "flex",
      alignItems: "flex-start",
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("lg")]: {
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.up("lg")]: {
        height: "100%",
        paddingBottom: 0,
      },
    },
    column: {
      display: "flex",
      flexDirection: "column",
      "&>*": {
        paddingBottom: theme.spacing(1),
      },
      "&>:last-child": {
        paddingBottom: 0,
      },
    },
    listRoot: {
      "&selected:hover": {
        backgroundColor: theme.palette.primary.light,
        color: "inherit",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: "inherit",
      },
    },
  })
);

export const UnitConfigItem = (props: IProps) => {
  const {
    id,
    index,
    expanded,
    primaryText,
    secondaryText,
    selected,
    isEnabled,
    hasCount,
    hasDuration,
    hasTime,
    hasTrigger,
    durationVal,
    countVal,
    allDayVal,
    startTimeVal,
    endTimeVal,
    triggerVal,
    timeFormat,
    toggleSelected,
    setSize,
  } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* Refs */
  const rowRef = useRef<HTMLDivElement | null>(null);

  /* Effects */
  //Store size of element for variable size list and windowing
  useEffect(() => {
    if (rowRef.current === null) return;
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [setSize, index]);

  const allDayStartDateTime = DateTimeNowHourMinute("00:00");
  const allDayEndDateTime = DateTimeNowHourMinute("0:00");
  let expandedProps = {};
  if (expanded)
    expandedProps = {
      ...expandedProps,
      button: true,
      onClick: () => toggleSelected(id),
    };
  return (
    <ListItem
      id={id}
      classes={{ root: classes.listRoot }}
      className={classes.unitItem}
      ref={rowRef}
      component={"div"}
      {...expandedProps}
    >
      <span className={classes.firstSection}>
        {expanded && (
          <GreenCheckbox
            readOnly={true}
            checked={selected}
            tabIndex={-1}
            disableRipple
            classes={{ root: classes.enabledCheckbox }}
          />
        )}
        <span className={classes.titleContainer}>
          <span>{primaryText}</span>
          <span className={classes.secondaryText}>{secondaryText}</span>
        </span>
      </span>
      <div className={classes.formContainer}>
        <Grid container>
          <Grid item xs={10} lg={5} className={classes.gridItem}>
            <EventConfigLabel
              expanded={false}
              highlightPrimary={isEnabled}
              label={t("notification").toUpperCase()}
              value={isEnabled ? t("enabled") : t("disabled")}
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <div className={classes.column}>
              {hasDuration && durationVal !== undefined && (
                <div className={classes.columnRow}>
                  <EventConfigLabel
                    expanded={false}
                    label={t("period").toUpperCase()}
                    value={
                      durationVal ? getFormatWithMinutes(durationVal) : "-"
                    }
                  />
                </div>
              )}
              {hasCount && countVal !== undefined && (
                <div className={classes.columnRow}>
                  <EventConfigLabel
                    expanded={false}
                    label={t("count").toUpperCase()}
                    value={countVal ? `${countVal}` : "-"}
                  />
                </div>
              )}
              {hasTime && (
                <>
                  <div className={classes.columnRow}>
                    <EventConfigLabel
                      expanded={false}
                      label={t("all_day").toUpperCase()}
                      highlightPrimary={!!allDayVal}
                      value={allDayVal ? t("enabled") : t("disabled")}
                    />
                  </div>
                  <div className={classes.columnRowMultiple}>
                    <div className={classes.columnRowMultipleItem}>
                      <EventConfigLabel
                        expanded={false}
                        highlightPrimary={allDayVal ? !allDayVal : undefined}
                        label={t("start_time").toUpperCase()}
                        value={
                          startTimeVal !== undefined
                            ? convertTimeHourMinute(
                                allDayVal
                                  ? allDayStartDateTime
                                  : DateTimeNowHourMinute(startTimeVal),
                                timeFormat
                              ) ?? ""
                            : "-"
                        }
                      />
                    </div>

                    <div className={classes.columnRowMultipleItem}>
                      <EventConfigLabel
                        expanded={false}
                        highlightPrimary={allDayVal ? !allDayVal : undefined}
                        label={t("end_time").toUpperCase()}
                        value={
                          endTimeVal !== undefined
                            ? convertTimeHourMinute(
                                allDayVal
                                  ? allDayEndDateTime
                                  : DateTimeNowHourMinute(endTimeVal),
                                timeFormat
                              ) ?? ""
                            : "-"
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {hasTrigger && triggerVal !== undefined && (
                <div className={classes.columnRow}>
                  <EventConfigLabel
                    expanded={false}
                    label={t("trigger_time").toUpperCase()}
                    value={
                      triggerVal !== undefined
                        ? convertTimeHourMinute(
                            DateTimeNowHourMinute(triggerVal),
                            timeFormat
                          ) ?? ""
                        : "-"
                    }
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </ListItem>
  );
};

interface IProps {
  id: string;
  index: number;
  expanded: boolean;
  primaryText: string;
  secondaryText: string;
  selected: boolean;
  isEnabled: boolean;
  hasDuration: boolean;
  hasCount: boolean;
  hasTime: boolean;
  hasTrigger: boolean;
  durationVal?: number;
  countVal?: number;
  allDayVal?: boolean;
  startTimeVal?: string;
  endTimeVal?: string;
  triggerVal?: string;
  timeFormat: string;
  toggleSelected: (optionID: string) => void;
  setSize: (index: number, size: number) => void;
}
