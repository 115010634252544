import { useEffect, useState } from "react";
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { DragStartEvent, DragEndEvent } from "@dnd-kit/core/dist";

import { DraggableItemsContainer, Droppable } from "./Droppable";
import { ResidentContactItem } from "./ResidentContactItem";
import { arrayMove as dndKitArrayMove } from "@dnd-kit/sortable";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/appHooks";
import { dragStateSignature, EmergencyDragState } from "../settingsSlice";
import {
  addEmergencyContact,
  deleteEmergencyContact,
  updateEmergencyContactsPosition,
} from "../settingsThunks";
import { AddEmergencyContact } from "../../../services/common.services";

export const arrayMove = <T,>(
  array: T[],
  oldIndex: number,
  newIndex: number
) => {
  return dndKitArrayMove(array, oldIndex, newIndex);
};

export const EmergencyContactTable = (props: Props) => {
  const { residentID } = props;

  /* Hooks */
  // Set activation constrain so it allows to click delete button
  const activationConstraint = { distance: 15 };
  const mouseSensor = useSensor(MouseSensor, { activationConstraint });

  const sensors = useSensors(mouseSensor, useSensor(TouchSensor));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const allContactsList = useAppSelector(
    (state) => state.settingsState.emergencyContactsState
  );

  const emergencyDragState = useAppSelector(
    (state) => state.settingsState.emergencyDragState
  );

  /* State */
  const [itemGroups, setItemGroups] = useState<EmergencyDragState>({
    emergency_contacts: [],
    all_contacts: [],
  });
  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    if (emergencyDragState) {
      setItemGroups(emergencyDragState);
    }
  }, [emergencyDragState]);

  /* Drag and drop methods */
  const handleDragStart = (event: DragStartEvent) =>
    setActiveId(event.active.id as string);

  const handleDragCancel = () => setActiveId(null);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over === null) {
      setActiveId(null);
      if (
        active &&
        active.data.current?.sortable.containerId === "emergency_contacts"
      ) {
        dispatch(deleteEmergencyContact(active.id as string));
      }
      return;
    }

    const activeContainer: dragStateSignature | undefined =
      active.data.current?.sortable.containerId;
    const overContainer: dragStateSignature | undefined =
      over.data.current?.sortable.containerId || over.id;
    const activeIndex = active.data.current?.sortable.index;
    const overIndex: number =
      over.id in itemGroups
        ? itemGroups[overContainer as dragStateSignature].length
        : over.data.current?.sortable.index;

    if (
      activeContainer === overContainer &&
      overContainer !== undefined &&
      activeIndex !== overIndex
    ) {
      //  Re order emergency contacts
      const newOrder = arrayMove(
        itemGroups[overContainer as dragStateSignature],
        activeIndex,
        overIndex
      );
      let emergency_contact_ids: string[] = [];
      let positions: number[] = [];

      newOrder.forEach((item: string, index) => {
        emergency_contact_ids.push(item);
        positions.push(index);
      });

      dispatch(
        updateEmergencyContactsPosition({
          resident_id: residentID,
          emergency_contact_ids,
          positions,
        })
      );

      // Update local state
      setItemGroups((itemGroups) => {
        return {
          ...itemGroups,
          [overContainer]: arrayMove(
            itemGroups[overContainer as dragStateSignature],
            activeIndex,
            overIndex
          ),
        };
      });
    }

    // If a new item is being dragged
    if (activeContainer !== overContainer && overContainer !== undefined) {
      // Find item being dragged
      const itemToAdd = allContactsList?.find((item) => item.id === active.id);
      if (itemToAdd) {
        let addEmergencyData: AddEmergencyContact = {
          resident_id: itemToAdd.resident_id ?? residentID,
          position: itemGroups[overContainer as dragStateSignature].length,
        };
        // Determines if is user or contact depending on the properties
        if (itemToAdd.contact_type) {
          addEmergencyData["contact_id"] = itemToAdd.id;
        } else {
          addEmergencyData["user_id"] = itemToAdd.id;
        }
        dispatch(addEmergencyContact(addEmergencyData));
      }
    }
    setActiveId(null);
  };

  const handleRemoveEmergencyContact = (emergencyID: string) => {
    dispatch(deleteEmergencyContact(emergencyID));
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
    >
      <div className="container">
        <Droppable
          id={"emergency_contacts"}
          items={itemGroups["emergency_contacts"]}
          auxText={t("emergency_help_text")}
          removeItem={handleRemoveEmergencyContact}
        />
        <DraggableItemsContainer
          items={itemGroups["all_contacts"]}
          auxText={t("emergency_all_contacts")}
        />
      </div>
      <DragOverlay>
        {activeId ? (
          <ResidentContactItem id={activeId as string} dragOverlay />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

interface Props {
  residentID: string;
}
