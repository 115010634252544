import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { useAppSelector } from "../app/appHooks";
import { UnitTypes } from "../../helpers/constants";
import { DeviceList } from "./DeviceList";
import { GatewayList } from "./GatewayList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noUnitSelected: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
  })
);

export function DevicesViewer() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* Selectors */
  const selectedUnit = useAppSelector((state) =>
    state.headerState.units?.find(
      (unit) => unit.id === state.devicesState.selectedUnit
    )
  );

  const devices = useAppSelector((state) => state.devicesState.devices);
  const gateways = useAppSelector((state) => state.devicesState.gateways);

  const allHelpAtHome = useAppSelector((state) =>
    state.headerState.units?.every(
      (unit) => unit.unit_type === UnitTypes.HELP_AT_HOME
    )
  );

  return (
    <>
      <h1 hidden>Devices viewer</h1>
      {selectedUnit !== undefined ? (
        <>
          <DeviceList selectedUnit={selectedUnit} devices={devices} />
          <GatewayList selectedUnit={selectedUnit} gateways={gateways} />
        </>
      ) : (
        <div className={classes.noUnitSelected}>
          <h3>{allHelpAtHome ? t("select_resident") : t("select_unit")}</h3>
        </div>
      )}
    </>
  );
}
