import React, { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { debounce } from "@material-ui/core";
import { useAppSelector } from "../../app/appHooks";
import clsx from "clsx";
import { isSafari } from "react-device-detect";

const DEFAULT_CHART_PROPORTION = 0.35;

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    analyticsChartContainerMinimized: {
      maxHeight: (props) =>
        props.isPrintable ? (isSafari ? "510px" : "610px") : "300px",
      minHeight: (props) =>
        props.isPrintable ? (isSafari ? "445px" : "545px") : "235px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0.5, 2),
      overflowY: "auto",
      "@media (min-height: 1000px)": {
        maxHeight: "initial",
      },
    },
    analyticsChartContainerMaximized: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0.5, 2),
    },
    [`@media print`]: {
      analyticsChartContainerMinimized: {
        overflow: "hidden",
      },
    },
  })
);

const getChartHeight = (
  maximized: boolean,
  wrapperRef: React.RefObject<HTMLDivElement>,
  proportion?: number,
  viewerHeight?: number
) => {
  let chartHeight = 200;
  if (viewerHeight) {
    if (proportion && !maximized) {
      chartHeight = viewerHeight * proportion;
    } else if (
      wrapperRef !== null &&
      wrapperRef.current !== null &&
      wrapperRef.current.parentElement
    ) {
      const parentNode = wrapperRef.current.parentNode;
      let height = 0;
      if (parentNode !== null) {
        height = Array.from(parentNode.children).reduce(
          (result: number, element) => {
            if (!element.isSameNode(wrapperRef.current)) {
              result += element.getBoundingClientRect().height;
            }
            return result;
          },
          0
        );
      }
      chartHeight =
        wrapperRef.current.parentElement.getBoundingClientRect().height -
        height -
        12;
    } else {
      if (maximized) {
        // viewer height - lastUpdatedSize - unitTitle size - containerFullHeight padding - chartHeader size - selfContainer padding
        chartHeight = viewerHeight - 28 - 39 - 8 - 61 - 8;
      } else {
        chartHeight = viewerHeight * DEFAULT_CHART_PROPORTION;
      }
    }
  }

  return chartHeight;
};

export default function AnalyticsChartContainer(props: IProps) {
  const { children, proportion, disableHeightOnMax } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const viewContainerHeight = useAppSelector(
    (state) => state.analyticsState.chartCardHeight
  );
  /* Hooks */
  const classes = useStyles(props);

  /* State */
  const [chartHeight, setChartHeight] = useState(0);

  /* Selectors  */
  const maximized = useAppSelector(
    (state) => !!state.analyticsState.maximizeChart
  );

  /* Effects */
  // Subscribe resize listener to handle window height resize
  useEffect(() => {
    function handleResize() {
      const chartHeight = getChartHeight(
        maximized,
        wrapperRef,
        proportion,
        viewContainerHeight?.viewerHeight
      );
      setChartHeight(chartHeight);
    }
    const debouncedHandleResize = debounce(handleResize, 1000);

    handleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [maximized, proportion, viewContainerHeight]);

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        !maximized && classes.analyticsChartContainerMinimized,
        maximized && classes.analyticsChartContainerMaximized
      )}
      style={{
        height: maximized && disableHeightOnMax ? "unset" : chartHeight,
        minHeight: props.height ? props.height : "",
      }}
    >
      {children}
    </div>
  );
}

interface IProps {
  children?: React.ReactNode;
  height?: string;
  proportion?: number;
  disableHeightOnMax?: boolean;
  isPrintable: boolean;
}
