import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Grid from "@material-ui/core/Grid/Grid";
import { Box, Button, Theme } from "@material-ui/core";
import { DateTimeLongFormat } from "../../helpers/datetime";
import YesNoDialog from "../common/YesNoDialog";
import { EventStatus, Views } from "../../helpers/constants";
import { IEvent } from "../../services/dashboard.services";
import { useLocation } from "react-router-dom";
import { toggleUnitPaused } from "../app/asyncThunkActions";
import { useAppSelector } from "../app/appHooks";
import { getCombinedDisplayName } from "../settings/common/helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      minWidth: "115px",
    },
    buttonUnassigned: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.light.light,
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    buttonAssigned: {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
      },
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("lg")]: {
        alignItems: "flex-start",
      },
    },
    popoverAssign: {
      border: `2px solid ${theme.palette.error.main}`,
      overflowY: "hidden",
      backgroundColor: theme.palette.error.main,
      padding: "0.1px",
    },
    popoverResolve: {
      border: `2px solid ${theme.palette.primary.main}`,
      overflowY: "hidden",
      backgroundColor: theme.palette.primary.main,
      padding: "0.1px",
    },
  })
);

export default function EventWorkflow(props: IProps) {
  const { event, layout } = props;
  /* ---- State ---- */
  const [dialogToggle, setDialogToggle] = useState(false);

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  /* ---- Selectors ---- */
  // Obtains the event unit
  const eventUnit = useAppSelector((state) => {
    const source =
      location.pathname === Views.DASHBOARD ? "dashboardState" : "headerState";
    const unit = state[source].units?.find(
      (unit) => unit.id === event?.unit_id
    );
    return unit;
  });
  const users = useAppSelector((state) => state.headerState.users);

  let unitDisplayName = getCombinedDisplayName(eventUnit);

  /* ---- Methods ---- */
  const handleOpenDropdown = (event: any) => {
    setDialogToggle(true);
  };

  const handleCloseDropdown = () => {
    setDialogToggle(false);
  };

  const responderUser = users?.find((user) => user.id === event?.responder_id);
  const responderEmail =
    responderUser !== undefined ? responderUser.email : "-";

  let timeResolved = "-";
  if (
    event &&
    event.time_resolved !== undefined &&
    event.time_resolved !== null
  ) {
    timeResolved = DateTimeLongFormat(event.time_resolved);
  }

  const isResolved = event && event.status === EventStatus.RESOLVED;

  const isDashboard = layout === Views.DASHBOARD;
  const isNotifications = layout === Views.NOTIFICATIONS;

  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} lg={6} xl={7} className={classes.detailsContainer}>
          <Box>{t("paused_by").toUpperCase()}</Box>
          <Box>{responderEmail}</Box>
        </Grid>
        <Grid item xs={12} lg={6} xl={5} className={classes.buttonContainer}>
          <Button
            id="resolve-button"
            classes={{ root: classes.buttonRoot }}
            color={isResolved ? "default" : "primary"}
            onClick={handleOpenDropdown}
            variant={isResolved ? "outlined" : "contained"}
            disabled={isResolved}
          >
            <Box fontWeight="fontWeightBold">{t("resume")}</Box>
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} xl={7} className={classes.detailsContainer}>
          <Box>{t("resumed").toUpperCase()}</Box>
          <Box>{timeResolved}</Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          xl={5}
          className={classes.buttonContainer}
        ></Grid>
      </Grid>
      <YesNoDialog
        toggle={dialogToggle}
        title={t("resume_stackcare_for", { unit: unitDisplayName })}
        message={undefined}
        possitiveText={"resume"}
        negativeText={"cancel"}
        positiveAction={() => {
          dispatch(
            toggleUnitPaused({
              togglePausedData: {
                eventId: event?.id || "",
                unitId: event?.unit_id || "",
                enabled: false,
              },
              fromDashboard: isDashboard,
              fromNotifications: isNotifications,
            })
          );
          return;
        }}
        negativeAction={() => {
          return;
        }}
        onClose={handleCloseDropdown}
      />
    </React.Fragment>
  );
}

interface IProps {
  event?: IEvent;
  layout: string;
}
