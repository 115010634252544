import React from "react";
import { useTranslation } from "react-i18next";
import MuiListItem from "@material-ui/core/ListItem";
import {
  EventStatus,
  RoleTypes,
  sortAlphabetical,
} from "../../../helpers/constants";
import {
  Box,
  Divider,
  List,
  ListItemText,
  Theme,
  withStyles,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { IUserDetails } from "../../../services/header.services";
import Grid from "@material-ui/core/Grid/Grid";
import { useDispatch } from "react-redux";
import { assignEventResponder } from "../../app/asyncThunkActions";
import clsx from "clsx";
import { getRoleLabel } from "../../settings/settingsHelpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBaseRoot: {},
    popoverContainer: {
      maxWidth: "600px",
      minWidth: "420px",
      maxHeight: "400px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    responderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    listHeader: {
      backgroundColor: theme.palette.light.light,
    },
    popoverTitleAssign: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.error.main,
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2, 0),
    },
    popoverTitleReassign: {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.light.light,
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2, 0),
      borderBottom: `2px solid ${theme.palette.error.main}`,
    },
    bold: {
      fontWeight: "bolder",
    },
    itemPadding: {
      padding: theme.spacing(0, 1),
    },
    dividerRoot: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

// Custom ListItem for assign list
const ListItem = withStyles(({ palette }) => ({
  root: {
    "&$selected": {
      backgroundColor: palette.light.light,
      color: palette.primary.main,
      fontWeight: "bold",
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
      color: palette.light.light,
    },
    "&:hover": {
      backgroundColor: palette.primary.main,
      color: palette.light.light,
      borderRadius: "0px",
    },
  },
  selected: {},
}))(MuiListItem);

const ListItemTextAssign = (props: { selected: boolean; text: string }) => {
  const classes = useStyles();
  return (
    <ListItemText
      primaryTypographyProps={{
        classes: {
          root: clsx(props.selected && classes.bold),
        },
        noWrap: true,
      }}
      primary={props.text}
    />
  );
};

const AsignItemRow = (props: {
  id: string;
  selected: boolean;
  userName: string;
  userEmail: string;
  userRole: string;
  className?: React.ComponentProps<typeof ListItem>["className"];
  onClick: React.ComponentProps<typeof ListItem>["onClick"];
}) => {
  const {
    id,
    userName,
    userEmail,
    userRole,
    selected,
    className,
    onClick,
  } = props;
  return (
    <ListItem
      id={id}
      button
      className={className}
      selected={selected}
      onClick={onClick}
    >
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <ListItemTextAssign selected={selected} text={userName} />
        </Grid>

        <Grid item xs={5}>
          <ListItemTextAssign selected={selected} text={userEmail} />
        </Grid>

        <Grid item xs={3}>
          <ListItemTextAssign selected={selected} text={userRole} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

const getValidResponders = (
  unorderedUsers: IUserDetails[] | undefined,
  firstUser: IUserDetails | undefined
): { users: IUserDetails[]; familyUsers: IUserDetails[] } => {
  if (unorderedUsers === undefined) return { users: [], familyUsers: [] };
  const filteredUsers = unorderedUsers.filter(
    (user) => user.id !== firstUser?.id
  );
  const users = filteredUsers
    .filter(
      (user) =>
        user.role.name === RoleTypes.admin ||
        user.role.name === RoleTypes.caregiver
    )
    .sort((a, b) => sortAlphabetical(a.first_name, b.first_name));

  const familyUsers = filteredUsers
    .filter((user) => user.role.name === RoleTypes.pro_family)
    .sort((a, b) => sortAlphabetical(a.first_name, b.first_name));

  return { users, familyUsers };
};

export const AssignResponderList = (props: IProps) => {
  const {
    eventID,
    users,
    currentUser,
    eventStatus,
    responder,
    fromDashboard,
    fromNotifications,
    onClose,
  } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ---- Methods ---- */
  const handleAssignResponder = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      event.currentTarget?.id !== undefined &&
      event.currentTarget.id !== responder &&
      eventID !== undefined
    ) {
      const assignData = {
        assignData: { eventID, responderID: event.currentTarget.id },
        fromDashboard: fromDashboard,
        fromNotifications: fromNotifications,
      };
      dispatch(assignEventResponder(assignData));
      onClose();
    }
  };

  // If event is assigned, get user to put it first on the list
  const firstUser = users?.find((user) => user.id === currentUser?.id);

  const firstUserRoleName = getRoleLabel(firstUser?.role?.name);

  // Filter out first user and order alphabetically
  const usersList = getValidResponders(users, firstUser);

  const listTitle =
    eventStatus === EventStatus.UNASSIGNED
      ? `${t("assign_to")}:`
      : `${t("reassign_to")}:`;

  return (
    <React.Fragment>
      <Box
        className={
          eventStatus === EventStatus.UNASSIGNED
            ? classes.popoverTitleAssign
            : classes.popoverTitleReassign
        }
        fontWeight="fontWeightBold"
        fontSize="large"
      >
        {listTitle}
      </Box>
      <List aria-label="responder list" className={classes.popoverContainer}>
        {firstUser && (
          <AsignItemRow
            id={firstUser.id}
            key={firstUser.id}
            className={classes.buttonBaseRoot}
            userName={`${firstUser.first_name} ${firstUser.last_name}`}
            userEmail={firstUser.email}
            userRole={
              firstUserRoleName ? t(firstUserRoleName) : firstUserRoleName
            }
            selected={firstUser?.id === responder}
            onClick={handleAssignResponder}
          />
        )}

        {usersList.users.length !== 0 && (
          <>
            <Divider variant="middle" classes={{ root: classes.dividerRoot }} />
            {usersList.users.map((user) => {
              const roleName = getRoleLabel(user?.role?.name);
              return (
                <AsignItemRow
                  key={user.id}
                  id={user.id}
                  userName={`${user.first_name} ${user.last_name}`}
                  userEmail={user.email}
                  userRole={roleName ? t(roleName) : roleName}
                  selected={user.id === responder}
                  onClick={handleAssignResponder}
                />
              );
            })}
          </>
        )}

        {usersList.familyUsers.length !== 0 && (
          <>
            <Divider variant="middle" classes={{ root: classes.dividerRoot }} />

            {usersList.familyUsers.map((user) => {
              const roleName = getRoleLabel(user?.role?.name);
              return (
                <AsignItemRow
                  key={user.id}
                  id={user.id}
                  userName={`${user.first_name} ${user.last_name}`}
                  userEmail={user.email}
                  userRole={roleName ? t(roleName) : roleName}
                  selected={user.id === responder}
                  onClick={handleAssignResponder}
                />
              );
            })}
          </>
        )}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  eventID?: string;
  users?: IUserDetails[];
  currentUser?: IUserDetails;
  responder?: string | null;
  eventStatus: EventStatus;
  fromDashboard: boolean;
  fromNotifications: boolean;
  onClose: () => void;
}
