import "chartjs-adapter-luxon";
import zoomPlugin from "chartjs-plugin-zoom";
import { DateTime } from "luxon";
import { Line, defaults, Chart } from "react-chartjs-2";

import { graphColorPicker } from "../../helpers/constants";
import {
  DateFromIsoString,
  HourFormat,
  HourMinuteFormat,
  timeConfigurations,
} from "../../helpers/datetime";
import useZoom from "./useZoom";
import usePan from "./usePan";

Chart.register(zoomPlugin); // REGISTER PLUGIN
defaults.font.family = "GothamRoundedBook";

type TimeBoundaries = {
  maxTime?: DateTime;
  minTime?: DateTime;
};

// Returns the values which the chart should round up if the time difference is less than 2 hours or 30 minutes
export const getRemainderToRound = (timeDiffHours: number) => {
  if (timeDiffHours < 0.5) return 5;
  if (timeDiffHours < 2) return 15;
  if (timeDiffHours >= 2 && timeDiffHours < 8) return 30;
  return undefined;
};

export const timeDiffInHours = (maxTime: DateTime, minTime: DateTime) =>
  maxTime.diff(minTime, ["hours"]).as("hours");

export const getDateTimeFormat = (
  { maxTime, minTime }: TimeBoundaries,
  useDefaultTimeConfig: boolean | undefined
) => {
  const defaultTimeConfig = {
    unit: "hour",
    displayFormats: { hour: HourFormat() },
    stepSize: 1,
  } as any;
  if (useDefaultTimeConfig) {
    return defaultTimeConfig;
  }

  if (minTime !== undefined && maxTime !== undefined) {
    const rangeHourDifference = timeDiffInHours(maxTime, minTime);
    if (rangeHourDifference < 0.5) {
      defaultTimeConfig.unit = "minutes";
      defaultTimeConfig.stepSize = 5;
      defaultTimeConfig.displayFormats = {
        minutes: HourMinuteFormat(),
      };
    } else if (rangeHourDifference < 2) {
      defaultTimeConfig.unit = "minutes";
      defaultTimeConfig.stepSize = 15;
      defaultTimeConfig.displayFormats = {
        minutes: HourMinuteFormat(),
      };
    } else if (rangeHourDifference >= 2 && rangeHourDifference < 8) {
      defaultTimeConfig.unit = "minutes";
      defaultTimeConfig.stepSize = 30;
      defaultTimeConfig.displayFormats = {
        minutes: HourMinuteFormat(),
      };
    } else if (rangeHourDifference >= 8 && rangeHourDifference < 24) {
      defaultTimeConfig.stepSize = 2;
    } else if (rangeHourDifference >= 24) {
      defaultTimeConfig.stepSize = 4;
      defaultTimeConfig.displayFormats = {
        hour: `EEE ${defaultTimeConfig.displayFormats.hour}`,
      };
    }
    return defaultTimeConfig;
  } else {
    return defaultTimeConfig;
  }
};

export default function LineChart(props: IProps) {
  const {
    min,
    max,
    labels,
    values,
    minTime,
    maxTime,
    animation,
    communityUnitTimezone,
    zoomEnabled,
    panEnabled,
    legendEnabled,
    maxChartLimit,
    minChartLimit,
    useDefaultTimeConfig,
  } = props;

  const { timeZone, locale } = timeConfigurations(communityUnitTimezone);

  const dateConfigAxis1 = getDateTimeFormat(
    {
      maxTime,
      minTime,
    },
    useDefaultTimeConfig
  );
  const dateConfigAxis2 = {
    unit: "hour",
    displayFormats: { hour: "LLL d" },
    stepSize: 4,
  };

  const showDays =
    minTime && maxTime ? timeDiffInHours(maxTime, minTime) >= 24 : false;

  const maxLimit =
    maxChartLimit !== undefined
      ? DateFromIsoString(maxChartLimit, timeZone).toMillis()
      : undefined;

  const minLimit =
    minChartLimit !== undefined
      ? DateFromIsoString(minChartLimit, timeZone).toMillis()
      : undefined;

  const zoomOptions = useZoom(zoomEnabled);

  const panOptions = usePan("x", false, panEnabled);

  const options = {
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    responsive: true,
    animation: animation !== undefined ? animation : true,
    spanGaps: true,
    format: {
      zone: "America/Denver",
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: false },
      zoom: {
        limits: {
          x: { min: minLimit, max: maxLimit },
        },
        zoom: zoomOptions,
        pan: panOptions,
        pinch: {
          enabled: !!zoomEnabled,
        },
      },
      title: {
        display: false,
      },
      legend: {
        display: !!legendEnabled,
        position: "bottom",
        fullSize: false,
        labels: {
          boxWidth: 43.5,
          boxHeight: 1,
          padding: 20,
        },
      },
    },
    scales: {
      x: {
        type: "time",
        min: minTime ? minTime.toMillis() : undefined,
        max: maxTime ? maxTime.toMillis() : undefined,
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        time: dateConfigAxis1,
        tick: {
          major: {
            enabled: true,
          },
        },
      },
      x2: {
        type: "time",
        min: minTime ? minTime.toMillis() : undefined,
        max: maxTime ? maxTime.toMillis() : undefined,
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        time: dateConfigAxis1,
        ticks: {
          display: false,
        },
        grid: {
          borderDash: [8, 4],
          drawBorder: false,
          drawTicks: false,
          offset: true,
        },
      },
      y: {
        min: min,
        max: max,
        ticks: {
          stepSize: 5,
        },
      },
      x1: {
        display: showDays,
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        min: minTime?.toISO(),
        max: maxTime?.toISO(),
        time: dateConfigAxis2,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: (value: string, index: number, values: any[]) => {
            return index === 2 ? value : "";
          },
        },
      },
    },
  };

  const datasetsArray = values.map((value, index) => {
    const { borderColor, dashlineStyle } = graphColorPicker(index);
    return {
      label: `${labels[index]}`,
      data: value,
      fill: false,
      borderWidth: 4,
      borderColor: borderColor,
      backgroundColor: "white",
      borderDash: dashlineStyle,
      pointRadius: 0,
    };
  });
  const data = {
    datasets: datasetsArray,
  };

  return (
    <Line type="line" data={data} options={options} width={100} height={40} />
  );
}

interface IProps {
  min: number;
  max: number;
  minTime?: DateTime;
  maxTime?: DateTime;
  labels: any[];
  values: any[];
  animation?: boolean;
  communityUnitTimezone?: string;
  zoomEnabled?: boolean;
  panEnabled?: boolean;
  legendEnabled?: boolean;
  maxChartLimit?: string;
  minChartLimit?: string;
  useDefaultTimeConfig?: boolean;
}
