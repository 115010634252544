import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { SettingsList } from "./SettingsList";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { MyProfile } from "./MyProfile";
import { SettingListKeys } from "../../helpers/constants";
import { CommunityInfo } from "./CommunityInfo";
import { EditCommunityUser } from "./EditCommunityUser";
import { EditNotificationGroup } from "./EditNotificationGroup";
import { NotificationSettings } from "./NotificationSettings";
import { AddCommunityUser } from "./AddCommunityUser";
import {
  getCommunityUnits,
  loadCommunityGroups,
  loadCommunityUsers,
} from "../header/headerThunks";
import { HandOff } from "./HandOff";
import {
  loadHandoffSettings,
  loadNotificationSettings,
} from "../app/asyncThunkActions";
import { FloatingNotificationPanel } from "../common/FloatingNotificationPanel";
import { AddUnitForm } from "./AddUnitForm";
import { unitOptions } from "./ResidentUnitsList";
import { EditCommunityUnit } from "./EditCommunityUnit";
import { ResidentSettingsForm } from "./ResidentSettingsForm";
import { ResidentContactsSettings } from "./ResidentContactsSettings";
import { EmergencyContacts } from "./EmergencyContacts";
import { NotificationGroupConfig } from "./NotificationGroupConfig";
import { loadUnitGroupsByEventType } from "./settingsThunks";
import { PauseStackCare } from "./PauseStackCare";
import { minToolbarHeight } from "../app/App";

const useStyles = <T extends { containerLayout: string; showBanner: boolean }>(
  props: T
) =>
  makeStyles((theme: Theme) =>
    createStyles({
      settingsContainer: {
        display: "flex",
        height: `calc(100vh - ${minToolbarHeight(props.showBanner, theme)})`,
      },
      settingsNavPanel: {
        display: "flex",
        width: props.containerLayout,
      },
      settingsContent: {
        display: "flex",
        flex: "1",
        justifyContent: "center",
        height: "100%",
      },
    })
  );

const EmptySectionIndicator = (props: { text: string }) => {
  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      whiteSpace="pre-line"
      textAlign="center"
    >
      <Typography variant="subtitle2">{props.text}</Typography>
    </Box>
  );
};

const SettingsOptions = (props: {
  selectedOption?: string;
  selectedUser?: string;
  userCount: number;
  selectedGroup?: string;
  selectedGroupConfig: boolean;
  selectedEventType?: string;
  selectedUnitOption?: string;
  addCommunityUser: boolean;
  addNotificationGroup: boolean;
  addUnit: boolean;
}) => {
  const {
    selectedOption,
    selectedUser,
    userCount,
    selectedGroup,
    selectedGroupConfig,
    selectedEventType,
    selectedUnitOption,
    addCommunityUser,
    addNotificationGroup,
    addUnit,
  } = props;
  const { t } = useTranslation();

  const pickSettingsComponent = () => {
    switch (selectedOption) {
      case "my_profile":
        return <MyProfile />;
      case SettingListKeys.community_info:
        return <CommunityInfo />;
      case SettingListKeys.community_users:
        if (addCommunityUser) {
          return <AddCommunityUser />;
        }
        if (selectedUser !== undefined) {
          return <EditCommunityUser />;
        }
        return (
          <EmptySectionIndicator
            text={userCount === 0 ? t("add_user") : t("select_user")}
          />
        );
      case SettingListKeys.hand_off:
        return <HandOff />;
      case SettingListKeys.notification_groups:
        if (selectedGroupConfig) {
          return <NotificationGroupConfig />;
        }
        if (selectedGroup !== undefined || addNotificationGroup) {
          return <EditNotificationGroup />;
        }
        return <EmptySectionIndicator text={t("select_group")} />;

      case SettingListKeys.notification_settings:
        if (selectedEventType !== undefined) {
          return <NotificationSettings />;
        }
        return <EmptySectionIndicator text={t("select_notification")} />;

      case SettingListKeys.units_residents:
        // If unit has no resident, an add resident option will be shown
        // If unit has a resident, regular options will be shown
        if (addUnit) {
          return <AddUnitForm />;
        }
        if (
          selectedUnitOption === unitOptions.resident_settings ||
          selectedUnitOption === unitOptions.add_resident
        ) {
          return <ResidentSettingsForm />;
        }

        if (selectedUnitOption === "resident_contacts") {
          return <ResidentContactsSettings />;
        }
        if (selectedUnitOption === "unit_settings") {
          return <EditCommunityUnit />;
        }
        if (selectedUnitOption === "pause_stackcare") {
          return <PauseStackCare />;
        }
        if (selectedUnitOption === "emergency_contacts") {
          return <EmergencyContacts />;
        }

        return <EmptySectionIndicator text={t("select_unit_resident")} />;
      case SettingListKeys.organization_info:
      case SettingListKeys.organization_users:
      case SettingListKeys.organization_groups:
      default:
        return <EmptySectionIndicator text={t("select_setting")} />;
    }
  };

  return pickSettingsComponent();
};

export default function Settings() {
  /* ---- Hooks ---- */
  const dispatch = useAppDispatch();
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const selectedOption = useAppSelector(
    (state) => state.settingsState.selectedOption
  );
  const selectedUser = useAppSelector(
    (state) => state.settingsState.selectedUser
  );
  const userCount = useAppSelector(
    (state) => state.headerState.users?.length ?? 0
  );
  const selectedGroup = useAppSelector(
    (state) => state.settingsState.selectedGroup
  );
  const selectedGroupConfig = useAppSelector(
    (state) => state.settingsState.selectedGroupConfig
  );
  const selectedEventType = useAppSelector(
    (state) => state.settingsState.selectedEventType
  );
  const selectedUnit = useAppSelector(
    (state) => state.settingsState.selectedUnit
  );
  const addCommunityUser = useAppSelector(
    (state) => state.settingsState.addUser
  );
  const addNotificationGroup = useAppSelector(
    (state) => state.settingsState.addNotificationGroup
  );
  const addUnit = useAppSelector((state) => state.settingsState.addUnit);

  const selectedUnitOption = useAppSelector(
    (state) => state.settingsState.selectedUnitConfigOpt
  );

  const installerRole = useAppSelector(
    (state) => state.headerState.installerRole
  );

  const allNotifications = useAppSelector(
    (state) => state.headerState.allNotifications
  );

  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  const getListContainerWidth = () => {
    switch (selectedOption) {
      case SettingListKeys.community_users:
      case SettingListKeys.notification_groups:
      case SettingListKeys.notification_settings:
        return "600px";
      case SettingListKeys.units_residents:
        if (selectedUnit) return "900px";
        return "600px";
      default:
        return "300px";
    }
  };

  const containerLayout = getListContainerWidth();
  const classes = useStyles({
    containerLayout: containerLayout,
    showBanner: showBanner,
  })();

  //Effects
  useEffect(() => {
    if (
      selectedOption === SettingListKeys.community_users &&
      communityID !== undefined
    ) {
      dispatch(loadCommunityUsers(communityID));
      if (!installerRole) {
        dispatch(loadCommunityGroups(communityID));
      }
    }
    if (
      selectedOption === SettingListKeys.notification_groups &&
      communityID !== undefined
    ) {
      dispatch(loadCommunityGroups(communityID));
      dispatch(loadNotificationSettings(communityID));
      dispatch(loadUnitGroupsByEventType(communityID));
    }
    if (
      selectedOption === SettingListKeys.hand_off &&
      communityID !== undefined
    ) {
      dispatch(loadHandoffSettings(communityID));
    }
    if (
      selectedOption === SettingListKeys.notification_settings &&
      communityID !== undefined
    ) {
      dispatch(loadNotificationSettings(communityID));
    }
    if (
      selectedOption === SettingListKeys.units_residents &&
      communityID !== undefined
    ) {
      dispatch(getCommunityUnits(communityID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, communityID]);

  return (
    <div className={classes.settingsContainer}>
      <div className={classes.settingsNavPanel}>
        <SettingsList />
      </div>
      <div className={classes.settingsContent}>
        <SettingsOptions
          selectedOption={selectedOption}
          selectedUser={selectedUser}
          userCount={userCount}
          selectedGroup={selectedGroup}
          selectedGroupConfig={selectedGroupConfig}
          selectedEventType={selectedEventType}
          selectedUnitOption={selectedUnitOption}
          addCommunityUser={addCommunityUser}
          addNotificationGroup={addNotificationGroup}
          addUnit={addUnit}
        />
      </div>
      {!installerRole && !allNotifications && <FloatingNotificationPanel />}
    </div>
  );
}
