import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  devicesServices,
  IRequestDeleteDeviceData,
  IRequestIdentifyData,
  IRequestUpdateLQIData,
  IUpdateZoneData,
} from "../../services/devices.services";
import { IFailedRequest } from "../../services/login.services";
import { handledError } from "../app/appSlice";

export const loadDevicesIssues = createAsyncThunk(
  "devices/loadDevicesIssues",
  async (communityID: string) => {
    const response = await devicesServices.getDeviceIssues(communityID);

    return response;
  }
);

export const loadDeviceIssuesBadge = createAsyncThunk(
  "devices/loadDeviceIssuesBadge",
  async (communityID: string) => {
    const response = await devicesServices.getDeviceIssues(communityID);

    return response;
  }
);

export const loadDevicesData = createAsyncThunk(
  "devices/loadDevicesData",
  async (siteID: string) => {
    const response = await devicesServices.getDevicesData(siteID);

    return response;
  }
);

export const loadGatewaysData = createAsyncThunk(
  "devices/loadGatewaysData",
  async (siteID: string) => {
    const response = await devicesServices.getGatewaysData(siteID);

    return response;
  }
);

export const loadDevicePlacementPhoto = createAsyncThunk<
  string,
  string,
  { rejectValue: IFailedRequest }
>(
  "devices/loadDevicePlacementPhoto",
  async (deviceID: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await devicesServices.getDevicePlacementPhoto(deviceID);
      return response;
    } catch (err) {
      dispatch(handledError());
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const deletePlacementPhoto = createAsyncThunk(
  "devices/deletePlacementPhoto",
  async (deviceID: string, { rejectWithValue }) => {
    try {
      const response = await devicesServices.deletePlacementPhoto(deviceID);
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const updateLQI = createAsyncThunk(
  "devices/updateLQI",
  async (requestData: IRequestUpdateLQIData) => {
    const response = await devicesServices.requestForLQIUpdate(requestData);

    return response;
  }
);

export const identifyDevice = createAsyncThunk(
  "devices/identifyDevice",
  async (requestData: IRequestIdentifyData) => {
    const response = await devicesServices.requestToIdentifyDevice(requestData);

    return response;
  }
);

export const deleteDevice = createAsyncThunk(
  "devices/deleteDevice",
  async (requestData: IRequestDeleteDeviceData) => {
    const response = await devicesServices.deleteDevice(requestData);

    return response;
  }
);

export const updateZoneName = createAsyncThunk(
  "devices/updateZoneName",
  async (requestData: IUpdateZoneData) => {
    const response = await devicesServices.updateZoneName(requestData);

    return response;
  }
);
