import {
  hideSoftNotification,
  showSoftNotification,
} from "../features/app/appSlice";
import {
  ErrorCodes,
  InvalidRequestErrorCodes,
  LOG_LINES_LIMIT,
  StorageKeys,
} from "./constants";
import { env, geolocationEnv, envTest, envStaging, envProd } from "./env";
import store from "../helpers/store";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { IFailedRequest, IToken, loginServices } from "./login.services";
import {
  getClientID,
  refreshTokenFailed,
  updateLocalToken,
} from "../features/login/loginSlice";
import { SoftNotificationTypes } from "../features/common/AppSnack";
import { TEST, STAGING, PRODUCTION } from "../helpers/constants";
import { currentEnv } from "../helpers/utils";

const INVALID_REQUEST = "invalid_request";

export const getEnv = () => {
  let environment = store.getState().appState.environment;

  if (environment === "") {
    environment = currentEnv();
  }

  switch (environment) {
    case TEST: {
      return envTest;
    }
    case STAGING: {
      return envStaging;
    }
    case PRODUCTION: {
      return envProd;
    }
    default: {
      return env;
    }
  }
};

const getVersionEnv = () => {
  const env = getEnv();
  if (env === undefined)
    throw new Error(
      "Environment variable for backend URL to make requests not found"
    );
  const envURL = new URL(env);
  const version = store.getState().appState.version;
  if (version === "") {
    return envURL.origin;
  }

  envURL.host = `${version}-dot-${envURL.host}`;
  return envURL.origin;
};

// Searches for a match in the error ErrorCodes
function getError(data: any, responseStatus: number): Error {
  if (responseStatus === 500) {
    const error: IFailedRequest = {
      code: ErrorCodes.SERVER_INTERNAL_ERROR,
      message: "",
    };
    return new Error(JSON.stringify(error));
  } else {
    let errorID = "";

    const responseErrorCode: string | undefined = data?.error?.code;
    const responseErrorMessage: string | undefined = data?.error?.message;

    if (
      responseErrorCode !== undefined &&
      responseErrorCode === INVALID_REQUEST &&
      responseErrorMessage
    ) {
      Object.keys(InvalidRequestErrorCodes).forEach((key) => {
        if (
          InvalidRequestErrorCodes[
            key as keyof typeof InvalidRequestErrorCodes
          ] === responseErrorMessage
        ) {
          errorID = key;
        }
      });
    } else {
      if (responseErrorCode !== undefined) {
        errorID = responseErrorCode;
      } else {
        errorID = responseErrorMessage ?? "";
      }
    }

    let errorCode: string | undefined = undefined;
    Object.keys(ErrorCodes).forEach((key) => {
      if (ErrorCodes[key as keyof typeof ErrorCodes] === errorID) {
        errorCode = key;
      }
    });

    const error: IFailedRequest = {
      code: errorCode,
      message: responseErrorMessage ?? "",
    };

    return new Error(JSON.stringify(error));
  }
}

// Provides the headers if the request is a secure request (uses JWT token)
function authHeader() {
  // return authorization header with jwt token
  const authData = localStorage.getItem("user");

  if (authData !== null) {
    const user = JSON.parse(authData);

    if (user && user.access_token) {
      return {
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${user.access_token}`,
      };
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

function dispatchSoftNotification(
  responseStatus: number,
  requestMethod: string,
  errorCode: string | null
) {
  if (responseStatus === 502) {
    if (requestMethod === "GET") {
      store.dispatch(
        showSoftNotification(SoftNotificationTypes.SERVICE_UNAVAILABLE_LOADING)
      );
    } else {
      store.dispatch(
        showSoftNotification(SoftNotificationTypes.SERVICE_UNAVAILABLE_UPDATING)
      );
    }
  } else if (responseStatus === 401) {
    if (errorCode !== null && errorCode !== ErrorCodes.INVALID_EMAIL_PASSWORD) {
      store.dispatch(
        showSoftNotification(SoftNotificationTypes.CREDENTIALS_EXPIRED)
      );
    }
  } else {
    if (requestMethod === "GET") {
      store.dispatch(
        showSoftNotification(SoftNotificationTypes.INTERNAL_ERROR_LOADING)
      );
    } else {
      store.dispatch(
        showSoftNotification(SoftNotificationTypes.INTERNAL_ERROR_UPDATING)
      );
    }
  }
}

function getLocalRefreshToken() {
  const _userToken = localStorage.getItem(StorageKeys.USER);
  return _userToken !== null ? (JSON.parse(_userToken) as IToken) : _userToken;
}
function updateRefreshToken(token: IToken) {
  localStorage.setItem(StorageKeys.USER, JSON.stringify(token));
  store.dispatch(updateLocalToken(token));
}

function failedRefresh() {
  store.dispatch(refreshTokenFailed());
}
const instance = axios.create();

async function refreshToken() {
  const refreshToken = getLocalRefreshToken();
  // If refresh token is null, something happened, logout
  if (refreshToken === null) {
    const _error: IFailedRequest = {
      message: "Refresh token not found",
    };
    return Promise.reject(_error);
  }

  const requestData = {
    grant_type: "refresh_token",
    refresh_token: refreshToken.refresh_token,
    client_id: getClientID(),
  };

  return instance.post("/oauth2/tokens", requestData, {
    baseURL: getVersionEnv(),
  });
}

interface RequestConfigUpdated extends AxiosRequestConfig {
  _retry?: boolean;
}

axios.interceptors.response.use(
  (response) => {
    // If there was a refresh token error before, hide it
    if (
      store.getState().appState.softNotificationToggle &&
      store.getState().appState.softNotificationType ===
        SoftNotificationTypes.CREDENTIALS_EXPIRED
    ) {
      store.dispatch(hideSoftNotification());
    }
    return response;
  },
  async (error: AxiosError) => {
    if (error.message === "Network Error") {
      // Network error, just return
      return Promise.reject(error);
    }
    // If it's not a network error, check
    const originalConfig: RequestConfigUpdated = error.config;
    // Request made and server responded
    if (error.response) {
      // Check if the auth header is set, this to make sure we
      //are not trying to refresh when is not a secured request (with auth bearer)
      const authHeader = !!originalConfig.headers?.authorization;
      // Access token was expired
      if (
        authHeader &&
        error.response.status === 401 &&
        !originalConfig._retry &&
        error.response.data?.error?.code !== ErrorCodes.INVALID_EMAIL_PASSWORD
      ) {
        originalConfig._retry = true;
        try {
          const rs = await refreshToken();
          const token = (rs.data as unknown) as IToken;
          updateRefreshToken(token);
          loginServices.authToFirebase(token.access_token);
          if (originalConfig.headers) {
            originalConfig.headers.authorization = `Bearer ${token.access_token}`;
          }
          return axios(originalConfig);
        } catch (_error) {
          failedRefresh();
          // Log
          clientLogger(
            getLogLine({
              url: originalConfig.url ?? "",
              responseData: _error,
            })
          );
        }
      }

      if (error.response.status >= 400 && error.response.status <= 599) {
        clientLogger(
          getLogLine({
            url: error.config.url ?? "",
            payload: error.response.data,
          })
        );
        dispatchSoftNotification(
          error.response.status,
          error.response.config.method ?? "GET",
          error.response.data.error && error.response.data.error.code
            ? error.response.data.error.code
            : null
        );
      }
    }
    clientLogger(
      getLogLine({
        url: error.config.url ?? "",
        responseData: error,
      })
    );
    return Promise.reject(error);
  }
);

/**
 * Makes a request with default headers and handles HTTP error codes
 *
 * @param {string} url API endpoint
 * @param {any} requestOptions overwrites RequestInit parameters
 * @param {boolean} secure determines if the JWT token is included in the headers
 * @return {Promise<any>} returns a promise which contains the server response
 */

export async function request(
  url: string,
  requestOptions: any,
  secure: boolean = true
): Promise<any> {
  clientLogger(
    getLogLine({
      url: url,
      payload: {
        method: requestOptions.method ? requestOptions.method : "GET",
        ...requestOptions,
      },
    })
  );

  return await axios({
    baseURL: getVersionEnv(),
    url: url,
    headers: secure
      ? authHeader()
      : {
          "content-type": "application/json; charset=utf-8",
        },
    data: requestOptions.body ? requestOptions.body : undefined,
    method: requestOptions.method ? requestOptions.method : "GET",
    responseType: requestOptions.responseType
      ? requestOptions.responseType
      : undefined,
  })
    .then(async (response) => {
      clientLogger(
        getLogLine({
          url: url,
          responseData: response.data,
        })
      );
      return response.data !== "" ? response.data ?? true : true;
    })
    .catch(async (error) => {
      clientLogger(
        getLogLine({
          url: url,
          responseData: error,
        })
      );
      if (error.response) {
        // Request made and server responded
        const data =
          requestOptions.responseType !== undefined
            ? JSON.parse(await error.response.data.text())
            : error.response.data;
        const _error: IFailedRequest = getError(data, error.response.status);
        return _error;
      } else if (error.request) {
        // The request was made but no response was received
        const _error: IFailedRequest = {
          message: JSON.stringify(error.request),
        };
        return _error;
      } else {
        // Something happened in setting up the request that triggered an Error
        const _error: IFailedRequest = {
          message: error.message,
        };
        return _error;
      }
    });
}

/**
 * Makes a request to get the users geolocation information

 * @return {Promise<any>} returns a promise which contains the server response
 */

export async function getGeolocation(): Promise<any> {
  return await fetch(`${geolocationEnv}`, {
    method: "GET",
  }).then(async (response) => {
    // If there is something wrong with the response
    if (!response.ok) {
      const data = await response.json();
      const error: IFailedRequest = getError(data, response.status);

      return error;
    } else {
      try {
        const data = await response.json();
        return data;
      } catch (err) {
        // If empty JSON, return true
        return true;
      }
    }
  });
}

const shouldLog = () =>
  store.getState().appState.appLogging &&
  process.env.REACT_APP_ENVIRONMENT !== "production";

const getLogLine = <
  T extends { url: string; status?: number; payload?: any; responseData?: any }
>(
  logData: T
) => {
  let logLine = `endpoint:"${logData.url}"`;
  logLine += logData.status !== undefined ? ` status: ${logData.status}` : "";
  logLine +=
    logData.payload !== undefined
      ? ` payload: ${JSON.stringify(logData.payload)}`
      : "";
  logLine +=
    logData.responseData !== undefined
      ? ` response: ${JSON.stringify(logData.responseData)}`
      : "";
  return logLine;
};

const clientLogger = (payload: string) => {
  if (shouldLog()) {
    const timestamp = `[${new Date().toISOString()}]`;
    const logLine = `${timestamp} ${payload}`;
    let appLog = sessionStorage.getItem(StorageKeys.APP_LOG);
    if (typeof appLog === "string") {
      let parsedLog = JSON.parse(appLog) as string[];
      // Check log length, cull entries if more than 1000
      if (parsedLog.length >= LOG_LINES_LIMIT) {
        parsedLog.shift();
        parsedLog.push(logLine);
      } else {
        parsedLog.push(logLine);
      }
      appLog = JSON.stringify(parsedLog);
    } else {
      let parsedLog = [logLine];
      appLog = JSON.stringify(parsedLog);
    }
    try {
      sessionStorage.setItem(StorageKeys.APP_LOG, appLog);
      console.log(`${timestamp} ${payload}\n`);
    } catch (e) {
      console.log("Local Storage is full, it will be emptied");
      sessionStorage.removeItem(StorageKeys.APP_LOG);
    }
  }
};

export const getAppLogs = () => {
  const storeLog = JSON.stringify(store.getState());
  const timestamp = `[${new Date().toISOString()}]`;
  const logLine = `${timestamp} ${storeLog}\n`;

  let appLogs = sessionStorage.getItem(StorageKeys.APP_LOG);
  let parsedLog: string[] = [];
  if (typeof appLogs === "string") {
    parsedLog = JSON.parse(appLogs) as string[];
    parsedLog.push(logLine);
  } else {
    parsedLog.push(logLine);
  }

  let printableString = "";
  parsedLog.forEach((line) => {
    printableString += `${line}\n`;
  });

  return printableString;
};
