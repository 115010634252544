import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { ReactComponent as WarningNotificationsIcon } from "../../../assets/img/icon.alert.warning.svg";
import { ReactComponent as CriticalNotificationsIcon } from "../../../assets/img/icon.alert.critical.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicatorsContainer: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    colorSecondary: {
      backgroundColor: theme.palette.dark.main,
    },
  })
);

export const EventsIndicator = (props: IProps) => {
  const { amountCriticalEvents, amountWarningEvents } = props;

  const classes = useStyles();
  return (
    <div className={classes.indicatorsContainer}>
      <Badge
        badgeContent={amountCriticalEvents}
        classes={{ colorSecondary: classes.colorSecondary }}
        showZero
        color={amountCriticalEvents > 0 ? "primary" : "secondary"}
      >
        <CriticalNotificationsIcon />
      </Badge>
      <Badge
        badgeContent={amountWarningEvents}
        classes={{ colorSecondary: classes.colorSecondary }}
        showZero
        color={amountWarningEvents > 0 ? "primary" : "secondary"}
      >
        <WarningNotificationsIcon />
      </Badge>
    </div>
  );
};

interface IProps {
  amountCriticalEvents: number;
  amountWarningEvents: number;
}
