import React from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { AccordionActions, Button, Divider } from "@material-ui/core";
import { ExpandMoreOutlined } from "@material-ui/icons";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
    "&$focusVisible": {
      backgroundColor: "inherit",
    },
  },
  content: {
    display: "flex",
    alignItems: "start",
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0, 0),
    "&$expanded": {
      margin: theme.spacing(0, 0),
    },
  },
  focusVisible: {
    backgroundColor: "inherit",
  },
  expanded: {},
}))(MuiAccordionSummary);

const Accordion = withStyles({
  root: {
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
      height: "fit-content",
      marginBottom: 20,
    },
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginBottom: 20,
    boxShadow: "none",
  },
  expanded: {},
})(MuiAccordion);

const ApplyButton = withStyles(({ palette, typography }) => ({
  root: {
    color: palette.contactBlue.main,
  },
  label: {
    fontFamily: typography.primaryFontFamily,
  },
}))(Button);

export const AccordionForm = (props: IProps) => {
  const {
    expand,
    enableButtons,
    enableApply,
    children,
    toggleAccordion,
    handleSave,
    handleCancel,
    handleApplyToSelected,
  } = props;

  /* Hooks */
  const { t } = useTranslation();

  /* Methods */
  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!expand) {
      handleCancel && handleCancel();
      toggleAccordion();
    }
  };

  const handleClosePanelButton = () => {
    handleCancel && handleCancel();
  };

  return (
    <Accordion expanded={expand}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        onClick={handleExpand}
        IconButtonProps={{
          onClick: expand ? handleClosePanelButton : undefined,
        }}
      >
        {children}
      </AccordionSummary>

      <Divider />
      <AccordionActions>
        <Button
          size="small"
          onClick={() => {
            handleCancel && handleCancel();
          }}
          disabled={!enableButtons}
        >
          {t("cancel")}
        </Button>
        <ApplyButton
          size="small"
          onClick={() => {
            handleApplyToSelected && handleApplyToSelected();
          }}
          disabled={!enableApply}
        >
          {t("apply_to_selected_units")}
        </ApplyButton>
        <Button
          color="primary"
          size="small"
          disabled={!enableButtons}
          onClick={() => {
            handleSave && handleSave();
          }}
        >
          {t("save")}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

interface IProps {
  expand: boolean;
  enableButtons: boolean;
  enableApply: boolean;
  children: React.ReactNode;
  toggleAccordion: () => void;
  handleCancel?: () => void;
  handleApplyToSelected?: () => void;
  handleSave?: () => void;
}
