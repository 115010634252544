import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/appHooks";
import { StackLogo } from "../StackLogo";
import { ReducerStates, Views } from "../../../helpers/constants";
import {
  finishAccountCreation,
  getCommunitiesAccountCreation,
} from "../loginSlice";
import { useHistory } from "react-router-dom";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginFormContainer: {
      width: "380px",
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "360px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    helperText: {
      minHeight: "20px",
    },
    subtitleText: {
      textAlign: "center",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: 500,
      fontSize: theme.typography.subtitle1.fontSize,
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(3),
    },
    selectMenu: {
      padding: theme.spacing(2),
    },
    menuItemText: {
      padding: theme.spacing(1),
    },
  })
);

export const SelectCommunityForm = (props: IProps) => {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  /* ---- State ---- */
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [formError, setFormError] = useState("");

  /* ---- Selectors ---- */
  const loginStatus = useAppSelector((state) => state.loginState.state);
  const communities = useAppSelector(
    (state) => state.loginState.userCommunities
  );

  /* ---- Effects ---- */
  // Get communities for the user
  useEffect(() => {
    if (loginStatus === ReducerStates.IDLE) {
      dispatch(getCommunitiesAccountCreation());
    }
  }, [loginStatus, dispatch]);

  // If the user has more than 1 communities, displays de select
  useEffect(() => {
    if (
      loginStatus === ReducerStates.SUCCEEDED &&
      communities !== undefined &&
      communities.length <= 1
    ) {
      if (communities.length === 1) {
        dispatch(finishAccountCreation(communities[0].id));
      } else if (communities.length === 0) {
        dispatch(finishAccountCreation());
      }
      history.push(Views.DASHBOARD);
    }
  }, [loginStatus, dispatch, communities, history]);

  /* ---- Methods ---- */
  const handleSelectedCommunityChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    const { value } = e.target;
    setSelectedCommunity(value as string);
    setFormError("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (selectedCommunity) {
      dispatch(finishAccountCreation(selectedCommunity));
      history.push(Views.DASHBOARD);
    }
  };

  const disableSubmit = selectedCommunity === "" || formError !== "";

  return (
    <React.Fragment>
      <StackLogo />
      <form
        id="validate-email-form"
        noValidate
        className={classes.loginFormContainer}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <p className={classes.subtitleText}>
          {t("communiy_selection_helper_text")}
        </p>

        <FormControl variant="filled" fullWidth>
          <Select
            displayEmpty={true}
            id="community-select"
            value={selectedCommunity}
            onChange={handleSelectedCommunityChange}
            error={formError !== ""}
            classes={{ selectMenu: classes.selectMenu }}
            renderValue={(value) =>
              value !== ""
                ? (communities?.find((community) => community.id === value)
                    ?.name as React.ReactNode)
                : t("select_community")
            }
          >
            {communities?.map((community) => {
              return (
                <MenuItem value={community.id}>
                  <span className={classes.menuItemText}>{community.name}</span>
                </MenuItem>
              );
            })}
          </Select>

          <FormHelperText
            id="verification_code-error"
            className={classes.helperText}
            component="span"
            error={formError !== ""}
          >
            {formError}
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          variant={disableSubmit ? "outlined" : "contained"}
          color="primary"
          size="large"
          disabled={disableSubmit}
          className={classes.textField}
        >
          {t("next")}
        </Button>
      </form>
    </React.Fragment>
  );
};
interface IProps {}
