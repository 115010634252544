import { IUnit } from "./dashboard.services";
import { IFailedRequest } from "./login.services";
import { request } from "./utils";

export const headerServices = {
  getCommunities,
  getUserDetails,
  getCommunity,
  getCommunityUsers,
  getEventResolutions,
  getEventResolutionsNoCommunity,
  getRoleTypes,
  getCommunityGroups,
  getAllCommunityUsers,
};

export interface ICommunities {
  community_type: string;
  company: string | null;
  default_alp: string | null;
  id: string;
  latitude: string | null;
  longitude: string | null;
  name: string;
  plan_id: string;
  postal_code: string;
  role: string[];
  time_created: string;
  time_zone: string;
  help_event: boolean;
  unread_events: number;
  organization_id: string | null;
  organization_name?: string | null;
  country: string | null;
  language: string | null;
  system_issues?: number;
}

export interface IPhoneNumber {
  country_code?: string | null;
  id: string;
  phone_number?: string | null;
  phone_type?: string | null;
  priority?: number | null;
  resident_id?: string | null;
  user_id?: string | null;
  user_type?: string | null;
}
export interface IResidentPhoneNumber extends IPhoneNumber {
  contact_id: string;
}

export interface IUserDetails {
  phone_number: string | null;
  allow_multiple_logins: boolean | null;
  country: string | null;
  email: string;
  first_name?: string | null;
  group_ids: string[];
  groups: {
    id: string;
    name: string;
  }[];
  id: string;
  language: string | null;
  last_name?: string | null;
  phone_numbers: IPhoneNumber[];
  pin: boolean;
  role: {
    all_units: boolean | null;
    name: string;
  };
  roles?: [
    {
      role: {
        all_units: boolean | null;
        community_id?: string;
        name: string;
      };
      units?: [
        {
          custom_name: string | null;
          id: string;
          name: string;
          unit_type: string;
        }
      ];
    }
  ];
  time_created: string;
  units: IUnit[];
  is_twenty_four_time: boolean | null;
  temperature_unit: string | null;
  time_zone: string | null;
  emergency_contact: boolean;
  last_active_time?: string | null;
}

export interface ICommunityUserDetails {
  [community_id: string]: IUserDetails[];
}

export interface IScheduleEnabledSettings {
  community_id: string | undefined;
  is_enabled: boolean;
}
export interface IParsedHandoffSettings {
  is_enabled: boolean | null;
  configuration: {
    message: string;
    0: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    1: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    2: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    3: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    4: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    5: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
    6: {
      is_enabled: boolean | null;
      start_time: string | null;
    } | null;
  };
}

export interface ICommunityDetails {
  name: string | null;
  postal_code: string | null;
  country: string | null;
  time_zone: string | null;
  language: string | null;
}

export interface IEventResolution {
  id: string;
  name?: string | null;
  description?: string | null;
  position?: number | null;
}
export interface IRoleTypes {
  custom_name: string | null;
  description: string;
  name: string;
  position: number | null;
}

interface ICommunityUser {
  id: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
}
export interface ICommunityGroup {
  id: string;
  community_id: string;
  name: string | null;
  users: ICommunityUser[];
}

/**
 * Loads the communities, needs to have a valid token
 *
 * @returns {ICommunities[]} An array containing the communities linked to the user
 */

async function getCommunities(): Promise<ICommunities[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request("/communities?version=2", requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

async function getCommunity(communityID: string): Promise<ICommunities> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(`/communities/${communityID}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as ICommunities);
  }
}

/**
 * Loads the user details, needs to have a valid token
 *
 * @returns {IUserDetails} A json with the user detail information
 */

async function getUserDetails(): Promise<IUserDetails> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request("/users", requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUserDetails);
  }
}

/**
 * Get community users
 * @param {string} community_id Community ID
 * @returns {IUserDetails[]} Users linked to a community
 */

async function getCommunityUsers(
  community_id: string
): Promise<IUserDetails[]> {
  const requestOptions: RequestInit = {
    method: "GET",
    body: null,
  };

  const data = await request(
    `/users?community_id=${community_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IUserDetails[]);
  }
}

/**
 * Get community users
 * @param {string} community_id Community ID
 * @returns {ICommunityUserDetails} Users linked to a community
 */

async function getAllCommunityUsers(): Promise<ICommunityUserDetails> {
  const requestOptions: RequestInit = {
    method: "GET",
    body: null,
  };

  const data = await request(`/users?all_communities=1`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as ICommunityUserDetails);
  }
}

/**
 * Get resolutions linked to a community
 * @param {string} community_id Community ID
 * @returns {IEventResolution[]} The different options to solve an event
 */

async function getEventResolutions(
  community_id: string
): Promise<IEventResolution[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/event_resolutions?community_id=${community_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEventResolution[]);
  }
}

/**
 * Get resolutions linked to a community
 * @returns {IEventResolution[]} The different options to solve an event
 */

async function getEventResolutionsNoCommunity(): Promise<IEventResolution[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(`/event_resolutions`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEventResolution[]);
  }
}

/**
 * Loads the user details, needs to have a valid token
 *
 * @returns {IRoleTypes[]} A json with the user detail information
 */

async function getRoleTypes(): Promise<IRoleTypes[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request("/role_types", requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IRoleTypes[]);
  }
}

/**
 * Get a community groups
 * @param {string} community_id Community ID
 * @returns {ICommunityGroup[]} Returns the list of community groups
 */

async function getCommunityGroups(
  community_id: string
): Promise<ICommunityGroup[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/groups?community_id=${community_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as ICommunityGroup[]);
  }
}
