import { Input, InputAdornment, MenuItem, Select } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { parsePhoneNumber } from "awesome-phonenumber";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { PhoneTypes, sortAlphabetical } from "../../../helpers/constants";
import { IPhoneNumber } from "../../../services/header.services";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWidth: {
      width: "100%",
    },
    inputContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      width: "300px",
      [theme.breakpoints.up("lg")]: {
        width: "300px",
      },
      [theme.breakpoints.down("lg")]: {
        width: "250px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "240px",
      },
    },
    fullWidth: {
      width: "300px",
      paddingRight: 0,
      marginRight: "-0.5em",
    },
    selectButtonLabel: {
      whiteSpace: "pre",
      fontFamily: theme.typography.primaryFontFamily,
    },
    inputField: {
      textAlign: "end",
    },
    selectIcon: {
      color: theme.palette.primary.main,
    },
    complement: {
      width: "20px",
      paddingRight: theme.spacing(4),
    },
    select: {
      width: "60px",
      paddingRight: theme.spacing(4),
    },
    menuItem: {
      borderRadius: 0,
    },
  })
);

const formatPhoneNumber = (
  phoneNumber: UnitPhoneNumber | null | undefined,
  defaultCountryCode: string
) => {
  if (phoneNumber === null || phoneNumber === undefined) return "";

  const phoneNumberValue = parsePhoneNumber(
    phoneNumber.phone_number ?? "",
    (phoneNumber.country_code ?? defaultCountryCode).toUpperCase()
  );
  let formatedPhoneNumber = phoneNumber.phone_number ?? "";
  if (
    phoneNumber.country_code &&
    phoneNumber.country_code !== defaultCountryCode
  ) {
    formatedPhoneNumber =
      phoneNumberValue.getNumber("international") ?? formatedPhoneNumber;
  } else {
    formatedPhoneNumber =
      phoneNumberValue.getNumber("national") ?? formatedPhoneNumber;
  }
  return formatedPhoneNumber;
};

export const PhoneNumberPicker = (props: IProps) => {
  const {
    fieldID,
    labelComplement,
    defaultCountryCode,
    phoneNumber,
    formFull,
    onChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const phoneTypeList = Object.values(PhoneTypes)
    .map((val) => ({
      id: val,
      label: val,
    }))
    .sort((a, b) => sortAlphabetical(t(a.label), t(b.label)));

  const handleChangeType = (value: string) => {
    if (phoneNumber === null) return;

    const newPhoneNumber = { ...phoneNumber };
    newPhoneNumber.phone_type = value;
    onChange(newPhoneNumber);
  };

  const handleChange = (value: string) => {
    if (phoneNumber === null) return;
    const newPhoneNumber = { ...phoneNumber };
    const countryCode = parsePhoneNumber(value).getRegionCode();
    if (countryCode !== null && countryCode !== newPhoneNumber.country_code) {
      newPhoneNumber.country_code = countryCode;
    }
    if (countryCode === null) {
      newPhoneNumber.country_code = defaultCountryCode;
    }

    newPhoneNumber.phone_number = value;
    onChange(newPhoneNumber);
  };
  const inputVal = formatPhoneNumber(phoneNumber, defaultCountryCode);
  return (
    <div
      className={clsx(classes.inputContainer, formFull && classes.fullWidth)}
    >
      <Input
        id={fieldID}
        type={"text"}
        className={classes.inputWidth}
        classes={{ input: classes.inputField }}
        placeholder="(234) 567-8901"
        value={inputVal}
        onChange={(e) => handleChange(e.currentTarget.value)}
        startAdornment={
          <InputAdornment position="end">
            <span className={classes.complement}>{labelComplement}</span>
            <Select
              id="phone-type-picker"
              value={phoneNumber?.phone_type}
              onChange={(e) => handleChangeType(e.target.value as string)}
              classes={{ icon: classes.selectIcon, select: classes.select }}
              disableUnderline
            >
              {phoneTypeList.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    classes={{ root: classes.menuItem }}
                    value={item.id}
                  >
                    {t(item.label)}
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        }
      />
    </div>
  );
};
export type UnitPhoneNumber = Pick<
  IPhoneNumber,
  "country_code" | "phone_number" | "phone_type" | "priority" | "user_type"
>;

interface IProps {
  fieldID: string;
  labelComplement?: string;
  defaultCountryCode: string;
  phoneNumber?: UnitPhoneNumber | null;
  formFull: boolean;
  onChange: (phoneNumber: UnitPhoneNumber) => void;
}
