import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import EventTab, { TAB_WIDTH } from "./EventTab";
import TabPanel from "./TabPanel";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import MaximizeIcon from "@material-ui/icons/ExpandLess";
import MinimizeIcon from "@material-ui/icons/ExpandMore";
import { TabTypes } from "../../../helpers/constants";
import TrayEvent from "./TrayEvent";
import TrayUnit from "./TrayUnit";
import { tabBarHeight, trayHeight } from "../Dashboard";
import { useDispatch } from "react-redux";
import { clearUnitStatus, clearAllUnitStatus } from "../dashboardSlice";
import TrayPausedEvent from "./TrayPausedEvent";
import clsx from "clsx";
import { debounce, ownerWindow } from "@material-ui/core/utils";
import { TabScrollButton } from "@material-ui/core";
import animate from "../../../helpers/animate";

const SCROLL_BUTTON_WIDTH = 40;

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  tabTrayContainer: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    height: "100%",
  },
  tabTrayContent: {
    height: `calc(${trayHeight})`,
  },
  tabContainer: {
    backgroundColor: "rgba(255, 255, 255, .15)",
    "backdrop-filter": "blur(5px)",
    "@supports ( -moz-appearance:none )": {
      backgroundColor: theme.palette.light.light,
    },
    top: `-${tabBarHeight}`,
    left: 0,
    width: "calc(100%)",
    display: "flex",
    position: "absolute",
    justifyContent: "space-between",
    boxShadow: "rgb(0 0 0 / 20%) 0px -5px 15px -6px",
  },
  tabButtons: {
    width: `calc(100% - 60px)`,
    display: "flex",
    overflowX: "scroll",
    // Hide dimensionless scrollbar on MacOS
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari + Chrome
    },
  },
  criticalBorder: {
    borderBottom: `20px solid ${theme.palette.error.main}`,
  },
  unitBorder: {
    borderBottom: `20px solid ${theme.palette.normal.main}`,
  },
  warningBorder: {
    borderBottom: `20px solid ${theme.palette.warning.main}`,
  },
  pausedBorder: {
    borderBottom: `20px solid ${theme.palette.paused.light}`,
  },
  fullHeight: {
    height: "100%",
  },
  pointer: {
    cursor: "pointer",
  },
  tabTrayModal: {
    width: "60px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-evenly",
  },
  tabTextContainer: {
    position: "absolute",
    bottom: "0",
    left: 15,
    transform: "translateY(130%)",
    zIndex: 1,
  },
  tabText: {
    fontSize: "0.78rem",
    color: "white",
  },
}));

export const TabTray = React.forwardRef((props: IProps, ref) => {
  const {
    handleCloseTab,
    tabs,
    selectedTab,
    handleMinimizeTabTray,
    handleMaximizeTabTray,
    handleCloseTabTray,
    openTray,
    minimizedTray,
  } = props;

  const scrollStart = "scrollLeft";
  const start = "left";
  const end = "right";

  /* ---- State ---- */
  const [displayScroll, setDisplayScroll] = useState({
    start: false,
    end: false,
  });

  /* ---- Refs ---- */
  const tabListRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  const getTabsMeta = useCallback(() => {
    const tabsNode = tabListRef.current;
    let tabsMeta;
    if (tabsNode) {
      const rect = tabsNode.getBoundingClientRect();
      // create a new object with ClientRect class props + scrollLeft
      tabsMeta = {
        clientWidth: tabsNode.clientWidth,
        scrollLeft: tabsNode.scrollLeft,
        scrollTop: tabsNode.scrollTop,
        scrollLeftNormalized: tabsNode.scrollLeft,
        scrollWidth: tabsNode.scrollWidth,
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
      };
    }

    let tabMeta;
    if (
      tabsNode &&
      tabListRef &&
      tabListRef.current !== null &&
      selectedTab !== undefined
    ) {
      const children = tabListRef.current.children;
      if (children.length > 0) {
        const tab = children[selectedTab];
        tabMeta = tab ? tab.getBoundingClientRect() : null;
      }
    }
    return { tabsMeta, tabMeta };
  }, [selectedTab]);

  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useDispatch();

  /* ---- Methods ---- */
  const handleChangeTab = (index: number) => {
    props.handleChangeFocus(index);
  };

  let styleCriticalBorder;
  if (selectedTab !== undefined && tabs !== undefined) {
    const borderStyle = `${tabs[selectedTab]?.type}Border`;
    if (borderStyle === "criticalBorder") {
      styleCriticalBorder = classes[borderStyle];
    }
    if (borderStyle === "unitBorder") {
      styleCriticalBorder = classes[borderStyle];
    }
    if (borderStyle === "warningBorder") {
      styleCriticalBorder = classes[borderStyle];
    }
    if (borderStyle === "pausedBorder") {
      styleCriticalBorder = classes[borderStyle];
    }
  }

  const onCloseTab = (index: number, id: string) => {
    handleCloseTab(index);
    dispatch(clearUnitStatus(id));
  };
  const onCloseTabTray = () => {
    handleCloseTabTray();
    dispatch(clearAllUnitStatus());
  };

  const scroll = (scrollValue: number) => {
    animate(scrollStart, tabListRef.current, scrollValue);
  };

  const moveTabsScroll = (delta: number) => {
    if (tabListRef.current === null) return;
    let scrollValue = tabListRef.current[scrollStart];
    scrollValue += delta;
    scroll(scrollValue);
  };

  const handleStartScrollClick = () => {
    if (tabListRef.current !== null) moveTabsScroll(-TAB_WIDTH);
  };

  const handleEndScrollClick = () => {
    if (tabListRef.current !== null) moveTabsScroll(TAB_WIDTH);
  };

  const getConditionalElements = () => {
    const conditionalElements: {
      scrollButtonStart: null | React.ReactNode;
      scrollButtonEnd: null | React.ReactNode;
    } = { scrollButtonStart: null, scrollButtonEnd: null };

    const scrollButtonsActive = displayScroll.start || displayScroll.end;

    conditionalElements.scrollButtonStart = scrollButtonsActive ? (
      <TabScrollButton
        orientation="horizontal"
        direction="left"
        onClick={handleStartScrollClick}
        disabled={!displayScroll.start}
      />
    ) : null;

    conditionalElements.scrollButtonEnd = scrollButtonsActive ? (
      <TabScrollButton
        orientation="horizontal"
        direction="right"
        onClick={handleEndScrollClick}
        disabled={!displayScroll.end}
      />
    ) : null;

    return conditionalElements;
  };

  const scrollSelectedIntoView = useCallback(() => {
    const { tabsMeta, tabMeta } = getTabsMeta();
    if (!tabMeta || !tabsMeta) {
      return;
    }

    if (tabMeta[start] < tabsMeta[start]) {
      // left side of button is out of view
      const nextScrollStart =
        tabsMeta[scrollStart] +
        (tabMeta[start] - tabsMeta[start]) -
        SCROLL_BUTTON_WIDTH;
      scroll(nextScrollStart);
    } else if (tabMeta[end] > tabsMeta[end]) {
      // right side of button is out of view
      const nextScrollStart =
        tabsMeta[scrollStart] +
        (tabMeta[end] - tabsMeta[end]) +
        SCROLL_BUTTON_WIDTH;
      scroll(nextScrollStart);
    }
  }, [getTabsMeta]);

  const updateScrollButtonState = useCallback(() => {
    if (tabListRef.current === null) return;
    const { scrollWidth, clientWidth } = tabListRef.current;
    let showStartScroll;
    let showEndScroll;
    const scrollLeft = tabListRef.current.scrollLeft;
    showStartScroll = scrollLeft > 1;
    showEndScroll = scrollLeft < scrollWidth - clientWidth - 1;

    if (
      showStartScroll !== displayScroll.start ||
      showEndScroll !== displayScroll.end
    ) {
      setDisplayScroll({ start: showStartScroll, end: showEndScroll });
    }
  }, [displayScroll]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTabsScroll = useCallback(
    debounce(() => {
      updateScrollButtonState();
    }),
    []
  );

  /* ---- Effects ---- */
  useEffect(() => {
    if (tabsRef.current === null) return;
    const handleResize = debounce(() => {
      updateScrollButtonState();
    });

    const win = ownerWindow(tabsRef.current);
    win.addEventListener("resize", handleResize);
    return () => {
      handleResize.clear();
      win.removeEventListener("resize", handleResize);
    };
  }, [updateScrollButtonState]);

  useEffect(() => {
    return () => {
      handleTabsScroll.clear();
    };
  }, [handleTabsScroll]);

  useEffect(() => {
    updateScrollButtonState();
  });

  useEffect(() => {
    scrollSelectedIntoView();
  }, [scrollSelectedIntoView]);

  const conditionalElements = getConditionalElements();

  return (
    <Card elevation={5} className={classes.tabTrayContainer} ref={ref}>
      <div
        ref={tabsRef}
        className={clsx(classes.tabContainer, styleCriticalBorder)}
      >
        {conditionalElements.scrollButtonStart}
        <div
          ref={tabListRef}
          className={classes.tabButtons}
          onScroll={handleTabsScroll}
        >
          {tabs !== undefined &&
            tabs.map((tab, index) => {
              return (
                <EventTab
                  key={`tab-${index}${tab.id}`}
                  index={index}
                  id={tab.id}
                  selected={index === selectedTab}
                  type={tab.type}
                  closeTab={onCloseTab}
                  changeTab={handleChangeTab}
                />
              );
            })}
        </div>
        {conditionalElements.scrollButtonEnd}
        <Box className={classes.tabTrayModal}>
          {openTray && !minimizedTray ? (
            <MinimizeIcon
              className={classes.pointer}
              onClick={() => handleMinimizeTabTray()}
            />
          ) : (
            <MaximizeIcon
              className={classes.pointer}
              onClick={() => handleMaximizeTabTray()}
            />
          )}
          <CloseIcon
            className={classes.pointer}
            onClick={() => onCloseTabTray()}
          />
        </Box>
      </div>
      {tabs &&
        openTray &&
        !minimizedTray &&
        tabs.map((tab, index) => {
          return (
            <TabPanel
              key={`tab-panel-${index}${tab.id}`}
              value={selectedTab}
              index={index}
              className={classes.tabTrayContent}
            >
              {(tab.type === TabTypes.CRITICAL ||
                tab.type === TabTypes.WARNING) && (
                <TrayEvent eventID={tab.id} />
              )}
              {tab.type === TabTypes.PAUSED && (
                <TrayPausedEvent eventID={tab.id} />
              )}
              {tab.type === TabTypes.UNIT && <TrayUnit unitID={tab.id} />}
            </TabPanel>
          );
        })}
    </Card>
  );
});

interface IProps {
  openTray: boolean;
  minimizedTray: boolean;
  tabs?: { id: string; type: TabTypes }[];
  selectedTab?: number;
  handleCloseTab: (index: number) => void;
  handleMaximizeTabTray: () => void;
  handleMinimizeTabTray: () => void;
  handleCloseTabTray: () => void;
  handleChangeFocus: (index: number) => void;
}
