import React, { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CriticalNotifications from "./CriticalNotifications";
import WarningNotifications from "./WarningNotifications";
import { EventsIndicator } from "./EventsIndicator";
import Divider from "@material-ui/core/Divider/Divider";
import {
  failedFirebaseDashboard,
  setCommunityEvents,
  startLoadingDashboard,
  startMonitoringNewEvents,
} from "../dashboardSlice";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../app/appHooks";
import { IAppState } from "../../../helpers/store";
import { getFirebaseDBReference } from "../../../helpers/firebase";
import { getEventsToUpdateFirebase } from "../../../helpers/utils";
import useEventTypesCount from "../hooks/useEventTypes";
import { ACTIVE_PANEL_WIDTH, TabTypes } from "../../../helpers/constants";
import useOrganization from "./hooks/useOrganization";
import usePanelsHeight from "./hooks/usePanelsHeight";
import { ORG_NOTIFICATIONS_PER_COMMUNITY } from "../../../services/constants";
import { minToolbarHeight } from "../../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    notificationsContainer: {
      display: "flex",
      width: "100%",
      borderLeft: `1px solid ${theme.palette.divider}`,
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      padding: theme.spacing(2.8, 1),
      flexDirection: "column",
      backgroundColor: theme.palette.light.light,
    },
    titleContainer: {
      display: "flex",
      marginBottom: theme.spacing(1),
      alignItems: "center",
    },
    indicatorsContainer: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    colorSecondary: {
      backgroundColor: theme.palette.dark.main,
    },
    notificationEventContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      overflowY: "auto",
      height: "100%",
    },
  })
);

export default function Notifications(props: IProps) {
  const { addTab } = props;
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  /* ---- State ---- */
  const [initHardEvents, setInitHardEvents] = useState(false);
  const [initSoftEvents, setInitSoftEvents] = useState(false);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedOrganizationID, orgsCommunities } = useOrganization();

  /* ---- Selectors ---- */
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);

  const communityEvents = useAppSelector(
    (state) => state.dashboardState.communityEvents
  );

  const selectedCommunityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const installerRole = useAppSelector(
    (state) => state.headerState.installerRole
  );

  const currentUser = useAppSelector(
    (state: IAppState) => state.headerState.user
  );

  const ref = useRef(null);

  const { criticalEventsCount, warningEventsCount } = useEventTypesCount(
    communityEvents
  );

  const { criticalPanelHeight, warningPanelHeight } = usePanelsHeight(
    warningEventsCount,
    criticalEventsCount,
    ref.current
  );
  /* ---- Effects ---- */
  // Community effects
  useEffect(() => {
    if (
      selectedCommunityID !== undefined &&
      firebaseAuth &&
      !installerRole &&
      currentUser &&
      currentUser.roles
    ) {
      const communityIds = new Set();
      const unitIds = new Set();
      currentUser.roles.forEach((role) => {
        if (role.role.all_units) {
          communityIds.add(role.role.community_id);
        } else {
          if (role.units) {
            role.units.forEach((unit) => {
              unitIds.add(unit.id);
            });
          }
        }
      });
      dispatch(startLoadingDashboard());
      const unresolvedEventsRef = getFirebaseDBReference().ref(
        `communities/${selectedCommunityID}/unresolved_events`
      );

      const listener = unresolvedEventsRef.on(
        "value",
        (snapshot) => {
          const vals = snapshot.val();
          let _records = getEventsToUpdateFirebase(vals);
          _records = _records.filter((record) => {
            const eventUnitID = record.unit_id;
            const eventCommunityID = record.community_id;
            return (
              communityIds.has(eventCommunityID) || unitIds.has(eventUnitID)
            );
          });

          dispatch(setCommunityEvents(_records));
          if (!initHardEvents) setInitHardEvents(true);
        },
        (error) => {
          dispatch(failedFirebaseDashboard());
        }
      );
      return () => unresolvedEventsRef.off("value", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID, firebaseAuth]);

  useEffect(() => {
    if (
      selectedCommunityID !== undefined &&
      currentUser !== undefined &&
      firebaseAuth &&
      !installerRole &&
      currentUser &&
      currentUser.roles
    ) {
      const communityIds = new Set();
      const unitIds = new Set();
      currentUser.roles.forEach((role) => {
        if (role.role.all_units) {
          communityIds.add(role.role.community_id);
        } else {
          if (role.units) {
            role.units.forEach((unit) => {
              unitIds.add(unit.id);
            });
          }
        }
      });
      dispatch(startLoadingDashboard());
      const unreadEventsRef = getFirebaseDBReference().ref(
        `communities/${selectedCommunityID}/unread_events_soft/${currentUser.id}`
      );

      const listener = unreadEventsRef.on(
        "value",
        (snapshot) => {
          const vals = snapshot.val();
          let _records = getEventsToUpdateFirebase(vals, true);
          dispatch(setCommunityEvents(_records));
          if (!initSoftEvents) setInitSoftEvents(true);
        },
        (error) => {
          dispatch(failedFirebaseDashboard());
        }
      );
      return () => unreadEventsRef.off("value", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID, firebaseAuth, currentUser]);

  // Signal the initialization is done and start monitoring events
  useEffect(() => {
    if (initHardEvents && initSoftEvents) {
      dispatch(startMonitoringNewEvents());
    }
  }, [dispatch, initHardEvents, initSoftEvents]);

  // Organization effects
  useEffect(() => {
    if (
      selectedOrganizationID !== undefined &&
      orgsCommunities !== undefined &&
      firebaseAuth &&
      !installerRole
    ) {
      dispatch(startLoadingDashboard());
      const unresolvedEventsRefArr = orgsCommunities.map((community) => {
        return getFirebaseDBReference()
          .ref(`communities/${community.id}/unresolved_events`)
          .orderByKey()
          .limitToFirst(ORG_NOTIFICATIONS_PER_COMMUNITY);
      });
      const listenerArr = unresolvedEventsRefArr.map((communityRef) =>
        communityRef.on(
          "value",
          (snapshot) => {
            const vals = snapshot.val();
            let _records = getEventsToUpdateFirebase(vals);
            dispatch(setCommunityEvents(_records));
          },
          (error) => {
            dispatch(failedFirebaseDashboard());
          }
        )
      );
      return () =>
        unresolvedEventsRefArr.forEach((communityRef, index) =>
          communityRef.off("value", listenerArr[index])
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationID, firebaseAuth]);

  useEffect(() => {
    if (
      selectedOrganizationID !== undefined &&
      orgsCommunities !== undefined &&
      currentUser !== undefined &&
      firebaseAuth &&
      !installerRole
    ) {
      dispatch(startLoadingDashboard());
      const unreadEventsRefArr = orgsCommunities.map((community) => {
        return getFirebaseDBReference()
          .ref(
            `communities/${community.id}/unread_events_soft/${currentUser.id}`
          )
          .orderByKey()
          .limitToFirst(ORG_NOTIFICATIONS_PER_COMMUNITY);
      });
      const listenerArr = unreadEventsRefArr.map((communityRef) =>
        communityRef.on(
          "value",
          (snapshot) => {
            const vals = snapshot.val();
            let _records = getEventsToUpdateFirebase(vals, true);
            dispatch(setCommunityEvents(_records));
          },
          (error) => {
            dispatch(failedFirebaseDashboard());
          }
        )
      );
      return () =>
        unreadEventsRefArr.forEach((communityRef, index) =>
          communityRef.off("value", listenerArr[index])
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationID, firebaseAuth, currentUser]);

  const innerListWidth = ACTIVE_PANEL_WIDTH - 18;
  return (
    <div
      className={classes.notificationsContainer}
      id="notifications-container"
    >
      <Typography
        id="notification-title"
        component="div"
        className={classes.titleContainer}
      >
        <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
          {t("active_notifications")}
        </Box>
        <EventsIndicator
          amountCriticalEvents={criticalEventsCount}
          amountWarningEvents={warningEventsCount}
        />
      </Typography>
      <div
        id="inner-notifications-container"
        className={classes.notificationEventContainer}
        ref={ref}
      >
        {warningEventsCount !== 0 || criticalEventsCount !== 0 ? (
          <React.Fragment>
            <CriticalNotifications
              addTab={addTab}
              unitId={null}
              height={criticalPanelHeight}
              // Width of the active panel minus the padding
              width={innerListWidth}
            />
            {warningEventsCount > 0 && criticalEventsCount > 0 && <Divider />}

            <WarningNotifications
              addTab={addTab}
              unitId={null}
              height={warningPanelHeight}
              width={innerListWidth}
            />
          </React.Fragment>
        ) : (
          <Box fontWeight="fontWeightBold" textAlign="center" marginTop={4}>
            {t("no_active_notifications")}
          </Box>
        )}
      </div>
    </div>
  );
}

interface IProps {
  addTab: (id: string, type: TabTypes) => void;
}
