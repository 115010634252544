import { SortState } from "../features/notifications/NotificationTable";
import { EventTypes } from "../helpers/constants";
import { NOTIFICATIONS_LIMIT } from "./constants";
import { IEvent } from "./dashboard.services";
import { IFailedRequest } from "./login.services";
import { request } from "./utils";

export const notificationsServices = {
  getCommunityEvents,
  notificationEvents,
};
/*
 * Loads the events for the community units
 * @param {string} community_id Community id to retrieve units *
 * @returns {IUnit[]} An array containing the communities linked to the user
 */

async function getCommunityEvents(
  community_id: string,
  page: number
): Promise<IEvent[]> {
  const requestOptions: RequestInit = {
    body: null,
  };
  const data = await request(
    `/events?community_id=${community_id}&status=unassigned,assigned,resolved&order=desc&limit=${NOTIFICATIONS_LIMIT}&page=${page}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEvent[]);
  }
}

export interface INotificationEvents {
  community_id: string;
  page: number;
  sort: SortState[];
  filters: { [key: string]: { [key: string]: boolean } };
  allHelpAtHome: boolean;
}

export const NotificationTableColumn = {
  notification: "notification",
  unit: "unit",
  resident: "resident",
  responder: "responder",
  status: "status",
};

/**
 * Resolve an event with a resolution id
 * @param {INotificationEvents} resolveData Event id and resolution id
 * @returns {IEvent[]} A list of events
 */

async function notificationEvents(
  params: INotificationEvents
): Promise<IEvent[]> {
  const defaultEventStatus = ["unassigned", "assigned", "resolved"];
  const defaultEventType = [EventTypes.hand_off_notification];
  const defaultResolvedEventType = [EventTypes.bathroom_entry];

  const requestOptions: RequestInit = {
    body: null,
  };

  const getOrder = (order: number) => (order === 0 ? "desc" : "asc");
  const getEquivalenceCol = (orderBy: string, allHelpAtHome: boolean) => {
    switch (orderBy) {
      case NotificationTableColumn.notification:
        return "event_type";
      case NotificationTableColumn.unit:
        //allHelpAtHome ? "city" : mixedUnitTypes ? "unit_or_city" : "unit",
        if (allHelpAtHome) {
          return "resident_city";
        }
        return "unit_name";
      case NotificationTableColumn.resident:
        return "resident_first_name";
      case NotificationTableColumn.responder:
        return "responder_email";
      default:
        return "";
    }
  };

  let sortString = "";
  if (params.sort) {
    if (params.sort.length === 1) {
      sortString = `&order=${getOrder(params.sort[0].order)}`;
    }
    if (params.sort.length === 2) {
      sortString = `&sort_primary=${getEquivalenceCol(
        params.sort[0].orderBy,
        params.allHelpAtHome
      )}&sort_primary_order=${getOrder(params.sort[0].order)}&order=${getOrder(
        params.sort[1].order
      )}`;
    }
  }
  let hasFilter = false;
  let filterString = "";
  let hasStatus = false;
  const filterKeys = Object.keys(params.filters);
  if (params.filters && filterKeys.length > 0) {
    hasFilter = true;
    filterKeys.forEach((key) => {
      //event_types
      //event_type_exclude
      if (key === NotificationTableColumn.notification) {
        let notificationStr = "";
        const filterValues = params.filters[key];
        const excludeCount = Object.values(filterValues).filter((val) => !val)
          .length;
        const includeCount = Object.values(filterValues).filter((val) => val)
          .length;

        Object.keys(filterValues).forEach((filterKey) => {
          // Choose query param that results in less characters
          if (excludeCount < includeCount) {
            if (!filterValues[filterKey]) {
              if (notificationStr === "") {
                notificationStr = `&event_type_exclude=${filterKey}`;
              } else {
                notificationStr += `,${filterKey}`;
              }
            }
          } else {
            if (filterValues[filterKey]) {
              if (notificationStr === "") {
                notificationStr = `&event_type=${filterKey}`;
              } else {
                notificationStr += `,${filterKey}`;
              }
            }
          }
        });
        filterString += notificationStr;
      }
      //unit_id
      if (
        key === NotificationTableColumn.unit ||
        key === NotificationTableColumn.resident
      ) {
        let unitString = "";
        const filterValues = params.filters[key];
        Object.keys(filterValues).forEach((filterKey) => {
          if (filterValues[filterKey]) {
            if (unitString === "") {
              unitString = `&unit_id=${filterKey}`;
            } else {
              unitString += `,${filterKey}`;
            }
          }
        });
        filterString += unitString;
      }
      //responder_id
      if (key === NotificationTableColumn.responder) {
        let responderString = "";
        const filterValues = params.filters[key];
        Object.keys(filterValues).forEach((filterKey) => {
          if (filterValues[filterKey]) {
            if (responderString === "") {
              responderString = `&responder_id=${filterKey}`;
            } else {
              responderString += `,${filterKey}`;
            }
          }
        });
        filterString += responderString;
      }
      if (key === NotificationTableColumn.status) {
        hasStatus = true;
        let statusString = "";
        const filterValues = params.filters[key];
        Object.keys(filterValues).forEach((filterKey) => {
          if (filterValues[filterKey]) {
            if (statusString === "") {
              statusString = `&status=${filterKey}`;
            } else {
              statusString += `,${filterKey}`;
            }
          }
        });
        filterString += statusString;
      }
    });

    if (!filterKeys.includes("notification") && filterKeys.length > 0) {
      filterString += `&event_type_exclude=${defaultEventType.join(",")}`;
      filterString += `&event_type_exclude_resolved=${defaultResolvedEventType.join(
        ","
      )}`;
    }
  }

  let queryString = `community_id=${params.community_id}${
    !hasStatus ? `&status=${defaultEventStatus.join(",")}` : ""
  }${
    hasFilter
      ? filterString
      : `&event_type_exclude=${defaultEventType.join(
          ","
        )}&event_type_exclude_resolved=${defaultResolvedEventType.join(",")}`
  }${sortString}&limit=${NOTIFICATIONS_LIMIT}&page=${params.page}`;
  // If query string is greater than 2048, it has exceeded recomended query length, switch to post
  let path = "/events?";
  if (queryString.length > 2030) {
    // Post request
    requestOptions.method = "POST";
    const bodyJSON = JSON.parse(
      '{"' + queryString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    path = "/events/search";
    requestOptions.body = JSON.stringify(bodyJSON);
    queryString = "";
  }

  const data = await request(`${path}${queryString}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IEvent[]);
  }
}
