import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { IAppState } from "../../../helpers/store";
import SectionHeader from "../charts/SectionHeader";
import i18n from "../../../i18n";
import NotificationHistoryItem from "../NotificationHistoryItem";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
  })
);

export default function UnitEventHistory(props: IProps) {
  const { unitId, communityUnitTimezone } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const unitEvents = useSelector(
    (state: IAppState) => state.dashboardState.unitEvents[unitId]
  );

  return (
    <React.Fragment>
      <div className={classes.rootContainer}>
        <SectionHeader text={i18n.t("notification_history").toUpperCase()} />

        {unitEvents && unitEvents.length > 0 ? (
          unitEvents.map((criticalEvent) => {
            return (
              <NotificationHistoryItem
                key={`critical-notification-${criticalEvent.id}`}
                notificationEvent={criticalEvent}
                communityUnitTimezone={communityUnitTimezone}
              />
            );
          })
        ) : (
          <Box marginTop={2}>{i18n.t("no_recent_notifications")}</Box>
        )}
      </div>
    </React.Fragment>
  );
}

interface IProps {
  unitId: string;
  communityUnitTimezone?: string;
}
