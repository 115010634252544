import { useEffect, useState } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";
import { IconButton } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { AnalyticsIcons } from "../../helpers/iconImports";
import { AnalyticsChartTypes } from "../../helpers/constants";
import { useAppSelector } from "../app/appHooks";
import { zoomDials, zoomValues, ZoomValues } from "./analyticsSlice";
import { CalendarDatePicker } from "./CalendarDatePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0, 1),
      marginBottom: theme.spacing(8),
      justifyContent: "center",
      alignItems: "center",
    },
    iconButtonRoot: {
      padding: theme.spacing(0.5),
      height: "50px",
      width: "50px",
      marginBottom: theme.spacing(8),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    controlIcon: {
      height: "28px",
      width: "28px",
    },
    disabled: {
      opacity: 0.35,
    },
    rotate: {
      "-webkit-transform": "rotate(90deg)",
      "-moz-transform": "rotate(90deg)",
      "-o-transform": "rotate(90deg)",
      "-ms-transform": "rotate(90deg)",
      transform: "rotate(90deg)",
    },
  })
);

const zoomByChartType = (type: string) => {
  switch (type) {
    case AnalyticsChartTypes.temperature:
    case AnalyticsChartTypes.activity:
      return true;
    case AnalyticsChartTypes.kitchen:
    case AnalyticsChartTypes.bathroom:
    case AnalyticsChartTypes.sleep:
    default:
      return false;
  }
};

type NextDialType = { customZoomState?: number; currentZoom?: ZoomValues };
type NextDialFunc = ({
  customZoomState,
  currentZoom,
}: NextDialType) => ZoomValues | null;

const getZoomInNextDial: NextDialFunc = ({ customZoomState, currentZoom }) => {
  const firstZoom = zoomValues[0];
  if (customZoomState !== undefined) {
    return customZoomState > firstZoom ? firstZoom : null;
  }
  if (currentZoom !== undefined) {
    return zoomDials(currentZoom).getPrevZoomDial();
  }
  return null;
};

const getZoomOutNextDial: NextDialFunc = ({ customZoomState, currentZoom }) => {
  const lastZoomElement = zoomValues[zoomValues.length - 1];
  if (customZoomState !== undefined) {
    return customZoomState < lastZoomElement ? lastZoomElement : null;
  }
  if (currentZoom !== undefined) {
    return zoomDials(currentZoom).getNextZoomDial();
  }
  return null;
};

export const ChartControlBar = (props: IProps) => {
  const {
    type,
    children,
    panLeft,
    panRight,
    panUp,
    panDown,
    onZoomIn,
    onZoomOut,
    onCalendarDateChange,
    onCalendarToday,
  } = props;

  /* State */
  const [imgsLoaded, setImgsLoaded] = useState(false);
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* Selectors */
  const paginationState = useAppSelector(
    (state) => state.analyticsState.maximizedPagination
  );
  const customZoomState = paginationState?.customZoom;

  const currentZoom = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.zoom
  );

  const hasZoom = zoomByChartType(type);

  const zoomInNextDial = getZoomInNextDial({ customZoomState, currentZoom });

  const zoomOutNextDial = getZoomOutNextDial({ customZoomState, currentZoom });

  const currentTime = paginationState
    ? DateTime.fromISO(paginationState.currentTime, {
        zone: "utc",
      })
    : undefined;

  const localEndTime = paginationState
    ? DateTime.fromISO(paginationState.localEnd, {
        zone: "utc",
      })
    : undefined;

  const rightButtonVisible =
    currentTime && localEndTime ? localEndTime < currentTime : false;

  useEffect(() => {
    const loadImage = (image: string) => {
      return new Promise((resolve, reject) => {
        type analyticsKeys = keyof typeof AnalyticsIcons;
        const img = new Image();
        img.src = AnalyticsIcons[image as analyticsKeys];
        img.onload = () => resolve(image);
        img.onerror = (err) => reject(err);
      });
    };

    Promise.all(
      Object.keys(AnalyticsIcons).map((image) => loadImage(image))
    ).then(() => setImgsLoaded(true));
  }, []);

  const isSleepChart = type === AnalyticsChartTypes.sleep;

  return (
    <>
      {imgsLoaded ? (
        <>
          {children}
          <div className={classes.container}>
            <CalendarDatePicker
              localStart={paginationState?.localStart}
              chartType={type}
              saveDateChange={onCalendarDateChange}
              onTodayButtonClick={onCalendarToday}
            />
            {isSleepChart ? (
              <>
                <IconButton
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  aria-label="pan up"
                  onClick={panUp}
                  disabled={!rightButtonVisible}
                >
                  <img
                    src={AnalyticsIcons.PanLeft}
                    className={clsx(
                      classes.controlIcon,
                      !rightButtonVisible && classes.disabled,
                      isSleepChart && classes.rotate
                    )}
                    alt={t("alt_text_pan_up", { chart_type: type })}
                    draggable={false}
                  />
                </IconButton>

                <IconButton
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  aria-label="pan down"
                  onClick={panDown}
                >
                  <img
                    src={AnalyticsIcons.PanRight}
                    className={clsx(
                      classes.controlIcon,
                      isSleepChart && classes.rotate
                    )}
                    alt={t("alt_text_pan_down", { chart_type: type })}
                    draggable={false}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  aria-label="pan left"
                  onClick={panLeft}
                >
                  <img
                    src={AnalyticsIcons.PanLeft}
                    className={clsx(classes.controlIcon)}
                    alt={t("alt_text_pan_left", { chart_type: type })}
                    draggable={false}
                  />
                </IconButton>

                <IconButton
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  onClick={panRight}
                  aria-label="pan right"
                  disabled={!rightButtonVisible}
                >
                  <img
                    src={AnalyticsIcons.PanRight}
                    className={clsx(
                      classes.controlIcon,
                      !rightButtonVisible && classes.disabled
                    )}
                    alt={t("alt_text_pan_right", { chart_type: type })}
                    draggable={false}
                  />
                </IconButton>
              </>
            )}

            {hasZoom && (
              <>
                <IconButton
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  aria-label="zoom in"
                  onClick={() => zoomInNextDial && onZoomIn(zoomInNextDial)}
                  disabled={!zoomInNextDial}
                >
                  <img
                    src={AnalyticsIcons.ZoomIn}
                    className={clsx(
                      classes.controlIcon,
                      !zoomInNextDial && classes.disabled
                    )}
                    alt={t("alt_text_zoom_in_chart", { chart_type: type })}
                    draggable={false}
                  />
                </IconButton>
                <IconButton
                  classes={{
                    root: classes.iconButtonRoot,
                  }}
                  aria-label="zoom out"
                  onClick={() => zoomOutNextDial && onZoomOut(zoomOutNextDial)}
                  disabled={!zoomOutNextDial}
                >
                  <img
                    src={AnalyticsIcons.ZoomOut}
                    className={clsx(
                      classes.controlIcon,
                      !zoomOutNextDial && classes.disabled
                    )}
                    alt={t("alt_text_zoom_out_chart", { chart_type: type })}
                    draggable={false}
                  />
                </IconButton>
              </>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

interface IProps {
  type: string;
  children: React.ReactNode;
  panLeft: () => void;
  panRight: () => void;
  panUp: () => void;
  panDown: () => void;
  onZoomIn: (zoom: ZoomValues) => void;
  onZoomOut: (zoom: ZoomValues) => void;
  onCalendarDateChange: (newDate: DateTime) => void;
  onCalendarToday: () => void;
}
