import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import EnvironmentSettingsForm from "./EnvironmentSettingsForm";
import { IAppState } from "../../helpers/store";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { LeftPanel, MainContainer, RightPanel } from "./LoginLayout";
import { LoginModes } from "./loginSlice";
import { setEnv } from "../app/appSlice";
import { currentEnv, validateAlexaAuth } from "../../helpers/utils";
import * as QueryString from "query-string";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomText: {
      textAlign: "center",
      position: "absolute",
      bottom: "16px",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  })
);

export default function UpdateEnvironment() {
  /* ---- Hooks ---- */
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const getFormattedProject = () => {
    if (getVersion === "") {
      return getProject;
    }

    return `${getVersion}-dot-${getProject}`;
  };

  const getProject = useSelector(
    (state: IAppState) => state.appState.environment
  );

  const getVersion = useSelector((state: IAppState) => state.appState.version);

  useEffect(() => {
    if (getProject === "") {
      dispatch(setEnv(currentEnv()));
    }
  });

  // Validate if URL has params that match an auth flow from Alexa
  const params = QueryString.parse(location.search);
  const mode = validateAlexaAuth(params) ? LoginModes.ALEXA : LoginModes.LOGIN;

  return (
    <MainContainer>
      <LeftPanel>
        <Typography variant="h4" component="h1" align="center">
          {"Environment Settings"}
        </Typography>
        {mode === LoginModes.LOGIN && (
          <Typography
            variant="body2"
            component="div"
            className={classes.bottomText}
          >
            {getFormattedProject()}
          </Typography>
        )}
      </LeftPanel>
      <RightPanel>
        <EnvironmentSettingsForm />
      </RightPanel>
    </MainContainer>
  );
}
