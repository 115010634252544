import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { createPIN, skipPinCreation } from "../loginSlice";
import { useAppDispatch, useAppSelector } from "../../app/appHooks";
import PinValidation from "../../common/PinValidation";
import { StackLogo } from "../StackLogo";
import { ActionBarBottom } from "./ActionBarBottom";
import { PIN_CODE_LENGTH } from "../../../helpers/constants";
import { ErrorCodes } from "../../../services/constants";
import { handledError } from "../../app/appSlice";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginFormContainer: {
      width: "380px",
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "360px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    pinFieldContainer: {
      width: "100%",
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
    },
    pinLabel: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: 500,
      fontSize: theme.typography.subtitle1.fontSize,
      padding: theme.spacing(1, 4),
    },
    secondaryButtons: {
      color: theme.palette.paused.light,
    },
  })
);

export const CreatePinForm = (props: IProps) => {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  /* ---- State ---- */
  const [pinCode, setPinCode] = useState("");
  const [confirmPinCode, setConfirmPinCode] = useState("");
  const [formError, setFormError] = useState("");

  /* ---- Selectors ---- */
  const errorCode = useAppSelector((state) => state.loginState.errorCode);

  /* Effects */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.PIN_EXISTS:
          dispatch(handledError());
          setFormError(t("pin_already_exists"));
          break;
      }
    }
  }, [errorCode, t, dispatch]);

  /* ---- Methods ---- */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (pinCode) {
      dispatch(createPIN(pinCode));
    }
  };

  const handleChangePinCode = (value: string) => {
    setPinCode(value);
    setFormError("");
  };

  const handleChangeConfirmPinCode = (value: string) => {
    setConfirmPinCode(value);
    setFormError("");
  };

  useEffect(() => {
    if (
      pinCode.length === PIN_CODE_LENGTH &&
      confirmPinCode.length === PIN_CODE_LENGTH
    ) {
      if (pinCode !== confirmPinCode) {
        setFormError("pins_dont_match");
      } else {
        setFormError("");
      }
    }
  }, [confirmPinCode, pinCode]);

  const handleSkipStep = () => {
    dispatch(skipPinCreation());
  };

  /* ---- Utils ---- */
  const disableSubmit =
    pinCode.length !== PIN_CODE_LENGTH ||
    confirmPinCode.length !== PIN_CODE_LENGTH ||
    pinCode !== confirmPinCode ||
    formError !== "";

  return (
    <React.Fragment>
      <StackLogo />
      <form
        id="create-pin-form"
        noValidate
        className={classes.loginFormContainer}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className={classes.pinFieldContainer}>
          <span className={classes.pinLabel}>{t("create_pin")}</span>
          <PinValidation onChange={handleChangePinCode} />
        </div>

        <div className={classes.pinFieldContainer}>
          <span className={classes.pinLabel}>{t("confirm_pin")}</span>
          <PinValidation
            onChange={handleChangeConfirmPinCode}
            error={formError}
          />
        </div>

        <Button
          type="submit"
          variant={disableSubmit ? "outlined" : "contained"}
          color="primary"
          size="large"
          disabled={disableSubmit}
        >
          {t("create_pin")}
        </Button>
      </form>
      <ActionBarBottom rightAligned>
        <Button onClick={handleSkipStep} className={classes.secondaryButtons}>
          {t("skip")}
        </Button>
      </ActionBarBottom>
    </React.Fragment>
  );
};

interface IProps {}
