import React from "react";
import ReactDOM from "react-dom";
import store from "./helpers/store";
import { saveAuthState } from "./helpers/localStorage";
import "./index.css";
import "./i18n";
import App from "./features/app/App";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import reportWebVitals from "./reportWebVitals";
import { initialize } from "./features/app/asyncThunkActions";
import { BrowserRouter as Router } from "react-router-dom";
import createAppTheme from "./styles/createAppTheme";

// Material UI Theme for StackCare App
const theme = createAppTheme();

// Initialize app
store.dispatch(initialize());

// Subscribe and synchronize auth state and selected community in local storage
store.subscribe(() => {
  saveAuthState(store.getState().loginState.userToken);
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
