import {
  capitalize,
  EventStatus,
  i18textByEventType,
  SYSTEM_RESOLVED_PAUSED,
} from "../../helpers/constants";
import { IEvent, IUnit } from "../../services/dashboard.services";
import {
  getResidentCity,
  getResidentName,
  isHardEvent,
  isHelpAtHome,
} from "../dashboard/dashboardSlice";
import i18n from "../../i18n";
import { detailsByEventType } from "../dashboard/eventDetailHelpers";
import { DateTimeShortFormatDay } from "../../helpers/datetime";
import { IUserDetails } from "../../services/header.services";

const TableFieldsS = [
  "notification",
  "unit",
  "resident",
  "status",
  "responder",
  "created",
] as const;

export type TableFields = typeof TableFieldsS[number];

export interface INotificationHeaderCell {
  id: TableFields;
  label: string;
  minWidth?: number;
  align?: "left";
  sortable: boolean;
  filterable: boolean;
}
export interface INotificationRow {
  eventID: string;
  eventType: string;
  cells: INotificationBodyCell[];
}

export interface INotificationBodyCell {
  id: TableFields;
  value: string;
}

export const getHeaderCells = (
  columnOrder: { [Key in TableFields]: number },
  allHelpAtHome: boolean,
  mixedUnitTypes: boolean
) => {
  const headerCells: INotificationHeaderCell[] = [
    {
      id: "notification",
      label: "notification",
      align: "left",
      sortable: true,
      filterable: true,
    },
    {
      id: "unit",
      label: allHelpAtHome ? "city" : mixedUnitTypes ? "unit_or_city" : "unit",
      align: "left",
      sortable: !mixedUnitTypes,
      filterable: allHelpAtHome || mixedUnitTypes ? false : true,
    },
    {
      id: "resident",
      label: "resident",
      align: "left",
      sortable: true,
      filterable: allHelpAtHome || mixedUnitTypes ? true : false,
    },
    {
      id: "status",
      label: "status",
      align: "left",
      sortable: false,
      filterable: false,
    },
    {
      id: "responder",
      label: "responder",
      align: "left",
      sortable: true,
      filterable: true,
    },
    {
      id: "created",
      label: "created",
      align: "left",
      sortable: true,
      filterable: false,
    },
  ];

  const orderedHeaderCells: INotificationHeaderCell[] = [];

  Object.keys(columnOrder).forEach((key) => {
    const index = columnOrder[(key as string) as TableFields];
    orderedHeaderCells[index] = headerCells.find(
      (cell) => cell.id === key
    ) as INotificationHeaderCell;
  });

  return orderedHeaderCells;
};

export const createData = (
  columnOrder: { [Key in TableFields]: number },
  notifications: IEvent[],
  units?: IUnit[],
  users?: IUserDetails[]
): INotificationRow[] => {
  const tableRows: INotificationRow[] = notifications.map((notification) => {
    const getFieldValue = (fieldID: TableFields) => {
      const eventType = i18n.t(i18textByEventType(notification.event_type));
      const unit = units?.find((unit) => unit.id === notification.unit_id);

      const isHelpAtHomeUnit = isHelpAtHome(unit);
      const unitName = unit ? unit.name : "-";
      const resident = unit
        ? getResidentName(unit.residents, { fullFirstName: false })
        : "-";
      const city = unit ? getResidentCity(unit.residents) : "-";
      const eventDetails = detailsByEventType(
        notification.event_type,
        notification.data
      );
      const systemResolved =
        notification.event_resolution_id === SYSTEM_RESOLVED_PAUSED;
      let unitDisplayName = "";
      if (unit !== undefined) {
        if (isHelpAtHomeUnit) {
          unitDisplayName = city;
        } else {
          unitDisplayName = unitName;
        }
      }

      let responder = "";
      if (isHardEvent(notification)) {
        if (systemResolved) {
          responder = i18n.t("not_applicable_paused");
        } else if (notification.status !== EventStatus.UNASSIGNED) {
          if (notification.email !== undefined && notification.email !== null) {
            responder = notification.email;
          } else {
            const responderUserObj = users?.find(
              (user) => user.id === notification.responder_id
            );

            if (responderUserObj !== undefined)
              responder = responderUserObj.email;
          }
        } else if (notification.status === EventStatus.UNASSIGNED) {
          responder = capitalize(i18n.t("unassigned"));
        }
      }

      const createdAt = DateTimeShortFormatDay(
        notification.time_created,
        unit?.time_zone
      );

      switch (fieldID) {
        case "notification":
          return eventType;
        case "resident":
          return resident;
        case "unit":
          return unitDisplayName;
        case "status":
          return eventDetails ?? "";
        case "responder":
          return responder;
        case "created":
          return createdAt;
        default:
          return "";
      }
    };

    const cells: INotificationBodyCell[] = [];

    Object.keys(columnOrder).forEach((key) => {
      const index = columnOrder[(key as string) as TableFields];
      cells[index] = {
        id: key as TableFields,
        value: getFieldValue(key as TableFields),
      };
    });

    const row: INotificationRow = {
      eventID: notification.id,
      eventType: notification.event_type,
      cells: cells,
    };

    return row;
  });

  return tableRows;
};

export const getColumnOrder = (
  allHelpAtHome: boolean,
  mixedUnitTypes: boolean
) => {
  const columnOrder: { [Key in TableFields]: number } = {
    notification: 0,
    unit: 1,
    resident: 2,
    status: 3,
    responder: 4,
    created: 5,
  };

  if (allHelpAtHome || mixedUnitTypes) {
    columnOrder.unit = 2;
    columnOrder.resident = 1;
  }

  return columnOrder;
};
