import React, { useEffect } from "react";
import {
  getEventDataDashboard,
  getResidentCity,
  getResidentName,
  IEnhancedUnit,
  IEnhancedUnit as IUnit,
  isHardEvent,
  isSoftEvent,
  ResidentNameOptions,
  toggleUnitWatchFlag,
  updateUnit,
} from "./dashboardSlice";
import { IEvent } from "../../services/dashboard.services";
import Popover, { PopoverActions } from "@material-ui/core/Popover/Popover";
import {
  EventStatus,
  EventTypes,
  iconByEventType,
  ReadStatus,
  SettingListKeys,
  TabTypes,
  UnitStatus,
  UnitTypes,
  Views,
} from "../../helpers/constants";
import Icons from "../../helpers/iconImports";
import UnitEventList from "./unitDashboard/UnitEventsList";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import { getFirebaseDBReference } from "../../helpers/firebase";
import { UnitLayout } from "./UnitLayout";
import {
  setSettingsOption,
  setSettingsUnit,
  setUnitConfigOpt,
  addUnit,
} from "../settings/settingsSlice";
import { unitSettings } from "../settings/ResidentUnitsList";
import { useHistory } from "react-router-dom";
import { setSelectedUnit } from "../devices/devicesSlice";

const filterPausedEvents = (events?: IEvent[]) =>
  events?.filter((eventEl) => !isPausedEvent(eventEl));

export const isPausedEvent = (eventEl: IEvent) =>
  eventEl.event_type === EventTypes.pause_notification;

const validateBadgeAmount = (amount?: number) => {
  const eventAmount = Number(amount);
  return String(!Number.isNaN(eventAmount) ? eventAmount : "");
};

export const getUnitTitle = (
  unit: IEnhancedUnit,
  options?: ResidentNameOptions
) => {
  const defaultResidentNameOptions = {
    fullFirstName: false,
  };
  const residentName = getResidentName(
    unit.residents,
    options ? options : defaultResidentNameOptions
  );
  const unitName = unit.name;
  const residentCity = getResidentCity(unit.residents);
  if (unit.unit_type === UnitTypes.HELP_AT_HOME) {
    return { unitTitle: residentName, unitSubtitle: residentCity };
  } else {
    return { unitTitle: unitName, unitSubtitle: residentName };
  }
};

// Determines which icon to display or if a badge replaces the icon
const unitIcon = (
  unitStatus: string,
  installerRole: boolean,
  softEvents?: IEvent[],
  hardEvents?: IEvent[],
  hubStatus?: string,
  offlineDevices?: number,
  batteryLowDevices?: number
) => {
  let unitIcon = "";
  let hasBadge = false;
  let isAssigned = false;
  let event = undefined;
  let criticalBadge = false;

  const filteredHardEvents = filterPausedEvents(hardEvents);
  const filteredSoftEvents = filterPausedEvents(softEvents);

  const hasHardEvents =
    filteredHardEvents !== undefined && filteredHardEvents.length > 0;
  const hasSoftEvents =
    filteredSoftEvents !== undefined && filteredSoftEvents.length > 0;

  // if has both, we would have at least 2 events, which means show badge
  if (unitStatus === UnitStatus.addNewUnit) {
    unitIcon = Icons.AddUnitIcon;
  } else if (unitStatus === UnitStatus.notInstalled) {
    unitIcon = Icons.NoGateway;
  } else if (installerRole) {
    if (hubStatus) {
      unitIcon = Icons.GatewayOffline;
    } else if (offlineDevices) {
      unitIcon = batteryLowDevices
        ? validateBadgeAmount(offlineDevices + batteryLowDevices)
        : (unitIcon = validateBadgeAmount(offlineDevices));
      hasBadge = true;
      criticalBadge = true;
    } else if (batteryLowDevices) {
      unitIcon = validateBadgeAmount(batteryLowDevices);
      hasBadge = true;
    } else if (unitStatus === UnitStatus.paused) {
      unitIcon = Icons.PausedUnitIcon;
    }
  } else if (unitStatus === UnitStatus.paused) {
    unitIcon = Icons.PausedUnitIcon;
  } else if (hasHardEvents && hasSoftEvents) {
    const eventAmount =
      Number(filteredSoftEvents?.length) + Number(filteredHardEvents?.length);
    unitIcon = validateBadgeAmount(eventAmount);
    hasBadge = true;
    // only hard
  } else if (hasHardEvents) {
    if (filteredHardEvents !== undefined) {
      if (filteredHardEvents.length === 1) {
        event = filteredHardEvents[0];
        unitIcon = iconByEventType(event.event_type);
        isAssigned =
          event.status !== EventStatus.UNASSIGNED && isHardEvent(event);
      } else {
        const eventAmount = filteredHardEvents?.length;
        unitIcon = validateBadgeAmount(eventAmount);
        hasBadge = true;
      }
    }
    // only soft
  } else if (hasSoftEvents) {
    if (filteredSoftEvents !== undefined) {
      if (filteredSoftEvents.length === 1) {
        event = filteredSoftEvents[0];
        unitIcon = iconByEventType(event.event_type);
      } else {
        const eventAmount = filteredSoftEvents?.length;
        unitIcon = validateBadgeAmount(eventAmount);
        hasBadge = true;
      }
    }
  }
  return { unitIcon, hasBadge, isAssigned, event, criticalBadge };
};

export default function CommunityUnit(props: IProps) {
  const { onClickOpenTab } = props;
  const {
    id,
    notVisible,
    is_notifications_paused,
    is_watched,
    time_updated,
    gateway_id,
  } = props.unit;

  /* Hooks */
  const dispatch = useDispatch();

  /* ---- State ---- */
  const [anchorEl, setAnchorEl] = React.useState(null);

  /* ---- Selectors ---- */
  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );
  const selectedCommunityID = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity?.id
  );
  const softEvents = useSelector((state: IAppState) =>
    state.dashboardState.communityEvents
      ?.filter((eventEl) => id === eventEl.unit_id && isSoftEvent(eventEl))
      .filter((eventEl) => eventEl.status === EventStatus.RESOLVED)
      .filter((eventEl) => eventEl.read_status === ReadStatus.UNREAD)
  );

  const history = useHistory();

  const installerRole = useSelector(
    (state: IAppState) => state.headerState.installerRole
  );

  const hubStatus = useSelector(
    (state: IAppState) =>
      state.devicesState.devicesIssues?.find(
        (device) => device.unit_id === props.unit.id
      )?.hub_status
  );

  const offlineDevices = useSelector(
    (state: IAppState) =>
      state.devicesState.devicesIssues?.find(
        (device) => device.unit_id === props.unit.id
      )?.offline_devices
  );

  const batteryLowDevices = useSelector(
    (state: IAppState) =>
      state.devicesState.devicesIssues?.find(
        (device) => device.unit_id === props.unit.id
      )?.low_battery_devices
  );

  const hardEvents = useSelector((state: IAppState) =>
    state.dashboardState.communityEvents
      ?.filter((eventEl) => id === eventEl.unit_id && isHardEvent(eventEl))
      .filter(
        (eventEl) =>
          eventEl.status === EventStatus.UNASSIGNED ||
          eventEl.status === EventStatus.ASSIGNED
      )
  );

  /* ---- Effects ---- */
  useEffect(() => {
    if (selectedCommunityID !== undefined && firebaseAuth) {
      const unitRef = getFirebaseDBReference().ref(
        `communities/${selectedCommunityID}/units/${id}/settings`
      );
      const listener = unitRef.on("value", (snapshot) => {
        const updatedUnit = snapshot.val();
        if (updatedUnit === null) return;
        if (
          updatedUnit.time_updated === undefined ||
          updatedUnit.time_updated === null
        )
          return;
        if (updatedUnit.time_updated !== time_updated)
          dispatch(updateUnit(updatedUnit));
      });
      return () => unitRef.off("value", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID, firebaseAuth]);

  /* ---- Methods ---- */
  const handleOpenDropdown = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddNewUnit = () => {
    dispatch(addUnit());
    dispatch(setSettingsOption(SettingListKeys.units_residents));
    history.push(Views.SETTINGS);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleSelectUnit = (unitID: string) => {
    if (addNewUnit) {
      handleAddNewUnit();
    } else if (hubStatus || offlineDevices || batteryLowDevices) {
      dispatch(setSelectedUnit(unitID));
      history.push(Views.DEVICES);
    } else {
      dispatch(setSettingsUnit(unitID));
      if (unitStatus === UnitStatus.paused) {
        dispatch(setUnitConfigOpt(unitSettings.pause_stackcare));
      } else {
        dispatch(setUnitConfigOpt(unitSettings.unit_settings));
      }
      dispatch(setSettingsOption(SettingListKeys.units_residents));
      history.push(Views.SETTINGS);
    }
  };

  const handleOpenTab = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let eventId = undefined;
    if (is_notifications_paused) {
      eventId = hardEvents?.find(
        (event) => event.event_type === EventTypes.pause_notification
      )?.id;
    }
    if (eventId) onClickOpenTab(eventId, TabTypes.PAUSED);
    else onClickOpenTab(id, TabTypes.UNIT);
  };

  const handleOpenEventTab = (
    e: React.MouseEvent<HTMLDivElement>,
    unitEvent: IEvent
  ) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(getEventDataDashboard(unitEvent.id));
    if (isHardEvent(unitEvent)) {
      if (unitEvent.event_type === EventTypes.pause_notification) {
        onClickOpenTab(unitEvent.id, TabTypes.PAUSED);
      } else {
        onClickOpenTab(unitEvent.id, TabTypes.CRITICAL);
      }
    } else {
      onClickOpenTab(unitEvent.id, TabTypes.WARNING);
    }
  };

  const handleWatchFlagClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    unitID: string
  ) => {
    e.stopPropagation();
    const watchFlagData = { unitID, isWatched: !isWatched };
    dispatch(toggleUnitWatchFlag(watchFlagData));
  };

  // Defines the behavior of the unit when clicked
  const handleUnitClick = (
    event: React.MouseEvent<HTMLDivElement>,
    isNotInstalled: boolean,
    iconData: ReturnType<typeof unitIcon>,
    unitID: string
  ) => {
    if (installerRole) {
      return handleSelectUnit(unitID);
    }
    if (addNewUnit) return handleAddNewUnit();
    if (isNotInstalled) return undefined;
    if (iconData.hasBadge) return handleOpenDropdown(event);
    if (iconData.unitIcon !== "" && iconData.event !== undefined)
      return handleOpenEventTab(event, iconData.event);

    return handleOpenTab(event);
  };

  const filteredHardEvents = filterPausedEvents(hardEvents);
  const filteredSoftEvents = filterPausedEvents(softEvents);

  // Unit Status
  const isNotInstalled = !gateway_id;
  const isCritical =
    filteredHardEvents !== undefined && filteredHardEvents.length > 0;
  const isWarning =
    filteredSoftEvents !== undefined && filteredSoftEvents.length > 0;
  const isPaused = is_notifications_paused;
  const isWatched = is_watched;
  const addNewUnit = props.unit.unit_type === "add_unit";

  const unitStatus = addNewUnit
    ? UnitStatus.addNewUnit
    : isNotInstalled
    ? UnitStatus.notInstalled
    : isCritical
    ? UnitStatus.critical
    : isWarning
    ? UnitStatus.warning
    : isPaused
    ? UnitStatus.paused
    : UnitStatus.normal;

  const unitIconInformation = unitIcon(
    unitStatus,
    installerRole,
    softEvents,
    hardEvents,
    hubStatus,
    offlineDevices,
    batteryLowDevices
  );

  const open = Boolean(anchorEl);
  const unitEventPopoverID = open ? "unit-event-popover" : undefined;

  const actionRef = React.useRef() as
    | React.MutableRefObject<PopoverActions>
    | undefined;

  // Obtains the information to display, if its a care at home unit it shows resident and city
  const unitTexts = getUnitTitle(props.unit);
  return (
    <React.Fragment>
      <UnitLayout
        title={unitTexts.unitTitle}
        subtitle={unitTexts.unitSubtitle}
        eventType={unitIconInformation.event?.event_type ?? ""}
        isWatched={isWatched}
        isAssigned={unitIconInformation.isAssigned}
        isResolved={false}
        notVisible={!!notVisible}
        hasBadge={unitIconInformation.hasBadge}
        unitIcon={unitIconInformation.unitIcon}
        status={unitStatus}
        toggleFlag={(e) => handleWatchFlagClick(e, id)}
        onClick={(e) =>
          handleUnitClick(e, isNotInstalled, unitIconInformation, props.unit.id)
        }
        installerRole={installerRole}
        hubStatus={hubStatus}
        offlineDevices={offlineDevices}
        batteryLowDevices={batteryLowDevices}
      />
      <Popover
        id={unitEventPopoverID}
        open={open}
        anchorEl={anchorEl}
        action={actionRef}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <UnitEventList
          unitId={id}
          addTab={onClickOpenTab}
          onClose={handleCloseDropdown}
          shouldShowCritical={isCritical}
          shouldShowWarning={isWarning}
          updatePopover={() => actionRef?.current?.updatePosition()}
        />
      </Popover>
    </React.Fragment>
  );
}

interface IProps {
  unit: IUnit;
  onClickOpenTab: (id: string, type: TabTypes) => void;
}
