import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  getLoginToken,
  resetPasswordInit,
  resetSliceState,
  getAuthorizationToken,
  LoginModes,
  redirectToVerifyAccount,
  environmentSettingsInit,
} from "./loginSlice";
import { ErrorCodes } from "../../services/constants";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { emailValidation } from "../../helpers/utils";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { ReducerStates, Views } from "../../helpers/constants";
import { IAlexaData, IAuthorizeData } from "../../services/login.services";
import { StackLogo } from "./StackLogo";
import { handledError } from "../app/appSlice";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginFormContainer: {
      width: "380px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "360px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    link: {
      cursor: "pointer",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: 500,
      fontSize: theme.typography.subtitle1.fontSize,
      paddingBottom: theme.spacing(1),
      textAlign: "right",
    },
    textFieldUser: {
      marginTop: "32px",
    },
    textFieldPassword: {
      marginTop: "0px",
    },
    helperText: {
      minHeight: "20px",
      lineHeight: "1.2",
      margin: theme.spacing(1, 0),
    },
    subtitleContainer: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        marginTop: theme.spacing(4),
      },
      "& img": {
        maxWidth: "60%",
        margin: "auto",
      },
    },
    linkContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      bottom: "30px",
      right: theme.spacing(7),
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
    },
  })
);

/**
 * Login form component
 */

export default function LoginForm(props: IProps) {
  const showEnvironment = process.env.REACT_APP_ENVIRONMENT !== "production";

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  /* ---- State ---- */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  /* ---- Selectors ---- */
  const errorCode = useSelector(
    (state: IAppState) => state.loginState.errorCode
  );

  const loginState: ReducerStates = useSelector(
    (state: IAppState) => state.loginState.state
  );

  /* ---- References ---- */
  const passwordRef = useRef(document.createElement("input"));

  /* ---- Effects ---- */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.INVALID_EMAIL_PASSWORD:
          dispatch(handledError());
          setUsernameError(t("email_or_password_is_incorrect"));
          setPasswordError(t("email_or_password_is_incorrect"));
          break;
        case ErrorCodes.UNVALIDATED_ACCOUNT:
          dispatch(handledError());
          dispatch(redirectToVerifyAccount({ email, password }));
          history.push(Views.ACCOUNT_CREATION);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  // Manage view flow
  useEffect(() => {
    if (ReducerStates[loginState] === ReducerStates.SUCCEEDED) {
      if (props.mode === "login") {
        history.push(Views.DASHBOARD);
        dispatch(resetSliceState());
      }
    }
  }, [loginState, dispatch, history, props.mode]);

  // Set cursor when showing/hiding passwords
  useEffect(() => {
    // Moving cursor to the end
    if (passwordRef.current && passwordRef.current.value) {
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [showPassword]);

  // Set cursor when showing/hiding passwords
  useEffect(() => {
    // Moving cursor to the end
    if (passwordRef.current && passwordRef.current.value) {
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [showPassword]);

  /* ---- Methods ---- */
  const handleUsernameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setEmail(value);
    setUsernameError("");
    setPasswordError("");
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setPassword(value);
    setUsernameError("");
    setPasswordError("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (email && password) {
      const loginData = { email, password };
      if (props.mode === "login") {
        dispatch(getLoginToken(loginData));
      } else if (props.mode === LoginModes.ALEXA) {
        if (props.params) {
          const authorizeData: IAuthorizeData = {
            email,
            password,
            ...props.params,
          };
          dispatch(getAuthorizationToken(authorizeData));
        }
      }
    }
  };

  const toggleShowPassword = useCallback(() => {
    setShowPassword((current) =>
      showPassword === "text" ? "password" : "text"
    );
    // Setting focus here
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showPassword, passwordRef]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleForgotPassword = () => {
    dispatch(resetPasswordInit());
  };

  const handleEnvironmentSetting = () => {
    dispatch(environmentSettingsInit());
  };

  /* ---- Utils ---- */
  const validateEmail = () => {
    if (email !== "") {
      if (emailValidation(email)) {
        setUsernameError(t("invalid_email"));
      } else {
        setUsernameError("");
      }
    }
  };

  const buttonText = props.mode === "login" ? "sign_in_title" : "authorize";
  const disabledLogin =
    email === "" ||
    password === "" ||
    usernameError !== "" ||
    passwordError !== "";

  return (
    <React.Fragment>
      <StackLogo />
      <form
        id="login-form"
        noValidate
        className={classes.loginFormContainer}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.textFieldUser}
          error={usernameError !== ""}
        >
          <InputLabel htmlFor="username">{t("email_address")}</InputLabel>
          <OutlinedInput
            name="username"
            id="username"
            value={email}
            onChange={handleUsernameChange}
            onBlur={validateEmail}
            label={t("email_address")}
            autoComplete="off"
          />
          <FormHelperText
            id="component-error-text"
            className={classes.helperText}
          >
            {usernameError}
          </FormHelperText>
        </FormControl>

        <FormControl
          variant="outlined"
          fullWidth
          className={classes.textFieldPassword}
          error={passwordError !== ""}
        >
          <InputLabel htmlFor="component-outlined">{t("password")}</InputLabel>
          <OutlinedInput
            id="component-outlined"
            name="password"
            type={showPassword}
            inputRef={passwordRef}
            label={t("password")}
            className="password-field"
            value={password}
            onChange={handlePasswordChange}
            autoComplete="current-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("alt_text_toggle_password_visibility")}
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword === "text" ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          type="submit"
          variant={disabledLogin ? "outlined" : "contained"}
          color="primary"
          size="large"
          disabled={disabledLogin}
          className={classes.textFieldUser}
          classes={{
            root: classes.buttonRoot,
          }}
        >
          {t(buttonText)}
        </Button>
      </form>
      {props.mode === LoginModes.ALEXA && (
        <div className={classes.subtitleContainer}>
          {props.subtitleComponent}
        </div>
      )}
      <div className={classes.linkContainer}>
        <Link
          className={classes.link}
          onClick={handleForgotPassword}
          color="primary"
          to={Views.RESET_PASSWORD}
          component={RouterLink}
        >
          <span>{t("forgot_password")}</span>
        </Link>
        <Link
          className={classes.link}
          onClick={() => undefined}
          color="primary"
          to={Views.ACCOUNT_CREATION}
          component={RouterLink}
        >
          <span>{`${t("new_user")}? >`}</span>
        </Link>
        {showEnvironment && (
          <Link
            className={classes.link}
            onClick={handleEnvironmentSetting}
            color="primary"
            to={Views.ENVIRONMENT_SETTING}
            component={RouterLink}
          >
            <span>{"Environment Settings >"}</span>
          </Link>
        )}
      </div>
    </React.Fragment>
  );
}

interface IProps {
  mode: string;
  subtitleComponent: React.ReactNode;
  params?: IAlexaData;
}
