import React, { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button, Grid, Input, Paper } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { UserSelector } from "./common/UserSelector";
import {
  updateNotificationGroup,
  addNotificationGroup as addNotificationGroupAction,
  deleteNotificationGroup,
  loadNotificationSettings,
} from "../app/asyncThunkActions";
import {
  IAddNotificationGroupData,
  IUpdateNotificationGroupData,
} from "../../services/settings.services";
import { arraysAreEqual } from "../../helpers/utils";
import { RoleTypes } from "../../helpers/constants";
import { IUserDetails } from "../../services/header.services";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { ContextMenu } from "./common/ContextMenu";
import { loadCommunityGroups } from "../header/headerThunks";
import { loadUnitGroupsByEventType } from "./settingsThunks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    EditNotificationGroupContainer: {
      padding: theme.spacing(6, 7),
      width: "550px",
      overflowY: "auto",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    wrapper: {
      listStyle: "none",
      padding: 0,
      borderRadius: "3px",
    },
    rowInput: {
      flex: "1.5",
      fontSize: "1rem",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1em .5em 0",
        flex: " 1",
        textAlign: "right",
        color: theme.palette.paused.main,
      },
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    inputWidth: {
      width: "100%",
    },
    rowContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      padding: ".5em",
      marginBottom: theme.spacing(4),
    },
    rowLabel: {
      paddingRight: theme.spacing(2),
    },
    rowContent: {
      flexGrow: 1,
    },
    row: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      marginBottom: theme.spacing(4),
    },
    formLabel: {
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
);
enum OperationType {
  ADD,
  EDIT,
}

export function EditNotificationGroup() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [groupName, setGroupName] = useState<string | null>(null);
  const [groupUsers, setGroupUsers] = useState<string[]>([]);
  const [deleteGroup, setDeleteGroup] = useState(false);

  /* Selectors */
  const usersState = useAppSelector((state) => state.headerState.users);

  let users: IUserDetails[] = [];
  if (usersState !== undefined) {
    users = (JSON.parse(JSON.stringify(usersState)) as IUserDetails[]).filter(
      (user) =>
        user.role.name === RoleTypes.admin ||
        user.role.name === RoleTypes.caregiver ||
        user.role.name === RoleTypes.installer
    );
  }
  const addNotificationGroup = useAppSelector(
    (state) => state.settingsState.addNotificationGroup
  );

  const selectedGroup = useAppSelector((state) =>
    state.headerState.communityGroups?.find(
      (user) => user.id === state.settingsState.selectedGroup
    )
  );
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const operationType =
    addNotificationGroup && selectedGroup === undefined
      ? OperationType.ADD
      : OperationType.EDIT;

  const editGroupContainer = useRef<HTMLUListElement | null>(null);

  /* Effects */
  useEffect(() => {
    if (selectedGroup === undefined && !addNotificationGroup) return;

    if (addNotificationGroup) {
      setGroupName(null);
      setGroupUsers([]);
    }

    if (selectedGroup !== undefined) {
      if (selectedGroup.name !== null) {
        setGroupName(selectedGroup.name);
      }
      if (selectedGroup.users !== null) {
        const selectedUsers = selectedGroup.users.map((user) => user.id);
        setGroupUsers(selectedUsers);
      }
    }
  }, [selectedGroup, addNotificationGroup]);

  useEffect(() => {
    if (editGroupContainer && editGroupContainer.current) {
      editGroupContainer.current.scrollTo(0, 0);
    }
  }, [selectedGroup]);

  /* Methods */
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value.trimStart());
  };

  const handleChangeSelectedUsers = (payload: string[]) => {
    setGroupUsers(payload);
  };

  const handleCancel = () => {
    if (selectedGroup === undefined) return;
    if (selectedGroup.name !== null) {
      setGroupName(selectedGroup.name);
    }

    if (selectedGroup.users !== null) {
      setGroupUsers(selectedGroup.users.map((user) => user.id));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    // Add a new notification group
    if (operationType === OperationType.ADD) {
      if (communityID === undefined) return;
      const groupData: IAddNotificationGroupData = {
        community_id: communityID,
        name: groupName,
        user_ids: groupUsers,
      };

      dispatch(addNotificationGroupAction(groupData));
    }
    // Update an existing notification group
    if (operationType === OperationType.EDIT) {
      if (selectedGroup === undefined) return;
      const groupData: IUpdateNotificationGroupData = {
        group_id: selectedGroup.id,
        groupData: {
          name: groupName,
          user_ids: groupUsers,
        },
      };

      if (selectedGroup.name === groupName) delete groupData.groupData.name;
      if (
        arraysAreEqual(
          selectedGroup.users.map((user) => user.id),
          groupUsers
        )
      )
        delete groupData.groupData.user_ids;
      dispatch(updateNotificationGroup(groupData));
    }
  };

  const validateEditGroup = (
    group: typeof selectedGroup,
    groupName: string | null,
    groupUsers: string[]
  ) => {
    if (group === undefined) return false;

    if (group.name !== groupName && groupName !== "") return true;
    if (
      !arraysAreEqual(
        group.users.map((user) => user.id),
        groupUsers
      )
    )
      return true;

    return false;
  };

  const validateAddGroup = (groupName: string | null) =>
    groupName !== null && groupName !== "";

  const enableSubmit =
    operationType === OperationType.EDIT
      ? validateEditGroup(selectedGroup, groupName, groupUsers)
      : validateAddGroup(groupName);

  const formTitle =
    operationType === OperationType.EDIT ? "edit_group" : "add_group";

  const submitText = operationType === OperationType.EDIT ? "save" : "add";

  const handleDeleteGroup = () => {
    if (selectedGroup) {
      dispatch(deleteNotificationGroup(selectedGroup.id)).then(() => {
        if (communityID) {
          dispatch(loadCommunityGroups(communityID));
          dispatch(loadNotificationSettings(communityID));
          dispatch(loadUnitGroupsByEventType(communityID));
        }
      });
    }
  };

  const handleClickDeleteOption = () => {
    setDeleteGroup(true);
  };

  const handleCloseDropdown = () => {
    // setMenuAnchorEl(null);
    setDeleteGroup(false);
  };

  const contextMenuOptions = [
    { label: "delete_group", action: handleClickDeleteOption },
  ];

  return (
    <Paper
      className={classes.EditNotificationGroupContainer}
      elevation={3}
      square
      ref={editGroupContainer}
    >
      <h2 className={classes.sectionTitle}>
        {t(formTitle)}

        {operationType === OperationType.EDIT && (
          <React.Fragment>
            <ContextMenu menuOptions={contextMenuOptions} />

            <ConfirmDialog
              dialogType={DialogTypes.warning}
              toggle={deleteGroup}
              title={t("delete_group")}
              message={t("confirm_delete_group")}
              possitiveText={t("yes")}
              negativeText={t("no")}
              positiveAction={handleDeleteGroup}
              negativeAction={() => void 0}
              onClose={handleCloseDropdown}
            />
          </React.Fragment>
        )}
      </h2>
      <form
        id="notification-group-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <div className={classes.rowContainer}>
          <div className={classes.rowLabel}>{t("name").toUpperCase()}</div>
          <div className={classes.rowContent}>
            <Input
              id="notification-group-name"
              type={"text"}
              className={classes.inputWidth}
              error={selectedGroup?.name !== "" && groupName === ""}
              value={groupName !== null ? (groupName as string) : ""}
              onChange={handleChangeName}
            />
          </div>
        </div>
        <Grid container className={classes.row}>
          <Grid item sm={12}>
            <div className={classes.formLabel}>{t("users").toUpperCase()}</div>
          </Grid>
          <Grid item sm={12}>
            <UserSelector
              selectedGroup={selectedGroup?.id}
              selectedUsers={groupUsers}
              users={users}
              handleUsersChange={handleChangeSelectedUsers}
            />
          </Grid>
        </Grid>
        <div className={classes.buttonContainer}>
          {operationType === OperationType.EDIT && (
            <Button
              disabled={!enableSubmit}
              classes={{
                root: classes.buttonRoot,
              }}
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
          )}
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t(submitText)}
          </Button>
        </div>
      </form>
    </Paper>
  );
}
