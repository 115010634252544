import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card/Card";
import Grid from "@material-ui/core/Grid/Grid";
import {
  EventTypes,
  i18textByEventType,
  iconByEventType,
  TabTypes,
} from "../../helpers/constants";
import Icons from "../../helpers/iconImports";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IEvent, IUnit } from "../../services/dashboard.services";
import { getUnitTitle } from "./CommunityUnit";
import { useAppSelector } from "../app/appHooks";
import { timeSince } from "../../helpers/datetime";
import { selectNotification } from "../notifications/notificationsSlice";
import { useDispatch } from "react-redux";
import { getEventDataDashboard } from "./dashboardSlice";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    root: {
      margin: "6px",
      minHeight: "100px",
      maxHeight: "130px",
    },
    notificationTile: {
      width: "220px",
      height: "140px",
    },
    residentTitle: {
      display: "flex",
      fontSize: 13,
      fontFamily: theme.typography.secondaryFontFamily,
      height: "25px",
      alignItems: "center",
    },
    eventTitle: {
      display: "block",
      fontSize: 13,
      fontFamily: theme.typography.secondaryFontFamily,
      alignItems: "center",
      lineHeight: "25px",
    },
    timeContainer: {
      display: "flex",
      fontSize: 10,
      justifyContent: "right",
      alignContent: "center",
      alignItems: "center",
      height: "100%",
    },
    hidden: {
      visibility: "hidden",
    },
    cardContent: {
      display: "flex",
      height: "100%",
      padding: theme.spacing(1.2),
    },
    unitContainer: {
      display: "flex",
      alignItems: "flex-end",
      height: "22px",
      fontWeight: "bold",
      fontSize: 13,
    },
    responderContainer: {
      display: "flex",
      alignItems: "flex-end",
      height: "22px",
      paddingLeft: "5px",
      fontSize: 13,
    },
    communityContainer: {
      display: "flex",
      alignItems: "flex-end",
      height: "22px",
      fontSize: 13,
    },
    unitIcon: {
      display: "flex",
      margin: "auto",
      height: "30px",
    },
    iconContainer: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      height: "25px",
    },
    statusIcon: {
      display: "flex",
      position: "absolute",
      bottom: "40%",
      right: "30%",
      height: "25px",
      fontSize: theme.typography.caption.fontSize,
    },
    statusImage: {
      filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.30))",
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    eventIcon: {
      display: "flex",
      margin: "auto",
      height: "24px",
    },
    eventStatusIcon: {
      display: "flex",
      height: "15px",
    },
    statusIconContainer: {
      display: "flex",
      alignItems: "center",
    },
    unassigned: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.error.main,
      border: "none",
      cursor: "pointer",
    },
    assigned: {
      color: theme.palette.paused.main,
      backgroundColor: theme.palette.error.lightTrans,
      border: `3px solid ${theme.palette.error.main}`,
      cursor: "pointer",
    },
    resolved: {
      color: theme.palette.paused.main,
      backgroundColor: theme.palette.light.main,
      border: `3px solid ${theme.palette.grey[500]}`,
      cursor: "pointer",
    },
  })
);

function WrappedNotificationLayout(props: IProps) {
  const { event, unit, onClickOpenTab } = props;

  /* Hooks */
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statusStyle = classes[event.status];
  const unitTexts = getUnitTitle(props.unit);
  const isHard = event.notification_type === "hard";
  const isPaused = event.event_type === EventTypes.pause_notification;

  const icon = iconByEventType(
    event.event_type !== null ? event.event_type : ""
  );
  const community = useAppSelector((state) =>
    state.headerState.communities?.find(
      (community) => community.id === unit.community_id
    )
  );

  const user = useAppSelector((state) => {
    const communityUserData = state.headerState.communityUserData;
    const responderId = event.responder_id;
    const communityId = event.community_id;
    if (!communityUserData || !responderId || !communityId) return undefined;

    const communityUsers = communityUserData[communityId];
    if (!communityUsers) return undefined;

    return communityUsers.find((user) => user.id === responderId);
  });

  const tabType = isHard
    ? isPaused
      ? TabTypes.PAUSED
      : TabTypes.CRITICAL
    : TabTypes.WARNING;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getEventDataDashboard(event.id));
    dispatch(selectNotification(event.id));
    onClickOpenTab(event.id, tabType);
  };

  return (
    <Card
      className={clsx(classes.notificationTile, statusStyle)}
      onClick={(e) => handleClick(e)}
    >
      <div className={classes.cardContent}>
        <Grid container spacing={0}>
          <Grid container spacing={0}>
            <Grid item xs={10}>
              <div className={clsx(classes.residentTitle)}>
                {unitTexts.unitTitle}
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={clsx(classes.timeContainer)}>
                {timeSince(event.time_created)}
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid className={classes.iconContainer} item xs={2}>
              {icon !== "" && (
                <React.Fragment>
                  <img
                    src={icon}
                    alt={t("alt_text_event_type_icon", {
                      event_type: event.event_type,
                    })}
                    draggable={false}
                    className={classes.eventIcon}
                  />
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={10}>
              <div
                className={clsx(classes.truncateEllipsis, classes.eventTitle)}
              >
                {t(i18textByEventType(event.event_type))}
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={1} className={classes.statusIconContainer}>
              {user && (
                <div className={classes.eventStatusIcon}>
                  <img
                    src={Icons.Assigned}
                    alt={t("alt_text_assigned_icon")}
                    draggable={false}
                    className={classes.statusImage}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={10}>
              <div
                className={clsx(
                  classes.truncateEllipsis,
                  classes.responderContainer
                )}
              >
                {user ? `${user.first_name} ${user.last_name}` : ""}
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.unitContainer}>
            <div
              className={clsx(
                classes.truncateEllipsis,
                unitTexts.unitSubtitle === "" && classes.hidden
              )}
            >
              <div className={classes.truncateEllipsis}>
                {unitTexts.unitSubtitle}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.communityContainer}>
            <div
              className={clsx(
                classes.truncateEllipsis,
                unitTexts.unitSubtitle === "" && classes.hidden
              )}
            >
              <div className={classes.truncateEllipsis}>{community?.name}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}

export const NotificationLayout = React.memo(WrappedNotificationLayout);

interface IProps {
  event: IEvent;
  unit: IUnit;
  onClickOpenTab: (id: string, type: TabTypes) => void;
}
