import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EventTypes } from "../../../helpers/constants";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { getNotificationFilterOptions } from "./helpers";
import { GreenCheckbox } from "./GreenCheckBox";
import { FilterSearchField } from "./FilterSearchField";
import { ApplyFilterButton } from "./ApplyFilterButton";
import { useDispatch } from "react-redux";
import { applyFilters } from "../notificationsSlice";
import { useAppSelector } from "../../app/appHooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContainer: {
      maxWidth: "300px",
      minWidth: "340px",
      maxHeight: "400px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    bold: {
      fontWeight: "bolder",
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
    },
    listLabel: {
      fontWeight: "bold",
    },
    listIcon: {
      minWidth: "25px",
    },
    popoverHeader: {
      display: "flex",
    },
  })
);

export const NotificationTypeFilter = (props: IProps) => {
  const { handleCloseFilter } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useDispatch();

  const options = getNotificationFilterOptions();

  const selectedOptions = useAppSelector(
    (state) => state.notificationsState.filters["notification"]
  );

  // Create controlled list options
  let defaultState: { [key: string]: boolean } = {};
  if (selectedOptions === undefined) {
    options.forEach((option) => {
      if (option.id === EventTypes.hand_off_notification) {
        defaultState[option.id] = false;
      } else {
        defaultState[option.id] = true;
      }
    });
  } else {
    defaultState = JSON.parse(JSON.stringify(selectedOptions));
  }

  /* ---- State ---- */
  const [filterSelection, setFilterSelection] = useState(defaultState);
  const [filteredOptions, setFilteredOptions] = useState<
    {
      id: string;
      label: string;
    }[]
  >([]);

  /* ---- Effects ---- */
  useEffect(() => {
    setFilteredOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOptions !== undefined) {
      setFilterSelection(selectedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ---- Methods ---- */
  const handleChange = (eventType: string) => {
    setFilterSelection({
      ...filterSelection,
      [eventType]: !filterSelection[eventType],
    });
  };

  // Obtain keys for searched for values
  const filteredOptionKeys = filteredOptions.map((option) => option.id);

  // Check if all searched for values are selected
  const allSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => filterSelection[val]);

  // Check if all searched for values are not selected
  const noneSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => !filterSelection[val]);

  const handleSelectAll = () => {
    setFilterSelection((prevState) => {
      const keysToUpdate = Object.keys(prevState).filter((option) =>
        filteredOptionKeys.includes(option)
      );
      const newState: {
        [key: string]: boolean;
      } = {};
      if (allSelected) {
        keysToUpdate.forEach((option) => (newState[option as string] = false));
      } else {
        keysToUpdate.forEach((option) => (newState[option as string] = true));
      }
      return { ...prevState, ...newState };
    });
  };

  const handleSearchField = (searchVal: string) => {
    const filteredArray = options.filter((option) =>
      option.label.toLowerCase().includes(searchVal)
    );
    setFilteredOptions(filteredArray);
  };

  const handleApplyButton = () => {
    dispatch(applyFilters({ key: "notification", values: filterSelection }));
    handleCloseFilter();
  };
  const atLeastOneSelected = Object.values(filterSelection).some((val) => val);

  return (
    <React.Fragment>
      <div className={classes.popoverHeader}>
        <FilterSearchField
          withApplyButton
          placeholder="search_notification_type"
          allSelected={allSelected}
          noneSelected={noneSelected}
          selectAllCheckbox
          handleSelectAll={handleSelectAll}
          handleSearchField={handleSearchField}
        />
        <ApplyFilterButton
          defaultOptions={defaultState}
          currentOptions={filterSelection}
          atLeastOne={atLeastOneSelected}
          onApplyFilter={handleApplyButton}
        />
      </div>
      <Divider />
      <List
        aria-label="notification type list"
        className={classes.popoverContainer}
        subheader={<li />}
      >
        {filteredOptions.map((option) => {
          return (
            <ListItem
              classes={{ root: classes.listRoot }}
              key={option.id}
              id={option.id}
              button
              onClick={() => handleChange(option.id)}
            >
              <ListItemIcon
                classes={{
                  root: classes.listIcon,
                }}
              >
                <GreenCheckbox
                  edge="start"
                  checked={filterSelection[option.id]}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  <span className={classes.listLabel}>{option.label}</span>
                }
                disableTypography
              />
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  handleCloseFilter: () => void;
}
