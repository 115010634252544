import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DashboardViewTypes,
  ReducerStates,
  Views,
} from "../../../helpers/constants";
import store from "../../../helpers/store";
import { ErrorCodes } from "../../../services/constants";
import { IFailedRequest } from "../../../services/login.services";
import { getDropdownList } from "../../header/CommunityDropdown";
import {
  changeSelectedCommunity,
  changeSelectedOrganization,
} from "../../header/headerSlice";
import {
  setFrameOrigin,
  externalLogin,
  IExternalLoginPayload,
  logout,
  IExternalChangeViewPayload,
  IExternalLoginStatusPayload,
  IExternalSwitchCommunityPayload,
} from "../../login/loginSlice";
import { useAppDispatch } from "./../appHooks";

interface IFrameRequest {
  actionID: number;
  action: string;
  payload?:
    | IExternalLoginPayload
    | IExternalChangeViewPayload
    | IExternalLoginStatusPayload
    | IExternalSwitchCommunityPayload;
}
interface IFrameResponsePayload {
  view: string;
}
interface IFrameResponse {
  actionID: number;
  action: string;
  payload?: IFrameResponsePayload;
  result: {
    success: boolean;
    error?: IFailedRequest;
  };
}

const FrameResponseTypes = {
  success: true,
  failed: false,
};

const FrameActions = {
  logIn: "logIn",
  logInStatus: "logInStatus",
  logOut: "logOut",
  changeView: "changeView",
  switchCommunity: "switchCommunity",
};

const FrameErrorMessages = {
  missingParameters: "Missing request parameters",
  unknownAction: "Unknown action",
  badPayload: "Wrong payload for action type",
  invalidURL: "Invalid URL",
  tokenNotFound: "Token Not Found",
  stillInitializing: "StackCare is still initializing",
  notAuthorized: "Not Authorized",
  onlyDashboard:
    "Organization functionality is only available in the dashboard URL",
};

// Validates allowed origins
const validateOrigin = (origin: string) => {
  if (window.location.origin === origin) return false;
  const rx = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/gim;
  const allowedOrigins = JSON.parse(
    process.env.REACT_APP_ALLOWED_ORIGINS as string
  ) as string[];

  const originToValidate = origin.match(rx);
  const validOrigin = allowedOrigins.some((_origin) => {
    const allowedOrigin = _origin.match(rx);
    if (originToValidate !== null && allowedOrigin !== null) {
      return allowedOrigin[0] === originToValidate[0];
    }
    return false;
  });

  if (!validOrigin) {
    console.log("Origin not recognized", origin);
    console.log(
      "Failed to validate origin, allowed origins are:",
      allowedOrigins
    );
  }
  return validOrigin;
};

// Validates event data from postMessage structure
const validateEventData = (data: any) =>
  typeof data === "object" &&
  data.hasOwnProperty("actionID") &&
  data.hasOwnProperty("action") &&
  (data.action !== FrameActions.logOut
    ? data.hasOwnProperty("payload")
    : data.payload === undefined);

// Validate allowed actions
const validateAction = (action: string) =>
  typeof action === "string" && Object.keys(FrameActions).includes(action);

function instanceOfLoginActionPayload(
  object: any
): object is IExternalLoginPayload {
  return "refreshToken" in object && "userID" in object && "clientID" in object;
}
function instanceOfChangeViewActionPayload(
  object: any
): object is IExternalChangeViewPayload {
  return "view" in object;
}

function instanceOfLoginStatusActionPayload(
  object: any
): object is IExternalLoginStatusPayload {
  return "userID" in object;
}

function instanceOfSwitchCommunityActionPayload(
  object: any
): object is IExternalSwitchCommunityPayload {
  return "id" in object && "type" in object;
}

function useFrame() {
  /* State */
  const [isFrame, setIsFrame] = useState(false);

  /* Hooks */
  const dispatch = useAppDispatch();
  const history = useHistory();

  /* Effects */
  useEffect(() => {
    if (window.self !== window.top) {
      if (!isFrame) setIsFrame(true);

      // Handler function for frame events
      const handleFrameEvent = async (event: MessageEvent) => {
        // Validate origin
        if (!validateOrigin(event.origin)) return;

        const origin = store.getState().loginState.origin;
        // If origin is valid and is not defined yet, define it
        if (origin === undefined) {
          dispatch(setFrameOrigin(event.origin));
        }

        const isLogged = store.getState().loginState.isLogged;
        const userToken = store.getState().loginState.userToken;
        const initialLoadFinished =
          store.getState().appState.state === ReducerStates.SUCCEEDED;

        // Validate data sent by parent app
        if (!validateEventData(event.data)) {
          window.parent.postMessage(
            {
              result: {
                success: FrameResponseTypes.failed,
                error: {
                  code: ErrorCodes.BAD_REQUEST,
                  message: FrameErrorMessages.missingParameters,
                },
              },
            },
            event.origin
          );
          return;
        }
        const { actionID, action, payload } = event.data as IFrameRequest;

        // Validate if is a kwnown action
        if (!validateAction(action)) {
          window.parent.postMessage(
            {
              actionID: actionID,
              action: action,
              result: {
                success: FrameResponseTypes.failed,
                error: {
                  code: ErrorCodes.BAD_REQUEST,
                  message: FrameErrorMessages.unknownAction,
                },
              },
            },
            event.origin
          );
          return;
        }

        // Function to respond to parent app
        const respondToParent = (
          requestResult: boolean,
          error?: IFailedRequest,
          payload?: IFrameResponsePayload
        ) => {
          const response: IFrameResponse = {
            actionID: actionID,
            action: action,
            result: {
              success: requestResult,
            },
          };
          if (error !== undefined) {
            response.result.error = error;
          }
          if (payload !== undefined) {
            response.payload = payload;
          }
          window.parent.postMessage(response, event.origin);
        };

        // Choose action and execute it
        switch (action) {
          case FrameActions.logIn:
            // Dispatch action to validate received token and validate it
            if (
              typeof payload === "object" &&
              instanceOfLoginActionPayload(payload)
            ) {
              const externalLoginResult = await dispatch(
                externalLogin(payload)
              );

              // If request was successful and userID is the same, confirm to parent app
              if (externalLogin.fulfilled.match(externalLoginResult)) {
                respondToParent(FrameResponseTypes.success);
              } else {
                // If request failed, send error to parent app
                respondToParent(
                  FrameResponseTypes.failed,
                  externalLoginResult.payload
                );
              }
            } else {
              const error: IFailedRequest = {
                code: ErrorCodes.BAD_REQUEST,
                message: FrameErrorMessages.badPayload,
              };
              // Payload validation failed
              respondToParent(FrameResponseTypes.failed, error);
            }
            break;
          case FrameActions.logInStatus:
            // Check if initialization is complete , if still initializing, respond and break
            if (!initialLoadFinished) {
              const error: IFailedRequest = {
                code: ErrorCodes.INITIALIZING,
                message: FrameErrorMessages.stillInitializing,
              };
              respondToParent(FrameResponseTypes.failed, error);
              break;
            }
            // Validate payload
            if (
              typeof payload === "object" &&
              instanceOfLoginStatusActionPayload(payload)
            ) {
              // Validate if token is present and valid
              if (isLogged && userToken) {
                // Validate if is the correct user
                if (userToken.user_id === payload.userID) {
                  respondToParent(FrameResponseTypes.success);
                } else {
                  const error: IFailedRequest = {
                    code: ErrorCodes.TOKEN_NOT_FOUND,
                    message: FrameErrorMessages.tokenNotFound,
                  };
                  // Payload validation failed
                  respondToParent(FrameResponseTypes.failed, error);
                }
              } else {
                // Token not found
                const error: IFailedRequest = {
                  code: ErrorCodes.TOKEN_NOT_FOUND,
                  message: FrameErrorMessages.tokenNotFound,
                };
                // Payload validation failed
                respondToParent(FrameResponseTypes.failed, error);
              }
            } else {
              const error: IFailedRequest = {
                code: ErrorCodes.BAD_REQUEST,
                message: FrameErrorMessages.badPayload,
              };
              respondToParent(FrameResponseTypes.failed, error);
            }
            break;
          case FrameActions.logOut:
            dispatch(logout());
            respondToParent(FrameResponseTypes.success);
            break;
          case FrameActions.changeView:
            // Validate if user is logged
            if (!isLogged) {
              const error: IFailedRequest = {
                code: ErrorCodes.TOKEN_NOT_FOUND,
                message: FrameErrorMessages.tokenNotFound,
              };
              respondToParent(FrameResponseTypes.failed, error);
              return;
            }

            if (
              typeof payload === "object" &&
              instanceOfChangeViewActionPayload(payload)
            ) {
              // Validate with urls
              const validURL = Object.values(Views).includes(
                `/${payload.view}`
              );
              if (validURL) {
                if (
                  store.getState().headerState.selectedOrganization !==
                    undefined &&
                  `/${payload.view}` !== Views.DASHBOARD
                ) {
                  const error: IFailedRequest = {
                    code: ErrorCodes.BAD_REQUEST,
                    message: FrameErrorMessages.onlyDashboard,
                  };
                  respondToParent(FrameResponseTypes.failed, error);
                } else {
                  history.push(`/${payload.view}`);
                  respondToParent(FrameResponseTypes.success);
                }
              } else {
                const error: IFailedRequest = {
                  code: ErrorCodes.BAD_REQUEST,
                  message: FrameErrorMessages.invalidURL,
                };
                respondToParent(FrameResponseTypes.failed, error);
              }
            } else {
              const error: IFailedRequest = {
                code: ErrorCodes.BAD_REQUEST,
                message: FrameErrorMessages.badPayload,
              };
              respondToParent(FrameResponseTypes.failed, error);
            }
            break;
          case FrameActions.switchCommunity:
            // Validate if user is logged
            if (!isLogged) {
              const error: IFailedRequest = {
                code: ErrorCodes.TOKEN_NOT_FOUND,
                message: FrameErrorMessages.tokenNotFound,
              };
              respondToParent(FrameResponseTypes.failed, error);
              return;
            }

            if (
              typeof payload === "object" &&
              instanceOfSwitchCommunityActionPayload(payload)
            ) {
              // Switch to dashboard if switching to organization
              if (
                window.location.pathname !== Views.DASHBOARD &&
                window.location.pathname !== Views.SETTINGS &&
                payload.type === DashboardViewTypes.ORGANIZATION
              )
                history.push(Views.DASHBOARD);
              // Validate if type of payload
              if (payload.type === DashboardViewTypes.COMMUNITY) {
                const currentPath = {
                  view: window.location.pathname.replace("/", ""),
                };
                const selectedCommunity = store.getState().headerState
                  .selectedCommunity;
                // Validate community
                // If is the same community id, respond with success and do nothing
                if (payload.id !== selectedCommunity?.id) {
                  const communities = store.getState().headerState.communities;
                  const validCommunity = communities?.find(
                    (community) => community.id === payload.id
                  );
                  if (validCommunity) {
                    dispatch(changeSelectedCommunity(validCommunity));
                    respondToParent(
                      FrameResponseTypes.success,
                      undefined,
                      currentPath
                    );
                    return;
                  } else {
                    const error: IFailedRequest = {
                      code: ErrorCodes.UNAUTHORIZED,
                      message: FrameErrorMessages.notAuthorized,
                    };
                    respondToParent(FrameResponseTypes.failed, error);
                  }
                } else {
                  respondToParent(
                    FrameResponseTypes.success,
                    undefined,
                    currentPath
                  );
                }
              } else if (DashboardViewTypes.ORGANIZATION === payload.type) {
                const currentPath = {
                  view: window.location.pathname.replace("/", ""),
                };
                // Validate org
                const selectedOrganization = store.getState().headerState
                  .selectedOrganization;
                if (payload.id !== selectedOrganization) {
                  const communities = store.getState().headerState.communities;
                  const dropdownList = getDropdownList(communities ?? []);
                  const organization = dropdownList.find(
                    (listItem) =>
                      listItem.id === payload.id &&
                      listItem.type === DashboardViewTypes.ORGANIZATION
                  );
                  if (organization !== undefined) {
                    dispatch(changeSelectedOrganization(payload.id));
                    if (organization.sublist === undefined) return;
                    respondToParent(
                      FrameResponseTypes.success,
                      undefined,
                      currentPath
                    );
                  } else {
                    const error: IFailedRequest = {
                      code: ErrorCodes.UNAUTHORIZED,
                      message: FrameErrorMessages.notAuthorized,
                    };
                    respondToParent(FrameResponseTypes.failed, error);
                  }
                } else {
                  respondToParent(
                    FrameResponseTypes.success,
                    undefined,
                    currentPath
                  );
                  return;
                }
              } else {
                // error type not recognized
                const error: IFailedRequest = {
                  code: ErrorCodes.BAD_REQUEST,
                  message: FrameErrorMessages.badPayload,
                };
                respondToParent(FrameResponseTypes.failed, error);
                return;
              }
            } else {
              const error: IFailedRequest = {
                code: ErrorCodes.BAD_REQUEST,
                message: FrameErrorMessages.badPayload,
              };
              respondToParent(FrameResponseTypes.failed, error);
            }
            break;
          default:
            return;
        }
      };
      // Subscribe listener with callback function
      window.addEventListener("message", handleFrameEvent, false);
      return () => {
        window.removeEventListener("message", handleFrameEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFrame;
}

export default useFrame;
