import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeader: {
      opacity: "0.54",
      wordBreak: "break-all",
    },
    sectionSecondary: {
      opacity: "0.54",
    },
  })
);

export default function SectionHeader(props: IProps) {
  const { text, complementaryText } = props;
  /* Hooks */
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography className={classes.sectionHeader}>{text}</Typography>
      {complementaryText && (
        <Typography variant="caption" className={classes.sectionSecondary}>
          {complementaryText}
        </Typography>
      )}
    </Box>
  );
}

interface IProps {
  text: string;
  complementaryText?: string;
}
