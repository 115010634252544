import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notLoggedContainer: {
      width: "760px",
      height: "550px",
      margin: "auto",
      overflow: "hidden",
      display: "flex",
      borderRadius: 16,
      lineHeight: 1,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        boxShadow: "none",
        borderRadius: "0",
      },
    },
    leftPanel: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      flex: "1 0 35%",
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "140px",
      gap: theme.spacing(4),
      zIndex: 1,
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        flex: "auto",
      },
    },
    rightPanel: {
      backgroundColor: "#fff",
      display: "flex",
      flex: "0 1 65%",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(4, 3),
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(4),
        flex: "0 1 100%",
      },
    },
    badge: {
      display: "block",
      height: "auto",
    },
    subtitleContainer: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& img": {
        maxWidth: "100%",
        margin: "auto",
      },
    },
  })
);

export const LeftPanel = (props: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box className={classes.leftPanel} boxShadow={5}>
      {props.children}
    </Box>
  );
};

export const RightPanel = (props: { children: React.ReactNode }) => {
  const classes = useStyles();
  return <div className={classes.rightPanel}>{props.children}</div>;
};

export const MainContainer = (props: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box className={classes.notLoggedContainer} boxShadow={5}>
      {props.children}
    </Box>
  );
};
