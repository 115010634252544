import React, { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { minToolbarHeight } from "../app/App";
import { useAppSelector } from "../app/appHooks";
import { CommunitiesFilterList } from "./CommunitiesFilterList";
import { NotificationStatesFilterList } from "./NotificationStatesFilterList";
import { useDispatch } from "react-redux";
import {
  setCommunityFilter,
  setStatusFilter,
  setUserFilter,
  setIsCommunityFiltered,
  setIsStatusFiltered,
  setIsUserFiltered,
} from "./dashboardSlice";
import { useTranslation } from "react-i18next";
import { IUserDetails } from "../../services/header.services";
import { CommunityUserFilterList } from "./CommunityUserFilterList";
import { EventStatus } from "../../helpers/constants";
import { tabBarHeight } from "./Dashboard";

const useStyles = makeStyles<
  Theme,
  { showBanner: boolean; minimizedTray: boolean }
>((theme: Theme) =>
  createStyles({
    panel: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "350px",
      backgroundColor: "#fff",
      boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.3)",
      zIndex: 1000,
      transition: "transform 0.2s ease-in-out",
      transform: "translateX(0)",
      height: ({ showBanner }) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      maxHeight: ({ showBanner }) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      paddingBottom: ({ minimizedTray }) => (minimizedTray ? tabBarHeight : 0),
    },
    panelHidden: {
      transform: "translateX(100%)",
    },
    closeButtonHeader: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      top: 0,
      zIndex: 10,
      backgroundColor: "white",
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2),
      height: "30px",
    },
    closeButton: {
      cursor: "pointer",
    },
    sectionTitle: {
      fontWeight: "bold",
      fontSize: "1.1rem",
      marginBottom: "10px",
    },
    communitiesSection: {
      marginTop: "10px",
      marginLeft: "35px",
    },
    notificationStatesSection: {
      marginTop: "20px",
      marginLeft: "35px",
    },
    allNoneButtonContainer: {
      display: "flex",
    },
    allNoneButton: {
      padding: theme.spacing(0.5, 1),
      width: "35px",
      cursor: "pointer",
      textAlign: "center",
      backgroundColor: "transparent",
      paddingRight: "10px",
    },
    allNoneButtonText: {
      fontSize: "0.7rem",
    },
    applyButton: {
      minWidth: "140px",
    },
    resetButton: {
      minWidth: "140px",
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      borderWidth: "2px",
      borderStyle: "solid",
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        borderColor: theme.palette.error.main,
      },
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "12%",
      margin: 0,
      padding: 0,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 -6px",
    },
    filterContainer: {
      maxHeight: "84%",
      overflowY: "auto",
      overflowX: "hidden",
      boxSizing: "border-box",
    },
  })
);

export const AllNotificationsFilterPanel = (props: IProps) => {
  const { show, onClose, showBanner, minimizedTray, userList } = props;
  const classes = useStyles({ showBanner, minimizedTray });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const panelRef = useRef<HTMLDivElement>(null);

  const [selectedCommunities, setSelectedCommunities] = useState<string[]>([]);
  const [selectedStatusStates, setSelectedStatusStates] = useState<string[]>(
    []
  );
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [communityFilteredState, setCommunityFilteredState] = useState<boolean>(
    false
  );
  const [statusFilteredState, setStatusFilteredState] = useState<boolean>(
    false
  );
  const [userFilteredState, setUserFilteredState] = useState<boolean>(false);
  const [applyEnabled, setApplyEnabled] = useState(false);
  const [resetEnabled, setResetEnabled] = useState(false);

  const communities = useAppSelector(
    (states) => states.headerState.communities
  );

  const communityFilter = useAppSelector(
    (states) => states.dashboardState.communityFilter
  );
  const statusFilter = useAppSelector(
    (states) => states.dashboardState.statusFilter
  );
  const userFilter = useAppSelector(
    (states) => states.dashboardState.userFilter
  );

  const isCommunityFiltered = useAppSelector(
    (states) => states.dashboardState.isCommunityFiltered
  );
  const isStatusFiltered = useAppSelector(
    (states) => states.dashboardState.isStatusFiltered
  );
  const isUserFiltered = useAppSelector(
    (states) => states.dashboardState.isUserFiltered
  );

  useEffect(() => {
    setSelectedCommunities(communityFilter);
    setSelectedStatusStates(statusFilter);
    setSelectedUsers(userFilter);
  }, [communities, communityFilter, statusFilter, userFilter]);

  useEffect(() => {
    const isCommunityDifferent =
      selectedCommunities.length !== communityFilter.length ||
      selectedCommunities.some(
        (communityId) => !communityFilter.includes(communityId)
      );

    const isStatusDifferent =
      selectedStatusStates.length !== statusFilter.length ||
      selectedStatusStates.some((stateId) => !statusFilter.includes(stateId));

    const isUserDifferent =
      selectedUsers.length !== userFilter.length ||
      selectedUsers.some((userId) => !userFilter.includes(userId));

    setApplyEnabled(
      isCommunityDifferent || isStatusDifferent || isUserDifferent
    );
  }, [
    selectedCommunities,
    communityFilter,
    selectedStatusStates,
    statusFilter,
    selectedUsers,
    userFilter,
  ]);

  useEffect(() => {
    setResetEnabled(isCommunityFiltered || isStatusFiltered || isUserFiltered);
  }, [isCommunityFiltered, isStatusFiltered, isUserFiltered]);

  const handleSelectCommunity = (communityId: string) => {
    setSelectedCommunities((prevSelected) => {
      const updatedSelected = prevSelected.includes(communityId)
        ? prevSelected.filter((id) => id !== communityId)
        : [...prevSelected, communityId];

      const allCommunitiesSelected =
        updatedSelected.length === communities?.length;
      setCommunityFilteredState(!allCommunitiesSelected);
      return updatedSelected;
    });
  };

  const handleSelectNotificationState = (stateId: string) => {
    setSelectedStatusStates((prevSelected) => {
      const updatedSelected = prevSelected.includes(stateId)
        ? prevSelected.filter((id) => id !== stateId)
        : [...prevSelected, stateId];

      const defaultStates = Object.values(EventStatus).filter(
        (status) => status !== EventStatus.RESOLVED
      ) as EventStatus[];
      const includesResolved = updatedSelected.includes(EventStatus.RESOLVED);
      const allDefaultStatesSelected =
        updatedSelected.length === defaultStates.length &&
        updatedSelected.every((state) =>
          defaultStates.includes(state as EventStatus)
        );

      setStatusFilteredState(
        includesResolved ||
          (updatedSelected.length > 0 && !allDefaultStatesSelected)
      );
      return updatedSelected;
    });
  };

  const handleSelectUsers = (userId: string) => {
    setSelectedUsers((prevSelected) => {
      const updatedSelected = prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId];

      const allUsersSelected = updatedSelected.length === userList.length;
      setUserFilteredState(!allUsersSelected);

      return updatedSelected;
    });
  };

  const handleApply = () => {
    if (selectedCommunities !== communityFilter) {
      dispatch(setCommunityFilter(selectedCommunities));
      dispatch(setIsCommunityFiltered(communityFilteredState));
    }

    if (selectedStatusStates !== statusFilter) {
      dispatch(setStatusFilter(selectedStatusStates));
      dispatch(setIsStatusFiltered(statusFilteredState));
    }

    if (selectedUsers !== userFilter) {
      dispatch(setUserFilter(selectedUsers));
      dispatch(setIsUserFiltered(userFilteredState));
    }

    onClose();
  };

  const handleReset = () => {
    handleSelectAllCommunities();
    handleSelectAllUsers();
    handleSelectAllStatuses();
  };

  const handleClose = () => {
    setSelectedCommunities(communityFilter);
    setCommunityFilteredState(isCommunityFiltered);
    setSelectedStatusStates(statusFilter);
    setStatusFilteredState(isStatusFiltered);
    setSelectedUsers(userFilter);
    setUserFilteredState(isUserFiltered);
    onClose();
  };

  const handleSelectAllCommunities = () => {
    setCommunityFilteredState(false);
    if (communities) {
      setSelectedCommunities(communities.map((community) => community.id));
    }
  };

  const handleSelectNoneCommunities = () => {
    setCommunityFilteredState(true);
    setSelectedCommunities([]);
  };

  const handleSelectAllStatuses = () => {
    setStatusFilteredState(false);
    setSelectedStatusStates(Object.values(EventStatus));
  };

  const handleSelectAllUsers = () => {
    setUserFilteredState(false);
    if (userList) {
      setSelectedUsers(userList.map((user) => user.id));
    }
  };

  const handleSelectNoneUsers = () => {
    setUserFilteredState(true);
    setSelectedUsers([]);
  };

  useEffect(() => {
    if (!show) return;
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div
      className={
        show ? classes.panel : `${classes.panel} ${classes.panelHidden}`
      }
      ref={panelRef}
    >
      <div className={classes.closeButtonHeader}>
        <CloseIcon className={classes.closeButton} onClick={handleClose} />
      </div>
      <div className={classes.filterContainer}>
        <div className={classes.communitiesSection}>
          <Typography className={classes.sectionTitle}>
            {t("communities")}
          </Typography>
          <div className={classes.allNoneButtonContainer}>
            <Box
              className={classes.allNoneButton}
              onClick={handleSelectAllCommunities}
            >
              <Typography className={classes.allNoneButtonText}>
                {t("all")}
              </Typography>
            </Box>
            <Box
              className={classes.allNoneButton}
              onClick={handleSelectNoneCommunities}
            >
              <Typography className={classes.allNoneButtonText}>
                {t("none")}
              </Typography>
            </Box>
          </div>
          <CommunitiesFilterList
            communities={communities}
            selectedCommunities={selectedCommunities}
            onSelectCommunity={handleSelectCommunity}
          />
        </div>
        <div className={classes.notificationStatesSection}>
          <Typography className={classes.sectionTitle}>
            {t("notification_states")}
          </Typography>
          <NotificationStatesFilterList
            selectedStates={selectedStatusStates}
            onSelectState={handleSelectNotificationState}
          />
        </div>
        <div className={classes.notificationStatesSection}>
          <Typography className={classes.sectionTitle}>
            {t("assignees")}
          </Typography>
          <div className={classes.allNoneButtonContainer}>
            <Box
              className={classes.allNoneButton}
              onClick={handleSelectAllUsers}
            >
              <Typography className={classes.allNoneButtonText}>
                {t("all")}
              </Typography>
            </Box>
            <Box
              className={classes.allNoneButton}
              onClick={handleSelectNoneUsers}
            >
              <Typography className={classes.allNoneButtonText}>
                {t("none")}
              </Typography>
            </Box>
          </div>
          <CommunityUserFilterList
            users={userList}
            selectedUsers={selectedUsers}
            onSelectUser={handleSelectUsers}
          />
        </div>
      </div>
      <Grid container className={classes.buttonWrapper}>
        <Grid item xs={6} className={classes.buttonContainer}>
          <Button
            className={classes.resetButton}
            onClick={handleReset}
            variant="outlined"
            disabled={!resetEnabled}
          >
            {t("reset")}
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.buttonContainer}>
          <Button
            className={classes.applyButton}
            onClick={handleApply}
            color="primary"
            variant="contained"
            disabled={!applyEnabled}
          >
            {t("apply")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

interface IProps {
  show: boolean;
  onClose: () => void;
  showBanner: boolean;
  minimizedTray: boolean;
  userList: IUserDetails[];
}
