import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export const LookUpQueryString = "lng";

const languageDetectionOptions = {
  // order and from where user language should be detected
  order: ["queryString", "localStorage", "navigator"],
  lookupLocalStorage: LookUpQueryString,
};

if (localStorage.getItem(LookUpQueryString) === "undefined") {
  localStorage.removeItem(LookUpQueryString);
}
localStorage.removeItem("i18nextLng");

/** Initialization for the localization library, loads
 * resources from a JSON structure that contains the
 * different translations available.
 */
i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: languageDetectionOptions,
    fallbackLng: "en",
    debug:
      process.env.REACT_APP_ENVIRONMENT &&
      (process.env.REACT_APP_ENVIRONMENT === "development" ||
        process.env.REACT_APP_ENVIRONMENT === "staging"),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
