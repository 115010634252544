import React from "react";
import { Bar, defaults } from "react-chartjs-2";
import { DateTime } from "luxon";
import { HourFormat, timeConfigurations } from "../../helpers/datetime";
import ChartDataLabels, {
  Context as OptionsContext,
} from "chartjs-plugin-datalabels";
import i18n from "../../i18n";
import { useMediaQuery, useTheme } from "@material-ui/core";

defaults.font.family = "GothamRoundedBook";

export default function BarChart(props: IProps) {
  const { min, max, labels, values } = props;

  const convertToScalar = (date: DateTime) => date.hour + date.minute / 60;

  /* Hooks */
  const theme = useTheme();
  const isLG = useMediaQuery(theme.breakpoints.up("lg"));
  const { timeFormat, locale } = timeConfigurations();

  const legendValue = { LG: 0.8, MD: 0.9 };
  const hourValues = values.flatMap((item) => {
    return item ? (item.hour * 60 + item.minute) / 60.0 : null;
  });

  const options = {
    indexAxis: "y",
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: false },
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        color: (context: OptionsContext) => {
          const date = values[context.dataIndex];
          return date
            ? date.hour === max.hour
              ? theme.palette.error.main
              : "black"
            : theme.palette.dark.main;
        },
        opacity: (context: OptionsContext) => {
          const date = values[context.dataIndex];
          return date ? (date.hour === max.hour ? "1" : "0.9") : "0.7";
        },
        align: (context: OptionsContext) => {
          const value = (hourValues[context.dataIndex] || 0) - min.hour;
          return value >= (isLG ? legendValue.LG : legendValue.MD)
            ? "left"
            : "right";
        },
        padding: {
          right: "10",
        },
        font: {
          family: theme.typography.secondaryFontFamily,
        },
        formatter: (value: number, context: OptionsContext) => {
          const date = values[context.dataIndex];
          if (date) {
            const dateStr = date.setLocale(locale).toFormat(timeFormat);
            return date.hour === max.hour
              ? `${i18n.t("after")} ${dateStr}`
              : dateStr;
          } else {
            return i18n.t("no_activity");
          }
        },
      },
    },
    scales: {
      xAxes: {
        display: true,
        min: convertToScalar(min),
        max:
          convertToScalar(max) % 1 === 0
            ? convertToScalar(max)
            : // When calculating the values for the x axis (min to max, eg: 7, 7.5, 8, 8.5...), the chart is omiting 13
              // so the chart its missing one grid line when the local time end is 13:30,
              // adding a small value forces the chart to display all values
              convertToScalar(max) + 0.0000001,
        ticks: {
          stepSize: 0.5,
          callback: function (value: number) {
            if (value % 1 === 0) {
              const date = DateTime.fromObject({
                hour: value,
              });
              return date.setLocale(locale).toFormat(HourFormat());
            }
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: hourValues,
        backgroundColor: [theme.palette.gradientDay.light],
        barThickness: 20,
        borderColor: ["#0000"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Bar
      type="horizontalBar"
      data={data}
      options={options}
      plugins={[ChartDataLabels]}
      width={100}
      height={40}
    />
  );
}

interface IProps {
  id: string;
  min: DateTime;
  max: DateTime;
  labels: string[];
  values: (DateTime | null)[];
}
