import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme, Typography } from "@material-ui/core";
import { SystemStatus } from "../../services/dashboard.services";
import { DateTimeShortFormatTZ } from "../../helpers/datetime";
import i18n from "../../i18n";
import { MaintenanceTypes } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBar: {
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
      display: "flex",
      justifyContent: "space-between",
    },
    dialogBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "550px",
    },
    dialogTitleBox: {
      paddingTop: "25px",
    },
    dialogTitle: {
      fontWeight: "bold",
      textAlign: "center",
    },
    dialogContentBox: {
      width: "550px",
    },
    dialogContentText: {
      marginBottom: "20px",
      textAlign: "center",
    },
    dialogButtons: {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
      height: "50px",
    },
  })
);

// Display a modal to make the user acknowledge something
export const BannerDialog = (props: iProps) => {
  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const { toggle, onClose } = props;
  const closeDialog = () => {
    onClose();
  };

  let maintenanceCompletionTime = "";
  if (props.status.status === MaintenanceTypes.maintenanceInProgress) {
    if (
      props.status.planned_maintenance &&
      props.status.planned_maintenance.end_time
    ) {
      maintenanceCompletionTime = DateTimeShortFormatTZ(
        props.status.planned_maintenance.end_time
      );
    }
  }

  const getStatusBody = (systemStatus: SystemStatus) => {
    switch (systemStatus.status) {
      case MaintenanceTypes.maintenanceInProgress:
        switch (systemStatus.planned_maintenance?.maintenance_type) {
          case MaintenanceTypes.maintenanceBackend:
          case MaintenanceTypes.maintenanceData:
          default:
            return (
              <>
                <Typography className={classes.dialogContentText}>
                  {t("maintenance_in_progress_message")}
                </Typography>
                {maintenanceCompletionTime && (
                  <Typography className={classes.dialogContentText}>
                    {i18n.t("maintenance_completion_message", {
                      completionTime: maintenanceCompletionTime,
                    })}
                  </Typography>
                )}
              </>
            );
        }
      case MaintenanceTypes.outageLTE:
        return (
          <>
            <Typography className={classes.dialogContentText}>
              {t("outage_lte_message_1")}
            </Typography>
            <Typography className={classes.dialogContentText}>
              {t("outage_lte_message_2")}
            </Typography>
            <Typography className={classes.dialogContentText}>
              {t("outage_lte_message_3")}
            </Typography>
          </>
        );
      case MaintenanceTypes.outageNotifications:
        return (
          <>
            <Typography className={classes.dialogContentText}>
              {t("outage_notifications_message")}
            </Typography>
          </>
        );
      case MaintenanceTypes.outageData:
        return (
          <>
            <Typography className={classes.dialogContentText}>
              {t("outage_data_message")}
            </Typography>
          </>
        );
      case MaintenanceTypes.outageBackend:
        return (
          <>
            <Typography className={classes.dialogContentText}>
              {t("outage_backend")}
            </Typography>
          </>
        );
      default:
        return (
          <>
            <Typography className={classes.dialogContentText}>
              {t("unplanned_outage")}
            </Typography>
          </>
        );
    }
  };

  return (
    <div>
      <Dialog
        open={toggle}
        aria-labelledby="banner-title"
        aria-describedby="banner-description"
        maxWidth="lg"
      >
        <DialogTitle className={classes.dialogTitleBox} id="banner-title">
          <Typography className={classes.dialogTitle}>{props.title}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContentBox}>
          {getStatusBody(props.status)}
        </DialogContent>
        <Button
          onClick={() => {
            closeDialog();
          }}
          color="default"
          variant="outlined"
          className={classes.dialogButtons}
        >
          {t("ok")}
        </Button>
      </Dialog>
    </div>
  );
};
export default BannerDialog;

export interface iProps {
  toggle: boolean;
  title: string;
  status: SystemStatus;
  onClose: () => void;
}
