import { configureStore } from "@reduxjs/toolkit";

import appReducer from "../features/app/appSlice";
import headerReducer from "../features/header/headerSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import loginReducer from "../features/login/loginSlice";
import commonReducer from "../features/common/commonSlice";
import analyticsReducer from "../features/analytics/analyticsSlice";
import settingsReducer from "../features/settings/settingsSlice";
import devicesReducer from "../features/devices/devicesSlice";
import reportsReducer from "../features/reports/reportsSlice";

const store = configureStore({
  reducer: {
    headerState: headerReducer,
    dashboardState: dashboardReducer,
    notificationsState: notificationsReducer,
    loginState: loginReducer,
    appState: appReducer,
    commonState: commonReducer,
    analyticsState: analyticsReducer,
    settingsState: settingsReducer,
    devicesState: devicesReducer,
    reportsState: reportsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type IAppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
