import { createAsyncThunk } from "@reduxjs/toolkit";
import { appServices, IAppLocalStorage } from "../../services/app.services";
import {
  dashboardServices,
  IAssignEventData,
  IEvent,
  IEventNotesData,
  ITogglePauseNotificationData,
} from "../../services/dashboard.services";
import { IFailedRequest, loginServices } from "../../services/login.services";
import {
  IResolveEventDataPassword,
  IResolveEventDataPin,
} from "../dashboard/dashboardSlice";
import store from "../../helpers/store";
import { getClientID } from "../login/loginSlice";
import {
  IProfileData,
  ICommunityInfoData,
  settingsServices,
  IUpdateRoleData,
  IUpdateCommunityUserData,
  IAddCommunityUserData,
  IDeleteUserData,
  IUpdateNotificationGroupData,
  IHandoffMessageData,
  IHandoffSettingsData,
  IScheduleEnabled,
  IAddNotificationGroupData,
  IResendInviteData,
} from "../../services/settings.services";
import { IUserDetails } from "../../services/header.services";
import firebase from "firebase/app";
import { getFirebaseConfig } from "../../firebaseConfig";

export const initialize = createAsyncThunk<
  IAppLocalStorage,
  void,
  {
    rejectValue: IFailedRequest;
  }
>("app/initialize", async (_, thunkAPI) => {
  try {
    const response = await appServices.initialize();

    firebase.initializeApp(getFirebaseConfig(response.env));

    if (response.userToken !== null) {
      // refresh token with backend before dispatching it
      try {
        response.userToken = await loginServices.refreshToken(
          response.userToken.refresh_token,
          response.parentClientID ?? getClientID()
        );
        if (response.userToken)
          store.dispatch(firebaseLogin(response.userToken.access_token));
      } catch (err) {
        response.userToken = null;
        return response;
      }
    }
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue((err as unknown) as IFailedRequest);
  }
});

// Assigns a responder to an event
export const assignEventResponder = createAsyncThunk(
  "dashboard/assignEventResponder",
  async (assignResponder: {
    assignData: IAssignEventData;
    fromDashboard: boolean;
    fromNotifications: boolean;
  }) => {
    const response = await dashboardServices.assignEventResponder(
      assignResponder.assignData
    );
    return response;
  }
);

interface IResolveEventPin {
  resolveEventData: IResolveEventDataPin;
  fromDashboard: boolean;
  fromNotifications: boolean;
}

// Resolve an event using the user pin number
export const resolveEventWithPin = createAsyncThunk<
  IEvent,
  IResolveEventPin,
  { rejectValue: boolean }
>(
  "dashboard/resolveEventWithPin",
  async (resolveEvent: IResolveEventPin, { rejectWithValue }) => {
    // If has pin, check if pin is correct, then resolve event
    const validatePin = await loginServices.validatePin({
      email: resolveEvent.resolveEventData.email,
      pin: resolveEvent.resolveEventData.pin || "",
    });
    if (validatePin) {
      const response = await dashboardServices.resolveEvent({
        eventID: resolveEvent.resolveEventData.eventID,
        resolutionID: resolveEvent.resolveEventData.resolutionID,
      });
      return response;
    } else {
      return rejectWithValue(false);
    }
  }
);

interface IResolveEventPassword {
  resolveEventData: IResolveEventDataPassword;
  fromDashboard: boolean;
  fromNotifications: boolean;
}
// Resolve an event using the user password
export const resolveEventWithPassword = createAsyncThunk<
  IEvent,
  IResolveEventPassword,
  { rejectValue: boolean }
>(
  "dashboard/resolveEventWithPassword",
  async (resolveEvent: IResolveEventPassword, { rejectWithValue }) => {
    // If doesn't have pin, check for password, is valid password, resolve event
    const validatePassword = await loginServices.validatePassword({
      email: resolveEvent.resolveEventData.email,
      password: resolveEvent.resolveEventData.password || "",
    });
    if (validatePassword) {
      const response = await dashboardServices.resolveEvent({
        eventID: resolveEvent.resolveEventData.eventID,
        resolutionID: resolveEvent.resolveEventData.resolutionID,
      });
      return response;
    } else {
      return rejectWithValue(false);
    }
  }
);

// Addes a new comment on an event
export const postNewComment = createAsyncThunk(
  "dashboard/postNewComment",
  async (eventNotesData: IEventNotesData, thunkAPI) => {
    const response = await dashboardServices.postNewComment(eventNotesData);
    return response;
  }
);

interface ITogglePauseNotification {
  togglePausedData: ITogglePauseNotificationData;
  fromDashboard: boolean;
  fromNotifications: boolean;
}

// Toogle pause state of a unit
export const toggleUnitPaused = createAsyncThunk(
  "dashboard/toggleUnitPaused",
  async (togglePaused: ITogglePauseNotification, { rejectWithValue }) => {
    const unit = await dashboardServices.togglePauseNotifications(
      togglePaused.togglePausedData
    );
    if (unit) {
      const response = await dashboardServices.getEvent(
        togglePaused.togglePausedData.eventId
      );
      return { unit: unit, event: response };
    }
    return rejectWithValue(false);
  }
);

export const firebaseLogin = createAsyncThunk(
  "login/firebaseLogin",
  async (accessToken: string) => {
    const response = await loginServices.authToFirebase(accessToken);
    return response.user !== null;
  }
);

export const submitMyProfileChanges = createAsyncThunk(
  "settings/submitMyProfileChanges",
  async (profileData: IProfileData) => {
    const response = await settingsServices.updateMyProfile(profileData);
    return response;
  }
);

export const submitCommunityInfoChanges = createAsyncThunk(
  "settings/submitCommunityInfoChanges",
  async (infoData: ICommunityInfoData) => {
    const response = await settingsServices.updateCommunityInfo(infoData);
    return response;
  }
);

export const updateCommunityUser = createAsyncThunk(
  "settings/updateCommunityUser",
  async (userData: {
    roleData?: IUpdateRoleData;
    userData?: IUpdateCommunityUserData;
  }) => {
    if (userData.roleData && userData.userData) {
      const [role, user] = await Promise.all([
        settingsServices.updateRole(userData.roleData),
        settingsServices.updateUser(userData.userData),
      ]);
      return { role, user };
    } else if (userData.roleData) {
      const role = await settingsServices.updateRole(userData.roleData);
      return { role, user: undefined };
    } else if (userData.userData) {
      const user = await settingsServices.updateUser(userData.userData);
      return { role: undefined, user };
    }
  }
);

export const addCommunityUser = createAsyncThunk<
  IUserDetails,
  IAddCommunityUserData,
  {
    rejectValue: IFailedRequest;
  }
>(
  "settings/addCommunityUser",
  async (userData: IAddCommunityUserData, { rejectWithValue }) => {
    try {
      const response = await settingsServices.addCommunityUser(userData);
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const deleteCommunityUser = createAsyncThunk<
  boolean,
  IDeleteUserData,
  {
    rejectValue: IFailedRequest;
  }
>(
  "settings/deleteCommunityUser",
  async (deleteData: IDeleteUserData, { rejectWithValue }) => {
    try {
      const response = await settingsServices.deleteCommunityUser(deleteData);
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const updateNotificationGroup = createAsyncThunk(
  "settings/updateNotificationGroup",
  async (groupData: IUpdateNotificationGroupData, { rejectWithValue }) => {
    try {
      const response = await settingsServices.updateNotificationGroup(
        groupData
      );
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const addNotificationGroup = createAsyncThunk(
  "settings/addNotificationGroup",
  async (groupData: IAddNotificationGroupData, { rejectWithValue }) => {
    try {
      const response = await settingsServices.addNotificationGroup(groupData);
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const deleteNotificationGroup = createAsyncThunk(
  "settings/deleteNotificationGroup",
  async (groupID: string, { rejectWithValue }) => {
    try {
      const response = await settingsServices.deleteNotificationGroup(groupID);
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const sendHandOff = createAsyncThunk(
  "settings/sendHandOff",
  async (messageData: IHandoffMessageData) => {
    await settingsServices.sendHandOff(messageData);
    return;
  }
);

export const toggleScheduleEnabled = createAsyncThunk(
  "settings/toggleScheduleEnabled",
  async (messageData: IScheduleEnabled) => {
    const response = await settingsServices.handoffScheduleToggle(messageData);
    return response;
  }
);

export const updateHandoffSettings = createAsyncThunk(
  "settings/updateHandOffSettings",
  async (messageData: IHandoffSettingsData) => {
    const response = await settingsServices.sendHandOffSettings(messageData);
    return response;
  }
);

export const loadHandoffSettings = createAsyncThunk(
  "header/loadHandoffSettings",
  async (community_id: string) => {
    const response = await Promise.all([
      settingsServices.getHandoffSettings(community_id),
    ]);
    return response;
  }
);

export const loadNotificationSettings = createAsyncThunk(
  "settings/loadNotificationSettings",
  async (community_id: string) => {
    const [eventTypes, eventConfigsCommunity] = await Promise.all([
      settingsServices.getCommunityEventTypes(community_id),
      settingsServices.getCommunityEventConfigs(community_id),
    ]);
    return { eventTypes, eventConfigsCommunity };
  }
);

export const resendInvitation = createAsyncThunk<
  boolean,
  IResendInviteData,
  {
    rejectValue: IFailedRequest;
  }
>(
  "settings/resendInvitation",
  async (userData: IResendInviteData, { rejectWithValue }) => {
    try {
      const response = await settingsServices.resendInvitation(userData);
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);
