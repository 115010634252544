import React from "react";

import { getAppLogs } from "../../services/utils";
import { DateTime } from "luxon";
import { useAppSelector } from "./appHooks";
import { Fab } from "@material-ui/core";
import Icons from "../../helpers/iconImports";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    floatingFab: {
      position: "absolute",
      bottom: 8,
      left: 8,
      opacity: 0.2,
      zIndex: 2000,
      "&:hover": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },

    icon: {
      height: "20px",
      width: "20px",
    },
    [`@media print`]: {
      floatingFab: {
        display: "none",
      },
    },
  })
);

export const DownloadLogs = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const appLogging = useAppSelector((state) => state.appState.appLogging);

  const showLoggingOptions =
    process.env.REACT_APP_ENVIRONMENT !== "production" && appLogging;

  const downloadLogs = () => {
    const element = document.createElement("a");
    const appLogs = getAppLogs();
    const file = new Blob([appLogs], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    const fileName = `stackcare-web-logs-${DateTime.now().toISO()}`;
    element.download = `${fileName}.log`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <React.Fragment>
      {showLoggingOptions && (
        <Fab
          className={classes.floatingFab}
          onClick={downloadLogs}
          disabled={!appLogging}
          aria-label="download logs"
          color="primary"
        >
          <img
            src={Icons.Log}
            alt={t("alt_text_on_watched_unit")}
            draggable={false}
            className={classes.icon}
          />
        </Fab>
      )}
    </React.Fragment>
  );
};
