import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      padding: theme.spacing(0.3, 3),
    },
    buttonLabel: {
      fontFamily: "inherit",
    },
  })
);

export const ApplyFilterButton = <T extends object, E extends object>(
  props: IProps<T, E>
) => {
  const {
    defaultOptions,
    needsToChangeToSave = true,
    currentOptions,
    atLeastOne,
    onApplyFilter,
  } = props;
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();

  const hasChanged =
    JSON.stringify(defaultOptions) === JSON.stringify(currentOptions);

  const needsToSelectOne = atLeastOne !== undefined ? !atLeastOne : false;

  return (
    <Button
      classes={{ text: classes.buttonText, label: classes.buttonLabel }}
      color="primary"
      disabled={
        (needsToChangeToSave ? hasChanged : needsToChangeToSave) ||
        needsToSelectOne
      }
      onClick={onApplyFilter}
    >
      {t("apply")}
    </Button>
  );
};

interface IProps<T, E> {
  defaultOptions: T;
  currentOptions: E;
  atLeastOne?: boolean;
  needsToChangeToSave?: boolean;
  onApplyFilter: () => void;
}
