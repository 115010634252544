import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ReducerStates } from "../../helpers/constants";
import { IReport, IUnit } from "../../services/dashboard.services";
import { sortUnits, isHelpAtHome } from "../dashboard/dashboardSlice";
import {
  changeSelectedCommunity,
  setAllNotifications,
} from "../header/headerSlice";
import { logout } from "../login/loginSlice";
import { downloadReport, generateReport } from "./reportsThunks";
import { IFailedRequest } from "../../services/login.services";
import { OpenTab } from "./ReportItem";

export interface IReportsState {
  units?: IUnit[];
  selectedUnit?: string;
  state: ReducerStates;
  firebaseState: ReducerStates;
  errorCode: string;
  errorMessage?: string;
  reports?: IReport[];
  selectedReport?: IReport;
}

export const initialReportsState: IReportsState = {
  units: undefined,
  selectedUnit: undefined,
  state: ReducerStates.IDLE,
  firebaseState: ReducerStates.IDLE,
  errorCode: "",
  errorMessage: "",
  reports: undefined,
  selectedReport: undefined,
};

const reportsAsyncThunks = [generateReport, downloadReport] as const;
// Check the state for every async thunk
const isAPendingAction = isPending(...reportsAsyncThunks);
const isAFulfilledAction = isFulfilled(...reportsAsyncThunks);
const isARejectedAction = isRejected(...reportsAsyncThunks);

const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    setSelectedUnit(state, action: PayloadAction<string>) {
      state.selectedUnit = action.payload;
      state.selectedReport = undefined;
    },
    loadCommunityUnits(state, action: PayloadAction<IUnit[]>) {
      const units: IUnit[] = action.payload;
      let tempUnits: IUnit[] = [];
      // sort communities by unit number
      if (units && units.length > 0) {
        const filteredUnits = units.filter(
          (unit) =>
            (unit.gateway_id && !isHelpAtHome(unit)) ||
            (unit.gateway_id &&
              isHelpAtHome(unit) &&
              unit.residents !== undefined &&
              unit.residents !== null &&
              unit.residents.length > 0)
        );
        tempUnits = sortUnits(filteredUnits);
      }
      state.units = tempUnits;
      state.firebaseState = ReducerStates.SUCCEEDED;
    },
    setSelectedReport(state, action: PayloadAction<IReport>) {
      state.selectedReport = action.payload;
    },
    initReports(state) {
      state.units = initialReportsState.units;
      state.selectedUnit = initialReportsState.selectedUnit;
      state.reports = initialReportsState.reports;
      state.selectedReport = initialReportsState.selectedReport;
    },
    loadFirebaseReports(state) {
      state.firebaseState = ReducerStates.PENDING;
    },
    failedFirebaseReports(state) {
      state.firebaseState = ReducerStates.FAILED;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeSelectedCommunity, (state) => {
        state.state = ReducerStates.IDLE;
        state.units = initialReportsState.units;
        state.selectedUnit = initialReportsState.selectedUnit;
        state.reports = initialReportsState.reports;
      })
      .addCase(setAllNotifications, (state) => {
        state.state = ReducerStates.IDLE;
        state.units = initialReportsState.units;
        state.selectedUnit = initialReportsState.selectedUnit;
        state.reports = initialReportsState.reports;
      })
      .addCase(generateReport.pending, (state, action) => {
        state.state = ReducerStates.PENDING;
      })
      .addCase(generateReport.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(downloadReport.pending, (state, action) => {
        state.state = ReducerStates.PENDING;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
        if (action.payload !== null) {
          let tempReports: IReport[] = [];
          const loadedReport = action.payload;
          if (state.reports) {
            tempReports = state.reports;
          }
          state.selectedReport = action.payload;
          const report = state.reports?.find(
            (report) =>
              report.unit_id === loadedReport.unit_id &&
              report.report_type === loadedReport.report_type &&
              report.report_date === loadedReport.report_date
          );
          if (report === undefined) {
            tempReports.push(loadedReport);
          }
          if (tempReports.length > 0) {
            state.reports = tempReports;
          }
          OpenTab(loadedReport);
        }
      })
      .addCase(logout.type, (state) => {
        return initialReportsState;
      })
      .addMatcher(isAPendingAction, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialReportsState.errorCode;
        state.errorMessage = initialReportsState.errorMessage;
      })
      .addMatcher(isAFulfilledAction, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addMatcher(isARejectedAction, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      });
  },
});

const { reducer } = reportsSlice;

export const {
  setSelectedUnit,
  loadCommunityUnits,
  setSelectedReport,
  initReports,
  loadFirebaseReports,
  failedFirebaseReports,
} = reportsSlice.actions;

export default reducer;
