import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import {
  getEmergencyAppUsers,
  getOrderedResidentContacts,
} from "./settingsHelpers";
import { EmergencyContactTable } from "./emergencyDragnDrop/EmergencyContactTable";
import { useEffect } from "react";
import { initEmergencyState } from "./settingsSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    residentContactsForm: {
      padding: theme.spacing(6, 7),
      height: "100%",
      overflowY: "auto",
      [theme.breakpoints.up("lg")]: {
        width: "850px",
      },
      [theme.breakpoints.down("lg")]: {
        width: "750px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "650px",
      },
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
  })
);

export const EmergencyContacts = () => {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const residentID = useAppSelector((state) => {
    const selectedUnit = state.settingsState.selectedUnit;
    if (selectedUnit !== undefined) {
      const unit = state.headerState.units?.find(
        (unit) => unit.id === selectedUnit
      );
      if (unit && unit.residents && unit.residents.length > 0) {
        return unit.residents[0].id;
      }
    }
    return undefined;
  });

  //Returns resident contacts that can be used as emergency contacts
  const emergencyResidentContacts = useAppSelector((state) => {
    const selectedUnit = state.settingsState.selectedUnit;
    if (selectedUnit !== undefined) {
      const unit = state.headerState.units?.find(
        (unit) => unit.id === selectedUnit
      );
      if (unit && unit.residents && unit.residents.length > 0) {
        return getOrderedResidentContacts(
          state.commonState.contactInformation[unit.residents[0].id]
            ?.contacts ?? []
        );
      }
    }
    return [];
  });

  //Returns users that can be used as emergency contacts
  const emergencyAppUsers = useAppSelector((state) => {
    return getEmergencyAppUsers(
      state.headerState.users ?? [],
      state.settingsState.selectedUnit
    );
  });

  useEffect(() => {
    dispatch(
      initEmergencyState({
        users: emergencyAppUsers,
        contacts: emergencyResidentContacts,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.residentContactsForm} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("emergency_contacts")}</h2>
      <EmergencyContactTable residentID={residentID ?? ""} />
    </Paper>
  );
};
