import { IconButton, Input, InputAdornment } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWidth: {
      width: "100%",
    },
  })
);

/* Components that displays an input with a dropdown icon, is used with a popover */
export const DropdownInput = (props: IProps) => {
  const { id, type, value, disabled, handleClick } = props;
  const classes = useStyles();
  return (
    <Input
      id={id}
      type={type ?? "text"}
      value={value ?? ""}
      disabled={disabled}
      onClick={!disabled ? (e) => handleClick(e, id) : undefined}
      className={classes.inputWidth}
      endAdornment={
        !disabled ? (
          <InputAdornment position="end">
            <IconButton aria-label={`toggle ${id} picker`}>
              <ArrowDropDownIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
    />
  );
};

//// InputProps['type']
interface IProps {
  id: string;
  type?: string;
  value: string | undefined;
  disabled?: boolean;
  handleClick: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
}
