import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountCreationSteps,
  CommunityTypes,
  ReducerStates,
} from "../../helpers/constants";
import {
  IAuthorizeData,
  ICreateAccountData,
  IFailedRequest,
  ILoginData,
  IPasswordChangeData,
  IPasswordResetData,
  IToken,
  IVerifyEmailData,
  loginServices,
} from "../../services/login.services";
import { firebaseLogin, initialize } from "../app/asyncThunkActions";
import store, { IAppState } from "../../helpers/store";
import { headerServices, ICommunities } from "../../services/header.services";
import { ErrorCodes, StorageKeys } from "../../services/constants";

export interface ILoginState {
  isLogged: boolean;
  userToken?: IToken;
  state: ReducerStates;
  errorCode: string;
  errorMessage: string;
  passwordResetSteps: number;
  accountCreationSteps: number;
  accountCreationData?: ICreateAccountData;
  userCommunities?: ICommunities[];
  userCountry: string | null;
  continueAccountCreation: boolean;
  mode: string;
  authParams: string;
  authRedirectURL: string;
  firebaseAuth?: boolean;
  origin?: string;
  parentClientID?: string;
  refreshTokenFailed: boolean;
}

export const initialLoginState: ILoginState = {
  isLogged: false,
  userToken: undefined,
  state: ReducerStates.IDLE,
  errorCode: "",
  errorMessage: "",
  passwordResetSteps: 0,
  accountCreationSteps: AccountCreationSteps.Instructions,
  accountCreationData: undefined,
  userCommunities: undefined,
  userCountry: null,
  continueAccountCreation: false,
  mode: "",
  authParams: "",
  authRedirectURL: "",
  firebaseAuth: undefined,
  origin: undefined,
  parentClientID: undefined,
  refreshTokenFailed: false,
};

export const LoginModes = {
  ALEXA: "alexa",
  LOGIN: "login",
};

export const getClientID = () => {
  const parentClientID = store.getState().loginState.parentClientID;
  if (parentClientID !== undefined) {
    return parentClientID;
  } else {
    return (process.env.REACT_APP_CLIENT_ID as unknown) as string;
  }
};

export const getLoginToken = createAsyncThunk<
  IToken,
  ILoginData,
  {
    state: ILoginState;
    extra: {
      jwt: string;
    };
    rejectValue: IFailedRequest;
  }
>("login/getLoginToken", async (loginData: ILoginData, { rejectWithValue }) => {
  try {
    const response = await loginServices.login(loginData, getClientID());
    store.dispatch(firebaseLogin(response.access_token));
    return response;
  } catch (err) {
    return rejectWithValue((err as unknown) as IFailedRequest);
  }
});

export const getAuthorizationToken = createAsyncThunk<
  string,
  IAuthorizeData,
  {
    state: ILoginState;
    extra: {
      jwt: string;
    };
    rejectValue: IFailedRequest;
  }
>(
  "login/getAuthToken",
  async (authorizeData: IAuthorizeData, { rejectWithValue }) => {
    try {
      const data = await loginServices.authorize(authorizeData);
      return data;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

// Sends verification token to user email
export const sendCode = createAsyncThunk(
  "login/sendCode",
  async (email: string) => {
    try {
      const response = await loginServices.requestCode(email);
      return response;
    } catch (err) {
      return err;
    }
  }
);

// Resets password using verification token and new password
export const resetPassword = createAsyncThunk(
  "login/resetPassword",
  async (passwordResetData: IPasswordResetData) => {
    try {
      const response = await loginServices.resetPassword(passwordResetData);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

// Creates an account
export const createAccount = createAsyncThunk<
  IToken,
  ICreateAccountData,
  {
    rejectValue: IFailedRequest;
  }
>(
  "login/createAccount",
  async (createAccountData: ICreateAccountData, thunkAPI) => {
    try {
      await loginServices.createAccount(createAccountData);

      const loginData = {
        email: createAccountData.email,
        password: createAccountData.password,
      };
      try {
        const response = await loginServices.login(loginData, getClientID());
        await store.dispatch(firebaseLogin(response.access_token));
        return response;
      } catch (err) {
        return thunkAPI.rejectWithValue((err as unknown) as IFailedRequest);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

// Verifies an email using the code sent to the user's email
export const verifyEmail = createAsyncThunk<
  IToken | undefined,
  IVerifyEmailData,
  {
    state: IAppState;
    rejectValue: IFailedRequest;
  }
>("login/verifyEmail", async (verifyEmailData: IVerifyEmailData, thunkAPI) => {
  try {
    const response = await loginServices.verifyEmail(verifyEmailData);
    const accountCreationData = thunkAPI.getState().loginState
      .accountCreationData;
    if (response && accountCreationData !== undefined) {
      const loginData = {
        email: accountCreationData.email,
        password: accountCreationData.password,
      };
      try {
        const response = await loginServices.login(loginData, getClientID());
        store.dispatch(firebaseLogin(response.access_token));
        return response;
      } catch (err) {
        thunkAPI.rejectWithValue((err as unknown) as IFailedRequest);
      }
    } else {
      return undefined;
    }
  } catch (err) {
    return thunkAPI.rejectWithValue((err as unknown) as IFailedRequest);
  }
});

// Sends verification token to user email
export const resendVerificationEmail = createAsyncThunk(
  "login/resendVerificationEmail",
  async (email: string) => {
    try {
      loginServices.resendVerificationEmail(email);
    } catch (err) {
      return err;
    }
  }
);

// Creates a PIN for the user's account
export const createPIN = createAsyncThunk(
  "login/createPIN",
  async (pin: string) => {
    try {
      const response = await loginServices.createPIN(pin);
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getCommunitiesAccountCreation = createAsyncThunk<
  ICommunities[],
  void,
  {
    rejectValue: IFailedRequest;
  }
>("login/getCommunitiesAccountCreation", async (_, thunkAPI) => {
  try {
    const response = await headerServices.getCommunities();
    return response;
  } catch (err) {
    return thunkAPI.rejectWithValue((err as unknown) as IFailedRequest);
  }
});

export const getUserGeolocation = createAsyncThunk(
  "login/getUserGeolocation",
  async () => {
    const response = await loginServices.getUserGeolocation();
    return response;
  }
);

export interface IExternalLoginPayload {
  refreshToken: string;
  userID: string;
  clientID: string;
}

export interface IExternalLoginStatusPayload {
  userID: string;
}
export interface IExternalChangeViewPayload {
  view: string;
}
export interface IExternalSwitchCommunityPayload {
  id: string;
  type: string;
}

export const externalLogin = createAsyncThunk<
  IToken,
  IExternalLoginPayload,
  {
    rejectValue: IFailedRequest;
  }
>(
  "login/externalLogin",
  async (externalLoginData: IExternalLoginPayload, { rejectWithValue }) => {
    try {
      const response = await loginServices.refreshToken(
        externalLoginData.refreshToken,
        externalLoginData.clientID
      );
      if (response.user_id === externalLoginData.userID) {
        store.dispatch(firebaseLogin(response.access_token));
        return response;
      } else {
        return rejectWithValue({
          code: ErrorCodes.TOKEN_INVALID,
          message: ErrorCodes.TOKEN_INVALID,
        });
      }
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

export const changePassword = createAsyncThunk<
  IToken,
  IPasswordChangeData,
  {
    state: ILoginState;
    extra: {
      jwt: string;
    };
    rejectValue: IFailedRequest;
  }
>(
  "settings/changePassword",
  async (passwordChangeData: IPasswordChangeData, { rejectWithValue }) => {
    try {
      const response = await loginServices.changePassword(passwordChangeData);
      return response;
    } catch (err) {
      return rejectWithValue((err as unknown) as IFailedRequest);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: initialLoginState,
  reducers: {
    logout(state) {
      state.isLogged = initialLoginState.isLogged;
      state.userToken = initialLoginState.userToken;
      state.parentClientID = initialLoginState.parentClientID;
      localStorage.removeItem(StorageKeys.PARENT_CLIENT_ID);
      state.firebaseAuth = initialLoginState.firebaseAuth;
      state.state = ReducerStates.IDLE;
      localStorage.removeItem(StorageKeys.COMMUNITY_FILTER);
      localStorage.removeItem(StorageKeys.IS_COMMUNITY_FILTERED);
      localStorage.removeItem(StorageKeys.STATUS_FILTER);
      localStorage.removeItem(StorageKeys.IS_STATUS_FILTERED);
      localStorage.removeItem(StorageKeys.USER_FILTER);
      localStorage.removeItem(StorageKeys.IS_USER_FILTERED);
      localStorage.removeItem(StorageKeys.ALL_NOTIFICATIONS);
    },
    loadRefreshToken(state) {
      state.state = ReducerStates.PENDING;
    },
    refreshToken(state, action) {
      state.userToken = action.payload;
      state.state = ReducerStates.IDLE;
    },
    resetPasswordInit(state) {
      state.state = ReducerStates.IDLE;
      state.passwordResetSteps = initialLoginState.passwordResetSteps;
      state.errorCode = initialLoginState.errorCode;
      state.errorMessage = initialLoginState.errorMessage;
    },
    environmentSettingsInit(state) {
      state.state = ReducerStates.IDLE;
      state.errorCode = initialLoginState.errorCode;
      state.errorMessage = initialLoginState.errorMessage;
    },
    accountCreationInit(state) {
      state.state = ReducerStates.IDLE;
      state.accountCreationSteps = initialLoginState.accountCreationSteps;
      state.accountCreationData = initialLoginState.accountCreationData;
      state.userToken = initialLoginState.userToken;
      state.continueAccountCreation = initialLoginState.continueAccountCreation;
      state.errorCode = initialLoginState.errorCode;
      state.errorMessage = initialLoginState.errorMessage;
    },
    resetSliceState(state) {
      state.state = ReducerStates.IDLE;
    },
    authorizeMode(state, action) {
      state.mode = action.payload.mode;
      state.authParams = action.payload.authParams;
    },
    backToAccountCreation(state) {
      state.state = ReducerStates.IDLE;
      state.accountCreationSteps = AccountCreationSteps.AccountCreation;
      state.errorCode = initialLoginState.errorCode;
      state.errorMessage = initialLoginState.errorMessage;
    },
    skipPinCreation(state) {
      state.state = ReducerStates.IDLE;
      state.accountCreationSteps = AccountCreationSteps.SelectCommunity;
      state.errorCode = initialLoginState.errorCode;
      state.errorMessage = initialLoginState.errorMessage;
    },
    finishAccountCreation(state, action: PayloadAction<string | undefined>) {
      state.isLogged = true;
      state.state = ReducerStates.SUCCEEDED;
      state.accountCreationSteps = initialLoginState.accountCreationSteps;
      state.accountCreationData = initialLoginState.accountCreationData;
      state.continueAccountCreation = initialLoginState.continueAccountCreation;
      state.userCommunities = initialLoginState.userCommunities;
      state.errorCode = initialLoginState.errorCode;
      state.errorMessage = initialLoginState.errorMessage;
    },
    redirectToVerifyAccount(
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) {
      state.continueAccountCreation = true;
      state.accountCreationData = {
        firstName: "",
        lastName: "",
        language: "",
        country: "",
        email: action.payload.email,
        password: action.payload.password,
        token: "",
      };
      state.accountCreationSteps = AccountCreationSteps.EmailVerification;
      state.state = ReducerStates.SUCCEEDED;
    },
    backToResetPassword(state) {
      state.state = ReducerStates.IDLE;
      state.passwordResetSteps = initialLoginState.passwordResetSteps;
      state.errorCode = initialLoginState.errorCode;
      state.errorMessage = initialLoginState.errorMessage;
    },
    setFrameOrigin(state, action: PayloadAction<string>) {
      state.origin = action.payload;
    },
    updateLocalToken(state, action: PayloadAction<IToken>) {
      state.userToken = action.payload;
      state.refreshTokenFailed = false;
    },
    refreshTokenFailed(state) {
      state.refreshTokenFailed = true;
    },
    goToAccountCreation(state) {
      state.accountCreationSteps = AccountCreationSteps.AccountCreation;
    },
    goToInstructions(state) {
      state.accountCreationSteps = AccountCreationSteps.Instructions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginToken.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLoginState.errorCode;
        state.errorMessage = initialLoginState.errorMessage;
      })
      .addCase(getLoginToken.fulfilled, (state, action) => {
        state.userToken = action.payload;
        state.isLogged = true;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getLoginToken.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorCode = action.payload?.code || "";
        state.errorMessage = action.payload?.message || "";
      })
      .addCase(changePassword.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLoginState.errorCode;
        state.errorMessage = initialLoginState.errorMessage;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.userToken = action.payload;
        state.isLogged = true;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorCode = action.payload?.code || "";
        state.errorMessage = action.payload?.message || "";
      })
      .addCase(getAuthorizationToken.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLoginState.errorCode;
        state.errorMessage = initialLoginState.errorMessage;
      })
      .addCase(getAuthorizationToken.fulfilled, (state, action) => {
        state.authRedirectURL = action.payload;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getAuthorizationToken.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorCode = action.payload?.code || "";
        state.errorMessage = action.payload?.message || "";
      })
      .addCase(initialize.fulfilled, (state, action) => {
        if (action.payload.userToken !== null) {
          state.userToken = action.payload.userToken;
          const parentClientID = action.payload.parentClientID;
          if (parentClientID) {
            state.parentClientID = parentClientID;
            localStorage.setItem(StorageKeys.PARENT_CLIENT_ID, parentClientID);
          } else {
            state.parentClientID = initialLoginState.parentClientID;
            localStorage.removeItem(StorageKeys.PARENT_CLIENT_ID);
          }
          state.isLogged = true;
        } else {
          state.isLogged = false;
        }
      })
      .addCase(sendCode.pending, (state) => {
        state.passwordResetSteps = 1;
      })
      .addCase(resetPassword.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLoginState.errorCode;
        state.errorMessage = initialLoginState.errorMessage;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.state = ReducerStates.SUCCEEDED;
        state.passwordResetSteps = 2;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        const error: any = action.error;
        state.errorCode = error?.code || "";
        state.errorMessage = error?.message || "";
      })
      .addCase(firebaseLogin.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLoginState.errorCode;
        state.errorMessage = initialLoginState.errorMessage;
      })
      .addCase(
        firebaseLogin.fulfilled,
        (state, action: PayloadAction<boolean>) => {
          state.firebaseAuth = action.payload;
          state.state = ReducerStates.SUCCEEDED;
        }
      )
      .addCase(firebaseLogin.rejected, (state) => {
        state.firebaseAuth = false;
        state.state = ReducerStates.FAILED;
      })
      .addCase(createAccount.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLoginState.errorCode;
        state.errorMessage = initialLoginState.errorMessage;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.userToken = action.payload;
        if (action.payload !== undefined)
          state.accountCreationSteps = AccountCreationSteps.CreatePin;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorCode = action.payload?.code || "";
        state.errorMessage = action.payload?.message || "";
      })
      .addCase(verifyEmail.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialLoginState.errorCode;
        state.errorMessage = initialLoginState.errorMessage;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.userToken = action.payload;
        if (action.payload !== undefined)
          state.accountCreationSteps = AccountCreationSteps.CreatePin;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorCode = action.payload?.code || "";
        state.errorMessage = action.payload?.message || "";
      })
      .addCase(resendVerificationEmail.pending, (state) => {
        state.state = ReducerStates.PENDING;
      })
      .addCase(resendVerificationEmail.fulfilled, (state) => {
        state.state = ReducerStates.SUCCEEDED;
        state.accountCreationSteps = AccountCreationSteps.EmailVerification;
      })
      .addCase(createPIN.pending, (state) => {
        state.state = ReducerStates.PENDING;
      })
      .addCase(createPIN.fulfilled, (state, action) => {
        if (action.payload)
          state.accountCreationSteps = AccountCreationSteps.SelectCommunity;
        state.state = ReducerStates.IDLE;
      })
      .addCase(createPIN.rejected, (state) => {
        state.state = ReducerStates.FAILED;
      })
      .addCase(getCommunitiesAccountCreation.pending, (state) => {
        state.state = ReducerStates.PENDING;
      })
      .addCase(getCommunitiesAccountCreation.fulfilled, (state, action) => {
        const communities: ICommunities[] = action.payload;
        let tempCommunities: ICommunities[] = [];
        if (communities && communities.length > 0) {
          // find pro communities
          tempCommunities = communities.filter(
            (community) => community.community_type === CommunityTypes.pro
          );
        }
        // set selected community
        state.userCommunities = tempCommunities;
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getCommunitiesAccountCreation.rejected, (state) => {
        state.state = ReducerStates.FAILED;
      })
      .addCase(getUserGeolocation.fulfilled, (state, action) => {
        state.userCountry = action.payload.country;
      })
      .addCase(externalLogin.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.isLogged = initialLoginState.isLogged;
        state.userToken = initialLoginState.userToken;
        state.firebaseAuth = initialLoginState.firebaseAuth;
      })
      .addCase(externalLogin.fulfilled, (state, action) => {
        state.userToken = action.payload;
        state.isLogged = true;
        state.state = ReducerStates.SUCCEEDED;
        const parentClientID = action.meta.arg.clientID;
        if (parentClientID) {
          state.parentClientID = parentClientID;
          localStorage.setItem(StorageKeys.PARENT_CLIENT_ID, parentClientID);
        } else {
          state.parentClientID = initialLoginState.parentClientID;
          localStorage.removeItem(StorageKeys.PARENT_CLIENT_ID);
        }
      })
      .addCase(externalLogin.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorCode = action.payload?.code || "";
        state.errorMessage = action.payload?.message || "";
      });
  },
});

const { reducer } = loginSlice;

// Destructure and export the plain action creators
export const {
  logout,
  loadRefreshToken,
  refreshToken,
  resetPasswordInit,
  resetSliceState,
  authorizeMode,
  accountCreationInit,
  backToAccountCreation,
  skipPinCreation,
  finishAccountCreation,
  redirectToVerifyAccount,
  backToResetPassword,
  setFrameOrigin,
  updateLocalToken,
  refreshTokenFailed,
  environmentSettingsInit,
  goToAccountCreation,
  goToInstructions,
} = loginSlice.actions;

export default reducer;
