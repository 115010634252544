import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  resetPassword,
  sendCode,
  resetSliceState,
  LoginModes,
  resetPasswordInit,
  backToResetPassword,
} from "./loginSlice";
import { ErrorCodes } from "../../services/constants";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ReducerStates, Views } from "../../helpers/constants";
import { emailValidation, passwordValidation } from "../../helpers/utils";
import { useHistory } from "react-router-dom";
import { IPasswordResetData } from "../../services/login.services";
import { StackLogo } from "./StackLogo";
import { handledError } from "../app/appSlice";
import { ActionBarBottom } from "./accountCreation/ActionBarBottom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      width: "380px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    subtitleForm: {
      marginTop: "28px",
      marginBottom: "-15px",
    },
    textFieldUser: {
      marginTop: "32px",
    },
    textFieldPassword: {
      marginTop: "0px",
    },
    helperText: {
      minHeight: "19px",
      marginTop: "5px",
      marginBottom: "5px",
      lineHeight: "1.2",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      width: "100%",
      justifyContent: "center",
    },
    forgot: {
      marginLeft: "40px",
      cursor: "pointer",
      textDecoration: "underline",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
    },
    secondaryButtons: {
      color: theme.palette.paused.light,
    },
  })
);

/**
 * Login form component
 */

export default function ResetPasswordForm() {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  /* ---- State ---- */
  const [step, setStep] = useState(0);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("password");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(
    "password"
  );

  /* ---- Selectors ---- */
  const errorCode = useSelector(
    (state: IAppState) => state.loginState.errorCode
  );

  const loginState: ReducerStates = useSelector(
    (state: IAppState) => state.loginState.state
  );

  const passwordResetSteps = useSelector(
    (state: IAppState) => state.loginState.passwordResetSteps
  );

  const loginMode = useSelector((state: IAppState) => state.loginState.mode);

  const authParams = useSelector(
    (state: IAppState) => state.loginState.authParams
  );

  /* ---- References ---- */
  const passwordRef = useRef(document.createElement("input"));
  const confirmPassword = useRef(document.createElement("input"));

  /* ---- Effects ---- */

  // Set errors coming from backend
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.RESET_TOKEN_INVALID:
          dispatch(handledError());
          setCodeError(t("incorrect_reset_code"));
          break;
        case ErrorCodes.INVALID_EMAIL:
          dispatch(handledError());
          setCodeError(t("invalid_email_address"));
          break;
        case ErrorCodes.SERVER_INTERNAL_ERROR:
          dispatch(handledError());
          setCodeError(t("something_happened"));
          break;
        default:
          setCodeError("");
          break;
      }
      dispatch(resetSliceState());
    }
  }, [errorCode, t, dispatch]);

  // Manage view flow
  useEffect(() => {
    if (ReducerStates[loginState] === ReducerStates.SUCCEEDED) {
      switch (passwordResetSteps) {
        // Code sent successfully, show reset password form
        case 1:
          dispatch(resetSliceState());
          break;
        // Password was reset, show login view
        case 2:
          if (loginMode === LoginModes.ALEXA) {
            history.push(`${Views.LOGIN}${authParams}`);
          } else {
            history.push(Views.LOGIN);
          }
          dispatch(resetSliceState());
          break;
      }
    }
  }, [
    loginState,
    passwordResetSteps,
    dispatch,
    history,
    loginMode,
    authParams,
  ]);

  // Set cursor to the end when showing/hiding new password
  useEffect(() => {
    // Moving cursor to the end
    if (passwordRef.current && passwordRef.current.value) {
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [showNewPassword]);

  // Set cursor to the end when showing/hiding confirm password
  useEffect(() => {
    // Moving cursor to the end
    if (confirmPassword.current && confirmPassword.current.value) {
      confirmPassword.current.selectionStart =
        confirmPassword.current.value.length;
      confirmPassword.current.selectionEnd =
        confirmPassword.current.value.length;
    }
  }, [showConfirmNewPassword]);

  /* ---- Methods ---- */
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setEmail(value);
    setEmailError("");
  };

  const handleSubmitRequestToken = (
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    if (email) {
      dispatch(sendCode(email));
      setStep(1);
    }
  };

  const handleNewPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setNewPassword(value);
    setNewPasswordError("");
  };

  const toggleShowNewPassword = useCallback(() => {
    setShowNewPassword((current) =>
      showNewPassword === "text" ? "password" : "text"
    );
    // Setting focus here
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showNewPassword, passwordRef]);

  const handleMouseDownNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setConfirmNewPassword(value);
  };

  const toggleShowConfirmPassword = useCallback(() => {
    setShowConfirmNewPassword((current) =>
      showConfirmNewPassword === "text" ? "password" : "text"
    );
    // Setting focus here
    if (confirmPassword.current) {
      confirmPassword.current.focus();
    }
  }, [showConfirmNewPassword, confirmPassword]);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmitPasswordReset = (
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    const passwordResetData: IPasswordResetData = {
      email,
      code,
      newPassword,
    };
    dispatch(resetPassword(passwordResetData));
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setCode(value);
    setCodeError("");
  };

  const handleResendCode = (): void => {
    if (email) {
      dispatch(sendCode(email));
    }
  };

  /* ---- Utils ---- */

  const validateEmail = () => {
    if (email !== "") {
      if (emailValidation(email)) {
        setEmailError(t("invalid_email"));
      } else {
        setEmailError("");
      }
    }
  };

  const validatePasswordFields = () => {
    const validationMessage = passwordValidation(
      newPassword,
      confirmNewPassword
    );
    if (validationMessage !== "") {
      setNewPasswordError(t(validationMessage));
    } else {
      setNewPasswordError("");
    }
  };

  const handleBackToLogin = () => {
    dispatch(resetPasswordInit());
    history.push(Views.LOGIN);
  };

  const handleBackToResetPassword = () => {
    dispatch(backToResetPassword());
    setStep(0);
    setEmail("");
  };

  const disabledSendEmail = email === "" || emailError !== "";
  const disabledResetPassword =
    code === "" ||
    newPassword === "" ||
    confirmNewPassword === "" ||
    newPasswordError !== "";

  return (
    <React.Fragment>
      <StackLogo />
      {step === 0 && (
        <React.Fragment>
          <Typography
            variant="body1"
            align="center"
            className={classes.subtitleForm}
          >
            {t("reset_pass_label")}
          </Typography>
          <form
            id="request-code-form"
            noValidate
            className={classes.formContainer}
            autoComplete="off"
            onSubmit={handleSubmitRequestToken}
          >
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.textFieldUser}
              error={emailError !== ""}
            >
              <InputLabel htmlFor="email">{t("email_address")}</InputLabel>
              <OutlinedInput
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                onBlur={validateEmail}
                autoComplete="off"
                label={t("email_address")}
              />
              <FormHelperText
                id="component-error-text"
                className={classes.helperText}
              >
                {emailError}
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              variant={disabledSendEmail ? "outlined" : "contained"}
              color="primary"
              size="large"
              disabled={disabledSendEmail}
              classes={{
                root: classes.buttonRoot,
              }}
            >
              {t("send_reset_email")}
            </Button>
          </form>
          <ActionBarBottom>
            <Button
              onClick={handleBackToLogin}
              startIcon={<ArrowBackIosIcon />}
              className={classes.secondaryButtons}
            >
              {t("action_back")}
            </Button>
          </ActionBarBottom>
        </React.Fragment>
      )}
      {step === 1 && (
        <form
          id="change-password-form"
          noValidate
          autoComplete="off"
          className={classes.formContainer}
          onSubmit={handleSubmitPasswordReset}
        >
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.textFieldUser}
            error={codeError !== ""}
          >
            <InputLabel htmlFor="reset-code">{t("reset_code")}</InputLabel>
            <OutlinedInput
              name="reset-code"
              id="reset-code"
              value={code}
              onChange={handleCodeChange}
              autoComplete="off"
              label={t("reset_code")}
            />
            <FormHelperText
              id="component-error-text"
              className={classes.helperText}
            >
              {codeError !== "" && codeError}
            </FormHelperText>
          </FormControl>

          <FormControl
            variant="outlined"
            fullWidth
            className={classes.textFieldPassword}
            error={newPasswordError !== ""}
          >
            <InputLabel htmlFor="newPassword">{t("new_password")}</InputLabel>
            <OutlinedInput
              id="newPassword"
              name="newPassword"
              type={showNewPassword}
              label={t("new_password")}
              className="password-field"
              inputRef={passwordRef}
              value={newPassword}
              onChange={handleNewPasswordChange}
              onBlur={validatePasswordFields}
              autoComplete="new-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                    edge="end"
                  >
                    {showNewPassword === "text" ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              id="component-error-text"
              className={classes.helperText}
            >
              {newPasswordError}
            </FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.textFieldPassword}
          >
            <InputLabel htmlFor="confirm-password">
              {t("confirm_password")}
            </InputLabel>
            <OutlinedInput
              id="confirm-password"
              name="confirm-password"
              type={showConfirmNewPassword}
              label={t("confirm_password")}
              inputRef={confirmPassword}
              className="password-field"
              value={confirmNewPassword}
              onChange={handleConfirmPasswordChange}
              onBlur={validatePasswordFields}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfirmNewPassword === "text" ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              variant={disabledResetPassword ? "outlined" : "contained"}
              color="primary"
              size="large"
              disabled={disabledResetPassword}
            >
              {t("reset_password")}
            </Button>
          </div>
          <ActionBarBottom>
            <Button
              onClick={handleBackToResetPassword}
              startIcon={<ArrowBackIosIcon />}
              className={classes.secondaryButtons}
            >
              {t("action_back")}
            </Button>
            <Link
              className={classes.forgot}
              onClick={handleResendCode}
              color="primary"
            >
              <Typography variant="subtitle1" component="span">
                {t("resend")}
              </Typography>
            </Link>
          </ActionBarBottom>
        </form>
      )}
    </React.Fragment>
  );
}
