import { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Popover,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { AccordionForm } from "./common/AccordionForm";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { EventConfigLabel } from "./common/EventConfigLabel";
import { ICommunityGroup } from "../../services/header.services";
import { ListOptionsFilter } from "./filters/ListOptionsFilter";
import { IUnitGroupConfig } from "../../services/settings.services";
import {
  updateCommunityGroupsConfig,
  updateUnitGroupsConfig,
} from "./settingsThunks";
import { showSoftNotification } from "../app/appSlice";
import { SoftNotificationTypes } from "../common/AppSnack";
import { GroupEventConfig } from "./settingsSlice";
import { sortAlphabetical } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstSection: {
      flexBasis: "30%",
      fontWeight: "bold",
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
    formContainer: {
      display: "flex",
      flexBasis: "70%",
      "&>*": {
        alignItems: "center",
      },
    },
    column: {
      display: "flex",
      flexDirection: "column",
      "&>*": {
        paddingBottom: theme.spacing(1),
      },
      "&>:last-child": {
        paddingBottom: 0,
      },
    },
    columnRow: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(2),
    },
    inputWidth: {
      width: "55%",
      fontSize: theme.typography.body2.fontSize,
    },
    iconButtonRoot: {
      padding: theme.spacing(0.5),
    },
  })
);

export const getNotificationGroupText = (
  notificationGroups: string[] | null,
  communityGroups?: ICommunityGroup[]
) => {
  if (notificationGroups === null) return [];
  return notificationGroups
    .map((notifGroup) => {
      const notifGroupName = communityGroups?.find(
        (communityGroup) => communityGroup.id === notifGroup
      );
      if (notifGroupName !== undefined) {
        return notifGroupName.name ?? "";
      }
      return "-";
    })
    .sort((a, b) => sortAlphabetical(a, b));
};

enum FormFieldIDs {
  primaryGroups = "primaryGroups",
  mixedPrimaryGroups = "mixedPrimaryGroups",
  escalationGroups = "escalationGroups",
  mixedEscalationGroups = "mixedEscalationGroups",
}

export const NotificationGroupTemplateForm = (props: IProps) => {
  const { communityGroups, expanded, setExpanded } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [
    communityGroupConfig,
    setCommunityGroupConfig,
  ] = useState<GroupEventConfig | null>(null);

  const [confirmApply, setConfirmApply] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverID, setPopoverID] = useState("");

  /* Selectors */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const configSelectedUnits = useAppSelector(
    (state) => state.settingsState.configSelectedUnits
  );

  const communityGroupConfigState = useAppSelector(
    (state) => state.settingsState.groupEventConfig
  );

  /* Effects */
  useEffect(() => {
    setExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      communityGroupConfigState !== undefined &&
      communityGroupConfig === null
    ) {
      setCommunityGroupConfig(communityGroupConfigState);
    }
  }, [communityGroupConfigState, communityGroupConfig]);

  /* Methods */
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleCancel = () => {
    setExpanded(false);
    if (communityGroupConfigState !== undefined)
      setCommunityGroupConfig(communityGroupConfigState);
  };

  const handleApplyToSelected = async () => {
    if (configSelectedUnits === undefined) return;
    if (configSelectedUnits.length === 0) return;

    const primaryGroups = communityGroupConfig?.primaryGroups ?? [];
    const escalationGroups = communityGroupConfig?.escalationGroups ?? [];
    const configUnitsData: IUnitGroupConfig = {
      unit_ids: configSelectedUnits,
      event_types: "all",
      level_1: primaryGroups,
      level_2: primaryGroups,
      level_3: escalationGroups,
      level_4: escalationGroups,
    };

    const updateConfigResult = await dispatch(
      updateUnitGroupsConfig(configUnitsData)
    );
    if (updateUnitGroupsConfig.fulfilled.match(updateConfigResult)) {
      dispatch(
        showSoftNotification(SoftNotificationTypes.APPLIED_CONFIG_CONFIRMATION)
      );
    }
  };

  const handleSave = () => {
    if (communityID === undefined) return;

    const primaryGroups = communityGroupConfig?.primaryGroups ?? [];
    const escalationGroups = communityGroupConfig?.escalationGroups ?? [];
    const configUnitsData = {
      level_1: primaryGroups,
      level_2: primaryGroups,
      level_3: escalationGroups,
      level_4: escalationGroups,
    };

    const configData = {
      communityID: communityID,
      requestData: configUnitsData,
    };

    dispatch(updateCommunityGroupsConfig(configData));
    setExpanded(false);
  };

  /* Methods */
  // Picker methods
  const handleOpenFilter = (event: any, popoverID: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(popoverID);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  const handleGroupsChange = (value: string[], fieldID: string) => {
    setCommunityGroupConfig((prevVal) => {
      const mixedKey =
        fieldID === FormFieldIDs.primaryGroups
          ? FormFieldIDs.mixedPrimaryGroups
          : FormFieldIDs.mixedEscalationGroups;
      if (prevVal !== null && fieldID !== "") {
        return { ...prevVal, [fieldID]: value, [mixedKey]: false };
      }
      return prevVal;
    });

    handleCloseFilter();
  };

  // Check if at least one is selected
  const enableApply =
    configSelectedUnits !== undefined && configSelectedUnits.length > 0;

  const getOptions = () => {
    if (communityGroups === undefined) return [];

    return communityGroups.map((group) => ({
      id: group.id,
      label: group.name,
    }));
  };
  const getSelectedOptions = (notificationGroupType: string) => {
    if (communityGroupConfig === null) return [];

    if (notificationGroupType === FormFieldIDs.primaryGroups) {
      return communityGroupConfig.primaryGroups;
    }
    if (notificationGroupType === FormFieldIDs.escalationGroups) {
      return communityGroupConfig.escalationGroups;
    }
    return [];
  };

  const getValueForNotificationGroup = ({
    group,
    isMixed,
  }: {
    group?: string[] | null;
    isMixed?: boolean | null;
  }) => {
    if (group === undefined) return "-";
    if (isMixed === undefined || isMixed === null) return "-";

    if (isMixed) return t("mixed_groups");

    const allGroups =
      [...(group ?? [])].sort().join(",") ===
      communityGroups
        ?.map((group) => group.id)
        .sort()
        .join(",");

    if (allGroups) return t("all_notification_groups");

    const groupDisplay = getNotificationGroupText(group, communityGroups);
    if (groupDisplay.length === 0) return t("no_notification_groups");

    return groupDisplay.join(", ");
  };

  const primaryDisplayValue = getValueForNotificationGroup({
    group: communityGroupConfig?.primaryGroups,
    isMixed: communityGroupConfig?.mixedPrimaryGroups,
  });
  const escalationDisplayValue = getValueForNotificationGroup({
    group: communityGroupConfig?.escalationGroups,
    isMixed: communityGroupConfig?.mixedEscalationGroups,
  });

  const open = Boolean(anchorEl);
  return (
    <>
      <AccordionForm
        expand={expanded}
        toggleAccordion={toggleAccordion}
        handleCancel={handleCancel}
        handleApplyToSelected={() => setConfirmApply(true)}
        handleSave={handleSave}
        enableButtons={expanded}
        enableApply={enableApply}
      >
        <span className={classes.firstSection}>{t("template")}</span>
        <ConfirmDialog
          dialogType={DialogTypes.informative}
          toggle={confirmApply}
          title={t("apply_changes")}
          message={t("confirm_apply_changes", {
            selected: configSelectedUnits?.length ?? 0,
          })}
          possitiveText={t("apply")}
          negativeText={t("cancel")}
          positiveAction={handleApplyToSelected}
          negativeAction={() => void 0}
          onClose={() => setConfirmApply(false)}
        />
        <form className={classes.formContainer}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <div className={classes.columnRow}>
                <EventConfigLabel
                  expanded={expanded}
                  label={t("primary_groups").toUpperCase()}
                  value={primaryDisplayValue}
                />
                {expanded && (
                  <Input
                    id="primary_groups"
                    type="text"
                    readOnly
                    className={classes.inputWidth}
                    onClick={(e) =>
                      handleOpenFilter(e, FormFieldIDs.primaryGroups)
                    }
                    value={primaryDisplayValue}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          classes={{ root: classes.iconButtonRoot }}
                          aria-label="toggle notification groups picker"
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.columnRow}>
                <EventConfigLabel
                  expanded={expanded}
                  label={t("escalation_groups").toUpperCase()}
                  value={escalationDisplayValue}
                />
                {expanded && (
                  <Input
                    id="escalation_groups"
                    type={"text"}
                    readOnly
                    className={classes.inputWidth}
                    onClick={(e) =>
                      handleOpenFilter(e, FormFieldIDs.escalationGroups)
                    }
                    value={escalationDisplayValue}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle notification groups picker">
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </form>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ zIndex: 1200 }}
        >
          <div>
            <ListOptionsFilter
              options={getOptions()}
              selectedOptions={getSelectedOptions(popoverID)}
              atLeastOneSelected={false}
              needsToChangeToSave={false}
              handleApplyFilter={(values) => {
                const vals = Object.keys(values).filter((key) => values[key]);
                handleGroupsChange(vals, popoverID);
              }}
              handleCloseFilter={handleCloseFilter}
            />
          </div>
        </Popover>
      </AccordionForm>
    </>
  );
};

interface IProps {
  expanded: boolean;
  setExpanded: (val: boolean) => void;
  communityGroups?: ICommunityGroup[] | undefined;
}
