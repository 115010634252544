import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    buttonBar: {
      padding: theme.spacing(2, 6),
      gap: theme.spacing(6),
    },
    warningTitle: {
      backgroundColor: theme.palette.error.main,
      padding: theme.spacing(1.2, 1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.light.light,
      fontFamily: "GothamRoundedMedium",
    },
    warningRoot: {
      border: `1px solid ${theme.palette.error.main}`,
    },
    warningContent: {
      padding: theme.spacing(2, 6),
      fontFamily: "GothamRoundedMedium",
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.dark.main,
    },
    primaryTitle: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1.2, 1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.light.light,
      fontFamily: "GothamRoundedMedium",
    },
    primaryRoot: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    primaryContent: {
      padding: theme.spacing(2, 6),
      fontFamily: "GothamRoundedMedium",
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.dark.main,
    },
    informativeTitle: {
      backgroundColor: theme.palette.contactBlue.main,
      padding: theme.spacing(1.2, 1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.light.light,
      fontFamily: "GothamRoundedMedium",
    },
    informativeRoot: {
      border: `1px solid ${theme.palette.contactBlue.main}`,
    },
    informativeContent: {
      padding: theme.spacing(2, 6),
      fontFamily: "GothamRoundedMedium",
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.dark.main,
    },
    actionButton: {
      color: theme.palette.light.light,
      "&:hover": {
        backgroundColor: (props) => {
          switch (props.dialogType) {
            case "warning":
              return theme.palette.error.dark;
            case "primary":
              return theme.palette.primary.dark;
            case "informative":
              return theme.palette.contactBlue.main;
            default:
              return theme.palette.primary.dark;
          }
        },
      },
      backgroundColor: (props) => {
        switch (props.dialogType) {
          case "warning":
            return theme.palette.error.main;
          case "primary":
            return theme.palette.primary.main;
          case "informative":
            return theme.palette.contactBlue.main;
          default:
            return theme.palette.contactBlue.main;
        }
      },
    },
  })
);

export enum DialogTypes {
  warning = "warning",
  primary = "primary",
  informative = "informative",
}

// Display a modal to make the user acknowledge something
export const ConfirmDialog = (props: IProps) => {
  /* ---- Hooks ---- */
  const classes = useStyles(props);
  const {
    dialogType,
    toggle,
    title,
    message,
    possitiveText,
    negativeText,
    positiveAction,
    negativeAction,
    onClose,
  } = props;

  return (
    <Dialog
      open={toggle}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      classes={{
        paper: classes[`${dialogType}Root` as keyof typeof classes],
      }}
      maxWidth="xs"
    >
      {title !== undefined && (
        <DialogTitle
          classes={{
            root: classes[`${dialogType}Title` as keyof typeof classes],
          }}
          id="alert-dialog-title"
          disableTypography
        >
          <Typography style={{ fontFamily: "inherit" }} variant="h6">
            {title}
          </Typography>
        </DialogTitle>
      )}
      {message !== undefined && (
        <DialogContent
          classes={{
            root: classes[`${dialogType}Content` as keyof typeof classes],
          }}
          id="confirm-dialog-description"
        >
          {message}
        </DialogContent>
      )}
      <DialogActions className={classes.buttonBar}>
        <Button
          onClick={() => {
            negativeAction();
            onClose();
          }}
          color="default"
          variant="outlined"
          fullWidth
        >
          {negativeText}
        </Button>
        <Button
          onClick={() => {
            positiveAction();
            onClose();
          }}
          className={classes.actionButton}
          color="inherit"
          variant="contained"
          fullWidth
        >
          {possitiveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface IProps {
  dialogType: DialogTypes;
  toggle: boolean;
  title: string | undefined;
  message: string | undefined;
  possitiveText: string | undefined;
  negativeText: string | undefined;
  positiveAction: () => void;
  negativeAction: () => void;
  onClose: () => void;
}
