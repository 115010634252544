import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { EventTypes, ReducerStates } from "../../helpers/constants";
import { IFailedRequest } from "../../services/login.services";
import { hideSoftNotification } from "../app/appSlice";
import {
  submitMyProfileChanges,
  submitCommunityInfoChanges,
  updateCommunityUser,
  addCommunityUser as addUserRole,
  deleteCommunityUser,
  updateNotificationGroup,
  loadHandoffSettings,
  sendHandOff,
  addNotificationGroup as addNotificationGroupAction,
  deleteNotificationGroup,
  loadNotificationSettings,
  resendInvitation,
} from "../app/asyncThunkActions";
import {
  changeSelectedCommunity,
  changeSelectedOrganization,
  setAllNotifications,
} from "../header/headerSlice";

import {
  IParsedHandoffSettings,
  IScheduleEnabledSettings,
  IUserDetails,
} from "../../services/header.services";
import {
  EventType,
  EventTypeConfig,
  EventTypeLevelConfig,
  IUnitGroupConfigs,
  UnitEventTypeConfig,
} from "../../services/settings.services";
import {
  createUnit,
  deletePhoneNumber,
  createPhoneNumber,
  getResidentInfo,
  loadEventConfig,
  loadUnitConfigByEvent,
  updateCommunityEventConfig,
  updatePhoneNumber,
  updateResident,
  deleteCommunityUnit,
  updateCommunityUnit,
  updateUnitConfigByEventType,
  addResident,
  addResidentContact,
  updateResidentContact,
  deleteResidentContact,
  addContactPhoneNumber,
  updateContactPhoneNumber,
  deleteContactPhoneNumber,
  getEmergencyContacts,
  deleteEmergencyContact,
  addEmergencyContact,
  updateEmergencyContactsPosition,
  loadUnitGroupsByEventType,
  updateUnitGroupsConfig,
  updateCommunityGroupsConfig,
} from "./settingsThunks";
import {
  EmergencyContact,
  IResidentContact,
} from "../../services/common.services";
import {
  getEmergencyAppUsers,
  getOrderedResidentContacts,
  hasPhoneNumbers,
} from "./settingsHelpers";
import { arrayEquals } from "./common/helpers";
import { logout } from "../login/loginSlice";

export type EmergencyItem = {
  id: string;
  first_name?: string | null;
  last_name?: string | null;
  contact_type?: string;
  resident_id?: string;
  emergency_contact?: boolean;
  phone_numbers?:
    | IResidentContact["phone_numbers"]
    | IUserDetails["phone_numbers"];
};

export type GroupEventConfig = {
  primaryGroups: string[];
  mixedPrimaryGroups: boolean;
  escalationGroups: string[];
  mixedEscalationGroups: boolean;
};

type UnitGroupData = {
  unit_id: string;
  groupConfigData: GroupEventConfig;
};

export type EmergencyDragState = {
  emergency_contacts: string[];
  all_contacts: string[];
};

export type dragStateSignature = keyof EmergencyDragState;
export interface ISettingsState {
  state: ReducerStates;
  selectedOption?: string;
  selectedUser?: string;
  selectedGroup?: string;
  selectedGroupConfig: boolean;
  selectedEventType?: string;
  selectedUnit?: string;
  selectedEventConfig?: EventTypeConfig;
  selectedUnitConfigOpt?: string;
  addUser: boolean;
  addNotificationGroup: boolean;
  addUnit: boolean;
  errorCode: string;
  errorMessage?: string;
  handoffSettingsState: ReducerStates;
  handoffSettings?: IParsedHandoffSettings;
  communityEventTypes?: EventType[];
  communityEventConfigs?: EventTypeConfig[];
  groupEventConfig?: GroupEventConfig;
  unitsGroupConfig?: UnitGroupData[];
  unitsEventConfig?: UnitEventTypeConfig[];
  configSelectedUnits?: string[];
  residentEmergencyContacts?: EmergencyContact[];
  emergencyContactsState?: EmergencyItem[];
  emergencyDragState: EmergencyDragState;
}

export const initialSettingsState: ISettingsState = {
  state: ReducerStates.IDLE,
  selectedOption: undefined,
  selectedUser: undefined,
  selectedGroup: undefined,
  selectedGroupConfig: false,
  selectedEventType: undefined,
  selectedEventConfig: undefined,
  selectedUnitConfigOpt: undefined,
  selectedUnit: undefined,
  addUser: false,
  addNotificationGroup: false,
  addUnit: false,
  errorCode: "",
  errorMessage: "",
  handoffSettingsState: ReducerStates.IDLE,
  handoffSettings: undefined,
  communityEventTypes: undefined,
  communityEventConfigs: undefined,
  groupEventConfig: undefined,
  unitsGroupConfig: undefined,
  unitsEventConfig: undefined,
  configSelectedUnits: undefined,
  residentEmergencyContacts: undefined,
  emergencyContactsState: undefined,
  emergencyDragState: {
    emergency_contacts: [],
    all_contacts: [],
  },
};

// Gets the new state for all contacts sorted
const getAllContactsDragState = (
  emergencyContactsState: EmergencyItem[],
  selectedUnit: string
) => {
  const users = getEmergencyAppUsers(
    emergencyContactsState
      .filter((user) => hasPhoneNumbers(user))
      .filter((item) => !item.resident_id) as IUserDetails[],
    selectedUnit
  );
  const contacts = getOrderedResidentContacts(
    emergencyContactsState.filter(
      (item) => item.resident_id
    ) as IResidentContact[]
  );

  return [...users, ...contacts]
    .filter((item) => !item.emergency_contact)
    .map((item) => item.id);
};

type MappedGroups = {
  event_type: string | null;
  notification_type?: string;
  "1"?: string[];
  "2"?: string[];
  "3"?: string[];
  "4"?: string[];
};

const getGroupsData = (mappedGroups: MappedGroups[]) => {
  const groupConfig: GroupEventConfig = {
    primaryGroups: [],
    mixedPrimaryGroups: false,
    escalationGroups: [],
    mixedEscalationGroups: false,
  };
  mappedGroups.forEach((configByEventType, index) => {
    // Add to set to remove duplicates, then convert back to array
    const primaryGroup = [
      ...new Set([
        ...(configByEventType[1] ?? []),
        ...(configByEventType[2] ?? []),
      ]),
    ].sort();
    const escalationGroups =
      configByEventType?.notification_type !== "soft"
        ? [
            ...new Set([
              ...(configByEventType[3] ?? []),
              ...(configByEventType[4] ?? []),
            ]),
          ]
        : [];

    if (index > 0) {
      // Add to set to remove duplicates, then convert back to array
      if (!arrayEquals(primaryGroup, groupConfig.primaryGroups)) {
        groupConfig.primaryGroups = [
          ...new Set([...primaryGroup, ...groupConfig.primaryGroups]),
        ].sort();
        if (
          configByEventType?.event_type !== EventTypes.hand_off_notification
        ) {
          groupConfig.mixedPrimaryGroups = true;
        }
      }

      if (!arrayEquals(escalationGroups, groupConfig.escalationGroups)) {
        if (
          escalationGroups.length > 0 &&
          groupConfig.escalationGroups.length > 0 &&
          configByEventType?.notification_type !== "soft" &&
          configByEventType?.event_type !== EventTypes.pause_notification &&
          configByEventType?.event_type !== EventTypes.unpause_notification
        ) {
          groupConfig.mixedEscalationGroups = true;
        }
        groupConfig.escalationGroups = [
          ...new Set([...escalationGroups, ...groupConfig.escalationGroups]),
        ].sort();
      }
    } else {
      groupConfig.primaryGroups = primaryGroup;
      groupConfig.escalationGroups = escalationGroups;
    }
  });
  return groupConfig;
};

const getCommunityGroupConfig = (communityEventConfig: EventTypeConfig[]) => {
  const mappedGroups: MappedGroups[] = communityEventConfig.map(
    ({ event_type, configuration, notification_type }) => {
      if (configuration === null) return { event_type };

      const config = JSON.parse(configuration) as EventTypeLevelConfig;
      return {
        event_type,
        notification_type,
        "1": config["1"]?.notification_groups,
        "2": config["2"]?.notification_groups,
        "3": config["3"]?.notification_groups,
        "4": config["4"]?.notification_groups,
      };
    }
  );

  return getGroupsData(mappedGroups);
};

const getGroupsByUnit = (unitGroupConfigs: IUnitGroupConfigs[]) => {
  return unitGroupConfigs.map((unitConfig) => {
    const groupsByEventType = [
      ...unitConfig.hard_notifications,
      ...unitConfig.soft_notifications,
    ];
    return {
      unit_id: unitConfig.unit_id,
      groupConfigData: getGroupsData(groupsByEventType),
    };
  });
};

const settingsAsyncThunks = [
  submitMyProfileChanges,
  submitCommunityInfoChanges,
  updateCommunityUser,
  deleteCommunityUser,
  updateNotificationGroup,
  addUserRole,
  loadHandoffSettings,
  sendHandOff,
  addNotificationGroupAction,
  deleteNotificationGroup,
  loadNotificationSettings,
  loadEventConfig,
  updateCommunityEventConfig,
  loadUnitConfigByEvent,
  createUnit,
  getResidentInfo,
  updateResident,
  updatePhoneNumber,
  deletePhoneNumber,
  createPhoneNumber,
  updateCommunityUnit,
  deleteCommunityUnit,
  addResident,
  addResidentContact,
  updateResidentContact,
  deleteResidentContact,
  addContactPhoneNumber,
  updateContactPhoneNumber,
  deleteContactPhoneNumber,
  getEmergencyContacts,
  loadUnitGroupsByEventType,
  resendInvitation,

  updateUnitGroupsConfig,
  updateCommunityGroupsConfig,
] as const;

// Check the state for every async thunk
const isAPendingAction = isPending(...settingsAsyncThunks);

const isAFulfilledAction = isFulfilled(...settingsAsyncThunks);

const isARejectedAction = isRejected(...settingsAsyncThunks);

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    setSettingsOption(state, action: PayloadAction<string>) {
      state.selectedOption = action.payload;
      state.selectedUser = initialSettingsState.selectedUser;
      state.addUser = initialSettingsState.addUser;
      state.addNotificationGroup = initialSettingsState.addNotificationGroup;
    },
    setSettingsUser(state, action: PayloadAction<string>) {
      state.selectedUser = action.payload;
      state.addUser = initialSettingsState.addUser;
    },
    addCommunityUser(state) {
      state.addUser = true;
      state.selectedUser = initialSettingsState.selectedUser;
    },
    setHandoffSettings(state, action: PayloadAction<IParsedHandoffSettings>) {
      state.handoffSettings = action.payload;
    },
    setScheduleEnabled(state, action: PayloadAction<IScheduleEnabledSettings>) {
      if (state.handoffSettings) {
        state.handoffSettings.is_enabled = action.payload.is_enabled;
      }
    },
    setSettingsGroups(state, action: PayloadAction<string>) {
      state.selectedGroup = action.payload;
      state.addNotificationGroup = initialSettingsState.addNotificationGroup;
      state.selectedGroupConfig = initialSettingsState.selectedGroupConfig;
    },
    setSettingsGroupsConfig(state) {
      state.selectedGroupConfig = true;
      state.addNotificationGroup = initialSettingsState.addNotificationGroup;
      state.selectedGroup = initialSettingsState.selectedGroup;
    },
    addNotificationGroup(state) {
      state.addNotificationGroup = true;
      state.selectedGroupConfig = initialSettingsState.selectedGroupConfig;
      state.selectedGroup = initialSettingsState.selectedGroup;
    },
    setSettingsUnit(state, action: PayloadAction<string>) {
      state.selectedUnit = action.payload;
      state.addUnit = initialSettingsState.addUnit;
      state.selectedUnitConfigOpt = initialSettingsState.selectedUnitConfigOpt;
    },
    addUnit(state) {
      state.addUnit = true;
      state.selectedUnit = initialSettingsState.selectedUnit;
      state.selectedUnitConfigOpt = initialSettingsState.selectedUnitConfigOpt;
      state.errorMessage = initialSettingsState.errorMessage;
      state.errorCode = initialSettingsState.errorCode;
    },
    setConfigSelectedUnits(state, action: PayloadAction<string[]>) {
      state.configSelectedUnits = action.payload;
    },
    setUnitConfigOpt(state, action: PayloadAction<string>) {
      state.selectedUnitConfigOpt = action.payload;
      state.errorMessage = initialSettingsState.errorMessage;
      state.errorCode = initialSettingsState.errorCode;
    },
    initEmergencyState(
      state,
      action: PayloadAction<{
        users: IUserDetails[];
        contacts: IResidentContact[];
      }>
    ) {
      const emergencyUsers = state.residentEmergencyContacts
        ?.filter(
          (contact) => contact.user_id !== null && contact.user_id !== undefined
        )
        .map((contact) => contact.user_id) as string[];

      const users = action.payload.users.map((user) => {
        const updatedUser = JSON.parse(JSON.stringify(user));
        if (emergencyUsers.includes(user.id)) {
          updatedUser.emergency_contact = true;
        } else {
          updatedUser.emergency_contact = false;
        }
        return updatedUser as IUserDetails;
      });

      // Include all posible options for initial pool creation
      state.emergencyContactsState = [...users, ...action.payload.contacts];

      // Sort by position to not rely on the list being in the correct order
      const emergencyContactsID =
        state.residentEmergencyContacts
          ?.sort((a, b) => a.position - b.position)
          .map((item) => item.id) ?? [];

      // Filter out emergency contacts to create drag state
      const allContactsIDs = [
        ...users
          .filter((user) => !user.emergency_contact)
          .filter((user) => hasPhoneNumbers(user)),
        ...action.payload.contacts.filter(
          (contact) => !contact.emergency_contact
        ),
      ].map((item) => item.id);

      state.emergencyDragState = {
        emergency_contacts: emergencyContactsID,
        all_contacts: allContactsIDs,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCommunityUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedUser = initialSettingsState.selectedUser;
        }
      })
      .addCase(addUserRole.fulfilled, (state) => {
        state.addUser = initialSettingsState.addUser;
        state.selectedUser = initialSettingsState.selectedUser;
      })
      .addCase(hideSoftNotification.type, (state) => {
        state.errorCode = initialSettingsState.errorCode;
        state.errorMessage = initialSettingsState.errorMessage;
      })
      .addCase(loadHandoffSettings.fulfilled, (state, action) => {
        var configuration = null;
        if (action.payload && action.payload[0]) {
          if (action.payload[0].configuration !== null) {
            configuration = JSON.parse(action.payload[0].configuration);
          }
          const handoffSettings = {
            is_enabled: action.payload[0].is_enabled,
            configuration: configuration,
          };
          state.handoffSettings = handoffSettings;
        }
      })
      .addCase(addNotificationGroupAction.fulfilled, (state) => {
        state.addNotificationGroup = false;
      })
      .addCase(deleteNotificationGroup.fulfilled, (state) => {
        state.selectedGroup = initialSettingsState.selectedGroup;
      })
      .addCase(
        loadNotificationSettings.fulfilled,
        (
          state,
          action: PayloadAction<{
            eventTypes: EventType[];
            eventConfigsCommunity: EventTypeConfig[];
          }>
        ) => {
          const eventTypes = action.payload.eventTypes;
          const eventConfigsCommunity = action.payload.eventConfigsCommunity.map(
            (eventConfig) => eventConfig.event_type
          );

          const communityEventTypes = eventTypes.filter((eventType) =>
            eventConfigsCommunity.includes(eventType.type)
          );
          state.communityEventConfigs = action.payload.eventConfigsCommunity;

          state.groupEventConfig = getCommunityGroupConfig(
            action.payload.eventConfigsCommunity
          );
          state.communityEventTypes = communityEventTypes;
        }
      )
      .addCase(loadEventConfig.fulfilled, (state, action) => {
        const eventConfigIndex = state.communityEventConfigs?.findIndex(
          (eventConfig) => eventConfig.id === action.payload.eventConfig.id
        );
        if (
          state.communityEventConfigs !== undefined &&
          eventConfigIndex !== undefined &&
          eventConfigIndex !== -1
        ) {
          state.communityEventConfigs[eventConfigIndex] =
            action.payload.eventConfig;
        }
        state.unitsEventConfig = action.payload.unitEventConfig;

        state.selectedEventType = action.meta.arg.eventType;
        const eventTypeConfig = state.communityEventConfigs?.find(
          (configEvent) => configEvent.event_type === action.meta.arg.eventType
        );
        if (eventTypeConfig !== undefined) {
          state.selectedEventConfig = eventTypeConfig;
        }
      })
      .addCase(loadEventConfig.rejected, (state, action) => {
        state.selectedEventType = action.meta.arg.eventType;
        const eventTypeConfig = state.communityEventConfigs?.find(
          (configEvent) => configEvent.event_type === action.meta.arg.eventType
        );
        if (eventTypeConfig !== undefined) {
          state.selectedEventConfig = eventTypeConfig;
        }
      })
      .addCase(updateCommunityEventConfig.fulfilled, (state, action) => {
        const eventConfigIndex = state.communityEventConfigs?.findIndex(
          (eventConfig) => eventConfig.id === action.meta.arg.eventConfigID
        );

        // Update configuration in updated event
        if (
          state.communityEventConfigs !== undefined &&
          eventConfigIndex !== undefined &&
          eventConfigIndex !== -1
        ) {
          if (action.payload.enableResponse !== undefined) {
            state.communityEventConfigs[eventConfigIndex].is_enabled =
              action.payload.enableResponse.is_enabled;
          }

          if (action.payload.configResponse !== undefined) {
            state.communityEventConfigs[
              eventConfigIndex
            ].configuration = JSON.stringify(action.payload.configResponse);
          }
        }

        // Update selected event config
        const eventType = state.selectedEventConfig?.event_type;
        if (eventType !== undefined) {
          const eventTypeConfig = state.communityEventConfigs?.find(
            (configEvent) => configEvent.event_type === eventType
          );
          if (eventTypeConfig !== undefined) {
            state.selectedEventConfig = eventTypeConfig;
          }
        }
      })
      .addCase(loadUnitConfigByEvent.fulfilled, (state, action) => {
        state.unitsEventConfig = action.payload;
      })
      .addCase(createUnit.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedUnit = action.payload.newUnit.id;
        }
        state.addUnit = initialSettingsState.addUnit;
      })
      .addCase(updateUnitConfigByEventType.fulfilled, (state, action) => {
        const configsToUpdate = action.meta.arg.unitIDs;
        const enableResponse = action.payload.enableResponse;
        const configResponse = action.payload.configResponse;

        configsToUpdate.forEach((unitID) => {
          // Get index of unit config to update
          const unitConfigIndex = state.unitsEventConfig?.findIndex(
            (localConfig) => localConfig.unit_id === unitID
          );

          // If the index was found, update
          if (unitConfigIndex !== undefined && unitConfigIndex !== -1) {
            if (enableResponse !== undefined) {
              const newConfig = enableResponse.find(
                (unitConfig) => unitConfig.unit_id === unitID
              );
              if (
                newConfig !== undefined &&
                state.unitsEventConfig !== undefined
              ) {
                state.unitsEventConfig[unitConfigIndex].is_enabled =
                  newConfig.is_enabled;
              }
            }
            if (configResponse) {
              const newConfig = configResponse.find(
                (unitConfig) => unitConfig.unit_id === unitID
              );
              if (newConfig !== undefined && state.unitsEventConfig) {
                state.unitsEventConfig[unitConfigIndex].configuration =
                  newConfig.configuration;
              }
            }
          }
        });
      })
      .addCase(deleteCommunityUnit.fulfilled, (state) => {
        state.selectedUnit = initialSettingsState.selectedUnit;
        state.selectedUnitConfigOpt =
          initialSettingsState.selectedUnitConfigOpt;
      })
      .addCase(getEmergencyContacts.fulfilled, (state, action) => {
        state.residentEmergencyContacts = action.payload.sort(
          (a, b) => a.position - b.position
        );
      })
      .addCase(addEmergencyContact.fulfilled, (state, action) => {
        const uniqueID =
          action.meta.arg.contact_id ?? action.meta.arg.user_id ?? "";
        // Remove from all contacts list
        const deleteIndex = state.emergencyDragState.all_contacts.indexOf(
          uniqueID
        );
        if (deleteIndex !== -1) {
          state.emergencyDragState.all_contacts.splice(deleteIndex, 1);
        }

        // Add to emergency resident contact list
        state.residentEmergencyContacts?.push(action.payload);

        // Add to end of the emergency contacts in the drag state
        state.emergencyDragState.emergency_contacts.push(action.payload.id);

        // Update resident state to indicate is a resident contact
        const indexToUpdate = state.emergencyContactsState?.findIndex(
          (item) => item.id === uniqueID
        );
        if (
          state.emergencyContactsState &&
          indexToUpdate !== undefined &&
          indexToUpdate !== -1
        ) {
          state.emergencyContactsState[indexToUpdate].emergency_contact = true;
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(updateEmergencyContactsPosition.fulfilled, (state, action) => {
        if (action.payload) {
          // Update emergency contacts
          state.emergencyDragState.emergency_contacts =
            action.meta.arg.emergency_contact_ids;

          // Update data of emergency contacts
          state.residentEmergencyContacts = state.residentEmergencyContacts?.map(
            (item) => {
              item.position = action.meta.arg.emergency_contact_ids.indexOf(
                item.id
              );
              return item;
            }
          );
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(deleteEmergencyContact.fulfilled, (state, action) => {
        if (action.payload) {
          const emergencyContactItem = state.residentEmergencyContacts?.find(
            (contact) => contact.id === action.meta.arg
          );

          if (emergencyContactItem) {
            // Get index of emergency list to delete
            const deleteIndex = state.emergencyDragState.emergency_contacts.indexOf(
              emergencyContactItem.id
            );

            // Remove from emergency drag and drop list
            state.emergencyDragState.emergency_contacts.splice(deleteIndex, 1);

            // Update property in state of available pool of contacts/users to select
            if (emergencyContactItem.contact_type === "user") {
              const indexToUpdate = state.emergencyContactsState?.findIndex(
                (item) => item.id === emergencyContactItem.user_id
              );
              if (
                state.emergencyContactsState &&
                indexToUpdate !== undefined &&
                indexToUpdate !== -1
              ) {
                state.emergencyContactsState[
                  indexToUpdate
                ].emergency_contact = false;
              }
            } else {
              // Update property in state of available pool of contacts/users to select
              const indexToUpdate = state.emergencyContactsState?.findIndex(
                (item) => item.id === emergencyContactItem.contact_id
              );
              if (
                state.emergencyContactsState &&
                indexToUpdate !== undefined &&
                indexToUpdate !== -1
              ) {
                state.emergencyContactsState[
                  indexToUpdate
                ].emergency_contact = false;
              }
            }

            // Delete from emergency contacts
            const emergencyItemIndex = state.residentEmergencyContacts?.findIndex(
              (item) => item.id === emergencyContactItem.id
            );
            if (
              state.residentEmergencyContacts &&
              emergencyItemIndex !== undefined &&
              emergencyItemIndex !== -1
            ) {
              state.residentEmergencyContacts.splice(emergencyItemIndex, 1);
            }

            // Sort and set new all contacts
            state.emergencyDragState.all_contacts = getAllContactsDragState(
              state.emergencyContactsState ?? [],
              state.selectedUnit ?? ""
            );
          }
        }
      })
      .addCase(addEmergencyContact.pending, (state) => {
        state.errorMessage = initialSettingsState.errorMessage;
        state.errorCode = initialSettingsState.errorCode;
        state.state = ReducerStates.PENDING;
      })
      .addCase(addEmergencyContact.rejected, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      })
      .addCase(updateEmergencyContactsPosition.pending, (state) => {
        state.errorMessage = initialSettingsState.errorMessage;
        state.errorCode = initialSettingsState.errorCode;
        state.state = ReducerStates.PENDING;
      })
      .addCase(updateEmergencyContactsPosition.rejected, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      })
      .addCase(deleteEmergencyContact.rejected, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      })
      .addCase(loadUnitGroupsByEventType.fulfilled, (state, action) => {
        if (action.payload) {
          state.unitsGroupConfig = getGroupsByUnit(action.payload);
        }
      })
      .addCase(updateUnitGroupsConfig.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedUnits = action.meta.arg.unit_ids;
          const primaryGroup = action.meta.arg.level_2;
          const escalationGroups = action.meta.arg.level_3;

          const groupConfig = {
            primaryGroups: primaryGroup,
            mixedPrimaryGroups: false,
            escalationGroups: escalationGroups,
            mixedEscalationGroups: false,
          };

          if (state.unitsGroupConfig !== undefined) {
            updatedUnits.forEach((unitID) => {
              const unitConfig = state.unitsGroupConfig?.find(
                (unitConfig) => unitConfig.unit_id === unitID
              );
              if (unitConfig !== undefined) {
                unitConfig.groupConfigData = groupConfig;
              }
            });
          } else {
            state.unitsGroupConfig = updatedUnits.map((unitID) => {
              return {
                unit_id: unitID,
                groupConfigData: groupConfig,
              };
            });
          }
        }
      })
      .addCase(updateCommunityGroupsConfig.fulfilled, (state, action) => {
        if (action.payload) {
          const primaryGroup = action.meta.arg.requestData.level_2;
          const escalationGroups = action.meta.arg.requestData.level_3;

          state.groupEventConfig = {
            primaryGroups: primaryGroup,
            mixedPrimaryGroups: false,
            escalationGroups: escalationGroups,
            mixedEscalationGroups: false,
          };
        }
      })
      .addCase(logout.type, (state) => {
        return initialSettingsState;
      })
      .addMatcher(isAPendingAction, (state) => {
        state.errorMessage = initialSettingsState.errorMessage;
        state.errorCode = initialSettingsState.errorCode;
        state.state = ReducerStates.PENDING;
      })
      .addMatcher(isAFulfilledAction, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addMatcher(isARejectedAction, (state, action) => {
        if (action.meta.rejectedWithValue) {
          const error = (action.payload as unknown) as IFailedRequest;

          state.errorCode = error?.code || "";
          state.errorMessage = error?.message || "";
        } else {
          state.errorCode = action.error.code ?? "";
          state.errorMessage = action.error.message;
        }
        state.state = ReducerStates.FAILED;
      })
      .addMatcher(
        isAnyOf(
          changeSelectedCommunity,
          changeSelectedOrganization,
          setAllNotifications
        ),
        (state) => initialSettingsState
      );
  },
});

const { reducer } = settingsSlice;

// Destructure and export the plain action creators
export const {
  setSettingsOption,
  setSettingsUser,
  setSettingsGroups,
  addCommunityUser,
  setHandoffSettings,
  setScheduleEnabled,
  addNotificationGroup,
  setSettingsUnit,
  addUnit,
  setConfigSelectedUnits,
  setUnitConfigOpt,
  initEmergencyState,
  setSettingsGroupsConfig,
} = settingsSlice.actions;

export default reducer;
