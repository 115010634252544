import { useState } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  CalendarPicker,
  PickersDay,
  PickersDayProps,
  pickersDayClasses,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { AnalyticsIcons } from "../../helpers/iconImports";
import {
  DateTimeISO,
  DateTimeNow,
  timeConfigurations,
} from "../../helpers/datetime";
import { appTheme } from "../../helpers/constants";

import { styled } from "@mui/material/styles";
import { getLocaleFormat } from "../analytics/CalendarDatePicker";

const DateTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: appTheme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: appTheme.palette.primary.main,
  },
});

const calendarTheme = {
  components: {
    MuiYearPicker: {
      styleOverrides: {
        root: {
          "& > div > button": {
            "&.Mui-selected": {
              backgroundColor: appTheme.palette.primary.main,
              "&:hover": {
                backgroundColor: appTheme.palette.primary.main,
              },
              "&:focus": {
                backgroundColor: appTheme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  },
} as any;

const theme = createTheme(calendarTheme);

const renderWeekPickerDay = (
  date: DateTime,
  selectedDates: Array<DateTime | null>,
  pickersDayProps: PickersDayProps<DateTime>
) => {
  return (
    <PickersDay
      {...pickersDayProps}
      sx={{
        [`&&.${pickersDayClasses.selected}`]: {
          backgroundColor: appTheme.palette.primary.main,
        },
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      padding: theme.spacing(0.5),
      height: "40px",
      width: "100%",
      marginBottom: theme.spacing(8),
      "&:last-child": {
        marginBottom: 0,
      },
      borderRadius: 0,
      borderBottom: `1px solid #949494`,
      "&:hover": {
        backgroundColor: "transparent", // Remove hover effect
      },
      justifyContent: "left",
    },

    controlIcon: {
      height: "28px",
      width: "28px",
    },
    calendarIcon: {
      height: "20px",
      width: "20px",
    },
    calendarButtonRoot: {
      padding: theme.spacing(0.5),
      height: "40px",
      width: "40px",
      marginBottom: theme.spacing(8),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    calendarContainer: {
      paddingTop: theme.spacing(1.5),
    },
    calendarHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    calendarButtonContainer: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0, 1.5, 0, 0),
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export const DateOfBirthDatePicker = (props: IProps) => {
  const { dateOfBirth, saveDateChange } = props;
  const { locale, timeZone } = timeConfigurations();

  /* State */
  const [date, setDate] = useState<DateTime | null>(null);
  const [dateError, setDateError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* Methods */
  // Calendar max date (today)
  const maxDate = DateTimeNow("UTC");
  const minDate = DateTimeISO("1900-01-01T00:00:00.000Z");

  const validateDateRange = (inputDate: DateTime | null) => {
    if (inputDate === null) return null;
    const dateToCheck = inputDate.setZone(timeZone);
    if (dateToCheck.startOf("day") < minDate.startOf("day")) return false;
    if (dateToCheck.startOf("day") > maxDate.startOf("day")) return false;
    return true;
  };

  const handleOpenCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (dateOfBirth) {
      const parsedStartDate = DateTimeISO(dateOfBirth).toUTC();
      setDate(parsedStartDate);
    } else {
      const defaultDOB = DateTime.now()
        .minus({ years: 80 })
        .set({ month: 1, day: 1 });
      setDate(defaultDOB);
    }
    setDateError(false);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (newDate: DateTime | null) => {
    if (validateDateRange(newDate)) {
      setDateError(false);
      setDate(newDate);
    }
  };

  const open = Boolean(anchorEl);
  const calendarPopoverID = open ? "date-of-birth-calendar" : undefined;

  const handleSaveDate = () => {
    if (dateOfBirth && date) {
      if (DateTimeISO(dateOfBirth).toUTC().toISODate() !== date.toISODate()) {
        saveDateChange(date.toUTC().toISODate());
      }
    } else {
      if (date) {
        saveDateChange(date.toISODate());
      }
    }
    setAnchorEl(null);
  };

  const handleDateCancel = () => {
    if (dateOfBirth) {
      setDate(DateTimeISO(dateOfBirth));
    }
    setAnchorEl(null);
  };

  const handleOnManualInput = (newDate: DateTime | null) => {
    if (newDate === null && date !== null) return;
    if (newDate !== null && newDate.isValid && validateDateRange(newDate)) {
      setDateError(!validateDateRange(newDate));
      setDate(newDate.setZone(timeZone));
    } else {
      if (newDate?.isValid) {
        setDateError(!validateDateRange(newDate));
      }
      if (date === null && dateOfBirth) {
        setDate(DateTimeISO(dateOfBirth));
      }
    }
  };

  const inputFormat = getLocaleFormat(locale);
  const placeholderInputFormat = inputFormat.replace("LL", "mm").toUpperCase();

  return (
    <>
      <Button
        classes={{
          root: classes.buttonRoot,
        }}
        aria-label="calendar"
        onClick={handleOpenCalendar}
        disableRipple
      >
        {dateOfBirth && (
          <Typography>
            {DateTime.fromFormat(dateOfBirth, "yyyy-MM-dd")
              .setLocale(locale)
              .toLocaleString(DateTime.DATE_MED)}
          </Typography>
        )}
      </Button>
      <Popover
        id={calendarPopoverID}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterLuxon}>
          <Box display="flex" minHeight={400}>
            <div className={classes.calendarContainer}>
              <div className={classes.calendarHeader}>
                <DatePicker
                  value={date}
                  onChange={handleOnManualInput}
                  inputFormat={inputFormat}
                  disableOpenPicker
                  renderInput={(params) => (
                    <DateTextField
                      {...params}
                      label={t("enter_date")}
                      inputProps={{
                        ...params?.inputProps,
                        placeholder: placeholderInputFormat,
                      }}
                      error={dateError}
                      variant="standard"
                    />
                  )}
                />
              </div>
              <ThemeProvider theme={theme}>
                <CalendarPicker
                  date={date}
                  renderDay={renderWeekPickerDay}
                  maxDate={maxDate}
                  minDate={minDate}
                  onChange={handleDateChange}
                />
              </ThemeProvider>
            </div>
            <div className={classes.calendarButtonContainer}>
              <IconButton
                classes={{
                  root: classes.calendarButtonRoot,
                }}
                aria-label="save calendar date change"
                onClick={handleSaveDate}
              >
                <img
                  src={AnalyticsIcons.CalendarCheck}
                  className={clsx(classes.calendarIcon)}
                  alt={t("alt_text_save_date_calendar")}
                  draggable={false}
                />
              </IconButton>
              <IconButton
                classes={{
                  root: classes.calendarButtonRoot,
                }}
                aria-label="cancel calendar date change"
                onClick={handleDateCancel}
              >
                <img
                  src={AnalyticsIcons.CalendarClose}
                  className={clsx(classes.calendarIcon)}
                  alt={t("alt_text_close_calendar")}
                  draggable={false}
                />
              </IconButton>
            </div>
            <div></div>
          </Box>
        </LocalizationProvider>
      </Popover>
    </>
  );
};

interface IProps {
  dateOfBirth: string | undefined;
  saveDateChange: (dateOfBirth: string) => void;
}
