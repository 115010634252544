import { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import {
  getResidentCity,
  getResidentName,
  isHelpAtHome,
} from "../dashboard/dashboardSlice";
import { IUnit } from "../../services/dashboard.services";
import { FilterSearchField } from "../notifications/filters/FilterSearchField";
import { getUnitDisplayName } from "./common/helpers";
import { setConfigSelectedUnits } from "./settingsSlice";
import useHelpAtHome from "../notifications/hooks/useHelpAtHome";
import { VariableSizeList as ListVariable } from "react-window";
import { UnitGroupItem } from "./UnitGroupItem";
import { ICommunityGroup } from "../../services/header.services";
import { getNotificationGroupText } from "./NotificationGroupTemplateForm";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: theme.typography.secondaryFontFamily,
    },
    filterContainer: {
      width: "50%",
      padding: theme.spacing(2, 0.5),
    },
    unitConfigContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: (props) => props.height,
      padding: theme.spacing(0, 2, 2, 2),
    },
  })
);

export const UnitGroupConfigList = (props: IProps) => {
  const { communityGroups, expanded, height } = props;

  /* Refs */
  const listRef = useRef<any | null>();
  const sizeMap = useRef<{ [key: number]: number }>({});

  /* Hooks */
  const classes = useStyles(props);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const units = useAppSelector((state) => state.headerState.units);

  const unitsGroupConfig = useAppSelector(
    (state) => state.settingsState.unitsGroupConfig
  );

  const [allHelpAtHome] = useHelpAtHome(units);

  /* Filtering */
  // Create controlled list options
  let defaultState: { [key: string]: boolean } = {};

  units?.forEach((unit) => (defaultState[unit.id] = false));

  /* ---- State ---- */
  const [filterSelection, setFilterSelection] = useState(defaultState);
  const [filteredOptions, setFilteredOptions] = useState<IUnit[]>([]);

  /* ---- Effects ---- */
  useEffect(() => {
    setFilteredOptions(units ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertToArray = (obj: { [key: string]: boolean }) => {
    const unitIDs: string[] = [];
    Object.keys(obj).forEach((key) => obj[key] && unitIDs.push(key));
    return unitIDs;
  };

  useEffect(() => {
    dispatch(setConfigSelectedUnits(convertToArray(filterSelection)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelection]);

  // Obtain keys for searched for values
  const filteredOptionKeys = filteredOptions.map((option) => option.id);

  // Check if all searched for values are selected
  const allSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => filterSelection[val]);

  // Check if all searched for values are not selected
  const noneSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => !filterSelection[val]);

  /* ---- Methods ---- */
  const handleChange = (optionID: string) => {
    setFilterSelection((prevState) => {
      return { ...prevState, [optionID]: !prevState[optionID] };
    });
  };

  const handleSelectAll = () => {
    setFilterSelection((prevState) => {
      const keysToUpdate = Object.keys(prevState).filter((option) =>
        filteredOptionKeys.includes(option)
      );
      const newState: {
        [key: string]: boolean;
      } = {};
      if (allSelected) {
        keysToUpdate.forEach((option) => (newState[option as string] = false));
      } else {
        keysToUpdate.forEach((option) => (newState[option as string] = true));
      }
      return { ...prevState, ...newState };
    });
  };

  const handleSearchField = (searchVal: string) => {
    const filteredArray = units?.filter((option) => {
      const isHelpAtHomeUnit = isHelpAtHome(option);
      const unitName = option ? option.name : "-";
      const resident = option
        ? getResidentName(option.residents, {
            fullFirstName: false,
            fullLastName: true,
          })
        : "-";
      const city = option ? getResidentCity(option.residents) : "-";

      if (isHelpAtHomeUnit) {
        return (
          resident.toLowerCase().includes(searchVal) ||
          city.toLowerCase().includes(searchVal)
        );
      } else {
        return (
          unitName.toLowerCase().includes(searchVal) ||
          resident.toLowerCase().includes(searchVal)
        );
      }
    });
    setFilteredOptions(filteredArray ?? []);
  };

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getSize = (index: number) => sizeMap.current[index] + 8 || 50;

  const getValueForNotificationGroup = ({
    group,
    isMixed,
  }: {
    group?: string[] | null;
    isMixed?: boolean | null;
  }) => {
    if (group === undefined) return "-";
    if (isMixed === undefined || isMixed === null) return "-";

    if (isMixed) return t("mixed_groups");

    const allGroups =
      [...(group ?? [])].sort().join(",") ===
      communityGroups
        ?.map((group) => group.id)
        .sort()
        .join(",");

    if (allGroups) return t("all_notification_groups");

    const groupDisplay = getNotificationGroupText(group, communityGroups);
    if (groupDisplay.length === 0) return t("no_notification_groups");

    return groupDisplay.join(", ");
  };

  return (
    <div id="unit-config-container" className={classes.unitConfigContainer}>
      <Typography className={classes.title} component="span">
        {t("notification_group_unit_config")}
      </Typography>
      <div className={classes.filterContainer}>
        <FilterSearchField
          withApplyButton={false}
          placeholder={allHelpAtHome ? "search_resident" : "search_unit"}
          allSelected={allSelected}
          noneSelected={noneSelected}
          selectAllCheckbox={expanded}
          handleSelectAll={handleSelectAll}
          handleSearchField={handleSearchField}
        />
      </div>

      <ListVariable
        ref={listRef}
        height={height}
        width="100%"
        itemCount={filteredOptions.length}
        itemSize={getSize}
        itemData={filteredOptions}
      >
        {({ data, index, style }) => {
          const unitID = data[index].id;

          const unitConfig = unitsGroupConfig?.find(
            (config) => config.unit_id === unitID
          );

          let primaryGroupValue = "-";
          let escalationGroupValue = "-";
          if (unitConfig !== undefined) {
            primaryGroupValue = getValueForNotificationGroup({
              group: unitConfig.groupConfigData.primaryGroups,
              isMixed: unitConfig.groupConfigData.mixedPrimaryGroups,
            });
            escalationGroupValue = getValueForNotificationGroup({
              group: unitConfig.groupConfigData.escalationGroups,
              isMixed: unitConfig.groupConfigData.mixedEscalationGroups,
            });
          }

          let unitDisplayName = getUnitDisplayName(data[index]);

          return (
            <div style={style}>
              <UnitGroupItem
                key={unitID}
                id={unitID}
                expanded={expanded}
                primaryText={unitDisplayName.primary}
                secondaryText={unitDisplayName.secondary}
                primaryGroup={primaryGroupValue}
                escalationGroup={escalationGroupValue}
                selected={filterSelection[unitID]}
                toggleSelected={handleChange}
                index={index}
                setSize={setSize}
              />
            </div>
          );
        }}
      </ListVariable>
    </div>
  );
};

interface IProps {
  expanded: boolean;
  height: number;
  communityGroups?: ICommunityGroup[] | undefined;
}
