import { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/appHooks";

import { DevicesUnitList } from "./DevicesUnitList";
import { FloatingNotificationPanel } from "../common/FloatingNotificationPanel";
import {
  loadDevicesIssues,
  loadDevicesData,
  loadGatewaysData,
} from "./devicesThunks";
import { DevicesViewer } from "./DevicesViewer";
import { minToolbarHeight } from "../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    devicesContainer: {
      display: "flex",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    devicesViewerContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      gap: "50px",
      padding: theme.spacing(6),
      overflowY: "auto",
      overflowX: "hidden",
      "@media (max-height: 830px)": {
        overflowY: "auto",
      },
      [theme.breakpoints.down("md")]: {
        overflowY: "auto",
      },

      [theme.breakpoints.down("md")]: {
        overflowX: "scroll",
      },
    },
  })
);

export default function Devices() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const dispatch = useDispatch();

  /* ---- Selectors ---- */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const selectedUnit = useAppSelector(
    (state) => state.devicesState.selectedUnit
  );

  const installerRole = useAppSelector(
    (state) => state.headerState.installerRole
  );

  /* ---- Effects ---- */
  // Fetch devices status
  useEffect(() => {
    if (communityID !== undefined) {
      dispatch(loadDevicesIssues(communityID));
    }
  }, [communityID, dispatch]);

  useEffect(() => {
    if (selectedUnit !== undefined) {
      dispatch(loadDevicesData(selectedUnit));
      dispatch(loadGatewaysData(selectedUnit));
    }
  }, [dispatch, selectedUnit]);

  return (
    <div className={classes.devicesContainer}>
      <DevicesUnitList />
      <div className={classes.devicesViewerContainer}>
        <DevicesViewer />
      </div>
      {!installerRole && <FloatingNotificationPanel />}
    </div>
  );
}
