import { List, Typography } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";

import { ICommunityGroup } from "../../services/header.services";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      background: "#FFF",
      padding: theme.spacing(0, 1),
    },
    optionsContainer: {
      width: "100%",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
    },
    title: {
      padding: theme.spacing(0, 1),
    },
  })
);

const SecondaryListItem = withStyles(({ palette, spacing }) => ({
  root: {
    paddingBottom: spacing(1),
    paddingTop: spacing(1),
    marginBottom: spacing(1),
    width: "100%",
    borderRadius: "0px",
    "&$selected": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
  },
  selected: {},
  divider: {},
}))(MuiListItem);

export const NotificationGroupsMenu = (props: IProps) => {
  const { groups, selected, onClickGroup } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <h3 className={classes.title}>
        {t("notification_groups").toUpperCase()}
      </h3>
      {groups !== undefined && groups.length > 0 && (
        <List className={classes.optionsContainer}>
          {groups.map((group) => {
            return (
              <SecondaryListItem
                button
                key={group.id}
                divider
                disableGutters
                onClick={() => onClickGroup(group.id)}
                selected={group.id === selected}
                className={classes.listContainer}
              >
                <Typography variant="subtitle2">{group.name}</Typography>
              </SecondaryListItem>
            );
          })}
        </List>
      )}
    </>
  );
};

interface IProps {
  groups?: ICommunityGroup[];
  selected?: string;
  onClickGroup: (groupID: string) => void;
}
