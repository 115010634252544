import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeader: {
      opacity: "0.54",
      wordBreak: "break-all",
    },
    sectionSecondary: {
      overflowX: "hidden",
    },
  })
);

export default function NoEventDataChart() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box height="100%">
      <Box display="flex" alignItems="center" justifyContent="left">
        <Typography className={classes.sectionHeader}>
          {t("chart").toUpperCase()}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 16px)"
      >
        <Typography className={classes.sectionSecondary}>
          {t("event_data_load_error")}
        </Typography>
      </Box>
    </Box>
  );
}
