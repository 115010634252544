import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReducerStates } from "../../helpers/constants";
import { IResident } from "../../services/dashboard.services";
import { commonServices, IResidentData } from "../../services/common.services";
import { logout } from "../login/loginSlice";
import {
  addContactPhoneNumber,
  addResidentContact,
  deleteContactPhoneNumber,
  deleteResidentContact,
  getResidentInfo,
  updateContactPhoneNumber,
  updateResidentContact,
} from "../settings/settingsThunks";

export interface IAppState {
  contactInformation: { [key: string]: IResident | undefined };
  state: ReducerStates;
  errorCode: string;
  errorMessage?: string;
}

export const initialCommonState: IAppState = {
  contactInformation: {},
  state: ReducerStates.IDLE,
  errorCode: "",
  errorMessage: "",
};

// Gets the community units and events for the community
export const getResidentContacts = createAsyncThunk(
  "common/getResidentContacts",
  async (residentData: IResidentData) => {
    const resident = await commonServices.getResidentContacts(residentData);
    return resident;
  }
);

const getResidentContact = (
  contactInformation: { [key: string]: IResident | undefined },
  residentID: string,
  contactID: string
) => {
  return (
    contactInformation[residentID]?.contacts?.find(
      (contact) => contact.id === contactID
    ) ?? undefined
  );
};

const commonSlice = createSlice({
  name: "common",
  initialState: initialCommonState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResidentContacts.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialCommonState.errorCode;
        state.errorMessage = initialCommonState.errorMessage;
      })
      .addCase(getResidentContacts.fulfilled, (state, action) => {
        state.state = ReducerStates.SUCCEEDED;
        state.contactInformation[action.meta.arg.resident_id] = action.payload;
      })
      .addCase(getResidentContacts.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(addResidentContact.fulfilled, (state, action) => {
        if (action.payload) {
          const resident = state.contactInformation[action.payload.resident_id];
          if (resident) {
            if (resident.contacts) {
              resident.contacts.push(action.payload);
            } else {
              resident.contacts = [action.payload];
            }
          }
        }
      })
      .addCase(updateResidentContact.fulfilled, (state, action) => {
        const resident = state.contactInformation[action.payload.resident_id];
        if (action.payload && resident) {
          if (resident.contacts) {
            const contactIndex = resident.contacts.findIndex(
              (contact) => contact.id === action.payload.id
            );
            if (contactIndex !== -1) {
              resident.contacts[contactIndex] = {
                ...resident.contacts[contactIndex],
                ...action.payload,
              };
            } else {
              resident.contacts.push(action.payload);
            }
          } else {
            resident.contacts = [action.payload];
          }
        }
      })
      .addCase(deleteResidentContact.fulfilled, (state, action) => {
        if (action.payload) {
          // error, estoy accediento meta arg q es contact id y esta indextado por resident
          const resident = state.contactInformation[action.meta.arg.residentID];
          if (resident !== undefined && resident.contacts) {
            const contactIndex = resident.contacts.findIndex(
              (contact) => contact.id === action.meta.arg.contactID
            );
            if (contactIndex !== -1) {
              resident.contacts.splice(contactIndex, 1);
            }
          }
        }
      })
      .addCase(addContactPhoneNumber.fulfilled, (state, action) => {
        const newPhoneNumber = action.payload;
        if (newPhoneNumber && newPhoneNumber.contact_id) {
          const contact = getResidentContact(
            state.contactInformation,
            action.meta.arg.residentID,
            newPhoneNumber.contact_id
          );
          if (contact) {
            if (contact.phone_numbers) {
              contact.phone_numbers.push(newPhoneNumber);
            } else {
              contact.phone_numbers = [newPhoneNumber];
            }
          }
        }
      })
      .addCase(updateContactPhoneNumber.fulfilled, (state, action) => {
        const updatedPhoneNumber = action.payload;
        if (updatedPhoneNumber && updatedPhoneNumber.contact_id) {
          const contact = getResidentContact(
            state.contactInformation,
            action.meta.arg.residentID,
            updatedPhoneNumber.contact_id
          );
          if (contact) {
            if (contact.phone_numbers) {
              const indexToUpdate = contact.phone_numbers.findIndex(
                (phone) => phone.id === updatedPhoneNumber.id
              );
              if (indexToUpdate !== -1) {
                contact.phone_numbers[indexToUpdate] = {
                  ...contact.phone_numbers[indexToUpdate],
                  ...updatedPhoneNumber,
                };
              } else {
                contact.phone_numbers.push(updatedPhoneNumber);
              }
            } else {
              contact.phone_numbers = [updatedPhoneNumber];
            }
          }
        }
      })
      .addCase(deleteContactPhoneNumber.fulfilled, (state, action) => {
        const contact = getResidentContact(
          state.contactInformation,
          action.meta.arg.residentID,
          action.meta.arg.contactID
        );
        if (contact && contact.phone_numbers) {
          const indexToDelete = contact.phone_numbers.findIndex(
            (phone) => phone.id === action.meta.arg.deleteData.phoneID
          );

          if (indexToDelete !== -1) {
            contact.phone_numbers.splice(indexToDelete, 1);
          }
        }
      })
      .addCase(getResidentInfo.fulfilled, (state, action) => {
        state.contactInformation[action.meta.arg.resident_id] = action.payload;
      })
      .addCase(logout.type, (state) => {
        return initialCommonState;
      });
  },
});

const { reducer } = commonSlice;

export default reducer;
