import { List, ListItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContainer: {
      maxWidth: "320px",
      minWidth: "250px",
      maxHeight: "500px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    listRoot: {
      borderRadius: 0,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "inherit",
      },
    },
    contentContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(0.5),
    },
    itemBorder: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },

    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
  })
);

export const GenericPicker = <T extends GenericList>(props: IProps<T>) => {
  const { selectedValue, optionList, localize, handleChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <List aria-label="dropwdown list" className={classes.popoverContainer}>
        {optionList?.map((option, index, arr) => {
          return (
            <ListItem
              classes={{ root: classes.listRoot }}
              key={option.id}
              id={option.id}
              selected={option.id === selectedValue}
              button
              onClick={() => handleChange(option.id)}
              className={clsx(
                classes.contentContainer,
                index < arr.length - 1 && classes.itemBorder
              )}
            >
              {localize ? t(option.label) : option.label}
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

type GenericList = { id: string; label: string };

interface IProps<T extends GenericList> {
  selectedValue: T["id"] | null;
  optionList: T[];
  localize: boolean;
  handleChange: (id: string) => void;
}
