import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import EventComment from "./EventComment";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as SendMessageIcon } from "../../../assets/img/icon.discussions.send_message.svg";
import { useSelector } from "react-redux";
import { IEvent, IEventNotes } from "../../../services/dashboard.services";
import { postNewComment } from "../../app/asyncThunkActions";
import { IAppState } from "../../../helpers/store";
import { getFirebaseDBReference } from "../../../helpers/firebase";
import { updateEventNotes } from "../dashboardSlice";
import {
  BASE64_COMMENT_CHAR_LIMIT,
  BASE64_STRING,
  LIMIT_TO_VALIDATE_BASE64,
  utf8_to_b64,
} from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../app/appHooks";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addCommentContainer: {
      display: "flex",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
      alignItems: "center",
    },
    addCommentInput: {
      width: "95%",
      fontSize: "small",
    },
    commentsTitle: {
      opacity: "0.54",
    },
    commentsContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
  })
);

export default function EventComments(props: IProps) {
  const { event } = props;

  const eventID = event?.id;
  const comments = event?.event_notes;
  const unitID = event?.unit_id;

  const selectedCommunityID = useAppSelector(
    (state: IAppState) => state.headerState.selectedCommunity?.id
  );

  const communityID = event?.community_id ?? selectedCommunityID ?? undefined;

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const communityUnitTimezone = useCommunityUnitTimezone(communityID, unitID);

  /* State */
  const [newComment, setNewComment] = useState("");

  /* Methods */
  const handlePostNewcomment = async () => {
    if (newComment && newComment.trim().length > 0 && eventID) {
      const resultAction = await dispatch(
        postNewComment({ event_id: eventID, description: newComment })
      );
      if (postNewComment.fulfilled.match(resultAction)) {
        setNewComment("");
      }
    }
  };

  const handleChangeNewComment = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let { value } = e.target;
    if (value.length > LIMIT_TO_VALIDATE_BASE64) {
      const base64String = utf8_to_b64(value);
      if (
        base64String.length + BASE64_STRING.length <=
        BASE64_COMMENT_CHAR_LIMIT
      ) {
        setNewComment(value);
      }
    } else {
      setNewComment(value);
    }
  };

  /* Selectors */
  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );

  useEffect(() => {
    if (!firebaseAuth) return;
    if (event === undefined) return;
    if (communityID === undefined) return;
    const firebaseKey = `${event.time_created.replace("T", " ")}-${event.id}`;
    const eventRef = getFirebaseDBReference().ref(
      `communities/${communityID}/event_notes/${firebaseKey}`
    );
    const listener = eventRef.on("value", (snapshot) => {
      const eventNotes = snapshot.val();
      if (eventNotes === null) return;
      let _notes: IEventNotes[] = [];
      for (var key in eventNotes) {
        _notes.push({
          ...eventNotes[key],
        });
      }
      dispatch(updateEventNotes({ eventID: event.id, eventNotes: _notes }));
    });
    return () => eventRef.off("value", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityID, firebaseAuth, event]);

  return (
    <React.Fragment>
      <div className={classes.commentsContainer}>
        <Typography className={classes.commentsTitle}>
          {t("comments").toUpperCase()}
        </Typography>
        <div className={classes.addCommentContainer} id="comments-container">
          <TextField
            id="standard-basic"
            className={classes.addCommentInput}
            placeholder={t("add_note")}
            onChange={handleChangeNewComment}
            value={newComment}
          />
          <IconButton onClick={handlePostNewcomment}>
            <SendMessageIcon
              opacity={newComment.trim().length > 0 ? "1" : "0.5"}
            />
          </IconButton>
        </div>

        {comments &&
          comments.map((comment) => {
            return (
              <EventComment
                key={comment.id}
                comment={comment}
                communityTimezone={communityUnitTimezone}
              />
            );
          })}
      </div>
    </React.Fragment>
  );
}

interface IProps {
  event?: IEvent;
}
