import React from "react";
import { useSelector } from "react-redux";
import store, { IAppState } from "../../helpers/store";
import { useTranslation } from "react-i18next";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { hideSoftNotification } from "../app/appSlice";

const useStyles = makeStyles({
  root: {
    flexWrap: "nowrap",
  },
  message: {
    display: "flex",
    flex: "1 0 85%",
    flexWrap: "wrap",
  },
  action: {
    paddingLeft: "0px",
  },
});

export const SoftNotificationTypes = {
  CREDENTIALS_EXPIRED: "failed_token_refresh",
  INTERNAL_ERROR_LOADING: "internal_error_loading",
  INTERNAL_ERROR_UPDATING: "internal_error_updating",
  SERVICE_UNAVAILABLE_LOADING: "service_unavailable_loading",
  SERVICE_UNAVAILABLE_UPDATING: "service_unavailable_updating",
  CONNECTION_OFFLINE: "connection_offline",
  VERIFICATION_CODE_RESENT: "verification_code_resent",
  LAST_ADMIN: "last_admin",
  EMAIL_ALREADY_EXIST_ERROR: "email_already_exist_error",
  APPLIED_CONFIG_CONFIRMATION: "applied_config_confirmation",
  EMERGENCY_CONTACT_LAST_PHONE_NUMBER: "emergency_contacts_last_phone_message",
  GATEWAY_OFFLINE: "gateway_offline_message",
  ZONE_NAME_CONFLICT: "zone_name_conflict",
};

interface ISnackTemplate {
  config: {
    disableWindowBlurListener: boolean;
    ContentProps: { [key: string]: string };
    autoHideDuration: number | null;
    onClose: (() => void) | undefined;
  };
  localized: boolean;
  components: {
    content: boolean;
    actionButton: boolean;
  };
  texts: {
    content: string;
    actionButton: string;
  };
  actions: {
    actionButton: () => void;
  };
  disableAction: boolean;
}

const softConfigTemplate = (content: string): ISnackTemplate => {
  const closeNotification = () => {
    store.dispatch(hideSoftNotification());
  };
  return {
    config: {
      disableWindowBlurListener: true,
      ContentProps: {
        "aria-describedby": "message-id",
      },
      autoHideDuration: null,
      onClose: undefined,
    },
    localized: true,
    components: {
      content: true,
      actionButton: false,
    },
    texts: {
      content: content,
      actionButton: "",
    },
    actions: {
      actionButton: closeNotification,
    },
    disableAction: false,
  };
};

const loadSoftNotificationType = (notificationType: string) => {
  switch (notificationType) {
    case SoftNotificationTypes.CREDENTIALS_EXPIRED:
      return softConfigTemplate(SoftNotificationTypes.CREDENTIALS_EXPIRED);
    case SoftNotificationTypes.INTERNAL_ERROR_LOADING:
      return softConfigTemplate(SoftNotificationTypes.INTERNAL_ERROR_LOADING);

    case SoftNotificationTypes.INTERNAL_ERROR_UPDATING:
      return softConfigTemplate(SoftNotificationTypes.INTERNAL_ERROR_UPDATING);

    case SoftNotificationTypes.SERVICE_UNAVAILABLE_LOADING:
      return softConfigTemplate(
        SoftNotificationTypes.SERVICE_UNAVAILABLE_LOADING
      );
    case SoftNotificationTypes.SERVICE_UNAVAILABLE_UPDATING:
      return softConfigTemplate(
        SoftNotificationTypes.SERVICE_UNAVAILABLE_UPDATING
      );
    case SoftNotificationTypes.CONNECTION_OFFLINE:
      const connectionOfflineConfig = softConfigTemplate(
        SoftNotificationTypes.CONNECTION_OFFLINE
      );
      connectionOfflineConfig.disableAction = true;
      return connectionOfflineConfig;
    case SoftNotificationTypes.VERIFICATION_CODE_RESENT:
      return softConfigTemplate(SoftNotificationTypes.VERIFICATION_CODE_RESENT);
    case SoftNotificationTypes.EMAIL_ALREADY_EXIST_ERROR:
      return softConfigTemplate(notificationType);
    case SoftNotificationTypes.APPLIED_CONFIG_CONFIRMATION:
      const snackConfig = softConfigTemplate(
        SoftNotificationTypes.APPLIED_CONFIG_CONFIRMATION
      );
      snackConfig.config.disableWindowBlurListener = false;
      snackConfig.config.autoHideDuration = 3000;
      snackConfig.disableAction = true;
      snackConfig.config.onClose = snackConfig.actions.actionButton;
      return snackConfig;
    case SoftNotificationTypes.EMERGENCY_CONTACT_LAST_PHONE_NUMBER:
      return softConfigTemplate(notificationType);
    case SoftNotificationTypes.GATEWAY_OFFLINE:
      return softConfigTemplate(SoftNotificationTypes.GATEWAY_OFFLINE);
    case SoftNotificationTypes.ZONE_NAME_CONFLICT:
      return softConfigTemplate(SoftNotificationTypes.ZONE_NAME_CONFLICT);
    default:
      const closeNotification = () => {
        store.dispatch(hideSoftNotification());
      };
      const defaultVal = {
        config: {
          disableWindowBlurListener: true,
          ContentProps: {
            "aria-describedby": "message-id",
          },
        },
        localized: false,
        components: {
          content: true,
          actionButton: false,
        },
        texts: {
          content: notificationType,
          actionButton: "",
        },
        actions: {
          actionButton: closeNotification,
        },
        disableAction: false,
      };
      return defaultVal;
  }
};

// Display a modal to make the user acknowledge something
export const AppSnack = (props: IProps) => {
  const { isLoading } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  /* ---- Selectors ---- */
  const notificationToggle = useSelector(
    (state: IAppState) => state.appState.softNotificationToggle
  );
  const notificationType = useSelector(
    (state: IAppState) => state.appState.softNotificationType
  );

  const notificationConfig = loadSoftNotificationType(notificationType);

  const {
    config,
    components,
    texts,
    actions,
    localized,
    disableAction,
  } = notificationConfig;

  return (
    <Snackbar
      key={Date.now()}
      {...config}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={!isLoading && notificationToggle}
    >
      <SnackbarContent
        classes={{
          root: classes.root,
          message: classes.message,
          action: classes.action,
        }}
        message={
          components?.content && (
            <span id="message-id">
              {localized ? t(texts?.content) : texts?.content}
            </span>
          )
        }
        action={
          !disableAction &&
          (components?.actionButton ? (
            <Button
              color="inherit"
              size="small"
              onClick={actions?.actionButton}
            >
              {t(texts?.actionButton)}
            </Button>
          ) : (
            [
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={actions?.actionButton}
              >
                <CloseIcon />
              </IconButton>,
            ]
          ))
        }
      />
    </Snackbar>
  );
};

interface IProps {
  isLoading: boolean;
}
