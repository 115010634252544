import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  OutlinedInput,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  accountCreationInit,
  backToAccountCreation,
  resendVerificationEmail,
  verifyEmail,
} from "./../loginSlice";
import { ErrorCodes } from "../../../services/constants";
import { useAppDispatch, useAppSelector } from "../../app/appHooks";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ActionBarBottom } from "./ActionBarBottom";
import { StackLogo } from "../StackLogo";
import { VERIFICATION_CODE_LENGTH, Views } from "../../../helpers/constants";
import { useHistory } from "react-router-dom";
import {
  handledError,
  hideSoftNotification,
  showSoftNotification,
} from "../../app/appSlice";
import { SoftNotificationTypes } from "../../common/AppSnack";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginFormContainer: {
      width: "380px",
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        width: "360px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    helperText: {
      minHeight: "20px",
    },
    subtitleText: {
      textAlign: "center",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: 500,
      fontSize: theme.typography.subtitle1.fontSize,
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(3),
    },
    secondaryButtons: {
      color: theme.palette.paused.light,
    },
    paper: {
      position: "absolute",
      width: 300,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.paused.light}`,
      boxShadow: theme.shadows[5],
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
    },
    modalBody: {
      padding: theme.spacing(3, 3, 2, 3),
    },
    modalTitle: {
      textAlign: "center",
      color: theme.palette.dark.main,
      margin: 0,
    },
    modalContent: {
      whiteSpace: "pre-line",
      fontSize: theme.typography.body1.fontSize,
    },
    modalButton: {
      borderTop: `1px solid ${theme.palette.paused.light}`,
      textAlign: "center",
      padding: theme.spacing(2, 0),
      cursor: "pointer",
      fontSize: "1rem",
    },
  })
);

const RESEND_CODE_TIMER = 3;

export const EmailVerificationForm = () => {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  /* ---- State ---- */
  const [verificationCode, setVerificationCode] = useState("");
  const [formError, setFormError] = useState("");
  const [modalMessage, setModalMessage] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(0);

  /* ---- Selectors ---- */
  const errorCode = useAppSelector((state) => state.loginState.errorCode);
  const continueAccountCreation = useAppSelector(
    (state) => state.loginState.continueAccountCreation
  );

  const accountData = useAppSelector(
    (state) => state.loginState.accountCreationData
  );

  /* ---- Effects ---- */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.INVALID_VERIFICATION_TOKEN:
          dispatch(handledError());
          setFormError(t("invalid_verification_code"));
          break;
        case ErrorCodes.VERIFICATION_TOKEN_EXPIRED:
          dispatch(handledError());
          setFormError(t("expired_verification_code"));
          break;
      }
    }
  }, [errorCode, t, dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (debounceTimer > 0) {
        setDebounceTimer(debounceTimer - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [debounceTimer]);

  useEffect(() => {
    if (continueAccountCreation) setModalMessage(continueAccountCreation);
  }, [continueAccountCreation]);

  /* ---- Methods ---- */
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setVerificationCode(value.replace(" ", ""));
    setFormError("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (
      verificationCode &&
      accountData !== undefined &&
      accountData.email !== ""
    ) {
      const verifyEmailData = {
        email: accountData.email,
        code: verificationCode,
      };
      dispatch(verifyEmail(verifyEmailData));
      dispatch(hideSoftNotification());
    }
  };

  const handleBackToAccountCreation = () => {
    dispatch(backToAccountCreation());
  };

  const handleResendCode = () => {
    if (accountData !== undefined && accountData.email !== "") {
      dispatch(resendVerificationEmail(accountData.email));
      dispatch(
        showSoftNotification(SoftNotificationTypes.VERIFICATION_CODE_RESENT)
      );
      setDebounceTimer(RESEND_CODE_TIMER);
    }
  };

  const handleClose = () => {
    setModalMessage(false);
  };

  const handleBackToLogin = () => {
    dispatch(accountCreationInit());
    history.push(Views.LOGIN);
  };

  const userEmail = accountData?.email;

  const disableSubmit =
    verificationCode === "" ||
    verificationCode.length < VERIFICATION_CODE_LENGTH ||
    formError !== "";

  return (
    <React.Fragment>
      <StackLogo />
      <form
        id="validate-email-form"
        noValidate
        className={classes.loginFormContainer}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <p className={classes.subtitleText}>
          {t("verification_code_email", {
            email: userEmail !== undefined ? `(${userEmail}) ` : ` `,
          })}
        </p>
        <FormControl variant="outlined" fullWidth className={classes.textField}>
          <InputLabel htmlFor="verificationCode" error={formError !== ""}>
            {t("email_verification_code")}
          </InputLabel>
          <OutlinedInput
            name="verificationCode"
            id="verificationCode"
            value={verificationCode}
            onChange={handleFormChange}
            label={t("email_verification_code")}
            autoComplete="off"
            error={formError !== ""}
          />
          <FormHelperText
            id="verification_code-error"
            className={classes.helperText}
            component="span"
            error={formError !== ""}
          >
            {formError}
          </FormHelperText>
        </FormControl>

        <Button
          type="submit"
          variant={disableSubmit ? "outlined" : "contained"}
          color="primary"
          size="large"
          disabled={disableSubmit}
          className={classes.textField}
        >
          {t("verify")}
        </Button>
      </form>
      <ActionBarBottom>
        <Button
          onClick={
            continueAccountCreation
              ? handleBackToLogin
              : handleBackToAccountCreation
          }
          startIcon={<ArrowBackIosIcon />}
          className={classes.secondaryButtons}
        >
          {t("action_back")}
        </Button>
        <Button
          onClick={handleResendCode}
          className={classes.secondaryButtons}
          disabled={debounceTimer > 0}
        >
          {`${debounceTimer > 0 ? `(${debounceTimer}) ` : ""}${t(
            "resend_code"
          )}`}
        </Button>
      </ActionBarBottom>
      <Modal
        open={modalMessage}
        onClose={handleClose}
        hideBackdrop
        disableEnforceFocus
        disableAutoFocus
      >
        <div className={classes.paper}>
          <div className={classes.modalBody}>
            <h3 className={classes.modalTitle}>
              {t("continue_account_creation")}
            </h3>
            <p className={classes.modalContent}>
              {t("continue_account_creation_body")}
            </p>
          </div>
          <div onClick={handleClose} className={classes.modalButton}>
            {t("ok")}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
