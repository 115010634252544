import {
  AccordionActions,
  AccordionDetails,
  Button,
  Divider,
  Theme,
  withStyles,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      fontFamily: theme.typography.primaryFontFamily,
    },
  })
);

const Accordion = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.light.main}`,
    borderRadius: "5px",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 0,
    "&$expanded": {
      minHeight: 0,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AddItemAccordion = (props: IProps) => {
  const {
    expanded,
    toggle,
    cancelText,
    cancelAction,
    addText,
    addAction,
    disabledAddAction,
    children,
    withHeader,
  } = props;
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  const handleToggle = () => {
    if (expanded) {
      toggle(false);
      cancelAction();
    } else {
      toggle(true);
    }
  };
  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          expanded ? (
            withHeader ? (
              <ExpandMoreOutlinedIcon color="disabled" />
            ) : null
          ) : (
            <AddIcon color="disabled" />
          )
        }
        onClick={handleToggle}
      ></AccordionSummary>
      {withHeader && <Divider />}
      <AccordionDetails> {expanded && children}</AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button
          className={clsx(classes.buttons)}
          onClick={cancelAction}
          size="small"
          classes={{ label: classes.buttons }}
        >
          {cancelText !== undefined ? t(cancelText) : t("cancel")}
        </Button>
        <Button
          onClick={addAction}
          disabled={disabledAddAction}
          size="small"
          color="primary"
          className={clsx(classes.buttons)}
          classes={{ label: classes.buttons }}
        >
          {addText !== undefined ? t(addText) : t("add")}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

interface IProps {
  expanded: boolean;
  toggle: (state: boolean) => void;
  cancelText?: string;
  cancelAction: () => void;
  addText?: string;
  addAction: () => void;
  disabledAddAction: boolean;
  children: React.ReactNode;
  withHeader?: boolean;
}
