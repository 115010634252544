import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  createAccount,
  getUserGeolocation,
  goToInstructions,
} from "./../loginSlice";
import { ErrorCodes } from "../../../services/constants";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { emailValidation, passwordValidation } from "../../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../app/appHooks";
import {
  NAME_CHARACTER_LIMIT,
  VERIFICATION_CODE_LENGTH,
} from "../../../helpers/constants";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ActionBarBottom } from "./ActionBarBottom";
import { StackLogo } from "../StackLogo";
import { handledError } from "../../app/appSlice";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginFormContainer: {
      width: "380px",
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: "360px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    link: {
      cursor: "pointer",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: 500,
      fontSize: theme.typography.subtitle1.fontSize,
      textAlign: "right",
    },
    textFieldPassword: {
      marginTop: "0px",
    },
    helperText: {
      lineHeight: 1,
      marginBottom: "-4px",
    },
    subtitleContainer: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        marginTop: theme.spacing(4),
      },
      "& img": {
        maxWidth: "60%",
        margin: "auto",
      },
    },
    linkContainer: {
      width: "380px",
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "absolute",
      bottom: "5px",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    halfFieldsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    halfWidth: {
      width: "49%",
    },
    secondaryButtons: {
      color: theme.palette.paused.light,
    },
  })
);

export const AccountCreationForm = () => {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  /* ---- State ---- */

  const [createAccountForm, setCreateAccountForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    token: "",
  });
  const [showPassword, setShowPassword] = useState("password");
  const [formError, setFormError] = useState({
    emailError: "",
    passwordError: "",
    tokenError: "",
  });

  /* ---- Selectors ---- */
  const errorCode = useAppSelector((state) => state.loginState.errorCode);
  const accountDataState = useAppSelector(
    (state) => state.loginState.accountCreationData
  );
  const userCountry = useAppSelector((state) => state.loginState.userCountry);

  /* ---- References ---- */
  const passwordRef = useRef(document.createElement("input"));

  /* ---- Effects ---- */
  useEffect(() => {
    if (accountDataState !== undefined) {
      setCreateAccountForm(accountDataState);
    }
  }, [accountDataState]);

  useEffect(() => {
    dispatch(getUserGeolocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.EMAIL_IN_USE:
          dispatch(handledError());
          setFormError({
            emailError: t("account_already_exist"),
            passwordError: "",
            tokenError: "",
          });
          break;
        case ErrorCodes.INVALID_VERIFICATION_TOKEN:
          dispatch(handledError());
          setFormError({
            emailError: "",
            passwordError: "",
            tokenError: t("invitation_code_invalid"),
          });
          break;
        case ErrorCodes.VERIFICATION_TOKEN_EXPIRED:
          dispatch(handledError());
          setFormError({
            emailError: "",
            passwordError: "",
            tokenError: t("invitation_code_expired"),
          });
          break;
      }
    }
  }, [errorCode, t, dispatch]);

  // Set cursor when showing/hiding passwords
  useEffect(() => {
    // Moving cursor to the end
    if (passwordRef.current && passwordRef.current.value) {
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [createAccountForm.email]);

  // Set cursor when showing/hiding passwords
  useEffect(() => {
    // Moving cursor to the end
    if (passwordRef.current && passwordRef.current.value) {
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [createAccountForm.email]);

  /* ---- Methods ---- */
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target;
    setCreateAccountForm({ ...createAccountForm, [name]: value });
    setFormError((prevVal) => ({ ...prevVal, [`${[name]}Error`]: "" }));
  };

  const validatePasswordFields = () => {
    const validationMessage = passwordValidation(createAccountForm.password);
    if (validationMessage !== "") {
      setFormError((prevValues) => ({
        ...prevValues,
        passwordError: t(validationMessage),
      }));
    } else {
      setFormError((prevValues) => ({ ...prevValues, passwordError: "" }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (
      createAccountForm.firstName &&
      createAccountForm.lastName &&
      createAccountForm.email &&
      createAccountForm.password &&
      createAccountForm.token
    ) {
      const language = navigator.language;
      const country = userCountry;
      dispatch(createAccount({ ...createAccountForm, language, country }));
    }
  };

  const toggleShowPassword = useCallback(() => {
    setShowPassword((current) =>
      showPassword === "text" ? "password" : "text"
    );
    // Setting focus here
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showPassword, passwordRef]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleGoBackPreviousStep = () => {
    dispatch(goToInstructions());
  };

  /* ---- Utils ---- */
  const validateEmail = () => {
    if (createAccountForm.email !== "") {
      if (emailValidation(createAccountForm.email)) {
        setFormError({
          ...formError,
          emailError: t("invalid_email"),
        });
      } else {
        setFormError({ ...formError, emailError: "" });
      }
    }
  };

  const disableSubmit =
    createAccountForm.firstName === "" ||
    createAccountForm.lastName === "" ||
    createAccountForm.email === "" ||
    createAccountForm.password === "" ||
    formError.emailError !== "" ||
    passwordValidation(createAccountForm.password) !== "" ||
    formError.passwordError !== "" ||
    createAccountForm.token.length < VERIFICATION_CODE_LENGTH;

  return (
    <React.Fragment>
      <StackLogo />
      <form
        id="account-creation-form"
        noValidate
        className={classes.loginFormContainer}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className={classes.halfFieldsContainer}>
          <FormControl variant="outlined" className={classes.halfWidth}>
            <InputLabel htmlFor="firstName">{t("first_name")}</InputLabel>
            <OutlinedInput
              name="firstName"
              id="firstName"
              value={createAccountForm.firstName}
              onChange={handleFormChange}
              label={t("first_name")}
              autoComplete="off"
              inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.halfWidth}>
            <InputLabel htmlFor="lastName">{t("last_name")}</InputLabel>
            <OutlinedInput
              name="lastName"
              id="lastName"
              value={createAccountForm.lastName}
              onChange={handleFormChange}
              label={t("last_name")}
              autoComplete="off"
              inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
            />
          </FormControl>
        </div>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="email" error={formError.emailError !== ""}>
            {t("email_address")}
          </InputLabel>
          <OutlinedInput
            name="email"
            id="email"
            value={createAccountForm.email}
            onChange={handleFormChange}
            onBlur={validateEmail}
            label={t("email_address")}
            autoComplete="off"
            error={formError.emailError !== ""}
          />
          {formError.emailError !== "" && (
            <FormHelperText
              id="email-error-text"
              className={classes.helperText}
              error={formError.emailError !== ""}
            >
              {formError.emailError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.textFieldPassword}
          error={formError.passwordError !== ""}
        >
          <InputLabel htmlFor="password">{t("password")}</InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            type={showPassword}
            inputRef={passwordRef}
            label={t("password")}
            value={createAccountForm.password}
            onChange={handleFormChange}
            onBlur={validatePasswordFields}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("alt_text_toggle_password_visibility")}
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword === "text" ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formError.passwordError !== "" && (
            <FormHelperText
              id="email-error-text"
              className={classes.helperText}
              error={formError.passwordError !== ""}
            >
              {formError.passwordError}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="token" error={formError.tokenError !== ""}>
            {t("invitation_code")}
          </InputLabel>
          <OutlinedInput
            name="token"
            id="token"
            value={createAccountForm.token}
            onChange={handleFormChange}
            label={t("invitation_code")}
            autoComplete="off"
            error={formError.tokenError !== ""}
          />
          {formError.tokenError !== "" && (
            <FormHelperText
              id="token-error-text"
              className={classes.helperText}
              error={formError.tokenError !== ""}
            >
              {formError.tokenError}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          type="submit"
          variant={disableSubmit ? "outlined" : "contained"}
          color="primary"
          size="large"
          disabled={disableSubmit}
        >
          {t("create_account")}
        </Button>
      </form>
      <ActionBarBottom>
        <Link
          className={classes.link}
          onClick={handleGoBackPreviousStep}
          underline="none"
        >
          <Button
            className={classes.secondaryButtons}
            startIcon={<ArrowBackIosIcon />}
          >
            {t("action_back")}
          </Button>
        </Link>
      </ActionBarBottom>
    </React.Fragment>
  );
};
