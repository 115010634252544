import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import { IEvent } from "../../services/dashboard.services";
import {
  EventStatus,
  EventTypes,
  iconByEventType,
  iconBySystemIssue,
  ReducerStates,
} from "../../helpers/constants";
import { isHardEvent } from "../dashboard/dashboardSlice";
import clsx from "clsx";
import { NotificationRow } from "./NotificationRow";
import { NotificationCell } from "./NotificationCell";
import { INotificationRow } from "./helpers";
import { Box, TableCell, TableRow } from "@material-ui/core";
import { useAppSelector } from "../app/appHooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unassignedNotification: {
      color: theme.palette.error.contrastText,
    },
  })
);

export function NotificationsTableBody(props: IProps) {
  const { rows, notifications, selected, handleClick, filterOn } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  const notificationsStatus = useAppSelector(
    (state) => state.notificationsState.state
  );

  const notificationsIsun = useAppSelector(
    (state) => state.notificationsState.notifications === undefined
  );

  return (
    <TableBody>
      {notifications.length > 0 &&
        rows.map((row) => {
          const notification = notifications.find(
            (notification) => notification.id === row.eventID
          );

          if (notification === undefined) return null;

          const isAlt = row.eventType === EventTypes.pause_notification;
          const icon = iconByEventType(
            row.eventType !== null ? row.eventType : "",
            isAlt
          );
          const issuesIcon = iconBySystemIssue(notification);

          const responderClass = clsx(
            notification.status === EventStatus.UNASSIGNED &&
              isHardEvent(notification) &&
              classes.unassignedNotification
          );

          return (
            <NotificationRow
              notification={notification}
              key={row.eventID}
              onClick={handleClick}
              selected={selected}
            >
              {row.cells.map((cell) => {
                return (
                  <NotificationCell
                    key={`${row.eventID}-${cell.id}`}
                    fieldType={cell.id}
                    icon={icon}
                    issuesIcon={issuesIcon}
                    className={responderClass}
                  >
                    {cell.value}
                  </NotificationCell>
                );
              })}
            </NotificationRow>
          );
        })}
      {(notifications.length === 0 || notificationsIsun) && (
        <TableRow key="no_events_found">
          <TableCell colSpan={8}>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              whiteSpace="pre-line"
              textAlign="center"
            >
              <h1>
                {(notificationsStatus === ReducerStates.PENDING ||
                  notificationsStatus === ReducerStates.IDLE) &&
                  t("notification_loading_msg")}
                {!notificationsIsun &&
                  notificationsStatus === ReducerStates.SUCCEEDED &&
                  !filterOn &&
                  t("notification_empty_msg")}
                {!notificationsIsun &&
                  notificationsStatus === ReducerStates.SUCCEEDED &&
                  filterOn &&
                  t("no_filtered_notifications")}
              </h1>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

interface IProps {
  rows: INotificationRow[];
  notifications: IEvent[];
  selected: string;
  filterOn: boolean;
  handleClick: (id: string) => void;
}
