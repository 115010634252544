import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/appHooks";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { UnitTypes } from "../../helpers/constants";
import "./index.css";
import { ReportsList } from "./ReportsList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noUnitSelected: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      paddingTop: "12px",
      flexDirection: "column",
    },
    reportsContent: {
      display: "flex",
      flex: "1",
      justifyContent: "center",
    },
  })
);

export function ReportsViewer() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* Selectors */
  const selectedUnit = useAppSelector((state) =>
    state.reportsState.units?.find(
      (unit) => unit.id === state.reportsState.selectedUnit
    )
  );

  const allHelpAtHome = useAppSelector((state) =>
    state.reportsState.units?.every(
      (unit) => unit.unit_type === UnitTypes.HELP_AT_HOME
    )
  );

  return (
    <React.Fragment>
      {selectedUnit !== undefined && (
        <React.Fragment>
          <div className={classes.reportsContent}>
            <ReportsList selectedUnit={selectedUnit} />
          </div>
        </React.Fragment>
      )}
      {selectedUnit === undefined && (
        <div className={classes.noUnitSelected}>
          <div>
            <h2>{t("reports")}</h2>
          </div>
          <div>
            <h3>{allHelpAtHome ? t("select_resident") : t("select_unit")}</h3>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
