import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import { Button, IconButton } from "@material-ui/core";
import { INotificationHeaderCell, TableFields } from "./helpers";
import { FilterOrder } from "../../helpers/constants";
import { CustomTableCell } from "./NotificationCell";
import clsx from "clsx";
import { SortState } from "./NotificationTable";
import { getFilterToHex } from "../../helpers/colorUtils";
import { NotificationIcons } from "../../helpers/iconImports";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerIndicatorCell: {
      padding: "0px",
      width: "12px",
      paddingRight: "30px",

      [theme.breakpoints.up("lg")]: {
        paddingRight: "65px",
      },
    },
    tableHead: {
      height: `51px`,
    },
    headerRow: {
      "& > .MuiTableCell-root": {
        fontFamily: theme.typography.tertiaryFontFamily,
        backgroundColor: theme.palette.primary.light,
      },
    },
    columnHeaderLabel: {
      fontWeight: "bolder",
    },
    columHeaderButton: {
      cursor: "pointer",
    },
    columnContainer: {
      display: "inline-flex",
      alignItems: "center",
    },
    sortIcon: {
      height: "16px",
      width: "16px",
    },
    sortArrowUp: {
      transform: "rotate(180deg)",
    },
    primary: {
      filter: `${
        getFilterToHex(theme.palette.indicator.main) !== null
          ? `brightness(0) saturate(100%) ${getFilterToHex(
              theme.palette.primary.main
            )}`
          : ""
      }`,
    },
    filterIcon: {
      float: "right",
    },
  })
);

const ArrowDownward = (props: { color: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <img
      src={NotificationIcons.SortArrowDown}
      alt={t("alt_text_sorted_desc")}
      draggable={false}
      className={clsx(
        classes.sortIcon,
        props.color === "primary" && classes.primary
      )}
    />
  );
};

const ArrowUpward = (props: { color: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <img
      src={NotificationIcons.SortArrowDown}
      alt={t("alt_text_sorted_asc")}
      draggable={false}
      className={clsx(
        classes.sortIcon,
        classes.sortArrowUp,
        props.color === "primary" && classes.primary
      )}
    />
  );
};

const FilterIcon = (props: { color: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <img
      src={NotificationIcons.Filter}
      alt={t("alt_text_sorted_asc")}
      draggable={false}
      className={clsx(
        classes.sortIcon,
        props.color === "primary" && classes.primary
      )}
    />
  );
};

export const NotificationsTableHeader = (props: IProps) => {
  const {
    columns,
    sort,
    filter,
    activeFilters,
    onOpenFilter,
    handleSort,
    handleResetSort,
    handleToggleFilter,
    handleClearFilters,
  } = props;

  const handleFilterToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleToggleFilter();
  };

  const handleColumnFilter = (
    e: React.MouseEvent<HTMLButtonElement>,
    column: string
  ) => {
    e.stopPropagation();
    onOpenFilter(e, column);
  };

  const handleCreatedSort = (
    e: React.MouseEvent<HTMLButtonElement>,
    column: TableFields
  ) => {
    e.stopPropagation();
    handleSort(column);
  };

  const _handleClearFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleClearFilters();
  };

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  const showClearFilters = Object.values(activeFilters).some(
    (filter) => filter
  );

  return (
    <TableHead classes={{ root: classes.tableHead }}>
      <TableRow className={classes.headerRow}>
        <TableCell
          className={classes.headerIndicatorCell}
          key={"blank"}
        ></TableCell>
        {columns.map((column) => {
          if (sort.length === 0) return null;
          const sortIcon = (color: string) => {
            if (column.id === sort[0].orderBy) {
              if (sort[0].order === FilterOrder.asc) {
                return <ArrowUpward color={color} />;
              }
              return <ArrowDownward color={color} />;
            } else if (column.id === sort[1].orderBy) {
              if (sort[1].order === FilterOrder.asc) {
                return <ArrowUpward color={color} />;
              }
              return <ArrowDownward color={color} />;
            } else {
              return null;
            }
          };
          const cellHeaderClickable =
            column.id !== "created" ||
            (column.id === "created" && sort.length === 1);

          return (
            <CustomTableCell
              key={column.id}
              style={{
                minWidth: column.minWidth,
              }}
              align={column.align}
              onClick={
                column.sortable && cellHeaderClickable
                  ? () => handleSort(column.id)
                  : undefined
              }
            >
              <div
                className={clsx(
                  classes.columnContainer,
                  column.sortable && classes.columHeaderButton
                )}
                onClick={
                  column.sortable && !cellHeaderClickable
                    ? column.id === "created" && sort.length > 1
                      ? () => handleResetSort()
                      : () => handleSort(column.id)
                    : undefined
                }
              >
                <span className={classes.columnHeaderLabel}>
                  {t(column.label).toUpperCase()}
                </span>
                {column.id === sort[0].orderBy && (
                  <IconButton
                    color={
                      column.id === "created" && sort.length > 1
                        ? "default"
                        : "primary"
                    }
                  >
                    {sortIcon("primary")}
                  </IconButton>
                )}
                {filter && column.filterable && column.id !== "created" && (
                  <IconButton
                    className={clsx(classes.filterIcon)}
                    onClick={(e) => handleColumnFilter(e, column.id)}
                  >
                    <FilterIcon
                      color={
                        activeFilters[column.id] !== undefined &&
                        activeFilters[column.id]
                          ? "primary"
                          : "default"
                      }
                    />
                  </IconButton>
                )}
                {sort.length > 1 && column.id === "created" && (
                  <IconButton
                    color={"default"}
                    onClick={(e) => handleCreatedSort(e, column.id)}
                  >
                    {sortIcon("default")}
                  </IconButton>
                )}
              </div>
              {column.id === "created" && (
                <IconButton
                  className={clsx(classes.filterIcon)}
                  onClick={(e) => handleFilterToggle(e)}
                >
                  <FilterIcon color={filter ? "primary" : "default"} />
                </IconButton>
              )}
              {column.id === "created" && filter && showClearFilters && (
                <Button
                  className={clsx(classes.filterIcon)}
                  onClick={_handleClearFilters}
                >
                  {t("clear")}
                </Button>
              )}
            </CustomTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

interface IProps {
  columns: INotificationHeaderCell[];
  activeFilters: { [key: string]: boolean };
  sort: SortState[];
  filter: boolean;
  onOpenFilter: (
    e: React.MouseEvent<HTMLButtonElement>,
    column: string
  ) => void;
  handleSort: (accessor: TableFields) => void;
  handleResetSort: () => void;
  handleClearFilters: () => void;
  handleToggleFilter: () => void;
}
