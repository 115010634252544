import { Grid, IconButton, List, withStyles } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import MuiListItem from "@material-ui/core/ListItem";
import { setSelectedUnit } from "./analyticsSlice";
import { getUnitTitle } from "../dashboard/CommunityUnit";
import Icons from "../../helpers/iconImports";
import { useTranslation } from "react-i18next";
import { getFilterToHex } from "../../helpers/colorUtils";
import clsx from "clsx";
import { getUnitChartData, toggleUnitWatchFlag } from "./analyticsThunks";
import { minToolbarHeight } from "../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    unitListContainer: {
      width: "100%",
      overflowY: "auto",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      paddingTop: "0px",
      borderRight: "1px solid #ccc",
    },
    itemLabelContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
    watchedUnitIcon: {
      height: "23px",
      width: "23px",
    },
    enabledFlag: {
      filter: `${
        getFilterToHex(theme.palette.indicator.main) !== null
          ? `brightness(0) saturate(100%) ${getFilterToHex(
              theme.palette.indicator.main
            )}`
          : ""
      }`,
    },
    iconButtonRoot: {
      display: "flex",
      padding: theme.spacing(0, 1),
      "&:hover": {
        backgroundColor: `unset`,
      },
    },
    secondaryTextContainer: {
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "right",
    },
    loadFont: {
      position: "absolute",
      top: 0,
      right: "50%",
      zIndex: -1,
      height: "1px",
      width: "1px",
      visibility: "hidden",
      fontFamily: theme.typography.tertiaryFontFamily,
    },
    pageTitle: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(3),
      borderBottom: "1px",
      height: "50px",
      backgroundColor: theme.palette.light.main,
      fontSize: "1.1rem",
    },
  })
);

const ListItem = withStyles(({ palette, spacing }) => ({
  root: {
    paddingBottom: spacing(1),
    paddingTop: spacing(1),
    borderRadius: "0px",
    "&$selected": {
      backgroundColor: `${palette.primary.main}4D !important `,
      fontWeight: "bold",
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
  },
  selected: {},
  divider: {},
}))(MuiListItem);

export function UnitList() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  const classes = useStyles(showBanner);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  /* Selectors */
  const units = useAppSelector((state) => state.analyticsState.units);
  const selectedUnit = useAppSelector(
    (state) => state.analyticsState.selectedUnit
  );

  const handleSelectUnit = (unitID: string) => {
    dispatch(setSelectedUnit(unitID));
    dispatch(getUnitChartData(unitID));
  };

  const handleWatchFlagClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    unitID: string,
    isWatched: boolean
  ) => {
    e.stopPropagation();
    const watchFlagData = { unitID, isWatched: !isWatched };
    dispatch(toggleUnitWatchFlag(watchFlagData));
  };

  return (
    <List aria-labelledby="unit-list" className={classes.unitListContainer}>
      <div className={classes.pageTitle}>{t("analytics")}</div>
      {units?.map((unit) => {
        const listItemText = getUnitTitle(unit);
        return (
          <ListItem
            key={unit.id}
            button
            onClick={() => handleSelectUnit(unit.id)}
            selected={unit.id === selectedUnit}
            divider
            disableGutters
          >
            <Grid container className={classes.itemLabelContainer}>
              <Grid item xs={5}>
                {listItemText.unitTitle}
              </Grid>

              <Grid item xs={7}>
                <div className={classes.secondaryTextContainer}>
                  <span>{listItemText.unitSubtitle}</span>
                  <IconButton
                    classes={{
                      root: classes.iconButtonRoot,
                    }}
                    onClick={(e) =>
                      handleWatchFlagClick(e, unit.id, unit.is_watched)
                    }
                    aria-label="toggle-watch-unit"
                  >
                    <img
                      src={
                        unit.is_watched
                          ? Icons.WatchflagEnabled
                          : Icons.WatchflagDisabled
                      }
                      alt={
                        unit.is_watched
                          ? t("alt_text_on_watched_unit")
                          : t("alt_text_off_watched_unit")
                      }
                      draggable={false}
                      className={clsx(
                        classes.watchedUnitIcon,
                        unit.is_watched && classes.enabledFlag
                      )}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
      <span className={classes.loadFont}>|</span>
    </List>
  );
}
