import { request } from "./utils";
import { IFailedRequest } from "./login.services";
import {
  IBathroomEventArray,
  ISleepData,
  ITemperatureData,
  IUnitKitchenData,
} from "../features/dashboard/eventDataTypes";

export const analyticsServices = {
  getUnitBathroomData,
  getUnitSleepData,
  getUnitTemperatureData,
  getUnitKitchenActivityData,
};

/**
 * Loads the bathroom data for an unit
 *
 * @param {string} unitID Unit to retrieve bathroom data
 * @returns {IBathroomEventArray[]} An object array containing bathroom data for the unit
 */

async function getUnitBathroomData(
  unitID: string,
  range?: {
    startDate: string;
    weeks: number;
  }
): Promise<IBathroomEventArray[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/units/${unitID}/bathroom_data${
      range ? `?weeks=${range.weeks}&start_date=${range.startDate}` : ""
    }`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IBathroomEventArray[]);
  }
}

/**
 * Loads sleep data for an unit
 *
 * @param {string} unitID Unit to retrieve bathroom data
 * @returns {ISleepData[]} An object array containing sleep data for the unit
 */

async function getUnitSleepData(
  unitID: string,
  range?: {
    startDate: string;
    weeks: number;
  }
): Promise<ISleepData[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/units/${unitID}/sleep_data${
      range ? `?weeks=${range.weeks}&start_date=${range.startDate}` : ""
    }`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as ISleepData[]);
  }
}

async function getUnitTemperatureData(
  unit_id: string,
  startTime: string,
  endTime: string
): Promise<ITemperatureData> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/units/${unit_id}/temperature_data?start_time=${startTime}&end_time=${endTime}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as ITemperatureData);
  }
}

/**
 * Loads the kitchen activity data for an unit
 *
 * @param {string} unitID Unit to retrieve temperature data
 * @returns {any} An object containing temperature data for the unit
 */

async function getUnitKitchenActivityData(
  unitID: string,
  range?: { startDate: string; weeks: number }
): Promise<Pick<IUnitKitchenData, "fridge_count" | "weekly_count">> {
  const requestOptions: RequestInit = {
    body: null,
  };
  const data = await request(
    `/units/${unitID}/refrigerator_data${
      range ? `?start_date=${range.startDate}&weeks=${range.weeks}` : ""
    }`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as any);
  }
}
