import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { StatusColor } from "../deviceHelpers";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: theme.palette.primary.main,
      fontSize: "0.8rem",
    },
    red: {
      color: theme.palette.error.main,
      fontSize: "0.8rem",
    },
    yellow: {
      color: theme.palette.warning.main,
      fontSize: "0.8rem",
    },
    text: {
      fontSize: "0.8rem",
    },
    label: {
      textTransform: "uppercase",
      fontSize: "0.8rem",
    },
  })
);

export const DeviceExpandedField = (props: IProps) => {
  const { label, value, color } = props;
  /* Hooks */
  const classes = useStyles();
  return (
    <>
      <div className={classes.label}>{label}</div>
      <div className={color ? classes[color] : classes.text}>{value ?? ""}</div>
    </>
  );
};

interface IProps {
  label: string;
  value?: string | null;
  color?: StatusColor;
}
