import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { getUnitTitle } from "../dashboard/CommunityUnit";
import { DateTimeShortFormat } from "../../helpers/datetime";
import { UnitTypes } from "../../helpers/constants";
import { AnalyticsViewerCharts } from "./AnalyticsViewerCharts";
import { AnalyticsViewerMaximized } from "./AnalyticsViewerMaximized";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./index.css";
import { debounce } from "@material-ui/core";
import { setAnalyticsCardHeight } from "./analyticsSlice";
import { AnalyticsHeader } from "./AnalyticsHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noUnitSelected: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      flexDirection: "column",
    },
    lastUpdated: {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.paused.light,
      paddingRight: theme.spacing(1.5),
    },
    lastUpdatedContainer: {
      display: "flex",
      justifyContent: "flex-end",
      margin: theme.spacing(0.5, 0),
    },
    [`@media print`]: {
      lastUpdatedContainer: {
        display: "none",
      },
      lastUpdated: {
        display: "none",
      },
    },
  })
);

export const CHART_DATA_LIMIT_MINIMIZED = 7;
export const CHART_DATA_LIMIT_MAXIMIZED = 14;

export function AnalyticsViewer(props: IProps) {
  const { containerRef } = props;
  const [showMaximized, setShowMaximized] = useState(false);

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const selectedUnit = useAppSelector((state) =>
    state.analyticsState.units?.find(
      (unit) => unit.id === state.analyticsState.selectedUnit
    )
  );

  const displayCharts = useAppSelector(
    (state) => !!state.analyticsState.selectedUnitData
  );

  const chartDataLastUpdated = useAppSelector(
    (state) => state.analyticsState.chartDataLastUpdated
  );

  const chartDataMaximizedLastUpdated = useAppSelector(
    (state) => state.analyticsState.chartDataMaximizedLastUpdated
  );

  const allHelpAtHome = useAppSelector((state) =>
    state.analyticsState.units?.every(
      (unit) => unit.unit_type === UnitTypes.HELP_AT_HOME
    )
  );
  const maximized = useAppSelector(
    (state) => !!state.analyticsState.maximizeChart
  );
  let listItemText = undefined;
  if (selectedUnit)
    listItemText = getUnitTitle(selectedUnit, {
      fullFirstName: true,
    });

  useEffect(() => {
    if (maximized) {
      setShowMaximized(true);
    } else {
      setShowMaximized(false);
    }
  }, [maximized]);

  const viewerHeight = containerRef.current?.clientHeight;
  const [cardHeight, setCardHeight] = useState((viewerHeight ?? 800) * 0.438);

  /* Effects */
  // Recalculate height of cards on resize
  useEffect(() => {
    function handleResize() {
      const viewerHeight = containerRef.current?.clientHeight;
      if (viewerHeight) {
        setCardHeight(viewerHeight * 0.438);
        dispatch(setAnalyticsCardHeight({ viewerHeight, cardHeight }));
      }
    }

    const debouncedHandleResize = debounce(handleResize, 1000);

    handleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {displayCharts !== undefined && (
        <React.Fragment>
          <AnalyticsHeader showMaximized={showMaximized} />
          <SwitchTransition>
            <CSSTransition
              key={showMaximized ? "maximized" : "minimized"}
              timeout={100}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              {showMaximized ? (
                <AnalyticsViewerMaximized
                  lastUpdated={chartDataMaximizedLastUpdated}
                />
              ) : (
                <AnalyticsViewerCharts
                  cardHeight={cardHeight}
                  lastUpdated={chartDataLastUpdated}
                  listItemText={listItemText}
                  isPrintable={false}
                />
              )}
            </CSSTransition>
          </SwitchTransition>
        </React.Fragment>
      )}
      {selectedUnit === undefined && (
        <div className={classes.noUnitSelected}>
          <div>
            <h2>{t("analytics")}</h2>
          </div>
          <h3>{allHelpAtHome ? t("select_resident") : t("select_unit")}</h3>
        </div>
      )}

      {chartDataLastUpdated && (
        <div className={classes.lastUpdatedContainer}>
          <span className={classes.lastUpdated}>{`${t("last_updated")}: ${
            showMaximized
              ? DateTimeShortFormat(chartDataMaximizedLastUpdated ?? "")
              : DateTimeShortFormat(chartDataLastUpdated ?? "")
          }`}</span>
        </div>
      )}
    </React.Fragment>
  );
}

interface IProps {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}
