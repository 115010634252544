import React, { useMemo } from "react";
import { DateTime } from "luxon";
import { isSafari } from "react-device-detect";

import {
  IDailyTemperatures,
  ITemperatureChartData,
  ITemperatureData,
  ITemperaturePoints,
} from "../../dashboard/eventDataTypes";
import LineChart, {
  getRemainderToRound,
  timeDiffInHours,
} from "../../common/LineChart";
import {
  AnalyticsChartTypes,
  formatTemperature,
  sortAlphabetical,
  Temperature,
} from "../../../helpers/constants";
import {
  DateFromIsoString,
  DateTimeFromMillis,
} from "../../../helpers/datetime";
import useCommunityUnitTimezone from "../../dashboard/hooks/useCommunityUnitTimezone";
import { useAppSelector } from "../../app/appHooks";
import { UnitChartHeader } from "./UnitChartHeader";
import { TemperatureSummary } from "../../dashboard/charts/HeatIndexWarningChart";
import AnalyticsChartContainer from "./AnalyticsChartContainer";
import { ChartMode } from "../AnalyticsViewerCharts";

// Obtains the min and max for the unit temperature chart
const getChartMinMaxTime = (
  creationTime: string,
  mode: ChartMode,
  pagination?: { startTime: string; endTime: string },
  communityUnitTimezone?: string
) => {
  let maxDateTime = DateFromIsoString(creationTime, communityUnitTimezone);
  let minDateTime = maxDateTime;
  if (mode === ChartMode.minimized) {
    minDateTime = minDateTime.minus({ hour: 12 });
  } else if (mode === ChartMode.maximized && pagination) {
    maxDateTime = DateFromIsoString(pagination.endTime, communityUnitTimezone);
    minDateTime = DateFromIsoString(
      pagination.startTime,
      communityUnitTimezone
    );

    // If the range limit is less than 2 hours, the x axis shows intervals of 15 min
    // if the range is less than 30 min, the x axis shows intervals of 5 min
    // to show it this way, the min limit has to start in an even value multiple of 15 or 5
    const rangeHourDifference = timeDiffInHours(maxDateTime, minDateTime);
    const remainderVal = getRemainderToRound(rangeHourDifference);
    if (remainderVal) {
      minDateTime = minDateTime.minus({
        minute: minDateTime.minute % remainderVal,
      });
    }
  } else {
    minDateTime = minDateTime.minus({ hours: 24 });
  }
  return { minDateTime, maxDateTime };
};

// Get min and max values for line chart
const getMinMaxTempData = (
  chartData: ITemperatureChartData[],
  temperature: Temperature
) => {
  let min: number = chartData[0]?.temperature_data[0]?.heat_index || 0;
  let max: number = chartData[0]?.temperature_data[0]?.heat_index || 0;
  chartData.forEach((zoneTempData) => {
    zoneTempData.temperature_data.forEach((tempData) => {
      let value = tempData.heat_index || 0;
      min = value < min ? value : min;
      max = value > max ? value : max;
    });
  });

  const yMinBuffer = temperature === Temperature.CELSIUS ? 5 : 10;
  min = Math.ceil((Number(formatTemperature(min, false)) - yMinBuffer) / 5) * 5;
  max = Math.ceil(Number(formatTemperature(max, false)) / 5) * 5;
  return [min, max];
};

// Get an array of arrays that contains objects with cartesian points used to
// draw the temperature chart
type TemperatureDataPoint = { x: DateTime; y: number | null };
type TemperatureChartDataset = TemperatureDataPoint[][];
const getDataSets = (
  chartData: ITemperatureChartData[],
  communityUnitTimezone?: string
): TemperatureChartDataset => {
  const dataSets: TemperatureChartDataset = [];

  chartData.forEach((zoneTempData) => {
    // Copy and dereference array
    const tempData = (JSON.parse(
      JSON.stringify(zoneTempData.temperature_data)
    ) as ITemperaturePoints[]).sort((a, b) => {
      return Number(a.timestamp) - Number(b.timestamp);
    });

    // Build point using Luxon DateTime object and formated temperature
    const data = tempData.flatMap((temperatureData) => {
      return {
        x: DateTimeFromMillis(
          temperatureData.timestamp || 0,
          communityUnitTimezone
        ),
        y: temperatureData.heat_index
          ? Number(formatTemperature(temperatureData.heat_index, false))
          : null,
      };
    });
    dataSets.push(data);
  });
  return dataSets;
};

export function UnitTemperatureChart(props: IProps) {
  const { chartData, mode, lastUpdatedTime, pagination, isPrintable } = props;

  /* Selectors */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const unitID = useAppSelector((state) => state.analyticsState.selectedUnit);
  const currentTime = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.currentTime
  );
  const maxChartLimit = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.end
  );
  const minChartLimit = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.start
  );
  const configTemperature = useAppSelector(
    (state) => state.appState.config.temperature
  );

  const isCurrent =
    currentTime !== undefined && currentTime === pagination?.endTime;
  const communityUnitTimezone = useCommunityUnitTimezone(communityID, unitID);

  const data = JSON.parse(JSON.stringify(chartData)) as ITemperatureData;
  const lastUpdated = lastUpdatedTime ?? "";

  // Current temperatures table data
  const currentTempData = (JSON.parse(
    JSON.stringify(data.daily_temps)
  ) as IDailyTemperatures[]).sort((a, b) => sortAlphabetical(a.name, b.name));

  const dataThreshold = data.typical_chart.threshold;

  // Obtain min&max temperature values for the line chart
  const minThreshold = data.typical_chart.typ_low_2 || 0;
  const maxThreshold = data.typical_chart.typ_high_2 || 0;

  // Line graph data
  const lineChartData = JSON.parse(
    JSON.stringify(data.chart_data)
  ) as ITemperatureChartData[];

  // Add missing zones to chart data using daily temperatures zones
  currentTempData.forEach((currTemp) => {
    const indexLineChart = lineChartData.findIndex(
      (lineCh) => lineCh.name === currTemp.name
    );
    if (indexLineChart === -1)
      lineChartData.push({ name: currTemp.name, temperature_data: [] });
  });

  lineChartData.sort((a, b) => sortAlphabetical(a.name, b.name));

  const [minTemp, maxTemp] = getMinMaxTempData(
    lineChartData,
    configTemperature
  );
  const { minDateTime, maxDateTime } = getChartMinMaxTime(
    lastUpdated,
    mode,
    pagination,
    communityUnitTimezone
  );

  const datasets = useMemo(
    () => getDataSets(lineChartData, communityUnitTimezone),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData, lastUpdated]
  );

  const filterDataSet = (
    datasets: TemperatureChartDataset,
    paginationState?: { startTime: string; endTime: string }
  ) => {
    if (paginationState) {
      const minDateTime = DateFromIsoString(
        paginationState.startTime,
        communityUnitTimezone
      ).minus({ hours: 12 });
      const maxDateTime = DateFromIsoString(
        paginationState.endTime,
        communityUnitTimezone
      ).plus({ hours: 12 });

      return datasets.map((line) => {
        const newDataset = line.filter(
          (point) => point.x > minDateTime && point.x < maxDateTime
        );
        return newDataset;
      });
    } else {
      return datasets;
    }
  };
  const filteredData = filterDataSet(datasets, pagination);

  const zone = currentTempData.map((item) => item.name);
  return (
    <React.Fragment>
      <UnitChartHeader
        type={AnalyticsChartTypes.temperature}
        mode={mode}
        data={chartData}
        isPrintable={isPrintable}
        lastUpdatedTime={lastUpdatedTime}
        isCurrent={mode === ChartMode.maximized ? isCurrent : undefined}
      />
      {mode === ChartMode.minimized && (
        <AnalyticsChartContainer
          height={isPrintable ? (isSafari ? "140px" : "180px") : "90px"}
          proportion={0.117}
          disableHeightOnMax={true}
          isPrintable={isPrintable}
        >
          <TemperatureSummary
            temperatureData={currentTempData}
            threshold={dataThreshold}
            minThreshold={minThreshold}
            maxTreshold={maxThreshold}
          />
        </AnalyticsChartContainer>
      )}
      <AnalyticsChartContainer
        height={isPrintable ? (isSafari ? "240px" : "340px") : "160px"}
        proportion={0.23}
        isPrintable={isPrintable}
      >
        <LineChart
          min={minTemp}
          max={maxTemp}
          labels={zone}
          values={filteredData}
          maxTime={maxDateTime}
          minTime={minDateTime}
          animation={false}
          communityUnitTimezone={communityUnitTimezone}
          maxChartLimit={maxChartLimit}
          minChartLimit={minChartLimit}
          zoomEnabled={mode === ChartMode.maximized}
          panEnabled={mode === ChartMode.maximized}
          legendEnabled={mode === ChartMode.maximized}
        />
      </AnalyticsChartContainer>
    </React.Fragment>
  );
}

interface IProps {
  chartData: ITemperatureData;
  mode: ChartMode;
  lastUpdatedTime?: string;
  pagination?: { startTime: string; endTime: string };
  isPrintable: boolean;
}
