import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  styled,
  withStyles,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button, Paper } from "@material-ui/core";
import { useAppSelector } from "../app/appHooks";
import { useDispatch } from "react-redux";
import {
  sendHandOff,
  updateHandoffSettings,
  toggleScheduleEnabled,
} from "../app/asyncThunkActions";
import {
  DateTimeNowHourMinute,
  convertTimeHourMinute,
  daysForLocale,
  loadTime,
  timeConfigurations,
} from "../../helpers/datetime";
import {
  brandPrimaryColor,
  ISO_TIME_SAVE_FORMAT,
} from "../../helpers/constants";
import { setHandoffSettings, setScheduleEnabled } from "./settingsSlice";
import {
  FormControl,
  OutlinedInput,
  Switch,
  SwitchProps,
  Typography,
  ListItem,
  Checkbox,
  List,
  Grid,
} from "@material-ui/core";
import { ExpandMoreOutlined } from "@material-ui/icons";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import LuxonUtils from "@date-io/luxon";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    handOffContainer: {
      padding: theme.spacing(6, 10),
      width: "550px",
      overflow: "auto",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    sectionTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    sectionDescription: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "left",
      fontWeight: "bold",
    },
    sections: {
      marginTop: 0,
      textAlign: "left",
    },
    scheduleSection: {
      marginTop: theme.spacing(4),
      testAlign: "left",
      marginRight: theme.spacing(1),
    },
    optionalSection: {
      marginTop: theme.spacing(4),
      testAlign: "left",
      color: theme.palette.text.secondary,
      fontSize: "small",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    optionalMessage: {
      border: "1px solid #ccc",
      borderRadius: "5px",
      fontWeight: "bold",
      marginBottom: 20,
      padding: "16px",
    },
    inputBox: {
      marginTop: "10px",
    },
    textFieldBorder: {
      border: "1px solid #ccc",
      borderRadius: "5px",
      fontWeight: "bold",
      marginBottom: 20,
      paddingTop: "8px",
      paddingLeft: "14px",
      paddingRight: "14px",
      paddingBottom: "14",
    },
    gridContainer: {
      alignItems: "center",
    },
    scheduleSwitch: {
      float: "right",
    },
    descriptionText: {
      fontWeight: "bold",
    },
    heading: {
      fontWeight: "bold",
    },
    secondaryHeading: {
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    accordionSummary: {
      height: "100%",
    },
    innerMenuItem: {
      paddingLeft: "0px",
      lineHeight: 0,
      display: "flex",
      justifyContent: "left",
      pointerEvents: "none",
      height: "45px",
    },
    checkboxDetails: {
      color: brandPrimaryColor,
      "&$checked": {
        color: brandPrimaryColor,
      },
    },
    textField: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(1),
      justifyContent: "right",
      pointerEvents: "auto",
    },
    scheduleButton: {
      float: "right",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    saveButton: {
      float: "right",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      color: brandPrimaryColor,
    },
    checkBox: {
      pointerEvents: "auto",
      transform: "scale(1.3)",
    },
  })
);

const Accordion = withStyles({
  root: {
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
      marginLeft: 2,
      marginRight: 2,
      height: "fit-content",
      marginBottom: 20,
    },
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontWeight: "bold",
    marginBottom: 20,
    marginLeft: 2,
    marginRight: 2,
    boxShadow: "none",
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 60,
    "&$expanded": {
      minHeight: 60,
    },
    alignItems: "baseline",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionSummaryMessage = withStyles({
  root: {
    minHeight: 100,
    "&$expanded": {
      minHeight: 60,
    },
    alignItems: "baseline",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    borderBottom: "1px solid #ccc",
  },
}))(MuiAccordionDetails);

const ScheduleSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: brandPrimaryColor,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface HandoffScheduleState {
  is_enabled: boolean;
  0: {
    is_enabled: boolean;
    start_time: DateTime | null;
  };
  1: {
    is_enabled: boolean;
    start_time: DateTime | null;
  };
  2: {
    is_enabled: boolean;
    start_time: DateTime | null;
  };
  3: {
    is_enabled: boolean;
    start_time: DateTime | null;
  };
  4: {
    is_enabled: boolean;
    start_time: DateTime | null;
  };
  5: {
    is_enabled: boolean;
    start_time: DateTime | null;
  };
  6: {
    is_enabled: boolean;
    start_time: DateTime | null;
  };
  [key: number]: { is_enabled: boolean; start_time: DateTime | null };
}

interface scheduleDay {
  day: number;
  start_time: DateTime | null;
}

export function HandOff() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* Selectors */
  const community = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );

  const settings = useAppSelector(
    (state) => state.settingsState.handoffSettings
  );

  const handoffConfiguration = useAppSelector(
    (state) => state.settingsState.handoffSettings?.configuration
  );

  const optionalScheduledMessage = useAppSelector(
    (state) => state.settingsState.handoffSettings?.configuration.message
  );

  const isEnabled = useAppSelector(
    (state) => state.settingsState.handoffSettings?.is_enabled
  );

  const is24HourTime = useAppSelector(
    (state) => state.headerState.user?.is_twenty_four_time === true
  );

  const userLanguage = useAppSelector(
    (state) => state.headerState.user?.language
  );

  const { timeFormat } = timeConfigurations();

  /* ---- Effects ---- */
  useEffect(() => {
    if (settings !== undefined) {
      loadHandoffSchedule(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const [expand, setExpand] = useState(false);

  const [messageExpand, setMessageExpand] = useState(false);

  const [message, setMessage] = useState("");

  const [scheduledMessage, setScheduledMessage] = useState("");

  const [tempScheduledMessage, setTempScheduledMessage] = useState("");

  const [scheduleTitle, setScheduleTitle] = useState("");

  const [handoffSchedule, setHandoffSchedule] = useState<HandoffScheduleState>({
    is_enabled: false,
    0: {
      is_enabled: false,
      start_time: DateTime.now(),
    },
    1: {
      is_enabled: false,
      start_time: DateTime.now(),
    },
    2: {
      is_enabled: false,
      start_time: DateTime.now(),
    },
    3: {
      is_enabled: false,
      start_time: DateTime.now(),
    },
    4: {
      is_enabled: false,
      start_time: DateTime.now(),
    },
    5: {
      is_enabled: false,
      start_time: DateTime.now(),
    },
    6: {
      is_enabled: false,
      start_time: DateTime.now(),
    },
  });

  const days = daysForLocale(
    userLanguage != null ? userLanguage : "en-US",
    false
  );

  const days_abbrev = daysForLocale(
    userLanguage != null ? userLanguage : "en-US",
    true
  );

  /* Methods */
  const loadConfiguration = () => {
    if (!handoffConfiguration) {
      let defaultConfiguration = {
        message: "",
        0: {
          is_enabled: false,
          start_time: DateTime.now(),
        },
        1: {
          is_enabled: false,
          start_time: DateTime.now(),
        },
        2: {
          is_enabled: false,
          start_time: DateTime.now(),
        },
        3: {
          is_enabled: false,
          start_time: DateTime.now(),
        },
        4: {
          is_enabled: false,
          start_time: DateTime.now(),
        },
        5: {
          is_enabled: false,
          start_time: DateTime.now(),
        },
        6: {
          is_enabled: false,
          start_time: DateTime.now(),
        },
      };
      return defaultConfiguration;
    }
    let configuration = {
      message: optionalScheduledMessage,
      0: {
        is_enabled:
          handoffConfiguration[0] !== null &&
          handoffConfiguration[0]?.is_enabled !== null
            ? handoffConfiguration[0]?.is_enabled
            : false,
        start_time:
          handoffConfiguration[0] !== null &&
          handoffConfiguration[0]?.start_time !== null
            ? DateTimeNowHourMinute(handoffConfiguration[0]?.start_time)
            : DateTime.now(),
      },
      1: {
        is_enabled:
          handoffConfiguration[1] !== null &&
          handoffConfiguration[1]?.is_enabled !== null
            ? handoffConfiguration[1]?.is_enabled
            : false,
        start_time:
          handoffConfiguration[1] !== null &&
          handoffConfiguration[1]?.start_time !== null
            ? DateTimeNowHourMinute(handoffConfiguration[1]?.start_time)
            : DateTime.now(),
      },
      2: {
        is_enabled:
          handoffConfiguration[2] !== null &&
          handoffConfiguration[2]?.is_enabled !== null
            ? handoffConfiguration[2]?.is_enabled
            : false,
        start_time:
          handoffConfiguration[2] !== null &&
          handoffConfiguration[2]?.start_time !== null
            ? DateTimeNowHourMinute(handoffConfiguration[2]?.start_time)
            : DateTime.now(),
      },
      3: {
        is_enabled:
          handoffConfiguration[3] !== null &&
          handoffConfiguration[3]?.is_enabled !== null
            ? handoffConfiguration[3]?.is_enabled
            : false,
        start_time:
          handoffConfiguration[3] !== null &&
          handoffConfiguration[3]?.start_time !== null
            ? DateTimeNowHourMinute(handoffConfiguration[3]?.start_time)
            : DateTime.now(),
      },
      4: {
        is_enabled:
          handoffConfiguration[4] !== null &&
          handoffConfiguration[4]?.is_enabled !== null
            ? handoffConfiguration[4]?.is_enabled
            : false,
        start_time:
          handoffConfiguration[4] !== null &&
          handoffConfiguration[4]?.start_time !== null
            ? DateTimeNowHourMinute(handoffConfiguration[4]?.start_time)
            : DateTime.now(),
      },
      5: {
        is_enabled:
          handoffConfiguration[5] !== null &&
          handoffConfiguration[5]?.is_enabled !== null
            ? handoffConfiguration[5]?.is_enabled
            : false,
        start_time:
          handoffConfiguration[5] !== null &&
          handoffConfiguration[5]?.start_time !== null
            ? DateTimeNowHourMinute(handoffConfiguration[5]?.start_time)
            : DateTime.now(),
      },
      6: {
        is_enabled:
          handoffConfiguration[6] !== null &&
          handoffConfiguration[6]?.is_enabled !== null
            ? handoffConfiguration[6]?.is_enabled
            : false,
        start_time:
          handoffConfiguration[6] !== null &&
          handoffConfiguration[6]?.start_time !== null
            ? DateTimeNowHourMinute(handoffConfiguration[6]?.start_time)
            : DateTime.now(),
      },
    };
    return configuration;
  };
  const toggleAccordion = () => {
    loadHandoffSchedule(false);
    setExpand((prev) => !prev);
  };

  const toggleMessage = () => {
    setMessageExpand((prev) => !prev);
    setTempScheduledMessage(scheduledMessage);
  };

  const handleMessageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setMessage(value);
  };

  const saveScheduledMessage = () => {
    if (handoffConfiguration) {
      const handoffSettings = Object.assign({}, handoffConfiguration, {
        message: tempScheduledMessage,
      });

      const messageData = {
        community_id: community?.id,
        is_enabled: handoffSchedule.is_enabled,
        configuration: handoffSettings,
      };

      dispatch(setHandoffSettings(messageData));
      dispatch(updateHandoffSettings(messageData));
      setScheduledMessage(tempScheduledMessage);
    }

    setMessageExpand(false);
  };

  const handleTempScheduledMessageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setTempScheduledMessage(value);
  };

  const handleScheduleChange = (key: number) => (date: DateTime | null) => {
    setHandoffSchedule((prevState) => ({
      ...prevState,
      [key]: {
        is_enabled: prevState[key].is_enabled,
        start_time: date,
      },
    }));
  };

  const handleCheckboxChange = (key: number) => () => {
    setHandoffSchedule((prevState) => ({
      ...prevState,
      [key]: {
        is_enabled: !prevState[key].is_enabled,
        start_time: prevState[key].start_time,
      },
    }));
  };

  const handleIsEnabledToggle = () => {
    let isEnabled = handoffSchedule.is_enabled;
    setHandoffSchedule((prevState) => ({
      ...prevState,
      is_enabled: !isEnabled,
    }));
    const messageData = {
      community_id: community?.id,
      is_enabled: !isEnabled,
    };
    dispatch(toggleScheduleEnabled(messageData));
    dispatch(setScheduleEnabled(messageData));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const messageData = {
      community_id: community?.id,
      message: message,
    };

    dispatch(sendHandOff(messageData));
  };

  const handleSave = (): void => {
    const config = {
      message: scheduledMessage,
      0: {
        is_enabled: handoffSchedule[0].is_enabled,
        start_time: convertTimeHourMinute(
          handoffSchedule[0].start_time,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        ),
      },
      1: {
        is_enabled: handoffSchedule[1].is_enabled,
        start_time: convertTimeHourMinute(
          handoffSchedule[1].start_time,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        ),
      },
      2: {
        is_enabled: handoffSchedule[2].is_enabled,
        start_time: convertTimeHourMinute(
          handoffSchedule[2].start_time,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        ),
      },
      3: {
        is_enabled: handoffSchedule[3].is_enabled,
        start_time: convertTimeHourMinute(
          handoffSchedule[3].start_time,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        ),
      },
      4: {
        is_enabled: handoffSchedule[4].is_enabled,
        start_time: convertTimeHourMinute(
          handoffSchedule[4].start_time,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        ),
      },
      5: {
        is_enabled: handoffSchedule[5].is_enabled,
        start_time: convertTimeHourMinute(
          handoffSchedule[5].start_time,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        ),
      },
      6: {
        is_enabled: handoffSchedule[6].is_enabled,
        start_time: convertTimeHourMinute(
          handoffSchedule[6].start_time,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        ),
      },
    };
    const messageData = {
      community_id: community?.id,
      is_enabled: handoffSchedule.is_enabled,
      configuration: config,
    };

    dispatch(setHandoffSettings(messageData));
    dispatch(updateHandoffSettings(messageData));
    parseSchedule(handoffSchedule);
    setExpand(false);
  };

  const handleScheduleCancel = () => {
    loadHandoffSchedule(false);
    setExpand(false);
  };

  const parseSchedule = (schedule: HandoffScheduleState) => {
    let scheduleList: scheduleDay[][] = [];
    let groupedDays: scheduleDay[] = [];
    let prevDayIndex = -1;
    // Loops through the schedule and groups days together if they are
    // consecutive and have the same start time
    for (let value = 0; value <= 6; value++) {
      let currDay = schedule[value];
      // If the current day is disabled or has missing properties,
      // skip and push the days that have been grouped so far
      if (!currDay.is_enabled || currDay.start_time == null) {
        // Only push if there exist days in the array
        if (groupedDays.length > 0) {
          scheduleList.push(groupedDays);
          groupedDays = [];
        }
        prevDayIndex = -1;
        continue;
      }
      // If there is a previous day, check to see if the start times match.
      // If the start times do not match, push whatever days that have already
      // been grouped and start a new list of grouped days
      if (prevDayIndex !== -1) {
        let prevDay = schedule[prevDayIndex];
        if (
          prevDay.start_time &&
          (prevDay.start_time.hour !== currDay.start_time.hour ||
            prevDay.start_time.minute !== currDay.start_time.minute)
        ) {
          if (groupedDays.length > 0) {
            scheduleList.push(groupedDays);
          }
          groupedDays = [];
        }
      }
      // The current day will always be pushed to the grouped days but the
      // previous check will determine if the array is empty or not
      groupedDays.push({
        day: value,
        start_time: currDay.start_time,
      });
      prevDayIndex = value;
    }

    // Pushes the final group onto the schedule list
    if (groupedDays.length > 0) {
      scheduleList.push(groupedDays);
    }

    if (scheduleList.length === 0) {
      setScheduleTitle("");
      return;
    }

    // Parses the list of grouped days and stores it as the schedule title
    setScheduleTitle(aggregateSchedule(scheduleList));
  };

  const aggregateSchedule = (scheduleList: scheduleDay[][]) => {
    let aggregatedSchedule: string[] = [];
    for (let i = 0; i < scheduleList.length; i++) {
      let timeString = "";
      let convertedTime = convertTimeHourMinute(
        scheduleList[i][0].start_time,
        timeFormat
      );
      // If only a single day is in this group, just parse normally
      if (scheduleList[i].length === 1) {
        timeString = `${
          days_abbrev[scheduleList[i][0].day].split(".")[0]
        }: ${convertedTime}`;
      } else {
        // If multiple days, parse the first and last day in the list.
        // The start time can be grabbed from the first day because
        // they all have the same start time
        timeString = `${days_abbrev[scheduleList[i][0].day]}-${
          days_abbrev[scheduleList[i][scheduleList[i].length - 1].day].split(
            "."
          )[0]
        }: ${convertedTime}`;
      }
      aggregatedSchedule.push(timeString);
    }

    return aggregatedSchedule.join(", ");
  };

  const loadHandoffSchedule = (toParse: boolean) => {
    if (isEnabled != null && handoffConfiguration) {
      const configuration = Object.assign(
        {},
        { is_enabled: isEnabled },
        loadConfiguration()
      );
      setHandoffSchedule(configuration);
      if (configuration.message) {
        setScheduledMessage(configuration.message);
        setTempScheduledMessage(configuration.message);
      }
      if (toParse) {
        parseSchedule(configuration);
      }
    }
  };

  return (
    <Paper className={classes.handOffContainer} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("hand_off")}</h2>
      <Typography className={classes.sectionDescription}>
        {t("hand_off_summary")}
      </Typography>
      <h3 className={classes.sections}>{t("send_now").toUpperCase()}</h3>
      <form
        id="login-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <fieldset className={classes.optionalMessage}>
          <FormControl fullWidth>
            <Typography className={classes.descriptionText}>
              {t("optional_message")}
            </Typography>
            <OutlinedInput
              name="optional_message"
              id="optional_message"
              value={message}
              onChange={handleMessageChange}
              placeholder={t("hand_off_hint_web")}
              multiline={true}
              notched={false}
              autoComplete="off"
              key="optional_message"
              rows="3"
              className={classes.inputBox}
            />
          </FormControl>
        </fieldset>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("send_now")}
          </Button>
        </div>
      </form>
      <Grid container className={classes.gridContainer}>
        <Grid>
          <h3 className={classes.scheduleSection}>{`${t(
            "schedule"
          ).toUpperCase()}`}</h3>
        </Grid>
        <Grid>
          <h3 className={classes.optionalSection}>{` ${t("optional")}`}</h3>
        </Grid>
      </Grid>
      <form
        id="login-form"
        noValidate
        autoComplete="off"
        className={classes.formContainer}
      >
        <fieldset className={classes.textFieldBorder}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={8}>
              <Typography className={classes.descriptionText}>
                {t("enable_scheduled_hand_off")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {
                <ScheduleSwitch
                  className={classes.scheduleSwitch}
                  checked={handoffSchedule.is_enabled}
                  onClick={handleIsEnabledToggle}
                />
              }
            </Grid>
          </Grid>
        </fieldset>
        <Accordion expanded={expand}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            onClick={toggleAccordion}
          >
            <Typography className={classes.descriptionText}>
              {scheduleTitle === ""
                ? t("schedule").charAt(0).toUpperCase() + t("schedule").slice(1)
                : scheduleTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                <ListItem
                  style={{ backgroundColor: "transparent" }}
                  className={classes.innerMenuItem}
                  button
                  key={value}
                >
                  <Checkbox
                    checked={handoffSchedule[value].is_enabled}
                    name={days[value]}
                    style={{
                      color: brandPrimaryColor,
                    }}
                    className={classes.checkBox}
                    size="medium"
                    onClick={handleCheckboxChange(value)}
                  />
                  <Grid container className={classes.gridContainer}>
                    <Grid item xs={7}>
                      <Typography>{days[value]}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <TimePicker
                          ampm={!is24HourTime}
                          value={loadTime(handoffSchedule[value].start_time)}
                          format={timeFormat}
                          className={classes.textField}
                          onClick={(e) => e.stopPropagation()}
                          onChange={handleScheduleChange(value)}
                          minutesStep={5}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
          <Button
            className={classes.saveButton}
            size="small"
            onClick={() => {
              handleSave();
            }}
          >
            {t("save")}
          </Button>
          <Button
            className={classes.scheduleButton}
            size="small"
            onClick={() => {
              handleScheduleCancel();
            }}
          >
            {t("cancel")}
          </Button>
        </Accordion>

        <Accordion expanded={messageExpand} style={{ marginBottom: "50px" }}>
          <AccordionSummaryMessage
            expandIcon={<ExpandMoreOutlined />}
            onClick={toggleMessage}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.descriptionText}>
                  {t("optional_message")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: messageExpand ? "none" : "flex" }}
              >
                <Typography className={classes.secondaryHeading}>
                  {scheduledMessage === ""
                    ? t("hand_off_schedule_hint_web")
                    : scheduledMessage}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummaryMessage>
          <AccordionDetails>
            <OutlinedInput
              name="optional_message_scheduled"
              id="optional_message_scheduled"
              value={tempScheduledMessage}
              onChange={handleTempScheduledMessageChange}
              placeholder={t("hand_off_schedule_hint_web")}
              multiline={true}
              notched={false}
              autoComplete="off"
              key="optional_message_scheduled"
              rows="3"
              className={classes.secondaryHeading}
            />
          </AccordionDetails>
          <Button
            className={classes.saveButton}
            size="small"
            onClick={() => {
              saveScheduledMessage();
            }}
          >
            {t("save")}
          </Button>
          <Button
            className={classes.scheduleButton}
            size="small"
            onClick={() => {
              setTempScheduledMessage(scheduledMessage);
              setMessageExpand(false);
            }}
          >
            {t("cancel")}
          </Button>
        </Accordion>
      </form>
    </Paper>
  );
}
