import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  EventStatus,
  RoleTypes,
  sortAlphabetical,
  TabTypes,
} from "../../helpers/constants";
import clsx from "clsx";
import { useAppSelector } from "../app/appHooks";
import { IconButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { minToolbarHeight } from "../app/App";
import { NotificationCard } from "./NotificationCard";
import { NotificationIcons } from "../../helpers/iconImports";
import { AllNotificationsFilterPanel } from "./AllNotificationsFilterPanel";
import { useDispatch } from "react-redux";
import {
  isActiveUser,
  setCommunityFilter,
  setIsCommunityFiltered,
  setIsStatusFiltered,
  setIsUserFiltered,
  setStatusFilter,
  setUserFilter,
} from "./dashboardSlice";
import { StorageKeys } from "../../services/constants";
import { IUserDetails } from "../../services/header.services";
import { getFilterToHex } from "../../helpers/colorUtils";
import { loadAllUnitUserData } from "../header/headerThunks";

const useStyles = makeStyles<
  Theme,
  { showBanner: boolean; minimizedTray: boolean }
>((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      position: "relative",
      zIndex: 2,
      alignContent: "flex-start",
      height: ({ showBanner }) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      padding: theme.spacing(2, 2),
    },
    notificationContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      position: "relative",
      zIndex: 2,
      overflowY: "auto",
      maxHeight: "calc(100vh - 80px)",
      paddingBottom: ({ minimizedTray }) =>
        minimizedTray ? theme.spacing(15) : theme.spacing(10),
    },
    sectionHeader: {
      width: "100%",
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      marginTop: theme.spacing(1),
    },
    communitiesContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    timeframeSelect: {
      minWidth: 120,
    },
    selectOption: {
      padding: theme.spacing(1),
    },
    title: {
      fontSize: "1.5rem",
    },
    filterIcon: {
      position: "absolute",
      right: theme.spacing(6),
    },
    sortIcon: {
      height: "20px",
      width: "20px",
    },
    panel: {
      position: "fixed",
      top: ({ showBanner }) => `${minToolbarHeight(showBanner, theme)}`,
      right: 0,
      width: "300px", // Adjust width as needed
      backgroundColor: "#fff",
      boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.3)",
      zIndex: 1000,
      transition: "transform 0.3s ease-in-out",
      transform: "translateX(0)", // Slide in from the right
      height: ({ showBanner }) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    panelHidden: {
      transform: "translateX(100%)", // Hidden off-screen
    },
    closeButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    primary: {
      filter: `${
        getFilterToHex(theme.palette.indicator.main) !== null
          ? `brightness(0) saturate(100%) ${getFilterToHex(
              theme.palette.primary.main
            )}`
          : ""
      }`,
    },
  })
);

const FilterIcon = (props: {
  showBanner: boolean;
  minimizedTray: boolean;
  color: string;
}) => {
  const classes = useStyles({
    showBanner: props.showBanner,
    minimizedTray: props.minimizedTray,
  });
  const { t } = useTranslation();
  return (
    <img
      src={NotificationIcons.Filter}
      alt={t("alt_text_sorted_asc")}
      draggable={false}
      className={clsx(
        classes.sortIcon,
        props.color === "primary" && classes.primary
      )}
    />
  );
};

export default function AllNotifications(props: IProps) {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);
  const [showPanel, setShowPanel] = useState(false);
  const { addTab, minimizedTray } = props;

  /* ---- Hooks ---- */
  const classes = useStyles({ showBanner, minimizedTray });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const communities = useAppSelector(
    (states) => states.headerState.communities
  );
  const communityUserData = useAppSelector(
    (states) => states.headerState.communityUserData
  );

  const isCommunityFiltered = useAppSelector(
    (state) => state.dashboardState.isCommunityFiltered
  );
  const isStatusFiltered = useAppSelector(
    (state) => state.dashboardState.isStatusFiltered
  );
  const isUserFiltered = useAppSelector(
    (state) => state.dashboardState.isUserFiltered
  );

  const userList = useMemo(() => {
    if (!communityUserData) return [] as IUserDetails[];
    const userList: IUserDetails[] = [];
    const userIdSet = new Set();

    if (communityUserData) {
      Object.entries(communityUserData).forEach(([_, users]) => {
        if (Array.isArray(users)) {
          users.forEach((user) => {
            if (!userIdSet.has(user.id)) {
              if (
                user.role &&
                (user.role.name === RoleTypes.admin ||
                  user.role.name === RoleTypes.caregiver) &&
                isActiveUser(user)
              ) {
                userIdSet.add(user.id);
                userList.push(user);
              }
            }
          });
        }
      });
    }

    return userList.sort((a, b) => {
      const firstNameComparison = sortAlphabetical(a.first_name, b.first_name);
      if (firstNameComparison === 0) {
        return sortAlphabetical(a.last_name, b.last_name);
      }
      return firstNameComparison;
    });
  }, [communityUserData]);

  const defaultStatusFilter = useMemo(
    () => [EventStatus.UNASSIGNED, EventStatus.ASSIGNED],
    []
  );

  useEffect(() => {
    const loadFilterFromLocalStorage = (
      storageKey: string,
      defaultValue: string[],
      setFilterAction: (filter: string[]) => void,
      isFilteredKey: string,
      setIsFilteredAction: (isFiltered: boolean) => void
    ) => {
      const localFilter = localStorage.getItem(storageKey);
      if (localFilter !== null) {
        const parsedFilter = localFilter.split(",");
        dispatch(setFilterAction(parsedFilter));
        const isFiltered = localStorage.getItem(isFilteredKey);
        if (isFiltered !== null) {
          dispatch(setIsFilteredAction(isFiltered === "true"));
        }
      } else {
        dispatch(setFilterAction(defaultValue));
        dispatch(setIsFilteredAction(false));
      }
    };

    if (communities) {
      loadFilterFromLocalStorage(
        StorageKeys.COMMUNITY_FILTER,
        communities.map((community) => community.id),
        setCommunityFilter,
        StorageKeys.IS_COMMUNITY_FILTERED,
        setIsCommunityFiltered
      );
    }

    loadFilterFromLocalStorage(
      StorageKeys.STATUS_FILTER,
      defaultStatusFilter,
      setStatusFilter,
      StorageKeys.IS_STATUS_FILTERED,
      setIsStatusFiltered
    );

    if (userList.length > 0) {
      loadFilterFromLocalStorage(
        StorageKeys.USER_FILTER,
        userList.map((user) => user.id),
        setUserFilter,
        StorageKeys.IS_USER_FILTERED,
        setIsUserFiltered
      );
    }
  }, [communities, defaultStatusFilter, dispatch, userList]);

  useEffect(() => {
    // Function to load community user data
    const loadData = async () => {
      if (communities && communities.length > 0) {
        const allCommunities = communities.map((community) => community.id);
        dispatch(loadAllUnitUserData(allCommunities));
      }
    };

    loadData();
  }, [communities, dispatch]);

  const isFiltered = () => {
    return isCommunityFiltered || isStatusFiltered || isUserFiltered;
  };

  const handlePanelOpen = () => {
    setShowPanel(true);
  };

  const handlePanelClose = () => {
    setShowPanel(false);
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.notificationContainer}
        id="all-notifications-container"
      >
        <div className={classes.sectionHeader}>
          <Typography className={classes.title}>
            {t("all_notifications")}
          </Typography>
        </div>
        <div>
          <IconButton
            className={clsx(classes.filterIcon)}
            onClick={handlePanelOpen}
          >
            <FilterIcon
              showBanner={showBanner}
              minimizedTray={minimizedTray}
              color={isFiltered() ? "primary" : "default"}
            />
          </IconButton>
        </div>
        <div className={classes.communitiesContainer}>
          <NotificationCard addTab={addTab} />
        </div>
      </div>
      <div>
        {
          <AllNotificationsFilterPanel
            show={showPanel}
            onClose={handlePanelClose}
            showBanner={showBanner}
            minimizedTray={minimizedTray}
            userList={userList}
          />
        }
      </div>
    </div>
  );
}

interface IProps {
  openTray: boolean;
  minimizedTray: boolean;
  addTab: (id: string, type: TabTypes) => void;
}
