import { useState, useEffect } from "react";
import { IUnit } from "../../../services/dashboard.services";
import { isHelpAtHome } from "../../dashboard/dashboardSlice";

function useHelpAtHome(units?: IUnit[]) {
  const [allHelpAtHome, setAllHelpAtHome] = useState<undefined | boolean>(
    undefined
  );
  const [someHelpAtHome, setSomeHelpAtHome] = useState<undefined | boolean>(
    undefined
  );
  const [someOtherUnitTypes, setSomeOtherUnitTypes] = useState<
    undefined | boolean
  >(undefined);

  useEffect(() => {
    if (units === undefined) return;
    const allHelpAtHome = units?.every((unit) => isHelpAtHome(unit));
    setAllHelpAtHome(allHelpAtHome);
  }, [units]);

  useEffect(() => {
    if (units === undefined) return;
    const someHelpAtHome = units?.some((unit) => isHelpAtHome(unit));
    setSomeHelpAtHome(someHelpAtHome);
  }, [units]);

  useEffect(() => {
    if (units === undefined) return;
    const someOtherUnitTypes = units?.some((unit) => !isHelpAtHome(unit));
    setSomeOtherUnitTypes(someOtherUnitTypes);
  }, [units]);

  return [allHelpAtHome, someHelpAtHome, someOtherUnitTypes];
}

export default useHelpAtHome;
