import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      height: "60px",
      width: "60px",
    },
    offsetScroll: {
      right: theme.spacing(3),
    },
  })
);

export const AddNewItemButton = (props: IProps) => {
  const { parentRef, handleAddAction } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* State */
  const [scrollbarVisible, setScrollbarVisible] = useState(false);

  /* Effects */
  useEffect(() => {
    if (parentRef.current !== null) {
      setScrollbarVisible(
        parentRef.current.scrollHeight > parentRef.current.clientHeight
      );
    }
  }, [
    parentRef?.current?.scrollHeight,
    parentRef?.current?.clientHeight,
    parentRef,
  ]);

  return (
    <Fab
      color="primary"
      onClick={handleAddAction}
      className={clsx(
        classes.addButton,
        scrollbarVisible && classes.offsetScroll
      )}
    >
      <AddIcon titleAccess={t("add")} />
    </Fab>
  );
};

interface IProps {
  parentRef: React.RefObject<HTMLDivElement>;
  handleAddAction: () => void;
}
