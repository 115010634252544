import { withStyles } from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";

export const SettingsListItem = withStyles(({ palette, spacing }) => ({
  root: {
    //paddingBottom: spacing(1),
    padding: spacing(1),
    width: "100%",
    borderRadius: "0px",
    "&$selected": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
  },
  selected: {},
  divider: {},
}))(MuiListItem);
