import { DeviceType, Manufacturers, SensorType } from "../../helpers/constants";
import { DevicesIcons } from "../../helpers/iconImports";
import i18n from "../../i18n";

export const StatusColors = {
  red: "red",
  green: "green",
  yellow: "yellow",
} as const;
export type StatusColor = typeof StatusColors[keyof typeof StatusColors];

export type DeviceStatus = { color: StatusColor; text: string };

export const BatteryState = {
  low: "low",
  good: "good",
} as const;

export const DeviceConnectionType = {
  wifi: "wifi",
  lte: "lte",
} as const;

export const iconBySensorType = (
  deviceType: string,
  manufacturer?: string | null
) => {
  switch (deviceType) {
    case SensorType.exterior_door:
    case SensorType.contact_count:
    case SensorType.contact:
      return getContactDeviceIcon(manufacturer);
    case SensorType.motion:
      return getMotionDeviceIcon(manufacturer);
    case SensorType.gateway:
      return DevicesIcons.GatewayDevice;
    case SensorType.help:
      return DevicesIcons.HelpButtonDevice;
    case SensorType.outlet:
      return DevicesIcons.OutletDevice;
    case SensorType.bulb:
      return DevicesIcons.BulbDevice;
    case SensorType.radar:
      return DevicesIcons.RadarDevice;
    default:
      return "";
  }
};

const getMotionDeviceIcon = (manufacturer?: string | null) => {
  const normalizedManufacturer = manufacturer?.toLowerCase();

  const iconsMap: { [key: string]: string } = {
    [Manufacturers.SAMJIN]: DevicesIcons.SamjinMotionDevice,
    [Manufacturers.ERIA]: DevicesIcons.EriaMotionDevice,
  };

  return iconsMap[normalizedManufacturer || ""] || DevicesIcons.MotionDevice;
};

const getContactDeviceIcon = (manufacturer?: string | null) => {
  const normalizedManufacturer = manufacturer?.toLowerCase();

  const iconsMap: { [key: string]: string } = {
    [Manufacturers.SAMJIN]: DevicesIcons.SamjinContactDevice,
    [Manufacturers.ERIA]: DevicesIcons.EriaContactDevice,
  };

  return iconsMap[normalizedManufacturer || ""] || DevicesIcons.ContactDevice;
};

export const iconByDeviceType = (deviceType: string) => {
  switch (deviceType) {
    case DeviceType.sensor:
      return DevicesIcons.MotionDevice;
    case DeviceType.button:
      return DevicesIcons.HelpButtonDevice;
    case DeviceType.outlet:
      return DevicesIcons.OutletDevice;
    case DeviceType.bulb:
      return DevicesIcons.BulbDevice;
    default:
      return "";
  }
};

export const deviceDisplayTextByDeviceType = (
  deviceType?: string | null,
  sensorType?: string | null
) => {
  if (deviceType === null || deviceType === undefined) return "";

  if (sensorType) {
    // Handles bulbs that have sensor type of motion
    if (deviceType === DeviceType.bulb) {
      return i18n.t(deviceType);
    } else {
      return i18n.t(deviceDisplayTextBySensorType(sensorType));
    }
  } else {
    return i18n.t(deviceType);
  }
};

export const deviceDisplayTextBySensorType = (deviceType: string) => {
  switch (deviceType) {
    case SensorType.exterior_door:
    case SensorType.contact_count:
    case SensorType.contact:
      return "contact_sensor";
    case SensorType.radar:
    case SensorType.motion:
      return "motion_sensor";
    case SensorType.gateway:
      return "stack_gateway";
    case SensorType.help:
      return "button";
    case SensorType.outlet:
      return "outlet";
    default:
      return "";
  }
};

export const sensorDisplayTextBySensorType = (deviceType: string) => {
  switch (deviceType) {
    case SensorType.exterior_door:
      return "device_type_exterior_door";
    case SensorType.contact_count:
      return "device_type_contact_count";
    case SensorType.contact:
      return "device_type_entry_door";
    case SensorType.motion:
      return "device_type_motion";
    case SensorType.gateway:
      return "stack_gateway";
    case SensorType.help:
      return "device_type_help";
    case SensorType.outlet:
      return "device_type_smart";
    case SensorType.radar:
      return "device_type_radar";
    default:
      return "";
  }
};

export const formatMacAddress = (macAddress?: string | null) => {
  return macAddress
    ? macAddress
        .toUpperCase()
        .match(/.{1,2}/g)
        ?.join(":") ?? ""
    : macAddress;
};
