import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    ldsRing: {
      display: "inline-block",
      position: "relative",
      width: "60px",
      height: "60px",
      "& div": {
        boxSizing: "border-box",
        display: "block",
        position: "absolute",
        width: "42px",
        height: "42px",
        margin: "8px",
        border: "3px solid #fff",
        borderRadius: "50%",
        animation: " $ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
        borderColor: "#fff transparent transparent transparent",
      },
      "& div:nth-child(1)": {
        animationDelay: "-0.45s",
      },
      "& div:nth-child(2)": {
        animationDelay: "-0.3s",
      },
      "& div:nth-child(3)": {
        animationDelay: "-0.15s",
      },
    },
    "@keyframes ldsRing": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  })
);
export const LoadingIndicator = () => {
  const classes = useStyles();

  return (
    <div className={classes.ldsRing}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
