import { IToken } from "./login.services";
import { StorageKeys } from "./constants";
import { currentEnv, currentVer } from "../helpers/utils";

export interface IAppLocalStorage {
  userToken: IToken | null;
  selectedCommunityID: string | null;
  selectedOrganizationID: string | null;
  allNotifications: string | null;
  parentClientID: string | null;
  env: string;
  version: string | null;
}

export const appServices = {
  initialize,
};

/**
 * Loads information from the localStorage
 *
 * @returns {IAppLocalStorage} Returns the storaged token or false if there is not any
 */

async function initialize(): Promise<IAppLocalStorage> {
  const _userToken = localStorage.getItem(StorageKeys.USER);
  const selectedCommunityID = localStorage.getItem(
    StorageKeys.SELECTED_COMMUNITY_ID
  );

  const selectedOrganizationID = localStorage.getItem(
    StorageKeys.SELECTED_ORGANIZATION_ID
  );

  const allNotifications = localStorage.getItem(StorageKeys.ALL_NOTIFICATIONS);

  const userToken =
    _userToken !== null ? (JSON.parse(_userToken) as IToken) : _userToken;

  const parentClientID = localStorage.getItem(StorageKeys.PARENT_CLIENT_ID);

  const env = currentEnv();

  const version = currentVer();

  return {
    userToken,
    selectedCommunityID,
    selectedOrganizationID,
    allNotifications,
    parentClientID,
    env,
    version,
  };
}
