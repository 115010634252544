import { useAppSelector } from "../app/appHooks";
import useHelpAtHome from "../notifications/hooks/useHelpAtHome";
import { AddResidentSettings } from "./AddResidentSettings";
import { EditResidentSettings } from "./EditResidentSettings";

export const ResidentSettingsForm = () => {
  const selectedUnitResident = useAppSelector((state) => {
    const selectedUnitID = state.settingsState.selectedUnit;
    if (selectedUnitID !== undefined) {
      return state.headerState.units?.find((unit) => unit.id === selectedUnitID)
        ?.residents?.[0];
    }
    return selectedUnitID;
  });

  const units = useAppSelector((state) => state.headerState.units);

  const [allHelpAtHome] = useHelpAtHome(units);

  return (
    <>
      {selectedUnitResident !== undefined ? (
        <EditResidentSettings
          resident={selectedUnitResident}
          homeCareCommunity={allHelpAtHome ?? false}
        />
      ) : (
        <AddResidentSettings homeCareCommunity={allHelpAtHome ?? false} />
      )}
    </>
  );
};
