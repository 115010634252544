import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";

import { FilterSearchField } from "../../notifications/filters/FilterSearchField";
import { ApplyFilterButton } from "../../notifications/filters/ApplyFilterButton";
import { GreenCheckbox } from "../../notifications/filters/GreenCheckBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContainer: {
      maxWidth: "300px",
      minWidth: "370px",
      maxHeight: "400px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      "&$selected:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
    },
    listLabel: {
      fontWeight: "bold",
      paddingRight: theme.spacing(0.5),
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    listIcon: {
      minWidth: "25px",
    },
    popoverHeader: {
      display: "flex",
    },
    selected: {},
  })
);

interface FilteredListOptions {
  id: string;
  label: string | null;
}

export const ListOptionsFilter = (props: IProps) => {
  const {
    options,
    selectedOptions,
    atLeastOneSelected,
    needsToChangeToSave,
    handleApplyFilter,
    handleCloseFilter,
  } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();

  // Create controlled list options
  let defaultState: { [key: string]: boolean } = {};

  options.forEach(
    (option) => (defaultState[option.id] = selectedOptions.includes(option.id))
  );

  /* ---- State ---- */
  const [filterSelection, setFilterSelection] = useState(defaultState);
  const [filteredOptions, setFilteredOptions] = useState<FilteredListOptions[]>(
    options
  );

  // Obtain keys for searched for values
  const filteredOptionKeys = options.map((option) => option.id);

  // Check if all searched for values are selected
  const allSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => filterSelection[val]);

  // Check if all searched for values are not selected
  const noneSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => !filterSelection[val]);

  /* ---- Methods ---- */
  const handleChange = (eventType: string) => {
    setFilterSelection({
      ...filterSelection,
      [eventType]: !filterSelection[eventType],
    });
  };

  const handleSelectAll = () => {
    setFilterSelection((prevState) => {
      const keysToUpdate = Object.keys(prevState).filter((option) =>
        filteredOptionKeys.includes(option)
      );
      const newState: {
        [key: string]: boolean;
      } = {};
      if (allSelected) {
        keysToUpdate.forEach((option) => (newState[option as string] = false));
      } else {
        keysToUpdate.forEach((option) => (newState[option as string] = true));
      }
      return { ...prevState, ...newState };
    });
  };

  const handleSearchField = (searchVal: string) => {
    const filteredArray = options.filter((option) =>
      option.label?.toLowerCase().includes(searchVal)
    );
    setFilteredOptions(filteredArray ?? []);
  };

  const handleApplyButton = () => {
    handleApplyFilter(filterSelection);
    handleCloseFilter();
  };

  const atLeastOne = Object.values(filterSelection).some((val) => val);

  return (
    <React.Fragment>
      <div className={classes.popoverHeader}>
        <FilterSearchField
          withApplyButton
          placeholder="search_resident"
          allSelected={allSelected}
          noneSelected={noneSelected}
          selectAllCheckbox
          handleSelectAll={handleSelectAll}
          handleSearchField={handleSearchField}
        />
        <ApplyFilterButton
          defaultOptions={defaultState}
          currentOptions={filterSelection}
          atLeastOne={atLeastOneSelected ? atLeastOne : undefined}
          onApplyFilter={handleApplyButton}
          needsToChangeToSave={needsToChangeToSave}
        />
      </div>
      <Divider />

      <List aria-label="list" className={classes.popoverContainer}>
        {filteredOptions.map((option) => {
          return (
            <ListItem
              classes={{ root: classes.listRoot }}
              key={option.id}
              id={option.id}
              button
              onClick={() => handleChange(option.id)}
            >
              <ListItemIcon
                classes={{
                  root: classes.listIcon,
                }}
              >
                <GreenCheckbox
                  edge="start"
                  checked={filterSelection[option.id]}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  <div
                    className={clsx(
                      classes.listLabel,
                      classes.truncateEllipsis
                    )}
                  >
                    {option.label}
                  </div>
                }
                disableTypography
              />
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  options: FilteredListOptions[];
  selectedOptions: string[];
  atLeastOneSelected: boolean;
  needsToChangeToSave: boolean;
  handleApplyFilter: (selectedOptions: { [key: string]: boolean }) => void;
  handleCloseFilter: () => void;
}
