import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { IEventNotes } from "../../../services/dashboard.services";
import { useTranslation } from "react-i18next";
import { DateTimeLongFormat } from "../../../helpers/datetime";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comment: {
      marginBottom: theme.spacing(2),
    },
    commentDate: {
      color: theme.palette.dark.dark,
      marginBottom: "4px",
      opacity: "0.54",
      fontSize: "small",
    },
    multilined: {
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    systemImportant: {
      color: theme.palette.error.main,
    },
  })
);
export default function EventComment(props: IProps) {
  const { comment, communityTimezone: communityUnitTimezone } = props;
  const formatedTime = DateTimeLongFormat(
    comment?.timestamp || "",
    communityUnitTimezone
  );
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.comment}>
      <Box
        fontWeight="fontWeightBold"
        fontSize="caption"
        className={classes.commentDate}
      >
        {`${formatedTime} - ${
          comment?.type?.includes("system") ? t("system") : comment.name
        }`}

        {comment?.type?.includes("system:issue") ? (
          <>
            <span>{" - "}</span>
            <span className={classes.systemImportant}>
              {t("system_issues")}
            </span>
          </>
        ) : comment?.type?.includes("system:delayed") ? (
          <>
            <span>{" - "}</span>
            <span className={classes.systemImportant}>{t("delayed")}</span>
          </>
        ) : (
          ""
        )}
      </Box>
      <Typography className={classes.multilined}>
        {comment?.description}
      </Typography>
    </div>
  );
}

interface IProps {
  comment: IEventNotes;
  communityTimezone?: string;
}
