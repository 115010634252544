import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  DefaultRegionCode,
  PHONE_NUMBER_MAX_LENGTH,
  getIconByPhoneType,
} from "../../helpers/constants";
import { Link, Theme, Tooltip, withStyles } from "@material-ui/core";
import { IPhoneNumber } from "../../services/header.services";
import {
  parsePhoneNumber,
  getRegionCodeForCountryCode,
} from "awesome-phonenumber";
import clsx from "clsx";
import { IAppState } from "../../helpers/store";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    phoneFieldContainer: {
      display: "flex",
      alignItems: "center",
      color: (props) =>
        !props.notClickable ? theme.palette.primary.main : undefined,
      padding: "0px 0px 4px 0px",
    },
    phoneFieldContainerDefault: {
      display: "flex",
      alignItems: "center",
      padding: (props) => (props.noPadding ? 0 : "0px 0px 4px 0px"),
    },
    phoneFieldIcon: {
      display: "inline-table",
      height: "20px",
      paddingRight: theme.spacing(1),
    },
    phoneFieldLabel: {
      fontFamily: (props) =>
        !props.notClickable ? theme.typography.secondaryFontFamily : undefined,
      fontSize: "1rem",
    },
    rightAlign: {
      justifyContent: "flex-end",
    },
  })
);

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const PhoneNumberField = (props: IProps) => {
  const { phone_type, phone_number, country_code } = props.phoneNumber;

  const user = useSelector((state: IAppState) => {
    return state.headerState.user;
  });

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles(props);
  const phoneIcon = getIconByPhoneType(phone_type ?? "");
  const regionCode =
    country_code && !isNaN(Number(country_code))
      ? getRegionCodeForCountryCode(Number(country_code))
      : DefaultRegionCode;

  const phoneNumberValue = parsePhoneNumber(phone_number ?? "", regionCode);
  const includeIcon = !props.noIcon;
  const isInternational = user && user.country?.toUpperCase() !== regionCode;

  const parsedPhoneNumber = isInternational
    ? phoneNumberValue.getNumber("international")
    : phoneNumberValue.getNumber("national") ??
      phoneNumberValue.getNumber("significant") ??
      (phoneNumberValue.toJSON().number?.input as string);

  const truncate = props.noTruncate !== undefined ? !props.noTruncate : true;

  return (
    <React.Fragment>
      {props.notClickable ? (
        <span
          className={clsx(
            classes.phoneFieldContainerDefault,
            props.rightAlign && classes.rightAlign
          )}
        >
          {includeIcon && (
            <img
              src={phoneIcon}
              alt={t("alt_text_phone_type_icon", {
                phone_type: phone_type ?? "",
              })}
              draggable={false}
              className={classes.phoneFieldIcon}
            />
          )}
          {truncate && parsedPhoneNumber.length > PHONE_NUMBER_MAX_LENGTH ? (
            <CustomTooltip
              title={parsedPhoneNumber}
              placement="top"
              interactive
            >
              <span className={classes.phoneFieldLabel}>
                {`${parsedPhoneNumber.slice(0, PHONE_NUMBER_MAX_LENGTH)}...`}
              </span>
            </CustomTooltip>
          ) : (
            <span className={classes.phoneFieldLabel}>{parsedPhoneNumber}</span>
          )}
        </span>
      ) : (
        <Link
          className={classes.phoneFieldContainer}
          color="primary"
          href={phoneNumberValue.getNumber("rfc3966")}
        >
          {includeIcon && (
            <img
              src={phoneIcon}
              alt={t("alt_text_phone_type_icon", {
                phone_type: phone_type ?? "",
              })}
              draggable={false}
              className={classes.phoneFieldIcon}
            />
          )}
          {truncate && parsedPhoneNumber.length > PHONE_NUMBER_MAX_LENGTH ? (
            <CustomTooltip
              title={parsedPhoneNumber}
              placement="top"
              interactive
            >
              <span className={classes.phoneFieldLabel}>
                {`${parsedPhoneNumber.slice(0, PHONE_NUMBER_MAX_LENGTH)}...`}
              </span>
            </CustomTooltip>
          ) : (
            <span className={classes.phoneFieldLabel}>{parsedPhoneNumber}</span>
          )}
        </Link>
      )}
    </React.Fragment>
  );
};

interface IProps {
  notClickable?: boolean;
  phoneNumber: IPhoneNumber;
  noPadding?: boolean;
  noIcon?: boolean;
  noTruncate?: boolean;
  rightAlign?: boolean;
}
