import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Link as RouterLink } from "react-router-dom";

import { accountCreationInit, goToAccountCreation } from "./../loginSlice";
import { useAppDispatch } from "../../app/appHooks";
import { Views } from "../../../helpers/constants";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ActionBarBottom } from "./ActionBarBottom";
import { StackLogo } from "../StackLogo";
// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionsContainer: {
      width: "380px",
      marginTop: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: theme.spacing(4),
      height: "65%",
      [theme.breakpoints.down("sm")]: {
        width: "360px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    link: {
      cursor: "pointer",
      fontFamily: theme.typography.secondaryFontFamily,
      fontWeight: 500,
      fontSize: theme.typography.subtitle1.fontSize,
      textAlign: "right",
    },
    secondaryButtons: {
      color: theme.palette.paused.light,
    },
    businessInstructions: {
      whiteSpace: "pre-line",
      fontSize: theme.typography.h6.fontSize,
      lineHeight: 1.3,
      margin: 0,
    },
    nextButton: {
      padding: theme.spacing(2, 8),
      maxHeight: 50,
    },
    buttonLabel: {
      fontSize: theme.typography.h6.fontSize,
    },
  })
);

export const AccountCreationInstructions = () => {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleBackToLogin = () => {
    dispatch(accountCreationInit());
  };
  const handleGoToAccountCreation = () => {
    dispatch(goToAccountCreation());
  };

  return (
    <>
      <StackLogo />
      <div className={classes.instructionsContainer}>
        <p className={classes.businessInstructions}>
          {t("business_account_instructions")}
        </p>
        <Button
          type="submit"
          variant={"contained"}
          color="primary"
          size="large"
          className={classes.nextButton}
          classes={{ label: classes.buttonLabel }}
          onClick={handleGoToAccountCreation}
        >
          {t("next")}
        </Button>
      </div>
      <ActionBarBottom>
        <Link
          className={classes.link}
          onClick={handleBackToLogin}
          to={Views.LOGIN}
          component={RouterLink}
          underline="none"
        >
          <Button
            className={classes.secondaryButtons}
            startIcon={<ArrowBackIosIcon />}
          >
            {t("action_back")}
          </Button>
        </Link>
      </ActionBarBottom>
    </>
  );
};
