import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { IAppState } from "../../helpers/store";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from "@material-ui/core";
import { logout } from "../login/loginSlice";
import { useTranslation } from "react-i18next";
import { loginServices } from "../../services/login.services";
import { toggleLogging } from "../app/appSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    userAvatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },

    nested: {
      paddingLeft: theme.spacing(3),
    },
    text: {
      paddingRight: theme.spacing(3),
    },
  })
);

export default function UserAvatar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  /* ---- State ---- */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  /* ---- Selectors ---- */
  const user = useSelector((state: IAppState) => state.headerState.user);

  /* ---- Methods ---- */
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    loginServices.logoutFirebase();
  };

  let fullName = "";
  if (user) {
    if (
      user.first_name !== null &&
      user.first_name !== undefined &&
      user.first_name !== ""
    ) {
      // Get user full name or the first name if user does not have last name
      if (
        user.last_name !== null &&
        user.last_name !== undefined &&
        user.last_name !== ""
      ) {
        const firstName = user.first_name[0].slice(0, 1).toUpperCase();
        const lastName = user.last_name[0].slice(0, 1).toUpperCase();
        fullName = `${firstName}${lastName}`;
      } else {
        const firstName = user.first_name[0].slice(0, 1).toUpperCase();
        fullName = `${firstName}`;
      }
      // No name or last name information for user, use email instead, Uppercase first letter
    } else {
      fullName = user.email[0].slice(0, 1).toUpperCase();
    }
  }

  const showLoggingOptions = process.env.REACT_APP_ENVIRONMENT !== "production";

  return (
    <div className={classes.root}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <Avatar alt={user?.first_name || ""} className={classes.userAvatar}>
          {fullName}
        </Avatar>
      </Button>
      <Menu
        id="menu-user-avatar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>
          <Box fontWeight="fontWeightBold">
            {`${user?.first_name || user?.email} ${user?.last_name || ""}`}
          </Box>
        </MenuItem>
        {showLoggingOptions && <LoggingOptions />}
        <Divider variant="middle" />
        <MenuItem onClick={handleLogout}>{t("log_out")}</MenuItem>
      </Menu>
    </div>
  );
}

const LoggingOptions = () => {
  const dispatch = useDispatch();

  /* ---- Selectors ---- */
  const appLoggingState = useSelector(
    (state: IAppState) => state.appState.appLogging
  );

  const handleChange = () => {
    dispatch(toggleLogging());
  };

  return (
    <React.Fragment>
      <Divider variant="middle" />
      <List
        subheader={
          <ListItem>
            <ListItemText id="switch-list-label-wifi" primary="Logging" />
            <ListItemSecondaryAction>
              <Switch
                checked={appLoggingState}
                onChange={handleChange}
                color="primary"
                name="appLogging"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        }
      ></List>
    </React.Fragment>
  );
};
