import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

export const DraggableItem = (props: Props) => {
  const { id, children } = props;
  const { listeners, setNodeRef, transform } = useDraggable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    margin: "0 38px 8px 24px",
  };

  return (
    <li style={style} ref={setNodeRef} {...listeners}>
      {children}
    </li>
  );
};

interface Props {
  id: string;
  children: React.ReactNode;
}
