import React from "react";
import { DateTime } from "luxon";
import StackedBarChart, { IDateVisitValue } from "../../common/StackedBarChart";
import {
  IBathroomEventArray,
  IBathroomEventData,
} from "../../dashboard/eventDataTypes";
import { UnitChartHeader } from "./UnitChartHeader";
import { AnalyticsChartTypes, Categories } from "../../../helpers/constants";
import AnalyticsChartContainer from "../charts/AnalyticsChartContainer";
import { CHART_DATA_LIMIT_MINIMIZED } from "../AnalyticsViewer";
import { ChartMode } from "../AnalyticsViewerCharts";
import { timeConfigurations } from "../../../helpers/datetime";

export function UnitBathroomChart(props: IProps) {
  const {
    chartData,
    mode,
    lastUpdatedTime,
    isPrintable,
    pagination,
    multipleBathrooms,
  } = props;
  const { locale } = timeConfigurations();

  /* Hooks */
  const dereferencedData = JSON.parse(
    JSON.stringify(chartData?.data || [])
  ) as IBathroomEventData[];

  let data = dereferencedData.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const filterWithPagination = (fullData: IBathroomEventData[]) => {
    if (pagination) {
      const startTime = DateTime.fromISO(pagination.startTime).startOf("day");
      const endTime = DateTime.fromISO(pagination.endTime).endOf("day");
      return fullData.filter((bathroomItem) => {
        const bathroomDate = DateTime.fromISO(bathroomItem.date);
        return bathroomDate >= startTime && bathroomDate <= endTime;
      });
    }
    return fullData;
  };

  const filterData = filterWithPagination(dereferencedData);

  // Reference dates
  let dates = undefined;
  let mappedMin = undefined;
  let mappedMax = undefined;

  if (pagination && mode === ChartMode.maximized) {
    dates = data.map((item) => item.date);
    mappedMin = dates.indexOf(pagination.startTime);
    mappedMax = dates.indexOf(pagination.endTime);
  }

  data =
    mode === ChartMode.maximized
      ? data
      : data.slice(-CHART_DATA_LIMIT_MINIMIZED);

  const valuesDay: number[] = [];
  const valuesNight: number[] = [];
  const valuesTotals: IDateVisitValue[] = [];
  const dayOutliers: boolean[] = [];
  const nightOutliers: boolean[] = [];
  const labels: (string | string[])[] = [];
  let max = 0;

  data.map((item, index) => {
    const totalOutlier = isTotalOutlier(item);
    const dayOutlier = isDayOutlier(item);
    const nightOutlier = isNightOutlier(item);
    valuesDay.push(item.day?.visits || 0);
    valuesNight.push(item.night?.visits || 0);
    valuesTotals.push({
      visitCount: (item.day?.visits || 0) + (item.night?.visits || 0),
      isOutlier: item.total ? totalOutlier : false,
    });
    dayOutliers.push(item.day ? dayOutlier : false);
    nightOutliers.push(item.night ? nightOutlier : false);
    const dateObj = DateTime.fromISO(item.date).setLocale(locale);
    labels.push([dateObj.toFormat("EEE"), dateObj.toFormat("dd")]);
    max = Math.max(max, (item.day?.visits || 0) + (item.night?.visits || 0));
    return index;
  });
  max = Math.floor((max + 10) / 10) * 10;

  let zoneName = undefined;
  if (multipleBathrooms) {
    const formatZoneName = (zoneName?: string | null) => {
      if (zoneName === null || zoneName === undefined) return "";
      return zoneName.slice(0, 15);
    };
    zoneName = formatZoneName(chartData.zone_name);
  }
  const chartTitle = multipleBathrooms ? zoneName : undefined;

  return (
    <React.Fragment>
      <UnitChartHeader
        type={AnalyticsChartTypes.bathroom}
        mode={mode}
        data={filterData}
        isPrintable={isPrintable}
        lastUpdatedTime={lastUpdatedTime}
        zoneId={chartData.zone_id}
      />
      <AnalyticsChartContainer isPrintable={isPrintable}>
        <StackedBarChart
          labels={labels}
          valuesDay={valuesDay}
          valuesNight={valuesNight}
          valuesTotals={valuesTotals}
          dayOutliers={dayOutliers}
          nightOutliers={nightOutliers}
          mappedMin={mappedMin}
          mappedMax={mappedMax}
          dateReference={dates}
          max={max}
          animation={false}
          panEnabled={mode === ChartMode.maximized}
          chartTitle={chartTitle}
        />
      </AnalyticsChartContainer>
    </React.Fragment>
  );
}

export const isTotalOutlier = (item: IBathroomEventData) => {
  return item.total?.indiv_category
    ? item.total.indiv_category === Categories.high ||
        item.total.indiv_category === Categories.low
    : item.total?.indiv_outlier
    ? item.total?.indiv_outlier.valueOf() === true
    : false;
};

export const isDayOutlier = (item: IBathroomEventData) => {
  return item.day?.indiv_category
    ? item.day.indiv_category === Categories.high ||
        item.day.indiv_category === Categories.low
    : item.day?.indiv_outlier
    ? item.day?.indiv_outlier.valueOf() === true
    : false;
};

export const isNightOutlier = (item: IBathroomEventData) => {
  return item.night?.indiv_category
    ? item.night.indiv_category === Categories.high ||
        item.night.indiv_category === Categories.low
    : item.night?.indiv_outlier
    ? item.night?.indiv_outlier.valueOf() === true
    : false;
};

interface IProps {
  chartData: IBathroomEventArray;
  mode: ChartMode;
  lastUpdatedTime?: string;
  isPrintable: boolean;
  pagination?: { startTime: string; endTime: string };
  multipleBathrooms?: boolean;
}
