import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Theme, useTheme } from "@material-ui/core/styles";

import {
  IActivityData,
  IActivityTime,
  IContact,
  IMonitorData,
} from "../../dashboard/eventDataTypes";
import {
  AnalyticsChartTypes,
  ContactSensorTypes,
  midBlurValue,
  noBlurValue,
  sortAlphabetical,
  StackTypes,
} from "../../../helpers/constants";
import FloatBarChart, {
  IFloatingBarDataFormat,
} from "../../common/FloatBarChart";
import {
  DateFromIsoString,
  DateTimeFromMillis,
} from "../../../helpers/datetime";
import {
  checkForContactCountSensor,
  checkForContactSensor,
  checkForMotionSensor,
} from "../../dashboard/charts/chartHelpers";
import useCommunityUnitTimezone from "../../dashboard/hooks/useCommunityUnitTimezone";
import { IUnitActivityData } from "../../dashboard/eventDataTypes";
import { useAppSelector } from "../../app/appHooks";
import { UnitChartHeader } from "./UnitChartHeader";
import AnalyticsChartContainer from "../charts/AnalyticsChartContainer";
import { ChartMode } from "../AnalyticsViewerCharts";
import { getRemainderToRound, timeDiffInHours } from "../../common/LineChart";

// Obtains datasets for unit activity chart
const getUnitActivityDatasets = (
  timeIntervals: IActivityTime[],
  colorArray: string[],
  theme: Theme,
  communityTimezone?: string
) => {
  // Obtain intervals for the zone, if is the last interval, add the no motion indicator
  const dayMonitorDatasets = timeIntervals.reduce(
    (result: string[][], data) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityTimezone
      );

      let endDate = startDate.plus({ minute: 3 });
      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityTimezone);
      }
      colorArray.push(theme.palette.activityMotion.main);
      result.push([startDate.toISO(), endDate.toISO()]);
      return result;
    },
    []
  );

  return dayMonitorDatasets;
};

// Obtains the min and max for the unit activity chart
const getChartMinMaxByEvent = (
  creationTime: string,
  mode: ChartMode,
  pagination?: { startTime: string; endTime: string },
  communityTimezone?: string
) => {
  let maxDateTime = DateFromIsoString(creationTime, communityTimezone);
  let minDateTime = maxDateTime;
  if (mode === ChartMode.minimized) {
    minDateTime = minDateTime.minus({ hour: 12 });
  } else if (mode === ChartMode.maximized && pagination) {
    maxDateTime = DateFromIsoString(pagination.endTime, communityTimezone);
    minDateTime = DateFromIsoString(pagination.startTime, communityTimezone);

    // If the range hour is less than 2 hours, we should should the chart every 15 o 5 minutes
    //to show it this way, the min limit has to start in an even value multiple of 15 or 5
    const rangeHourDifference = timeDiffInHours(maxDateTime, minDateTime);
    const remainderVal = getRemainderToRound(rangeHourDifference);
    if (remainderVal) {
      minDateTime = minDateTime.minus({
        minute: minDateTime.minute % remainderVal,
      });
    }
  } else {
    minDateTime = minDateTime.minus({ hours: 48 });
  }
  return { minDateTime, maxDateTime };
};

// Obtains datasets when motion arrays exist for a zone
const getActivityDatasets = (
  activityData: IActivityData,
  theme: Theme,
  communityTimezone?: string
) => {
  // Copy and dereference array and orders it ascendingly
  const timeIntervals = JSON.parse(
    JSON.stringify(activityData.activity ? activityData.activity : [])
  ) as IActivityTime[];

  // Get the last interval for the zone
  let colorArray: string[] = [];
  const defaultData = [[""]];
  let result = {
    color: colorArray,
    stack: StackTypes.MOTION,
    datasets: defaultData,
  };

  const dayMonitorDataset = getUnitActivityDatasets(
    timeIntervals,
    colorArray,
    theme,
    communityTimezone
  );
  if (dayMonitorDataset.length > 0) result.datasets = dayMonitorDataset;
  return result;
};

// Obtains datasets when contact arrays exist for a zone
const getContactDatasets = (
  activityData: IActivityData,
  creationTime: string,
  theme: Theme,
  startBlurInterval: number,
  endBlurInterval: number,
  communityTimezone?: string
) => {
  const sensors = activityData.sensors ? activityData.sensors : [];
  // Copy and dereference array and orders it ascendingly
  const contactData = JSON.parse(
    JSON.stringify(activityData.contact ? activityData.contact : [])
  ) as IContact[];

  const contactDatasets = contactData.reduce(
    (result: IFloatingBarDataFormat[], contact) => {
      const sensorID = contact.sensor_id;
      const contactSensor = sensors.find(
        (sensor) => sensor.sensor_id === sensorID
      );
      if (
        contact.activity &&
        contactSensor !== undefined &&
        (contactSensor.sensor_type === ContactSensorTypes.contact ||
          contactSensor.sensor_type === ContactSensorTypes.exterior_door)
      ) {
        const timeIntervals = contact.activity ?? [];

        // Array of time intervals ordered
        const contactDatasets = timeIntervals.map((data, index) => {
          const startDate = DateTimeFromMillis(
            data.start_time || 0,
            communityTimezone
          ).minus({
            minute: startBlurInterval,
          });
          let endDate = startDate.plus({ minute: endBlurInterval });
          if (data.end_time) {
            endDate = DateTimeFromMillis(
              data.end_time,
              communityTimezone
            ).plus({ minute: endBlurInterval });
          }
          if (data.end_time === null || data.end_time === undefined) {
            endDate = DateFromIsoString(creationTime, communityTimezone);
          }
          return [startDate.toISO(), endDate.toISO()];
        });
        result.push({
          color: theme.palette.contactBlue.main,
          stack: StackTypes.CONTACT,
          datasets: contactDatasets.length > 0 ? contactDatasets : [[""]],
        });
      }
      return result;
    },
    []
  );

  return contactDatasets;
};

// Obtains datasets when contact arrays exist for a zone
const getContactCountDatasets = (
  activityData: IActivityData,
  creationTime: string,
  theme: Theme,
  startBlurInterval: number,
  endBlurInterval: number,
  communityTimezone?: string
) => {
  const sensors = activityData.sensors ? activityData.sensors : [];
  // Copy and dereference array and orders it ascendingly
  const contactCountData = JSON.parse(
    JSON.stringify(activityData.contact_count ? activityData.contact_count : [])
  ) as IContact[];

  const contactCountDatasets = contactCountData.reduce(
    (result: IFloatingBarDataFormat[], contact) => {
      const sensorID = contact.sensor_id;
      const contactCountSensor = sensors.find(
        (sensor) => sensor.sensor_id === sensorID
      );
      if (
        contact.activity &&
        contactCountSensor !== undefined &&
        contactCountSensor.sensor_type === ContactSensorTypes.contact_count
      ) {
        const timeIntervals = contact.activity ?? [];

        // Array of time intervals ordered
        const contactDatasets = timeIntervals.map((data, index) => {
          const startDate = DateTimeFromMillis(
            data.start_time || 0,
            communityTimezone
          ).minus({
            minute: startBlurInterval,
          });
          let endDate = startDate.plus({ minute: endBlurInterval });
          if (data.end_time) {
            endDate = DateTimeFromMillis(
              data.end_time,
              communityTimezone
            ).plus({ minute: endBlurInterval });
          }
          if (data.end_time === null || data.end_time === undefined) {
            endDate = DateFromIsoString(creationTime, communityTimezone);
          }
          return [startDate.toISO(), endDate.toISO()];
        });
        result.push({
          color: theme.palette.contactBlue.main,
          stack: StackTypes.CONTACT,
          datasets: contactDatasets.length > 0 ? contactDatasets : [[""]],
        });
      }
      return result;
    },
    []
  );

  return contactCountDatasets;
};

export function UnitActivityChart(props: IProps) {
  const { chartData, mode, pagination, lastUpdatedTime, isPrintable } = props;

  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const unitID = useAppSelector((state) => state.analyticsState.selectedUnit);
  const currentTime = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.currentTime
  );
  const maxChartLimit = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.end
  );
  const minChartLimit = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.start
  );

  /* Hooks */
  const communityUnitTimezone = useCommunityUnitTimezone(communityID, unitID);
  const { t } = useTranslation();
  const theme = useTheme();

  const data = JSON.parse(JSON.stringify(chartData)) as IMonitorData;

  const lastUpdated = lastUpdatedTime ?? "";
  const isCurrent =
    currentTime !== undefined && currentTime === pagination?.endTime;

  const motionChartData = (JSON.parse(
    JSON.stringify(data.activity_data ? data.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForMotionSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const contactChartData = (JSON.parse(
    JSON.stringify(data.activity_data ? data.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForContactSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const contactCountChartData: IActivityData[] = (JSON.parse(
    JSON.stringify(data.activity_data ? data.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForContactCountSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const motionLabels = motionChartData.reduce((result: string[], element) => {
    if (element.activity !== undefined) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const contactLabels = contactChartData.reduce((result: string[], element) => {
    if (checkForContactSensor(element)) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const contactCountLabels = contactCountChartData.reduce(
    (result: string[], element) => {
      if (checkForContactCountSensor(element)) {
        result.push(t("contact_type_refrigerator"));
      }
      return result;
    },
    []
  );

  const labels = [...motionLabels, ...contactLabels, ...contactCountLabels];

  const parseContactDatasets = (
    contactOrderedZones: IActivityData[],
    eventCreationDate: string,
    theme: Theme,
    startBlurInterval: number,
    endBlurInterval: number,
    communityTimezone?: string
  ) => {
    const contactDatasets: IFloatingBarDataFormat[] = contactOrderedZones.reduce(
      (result: IFloatingBarDataFormat[], contactData) => {
        if (contactData.contact !== undefined) {
          result.push(
            ...getContactDatasets(
              contactData,
              eventCreationDate,
              theme,
              startBlurInterval,
              endBlurInterval,
              communityTimezone
            )
          );
        } else {
          if (checkForContactSensor(contactData)) {
            result.push({
              color: theme.palette.contactBlue.main,
              stack: StackTypes.CONTACT,
              datasets: [[]],
            });
          }
        }
        return result;
      },
      []
    );
    return contactDatasets;
  };

  const parseContactCountDatasets = (
    contactCountOrderedZones: IActivityData[],
    eventCreationDate: string,
    theme: Theme,
    startBlurInterval: number,
    endBlurInterval: number,
    communityTimezone?: string
  ) => {
    const contactCountDatasets: IFloatingBarDataFormat[] = contactCountOrderedZones.reduce(
      (result: IFloatingBarDataFormat[], contactData) => {
        if (contactData.contact_count !== undefined) {
          result.push(
            ...getContactCountDatasets(
              contactData,
              eventCreationDate,
              theme,
              startBlurInterval,
              endBlurInterval,
              communityTimezone
            )
          );
        } else {
          if (checkForContactSensor(contactData)) {
            result.push({
              color: theme.palette.contactBlue.main,
              stack: StackTypes.CONTACT,
              datasets: [[]],
            });
          }
        }
        return result;
      },
      []
    );
    return contactCountDatasets;
  };

  const getDataSets = (
    motionOrderedZones: IActivityData[],
    contactOrderedZones: IActivityData[],
    contactCountOrderedZones: IActivityData[],
    eventCreationDate: string,
    theme: Theme,
    communityTimezone?: string,
    startBlurInterval: number = 1,
    endBlurInterval: number = 2
  ) => {
    const dataSets: Record<string, IFloatingBarDataFormat[]> = {};

    // Obtain datasets for motion intervals
    const activityDatasets: IFloatingBarDataFormat[] = motionOrderedZones.map(
      (activityData) =>
        getActivityDatasets(activityData, theme, communityTimezone)
    );

    // Obtain datasets for contact intervals
    const contactDatasets: IFloatingBarDataFormat[] = parseContactDatasets(
      contactOrderedZones,
      eventCreationDate,
      theme,
      startBlurInterval,
      endBlurInterval,
      communityTimezone
    );

    // Obtain datasets for no blur contact intervals
    const contactNoBlurDatasets: IFloatingBarDataFormat[] = parseContactDatasets(
      contactOrderedZones,
      eventCreationDate,
      theme,
      noBlurValue,
      noBlurValue,
      communityTimezone
    );

    // Obtain datasets for no blur contact intervals
    const contactMidBlurDatasets: IFloatingBarDataFormat[] = parseContactDatasets(
      contactOrderedZones,
      eventCreationDate,
      theme,
      midBlurValue,
      midBlurValue,
      communityTimezone
    );

    // Obtain datasets for contact count intervals
    const contactCountDatasets: IFloatingBarDataFormat[] = parseContactCountDatasets(
      contactCountOrderedZones,
      eventCreationDate,
      theme,
      startBlurInterval,
      endBlurInterval,
      communityTimezone
    );

    // Obtain datasets for no blur contact count intervals
    const contactCountNoBlurDatasets: IFloatingBarDataFormat[] = parseContactCountDatasets(
      contactCountOrderedZones,
      eventCreationDate,
      theme,
      noBlurValue,
      noBlurValue,
      communityTimezone
    );

    // Obtain datasets for no blur contact count intervals
    const contactCountMidBlurDatasets: IFloatingBarDataFormat[] = parseContactCountDatasets(
      contactCountOrderedZones,
      eventCreationDate,
      theme,
      midBlurValue,
      midBlurValue,
      communityTimezone
    );

    dataSets["activity"] = activityDatasets;
    dataSets["contact"] = contactDatasets;
    dataSets["contact_count"] = contactCountDatasets;
    dataSets["contact_no_blur"] = contactNoBlurDatasets;
    dataSets["contact_count_no_blur"] = contactCountNoBlurDatasets;
    dataSets["contact_mid_blur"] = contactMidBlurDatasets;
    dataSets["contact_count_mid_blur"] = contactCountMidBlurDatasets;

    return dataSets;
  };

  const datasets = useMemo(
    () =>
      getDataSets(
        motionChartData,
        contactChartData,
        contactCountChartData,
        lastUpdated,
        theme,
        communityUnitTimezone
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData, lastUpdated]
  );

  const filterDataSet = (
    datasets: IFloatingBarDataFormat[],
    paginationState?: { startTime: string; endTime: string }
  ) => {
    if (paginationState) {
      const minDateTime = DateFromIsoString(
        paginationState.startTime,
        communityUnitTimezone
      ).minus({ hours: 12 });
      const maxDateTime = DateFromIsoString(
        paginationState.endTime,
        communityUnitTimezone
      ).plus({ hours: 12 });
      const dataCopy = JSON.parse(
        JSON.stringify(datasets)
      ) as IFloatingBarDataFormat[];
      return dataCopy.map((stack) => {
        const newDataset = stack.datasets.filter((block, index) => {
          const blockStart = DateFromIsoString(block[0], communityUnitTimezone);
          const blockEnd = DateFromIsoString(block[1], communityUnitTimezone);
          let isOutside = blockStart < minDateTime || blockEnd > maxDateTime;
          if (isOutside) {
            if (Array.isArray(stack.color)) {
              stack.color[index] = "";
            }
            return false;
          }
          return true;
        });
        let newColor = stack.color;
        if (Array.isArray(stack.color)) {
          newColor = stack.color.filter((item) => item);
        }
        return {
          ...stack,
          color: newColor,
          datasets: newDataset,
        };
      });
    } else {
      return datasets;
    }
  };

  const filteredActivityData = filterDataSet(datasets["activity"], pagination);

  const filteredData = [
    ...filteredActivityData,
    ...datasets["contact"],
    ...datasets["contact_count"],
  ];

  const filteredNoBlurData = [
    ...filteredActivityData,
    ...datasets["contact_no_blur"],
    ...datasets["contact_count_no_blur"],
  ];

  const filteredMidBlurData = [
    ...filteredActivityData,
    ...datasets["contact_mid_blur"],
    ...datasets["contact_count_mid_blur"],
  ];

  const { minDateTime, maxDateTime } = getChartMinMaxByEvent(
    lastUpdated,
    mode,
    pagination,
    communityUnitTimezone
  );

  return (
    <React.Fragment>
      <UnitChartHeader
        type={AnalyticsChartTypes.activity}
        mode={mode}
        data={chartData}
        isPrintable={isPrintable}
        lastUpdatedTime={lastUpdatedTime}
        isCurrent={mode === ChartMode.maximized ? isCurrent : undefined}
        communityUnitTimezone={communityUnitTimezone}
      />
      <AnalyticsChartContainer isPrintable={isPrintable}>
        <FloatBarChart
          max={maxDateTime}
          min={minDateTime}
          labels={labels}
          values={filteredData}
          noBlurValues={filteredNoBlurData}
          midBlurValues={filteredMidBlurData}
          showLegends={true}
          animation={false}
          communityUnitTimezone={communityUnitTimezone}
          maxChartLimit={maxChartLimit}
          minChartLimit={minChartLimit}
          zoomEnabled={mode === ChartMode.maximized}
          panEnabled={mode === ChartMode.maximized}
        />
      </AnalyticsChartContainer>
    </React.Fragment>
  );
}

interface IProps {
  chartData: IUnitActivityData;
  mode: ChartMode;
  lastUpdatedTime?: string;
  pagination?: { startTime: string; endTime: string };
  isPrintable: boolean;
}
