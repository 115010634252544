import { request } from "./utils";
import { IFailedRequest } from "./login.services";
import { IReport } from "./dashboard.services";

export const reportsServices = {
  generateReport,
  downloadReport,
};

export interface IReportData {
  community_id: string;
  unit_id: string;
  report_type: string;
  report_date: string;
}

async function generateReport(reportData: IReportData): Promise<null> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      report_type: reportData.report_type,
      report_date: reportData.report_date,
    }),
  };

  const data = await request(
    `/communities/${reportData.community_id}/units/${reportData.unit_id}/reports`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data);
  }
}

/**
 * Loads the report data for a given unit and date
 *
 * @param {string} communityID Community for unit
 * @param {string} unitID Unit to retrieve report data
 * @param {string} reportDate Date of report data
 * @param {string} reportType Type of report
 * @returns {string} An html string that contains the report data
 */

async function downloadReport(
  communityID: string,
  unitID: string,
  reportDate: string,
  reportType: string
): Promise<IReport> {
  const requestOptions: RequestInit = {
    body: null,
  };
  const data = await request(
    `/communities/${communityID}/units/${unitID}/reports?report_date=${reportDate}&report_type=${reportType}`,
    requestOptions
  );
  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IReport);
  }
}
