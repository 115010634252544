import { debounce } from "@mui/material";
import { DateTime } from "luxon";

import {
  appTheme,
  MAXIMUM_MINUTES_ZOOM_RANGE,
  MINIMUM_MINUTES_ZOOM_RANGE,
} from "../../helpers/constants";
import { setCustomZoom } from "../analytics/analyticsSlice";
import { useAppDispatch } from "../app/appHooks";

function useZoom(zoomEnabed?: boolean) {
  /* Hooks */
  const dispatch = useAppDispatch();

  const zoomOptions = {
    wheel: {
      enabled: !!zoomEnabed,
      speed: 0.1,
    },
    drag: {
      enabled: !!zoomEnabed,
      modifierKey: "shift",
      borderColor: appTheme.palette.primary.main,
      backgroundColor: `${appTheme.palette.primary.main}4D`,
      borderWidth: 1,
      threshold: 10,
    },
    mode: "x",
    onZoomStart: ({ chart, event }: { chart: any; event: any }) => {
      const rect = event.target.getBoundingClientRect();
      const focalPoint = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };

      const speed =
        1 +
        (event.deltaY >= 0
          ? -zoomOptions.wheel.speed
          : zoomOptions.wheel.speed);

      const range = chart.scales["x"].max - chart.scales["x"].min;
      const newRange = range * (speed - 1);

      const centerPoint = chart.scales["x"].isHorizontal()
        ? focalPoint.x
        : focalPoint.y;
      // `scale.getValueForPixel()` can return a value less than the `scale.min` or
      // greater than `scale.max` when `centerPoint` is outside chartArea.
      const minPercent = Math.max(
        0,
        Math.min(
          1,
          (chart.scales["x"].getValueForPixel(centerPoint) -
            chart.scales["x"].min) /
            range || 0
        )
      );

      const maxPercent = 1 - minPercent;

      const newScaleRange = {
        min: chart.scales["x"].min + newRange * minPercent,
        max: chart.scales["x"].max - newRange * maxPercent,
      };

      const localStart = DateTime.fromMillis(newScaleRange.min).toUTC();
      const localEnd = DateTime.fromMillis(newScaleRange.max).toUTC();

      const zoomMinutes =
        localEnd.diff(localStart, ["minutes"]).toObject().minutes ?? 0;
      if (zoomMinutes < MINIMUM_MINUTES_ZOOM_RANGE) {
        if (event.deltaY < 0) {
          return false;
        }
      }
      if (zoomMinutes > MAXIMUM_MINUTES_ZOOM_RANGE) {
        return false;
      }
    },
    onZoomComplete: debounce(({ chart }: { chart: any }) => {
      const localStart = DateTime.fromMillis(chart.scales["x"].min).toUTC();
      const localEnd = DateTime.fromMillis(chart.scales["x"].max).toUTC();

      const customZoom =
        localEnd.diff(localStart, ["hours"]).toObject().hours ?? 0;

      dispatch(
        setCustomZoom({
          localStart: localStart.toISO(),
          localEnd: localEnd.toISO(),
          customZoom,
        })
      );
    }),
  };
  return zoomOptions;
}

export default useZoom;
