import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EVENT_CARD_HEIGHT, TabTypes } from "../../../helpers/constants";
import { IAppState } from "../../../helpers/store";
import { isHardEvent, removeCommunityEvent } from "../dashboardSlice";
import NotificationEvent from "../NotificationEvent";
import { EventStatus } from "../../../helpers/constants";
import { isPausedEvent } from "../CommunityUnit";
import "./index.css";
import { getFirebaseDBReference } from "../../../helpers/firebase";
import { IEvent } from "../../../services/dashboard.services";
import useOrganization from "./hooks/useOrganization";
import { VariableSizeList as List } from "react-window";

export default function CriticalNotifications(props: IProps) {
  const { addTab, unitId, organization, events, height, width } = props;
  /* ---- Hooks ---- */
  const dispatch = useDispatch();
  const { selectedOrganizationID, orgsCommunities } = useOrganization();

  /* ---- Selectors ---- */
  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );
  const communityEvents = useSelector(
    (state: IAppState) => state.dashboardState.communityEvents
  );
  const selectedCommunityID = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity?.id
  );

  let criticalEvents =
    communityEvents &&
    communityEvents
      .filter((eventEl) => unitId === null || eventEl.unit_id === unitId)
      .filter((eventEl) => isHardEvent(eventEl) && !isPausedEvent(eventEl))
      .filter(
        (eventEl) =>
          eventEl.status === EventStatus.UNASSIGNED ||
          eventEl.status === EventStatus.ASSIGNED
      );

  if (organization && events) {
    criticalEvents = events.filter((eventEl) => isHardEvent(eventEl));
  }

  /* Effects */
  // Listens to removed childs from the community unresolved events node and marks them as solved
  useEffect(() => {
    if (selectedCommunityID !== undefined && firebaseAuth) {
      const removedEventsRef = getFirebaseDBReference().ref(
        `communities/${selectedCommunityID}/unresolved_events`
      );

      const listener = removedEventsRef.on("child_removed", (snapshot) => {
        const resolvedEvent = snapshot.val();
        if (resolvedEvent === null) return;
        dispatch(removeCommunityEvent(resolvedEvent.id));
      });
      return () => removedEventsRef.off("child_removed", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID, firebaseAuth]);

  // Listens to removed childs from the communities unresolved events node and marks them as solved
  useEffect(() => {
    if (
      selectedOrganizationID !== undefined &&
      orgsCommunities !== undefined &&
      firebaseAuth
    ) {
      const removedEventsRefArr = orgsCommunities.map((community) =>
        getFirebaseDBReference().ref(
          `communities/${community.id}/unresolved_events`
        )
      );
      const listenerArr = removedEventsRefArr.map((communityRef) =>
        communityRef.on("child_removed", (snapshot) => {
          const resolvedEvent = snapshot.val();
          if (resolvedEvent === null) return;
          dispatch(removeCommunityEvent(resolvedEvent.id));
        })
      );
      return () =>
        removedEventsRefArr.forEach((communityRef, index) =>
          communityRef.off("child_removed", listenerArr[index])
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationID, firebaseAuth]);

  const getItemSize = (index: number) => EVENT_CARD_HEIGHT + 5;

  const Row = <T extends { index: number; style: any }>(props: T) => {
    if (criticalEvents === undefined) return null;
    const criticalEvent = criticalEvents[props.index];
    return (
      <div style={props.style}>
        <NotificationEvent
          id={criticalEvent.id}
          unit_id={criticalEvent.unit_id}
          event_type={criticalEvent.event_type}
          responder_id={criticalEvent.responder_id}
          status={criticalEvent.status}
          data={criticalEvent.data}
          time_created={criticalEvent.time_created}
          notification_type={criticalEvent.notification_type}
          community_id={criticalEvent.community_id}
          read_status={criticalEvent.read_status}
          onClickOpenTab={addTab}
        />
      </div>
    );
  };

  return (
    <List
      height={height}
      itemCount={criticalEvents?.length || 0}
      itemSize={getItemSize}
      width={width}
    >
      {Row}
    </List>
  );
}

interface IProps {
  addTab: (id: string, type: TabTypes) => void;
  unitId: string | null;
  organization?: boolean;
  events?: IEvent[];
  height: number;
  width: number;
}
