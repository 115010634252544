import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ReducerStates,
  Temperature,
  TimeFormat,
} from "../../helpers/constants";
import { LookUpQueryString } from "../../i18n";
import { StorageKeys } from "../../services/constants";
import { getHeaderInformation } from "../header/headerThunks";
import { initialize, submitMyProfileChanges } from "./asyncThunkActions";

export interface IAppConfiguration {
  temperature: Temperature;
  timeFormat: TimeFormat;
}
export interface IAppState {
  state: ReducerStates;
  tokenObj: any;
  softNotificationToggle: boolean;
  softNotificationType: string;
  config: IAppConfiguration;
  appLogging: boolean;
  environment: string;
  version: string;
}

export const initialAppState: IAppState = {
  state: ReducerStates.IDLE,
  tokenObj: undefined,
  softNotificationToggle: false,
  softNotificationType: "",
  config: { temperature: Temperature.CELSIUS, timeFormat: TimeFormat.TIME12H },
  appLogging: process.env.REACT_APP_ENVIRONMENT !== "production",
  environment: "",
  version: "",
};

const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    finishedInit(state) {
      state.state = ReducerStates.IDLE;
    },
    showSoftNotification(state, action) {
      state.softNotificationToggle = true;
      if (state.softNotificationType === initialAppState.softNotificationType) {
        state.softNotificationType = action.payload;
      }
    },
    hideSoftNotification(state) {
      state.softNotificationToggle = false;
      state.softNotificationType = initialAppState.softNotificationType;
    },
    handledError(state) {
      state.softNotificationToggle = false;
      state.softNotificationType = initialAppState.softNotificationType;
    },
    toggleLogging(state) {
      state.appLogging = !state.appLogging;
    },
    setEnv(state, action: PayloadAction<string>) {
      state.environment = action.payload;
      localStorage.setItem(StorageKeys.ENV, action.payload);
    },
    setVer(state, action: PayloadAction<string>) {
      state.version = action.payload;
      if (action.payload === "") {
        localStorage.removeItem(StorageKeys.VERSION);
      } else {
        localStorage.setItem(StorageKeys.VERSION, action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        state.state = ReducerStates.PENDING;
      })
      .addCase(initialize.fulfilled, (state, action) => {
        if (action.payload.userToken !== null) {
          state.tokenObj = action.payload.userToken;
        }
        sessionStorage.removeItem(StorageKeys.APP_LOG);
        state.state = ReducerStates.SUCCEEDED;
        if (action.payload.env !== null) {
          state.environment = action.payload.env;
        }
        if (action.payload.version !== null) {
          state.version = action.payload.version;
        }
      })
      .addCase(getHeaderInformation.fulfilled, (state, action) => {
        const language = action.payload.userDetails?.language;
        const temperatureUnit = action.payload.userDetails?.temperature_unit;
        const timeFormat = action.payload.userDetails?.is_twenty_four_time;

        if (language !== null) {
          localStorage.setItem(LookUpQueryString, language);
        }

        if (temperatureUnit !== null)
          state.config.temperature = temperatureUnit as Temperature;

        if (timeFormat !== null)
          state.config.timeFormat = timeFormat
            ? TimeFormat.TIME24H
            : TimeFormat.TIME12H;

        if (temperatureUnit === null)
          state.config.temperature = Temperature.FAHRENHEIT;
        if (timeFormat === null) state.config.timeFormat = TimeFormat.TIME12H;

        localStorage.setItem(
          StorageKeys.USER_ID,
          action.payload.userDetails.id
        );
      })
      .addCase(submitMyProfileChanges.fulfilled, (state, action) => {
        const temperatureUnit = action.payload.temperature_unit;
        const timeFormat = action.payload.is_twenty_four_time;

        if (temperatureUnit !== null)
          state.config.temperature = temperatureUnit as Temperature;

        if (timeFormat !== null)
          state.config.timeFormat = timeFormat
            ? TimeFormat.TIME24H
            : TimeFormat.TIME12H;

        state.state = ReducerStates.SUCCEEDED;
      });
  },
});

const { reducer } = appSlice;

// Destructure and export the plain action creators
export const {
  finishedInit,
  showSoftNotification,
  hideSoftNotification,
  handledError,
  toggleLogging,
  setEnv,
  setVer,
} = appSlice.actions;

export default reducer;
