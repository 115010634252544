import { DateTime } from "luxon";
import { DateTimeNow } from "../../helpers/datetime";

import { setCustomPan } from "../analytics/analyticsSlice";
import { useAppDispatch } from "../app/appHooks";

function usePan(mode: string, saveAsDate: boolean, panEnabed?: boolean) {
  /* Hooks */
  const dispatch = useAppDispatch();

  const panOptions = {
    enabled: !!panEnabed,
    mode: mode,
    onPanComplete: ({ chart }: { chart: any }) => {
      let localStart = DateTime.fromMillis(chart.scales[mode].min).toUTC();
      let localEnd = DateTime.fromMillis(chart.scales[mode].max).toUTC();

      if (saveAsDate) {
        const current = DateTimeNow().toUTC();
        if (current.toISODate() !== localEnd.toISODate()) {
          localEnd = localEnd.plus({ day: 1 });
        }
        localStart = localEnd.minus({ weeks: 2 });
      }

      dispatch(
        setCustomPan({
          localStart: saveAsDate ? localStart.toISODate() : localStart.toISO(),
          localEnd: saveAsDate ? localEnd.toISODate() : localEnd.toISO(),
        })
      );
    },
  };
  return panOptions;
}

export default usePan;
