// CommunityList.tsx
import React from "react";
import { List, ListItem, Typography } from "@material-ui/core";
import { GreenCheckbox } from "../notifications/filters/GreenCheckBox";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ICommunities } from "../../services/header.services";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      transform: "scale(0.85)", // Adjust the scale value as needed
      marginRight: theme.spacing(1), // Space between checkbox and text
    },
    communityItem: {
      padding: theme.spacing(0.1, 1),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    communityName: {
      fontSize: "0.95rem",
    },
  })
);

export const CommunitiesFilterList = (props: IProps) => {
  const { communities, selectedCommunities, onSelectCommunity } = props;
  const classes = useStyles();

  return (
    <List>
      {communities &&
        communities.map((community) => (
          <ListItem key={community.id} className={classes.communityItem}>
            <div className={classes.checkboxContainer}>
              <GreenCheckbox
                edge="start"
                checked={selectedCommunities.includes(community.id)}
                tabIndex={-1}
                disableRipple
                onChange={() => onSelectCommunity(community.id)}
              />
            </div>
            <Typography className={classes.communityName}>
              {community.name}
            </Typography>
          </ListItem>
        ))}
    </List>
  );
};

interface IProps {
  communities: ICommunities[] | undefined;
  selectedCommunities: string[];
  onSelectCommunity: (id: string) => void;
}
