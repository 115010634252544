import { Grid, ListItem } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { GreenCheckbox } from "../notifications/filters/GreenCheckBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unitItem: {
      display: "flex",
      backgroundColor: theme.palette.light.light,
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(1, 1.5),
      borderRadius: "5px",
      border: `1px solid #D6D6D6`,
      paddingRight: 30,
      alignItems: "flex-start",
    },
    titleContainer: {
      display: "flex",
      gap: theme.spacing(2),
      fontSize: theme.typography.body1.fontSize,
      alignItems: "center",
      overflow: "hidden",
    },
    secondaryText: {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      flex: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    enabledCheckbox: {
      paddingRight: theme.spacing(1),
      padding: 0,
    },
    firstSection: {
      flexBasis: "30%",
      fontWeight: "bold",
      display: "inline-flex",
      minWidth: 0,
    },
    formContainer: {
      display: "flex",
      flexBasis: "70%",
      minWidth: 0,
      "&>*": {
        alignItems: "center",
      },
    },
    column: {
      display: "flex",
      flexDirection: "column",
      "&>*": {
        paddingBottom: theme.spacing(1),
      },
      "&>:last-child": {
        paddingBottom: 0,
      },
    },
    listRoot: {
      "&selected:hover": {
        backgroundColor: theme.palette.primary.light,
        color: "inherit",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: "inherit",
      },
    },
    itemContainer: {
      width: "100%",
      display: "flex",
    },
    itemLabel: {
      minWidth: 0,
      whiteSpace: "nowrap",

      fontSize: theme.typography.caption.fontSize,
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    itemValue: {
      fontSize: theme.typography.body2.fontSize,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
  })
);

export const UnitGroupItem = (props: IProps) => {
  const {
    id,
    index,
    expanded,
    primaryText,
    secondaryText,
    primaryGroup,
    escalationGroup,
    selected,
    toggleSelected,
    setSize,
  } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* Refs */
  const rowRef = useRef<HTMLDivElement | null>(null);

  /* Effects */
  //Store size of element for variable size list and windowing
  useEffect(() => {
    if (rowRef.current === null) return;
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [setSize, index]);

  let expandedProps = {};
  if (expanded)
    expandedProps = {
      ...expandedProps,
      button: true,
      onClick: () => toggleSelected(id),
    };
  return (
    <ListItem
      id={id}
      classes={{ root: classes.listRoot }}
      className={classes.unitItem}
      ref={rowRef}
      component={"div"}
      {...expandedProps}
    >
      <span className={classes.firstSection}>
        {expanded && (
          <GreenCheckbox
            readOnly={true}
            checked={selected}
            tabIndex={-1}
            disableRipple
            classes={{ root: classes.enabledCheckbox }}
          />
        )}
        <span className={classes.titleContainer}>
          <span>{primaryText}</span>
          <span className={classes.secondaryText}>{secondaryText}</span>
        </span>
      </span>
      <div className={classes.formContainer}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <div className={classes.itemContainer}>
              <div className={classes.itemLabel}>
                {t("primary_groups").toUpperCase()}
                <span className={classes.itemValue}>{primaryGroup}</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.itemContainer}>
              <div className={classes.itemLabel}>
                {t("escalation_groups").toUpperCase()}
                <span className={classes.itemValue}>{escalationGroup}</span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </ListItem>
  );
};

interface IProps {
  id: string;
  index: number;
  expanded: boolean;
  primaryText: string;
  secondaryText: string;
  primaryGroup: string;
  escalationGroup: string;
  selected: boolean;
  toggleSelected: (optionID: string) => void;
  setSize: (index: number, size: number) => void;
}
