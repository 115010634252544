import { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/appHooks";
import { NotificationGroupTemplateForm } from "./NotificationGroupTemplateForm";
import { UnitGroupConfigList } from "./UnitGroupConfigList";
import { ICommunityGroup } from "../../services/header.services";
import { sortAlphabetical } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    titleContainer: {
      padding: theme.spacing(2, 0),
    },
    title: {
      fontFamily: theme.typography.secondaryFontFamily,
    },
    templateContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: theme.spacing(2),
    },
  })
);

export const NotificationGroupConfig = () => {
  /* Hooks */
  const { t } = useTranslation();

  /* State */
  const [heightList, setHeightList] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();

  /* Selectors */
  const selectedEventType = useAppSelector(
    (state) => state.settingsState.selectedEventType
  );

  const communityGroups = useAppSelector((state) => {
    if (state.headerState.communityGroups !== undefined) {
      return JSON.parse(
        JSON.stringify(state.headerState.communityGroups)
      ) as ICommunityGroup[];
    }
    return state.headerState.communityGroups;
  })?.sort((a, b) => sortAlphabetical(a.name, b.name));

  /* Refs */
  const ref = useRef<HTMLDivElement | null>(null);
  const templateRef = useRef<HTMLDivElement | null>(null);

  /* Effects */
  useEffect(() => {
    // Set unit list height (if it expands, should be less)
    setTimeout(() => {
      // do something 1 sec after clicked has changed
      const parentEl = ref.current;
      const parentHeight = parentEl?.clientHeight;
      const templateHeight = templateRef.current?.clientHeight;

      if (parentHeight && templateHeight) {
        setHeightList(parentHeight - templateHeight);
      }
    }, 300);
  }, [selectedEventType, expanded]);

  const templateTitle = `${t("event_template", {
    eventType: t("notification_groups"),
  })} `;

  return (
    <div
      id="notification-group-config-content"
      ref={ref}
      className={classes.contentContainer}
    >
      <div
        ref={templateRef}
        id="template-container"
        className={classes.templateContainer}
      >
        <div className={classes.titleContainer}>
          <Typography className={classes.title} component="span">
            {templateTitle}
          </Typography>
        </div>

        <NotificationGroupTemplateForm
          expanded={expanded}
          setExpanded={setExpanded}
          communityGroups={communityGroups}
        />
      </div>
      {heightList !== 0 && (
        <UnitGroupConfigList
          expanded={expanded}
          height={heightList}
          communityGroups={communityGroups}
        />
      )}
    </div>
  );
};
