// NotificationStates.tsx
import React from "react";
import { List, ListItem, Typography } from "@material-ui/core";
import { GreenCheckbox } from "../notifications/filters/GreenCheckBox";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EventStatus } from "../../helpers/constants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      transform: "scale(0.85)", // Adjust the scale value as needed
      marginRight: theme.spacing(1), // Space between checkbox and text
    },
    stateItem: {
      padding: theme.spacing(0.1, 1),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    stateName: {
      fontSize: "0.95rem",
    },
  })
);

export const NotificationStatesFilterList = (props: IProps) => {
  const { selectedStates, onSelectState } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const notificationStates = Object.values(EventStatus).map((status) => ({
    id: status,
    name: t(status),
  }));

  return (
    <List>
      {notificationStates.map((state) => (
        <ListItem key={state.id} className={classes.stateItem}>
          <div className={classes.checkboxContainer}>
            <GreenCheckbox
              edge="start"
              checked={selectedStates.includes(state.id)}
              tabIndex={-1}
              disableRipple
              onChange={() => onSelectState(state.id)}
            />
          </div>
          <Typography className={classes.stateName}>{state.name}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

interface IProps {
  selectedStates: string[];
  onSelectState: (id: string) => void;
}
