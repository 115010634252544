import { Grid, Input, Popover } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactTypes } from "../../../helpers/constants";
import { labelByContactType } from "../../dashboard/eventDetails/ResidentContacts";
import { DropdownInput } from "../common/DropdownInput";
import { GenericPicker } from "../common/GenericPicker";
import {
  PhoneNumberPicker,
  UnitPhoneNumber,
} from "../common/PhoneNumberPicker";
import { ContactFormState, FormFieldIDs } from "../UpdateResidentContacts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1em .5em 0",
        flex: " 1",
        textAlign: "right",
        color: theme.palette.paused.main,
      },
    },
    inputWidth: {
      width: "100%",
    },
    phoneNumbersContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },

    itemRowContainer: {
      display: "flex",
      alignItems: "baseline",
      margin: theme.spacing(1, 0),
    },
    itemRowLabel: {
      paddingRight: theme.spacing(2),
      minWidth: "120px",
    },
    itemRowContent: {
      overflowWrap: "anywhere",
      fontSize: "1rem",
    },
  })
);

const ItemRow = (props: { label: string; children: React.ReactNode }) => {
  const { label, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.itemRowContainer}>
      <div className={classes.itemRowLabel}>{label.toUpperCase()}</div>

      <div className={classes.itemRowContent}>{children}</div>
    </div>
  );
};
export const EditResidentContactForm = (props: IProps) => {
  const { values, defaultRegionCode, updateValue, updatePhone } = props;
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* State */
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [popoverID, setPopoverID] = useState("");

  const [formError, setFormError] = useState({
    [FormFieldIDs.firstName]: "",
    [FormFieldIDs.lastName]: "",
  });

  /* Methods */
  const handleOpenFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(id);
  };

  const handleChange = (value: string, formField: FormFieldIDs) => {
    updateValue(value, formField);
    setAnchorEl(null);
  };

  const handlePhoneChange = (
    value: UnitPhoneNumber,
    formField: FormFieldIDs
  ) => {
    updatePhone(value, formField);
    setAnchorEl(null);
  };

  /* List values */
  const contactTypeList = Object.values(ContactTypes)
    .map((val) => ({
      id: val,
      label: labelByContactType(val),
    }))
    .filter(
      (contactType) =>
        !(
          contactType.id === ContactTypes.USER ||
          contactType.id === ContactTypes.CAREGIVER
        )
    );

  // Validate if required field has value
  const validateNotEmpty = (id: FormFieldIDs) => {
    if (values[id] === null || values[id] === "") {
      setFormError((prevVal) => ({ ...prevVal, [id]: "requiredVal" }));
    } else {
      setFormError((prevVal) => ({ ...prevVal, [id]: "" }));
    }
  };

  return (
    <>
      <form
        id="add-new-user-form"
        noValidate
        autoComplete="off"
        className={classes.formContainer}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <ItemRow label={t("first_name")}>
              <Input
                id={FormFieldIDs.firstName}
                type={"text"}
                className={classes.inputWidth}
                value={values[FormFieldIDs.firstName] ?? ""}
                onChange={(e) => {
                  handleChange(e.currentTarget.value, FormFieldIDs.firstName);
                }}
                error={
                  formError[FormFieldIDs.firstName] !== "" &&
                  formError[FormFieldIDs.firstName] !== null
                }
                onBlur={() => validateNotEmpty(FormFieldIDs.firstName)}
              />
            </ItemRow>
            <ItemRow label={t("last_name")}>
              <Input
                id={FormFieldIDs.lastName}
                type={"text"}
                className={classes.inputWidth}
                value={values[FormFieldIDs.lastName] ?? ""}
                onChange={(e) => {
                  handleChange(e.currentTarget.value, FormFieldIDs.lastName);
                }}
                error={
                  formError[FormFieldIDs.lastName] !== "" &&
                  formError[FormFieldIDs.lastName] !== null
                }
                onBlur={() => validateNotEmpty(FormFieldIDs.lastName)}
              />
            </ItemRow>
            <ItemRow label={t("contact_type")}>
              <DropdownInput
                id={FormFieldIDs.contactType}
                value={
                  values[FormFieldIDs.contactType]
                    ? t(
                        labelByContactType(
                          values[FormFieldIDs.contactType] as string
                        )
                      )
                    : ""
                }
                handleClick={handleOpenFilter}
              />
            </ItemRow>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.phoneNumbersContainer}>
              <PhoneNumberPicker
                fieldID={FormFieldIDs.phoneNumberOne}
                labelComplement={"1."}
                defaultCountryCode={defaultRegionCode}
                formFull={false}
                phoneNumber={values[FormFieldIDs.phoneNumberOne]}
                onChange={(value: UnitPhoneNumber) =>
                  handlePhoneChange(value, FormFieldIDs.phoneNumberOne)
                }
              />
              <PhoneNumberPicker
                fieldID={FormFieldIDs.phoneNumberTwo}
                labelComplement={"2."}
                defaultCountryCode={defaultRegionCode}
                formFull={false}
                phoneNumber={values[FormFieldIDs.phoneNumberTwo]}
                onChange={(value: UnitPhoneNumber) =>
                  handlePhoneChange(value, FormFieldIDs.phoneNumberTwo)
                }
              />
            </div>
          </Grid>
        </Grid>
      </form>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1200 }}
      >
        <>
          {popoverID === FormFieldIDs.contactType && (
            <GenericPicker
              selectedValue={values["contact-type"] ?? null}
              optionList={contactTypeList}
              localize
              handleChange={(option) =>
                handleChange(option, FormFieldIDs.contactType)
              }
            />
          )}
          {popoverID === "" && null}
        </>
      </Popover>
    </>
  );
};

interface IProps {
  values: ContactFormState;
  defaultRegionCode: string;
  updateValue: (value: string, formField: FormFieldIDs) => void;
  updatePhone: (value: UnitPhoneNumber, formField: FormFieldIDs) => void;
}
