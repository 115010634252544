import React, { useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import CommunityUnit from "./CommunityUnit";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import { ReducerStates, RoleTypes, TabTypes } from "../../helpers/constants";
import { getCommunityData } from "./dashboardSlice";
import { IEnhancedUnit as IUnit } from "./dashboardSlice";
import { tabBarHeight, trayHeight } from "./Dashboard";
import clsx from "clsx";
import { minToolbarHeight } from "../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    unitContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      position: "relative",
      zIndex: 2,
      alignContent: "flex-start",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      overflowY: "auto",
      padding: theme.spacing(2, 2),
    },
    // FIX - add tray height to theme mixins
    openTrayHeight: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)} - ${trayHeight})`,
      paddingBottom: tabBarHeight,
    },
    minimizedTrayHeight: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)} )`,
      paddingBottom: tabBarHeight,
    },
  })
);

const completeRows = (
  length: number,
  isLG: boolean,
  showAddUnitTile: boolean,
  isInstaller: boolean
): IUnit[] => {
  let unitTile: IUnit = {
    community_id: "",
    custom_name: null,
    id: "",
    is_notifications_paused: false,
    is_occupied: null,
    is_watched: true,
    last_motion_time: "",
    name: "",
    residents: [],
    site_id: null,
    unit_number: 0,
    unit_type: "",
    notVisible: true,
    time_updated: "",
    time_created: "",
    security_notification_enabled: null,
    pause_notifications_expiration: null,
    time_zone: "",
  };
  const lastUnit: IUnit = {
    community_id: "",
    custom_name: null,
    id: "",
    is_notifications_paused: false,
    is_occupied: null,
    is_watched: false,
    last_motion_time: "",
    name: "",
    residents: [],
    site_id: null,
    unit_number: 0,
    unit_type: "add_unit",
    notVisible: false,
    time_updated: "",
    time_created: "",
    security_notification_enabled: null,
    pause_notifications_expiration: null,
    time_zone: "",
  };
  let units = [];

  let newLength = length;
  if (showAddUnitTile) {
    units.push(lastUnit);
    newLength = length + 1;
  }

  if (!isLG) {
    if (newLength % 5 !== 0) {
      for (let i = 0; i < 5 - (newLength % 5); i++) {
        units.push(unitTile);
      }
    }
  } else {
    if (newLength % 10 !== 0) {
      for (let i = 0; i < 10 - (newLength % 10); i++) {
        units.push(unitTile);
      }
    }
  }

  return units;
};

export default function UnitTiles(props: IProps) {
  const { addTab, openTray, minimizedTray } = props;
  const showBanner = useSelector(
    (state: IAppState) => state.headerState.showBanner
  );

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const theme = useTheme();
  const dispatch = useDispatch();

  /* ---- State ---- */

  const [units, setUnits] = useState<IUnit[] | undefined>(undefined);
  const isLG = useMediaQuery(theme.breakpoints.up("lg"));

  /* ---- Selectors ---- */

  const dashboardStatus = useSelector(
    (state: IAppState) => state.dashboardState.state
  );

  const headerStatus = useSelector(
    (state: IAppState) => state.headerState.state
  );

  const selectedCommunity = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity
  );

  const unitsState = useSelector(
    (state: IAppState) => state.dashboardState.units
  );

  // Determine the role of the user
  const isCommunityAdmin = !!selectedCommunity?.role.includes(RoleTypes.admin);
  let isOrgAdmin = false;
  const isInstaller = !!selectedCommunity?.role.includes(RoleTypes.installer);
  if (selectedCommunity) {
    isOrgAdmin = selectedCommunity.role.includes(RoleTypes.org);
  }

  const showAddUnitTile =
    selectedCommunity !== undefined
      ? isOrgAdmin || isCommunityAdmin || isInstaller
      : false;

  /* ---- Effects ---- */
  useEffect(() => {
    if (
      dashboardStatus === ReducerStates.IDLE &&
      headerStatus === ReducerStates.SUCCEEDED
    ) {
      if (selectedCommunity !== undefined) {
        dispatch(getCommunityData(selectedCommunity.id));
      }
    }
  }, [dashboardStatus, headerStatus, selectedCommunity, dispatch]);

  // When we have new units from the server, complete rows so it always displays 5 or 10 per row
  useEffect(() => {
    if (dashboardStatus === ReducerStates.SUCCEEDED) {
      if (unitsState && unitsState.length > 0) {
        const completeUnitRows = completeRows(
          unitsState.length,
          isLG,
          showAddUnitTile,
          isInstaller
        );
        const tempUnits: IUnit[] = unitsState.concat(completeUnitRows);
        setUnits(tempUnits);
      }
    }
  }, [dashboardStatus, isLG, showAddUnitTile, isInstaller, unitsState]);

  // Clean units state if we have to reload the dashboard
  useEffect(() => {
    if (dashboardStatus === ReducerStates.IDLE) {
      setUnits(undefined);
    }
  }, [dashboardStatus]);

  return (
    <div
      className={clsx(
        classes.unitContainer,
        openTray && classes.openTrayHeight,
        minimizedTray && classes.minimizedTrayHeight
      )}
      id="unit-container"
    >
      {units &&
        units.map((unit, index) => {
          return (
            <CommunityUnit key={index} unit={unit} onClickOpenTab={addTab} />
          );
        })}
    </div>
  );
}

interface IProps {
  openTray: boolean;
  minimizedTray: boolean;
  addTab: (id: string, type: TabTypes) => void;
}
