import React, { forwardRef, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    iconColor: {
      color: "inherit",
    },
    listItem: {
      margin: theme.spacing(1, 0),
    },
    badge: {
      justifyContent: "center",
      alignItems: "center",
      minHeight: "20px",
      minWidth: "22px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10px",
      padding: "3px",
      paddingBottom: 1,
      textAlign: "center",
      color: theme.palette.light.light,
      fontWeight: "bold",
      fontSize: theme.typography.caption.fontSize,
      display: "inline-table",
    },
    listButton: {
      "&:hover": {
        "& $badge": {
          backgroundColor: theme.palette.light.light,
          color: theme.palette.primary.main,
        },
      },
    },
    preventOverflow: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
  })
);

export default function ListItemLink(props: IProps) {
  const {
    icon,
    primary,
    to,
    selected,
    disabled,
    visible,
    badge,
    action,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const handleClick = () => {
    if (action) {
      dispatch(action());
    }
  };

  return (
    <React.Fragment>
      {visible ? (
        <li className={classes.listItem}>
          <ListItem
            selected={selected}
            button
            component={renderLink}
            disabled={disabled}
            onClick={handleClick}
            classes={{ button: classes.listButton }}
          >
            {icon ? (
              <ListItemIcon className={classes.iconColor}>{icon}</ListItemIcon>
            ) : null}
            <ListItemText
              primaryTypographyProps={{ variant: "h6" }}
              primary={primary}
              classes={{ primary: classes.preventOverflow }}
            />
            {badge ? <div className={classes.badge}>{badge}</div> : null}
          </ListItem>
        </li>
      ) : null}
    </React.Fragment>
  );
}

interface IProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  selected: boolean;
  disabled: boolean;
  visible: boolean;
  badge: string | undefined;
  action?: () => void;
}
