import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/appHooks";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { getUnitTitle } from "../dashboard/CommunityUnit";
import { Views } from "../../helpers/constants";
import { AnalyticsViewerCharts } from "./AnalyticsViewerCharts";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import Icons from "../../helpers/iconImports";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      marginBottom: 0,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1),
      textAlign: "center",
      fontSize: theme.typography.body1.fontSize,
      fontWeight: "bold",
    },
    lastUpdated: {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.paused.light,
      paddingRight: theme.spacing(1.5),
    },
    backButtonContainer: {
      width: "100%",
      textAlign: "left",
      paddingTop: "10px",
      paddingLeft: theme.spacing(1.5),
    },
    backButton: {
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    printReportContainer: {
      width: "100%",
      textAlign: "right",
      paddingTop: "6px",
      paddingRight: theme.spacing(2),
    },
    printReportButton: {
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    buttons: {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: 1,
    },
    icon: {
      width: "80%",
    },
    [`@media print`]: {
      sectionTitle: {
        display: "none",
      },
      printReportButton: {
        display: "none",
      },
      backButton: {
        display: "none",
      },
    },
  })
);

export const LandscapeOrientation = () => (
  <style type="text/css">{"@media print{@page {size: landscape}}"}</style>
);

const printReport = () => {
  window.print();
};

export function AnalyticsPrintableViewer(props: IProps) {
  const { containerRef } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  /* Selectors */
  const selectedUnit = useAppSelector((state) =>
    state.analyticsState.units?.find(
      (unit) => unit.id === state.analyticsState.selectedUnit
    )
  );

  const displayCharts = useAppSelector(
    (state) => !!state.analyticsState.selectedUnitData
  );

  const chartDataLastUpdated = useAppSelector(
    (state) => state.analyticsState.chartDataLastUpdated
  );

  let listItemText = undefined;
  if (selectedUnit) listItemText = getUnitTitle(selectedUnit);

  const viewerHeight = containerRef.current?.clientHeight;
  const [cardHeight] = useState((viewerHeight ?? 800) * 0.438);

  const analyticsRedirect = () => {
    history.push(Views.ANALYTICS);
  };

  useEffect(() => {
    if (selectedUnit === undefined) {
      analyticsRedirect();
    }
  });

  return (
    <React.Fragment>
      {displayCharts !== undefined && (
        <React.Fragment>
          <Grid container>
            <Grid item xs={2} className={classes.backButtonContainer}>
              {selectedUnit && (
                <Button
                  size="small"
                  className={classes.backButton}
                  classes={{ label: classes.buttons }}
                  onClick={analyticsRedirect}
                >
                  {t("action_back")}
                </Button>
              )}
            </Grid>
            <Grid item xs={8}>
              {listItemText && (
                <div className={classes.sectionTitle}>
                  {`${listItemText.unitTitle}${
                    listItemText.unitSubtitle !== ""
                      ? ` - ${listItemText.unitSubtitle}`
                      : ""
                  }`}
                </div>
              )}
            </Grid>
            <Grid item xs={2} className={classes.printReportContainer}>
              {selectedUnit && (
                <Button
                  size="small"
                  className={classes.printReportButton}
                  classes={{ label: classes.buttons }}
                  endIcon={
                    <img
                      src={Icons.PrintReportIcon}
                      alt={""}
                      draggable={false}
                      className={classes.icon}
                    />
                  }
                  onClick={printReport}
                >
                  {t("print_report")}
                </Button>
              )}
            </Grid>
          </Grid>
          <AnalyticsViewerCharts
            cardHeight={cardHeight}
            lastUpdated={chartDataLastUpdated}
            listItemText={listItemText}
            isPrintable={true}
          />
        </React.Fragment>
      )}
      {LandscapeOrientation()}
    </React.Fragment>
  );
}

interface IProps {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}
