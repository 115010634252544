import React, { useEffect, useState } from "react";
import UnitTiles from "./UnitTiles";
import Organization from "./organizations/Organization";
import Notifications from "./activeNotificationPanel/Notifications";
import { TabTray } from "./tabTray/TabTray";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide/Slide";
import {
  DashboardViewTypes,
  ReducerStates,
  TabTypes,
} from "../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import {
  addTab,
  changeFocus,
  closeTab,
  closeTabTray,
  maximizeTabTray,
  minimizeTabTray,
} from "./dashboardSlice";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { minToolbarHeight } from "../app/App";
import AllNotifications from "./AllNotifications";

export const trayHeight = "313px";
export const tabBarHeight = "67px";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    dashboardContainer: {
      display: "flex",
    },
    mainContent: {
      flex: "4",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        flex: "3",
      },
      minHeight: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    noCommunitiesContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    unitTileContainer: {
      display: "flex",
    },
    notificationsContainer: {
      display: "flex",
      flex: "0 1 370px",
      height: "100%",
    },
    trayContainer: {
      display: "flex",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
    },
    openedTray: {
      height: trayHeight,
      zIndex: 3,
    },
    minimizedTray: {
      zIndex: 3,
    },
    closedTray: {
      display: "none",
    },
  })
);

export default function Dashboard() {
  const showBanner = useSelector(
    (state: IAppState) => state.headerState.showBanner
  );

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* ---- State ---- */
  const [dashboardViewType, setDashboardViewType] = useState<
    string | undefined
  >(undefined);

  /* ---- Selectors ---- */
  const openTray = useSelector(
    (state: IAppState) => state.dashboardState.openTray
  );
  const minimizedTray = useSelector(
    (state: IAppState) => state.dashboardState.minimizedTray
  );
  const selectedTab = useSelector(
    (state: IAppState) => state.dashboardState.selectedTab
  );
  const tabs = useSelector((state: IAppState) => state.dashboardState.tabs);

  const communities = useSelector(
    (state: IAppState) => state.headerState.communities
  );

  const headerState = useSelector(
    (state: IAppState) => state.headerState.state
  );

  const selectedCommunity = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity
  );

  const selectedOrganization = useSelector(
    (state: IAppState) => state.headerState.selectedOrganization
  );

  const allNotifications = useSelector(
    (state: IAppState) => state.headerState.allNotifications
  );

  const validUserRole = useSelector(
    (state: IAppState) => state.headerState.validUserRole
  );

  const installerRole = useSelector(
    (state: IAppState) => state.headerState.installerRole
  );

  /* ---- Methods ---- */
  // Add tab to tab tray, tabs can be created by clicking an event or a unit
  const handleAddTab = (id: string, type: TabTypes) => {
    dispatch(addTab({ id, type }));
  };

  const handleCloseTab = (index: number) => {
    dispatch(closeTab(index));
  };

  const handleChangeFocus = (index?: number) => {
    dispatch(changeFocus(index));
  };

  const handleMinimizeTabTray = () => {
    dispatch(minimizeTabTray());
  };

  const handleMaximizeTabTray = () => {
    dispatch(maximizeTabTray());
  };

  const handleCloseTabTray = () => {
    dispatch(closeTabTray());
  };

  /* ---- Effects ---- */
  useEffect(() => {
    if (allNotifications) {
      setDashboardViewType(DashboardViewTypes.ALL_NOTIFICATIONS);
    } else if (selectedOrganization !== undefined) {
      setDashboardViewType(DashboardViewTypes.ORGANIZATION);
    } else if (selectedCommunity !== undefined) {
      setDashboardViewType(DashboardViewTypes.COMMUNITY);
    }
  }, [
    allNotifications,
    selectedCommunity,
    selectedOrganization,
    setDashboardViewType,
  ]);

  const showEmptyMessage =
    (headerState === ReducerStates.SUCCEEDED ||
      headerState === ReducerStates.FAILED) &&
    (!validUserRole || communities === undefined || communities.length === 0);

  return (
    <div className={classes.dashboardContainer}>
      {validUserRole && communities && communities.length > 0 && (
        <React.Fragment>
          <div className={classes.mainContent}>
            {dashboardViewType === DashboardViewTypes.ORGANIZATION && (
              <div className={classes.unitTileContainer}>
                <Organization
                  addTab={handleAddTab}
                  openTray={openTray}
                  minimizedTray={minimizedTray}
                />
              </div>
            )}
            {dashboardViewType === DashboardViewTypes.COMMUNITY && (
              <div className={classes.unitTileContainer}>
                <UnitTiles
                  addTab={handleAddTab}
                  openTray={openTray}
                  minimizedTray={minimizedTray}
                />
              </div>
            )}
            {dashboardViewType === DashboardViewTypes.ALL_NOTIFICATIONS && (
              <div className={classes.unitTileContainer}>
                <AllNotifications
                  addTab={handleAddTab}
                  openTray={openTray}
                  minimizedTray={minimizedTray}
                />
              </div>
            )}
            <div
              className={`${classes.trayContainer} ${
                openTray
                  ? classes.openedTray
                  : minimizedTray &&
                    tabs !== undefined &&
                    selectedTab !== undefined
                  ? classes.minimizedTray
                  : classes.closedTray
              }`}
            >
              {openTray && (
                <Slide direction="up" in={openTray && !minimizedTray}>
                  <TabTray
                    openTray={openTray}
                    minimizedTray={minimizedTray}
                    tabs={tabs}
                    selectedTab={selectedTab}
                    handleCloseTab={handleCloseTab}
                    handleChangeFocus={handleChangeFocus}
                    handleMaximizeTabTray={handleMaximizeTabTray}
                    handleMinimizeTabTray={handleMinimizeTabTray}
                    handleCloseTabTray={handleCloseTabTray}
                  />
                </Slide>
              )}
              {minimizedTray && (
                <Slide direction="up" in={minimizedTray && !openTray}>
                  <TabTray
                    openTray={openTray}
                    minimizedTray={minimizedTray}
                    tabs={tabs}
                    selectedTab={selectedTab}
                    handleCloseTab={handleCloseTab}
                    handleChangeFocus={handleChangeFocus}
                    handleMaximizeTabTray={handleMaximizeTabTray}
                    handleMinimizeTabTray={handleMinimizeTabTray}
                    handleCloseTabTray={handleCloseTabTray}
                  />
                </Slide>
              )}
            </div>
          </div>

          {!installerRole &&
            dashboardViewType !== DashboardViewTypes.ALL_NOTIFICATIONS && (
              <div className={classes.notificationsContainer}>
                <Notifications addTab={handleAddTab} />
              </div>
            )}
        </React.Fragment>
      )}
      {showEmptyMessage && (
        <Box className={classes.noCommunitiesContent}>
          <h3>{t("no_communities_contact_admin")}</h3>
        </Box>
      )}
    </div>
  );
}
