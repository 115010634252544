import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAppState } from "../../../helpers/store";
import SectionHeader from "../charts/SectionHeader";
import UnitDashboardItem from "./UnitDashboardItem";
import {
  celsiusToFahrenheit,
  DashboardItemTypes,
  Temperature,
  temperatureConfig,
  truncate,
  Categories,
} from "../../../helpers/constants";
import { Box, capitalize } from "@material-ui/core";
import {
  DateRelative,
  OptionalTimeZoneString,
  TimeShortFormatWithoutTZ,
} from "../../../helpers/datetime";
import ResidentContacts from "../eventDetails/ResidentContacts";
import { useTheme } from "@material-ui/core";

const DASH = "-";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      width: "99%",
      display: "flex",
      flexWrap: "wrap",
    },
    rootContainer: {
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      overflowX: "hidden",
    },
  })
);

export default function UnitDashboard(props: IProps) {
  const { unitId, communityUnitTimezone } = props;
  const dashboard = useSelector((state: IAppState) => {
    return state.dashboardState.unitDashboard[unitId];
  });

  const unit = useSelector((state: IAppState) => {
    return state.dashboardState.units?.find((unit) => unit.id === unitId);
  });

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  //sleep
  let sleepLabel: string = DASH;
  let sleepSub: string = "";
  const sleepDataDuration = dashboard?.sleep_data?.duration;
  if (sleepDataDuration) {
    const minutes = sleepDataDuration / 60000;
    sleepLabel = truncate(minutes / 60.0);
    sleepSub = t("hours");
  }

  //activity
  let activityLabel: string = DASH;
  let activitySub: string = "";
  const motionDataLastMotion = dashboard?.motion_data?.last_motion_time;
  if (motionDataLastMotion) {
    activityLabel = TimeShortFormatWithoutTZ(
      motionDataLastMotion,
      communityUnitTimezone
    );
    const optionalTimezone = OptionalTimeZoneString(
      motionDataLastMotion,
      communityUnitTimezone
    );
    activitySub = `${
      optionalTimezone ? `${optionalTimezone}, ` : ""
    }${DateRelative(motionDataLastMotion)}`;
  }

  //kitchen activity
  let kitchenLabel: string = DASH;
  let kitchenSub: string = "";
  const kitchenDataLastMotion = dashboard?.kitchen_data?.last_motion_event_time;
  if (kitchenDataLastMotion) {
    kitchenLabel = TimeShortFormatWithoutTZ(
      kitchenDataLastMotion,
      communityUnitTimezone
    );
    const optionalTimezone = OptionalTimeZoneString(
      kitchenDataLastMotion,
      communityUnitTimezone
    );
    kitchenSub = `${
      optionalTimezone ? `${optionalTimezone}, ` : ""
    }${DateRelative(kitchenDataLastMotion)}`;
  }

  //temperature
  let tempLabel = DASH;
  let tempSub = "";
  let tempColor = theme.palette.metrics.main;
  const heatDataTemperatureCelsius = dashboard?.heat_index_data?.celsius;
  if (heatDataTemperatureCelsius) {
    const tempScale = temperatureConfig();
    tempLabel = `${
      tempScale === Temperature.FAHRENHEIT
        ? truncate(celsiusToFahrenheit(heatDataTemperatureCelsius))
        : truncate(heatDataTemperatureCelsius)
    }°`;
    tempSub =
      tempScale === Temperature.FAHRENHEIT ? t("fahrenheit") : t("celsius");
  }
  if (dashboard?.heat_index_data?.category) {
    if (dashboard?.heat_index_data?.category !== Categories.normal)
      tempColor = theme.palette.error.main;
  }

  //bathroom
  let bathLabel: string = DASH;
  let bathSub: string = "";
  let bathColor = theme.palette.metrics.main;
  const bathroomDataVisits = dashboard?.bathroom_data?.visits;
  if (bathroomDataVisits) {
    bathLabel = bathroomDataVisits.toString();
    bathSub = capitalize(t("visits"));
  }
  if (dashboard?.bathroom_data?.category) {
    if (dashboard?.bathroom_data?.category !== Categories.normal)
      bathColor = theme.palette.warning.main;
  }

  // Get resident in the unit, if is not defined, then the card doesn't show
  const resident =
    unit !== undefined && unit.residents && unit.residents.length > 0
      ? unit.residents[0]
      : undefined;

  return (
    <React.Fragment>
      <div className={classes.rootContainer}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <SectionHeader text={t("status").toUpperCase()} />
          {resident && unit && unit.community_id && (
            <ResidentContacts
              residentID={resident.id}
              communityID={unit.community_id}
              unitID={unit.id}
            />
          )}
        </Box>
        <Box className={classes.itemContainer}>
          <UnitDashboardItem
            icon={DashboardItemTypes.sleep}
            line1={sleepLabel}
            line2={sleepSub}
            textColor={theme.palette.metrics.main}
          />
          <UnitDashboardItem
            icon={DashboardItemTypes.activity}
            line1={activityLabel}
            line2={activitySub}
            textColor={theme.palette.metrics.main}
          />
          <UnitDashboardItem
            icon={DashboardItemTypes.temperature}
            line1={tempLabel}
            line2={tempSub}
            textColor={tempColor}
          />
          {dashboard?.kitchen_data && (
            <UnitDashboardItem
              icon={DashboardItemTypes.kitchen}
              line1={kitchenLabel}
              line2={kitchenSub}
              textColor={theme.palette.metrics.main}
            />
          )}
          <UnitDashboardItem
            icon={DashboardItemTypes.bathroom}
            line1={bathLabel}
            line2={bathSub}
            textColor={bathColor}
          />
        </Box>
      </div>
    </React.Fragment>
  );
}

interface IProps {
  unitId: string;
  communityUnitTimezone?: string;
}
