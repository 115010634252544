import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moreButton: {
      position: "absolute",
      right: 0,
      top: "-12px",
    },
  })
);

export const ContextMenu = (props: IProps) => {
  const { menuOptions } = props;
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  /* ---- Refs ---- */
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  /* ---- Methods ---- */
  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        className={classes.moreButton}
        aria-label="more user options"
        aria-haspopup="true"
        onClick={handleOpenMoreMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="more-menu"
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMoreMenu}
      >
        {menuOptions.map((option) => {
          const handleOptionClick = () => {
            option.action();
            handleCloseMoreMenu();
          };
          return (
            <MenuItem key={option.label} onClick={handleOptionClick}>
              {t(option.label)}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

interface IProps {
  menuOptions: { action: () => void; label: string }[];
}
