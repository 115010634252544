import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconButton } from "@material-ui/core";
import Icons from "../../../helpers/iconImports";
import { getFilterToHex } from "../../../helpers/colorUtils";

export const SortableItem = (props: Props) => {
  const { id, index, children, removeItem } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    display: "flex",
    alignItems: "center",
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    marginBottom: "8px",
  };

  const numeration = {
    width: "8px",
    marginRight: "16px",
  };

  const iconColor = `brightness(0) saturate(100%) ${getFilterToHex("#303030")}`;
  const icon = {
    filter: iconColor.replace(";", ""),
  };

  const handleDeleteItem = (id: string) => {
    removeItem && removeItem(id);
  };
  return (
    <li style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <span style={numeration}>{`${index + 1}.`}</span>
      {children}

      <IconButton onClick={() => handleDeleteItem(id)} aria-label="Remove">
        <img
          src={Icons.CloseIcon}
          draggable={false}
          alt="Remove"
          style={icon}
        />
      </IconButton>
    </li>
  );
};

interface Props {
  id: string;
  index: number;
  children: React.ReactNode;
  removeItem: (id: string) => void;
}
