import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getUnitStatus } from "../dashboardSlice";
import { IAppState } from "../../../helpers/store";
import Grid from "@material-ui/core/Grid/Grid";
import UnitDashboard from "../unitDashboard/UnitDashboard";
import UnitEventHistory from "../unitDashboard/UnitNotificationHistory";
import { Box } from "@material-ui/core";
import { DateTimeShortFormat } from "../../../helpers/datetime";
import i18n from "../../../i18n";
import UnitActivityChart from "../charts/UnitActivityChart";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";

const useStyles = makeStyles((theme: Theme) => ({
  containerFullHeight: {
    height: "100%",
    "&>*": {
      height: "100%",
    },
  },
  chartsContainer: {
    padding: theme.spacing(0, 3),
  },
  center: {
    color: theme.palette.light.light,
    transform: "translateX(-50%)",
  },
}));

export default function TrayUnit(props: IProps) {
  const { unitID } = props;
  const dashboard = useSelector((state: IAppState) => {
    return state.dashboardState.unitDashboard[unitID];
  });
  const communityID = useSelector((state: IAppState) => {
    return state.headerState.selectedCommunity?.id;
  });
  const communityUnitTimezone = useCommunityUnitTimezone(communityID, unitID);

  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useDispatch();

  /* ---- Effects ---- */
  useEffect(() => {
    if (!dashboard) {
      dispatch(getUnitStatus(unitID));
    }
  }, [dashboard, dispatch, unitID]);

  const lastUpdatedLabel = dashboard?.local_time_updated
    ? `${i18n.t("last_updated")}: ${DateTimeShortFormat(
        dashboard?.local_time_updated,
        communityUnitTimezone
      )}`
    : "";

  return (
    <React.Fragment>
      <Box
        position="absolute"
        top="-17px"
        left="50%"
        fontWeight="fontWeightBold"
        fontSize="caption.fontSize"
        className={classes.center}
      >
        {lastUpdatedLabel}
      </Box>
      <Grid container className={classes.containerFullHeight}>
        <Grid item xs={3}>
          <UnitDashboard
            unitId={unitID}
            communityUnitTimezone={communityUnitTimezone}
          />
        </Grid>
        <Grid item xs={6} className={classes.chartsContainer}>
          <UnitActivityChart
            unitId={unitID}
            communityUnitTimezone={communityUnitTimezone}
          />
        </Grid>
        <Grid item xs={3}>
          <UnitEventHistory
            unitId={unitID}
            communityUnitTimezone={communityUnitTimezone}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

interface IProps {
  unitID: string;
}
