import { useEffect, useState } from "react";
import {
  AccordionActions,
  AccordionDetails,
  Button,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import { useAppDispatch, useAppSelector } from "../app/appHooks";

import {
  ContactTypes,
  DefaultRegionCode,
  PhoneTypes,
} from "../../helpers/constants";
import { ExpandMoreOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { getUserName } from "./UsersSettingsList";
import {
  IPhoneNumber,
  IResidentPhoneNumber,
} from "../../services/header.services";
import { PhoneNumberField } from "../common/PhoneNumberField";
import { AddItemAccordion } from "./common/AddItemAccordion";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { IResidentContact } from "../../services/common.services";
import { labelByContactType } from "../dashboard/eventDetails/ResidentContacts";
import { EditResidentContactForm } from "./residentUnitSettings/EditResidentContactForm";
import { UnitPhoneNumber } from "./common/PhoneNumberPicker";
import {
  addContactPhoneNumber,
  addResidentContact,
  deleteContactPhoneNumber,
  deleteResidentContact,
  IAddResidentContactData,
  updateContactPhoneNumber,
  updateResidentContact,
} from "./settingsThunks";
import { getRegionCodeForCountryCode } from "awesome-phonenumber";
import { IAddContactPhoneNumber } from "../../services/settings.services";
import { ErrorCodes } from "../../services/constants";
import { handledError, showSoftNotification } from "../app/appSlice";
import { SoftNotificationTypes } from "../common/AppSnack";
import { sortContacts } from "./settingsHelpers";
import {
  adjustPhoneNumber,
  getPhoneOperationsArr,
  phoneOperations,
} from "./common/phoneHelpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      padding: theme.spacing(0, 2, 2, 4),
    },
    addListTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      fontWeight: theme.typography.body1.fontWeight,
    },
    buttons: {
      fontFamily: theme.typography.primaryFontFamily,
    },
    bold: {
      fontWeight: "bold",
    },
    accordionListItem: {
      marginBottom: theme.spacing(1),
    },
    sectionOne: {
      display: "inline-flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  })
);

const Accordion = withStyles((theme: Theme) => ({
  root: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 0,
    "&$expanded": {
      minHeight: 0,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export enum FormFieldIDs {
  firstName = "first-name",
  lastName = "last-name",
  contactType = "contact-type",
  phoneNumberOne = "phone-number-one",
  phoneNumberTwo = "phone-number-two",
}

type UnitPhoneNumberUpdate = UnitPhoneNumber & { id?: string | null };

export type ContactFormState = {
  [FormFieldIDs.firstName]?: string | null;
  [FormFieldIDs.lastName]?: string | null;
  [FormFieldIDs.contactType]?: string | null;
  [FormFieldIDs.phoneNumberOne]: UnitPhoneNumberUpdate;
  [FormFieldIDs.phoneNumberTwo]: UnitPhoneNumberUpdate;
};

const getNewPhoneNumber = (
  phone: UnitPhoneNumberUpdate,
  contact_id: string
): IAddContactPhoneNumber => {
  delete phone.id;
  return {
    ...adjustPhoneNumber(phone),
    contact_id: contact_id,
  };
};

const getUpdatedPhoneNumber = (
  phone: UnitPhoneNumberUpdate,
  oldPhoneNumbers: IResidentPhoneNumber[] | null | undefined,
  contact_id: string
): IResidentPhoneNumber => {
  const oldPhone = oldPhoneNumbers?.find(
    (oldPhone) => phone.id === oldPhone.id
  );
  if (oldPhone) {
    const updatedPhoneNumber: { [key: string]: any } = {
      ...oldPhone,
      ...adjustPhoneNumber(phone),
      id: phone.id as string,
    };

    Object.keys(oldPhone).forEach((key) => {
      if (updatedPhoneNumber[key] === null) {
        delete updatedPhoneNumber[key];
      }
    });
    return updatedPhoneNumber as IResidentPhoneNumber;
  } else {
    return {
      ...adjustPhoneNumber(phone),
      id: phone.id as string,
      contact_id: contact_id,
    };
  }
};

export const UpdateResidentContacts = (props: { residentID: string }) => {
  const { residentID } = props;
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const defaultRegionCode = useAppSelector(
    (state) => state.headerState.user?.country ?? DefaultRegionCode
  );
  const resident = useAppSelector((state) => {
    return state.commonState.contactInformation[residentID];
  });

  const errorCode = useAppSelector((state) => state.settingsState.errorCode);

  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.EMERGENCY_CONTACT_LAST_PHONE_NUMBER:
          dispatch(handledError());
          dispatch(
            showSoftNotification(
              SoftNotificationTypes.EMERGENCY_CONTACT_LAST_PHONE_NUMBER
            )
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  /* State */
  const [contactDetail, setContactDetail] = useState<
    IResidentContact | undefined
  >(undefined);
  const [addNewContact, setAddNewContact] = useState(false);
  const [deleteContact, setDeleteContact] = useState("");
  const [confirmEmergencyContact, setConfirmEmergencyContact] = useState("");

  const [newContactValues, setNewContactValues] = useState<ContactFormState>({
    [FormFieldIDs.firstName]: null,
    [FormFieldIDs.lastName]: null,
    [FormFieldIDs.contactType]: ContactTypes.FAMILY,
    [FormFieldIDs.phoneNumberOne]: {
      id: null,
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.HOME,
      phone_number: null,
    },
    [FormFieldIDs.phoneNumberTwo]: {
      id: null,
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.MOBILE,
      phone_number: null,
    },
  });

  const [editContactValues, setEditContactValues] = useState<ContactFormState>({
    [FormFieldIDs.firstName]: null,
    [FormFieldIDs.lastName]: null,
    [FormFieldIDs.contactType]: null,
    [FormFieldIDs.phoneNumberOne]: {
      id: null,
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.HOME,
      phone_number: null,
    },
    [FormFieldIDs.phoneNumberTwo]: {
      id: null,
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.MOBILE,
      phone_number: null,
    },
  });

  const residentContactsList = [...(resident?.contacts ?? [])].sort((a, b) =>
    sortContacts(a, b)
  );

  /* Effects */
  useEffect(() => {
    if (contactDetail !== undefined) {
      const initialVals: ContactFormState = {
        [FormFieldIDs.firstName]: contactDetail.first_name ?? "",
        [FormFieldIDs.lastName]: contactDetail.last_name ?? "",
        [FormFieldIDs.contactType]: contactDetail.contact_type,
        [FormFieldIDs.phoneNumberOne]: {
          id: null,
          country_code: defaultRegionCode,
          phone_type: PhoneTypes.HOME,
          phone_number: null,
        },
        [FormFieldIDs.phoneNumberTwo]: {
          id: null,
          country_code: defaultRegionCode,
          phone_type: PhoneTypes.MOBILE,
          phone_number: null,
        },
      };

      if (
        contactDetail.phone_numbers &&
        contactDetail.phone_numbers.length > 0
      ) {
        const phoneNumbersCopy = JSON.parse(
          JSON.stringify(contactDetail.phone_numbers)
        );
        phoneNumbersCopy.sort(
          <T extends { priority?: number | null }>(a: T, b: T) =>
            Number(a.priority) - Number(b.priority)
        );

        if (phoneNumbersCopy.length > 0) {
          const phoneNumberOne = phoneNumbersCopy[0];
          const countryCode = phoneNumberOne.country_code
            ? String(
                getRegionCodeForCountryCode(Number(phoneNumberOne.country_code))
              )
            : defaultRegionCode;
          initialVals[FormFieldIDs.phoneNumberOne] = {
            id: phoneNumberOne.id,
            country_code: countryCode,
            phone_type: phoneNumberOne.phone_type ?? PhoneTypes.HOME,
            phone_number: phoneNumberOne.phone_number ?? null,
          };
        }
        if (phoneNumbersCopy.length > 1) {
          const phoneNumberTwo = phoneNumbersCopy[1];
          const countryCode = phoneNumberTwo.country_code
            ? String(
                getRegionCodeForCountryCode(Number(phoneNumberTwo.country_code))
              )
            : defaultRegionCode;
          initialVals[FormFieldIDs.phoneNumberTwo] = {
            id: phoneNumberTwo.id,
            country_code: countryCode,
            phone_type: phoneNumberTwo.phone_type ?? PhoneTypes.HOME,
            phone_number: phoneNumberTwo.phone_number ?? null,
          };
        }
      }

      setEditContactValues((prevVal) => ({ ...prevVal, ...initialVals }));
    }
  }, [contactDetail, contactDetail?.phone_numbers, defaultRegionCode]);

  /*------------ Accordion methods ---------*/
  const handleToggleConctactDetail = (contact: IResidentContact) => {
    if (contactDetail === undefined) {
      setContactDetail(contact);
    } else {
      if (contactDetail.id === contact.id) {
        setContactDetail(undefined);
      } else {
        setContactDetail(contact);
      }
    }
  };

  const handleCancelItemAdd = () => {
    setAddNewContact(false);
    setNewContactValues({
      [FormFieldIDs.firstName]: null,
      [FormFieldIDs.lastName]: null,
      [FormFieldIDs.contactType]: ContactTypes.FAMILY,
      [FormFieldIDs.phoneNumberOne]: {
        id: null,
        country_code: defaultRegionCode,
        phone_type: PhoneTypes.HOME,
        phone_number: null,
      },
      [FormFieldIDs.phoneNumberTwo]: {
        id: null,
        country_code: defaultRegionCode,
        phone_type: PhoneTypes.MOBILE,
        phone_number: null,
      },
    });
  };

  const checkValuesValidity = (values: ContactFormState) => {
    return (
      values[FormFieldIDs.firstName] !== "" &&
      values[FormFieldIDs.firstName] !== null &&
      values[FormFieldIDs.lastName] !== "" &&
      values[FormFieldIDs.lastName] !== null &&
      values[FormFieldIDs.contactType] !== "" &&
      values[FormFieldIDs.contactType] !== null
    );
  };

  const checkContactInfoChange = (values: ContactFormState) => {
    if (contactDetail === undefined) return false;

    return (
      contactDetail.first_name !== values[FormFieldIDs.firstName] ||
      contactDetail.last_name !== values[FormFieldIDs.lastName] ||
      contactDetail.contact_type !== values[FormFieldIDs.contactType]
    );
  };

  const checkPhoneNumbersChanged = (
    oldData: IResidentContact["phone_numbers"],
    values: ContactFormState
  ) => {
    let changed = false;
    if (oldData === undefined || oldData === null) return changed;
    const phone1 = values[FormFieldIDs.phoneNumberOne];
    if (phone1.id) {
      if (phone1.phone_number) {
        const oldPhone = oldData?.find((oldPhone) => oldPhone.id === phone1.id);
        const adjustedPhone1 = adjustPhoneNumber(phone1);
        if (
          oldPhone &&
          (oldPhone.phone_number !== adjustedPhone1.phone_number ||
            oldPhone.phone_type !== adjustedPhone1.phone_type ||
            oldPhone.country_code !== adjustedPhone1.country_code)
        ) {
          changed = true;
        }
      } else {
        changed = true;
      }
    } else {
      if (phone1.phone_number) {
        changed = true;
      }
    }
    const phone2 = values[FormFieldIDs.phoneNumberTwo];
    if (phone2.id) {
      if (phone2.phone_number) {
        const oldPhone = oldData?.find((oldPhone) => oldPhone.id === phone2.id);
        const adjustedPhone2 = adjustPhoneNumber(phone2);
        if (
          oldPhone &&
          (oldPhone.phone_number !== adjustedPhone2.phone_number ||
            oldPhone.phone_type !== adjustedPhone2.phone_type ||
            oldPhone.country_code !== adjustedPhone2.country_code)
        ) {
          changed = true;
        }
      } else {
        changed = true;
      }
    } else {
      if (phone2.phone_number) {
        changed = true;
      }
    }
    return changed;
  };

  /*------------ Edit resident contact form methods ---------*/
  const handleChangeEditForm = (value: string, formField: FormFieldIDs) => {
    setEditContactValues((prevVal) => ({
      ...prevVal,
      [formField]: value.trimStart(),
    }));
  };

  const handlePhoneChangeEditForm = (
    value: UnitPhoneNumber,
    formField: FormFieldIDs
  ) => {
    setEditContactValues((prevVal) => ({ ...prevVal, [formField]: value }));
  };

  const handlePhoneNumberUpdate = async (
    oldPhoneNumbers: IResidentPhoneNumber[] | null | undefined,
    newValues: ContactFormState,
    resident_id: string,
    contact_id: string,
    forcePhoneUpdate: boolean
  ) => {
    const phoneOperation = getPhoneOperationsArr(
      oldPhoneNumbers,
      [newValues["phone-number-one"], newValues["phone-number-two"]] as const,
      "contact"
    );

    for (const item of phoneOperation) {
      if (item.op === phoneOperations.add) {
        await dispatch(
          addContactPhoneNumber({
            residentID: resident_id,
            phoneNumber: getNewPhoneNumber(item.phone, contact_id),
          })
        );
      }
      if (item.op === phoneOperations.update) {
        await dispatch(
          updateContactPhoneNumber({
            residentID: resident_id,
            phoneData: {
              phoneID: item.phone.id as string,
              phone: getUpdatedPhoneNumber(
                item.phone,
                oldPhoneNumbers,
                contact_id
              ),
            },
          })
        );
      }
      if (item.op === phoneOperations.delete) {
        if (item.phone.id) {
          await dispatch(
            deleteContactPhoneNumber({
              residentID: resident_id,
              contactID: contact_id,
              deleteData: {
                forceDelete: forcePhoneUpdate,
                phoneID: item.phone.id,
              },
            })
          );
        }
      }
      if (item.op === phoneOperations.same) {
        await dispatch(
          updateContactPhoneNumber({
            residentID: resident_id,
            phoneData: {
              phoneID: item.phone.id as string,
              phone: getUpdatedPhoneNumber(
                item.phone,
                oldPhoneNumbers,
                contact_id
              ),
            },
          })
        );
      }
    }
  };

  const handleSaveContactChanges = async (forcePhoneUpdate: boolean) => {
    if (contactDetail === undefined) return;

    if (checkContactInfoChange(editContactValues)) {
      const contact = {
        community_id: contactDetail.community_id,
        resident_id: contactDetail.resident_id,
        first_name: editContactValues[FormFieldIDs.firstName] ?? "",
        last_name: editContactValues[FormFieldIDs.lastName] ?? "",
        contact_type: editContactValues[FormFieldIDs.contactType] ?? "",
      };

      const editContactResult = await dispatch(
        updateResidentContact({
          contact: contact,
          contactID: contactDetail.id,
        })
      );

      if (updateResidentContact.fulfilled.match(editContactResult)) {
        setContactDetail(undefined);
      }
    }

    if (
      checkPhoneNumbersChanged(contactDetail?.phone_numbers, editContactValues)
    ) {
      // Handles the adding, updating and deleting resident phone numbers
      handlePhoneNumberUpdate(
        contactDetail.phone_numbers,
        editContactValues,
        residentID,
        contactDetail.id,
        forcePhoneUpdate
      );
      setContactDetail(undefined);
    }
  };

  // Validates if the resident is an emergency contact and if after the updates will have at least one phone number
  const handleSaveChangesValidation = () => {
    if (contactDetail === undefined) return;

    if (
      contactDetail.emergency_contact &&
      contactDetail.phone_numbers &&
      contactDetail.phone_numbers.length > 0
    ) {
      const oldPhones = contactDetail.phone_numbers.map((phone) => phone.id);
      //old contact has numbers, we need to check if with the updates there is at least one number
      const updatedPhones = [
        editContactValues["phone-number-one"],
        editContactValues["phone-number-two"],
      ] as const;

      updatedPhones.forEach((phone) => {
        // add
        if (phone.id === null && phone.phone_number) {
          oldPhones.push("new");
        }
        //delete
        else if (
          phone.id !== null &&
          phone.id !== undefined &&
          (phone.phone_number === "" || phone.phone_number === null)
        ) {
          oldPhones.splice(oldPhones.indexOf(phone.id), 1);
        }
      });

      // There are still some phones left
      if (oldPhones.length === 0) {
        setConfirmEmergencyContact("emergency_contacts_last_phone_message");
      } else {
        handleSaveContactChanges(false);
      }
    } else {
      handleSaveContactChanges(false);
    }
  };

  const enableEdit =
    checkValuesValidity(editContactValues) &&
    (checkContactInfoChange(editContactValues) ||
      checkPhoneNumbersChanged(
        contactDetail?.phone_numbers,
        editContactValues
      ));

  /*------------ Add resident contact form methods ---------*/
  const handleChangeAddForm = (value: string, formField: FormFieldIDs) => {
    setNewContactValues((prevVal) => ({
      ...prevVal,
      [formField]: value.trimStart(),
    }));
  };

  const handlePhoneChangeAddForm = (
    value: UnitPhoneNumber,
    formField: FormFieldIDs
  ) => {
    setNewContactValues((prevVal) => ({ ...prevVal, [formField]: value }));
  };

  const handleAddResidentContact = async () => {
    if (residentID === undefined) return;
    if (communityID === undefined) return;

    const contact = {
      community_id: communityID,
      resident_id: residentID,
      first_name: newContactValues[FormFieldIDs.firstName] ?? "",
      last_name: newContactValues[FormFieldIDs.lastName] ?? "",
      contact_type: newContactValues[FormFieldIDs.contactType] ?? "",
    };

    const phone1 = newContactValues[FormFieldIDs.phoneNumberOne];
    delete phone1.id;
    const phone2 = newContactValues[FormFieldIDs.phoneNumberTwo];
    delete phone2.id;
    let phoneNumbers: IAddResidentContactData["phoneNumbers"] = [];
    if (phone1.phone_number) {
      phoneNumbers.push(adjustPhoneNumber(phone1));
    }
    if (phone2.phone_number) {
      phoneNumbers.push(adjustPhoneNumber(phone2));
    }

    if (phoneNumbers.length > 0) {
      phoneNumbers = phoneNumbers.map((val, index) => ({
        ...val,
        priority: index + 1,
      }));
    }

    const addContactResult = await dispatch(
      addResidentContact({ contact, phoneNumbers })
    );

    if (addResidentContact.fulfilled.match(addContactResult)) {
      setAddNewContact(false);
      handleCancelItemAdd();
    }
  };

  const enableAdd = checkValuesValidity(newContactValues);

  /*------------ Delete resident contact form methods ---------*/
  const handleContactDelete = () => {
    if (contactDetail === undefined) return;
    dispatch(
      deleteResidentContact({
        residentID: residentID,
        contactID: contactDetail.id,
      })
    );
  };

  const handleDeleteClick = (contact: IResidentContact) => {
    if (contact.emergency_contact) {
      setDeleteContact("delete_emergency_contact_confirm_message");
    } else {
      setDeleteContact("delete_contact_confirm_message");
    }
  };

  return (
    <div className={classes.formContainer}>
      <h3 className={classes.addListTitle}>
        {t("resident_contacts").toUpperCase()}
      </h3>
      <p>{t("resident_contacts_users_summary")}</p>

      <ConfirmDialog
        dialogType={DialogTypes.warning}
        toggle={deleteContact !== ""}
        title={t("delete")}
        message={deleteContact ? t(deleteContact) : ""}
        possitiveText={t("yes")}
        negativeText={t("no")}
        positiveAction={handleContactDelete}
        negativeAction={() => void 0}
        onClose={() => setDeleteContact("")}
      />
      <ConfirmDialog
        dialogType={DialogTypes.warning}
        toggle={confirmEmergencyContact !== ""}
        title={t("emergency_contact")}
        message={confirmEmergencyContact ? t(confirmEmergencyContact) : ""}
        possitiveText={t("yes")}
        negativeText={t("no")}
        positiveAction={() => {
          setConfirmEmergencyContact("");
          handleSaveContactChanges(true);
        }}
        negativeAction={() => void 0}
        onClose={() => {
          setConfirmEmergencyContact("");
        }}
      />
      {residentContactsList.length > 0 &&
        residentContactsList.map((contact) => {
          const contactType = labelByContactType(contact.contact_type);

          const userName = getUserName(contact);
          const phoneNumbers = contact.phone_numbers;
          let phoneNumber: IPhoneNumber | undefined = undefined;
          if (phoneNumbers && phoneNumbers.length > 0) {
            phoneNumber = phoneNumbers[0];
          }

          // const hasPhoneNumbers = phoneNumbers && phoneNumbers.length > 0;
          const openPanel = contactDetail?.id === contact.id;
          return (
            <Accordion
              className={classes.accordionListItem}
              TransitionProps={{ unmountOnExit: true }}
              key={contact.id}
              expanded={openPanel}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                onClick={() => handleToggleConctactDetail(contact)}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <div className={clsx(classes.sectionOne, classes.bold)}>
                      {userName}
                      <Typography variant="caption">{t("contact")}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    {t(contactType ?? "")}
                  </Grid>
                  <Grid item xs={5}>
                    {phoneNumber !== undefined && (
                      <PhoneNumberField
                        phoneNumber={phoneNumber}
                        notClickable
                        noPadding
                        rightAlign
                        noTruncate
                      />
                    )}
                  </Grid>
                </Grid>
              </AccordionSummary>
              {openPanel && <Divider />}
              <AccordionDetails>
                <EditResidentContactForm
                  values={editContactValues}
                  defaultRegionCode={defaultRegionCode}
                  updateValue={handleChangeEditForm}
                  updatePhone={handlePhoneChangeEditForm}
                />
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                <Button
                  className={classes.buttons}
                  onClick={() => setContactDetail(undefined)}
                  size="small"
                  classes={{ label: classes.buttons }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={() => handleDeleteClick(contact)}
                  size="small"
                  className={classes.deleteButton}
                  classes={{ label: classes.buttons }}
                >
                  {t("delete")}
                </Button>
                <Button
                  onClick={handleSaveChangesValidation}
                  size="small"
                  color="primary"
                  disabled={!enableEdit}
                  classes={{ label: classes.buttons }}
                >
                  {t("save")}
                </Button>
              </AccordionActions>
            </Accordion>
          );
        })}

      <AddItemAccordion
        expanded={addNewContact}
        toggle={setAddNewContact}
        cancelAction={handleCancelItemAdd}
        addText="save"
        addAction={() => handleAddResidentContact()}
        disabledAddAction={!enableAdd}
        withHeader
      >
        <EditResidentContactForm
          values={newContactValues}
          defaultRegionCode={defaultRegionCode}
          updateValue={handleChangeAddForm}
          updatePhone={handlePhoneChangeAddForm}
        />
      </AddItemAccordion>
    </div>
  );
};
