import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

// Component styles
const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    container: {
      width: "380px",
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
      bottom: 16,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: (props) => (props.rightAligned ? "row-reverse" : "row"),
      position: "absolute",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  })
);

export const ActionBarBottom = (props: IProps) => {
  const classes = useStyles(props);

  return <div className={classes.container}>{props.children}</div>;
};

interface IProps {
  children: React.ReactNode;
  rightAligned?: boolean;
}
