import { PRODUCTION, STAGING, TEST } from "./helpers/constants";

export const firebaseConfig =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? JSON.parse(process.env.REACT_APP_FIREBASE_API_KEY_PRODUCTION as string)
    : JSON.parse(process.env.REACT_APP_FIREBASE_API_KEY_STAGING as string);

export const firebaseConfigTest = JSON.parse(
  process.env.REACT_APP_FIREBASE_API_KEY_TEST as string
);
export const firebaseConfigStaging = JSON.parse(
  process.env.REACT_APP_FIREBASE_API_KEY_STAGING as string
);
export const firebaseConfigProd = JSON.parse(
  process.env.REACT_APP_FIREBASE_API_KEY_PRODUCTION as string
);

export const getFirebaseConfig = (project: string) => {
  switch (project) {
    case TEST:
      return firebaseConfigTest;
    case STAGING:
      return firebaseConfigStaging;
    case PRODUCTION:
      return firebaseConfigProd;
    default:
      return firebaseConfig;
  }
};
