import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CriticalNotifications from "../activeNotificationPanel/CriticalNotifications";
import WarningNotifications from "../activeNotificationPanel/WarningNotifications";
import { ACTIVE_PANEL_WIDTH, TabTypes } from "../../../helpers/constants";
import { IEvent } from "../../../services/dashboard.services";
import { useAppSelector } from "../../app/appHooks";
import useEventTypesCount from "../hooks/useEventTypes";
import usePanelsHeight from "../activeNotificationPanel/hooks/usePanelsHeight";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationsContainer: {
      display: "flex",
      width: `${ACTIVE_PANEL_WIDTH}px`,
      maxHeight: "70vh",
      borderLeft: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 1),
      flexDirection: "column",
    },
    colorSecondary: {
      backgroundColor: theme.palette.dark.main,
    },
    notificationEventContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      overflowY: "auto",
      height: "100%",
    },
    divider: {
      minHeight: "20px",
      paddingTop: "10px",
    },
    emptyContainer: {
      fontWeight: "bold",
      textAlign: "center",
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  })
);

export default function UnitEventList(props: IProps) {
  const {
    unitId,
    shouldShowCritical,
    shouldShowWarning,
    organization,
    events,
    addTab,
    onClose,
    updatePopover,
  } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  /* ---- Selectors ---- */
  const communityEvents = useAppSelector((state) =>
    state.dashboardState.communityEvents?.filter(
      (eventEl) => eventEl.unit_id === unitId
    )
  );
  const handleAddTab = (id: string, type: TabTypes) => {
    addTab(id, type);
    onClose();
  };

  const { criticalEventsCount, warningEventsCount } = useEventTypesCount(
    !!organization ? events : communityEvents,
    !!organization
  );

  const ref = useRef(null);

  const { criticalPanelHeight, warningPanelHeight } = usePanelsHeight(
    warningEventsCount,
    criticalEventsCount,
    ref.current,
    true
  );

  // Update popover position imperatively
  useEffect(() => {
    const timer = setTimeout(() => {
      updatePopover && updatePopover();
    }, 1);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const innerListWidth = ACTIVE_PANEL_WIDTH - 18;

  return (
    <div
      className={classes.notificationsContainer}
      id="notifications-popup-container"
      ref={ref}
    >
      <div
        id="inner-notifications-popup-container"
        className={classes.notificationEventContainer}
      >
        {shouldShowWarning || shouldShowCritical ? (
          <React.Fragment>
            <CriticalNotifications
              addTab={handleAddTab}
              unitId={unitId}
              organization={organization}
              events={organization ? events : undefined}
              height={criticalPanelHeight}
              width={innerListWidth}
            />
            {shouldShowWarning && shouldShowCritical && (
              <div className={classes.divider}>
                <Divider />
              </div>
            )}
            <WarningNotifications
              addTab={handleAddTab}
              unitId={unitId}
              organization={organization}
              events={organization ? events : undefined}
              height={warningPanelHeight}
              width={innerListWidth}
            />
          </React.Fragment>
        ) : (
          <div className={classes.emptyContainer}>
            {t("no_active_notifications")}
          </div>
        )}
      </div>
    </div>
  );
}

interface IProps {
  unitId: string;
  shouldShowCritical: boolean;
  shouldShowWarning: boolean;
  organization?: boolean;
  events?: IEvent[];
  addTab: (id: string, type: TabTypes) => void;
  onClose: () => void;
  updatePopover?: () => void;
}
