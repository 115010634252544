import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Grid, IconButton, withStyles } from "@material-ui/core";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { DateTime } from "luxon";

import { useAppDispatch, useAppSelector } from "../app/appHooks";
import {
  DateTimeNow,
  DateTimeLong,
  DateFromISOStringUTC,
} from "../../helpers/datetime";
import { Gateway } from "../../services/devices.services";
import { IUnit } from "../../services/dashboard.services";
import { DeviceIcon } from "./DeviceIcon";
import {
  DeviceConnectionType,
  DeviceStatus,
  formatMacAddress,
  StatusColor,
  StatusColors,
} from "./deviceHelpers";
import { DeviceExpandedField } from "./common/DeviceExpandedField";
import { DevicePlacementPhoto } from "./DevicePlacementPhoto";
import { loadDevicePlacementPhoto, loadGatewaysData } from "./devicesThunks";
import { DevicesIcons } from "../../helpers/iconImports";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gatewayViewerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      minWidth: "1000px",
    },
    listTitle: {
      margin: 0,
      fontSize: theme.typography.subtitle1.fontSize,
      textTransform: "uppercase",
      marginBottom: theme.spacing(1.5),
    },
    listHeader: {
      paddingRight: "56px",
      "& > *": {
        fontSize: theme.typography.caption.fontSize,
        textTransform: "uppercase",
        fontWeight: "bold",
      },
    },
    rowContainer: {
      "& > *": {
        display: "flex",
        alignItems: "center",
        fontSize: theme.typography.caption.fontSize,
      },
    },
    iconCell: {
      justifyContent: "center",
      textTransform: "uppercase",
      fontWeight: "bold",
      gap: "8px",
    },
    green: {
      color: theme.palette.primary.main,
    },
    red: {
      color: theme.palette.error.main,
    },
    yellow: {
      color: theme.palette.warning.main,
    },
    padding: {
      paddingLeft: theme.spacing(2),
    },
    accordionDetailsPanel: {
      display: "flex",
      width: "100%",
      alignItems: "baseline",
      paddingLeft: theme.spacing(4),
    },
    label: {
      textTransform: "uppercase",
    },
    columnGrid: {
      display: "grid",
      gridTemplateColumns: "1.1fr 2fr",
      rowGap: theme.spacing(1),
    },
    refreshButton: {
      paddingTop: "10px",
    },
  })
);

const Accordion = withStyles((theme: Theme) => ({
  root: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "none",
    width: "100%",
    "&$expanded": {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    "&$expanded": {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      minHeight: 48,
    },
  },
  content: {
    margin: "8px 0",
    "&$expanded": {
      margin: "0",
    },
  },
  expandIcon: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const getGatewayStatus = (
  isConnected: Gateway["is_connected"]
): DeviceStatus => {
  if (!isConnected) {
    return { text: "offline", color: StatusColors.red };
  }
  return { text: "online", color: StatusColors.green };
};

const getLastContactStatus = (
  lastContactTime: string,
  userTimezone?: string | null
): DeviceStatus => {
  let contactTime = DateFromISOStringUTC(lastContactTime, userTimezone);

  const timeDiffMinutes = DateTime.now().diff(contactTime, "minutes").toObject()
    .minutes;

  if (userTimezone) {
    contactTime = contactTime.setZone(userTimezone).setZone(userTimezone);
  } else {
    contactTime = contactTime.setZone(DateTimeNow().zoneName);
  }

  let color: StatusColor = StatusColors.green;
  if (timeDiffMinutes !== undefined && timeDiffMinutes > 30) {
    color = StatusColors.yellow;
  }

  return {
    text: DateTimeLong(contactTime),
    color,
  };
};

const getLteSignalStatus = (rssi?: number | null): DeviceStatus => {
  let lteSignalStatus: DeviceStatus = { text: "", color: StatusColors.green };

  if (rssi === null || rssi === undefined) return lteSignalStatus;

  if (rssi < -100) {
    lteSignalStatus = { text: "poor", color: StatusColors.red };
  }
  if (rssi >= -100) {
    lteSignalStatus = { text: "fair", color: StatusColors.yellow };
  }
  if (rssi >= -85) {
    lteSignalStatus = { text: "good", color: StatusColors.green };
  }
  if (rssi >= -70) {
    lteSignalStatus = { text: "very_good", color: StatusColors.green };
  }

  return lteSignalStatus;
};

const getWifiSignalStatus = (rssi?: number | null): DeviceStatus => {
  let wifiSignalStatus: DeviceStatus = { text: "", color: StatusColors.green };

  if (rssi === null || rssi === undefined) return wifiSignalStatus;

  if (rssi <= -70) {
    wifiSignalStatus = { text: "poor", color: StatusColors.red };
  }
  if (rssi > -70) {
    wifiSignalStatus = { text: "fair", color: StatusColors.yellow };
  }
  if (rssi > -60) {
    wifiSignalStatus = { text: "good", color: StatusColors.green };
  }

  return wifiSignalStatus;
};

const getGatewaySignal = (
  connectionType: Gateway["connection_type"],
  rssi: { lteRssi?: number | null; wifiRssi?: number | null }
): DeviceStatus => {
  if (connectionType === "lte") {
    return getLteSignalStatus(rssi.lteRssi);
  }
  if (connectionType === "wifi") {
    return getWifiSignalStatus(rssi.wifiRssi);
  }
  return { text: "", color: StatusColors.green };
};

export const GatewayList = (props: IProps) => {
  const { selectedUnit, gateways } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [photoUrls, setPhotoUrls] = useState<{ [key: string]: string }>({});
  // const dispatch = useAppDispatch();

  /* Selectors */
  const userTimezone = useAppSelector(
    (state) => state.headerState.user?.time_zone
  );

  const [photoStatus, setPhotoStatus] = useState<{ [key: string]: boolean }>(
    {}
  );

  // Initialize photo status when the component mounts or the devices array changes
  useEffect(() => {
    const initialStatus: { [key: string]: boolean } = {};
    if (gateways) {
      gateways.forEach((gateway) => {
        initialStatus[gateway.id] = false;
      });
      setPhotoStatus(initialStatus);
    }
  }, [gateways]);

  const handlePhotoLoadSuccess = (deviceID: string) => {
    setPhotoStatus((prevState) => ({
      ...prevState,
      [deviceID]: true,
    }));
  };

  const setPhotoStatusFalse = (deviceID: string) => {
    setPhotoStatus((prevState) => ({
      ...prevState,
      [deviceID]: false,
    }));
  };

  useEffect(() => {
    const fetchPhotoUrls = async () => {
      const urls: { [key: string]: string } = {};
      if (gateways) {
        for (const gateway of gateways) {
          const imgURL = await dispatch(loadDevicePlacementPhoto(gateway.id));
          if (typeof imgURL.payload === "string") {
            urls[gateway.id] = imgURL.payload;
          }
        }
        setPhotoUrls(urls);
      }
    };
    fetchPhotoUrls();
  }, [gateways, dispatch]);

  const handleRefreshDevicesList = () => {
    if (selectedUnit !== undefined) {
      dispatch(loadGatewaysData(selectedUnit.id));
    }
  };

  return (
    <div>
      <h2 className={classes.listTitle}>
        {t("gateway")}
        <IconButton
          className={classes.refreshButton}
          onClick={handleRefreshDevicesList}
        >
          <img
            src={DevicesIcons.Refresh}
            alt={"alt_text_refresh"}
            draggable={false}
          />
        </IconButton>
      </h2>
      <div className={classes.gatewayViewerContainer}>
        {selectedUnit !== undefined && gateways !== undefined && (
          <>
            {gateways.length === 0 ? (
              <span>{t("no_devices")}</span>
            ) : (
              <Grid container spacing={1} className={classes.listHeader}>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  {t("gateway")}
                </Grid>
                <Grid item xs={2}>
                  {t("mac_address")}
                </Grid>
                <Grid item xs={2}>
                  {t("model")}
                </Grid>
                <Grid item xs={1}>
                  {t("status")}
                </Grid>
                <Grid item xs={2}>
                  {t("last_contact")}
                </Grid>
                <Grid item xs={1}>
                  {t("connection")}
                </Grid>
                <Grid item xs={1} style={{ paddingLeft: "16px" }}>
                  {t("signal")}
                </Grid>
              </Grid>
            )}
            {gateways.length > 0 &&
              gateways.map((gateway) => {
                const status = getGatewayStatus(gateway.is_connected);

                const macAddressFormatted = gateway.mac_address
                  ? gateway.mac_address
                      .toUpperCase()
                      .match(/.{1,2}/g)
                      ?.join(":") ?? ""
                  : "";
                const lastContactStatus = gateway.last_contact_time
                  ? getLastContactStatus(
                      gateway.last_contact_time,
                      userTimezone
                    )
                  : ({
                      text: "",
                      color: StatusColors.green,
                    } as DeviceStatus);

                const gatewaySignal = getGatewaySignal(
                  gateway.connection_type,
                  { lteRssi: gateway.lte_rssi, wifiRssi: gateway.wifi_rssi }
                );

                const hasWifiConnection =
                  gateway.connection_type === DeviceConnectionType.wifi;
                const hasLTEConnection =
                  gateway.connection_type === DeviceConnectionType.lte;

                return (
                  <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    key={gateway.id}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                      <Grid
                        container
                        spacing={1}
                        className={classes.rowContainer}
                      >
                        <Grid item xs={1} className={classes.iconCell}>
                          <DeviceIcon
                            sensorType={"gateway"}
                            iconAlt={t("alt_device_icon", {
                              sensor_type: "gateway",
                            })}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {t("name_gateway")}
                        </Grid>
                        <Grid item xs={2}>
                          {macAddressFormatted}
                        </Grid>
                        <Grid item xs={2}>
                          {gateway.model}
                        </Grid>
                        <Grid item xs={1}>
                          <div className={classes[status.color]}>
                            {t(status.text)}
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div className={classes[lastContactStatus.color]}>
                            {lastContactStatus.text}
                          </div>
                        </Grid>
                        <Grid item xs={1}>
                          {gateway.connection_type}
                        </Grid>
                        <Grid item xs={1} style={{ paddingLeft: "16px" }}>
                          <div className={classes[gatewaySignal.color]}>
                            {gatewaySignal.text !== ""
                              ? t(gatewaySignal.text)
                              : ""}
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.accordionDetailsPanel}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <div className={classes.columnGrid}>
                              <DeviceExpandedField
                                label={t("mac_address")}
                                value={macAddressFormatted}
                              />
                              <DeviceExpandedField
                                label={t("model")}
                                value={gateway.model}
                              />
                              <DeviceExpandedField
                                label={t("status")}
                                value={t(status.text)}
                                color={status.color}
                              />
                              <DeviceExpandedField
                                label={t("connection")}
                                value={gateway.connection_type}
                              />
                              {hasWifiConnection && (
                                <>
                                  <DeviceExpandedField
                                    label={t("wifi_signal")}
                                    value={
                                      gatewaySignal.text !== ""
                                        ? t(gatewaySignal.text)
                                        : ""
                                    }
                                    color={gatewaySignal.color}
                                  />
                                </>
                              )}
                              {hasLTEConnection && (
                                <>
                                  <DeviceExpandedField
                                    label={t("lte_signal")}
                                    value={
                                      gatewaySignal.text !== ""
                                        ? t(gatewaySignal.text)
                                        : ""
                                    }
                                    color={gatewaySignal.color}
                                  />
                                  <DeviceExpandedField
                                    label={t("module")}
                                    value={gateway.lte_module ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("operator")}
                                    value={gateway.lte_operator ?? ""}
                                  />
                                </>
                              )}
                              <DeviceExpandedField
                                label={t("last_contact")}
                                value={lastContactStatus.text}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className={classes.columnGrid}>
                              {hasWifiConnection && (
                                <>
                                  <DeviceExpandedField
                                    label={t("ssid")}
                                    value={gateway.wifi_ssid ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("ip_address")}
                                    value={gateway.ip ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("wifi_mac")}
                                    value={formatMacAddress(
                                      gateway.wifi_mac_address
                                    )}
                                  />
                                  <DeviceExpandedField
                                    label={t("wifi_channel")}
                                    value={`${
                                      gateway.wifi_channel_primary ?? ""
                                    }`}
                                  />
                                  <DeviceExpandedField
                                    label={t("wifi_rssi")}
                                    value={gateway.wifi_rssi?.toString() ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("ap_bssid")}
                                    value={formatMacAddress(
                                      gateway.wifi_ap_bssid
                                    )}
                                  />
                                </>
                              )}
                              {hasLTEConnection && (
                                <>
                                  <DeviceExpandedField
                                    label={t("lte_imei")}
                                    value={gateway.lte_imei ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("lte_imsi")}
                                    value={gateway.lte_imsi ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("mode")}
                                    value={gateway.lte_cell_mode ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("rsrp")}
                                    value={gateway.lte_rsrp?.toString() ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("rsrq")}
                                    value={gateway.lte_rsrq?.toString() ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("rssi")}
                                    value={gateway.lte_rssi?.toString() ?? ""}
                                  />
                                  <DeviceExpandedField
                                    label={t("sinr")}
                                    value={gateway.lte_sinr?.toString() ?? ""}
                                  />
                                </>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            {photoUrls[gateway.id] && (
                              <DevicePlacementPhoto
                                deviceID={gateway.id}
                                photoStatus={photoStatus}
                                onPhotoLoadSuccess={handlePhotoLoadSuccess}
                                setPhotoStatusFalse={setPhotoStatusFalse}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

interface IProps {
  selectedUnit?: IUnit;
  gateways?: Gateway[];
}
