import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Backdrop, CssBaseline } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import "./App.css";
import { IAppState } from "../../helpers/store";
import { ReducerStates, Views } from "../../helpers/constants";
import { LoadingIndicator } from "../common/LoadingIndicator";
import Header, { showHeader } from "../header/Header";
import Login from "../login/Login";
import Dashboard from "../dashboard/Dashboard";
import AccountCreation from "../login/accountCreation/AccountCreation";
import ResetPassword from "../login/ResetPassword";
import { AppSnack } from "../common/AppSnack";
import UpdateEnvironment from "../login/UpdateEnvironment";
import PrivateRoute from "./PrivateRoute";
import Notifications from "../notifications/Notifications";
import Analytics from "../analytics/Analytics";
import Devices from "../devices/Devices";
import AnalyticsPrintable from "../analytics/AnalyticsPrintable";
import useFrame from "./hooks/useFrame";
import FrameLoading from "../app/FrameLoading";
import Settings from "../settings/Settings";
import Reports from "../reports/Reports";
import { DownloadLogs } from "./DownloadLogs";

export const bannerHeight = "40px";
export const minToolbarHeight = (showBanner: Boolean, theme: Theme) =>
  showBanner
    ? `calc(${theme.mixins.toolbar.minHeight} + ${bannerHeight})`
    : `calc(${theme.mixins.toolbar.minHeight})`;

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    logged: {
      minHeight: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      backgroundColor: theme.palette.light.main,
      overflow: "hidden",
    },
    notLogged: {
      minHeight: `100vh`,
      backgroundColor: theme.palette.light.main,
      display: "flex",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

/**
 * App component
 *
 * @returns renders the app
 */
const App = () => {
  const showBanner = useSelector(
    (state: IAppState) => state.headerState.showBanner
  );

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const location = useLocation();

  /* ---- Selectors ---- */
  const isLoading = useSelector((state: IAppState) => {
    return (
      state.headerState.state === ReducerStates.PENDING ||
      state.loginState.state === ReducerStates.PENDING ||
      state.appState.state === ReducerStates.PENDING ||
      state.dashboardState.state === ReducerStates.PENDING ||
      state.dashboardState.firebaseState === ReducerStates.PENDING ||
      state.notificationsState.firebaseState === ReducerStates.PENDING ||
      state.notificationsState.state === ReducerStates.PENDING ||
      state.commonState.state === ReducerStates.PENDING ||
      state.analyticsState.state === ReducerStates.PENDING ||
      state.analyticsState.firebaseState === ReducerStates.PENDING ||
      state.settingsState.state === ReducerStates.PENDING ||
      state.devicesState.state === ReducerStates.PENDING ||
      state.reportsState.state === ReducerStates.PENDING
    );
  });

  const appState = useSelector((state: IAppState) => state.appState.state);
  const isLogged = useSelector((state: IAppState) => state.loginState.isLogged);

  const isFrame = useFrame();

  const initialLoadFinished = appState === ReducerStates.SUCCEEDED;

  return (
    <React.Fragment>
      {initialLoadFinished && (
        <React.Fragment>
          <CssBaseline />
          {isLogged && showHeader(location.pathname) && <Header />}
          <div
            className={
              isLogged && showHeader(location.pathname)
                ? classes.logged
                : classes.notLogged
            }
            id="main"
          >
            <Switch>
              <Route exact path={Views.ROOT}>
                {isLogged && !isFrame ? (
                  <Redirect to={Views.DASHBOARD} />
                ) : (
                  <Redirect to={Views.LOGIN} />
                )}
              </Route>
              <PrivateRoute
                path={Views.DASHBOARD}
                component={Dashboard}
                exact
              />
              <PrivateRoute
                path={Views.NOTIFICATIONS}
                component={Notifications}
                exact
              />
              <PrivateRoute
                path={Views.ANALYTICS}
                component={Analytics}
                exact
              />
              <PrivateRoute path={Views.REPORTS} component={Reports} exact />
              <PrivateRoute path={Views.DEVICES} component={Devices} exact />
              <PrivateRoute path={Views.SETTINGS} component={Settings} exact />
              <PrivateRoute
                path={Views.ANALYTICS_PRINTABLE}
                component={AnalyticsPrintable}
                exact
              />
              <Route exact path={Views.LOGIN}>
                {isFrame ? <FrameLoading /> : <Login />}
              </Route>
              <Route exact path={Views.ACCOUNT_CREATION}>
                <AccountCreation />
              </Route>
              <Route exact path={Views.RESET_PASSWORD}>
                <ResetPassword />
              </Route>
              <Route exact path={Views.ENVIRONMENT_SETTING}>
                <UpdateEnvironment />
              </Route>
            </Switch>
            <AppSnack isLoading={isLoading} />
            <DownloadLogs />
            <Backdrop className={classes.backdrop} open={isLoading}>
              <LoadingIndicator />
            </Backdrop>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default function WrappedApp() {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <App />
    </Suspense>
  );
}
