import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReportData, reportsServices } from "../../services/reports.services";

export const generateReport = createAsyncThunk(
  "reports/generateReport",
  async (messageData: IReportData) => {
    await reportsServices.generateReport(messageData);
    return;
  }
);

export const downloadReport = createAsyncThunk(
  "reports/downloadReport",
  async (messageData: IReportData) => {
    return reportsServices.downloadReport(
      messageData.community_id,
      messageData.unit_id,
      messageData.report_date,
      messageData.report_type
    );
  }
);
