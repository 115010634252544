import React from "react";
import { useTranslation } from "react-i18next";
import { LayoutChartContainer } from "./LayoutChartContainer";
import {
  IActivityData,
  IActivityTime,
  IContact,
  IMonitorData,
} from "../eventDataTypes";
import {
  ContactSensorTypes,
  sortAlphabetical,
  StackTypes,
} from "../../../helpers/constants";
import FloatBarChart, {
  IFloatingBarDataFormat,
} from "../../common/FloatBarChart";
import {
  DateFromIsoString,
  DateTimeFromMillis,
  DateTimeNow,
} from "../../../helpers/datetime";
import { EventTypes } from "../../../helpers/constants";
import {
  checkForContactCountSensor,
  checkForContactSensor,
  checkForMotionSensor,
} from "./chartHelpers";
import { IEvent } from "../../../services/dashboard.services";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";
import { Theme, useTheme } from "@material-ui/core/styles";

// Obtains datasets for day monitor event, draws red blocks for every motion zone
const getDayMonitorDatasets = (
  orderedTimeIntervals: IActivityTime[],
  colorArray: string[],
  startLastInterval: number,
  triggeringValue: number | null,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  // Obtain intervals for the zone, if is the last interval, add the no motion indicator
  const dayMonitorDatasets = orderedTimeIntervals.reduce(
    (result: string[][], data, index) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityUnitTimezone
      );

      let endDate = startDate.plus({ minute: 3 });
      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityUnitTimezone);
      }
      colorArray.push(theme.palette.activityMotion.main);
      result.push([startDate.toISO(), endDate.toISO()]);
      // If is the last interval, add one red bar until end of event
      if (
        index === orderedTimeIntervals.length - 1 &&
        triggeringValue !== null
      ) {
        const startLast = DateTimeFromMillis(
          startLastInterval,
          communityUnitTimezone
        );
        const endLast = startLast.plus({ minute: triggeringValue });
        colorArray.push(theme.palette.error.trans ?? "");
        result.push([startLast.toISO(), endLast.toISO()]);
      }
      return result;
    },
    []
  );

  // If there are no motion values for zone, add last interval
  if (dayMonitorDatasets.length === 0 && triggeringValue !== null) {
    const startLast = DateTimeFromMillis(
      startLastInterval,
      communityUnitTimezone
    );
    const endLast = startLast.plus({ minute: triggeringValue });
    colorArray.push(theme.palette.error.trans ?? "");
    dayMonitorDatasets.push([startLast.toISO(), endLast.toISO()]);
  }

  return dayMonitorDatasets;
};

// Obtains datasets for night monitor event, draws red blocks only for bedroom type zones
const getNightMonitorDatasets = (
  orderedTimeIntervals: IActivityTime[],
  colorArray: string[],
  zoneType: string,
  eventCreationDate: string,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  const nightMonitorDatasets = orderedTimeIntervals.reduce(
    (result: string[][], data, index) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityUnitTimezone
      );
      // Define a default end date, if end date is not null, use it
      let endDate = startDate.plus({ minute: 3 });
      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityUnitTimezone);
      }
      // Store this interval
      colorArray.push(theme.palette.activityMotion.main);
      result.push([startDate.toISO(), endDate.toISO()]);

      // If is the last interval, add one red bar for bedroom type zones until end of event
      if (zoneType === "bedroom") {
        if (
          index === orderedTimeIntervals.length - 1 &&
          orderedTimeIntervals.length > 0
        ) {
          const lastInterval =
            orderedTimeIntervals[orderedTimeIntervals.length - 1];
          // Use end time of last interval if it exists, if not use start time plus 1 minute
          const startLast = lastInterval.end_time
            ? DateTimeFromMillis(
                lastInterval.end_time,
                communityUnitTimezone
              ).plus({ second: 1 })
            : DateTimeFromMillis(
                lastInterval.start_time || 0,
                communityUnitTimezone
              ).plus({
                minute: 1,
              });
          const endLast = DateFromIsoString(
            eventCreationDate,
            communityUnitTimezone
          );
          colorArray.push(theme.palette.error.trans ?? "");
          result.push([startLast.toISO(), endLast.toISO()]);
        }
      }
      // Push color to color array for this index
      return result;
    },
    []
  );

  // If there are no motion intervals for zone and its a bedroom type zone, add one interval
  if (nightMonitorDatasets.length === 0) {
    if (zoneType === "bedroom") {
      // Use start of range as start time for interval
      const startLast = DateFromIsoString(
        eventCreationDate,
        communityUnitTimezone
      ).set({
        hour: 0,
        minute: 0,
        second: 1,
      });
      const endLast = DateFromIsoString(
        eventCreationDate,
        communityUnitTimezone
      );
      colorArray.push(theme.palette.error.trans ?? "");
      nightMonitorDatasets.push([startLast.toISO(), endLast.toISO()]);
    }
  }
  return nightMonitorDatasets;
};

// Obtains datasets for kitchen monitor event
const getKitchenMonitorDatasets = (
  orderedTimeIntervals: IActivityTime[],
  colorArray: string[],
  theme: Theme,
  communityUnitTimezone?: string
) => {
  const kitchenMonitorDatasets = orderedTimeIntervals.reduce(
    (result: string[][], data, index) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityUnitTimezone
      );
      // Define a default end date, if end date is not null, use it
      let endDate = startDate.plus({ minute: 3 });
      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityUnitTimezone);
      }
      // Store this interval
      colorArray.push(theme.palette.activityMotion.main);
      result.push([startDate.toISO(), endDate.toISO()]);

      // Push color to color array for this index
      return result;
    },
    []
  );

  return kitchenMonitorDatasets;
};

// Obtains datasets for refrigerator door open event, draws contact count event blocks
const getDoorOpenDatasets = (
  orderedTimeIntervals: IActivityTime[],
  colorArray: string[],
  theme: Theme,
  communityUnitTimezone?: string
) => {
  const doorOpenDatasets = orderedTimeIntervals.reduce(
    (result: string[][], data, index) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityUnitTimezone
      );
      // Define a default end date, if end date is not null, use it
      let endDate = startDate.plus({ minute: 3 });
      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityUnitTimezone);
      }
      // Store this interval
      colorArray.push(theme.palette.activityMotion.main);
      result.push([startDate.toISO(), endDate.toISO()]);

      return result;
    },
    []
  );

  return doorOpenDatasets;
};

// Obtains the min and max for the activity chart
// DAM: max is greatest end time value round up to nearest hour +1 ,min is max - 6h
// NAM: max 5am local time, min is 12am local time
// KAM: max is the event creation time and min is max -24h
// RDO: max is the event creation time and min is max -1h
const getChartMinMaxByEvent = (
  creationTime: string,
  referenceTime: number,
  eventType: string,
  communityUnitTimezone?: string
) => {
  let maxDateTime = DateFromIsoString(creationTime, communityUnitTimezone);
  let minDateTime = maxDateTime.minus({ hour: 6 });
  switch (eventType) {
    case EventTypes.activity_monitor_day:
      maxDateTime = DateTimeFromMillis(referenceTime, communityUnitTimezone)
        .plus({ hour: 2 })
        .set({ minute: 0 });
      minDateTime = maxDateTime.minus({ hour: 6 });
      return { minDateTime, maxDateTime };
    case EventTypes.activity_monitor_night:
      maxDateTime = maxDateTime
        .set({ hour: 5, minute: 0, second: 0 })
        .set({ minute: 0 });
      minDateTime = maxDateTime.minus({ hour: 5 });
      return { minDateTime, maxDateTime };
    case EventTypes.activity_monitor_kitchen:
      minDateTime = maxDateTime.minus({ hour: 24 });
      return { minDateTime, maxDateTime };
    case EventTypes.refrigerator_door_open:
    case EventTypes.exterior_door_open:
      minDateTime = maxDateTime.minus({ hour: 1 });
      return { minDateTime, maxDateTime };
    default:
      return { minDateTime, maxDateTime };
  }
};

const getHeaderTexts = (eventType: string) => {
  switch (eventType) {
    case EventTypes.activity_monitor_day:
    case EventTypes.activity_monitor_night:
      return {
        title: "room_activity",
        subtitle: "",
      };
    case EventTypes.activity_monitor_kitchen:
      return {
        title: "recent_activity",
        subtitle: "last_24_hours",
      };
    case EventTypes.refrigerator_door_open:
    case EventTypes.exterior_door_open:
      return {
        title: "recent_activity",
        subtitle: "last_1_hour",
      };

    default:
      return {
        title: "room_activity",
        subtitle: "",
      };
  }
};

// Obtains datasets when motion arrays exist for a zone
const getActivityDatasets = (
  activityData: IActivityData,
  eventType: string,
  triggeringValue: number | null,
  startLastInterval: number,
  eventCreationDate: string,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  // Copy and dereference array and orders it ascendingly
  const orderedTimeIntervals = (JSON.parse(
    JSON.stringify(activityData.activity ? activityData.activity : [])
  ) as IActivityTime[]).sort((a, b) => {
    return Number(a.start_time) - Number(b.end_time);
  });

  // Get the last interval for the zone
  let colorArray: string[] = [];
  const defaultData = [[""]];
  let result = {
    color: colorArray,
    stack: StackTypes.MOTION,
    datasets: defaultData,
  };
  switch (eventType) {
    case EventTypes.activity_monitor_day:
      const dayMonitorDataset = getDayMonitorDatasets(
        orderedTimeIntervals,
        colorArray,
        startLastInterval,
        triggeringValue,
        theme,
        communityUnitTimezone
      );
      if (dayMonitorDataset.length > 0) result.datasets = dayMonitorDataset;
      return result;
    case EventTypes.activity_monitor_night:
      const nightMonitorDataset = getNightMonitorDatasets(
        orderedTimeIntervals,
        colorArray,
        activityData.zone_type,
        eventCreationDate,
        theme,
        communityUnitTimezone
      );
      if (nightMonitorDataset.length > 0) result.datasets = nightMonitorDataset;
      return result;
    case EventTypes.activity_monitor_kitchen:
      const kitchenMonitorDataset = getKitchenMonitorDatasets(
        orderedTimeIntervals,
        colorArray,
        theme,
        communityUnitTimezone
      );
      if (kitchenMonitorDataset.length > 0)
        result.datasets = kitchenMonitorDataset;
      return result;
    case EventTypes.refrigerator_door_open:
    case EventTypes.exterior_door_open:
    case EventTypes.hand_off_notification:
      const doorOpenDatasets = getDoorOpenDatasets(
        orderedTimeIntervals,
        colorArray,
        theme,
        communityUnitTimezone
      );
      if (doorOpenDatasets.length > 0) result.datasets = doorOpenDatasets;
      return result;
    default:
      return result;
  }
};

// Obtains datasets when contact arrays exist for a zone
const getContactDatasets = (
  activityData: IActivityData,
  creationTime: string,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  const sensors = activityData.sensors ? activityData.sensors : [];
  // Copy and dereference array and orders it ascendingly
  const contactData = JSON.parse(
    JSON.stringify(activityData.contact ? activityData.contact : [])
  ) as IContact[];

  const contactDatasets = contactData.reduce(
    (result: IFloatingBarDataFormat[], contact) => {
      const sensorID = contact.sensor_id;
      const contactSensor = sensors.find(
        (sensor) => sensor.sensor_id === sensorID
      );
      if (
        contact.activity &&
        contactSensor !== undefined &&
        (contactSensor.sensor_type === ContactSensorTypes.contact ||
          contactSensor.sensor_type === ContactSensorTypes.exterior_door)
      ) {
        const orderedTimeIntervals = contact.activity.sort((a, b) => {
          return Number(a.start_time) - Number(b.end_time);
        });

        // Array of time intervals ordered
        const contactDatasets = orderedTimeIntervals.map((data, index) => {
          const startDate = DateTimeFromMillis(
            data.start_time || 0,
            communityUnitTimezone
          ).minus({
            minute: 1,
          });
          let endDate = startDate.plus({ minute: 2 });
          if (data.end_time) {
            endDate = DateTimeFromMillis(
              data.end_time,
              communityUnitTimezone
            ).plus({ minute: 2 });
          } else if (
            data.end_time === null &&
            index === orderedTimeIntervals.length - 1
          ) {
            endDate = DateFromIsoString(creationTime, communityUnitTimezone);
          }
          return [startDate.toISO(), endDate.toISO()];
        });
        result.push({
          color: theme.palette.contactBlue.main,
          stack: StackTypes.CONTACT,
          datasets: contactDatasets.length > 0 ? contactDatasets : [[""]],
        });
      }
      return result;
    },
    []
  );

  return contactDatasets;
};

// Obtains datasets when contact arrays exist for a zone
const getContactCountDatasets = (
  activityData: IActivityData,
  creationTime: string,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  const sensors = activityData.sensors ? activityData.sensors : [];
  // Copy and dereference array and orders it ascendingly
  const contactCountData = JSON.parse(
    JSON.stringify(activityData.contact_count ? activityData.contact_count : [])
  ) as IContact[];

  const contactCountDatasets = contactCountData.reduce(
    (result: IFloatingBarDataFormat[], contact) => {
      const sensorID = contact.sensor_id;
      const contactCountSensor = sensors.find(
        (sensor) => sensor.sensor_id === sensorID
      );
      if (
        contact.activity &&
        contactCountSensor !== undefined &&
        contactCountSensor.sensor_type === ContactSensorTypes.contact_count
      ) {
        const orderedTimeIntervals = contact.activity.sort((a, b) => {
          return Number(a.start_time) - Number(b.end_time);
        });

        // Array of time intervals ordered
        const contactDatasets = orderedTimeIntervals.map((data, index) => {
          const startDate = DateTimeFromMillis(
            data.start_time || 0,
            communityUnitTimezone
          ).minus({
            minute: 1,
          });
          let endDate = startDate.plus({ minute: 2 });
          if (data.end_time) {
            endDate = DateTimeFromMillis(
              data.end_time,
              communityUnitTimezone
            ).plus({ minute: 2 });
          } else if (
            data.end_time === null &&
            index === orderedTimeIntervals.length - 1
          ) {
            endDate = DateFromIsoString(creationTime, communityUnitTimezone);
          }
          return [startDate.toISO(), endDate.toISO()];
        });
        result.push({
          color: theme.palette.contactBlue.main,
          stack: StackTypes.CONTACT,
          datasets: contactDatasets.length > 0 ? contactDatasets : [[""]],
        });
      }
      return result;
    },
    []
  );

  return contactCountDatasets;
};

export default function ActivityMonitorMotionChart(props: IProps) {
  const { event } = props;
  const communityID = event.community_id ?? undefined;

  /* Hooks */
  const { t } = useTranslation();
  const theme = useTheme();
  const communityUnitTimezone = useCommunityUnitTimezone(
    communityID,
    event.unit_id
  );
  const eventType = event ? event.event_type : "";
  const eventData = JSON.parse(event?.data || "") as IMonitorData;
  const eventCreationDate = event ? event.time_created : "";
  const triggeringValue =
    eventData.triggering_value !== undefined
      ? eventData.triggering_value
      : null;

  const headerTexts = getHeaderTexts(eventType);

  let maxValueFromData = DateTimeNow(communityUnitTimezone).toMillis();
  if (eventCreationDate) {
    maxValueFromData = DateFromIsoString(
      eventCreationDate,
      communityUnitTimezone
    ).toMillis();
  }

  const motionChartData = (JSON.parse(
    JSON.stringify(eventData.activity_data ? eventData.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForMotionSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const contactChartData = (JSON.parse(
    JSON.stringify(eventData.activity_data ? eventData.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForContactSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  let contactCountChartData: IActivityData[] = [];
  if (
    eventType === EventTypes.refrigerator_door_open ||
    eventType === EventTypes.activity_monitor_kitchen ||
    eventType === EventTypes.exterior_door_open
  ) {
    contactCountChartData = (JSON.parse(
      JSON.stringify(eventData.activity_data ? eventData.activity_data : [])
    ) as IActivityData[])
      .filter((activityData) => checkForContactCountSensor(activityData))
      .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));
  }

  const motionLabels = motionChartData.reduce((result: string[], element) => {
    if (element.activity !== undefined) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const contactLabels = contactChartData.reduce((result: string[], element) => {
    if (checkForContactSensor(element)) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const contactCountLabels = contactCountChartData.reduce(
    (result: string[], element) => {
      if (checkForContactCountSensor(element)) {
        result.push(t("contact_type_refrigerator"));
      }
      return result;
    },
    []
  );
  const labels = [...motionLabels, ...contactLabels, ...contactCountLabels];

  const getDataSets = (
    motionOrderedZones: IActivityData[],
    contactOrderedZones: IActivityData[],
    contactCountOrderedZones: IActivityData[],
    triggeringValue: number | null,
    eventCreationDate: string,
    theme: Theme,
    communityUnitTimezone?: string
  ) => {
    const dataSets: IFloatingBarDataFormat[] = [];
    // Obtain the global max date
    const greatestStartDate = motionOrderedZones.reduce(
      (startDateLastInterval: number, activityData) => {
        // Order time intervals
        const orderedTimeIntervals = (JSON.parse(
          JSON.stringify(activityData.activity ? activityData.activity : [])
        ) as IActivityTime[]).sort((a, b) => {
          return Number(a.start_time) - Number(b.end_time);
        });

        // If there are intervals, obtain the greatest (last one)
        if (orderedTimeIntervals.length > 0) {
          const localMax =
            orderedTimeIntervals[orderedTimeIntervals.length - 1];

          if (localMax.end_time) {
            if (localMax.end_time > startDateLastInterval) {
              startDateLastInterval = DateTimeFromMillis(
                localMax.end_time,
                communityUnitTimezone
              )
                .plus({
                  second: 1,
                })
                .toMillis();
            }
          } else if (localMax.start_time) {
            if (localMax.start_time > startDateLastInterval) {
              startDateLastInterval = DateTimeFromMillis(
                localMax.start_time || 0,
                communityUnitTimezone
              )
                .plus({ second: 1 })
                .toMillis();
            }
          }
        }
        return startDateLastInterval;
      },
      -Infinity
    );

    // Store component-wide variable to calculate max range of graph
    if (greatestStartDate !== -Infinity) {
      maxValueFromData = greatestStartDate;
    }

    // Obtain datasets for motion intervals
    const activityDatasets: IFloatingBarDataFormat[] = motionOrderedZones.map(
      (activityData) =>
        getActivityDatasets(
          activityData,
          eventType,
          triggeringValue,
          greatestStartDate,
          eventCreationDate,
          theme,
          communityUnitTimezone
        )
    );

    // Obtain datasets for contact intervals
    const contactDatasets: IFloatingBarDataFormat[] = contactOrderedZones.reduce(
      (result: IFloatingBarDataFormat[], contactData) => {
        if (contactData.contact !== undefined) {
          result.push(
            ...getContactDatasets(
              contactData,
              eventCreationDate,
              theme,
              communityUnitTimezone
            )
          );
        } else {
          if (checkForContactSensor(contactData)) {
            result.push({
              color: theme.palette.contactBlue.main,
              stack: StackTypes.CONTACT,
              datasets: [[]],
            });
          }
        }
        return result;
      },
      []
    );

    // Obtain datasets for contact intervals
    const contactCountDatasets: IFloatingBarDataFormat[] = contactCountOrderedZones.reduce(
      (result: IFloatingBarDataFormat[], contactData) => {
        if (contactData.contact_count !== undefined) {
          result.push(
            ...getContactCountDatasets(
              contactData,
              eventCreationDate,
              theme,
              communityUnitTimezone
            )
          );
        } else {
          if (checkForContactCountSensor(contactData)) {
            result.push({
              color: theme.palette.contactBlue.main,
              stack: StackTypes.CONTACT,
              datasets: [[]],
            });
          }
        }
        return result;
      },
      []
    );

    dataSets.push(
      ...activityDatasets,
      ...contactDatasets,
      ...contactCountDatasets
    );
    return dataSets;
  };

  const datasets = getDataSets(
    motionChartData,
    contactChartData,
    contactCountChartData,
    triggeringValue,
    eventCreationDate,
    theme,
    communityUnitTimezone
  );

  const { minDateTime, maxDateTime } = getChartMinMaxByEvent(
    eventCreationDate,
    maxValueFromData,
    eventType,
    communityUnitTimezone
  );

  const useAtomicHour = !(
    eventType === EventTypes.refrigerator_door_open ||
    eventType === EventTypes.exterior_door_open
  );

  return (
    <LayoutChartContainer
      title={t(headerTexts.title).toUpperCase()}
      complementaryText={
        headerTexts.subtitle !== "" ? t(headerTexts.subtitle) : undefined
      }
      unitID={event.unit_id}
      eventTimeCreated={event.time_created}
      eventType={event.event_type}
      emptyData={labels.length === 0}
    >
      <FloatBarChart
        max={maxDateTime}
        min={minDateTime}
        labels={labels}
        values={datasets}
        useAtomicHour={useAtomicHour}
        communityUnitTimezone={communityUnitTimezone}
      />
    </LayoutChartContainer>
  );
}

interface IProps {
  event: IEvent;
}
