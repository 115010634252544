import { Grid, Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AnalyticsChartTypes, Views } from "../../helpers/constants";
import Icons from "../../helpers/iconImports";

import { useAppSelector } from "../app/appHooks";
import { getUnitTitle } from "../dashboard/CommunityUnit";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    analyticsHeaderContainer: {
      width: (props) => (props.showMaximized ? "calc(100% - 66px)" : "100%"),
      paddingTop: theme.spacing(1.5),
      padding: "12px 0px 0px 16px",
    },
    sectionTitle: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: "bold",
      textAlign: "center",
      paddingLeft: "50%",
    },
    viewPDFContainer: {
      width: "100%",
      textAlign: "right",
      paddingRight: "10px",
    },
    controlBarDetails: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      fontSize: "1.25rem",
      paddingRight: "10px",
    },
    viewPDFButton: {
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
    buttons: {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: 1,
    },
    icon: {
      width: "80%",
    },
  })
);

interface TitleProps {
  listItemText?: {
    unitTitle: string;
    unitSubtitle: string;
  };
  className: string;
}

export const AnalyticsUnitTitle = (props: TitleProps) => (
  <>
    {props.listItemText && (
      <h3 className={props.className}>
        {`${props.listItemText.unitTitle}${
          props.listItemText.unitSubtitle !== ""
            ? ` - ${props.listItemText.unitSubtitle}`
            : ""
        }`}
      </h3>
    )}
  </>
);

export const AnalyticsHeader = (props: IProps) => {
  const { showMaximized } = props;
  /* Hooks */
  const classes = useStyles(props);
  const { t } = useTranslation();
  const history = useHistory();

  /* Selectors */
  const selectedUnit = useAppSelector((state) =>
    state.analyticsState.units?.find(
      (unit) => unit.id === state.analyticsState.selectedUnit
    )
  );
  const chartType = useAppSelector(
    (state) => state.analyticsState.maximizeChart?.type
  );
  const zoom = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.zoom
  );
  const customZoom = useAppSelector(
    (state) => state.analyticsState.maximizedPagination?.customZoom
  );

  let listItemText = undefined;
  if (selectedUnit)
    listItemText = getUnitTitle(selectedUnit, {
      fullFirstName: true,
    });

  const viewPrintable = () => {
    history.push(Views.ANALYTICS_PRINTABLE);
  };

  const hasZoomInWeeks =
    chartType === AnalyticsChartTypes.sleep ||
    chartType === AnalyticsChartTypes.bathroom ||
    chartType === AnalyticsChartTypes.kitchen;

  return (
    <Grid container className={classes.analyticsHeaderContainer}>
      <Grid item xs={8}>
        <AnalyticsUnitTitle
          listItemText={listItemText}
          className={classes.sectionTitle}
        />
      </Grid>
      {!showMaximized && (
        <Grid item xs={4} className={classes.viewPDFContainer}>
          {selectedUnit && (
            <Button
              size="small"
              className={classes.viewPDFButton}
              classes={{ label: classes.buttons }}
              endIcon={
                <img
                  src={Icons.ReportIcon}
                  alt={""}
                  draggable={false}
                  className={classes.icon}
                />
              }
              onClick={viewPrintable}
            >
              {t("view_report")}
            </Button>
          )}
        </Grid>
      )}
      {showMaximized && zoom && (
        <Grid item xs={4}>
          <div className={classes.controlBarDetails}>
            {customZoom === undefined
              ? hasZoomInWeeks
                ? t("week_2")
                : `${zoom} ${t("hour", { count: zoom })}`
              : null}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

interface IProps {
  showMaximized: boolean;
}
