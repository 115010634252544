import { ContactSensorTypes } from "../../../helpers/constants";
import { IActivityData } from "../eventDataTypes";

export const checkForContactSensor = (activityData: IActivityData): boolean => {
  const sensors = activityData.sensors;
  const atLeastOneContact = sensors?.some(
    (sensor) =>
      sensor.sensor_type === ContactSensorTypes.contact ||
      sensor.sensor_type === ContactSensorTypes.exterior_door
  );
  return !!atLeastOneContact;
};

export const checkForMotionSensor = (activityData: IActivityData): boolean => {
  const sensors = activityData.sensors;
  if (sensors === null || sensors === undefined) return true;
  return sensors.some((sensor) => sensor.sensor_type === "motion");
};

export const checkForContactCountSensor = (
  activityData: IActivityData
): boolean => {
  const sensors = activityData.sensors;
  const atLeastOneContact = sensors?.some(
    (sensor) => sensor.sensor_type === ContactSensorTypes.contact_count
  );
  return !!atLeastOneContact;
};
