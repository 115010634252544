import { useAppSelector } from "../../app/appHooks";

function useCommunityUnitTimezone(
  communityID?: string | null | undefined,
  unitID?: string
) {
  const orgUnitTimezone: string | undefined = useAppSelector(
    (state) =>
      state.dashboardState.orgUnits[communityID ?? ""]?.find(
        (unit) => unit.id === unitID
      )?.time_zone
  );

  const unitTimezone: string | null | undefined = useAppSelector(
    (state) =>
      state.headerState.units?.find((unit) => unit.id === unitID)?.time_zone
  );
  const communityTimezone: string | null | undefined = useAppSelector(
    (state) =>
      state.headerState.communities?.find(
        (community) => community.id === communityID
      )?.time_zone
  );
  return orgUnitTimezone ?? unitTimezone ?? communityTimezone;
}

export default useCommunityUnitTimezone;
