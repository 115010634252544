import "chartjs-adapter-luxon";
import zoomPlugin from "chartjs-plugin-zoom";
import { useTheme } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { Bar, defaults, Chart } from "react-chartjs-2";

import i18n from "../../i18n";
import { DateFromIsoString, timeConfigurations } from "../../helpers/datetime";
import {
  StackTypes,
  midBlurWindow,
  noBlurWindow,
} from "../../helpers/constants";
import usePan from "./usePan";
import useZoom from "./useZoom";
import { getDateTimeFormat, timeDiffInHours } from "./LineChart";
import { useAppSelector } from "../app/appHooks";

Chart.register(zoomPlugin); // REGISTER PLUGIN

defaults.font.family = "GothamRoundedBook";

const displayLegends = (values: IFloatingBarDataFormat[]) => {
  let displayLegends = true;
  // Obtains an array of unique values. This values indicate wether there are more than one unique legends.
  const uniqueLegends = Array.from(new Set(values.map((item) => item.stack)));

  if (uniqueLegends.length === 0) displayLegends = false;
  if (uniqueLegends.length === 1 && uniqueLegends[0] === StackTypes.MOTION)
    displayLegends = false;

  return displayLegends;
};

const transformData = (values: IFloatingBarDataFormat[]) => {
  return values.flatMap((val, index: number) => {
    const datasets = val.datasets.map((value: string[], _index: number) => {
      const dataDisplay = [];
      dataDisplay[index] = value;
      return {
        label: `${val.stack}-${index}-${_index}`,
        data: dataDisplay,
        backgroundColor:
          typeof val.color === "string" ? val.color : val.color[_index],
      };
    });
    return datasets;
  });
};

export default function FloatBarChart(props: IProps) {
  const {
    min,
    max,
    labels,
    values,
    noBlurValues,
    midBlurValues,
    showLegends,
    animation,
    communityUnitTimezone,
    zoomEnabled,
    panEnabled,
    maxChartLimit,
    minChartLimit,
    useDefaultTimeConfig,
  } = props;
  const { timeZone, locale } = timeConfigurations(communityUnitTimezone);
  const theme = useTheme();

  const useAtomicHour =
    props.useAtomicHour !== undefined ? props.useAtomicHour : true;

  const dateConfigAxis1 = getDateTimeFormat(
    { maxTime: max, minTime: min },
    useDefaultTimeConfig
  );

  const dateConfigAxis2 = {
    unit: "hour",
    displayFormats: { hour: "LLL d" },
    stepSize: 2,
  };

  const timeDiffHours = timeDiffInHours(max, min);
  const showDays = max && min ? timeDiffHours >= 24 : false;

  const maxLimit =
    maxChartLimit !== undefined
      ? DateFromIsoString(maxChartLimit, timeZone).toMillis()
      : undefined;
  const minLimit =
    minChartLimit !== undefined
      ? DateFromIsoString(minChartLimit, timeZone).toMillis()
      : undefined;

  if (!useAtomicHour) {
    dateConfigAxis1.stepSize = 4;
  }

  const _displayLegends =
    showLegends === undefined ? displayLegends(values) : showLegends;

  const fontSize = labels.length <= 7 ? 12 : 11;

  const zoomOptions = useZoom(zoomEnabled);
  const panOptions = usePan("x", false, panEnabled);

  const adjustedMin = min.minus({
    minutes: min.minute % dateConfigAxis1.stepSize,
  });

  const options = {
    // If the range limit is less than 2 hours, the x axis shows intervals of 15 min
    // if the range is less than 30 min, the x axis shows intervals of 5 min
    // to show it this way, the min limit has to start in an even value multiple of 15 or 5
    responsive: true,
    indexAxis: "y",
    animation: animation !== undefined ? animation : true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: false },
      zoom: {
        limits: {
          x: { min: minLimit, max: maxLimit },
        },
        zoom: zoomOptions,
        pan: panOptions,
        pinch: {
          enabled: !!zoomEnabled,
        },
      },
      legend: {
        display: _displayLegends,
        // this toggles on / off the confidence intervals
        labels: {
          generateLabels(chart: any) {
            const datasets = chart.data.datasets;
            const {
              labels: { usePointStyle, pointStyle, textAlign, color },
            } = chart.legend.options;

            const defaultSettings = chart
              ._getSortedDatasetMetas()
              .map((meta: any) => {
                const style = meta.controller.getStyle(
                  usePointStyle ? 0 : undefined
                );
                return {
                  text: datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  fontColor: color,
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 10 / 4,
                  strokeStyle: style.backgroundColor,
                  pointStyle: pointStyle || style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0,
                  datasetIndex: meta.index,
                };
              }, this);

            const newSettings = defaultSettings.reduce(
              (result: any, element: any) => {
                if (element.text.includes("motion")) {
                  const motionIndex = result.findIndex(
                    (el: any) => el.text === i18n.t("motion")
                  );
                  if (motionIndex < 0) {
                    const newLegendItem = {
                      ...element,
                      fillStyle: theme.palette.activityMotion.main,
                      strokeStyle: theme.palette.activityMotion.main,
                      text: i18n.t("motion"),
                      datasetIndex: [element.datasetIndex],
                    };
                    result.push(newLegendItem);
                  } else {
                    result[motionIndex].datasetIndex.push(element.datasetIndex);
                  }
                }

                if (element.text.includes("contact")) {
                  const contactIndex = result.findIndex(
                    (el: any) => el.text === i18n.t("door")
                  );
                  if (contactIndex < 0) {
                    const newLegendItem = {
                      ...element,
                      fillStyle: theme.palette.contactBlue.main,
                      strokeStyle: theme.palette.contactBlue.main,
                      text: i18n.t("door"),
                      datasetIndex: [element.datasetIndex],
                    };
                    result.push(newLegendItem);
                  } else {
                    result[contactIndex].datasetIndex.push(
                      element.datasetIndex
                    );
                  }
                }
                return result;
              },
              []
            );
            return newSettings;
          },
        },
        onClick(e: any, legendItem: any, legend: any) {
          return void undefined;
        },
        position: "bottom",
      },
    },
    title: {
      display: false,
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          font: {
            size: fontSize,
          },
        },
      },
      x: {
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        stacked: false,
        min: adjustedMin.toMillis(),
        max: max.toMillis(),
        time: dateConfigAxis1,
      },
      x2: {
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        min: adjustedMin.toMillis(),
        max: max.toMillis(),
        time: dateConfigAxis1,
        ticks: {
          display: false,
        },
        grid: {
          borderDash: [8, 4],
          drawBorder: false,
          drawTicks: false,
          offset: true,
        },
      },
      x1: {
        display: showDays,
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        min: min.toMillis(),
        max: max.toMillis(),
        time: dateConfigAxis2,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: (value: string, index: number, values: any[]) => {
            return index === 5 ? value : "";
          },
        },
      },
    },
  };
  const dataTest = values.flatMap((val, index: number) => {
    const datasets = val.datasets.map((value: string[], _index: number) => {
      const dataDisplay = [];
      dataDisplay[index] = value;
      return {
        label: `${val.stack}-${index}-${_index}`,
        data: dataDisplay,
        backgroundColor:
          typeof val.color === "string" ? val.color : val.color[_index],
      };
    });
    return datasets;
  });

  let data = {
    labels: labels,
    datasets: dataTest,
  };

  const pagenation = useAppSelector(
    (state) => state.analyticsState.maximizedPagination
  );

  if (pagenation && noBlurValues && midBlurValues) {
    const dateTime1 = DateTime.fromISO(pagenation.localStart);
    const dateTime2 = DateTime.fromISO(pagenation.localEnd);
    const differenceInHours = dateTime2.diff(dateTime1, "hours").hours;
    let newData = null;
    if (differenceInHours < noBlurWindow) {
      newData = transformData(noBlurValues);
    } else if (differenceInHours <= midBlurWindow) {
      newData = transformData(midBlurValues);
    }
    if (newData !== null) {
      data = {
        labels: labels,
        datasets: newData,
      };
    }
  }
  return (
    <Bar type="bar" data={data} options={options} width={100} height={300} />
  );
}

export interface IFloatingBarDataFormat {
  color: string | string[];
  stack: string;
  datasets: string[][];
}

interface IProps {
  min: DateTime;
  max: DateTime;
  labels: string[];
  values: IFloatingBarDataFormat[];
  noBlurValues?: IFloatingBarDataFormat[];
  midBlurValues?: IFloatingBarDataFormat[];
  useAtomicHour?: boolean;
  showLegends?: boolean;
  animation?: boolean;
  communityUnitTimezone?: string;
  zoomEnabled?: boolean;
  panEnabled?: boolean;
  maxChartLimit?: string;
  minChartLimit?: string;
  useDefaultTimeConfig?: boolean;
}
