import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-luxon";
import type {} from "css-font-loading-module";
import { DateTime } from "luxon";
import { Bar, defaults, Chart } from "react-chartjs-2";
import { useTheme } from "@material-ui/core/styles";

import i18n from "../../i18n";
import {
  DateFromIsoString,
  DateTimeISO,
  HourFormat,
  HourMinuteFormat,
  timeConfigurations,
} from "../../helpers/datetime";
import { SleepStatus, truncate } from "../../helpers/constants";
import usePan from "./usePan";
import { ChartMode } from "../analytics/AnalyticsViewerCharts";

Chart.register(zoomPlugin); // REGISTER PLUGIN

defaults.font.family = "GothamRoundedBook";

export default function FloatBarChartY(props: IProps) {
  const {
    min,
    max,
    labels,
    status,
    values,
    communityUnitTimezone,
    animation,
    panEnabled,
    maxChartLimit,
    minChartLimit,
    chartMode,
  } = props;
  const { timeZone, locale } = timeConfigurations(communityUnitTimezone);
  const theme = useTheme();

  const useAtomicHour =
    props.useAtomicHour !== undefined ? props.useAtomicHour : true;

  const panOptions = usePan("y", true, panEnabled);
  const maxLimit =
    maxChartLimit !== undefined
      ? DateFromIsoString(maxChartLimit, timeZone).toMillis()
      : undefined;
  const minLimit =
    minChartLimit !== undefined
      ? DateFromIsoString(minChartLimit, timeZone).toMillis()
      : undefined;

  const options = {
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    responsive: true,
    animation: animation !== undefined ? animation : true,
    indexAxis: "y",
    maintainAspectRatio: false,
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: false,
      },
      zoom: {
        limits: {
          y: { min: minLimit, max: maxLimit },
          y1: { min: minLimit, max: maxLimit },
        },
        pan: panOptions,
      },
    },
    title: {
      display: false,
    },

    scales: {
      y: {
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        display: true,
        position: "left",
        time: {
          unit: "day",
          displayFormats: {
            day: `LLL dd`,
          },
        },
      },
      y1: {
        display: true,
        position: "right",
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        time: {
          unit: "day",
          displayFormats: {
            day: `LLL dd`,
          },
        },
        ticks: {
          autoSkip: false,
          callback: function (value: any, index: any, ticks: any) {
            return DateTime.fromMillis(ticks[index].value, {
              zone: timeZone,
            })
              .setLocale(locale)
              .plus({ day: 1 })
              .toFormat(`LLL dd`);
          },
        },
      },
      x: {
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        stacked: false,
        min: min.toISO(),
        max: max.toISO(),
        time: {
          unit: "hour",
          displayFormats: {
            hour: useAtomicHour ? HourFormat() : HourMinuteFormat(),
          },
          stepSize: 2,
        },
      },
      x2: {
        type: "time",
        adapters: {
          date: {
            zone: timeZone,
            locale: locale,
          },
        },
        stacked: false,
        min: min.toISO(),
        max: max.toISO(),
        time: {
          unit: "hour",
          displayFormats: {
            hour: useAtomicHour ? HourFormat() : HourMinuteFormat(),
          },
          stepSize: 2,
        },
        ticks: {
          display: false,
        },
        grid: {
          borderDash: [8, 4],
          drawBorder: false,
          drawTicks: false,
          offset: true,
        },
      },
    },
  };

  const isMaximised = chartMode === ChartMode.maximized;
  const data = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx?.createLinearGradient(
      0,
      0,
      ctx?.canvas?.clientWidth || 200,
      0
    );
    gradient?.addColorStop(0, theme.palette.gradientDay.dark);
    gradient?.addColorStop(0.8, theme.palette.gradientDay.light);
    const errorGradient = ctx?.createLinearGradient(
      0,
      0,
      ctx?.canvas?.clientWidth || 200,
      0
    );
    errorGradient?.addColorStop(0, theme.palette.error.main);
    errorGradient?.addColorStop(0.5, theme.palette.error.light);
    return {
      labels: labels,
      font: {
        family: "GothamRoundedBook",
      },
      datasets: [
        {
          stack: "sleep",
          data: values.map((val) => [...val]),
          backgroundColor: status.map((val) =>
            val === SleepStatus.normal ? gradient : errorGradient
          ),
          barThickness: isMaximised ? 22 : 20,
          datalabels: {
            font: {
              size: isMaximised ? 14 : 12,
            },
            labels: {
              value: {
                clip: true,
                color: theme.palette.dark.main,
                font: {
                  family: theme.typography.tertiaryFontFamily,
                },
                align: "center",
                formatter: (value: string[]) => {
                  if (Array.isArray(value) && value.length > 0) {
                    const sleepStatus = value[2];
                    if (sleepStatus !== SleepStatus.noData) {
                      const initialDate = DateTimeISO(value[0]);
                      const finalDate = DateTimeISO(value[1]);
                      const diff = finalDate.diff(initialDate, ["hours"]);
                      let result = `${truncate(
                        diff.toMillis() / (1000 * 60 * 60)
                      )} ${i18n.t("hours")}`;
                      if (sleepStatus === SleepStatus.late) {
                        result = result.concat(
                          ` (${i18n.t("late").toLocaleLowerCase()})`
                        );
                      }
                      if (sleepStatus === SleepStatus.nightEvent) {
                        result = result.concat(` ⚠️`);
                      }
                      return result;
                    } else {
                      return `?`;
                    }
                  } else {
                    return "";
                  }
                },
              },
            },
          },
        },
      ],
    };
  };

  // Update chart when fonts are loaded to prevent need to hover for fonts to appear correctly
  const chartRef = React.useRef();
  document.fonts.ready.then(() => {
    if (chartRef.current) {
      (chartRef.current as any)?.update();
    }
  });

  return (
    <Bar
      type="bar"
      data={data}
      ref={chartRef}
      plugins={[ChartDataLabels]}
      options={options}
      width={100}
      height={40}
    />
  );
}
interface IProps {
  min: DateTime;
  max: DateTime;
  labels: string[];
  status: string[];
  values: string[][];
  useAtomicHour?: boolean;
  communityUnitTimezone?: string;
  animation?: boolean;
  panEnabled?: boolean;
  maxChartLimit?: string;
  minChartLimit?: string;
  chartMode: ChartMode;
}
