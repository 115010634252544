import { DateTime } from "luxon";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { ChartMode } from "./AnalyticsViewerCharts";
import { ChartControlBar } from "./ChartControlBar";
import { ChartPicker } from "./charts/ChartPicker";
import {
  chartZoomIn,
  chartZoomOut,
  panChart,
  ZoomValues,
} from "./analyticsSlice";
import {
  expandAnalyticsChart,
  getChartDataByDate,
  getChartDataDown,
  getChartDataLeft,
  getChartDataRight,
  getChartDataUp,
} from "./analyticsThunks";
import { AnalyticsChartTypes } from "../../helpers/constants";
import { IBathroomEventArray } from "../dashboard/eventDataTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerFullHeight: {
      height: "100%",
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(1),
    },
    maximizedChartContainer: {
      height: "100%",
      padding: theme.spacing(0.5, 0, 1, 2),
      display: "flex",
      flexWrap: "nowrap",
    },
    chartCard: {
      backgroundColor: theme.palette.light.light,
      borderRadius: "5px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  })
);

export const AnalyticsViewerMaximized = (props: IProps) => {
  const { lastUpdated } = props;

  /* Hooks */
  const dispatch = useAppDispatch();
  const classes = useStyles();

  /* Selectors */
  const chartData = useAppSelector(
    (state) => state.analyticsState.maximizeChart
  );
  const bathroomZoneId = useAppSelector(
    (state) => state.analyticsState.bathroomZoneId
  );

  const pagination = useAppSelector(
    (state) => state.analyticsState.maximizedPagination
  );

  const handleZoomIn = (zoom: ZoomValues) => {
    dispatch(chartZoomIn(zoom));
  };
  const handleZoomOut = (zoom: ZoomValues) => {
    dispatch(chartZoomOut(zoom));
  };

  const handlePanLeft = () => {
    if (pagination) {
      const targetStartTime = DateTime.fromISO(pagination.localStart, {
        zone: "utc",
      }).minus({ hours: pagination.customZoom ?? pagination.zoom });

      const targetEndTime = DateTime.fromISO(pagination.localEnd, {
        zone: "utc",
      }).minus({ hours: pagination.customZoom ?? pagination.zoom });

      // Date until we have loaded data
      const currentStartTime = DateTime.fromISO(pagination.start, {
        zone: "utc",
      });

      // If target is before than current start time, we need to load
      if (targetStartTime < currentStartTime) {
        const chartPaginationData = {
          chartType: chartData?.type ?? "",
          localStartTime: targetStartTime.toISO(),
          localEndTime: targetEndTime.toISO(),
        };
        dispatch(getChartDataLeft(chartPaginationData));
      } else {
        const chartPaginationData = {
          startTime: targetStartTime.toISO(),
          endTime: targetEndTime.toISO(),
        };
        dispatch(panChart(chartPaginationData));
      }
    }
  };

  const handlePanRight = () => {
    if (pagination) {
      const currentTime = DateTime.fromISO(pagination.currentTime, {
        zone: "utc",
      });
      const targetStartTime = DateTime.fromISO(pagination.localStart, {
        zone: "utc",
      }).plus({ hours: pagination.customZoom ?? pagination.zoom });

      const targetEndTime = DateTime.fromISO(pagination.localEnd, {
        zone: "utc",
      }).plus({ hours: pagination.customZoom ?? pagination.zoom });

      // Date until we have loaded data
      const endTime = DateTime.fromISO(pagination.end, {
        zone: "utc",
      });
      // If we don't need to get data, just pan right
      if (targetEndTime < endTime) {
        const chartPaginationData = {
          startTime: targetStartTime.toISO(),
          endTime: targetEndTime.toISO(),
        };
        dispatch(panChart(chartPaginationData));
      } else {
        if (endTime === currentTime) {
          const chartPaginationData = {
            startTime: endTime
              .minus({ hours: pagination.customZoom ?? pagination.zoom })
              .toISO(),
            endTime: endTime.toISO(),
          };
          dispatch(panChart(chartPaginationData));
        } else {
          let chartPaginationData = {
            chartType: chartData?.type ?? "",
            localStartTime: targetStartTime.toISO(),
            localEndTime: targetEndTime.toISO(),
          };
          dispatch(getChartDataRight(chartPaginationData));
        }
      }
    }
  };

  const handlePanUp = () => {
    if (pagination) {
      const currentTime = DateTime.fromISO(pagination.currentTime, {
        zone: "utc",
      });
      const targetStartTime = DateTime.fromISO(pagination.localStart, {
        zone: "utc",
      }).plus({ weeks: pagination.zoom });

      const targetEndTime = DateTime.fromISO(pagination.localEnd, {
        zone: "utc",
      }).plus({ weeks: pagination.zoom });

      // Date until we have loaded data
      const endTime = DateTime.fromISO(pagination.end, {
        zone: "utc",
      });
      // If we don't need to get data, just pan right
      if (targetEndTime < endTime) {
        const chartPaginationData = {
          startTime: targetStartTime.toISODate(),
          endTime: targetEndTime.toISODate(),
        };
        dispatch(panChart(chartPaginationData));
      } else {
        if (endTime === currentTime) {
          const chartPaginationData = {
            startTime: endTime
              .minus({ weeks: pagination.customZoom ?? pagination.zoom })
              .toISO(),
            endTime: endTime.toISODate(),
          };
          dispatch(panChart(chartPaginationData));
        } else {
          let chartPaginationData = {
            chartType: chartData?.type ?? "",
            localStartTime: targetStartTime.toISO(),
            localEndTime: targetEndTime.toISO(),
          };
          dispatch(getChartDataUp(chartPaginationData));
        }
      }
    }
  };
  const handlePanDown = () => {
    if (pagination) {
      const targetStartTime = DateTime.fromISO(pagination.localStart, {
        zone: "utc",
      }).minus({ weeks: pagination.zoom });

      const targetEndTime = DateTime.fromISO(pagination.localEnd, {
        zone: "utc",
      }).minus({ weeks: pagination.zoom });

      // Date until we have loaded data
      const startTime = DateTime.fromISO(pagination.start, {
        zone: "utc",
      });
      // If we don't need to get data, just pan down

      if (targetStartTime > startTime) {
        const chartPaginationData = {
          startTime: targetStartTime.toISODate(),
          endTime: targetEndTime.toISODate(),
        };
        dispatch(panChart(chartPaginationData));
      } else {
        let chartPaginationData = {
          chartType: chartData?.type ?? "",
          localStartTime: targetStartTime.toISODate(),
          localEndTime: targetEndTime.toISODate(),
        };
        dispatch(getChartDataDown(chartPaginationData));
      }
    }
  };

  const handleCalendarDateChange = (newDate: DateTime) => {
    if (pagination) {
      const targetStartTime = DateTime.fromISO(pagination.localStart, {
        zone: "utc",
      }).set({ year: newDate.year, month: newDate.month, day: newDate.day });

      const targetEndTime = targetStartTime.plus({
        hours: pagination.customZoom ?? pagination.zoom,
      });

      const chartPaginationData = {
        localStartTime: targetStartTime.toISO(),
        localEndTime: targetEndTime.toISO(),
        chartType: chartData?.type ?? "",
      };

      dispatch(getChartDataByDate(chartPaginationData));
    }
  };
  const handleCalendarToday = () => {
    if (chartData) {
      dispatch(expandAnalyticsChart({ chartType: chartData.type }));
    }
  };

  const chartPagination = pagination
    ? {
        startTime: pagination.localStart,
        endTime: pagination.localEnd,
      }
    : undefined;

  /* Bathroom and kitchen charts use date-only panning,
   but unlike sleep these charts pan left and right, 
   so we map date pan functions to these buttons */
  const isBathroom = chartData?.type === AnalyticsChartTypes.bathroom;
  const isKitchen = chartData?.type === AnalyticsChartTypes.kitchen;

  let chartValues = chartData?.data;
  let hasMultipleBathrooms = undefined;
  if (isBathroom && chartData) {
    const bathData: IBathroomEventArray[] = chartData.data;
    hasMultipleBathrooms = bathData.length > 1;
    if (bathroomZoneId) {
      const bathroomZoneData = bathData.find(
        (item) => item.zone_id === bathroomZoneId
      );
      if (bathroomZoneData) chartValues = bathroomZoneData;
    } else {
      if (bathData.length > 0) chartValues = bathData[0];
    }
  }

  return (
    <div className={classes.maximizedChartContainer}>
      {chartData && (
        <ChartControlBar
          type={chartData.type}
          panLeft={isBathroom || isKitchen ? handlePanDown : handlePanLeft}
          panRight={isBathroom || isKitchen ? handlePanUp : handlePanRight}
          panUp={handlePanUp}
          panDown={handlePanDown}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          onCalendarDateChange={handleCalendarDateChange}
          onCalendarToday={handleCalendarToday}
        >
          <div className={classes.chartCard}>
            <ChartPicker
              type={chartData.type}
              data={chartValues}
              mode={ChartMode.maximized}
              pagination={chartPagination}
              lastUpdated={lastUpdated}
              isPrintable={false}
              multipleBathrooms={hasMultipleBathrooms}
            />
          </div>
        </ChartControlBar>
      )}
    </div>
  );
};

interface IProps {
  lastUpdated?: string;
}
