import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Views } from "../../helpers/constants";
import { useAppSelector } from "./appHooks";

// Prevents users that are not logged from accessing pages that required to be logged in
const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const isLogged = useAppSelector((state) => state.loginState.isLogged);
  const validUserRole = useAppSelector(
    (state) =>
      state.headerState.user === undefined || state.headerState.validUserRole
  );
  const isOrganization = useAppSelector(
    (state) => !!state.headerState.selectedOrganization
  );
  const allNotifications = useAppSelector(
    (state) => state.headerState.allNotifications
  );

  const allNotificationRedirect =
    allNotifications &&
    props.path !== Views.DASHBOARD &&
    props.path !== Views.SETTINGS;

  const orgRedirect =
    isOrganization &&
    props.path !== Views.DASHBOARD &&
    props.path !== Views.SETTINGS;

  const roleRedirect = !validUserRole && props.path !== Views.DASHBOARD;
  return isLogged ? (
    orgRedirect || roleRedirect || allNotificationRedirect ? (
      <Redirect to={Views.DASHBOARD} />
    ) : (
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
    )
  ) : (
    <Redirect to={Views.LOGIN} />
  );
};
export default PrivateRoute;
