import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import logo from "../../assets/img/logo.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Badge, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import UserAvatar from "./UserAvatar";
import { getHeaderInformation } from "./headerThunks";
import { IAppState } from "../../helpers/store";
import {
  MaintenanceTypes,
  ReducerStates,
  RoleTypes,
  ValidBannerTypes,
  Views,
} from "../../helpers/constants";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import NavigationPanel from "./NavigationPanel";
import CommunityDropdown from "./CommunityDropdown";
import { useHistory, useLocation } from "react-router-dom";
import useOnlineStatus from "react-online-hook";
import { hideSoftNotification, showSoftNotification } from "../app/appSlice";
import { useAppDispatch } from "../app/appHooks";
import clsx from "clsx";
import { SoftNotificationTypes } from "../common/AppSnack";
import { useTranslation } from "react-i18next";
import useInterval from "../app/hooks/useInterval";
import { loadDeviceIssuesBadge } from "../devices/devicesThunks";
import { SystemStatus } from "../../services/dashboard.services";
import { getFirebaseDBReference } from "../../helpers/firebase";
import BannerDialog from "../app/BannerDialog";
import { updateBannerState } from "./headerSlice";
import { minToolbarHeight } from "../app/App";

// const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.dark.main,
      zIndex: theme.zIndex.appBar,
      height: (showBanner) => minToolbarHeight(showBanner, theme),
    },
    headerContainer: {
      justifyContent: "space-between",
    },
    title: {
      fontSize: 14,
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
    },
    logoImg: {
      maxWidth: "100%",
      display: "block",
      height: "auto",
      maxHeight: "40px",
      marginLeft: theme.spacing(1),
    },
    communityContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: theme.spacing(2),
    },
    iconButtonRoot: {
      "&.Mui-disabled": {
        opacity: 0.3,
        color: "inherit",
      },
    },
    isFrame: {
      top: "-110px",
    },
    banner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.error.light,
      padding: theme.spacing(2),
      height: "40px",
      cursor: "pointer",
    },
    bannerText: {
      opacity: "0.60",
      color: "black",
    },
    [`@media print`]: {
      root: {
        display: "none",
      },
      headerContainer: {
        display: "none",
      },
    },
  })
);

export const showHeader = (pathname: string) =>
  pathname !== Views.LOGIN && pathname !== Views.RESET_PASSWORD;

/**
 * Header component
 * @param {string} community Active community
 */

const MINUTE_MS = 120000;

const showOutageBanner = (systemStatus: SystemStatus | null) => {
  if (systemStatus && systemStatus.status) {
    return Object.values(ValidBannerTypes).includes(
      systemStatus.status as ValidBannerTypes
    );
  } else {
    return false;
  }
};

export default function Header() {
  const showBanner = useSelector(
    (state: IAppState) => state.headerState.showBanner
  );

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isOnline } = useOnlineStatus();
  const { t, i18n } = useTranslation();

  /* ---- State ---- */
  const [open, setOpen] = useState(false);
  const [systemStatus, setSystemStatus] = useState<SystemStatus | null>(null);
  const [isBannerOpen, setBannerOpen] = useState(false);
  const [wasOffline, setIsOffline] = React.useState(false);

  /* ---- Selectors ---- */
  const headerStatus = useSelector(
    (state: IAppState) => state.headerState.state
  );
  const communities = useSelector(
    (state: IAppState) => state.headerState.communities
  );

  const softNotificationStatus = useSelector(
    (state: IAppState) => state.appState.softNotificationToggle
  );

  const isFrame = useSelector(
    (state: IAppState) => state.loginState.origin !== undefined
  );

  const language = useSelector(
    (state: IAppState) => state.headerState.user?.language
  );

  const communityID = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity?.id
  );
  const deviceIssues = useSelector(
    (state: IAppState) => state.devicesState.devicesIssues?.length
  );
  const systemIssues = useSelector((state: IAppState) =>
    state.devicesState.systemIssues?.valueOf()
  );
  const installerRole = useSelector(
    (state: IAppState) => state.headerState.installerRole
  );

  const selectedCommunity = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity
  );

  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );

  const isCaregiver = !!selectedCommunity?.role.includes(RoleTypes.caregiver);

  const issueCount = installerRole ? systemIssues : deviceIssues;

  /* ---- Effects ---- */
  useEffect(() => {
    if (headerStatus === ReducerStates.IDLE && showHeader(location.pathname)) {
      dispatch(getHeaderInformation());
    }
  }, [headerStatus, dispatch, location.pathname]);

  useEffect(() => {
    if (
      headerStatus === ReducerStates.SUCCEEDED &&
      communities !== undefined &&
      communities.length === 0
    ) {
      history.push(Views.DASHBOARD);
    }
  }, [communities, headerStatus, history, location.pathname]);

  useEffect(() => {
    if (!isOnline && !softNotificationStatus) {
      dispatch(showSoftNotification(SoftNotificationTypes.CONNECTION_OFFLINE));
      setIsOffline(true);
    }
  }, [dispatch, isOnline, softNotificationStatus]);

  useEffect(() => {
    if (isOnline && softNotificationStatus && wasOffline) {
      dispatch(hideSoftNotification());
      setIsOffline(false);
    }
  }, [dispatch, wasOffline, isOnline, softNotificationStatus]);

  useEffect(() => {
    //Check if language in local storage has changed and load corresponding translation
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  useEffect(() => {
    const statusRef = getFirebaseDBReference().ref(`status`);
    const listener = statusRef.on("value", (snapshot) => {
      const status: SystemStatus = snapshot.val();
      setSystemStatus(status);
      if (status && status.status) {
        dispatch(updateBannerState(showOutageBanner(status)));
      } else {
        dispatch(updateBannerState(false));
      }
    });
    return () => statusRef.off("value", listener);
  }, [dispatch, firebaseAuth]);

  // Request devices issues
  useEffect(() => {
    if (communityID !== undefined && !isCaregiver) {
      dispatch(loadDeviceIssuesBadge(communityID));
    }
  }, [communityID, dispatch, isCaregiver]);

  useInterval(() => {
    if (communityID !== undefined && !isCaregiver && isOnline) {
      dispatch(loadDeviceIssuesBadge(communityID));
    }
  }, MINUTE_MS);

  /* ---- Methods ---- */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const openBanner = () => {
    setBannerOpen(true);
  };

  const closeBanner = () => {
    setBannerOpen(false);
  };

  const getStatusMessage = (status: string) => {
    switch (status) {
      case MaintenanceTypes.maintenanceInProgress:
        return t("maintenance_in_progress");
      case MaintenanceTypes.outageLTE:
        return t("outage_lte");
      case MaintenanceTypes.outageNotifications:
        return t("outage_notifications");
      case MaintenanceTypes.outageData:
        return t("outage_data");
      case MaintenanceTypes.outageBackend:
        return t("outage_backend");
      default:
        return t("system_outage");
    }
  };

  let statusTitle = "";
  if (systemStatus && systemStatus.status) {
    statusTitle = getStatusMessage(systemStatus.status);
  }

  const enabledMenu = communities !== undefined && communities.length > 0;

  return (
    <React.Fragment>
      {showHeader(location.pathname) && (
        <React.Fragment>
          <AppBar
            className={clsx(classes.root, isFrame && classes.isFrame)}
            position={isFrame ? "absolute" : "sticky"}
          >
            <Toolbar>
              <Grid container className={classes.headerContainer}>
                <Grid item xs={3} className={classes.logoContainer}>
                  {!open && (
                    <React.Fragment>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        disabled={!enabledMenu}
                        classes={{ disabled: classes.iconButtonRoot }}
                      >
                        <Badge badgeContent={issueCount ?? 0} color="primary">
                          <MenuIcon fontSize="large" />
                        </Badge>
                      </IconButton>
                      <img
                        src={logo}
                        alt="StackCare Logo"
                        className={classes.logoImg}
                        draggable={false}
                      />
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={6} className={classes.communityContainer}>
                  <CommunityDropdown />
                </Grid>
                <Grid item xs={3} className={classes.avatarContainer}>
                  <UserAvatar />
                </Grid>
              </Grid>
            </Toolbar>
            {showOutageBanner(systemStatus) && (
              <div className={classes.banner} onClick={openBanner}>
                <Typography className={classes.bannerText}>
                  {statusTitle}
                </Typography>
              </div>
            )}
          </AppBar>

          <NavigationPanel
            open={open}
            handleNavigationClose={handleDrawerClose}
          />

          {systemStatus && (
            <BannerDialog
              toggle={isBannerOpen}
              title={statusTitle}
              status={systemStatus}
              onClose={closeBanner}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
