import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Divider, List, ListItem, Radio } from "@material-ui/core";
import clsx from "clsx";
import { IRoleTypes } from "../../../services/header.services";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverHeader: {
      display: "flex",
    },
    listRoot: {
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
    },
    popoverContainer: {
      maxWidth: "360px",
      minWidth: "340px",
      maxHeight: "500px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    optionTitle: {
      display: "flex",
      fontWeight: "bold",
    },
    optionDescription: {
      display: "flex",
      marginTop: theme.spacing(0.5),
      marginBottom: 0,
    },
    contentContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(0.5),
    },
    itemBorder: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  })
);

const CustomRadio = withStyles(({ spacing, palette }) => ({
  root: {
    alignItems: "flex-start",
    padding: 0,
    marginLeft: spacing(0.5),
    minWidth: "25px",
    color: palette.primary.main,
    "&$checked": {
      color: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  checked: {},
}))(Radio);

export const roleLabels: { [key: string]: string } = {
  admin: "admin",
  caregiver: "caregiver",
  family: "family",
  "pro:family": "family",
  installer: "installer_maintenance",
};

export const roleDescriptionsLabels: { [key: string]: string } = {
  admin: "admin_description",
  caregiver: "caregiver_description",
  family: "family_description",
  "pro:family": "family_description",
  installer: "installer_description",
};

export const RolePicker = (props: IProps) => {
  const { value, userRoles, handleChange } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={classes.popoverHeader}>
        <ListItem
          className={classes.listRoot}
          key={"search-select-all"}
          id={"popover-header-role"}
        >
          {t("role").toUpperCase()}
        </ListItem>
      </div>
      <Divider />
      <List aria-label="role type list" className={classes.popoverContainer}>
        {userRoles?.map((option, index, arr) => {
          return (
            <ListItem
              classes={{ root: classes.listRoot }}
              key={option.name}
              id={option.name}
              button
              onClick={() => handleChange(option.name)}
            >
              <div
                className={clsx(
                  classes.contentContainer,
                  index < arr.length - 1 && classes.itemBorder
                )}
              >
                <div>
                  <div className={classes.optionTitle}>
                    {t(roleLabels[option.name])}
                  </div>
                  <p className={classes.optionDescription}>
                    {t(roleDescriptionsLabels[option.name])}
                  </p>
                </div>
                <CustomRadio
                  edge="start"
                  checked={option.name === value}
                  value={option.name}
                  tabIndex={-1}
                  disableRipple
                />
              </div>
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  value: string | null;
  userRoles?: IRoleTypes[];
  handleChange: (id: string) => void;
}
