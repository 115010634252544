import React from "react";
import { Bar, defaults } from "react-chartjs-2";
import i18n from "../../i18n";
import ChartDataLabels, {
  Context as OptionsContext,
} from "chartjs-plugin-datalabels";
import "../../index.css";
import type {} from "css-font-loading-module";
import { useTheme } from "@material-ui/core";
import { setCustomPan } from "../analytics/analyticsSlice";
import { useAppDispatch } from "../app/appHooks";

defaults.font.family = "GothamRoundedBook";

export default function StackedBarChart(props: IProps) {
  const {
    labels,
    valuesDay,
    valuesNight,
    valuesTotals,
    dayOutliers,
    nightOutliers,
    max,
    animation,
    panEnabled,
    mappedMin,
    mappedMax,
    dateReference,
    chartTitle,
  } = props;

  /* Hooks */
  const theme = useTheme();
  const dispatch = useAppDispatch();

  let limits = undefined;
  if (!!panEnabled && dateReference) {
    limits = { x: { min: 0, max: dateReference.length - 1 } };
  }

  const hideValuePivot = max / 10;
  const options = {
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    responsive: true,
    maintainAspectRatio: false,
    animation: animation !== undefined ? animation : true,
    plugins: {
      legend: {
        display: true,
        align: "center",
        position: "bottom",
      },
      title: {
        display: !!chartTitle,
        text: chartTitle,
        align: "center",
        padding: {
          top: 5,
          bottom: 5,
        },
      },
      datalabels: {
        anchor: "end",
        align: "bottom",
        padding: {
          top: "10",
        },
        font: {
          family: "GothamRoundedBook",
        },
      },
      zoom: {
        limits: limits,
        pan: {
          enabled: !!panEnabled,
          mode: "x",
          onPanComplete: ({ chart }: { chart: any }) => {
            if (dateReference) {
              let localStart = dateReference[chart.scales["x"].min];
              let localEnd = dateReference[chart.scales["x"].max];

              dispatch(
                setCustomPan({
                  localStart: localStart,
                  localEnd: localEnd,
                })
              );
            }
          },
        },
      },
    },
    scales: {
      x: {
        type: "category",
        stacked: true,
        min: mappedMin,
        max: mappedMax,
      },
      y: {
        type: "linear",
        stacked: true,
        suggestedMax: max,
        position: "left",
        ticks: { stepSize: 4 },
      },
      y1: {
        type: "linear",
        position: "right",
        ticks: {
          display: true,
          callback: () => "",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const data = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx?.createLinearGradient(0, 0, 0, 400);
    gradient?.addColorStop(0, theme.palette.gradientNight.dark);
    gradient?.addColorStop(1, theme.palette.gradientNight.light);
    const gradient2 = ctx?.createLinearGradient(0, 0, 0, 200);
    gradient2?.addColorStop(0, theme.palette.gradientDay.dark);
    gradient2?.addColorStop(1, theme.palette.gradientDay.light);
    return {
      backgroundColor: gradient,
      labels: labels,
      datasets: [
        {
          stack: "key",
          label: i18n.t("night"),
          data: valuesNight,
          backgroundColor: gradient,
          barThickness: 30,
          datalabels: {
            color: (context: OptionsContext) => {
              return nightOutliers[context.dataIndex]
                ? theme.palette.warning.main
                : "white";
            },
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("day"),
          data: valuesDay,
          backgroundColor: gradient2,
          barThickness: 30,
          datalabels: {
            labels: {
              value: {
                clip: true,
                color: (context: OptionsContext) => {
                  return dayOutliers[context.dataIndex]
                    ? theme.palette.warning.main
                    : "black";
                },
                align: "start",
                formatter: (value: number) => {
                  return value > hideValuePivot ? value : "";
                },
              },
              totals: {
                clip: true,
                font: {
                  family: theme.typography.tertiaryFontFamily,
                },
                color: (context: OptionsContext) => {
                  return valuesTotals[context.dataIndex].isOutlier
                    ? theme.palette.warning.main
                    : "black";
                },
                align: "top",
                padding: {
                  bottom: "-6",
                },
                formatter: (index: number, context: OptionsContext) => {
                  return valuesTotals[context.dataIndex].visitCount;
                },
              },
            },
          },
        },
      ],
    };
  };

  // Update chart when fonts are loaded to prevent need to hover for fonts to appear correctly
  const chartRef = React.useRef();
  document.fonts.ready.then(() => {
    if (chartRef.current) {
      (chartRef.current as any)?.update();
    }
  });

  return (
    <Bar
      type="horizontalBar"
      data={data}
      ref={chartRef}
      options={options}
      plugins={[ChartDataLabels]}
      width={100}
      height={40}
    />
  );
}

interface IProps {
  labels: (string | string[])[];
  max: number;
  valuesDay: number[];
  dayOutliers: boolean[];
  valuesNight: number[];
  nightOutliers: boolean[];
  valuesTotals: IDateVisitValue[];
  animation?: boolean;
  mappedMin?: number;
  mappedMax?: number;
  dateReference?: string[];
  panEnabled?: boolean;
  chartTitle?: string;
}

export interface IDateVisitValue {
  visitCount: number;
  isOutlier: boolean;
}
