import React from "react";
import UpAndAboutBarChart from "./UpAndAboutBarChart";
import HeatIndexWarningChart from "./HeatIndexWarningChart";
import { useTranslation } from "react-i18next";
import { EventTypes } from "../../../helpers/constants";
import SectionHeader from "./SectionHeader";
import NotEventDataChart from "./NoEventDataChart";
import BathroomVisitCountChart from "./BathroomVisitCountChart";
import SimpleMotionChart from "./SimpleMotionChart";
import ActivityMonitorMotionChart from "./ActivityMonitorMotionChart";
import BathroomUsageMotionChart from "./BathroomUsageMotionChart";
import SecurityNotificationChart from "./SecurityNotificationChart";
import { IEvent } from "../../../services/dashboard.services";

export default function EventChart(props: IProps) {
  const { event } = props;
  const { t } = useTranslation();

  const pickChart = () => {
    if (props.eventDataApiFailed === true) {
      return <NotEventDataChart />;
    } else {
      switch (event?.event_type) {
        case EventTypes.no_morning_motion:
          return <UpAndAboutBarChart event={event} />;
        case EventTypes.heat_index_warning:
          return <HeatIndexWarningChart event={event} />;
        case EventTypes.bathroom_anomaly:
        case EventTypes.night_bathroom_aggregate_time:
        case EventTypes.night_bathroom_overstay:
        case EventTypes.night_bathroom_visit_count:
        case EventTypes.bathroom_entry:
          return <BathroomUsageMotionChart event={event} />;
        case EventTypes.bathroom_visit_count:
          return <BathroomVisitCountChart event={event} />;
        case EventTypes.activity_monitor_day:
        case EventTypes.activity_monitor_night:
        case EventTypes.activity_monitor_kitchen:
        case EventTypes.refrigerator_door_open:
        case EventTypes.exterior_door_open:
        case EventTypes.hand_off_notification:
          return <ActivityMonitorMotionChart event={event} />;
        case EventTypes.help_button_press:
        case EventTypes.exterior_exit:
        case EventTypes.site_exit:
        case EventTypes.pause_notification:
        case EventTypes.fall_notification:
          return <SimpleMotionChart event={event} />;
        case EventTypes.security_notification:
          return <SecurityNotificationChart event={event} />;
        default:
          return <SectionHeader text={t("chart").toUpperCase()} />;
      }
    }
  };

  return pickChart();
}
interface IProps {
  event?: IEvent;
  eventDataApiFailed?: boolean;
}
