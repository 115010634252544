import React from "react";
import { useTranslation } from "react-i18next";
import {
  IActivityData,
  IActivityTime,
  IContact,
  IMonitorData,
} from "../eventDataTypes";
import { sortAlphabetical, StackTypes } from "../../../helpers/constants";
import FloatBarChart, {
  IFloatingBarDataFormat,
} from "../../common/FloatBarChart";
import {
  DateFromIsoString,
  DateTimeFromMillis,
} from "../../../helpers/datetime";
import { EventTypes } from "../../../helpers/constants";
import { checkForContactSensor, checkForMotionSensor } from "./chartHelpers";
import { IEvent } from "../../../services/dashboard.services";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";
import { LayoutChartContainer } from "./LayoutChartContainer";
import { Theme, useTheme } from "@material-ui/core";

// Obtains datasets for night bathroom events (night_bathroom_aggregate_time, night_bathroom_overstay and night_bathroom_visit_count)
const getNightBathroomDatasets = (
  orderedTimeIntervals: IActivityTime[],
  colorArray: string[],
  zoneType: string,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  // Obtain intervals for the zone, if is the last interval, add the no motion indicator
  const bathroomDatasets = orderedTimeIntervals.reduce(
    (result: string[][], data, index) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityUnitTimezone
      );

      let endDate = startDate.plus({ minute: 3 });
      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityUnitTimezone);
      }
      result.push([startDate.toISO(), endDate.toISO()]);
      if (zoneType === "bathroom") {
        colorArray.push(theme.palette.error.anomaly ?? "");
      } else {
        colorArray.push(theme.palette.activityMotion.main);
      }
      return result;
    },
    []
  );

  return bathroomDatasets;
};

// Obtains datasets for bathroom anomaly event
const getBathroomAnomalyDatasets = (
  orderedTimeIntervals: IActivityTime[],
  colorArray: string[],
  zoneID: string,
  triggeringTime: string | null,
  triggeringZoneID: string | null,
  eventCreationDate: string,
  theme: Theme,
  communityUnitTimezone?: string,
  getRecentActivity?: boolean
) => {
  const cutOffValue = DateFromIsoString(
    triggeringTime || "",
    communityUnitTimezone
  );
  const bathroomAnomalyDatasets = orderedTimeIntervals.reduce(
    (result: string[][], data, index) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityUnitTimezone
      );
      // Define a default end date, if end date is not null, use it
      let endDate = startDate.plus({ minute: 3 });

      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityUnitTimezone);
      }

      const crossesTrigger = startDate <= cutOffValue && cutOffValue < endDate;

      // Check if the block crosses starts before trigger time but ends after
      if (crossesTrigger && zoneID === triggeringZoneID) {
        const blockBeforeTrigger = [startDate.toISO(), cutOffValue.toISO()];
        const blockAfterTrigger = [cutOffValue.toISO(), endDate.toISO()];

        result.push(blockBeforeTrigger);
        result.push(blockAfterTrigger);

        colorArray.push(theme.palette.activityMotion.main);
        colorArray.push(theme.palette.error.anomaly ?? "");
      } else {
        // Store this interval
        result.push([startDate.toISO(), endDate.toISO()]);

        if (startDate >= cutOffValue && zoneID === triggeringZoneID) {
          colorArray.push(theme.palette.error.anomaly ?? "");
        } else {
          colorArray.push(theme.palette.activityMotion.main);
        }
      }

      // Push color to color array for this index
      return result;
    },
    []
  );

  // Add an inactivity interval if the triggering time exists
  if (zoneID === triggeringZoneID && triggeringTime) {
    if (getRecentActivity) {
      const endLast = DateFromIsoString(triggeringTime, communityUnitTimezone);
      const startLast = endLast.minus({ minute: 1 });
      colorArray.push(theme.palette.error.anomaly ?? "");
      bathroomAnomalyDatasets.push([startLast.toISO(), endLast.toISO()]);
    } else {
      const startLast = DateFromIsoString(
        triggeringTime,
        communityUnitTimezone
      );
      const endLast = DateFromIsoString(
        eventCreationDate,
        communityUnitTimezone
      );
      colorArray.push(theme.palette.error.trans ?? "");
      bathroomAnomalyDatasets.push([startLast.toISO(), endLast.toISO()]);
    }
  }

  return bathroomAnomalyDatasets;
};

// Obtains the min and max for the activity chart
const getChartMinMaxByEvent = (
  creationTime: string,
  eventType: string,
  triggeringTime: string | null,
  communityUnitTimezone?: string
) => {
  let maxDateTime = DateFromIsoString(creationTime, communityUnitTimezone);
  let minDateTime = maxDateTime.minus({ hour: 6 });
  switch (eventType) {
    case EventTypes.night_bathroom_aggregate_time:
    case EventTypes.night_bathroom_overstay:
    case EventTypes.night_bathroom_visit_count:
      maxDateTime = maxDateTime
        .set({ hour: 5, minute: 0, second: 0 })
        .set({ minute: 0 });
      minDateTime = maxDateTime.minus({ hour: 5 });
      return { minDateTime, maxDateTime };
    case EventTypes.bathroom_entry:
      if (triggeringTime !== null) {
        maxDateTime = DateFromIsoString(triggeringTime, communityUnitTimezone);
      }
      minDateTime = maxDateTime.minus({ hour: 6 });
      return { minDateTime, maxDateTime };
    case EventTypes.bathroom_anomaly:
      return { minDateTime, maxDateTime };
    default:
      return { minDateTime, maxDateTime };
  }
};

// Obtains datasets when motion arrays exist for a zone
const getActivityDatasets = (
  activityData: IActivityData,
  eventType: string,
  triggeringTime: string | null,
  triggeringZoneID: string | null,
  eventCreationDate: string,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  // Copy and dereference array and orders it ascendingly
  const orderedTimeIntervals = (JSON.parse(
    JSON.stringify(activityData.activity ? activityData.activity : [])
  ) as IActivityTime[]).sort((a, b) => {
    return Number(a.start_time) - Number(b.end_time);
  });

  // Get the last interval for the zone
  let colorArray: string[] = [];
  const defaultData = [[""]];
  let result = {
    color: colorArray,
    stack: StackTypes.MOTION,
    datasets: defaultData,
  };
  switch (eventType) {
    case EventTypes.night_bathroom_aggregate_time:
    case EventTypes.night_bathroom_overstay:
    case EventTypes.night_bathroom_visit_count:
      const bathroomDatasets = getNightBathroomDatasets(
        orderedTimeIntervals,
        colorArray,
        activityData.zone_type,
        theme,
        communityUnitTimezone
      );
      if (bathroomDatasets.length > 0) result.datasets = bathroomDatasets;
      return result;
    case EventTypes.bathroom_entry:
      const bathroomActivityDatasets = getBathroomAnomalyDatasets(
        orderedTimeIntervals,
        colorArray,
        activityData.zone_id,
        triggeringTime,
        triggeringZoneID,
        eventCreationDate,
        theme,
        communityUnitTimezone,
        true
      );
      if (bathroomActivityDatasets.length > 0)
        result.datasets = bathroomActivityDatasets;
      return result;
    case EventTypes.bathroom_anomaly:
      const bathroomAnomalyDatasets = getBathroomAnomalyDatasets(
        orderedTimeIntervals,
        colorArray,
        activityData.zone_id,
        triggeringTime,
        triggeringZoneID,
        eventCreationDate,
        theme,
        communityUnitTimezone
      );
      if (bathroomAnomalyDatasets.length > 0)
        result.datasets = bathroomAnomalyDatasets;
      return result;
    default:
      return result;
  }
};

// Obtains datasets when contact arrays exist for a zone
const getContactDatasets = (
  activityData: IActivityData,
  creationTime: string,
  theme: Theme,
  communityUnitTimezone?: string
) => {
  const sensors = activityData.sensors ? activityData.sensors : [];
  // Copy and dereference array and orders it ascendingly
  const contactData = JSON.parse(
    JSON.stringify(activityData.contact ? activityData.contact : [])
  ) as IContact[];
  const contactDatasets = contactData.reduce(
    (result: IFloatingBarDataFormat[], contact) => {
      const sensorID = contact.sensor_id;
      const contactSensor = sensors.find(
        (sensor) => sensor.sensor_id === sensorID
      );
      if (contact.activity && contactSensor !== undefined) {
        const orderedTimeIntervals = contact.activity.sort((a, b) => {
          return Number(a.start_time) - Number(b.end_time);
        });

        // Array of time intervals ordered
        const contactDatasets = orderedTimeIntervals.map((data, index) => {
          const startDate = DateTimeFromMillis(
            data.start_time || 0,
            communityUnitTimezone
          ).minus({
            minute: 1,
          });
          let endDate = startDate.plus({ minute: 2 });
          if (data.end_time) {
            endDate = DateTimeFromMillis(
              data.end_time,
              communityUnitTimezone
            ).plus({ minute: 2 });
          } else if (
            data.end_time === null &&
            index === orderedTimeIntervals.length - 1
          ) {
            endDate = DateFromIsoString(creationTime, communityUnitTimezone);
          }
          return [startDate.toISO(), endDate.toISO()];
        });
        result.push({
          color: theme.palette.contactBlue.main,
          stack: StackTypes.CONTACT,
          datasets: contactDatasets.length > 0 ? contactDatasets : [[""]],
        });
      }
      return result;
    },
    []
  );

  return contactDatasets;
};

export default function BathroomUsageMotionChart(props: IProps) {
  const { event } = props;
  const communityID = event.community_id ?? undefined;
  /* Hooks */
  const { t } = useTranslation();
  const communityUnitTimezone = useCommunityUnitTimezone(
    communityID,
    event.unit_id
  );
  const theme = useTheme();

  const eventType = event ? event.event_type : "";
  const eventData = JSON.parse(event?.data || "") as IMonitorData;
  const eventCreationDate = event ? event.time_created : "";
  const triggeringTime =
    eventData.triggering_time !== undefined ? eventData.triggering_time : null;
  const triggeringZoneID = event ? event.zone_id : "";

  const motionChartData = (JSON.parse(
    JSON.stringify(eventData.activity_data ? eventData.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForMotionSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const contactChartData = (JSON.parse(
    JSON.stringify(eventData.activity_data ? eventData.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForContactSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const motionLabels = motionChartData.reduce((result: string[], element) => {
    if (element.activity !== undefined) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const contactLabels = contactChartData.reduce((result: string[], element) => {
    if (checkForContactSensor(element)) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const labels = [...motionLabels, ...contactLabels];

  const getDataSets = (
    motionOrderedZones: IActivityData[],
    contactOrderedZones: IActivityData[],
    triggeringTime: string | null,
    triggeringZoneID: string | null,
    eventCreationDate: string,
    theme: Theme,
    communityUnitTimezone?: string
  ) => {
    const dataSets: IFloatingBarDataFormat[] = [];

    // Obtain datasets for motion intervals
    const activityDatasets: IFloatingBarDataFormat[] = motionOrderedZones.map(
      (activityData) =>
        getActivityDatasets(
          activityData,
          eventType,
          triggeringTime,
          triggeringZoneID,
          eventCreationDate,
          theme,
          communityUnitTimezone
        )
    );

    // Obtain datasets for contact intervals
    const contactDatasets: IFloatingBarDataFormat[] = contactOrderedZones.reduce(
      (result: IFloatingBarDataFormat[], contactData) => {
        if (contactData.contact !== undefined) {
          result.push(
            ...getContactDatasets(
              contactData,
              eventCreationDate,
              theme,
              communityUnitTimezone
            )
          );
        } else {
          if (checkForContactSensor(contactData)) {
            result.push({
              color: theme.palette.contactBlue.main,
              stack: StackTypes.CONTACT,
              datasets: [[]],
            });
          }
        }
        return result;
      },
      []
    );
    dataSets.push(...activityDatasets, ...contactDatasets);
    return dataSets;
  };

  const datasets = getDataSets(
    motionChartData,
    contactChartData,
    triggeringTime,
    triggeringZoneID,
    eventCreationDate,
    theme,
    communityUnitTimezone
  );

  const { minDateTime, maxDateTime } = getChartMinMaxByEvent(
    eventCreationDate,
    eventType,
    triggeringTime,
    communityUnitTimezone
  );

  return (
    <LayoutChartContainer
      title={t("room_activity").toUpperCase()}
      unitID={event.unit_id}
      eventTimeCreated={event.time_created}
      eventType={event.event_type}
      emptyData={labels.length === 0}
    >
      <FloatBarChart
        max={maxDateTime}
        min={minDateTime}
        labels={labels}
        values={datasets}
        communityUnitTimezone={communityUnitTimezone}
      />
    </LayoutChartContainer>
  );
}

interface IProps {
  event: IEvent;
}
