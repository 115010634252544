import React from "react";
import { Bar, defaults } from "react-chartjs-2";
import i18n from "../../i18n";
import ChartDataLabels, {
  Context as OptionsContext,
} from "chartjs-plugin-datalabels";
import { useTheme } from "@material-ui/core/styles";
import { setCustomPan } from "../analytics/analyticsSlice";
import { useAppDispatch } from "../app/appHooks";

defaults.font.family = "GothamRoundedBook";

export default function StackedBarChartB(props: IProps) {
  const {
    labels,
    valuesMorning,
    valuesAfternoon,
    valuesEvening,
    valuesNight,
    valuesTotals,
    max,
    animation,
    panEnabled,
    mappedMin,
    mappedMax,
    dateReference,
  } = props;

  /* Hooks */
  const theme = useTheme();
  const dispatch = useAppDispatch();

  let limits = undefined;
  if (!!panEnabled && dateReference) {
    limits = { x: { min: 0, max: dateReference.length - 1 } };
  }

  const hideValuePivot = max / 10;
  const options = {
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    responsive: true,
    maintainAspectRatio: false,
    animation: animation !== undefined ? animation : true,
    plugins: {
      legend: {
        display: true,
        align: "center",
        position: "bottom",
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        font: {
          family: "GothamRoundedBook",
        },
      },
      zoom: {
        limits: limits,
        pan: {
          enabled: !!panEnabled,
          mode: "x",
          onPanComplete: ({ chart }: { chart: any }) => {
            if (dateReference) {
              let localStart = dateReference[chart.scales["x"].min];
              let localEnd = dateReference[chart.scales["x"].max];

              dispatch(
                setCustomPan({
                  localStart: localStart,
                  localEnd: localEnd,
                })
              );
            }
          },
        },
      },
    },
    scales: {
      x: {
        type: "category",
        stacked: true,
        min: mappedMin,
        max: mappedMax,
      },
      y: {
        type: "linear",
        suggestedMax: max,
        position: "left",
        ticks: {
          stepSize: 4,
        },
      },
      y1: {
        type: "linear",
        position: "right",
        ticks: {
          display: true,
          callback: () => "",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  enum DayTimes {
    night,
    morning,
    afternoon,
    evening,
  }

  const getGradient = (
    type: number,
    canvas: HTMLCanvasElement
  ): CanvasGradient | undefined => {
    const gradientColors = {
      [DayTimes.night]: [
        theme.palette.gradientNight.dark,
        theme.palette.gradientNight.light,
      ],
      [DayTimes.morning]: [
        theme.palette.gradientMorning.light,
        theme.palette.gradientMorning.dark,
      ],
      [DayTimes.afternoon]: [
        theme.palette.gradientAfternoon.light,
        theme.palette.gradientAfternoon.dark,
      ],
      [DayTimes.evening]: [
        theme.palette.gradientEvening.light,
        theme.palette.gradientEvening.dark,
      ],
    };

    const ctx = canvas.getContext("2d");
    const gradient = ctx?.createLinearGradient(0, 0, 0, 200);
    gradient?.addColorStop(0, gradientColors[type as DayTimes][0]);
    gradient?.addColorStop(0.7, gradientColors[type as DayTimes][1]);

    return gradient;
  };

  const displayBarLabels = true;

  const data = (canvas: HTMLCanvasElement) => {
    return {
      labels: labels,
      datasets: [
        {
          stack: "key",
          label: i18n.t("night"),
          data: valuesNight,
          backgroundColor: getGradient(DayTimes.night, canvas),
          barThickness: 30,
          datalabels: {
            display: displayBarLabels,
            color: "white",
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("morning"),
          data: valuesMorning,
          backgroundColor: getGradient(DayTimes.morning, canvas),
          barThickness: 30,
          datalabels: {
            display: displayBarLabels,
            color: "white",
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("afternoon"),
          data: valuesAfternoon,
          backgroundColor: getGradient(DayTimes.afternoon, canvas),
          barThickness: 30,
          datalabels: {
            color: "white",
            display: displayBarLabels,
            formatter: (value: number) => {
              return value > hideValuePivot ? value : "";
            },
          },
        },
        {
          stack: "key",
          label: i18n.t("evening"),
          data: valuesEvening,
          backgroundColor: getGradient(DayTimes.evening, canvas),
          barThickness: 30,
          datalabels: {
            labels: {
              value: {
                color: "white",
                display: displayBarLabels,
                align: "start",
                formatter: (value: number) => {
                  return value > hideValuePivot ? value : "";
                },
              },
              totals: {
                display: true,
                font: {
                  family: theme.typography.tertiaryFontFamily,
                },
                color: "black",
                align: "top",
                formatter: (index: number, context: OptionsContext) => {
                  return valuesTotals[context.dataIndex];
                },
              },
              align: "top",
              padding: {
                bottom: "-6",
              },
            },
          },
        },
      ],
    };
  };

  // Update chart when fonts are loaded to prevent need to hover for fonts to appear correctly
  const chartRef = React.useRef();
  document.fonts.ready.then(() => {
    if (chartRef.current) {
      (chartRef.current as any)?.update();
    }
  });

  return (
    <Bar
      type="horizontalBar"
      data={data}
      ref={chartRef}
      options={options}
      plugins={[ChartDataLabels]}
      width={100}
      height={40}
    />
  );
}

interface IProps {
  labels: (string | string[])[];
  max: number;
  valuesMorning: number[];
  valuesAfternoon: number[];
  valuesEvening: number[];
  valuesNight: number[];
  valuesTotals: number[];
  values?: IStackedBarDataFormat;
  animation?: boolean;
  mappedMin?: number;
  mappedMax?: number;
  dateReference?: string[];
  panEnabled?: boolean;
}

export interface IStackedBarDataFormat {
  visitCount: number;
  isOutlier: boolean;
  stack: string;
  label: string;
  data: number[];
  backgroundColor: CanvasGradient | string;
  barThickness: number;
  datalabels: {
    color: string;
  };
}
