import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
    },
    labelContainer: {
      textAlign: "right",
    },
    formLabel: {
      paddingRight: theme.spacing(2),
    },
    inputContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
  })
);

export const FormRow = (props: IProps) => {
  const { inputKey, children, required } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container component="li" className={classes.formRow}>
      <Grid item sm={5} className={classes.labelContainer}>
        <div className={classes.formLabel}>
          {t(inputKey).toUpperCase()}
          {required ? "*" : ""}
        </div>
      </Grid>
      <Grid item sm={7}>
        <div className={classes.inputContainer}>{children}</div>
      </Grid>
    </Grid>
  );
};

interface IProps {
  inputKey: string;
  required?: boolean;
  children: React.ReactNode;
}
