import { IResident } from "./dashboard.services";
import { request } from "./utils";
import { IFailedRequest } from "./login.services";
import { IPhoneNumber, IResidentPhoneNumber } from "./header.services";
import {
  IAddContactPhoneNumber,
  IAddPhoneNumberData,
} from "./settings.services";

export interface IResidentContact {
  id: string;
  resident_id: string;
  community_id: string;
  first_name?: string | null;
  last_name?: string | null;
  contact_type: string;
  email?: string | null;
  emergency_contact?: boolean;
  phone_numbers?: IResidentPhoneNumber[] | null;
  user_id?: string;
  position?: number;
}
export interface EmergencyContact {
  id: string;
  community_id: string;
  resident_id: string;
  contact_type: string;
  position: number;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  emergency_contact?: boolean;
  phone_numbers?: IPhoneNumber[] | IResidentPhoneNumber[] | null;
  user_id?: string | null;
  contact_id?: string | null;
}

export const commonServices = {
  getResidentContacts,
  addResidentContact,
  deleteResidentContact,
  updateResidentContact,
  addContactPhoneNumber,
  updateContactPhoneNumber,
  deleteContactPhoneNumber,
  getEmergencyContacts,
  addEmergencyContact,
  updateEmergencyContactsPosition,
  deleteEmergencyContact,
};

export interface IResidentData {
  community_id: string;
  resident_id: string;
}

/**
 * Loads the resident and its contacts
 *
 * @param {IGetResidentData} residentData Resident ID
 * @returns {IResident} An array containing the communities linked to the user
 */

async function getResidentContacts(
  residentData: IResidentData
): Promise<IResident> {
  const { community_id, resident_id } = residentData;
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/communities/${community_id}/residents/${resident_id}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IResident);
  }
}

export interface IAddContactData {
  community_id: string;
  resident_id: string;
  first_name?: string | null;
  last_name?: string | null;
  contact_type: string;
  email?: string | null;
}

async function addResidentContact(
  contactData: IAddContactData
): Promise<IResidentContact> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...contactData,
    }),
  };

  const data = await request(`/contacts`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IResidentContact);
  }
}

export type IUpdateContactData = {
  contact: IAddContactData;
  contactID: string;
};

async function updateResidentContact(
  contactData: IUpdateContactData
): Promise<IResidentContact> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...contactData.contact,
    }),
  };

  const data = await request(
    `/contacts/${contactData.contactID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IResidentContact);
  }
}

async function deleteResidentContact(contactID: string): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(`/contacts/${contactID}`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}

async function addContactPhoneNumber(
  phone: IAddContactPhoneNumber
): Promise<IResidentPhoneNumber> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...phone,
    }),
  };

  const data = await request(`/contact_phone_numbers`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IResidentPhoneNumber);
  }
}

export type IUpdateContactPhoneData = {
  phone: IAddPhoneNumberData;
  phoneID: string;
};

async function updateContactPhoneNumber(
  phoneData: IUpdateContactPhoneData
): Promise<IResidentPhoneNumber> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...phoneData.phone,
    }),
  };

  const data = await request(
    `/contact_phone_numbers/${phoneData.phoneID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as IResidentPhoneNumber);
  }
}

export type IDeleteContactPhoneData = {
  forceDelete: boolean;
  phoneID: string;
};

async function deleteContactPhoneNumber(
  deleteData: IDeleteContactPhoneData
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(
    `/contact_phone_numbers/${deleteData.phoneID}?force_delete=${
      deleteData.forceDelete ? 1 : 0
    }`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}

async function getEmergencyContacts(
  residentID: string
): Promise<EmergencyContact[]> {
  const requestOptions: RequestInit = {
    body: null,
  };

  const data = await request(
    `/emergency_contacts?resident_id=${residentID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as EmergencyContact[]);
  }
}

export interface AddEmergencyContact {
  resident_id: string;
  user_id?: string;
  contact_id?: string;
  position?: number;
}

async function addEmergencyContact(
  contactData: AddEmergencyContact
): Promise<EmergencyContact> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...contactData,
    }),
  };

  const data = await request(`/emergency_contacts`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as EmergencyContact);
  }
}

export interface ContactPositionData {
  resident_id: string;
  emergency_contact_ids: string[];
  positions: number[];
}

async function updateEmergencyContactsPosition(
  contactPositionData: ContactPositionData
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      ...contactPositionData,
    }),
  };

  const data = await request(`/emergency_contacts`, requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}

async function deleteEmergencyContact(
  emergencyContactID: string
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };

  const data = await request(
    `/emergency_contacts/${emergencyContactID}`,
    requestOptions
  );

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as boolean);
  }
}
