import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import { SortableItem } from "./SortableItem";
import { ResidentContactItem } from "./ResidentContactItem";
import { DraggableItem } from "./DraggableItem";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    droppable: {
      minWidth: "110px",
      minHeight: "72px",
      padding: theme.spacing(1.5, 1),
      borderRadius: "10px",
      boxShadow:
        "inset rgba(201, 211, 219, 0.5) 0 0 0 1px, rgba(255, 255, 255, 0) 0 0 0 1px, rgba(201, 211, 219, 0.25) 2px 2px 15px",
      transition: "box-shadow 250ms ease",
      listStyleType: "none",
      marginTop: theme.spacing(3),
    },
    isOver: {
      boxShadow: `inset ${theme.palette.primary.main} 0 0 0 2px, rgba(201, 211, 219, 0.5) 2px 2px 15px`,
    },
    addListTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      fontWeight: theme.typography.body1.fontWeight,
    },
  })
);

export const Droppable = (props: IProps) => {
  const { id, items, auxText, removeItem } = props;
  const { setNodeRef, over } = useDroppable({ id });
  const classes = useStyles();

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      {auxText && <p>{auxText}</p>}
      <ul
        className={clsx(classes.droppable, over && classes.isOver)}
        ref={setNodeRef}
      >
        {items.map((item, index) => {
          return (
            <SortableItem
              key={item}
              id={item}
              index={index}
              removeItem={removeItem}
            >
              <ResidentContactItem id={item} />
            </SortableItem>
          );
        })}
      </ul>
    </SortableContext>
  );
};

export const DraggableItemsContainer = (props: {
  items: string[];
  auxText?: string;
}) => {
  const { items, auxText } = props;
  const classes = useStyles();
  const { active, over } = useDroppable({ id: '"emergency_contacts"' });

  return (
    <div>
      {auxText && <p>{auxText}</p>}
      <ul
        className={clsx(classes.droppable, active && !over && classes.isOver)}
      >
        {items.map((item) => {
          return (
            <DraggableItem key={item} id={item}>
              <ResidentContactItem id={item} />
            </DraggableItem>
          );
        })}
      </ul>
    </div>
  );
};

interface IProps {
  id: string;
  items: string[];
  auxText?: string;
  removeItem: (id: string) => void;
}
