import {
  Button,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Popover,
  Tooltip,
  withStyles,
  TextField,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormRow } from "./common/FormRow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  DefaultRegionCode,
  MIN_WIDTH_FOR_TOOLTIP,
  NAME_CHARACTER_LIMIT,
  PhoneTypes,
  sortAlphabetical,
  UnitTypes,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { PhoneNumberPicker, UnitPhoneNumber } from "./common/PhoneNumberPicker";
import { GenericPicker } from "./common/GenericPicker";
import { createUnit, ICreateUnitData } from "./settingsThunks";
import { isHelpAtHome } from "../dashboard/dashboardSlice";
import {
  getCountryCodeForRegionCode,
  parsePhoneNumber,
} from "awesome-phonenumber";
import { getStatesList } from "./common/helpers";
import { ErrorCodes } from "../../services/constants";
import { handledError } from "../app/appSlice";
import { OptionsType, timezoneFormated, countryToFlag } from "./CommunityInfo";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateOfBirthDatePicker } from "./DateOfBirthDatePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addUnitFormContainer: {
      padding: theme.spacing(6, 7),
      width: "550px",
      overflow: "auto",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      textAlign: "center",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    fieldList: {
      listStyle: "none",
      padding: 0,
      paddingRight: "50px",
      width: "100%",
    },
    inputWidth: {
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    phoneNumbersContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    phoneNumbersTitle: {
      marginTop: theme.spacing(4),
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1.2em .5em 1.4em",
      },
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    helperText: {
      minHeight: "20px",
      lineHeight: "1.2",
      color: theme.palette.error.main,
    },
    flagIcon: {
      marginRight: theme.spacing(0.5),
    },
    rowInput: {
      width: "58.33%",
    },
    subheaderRoot: {
      lineHeight: "16px",
    },
  })
);

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const InputRow = (props: {
  inputKey: string;
  value: string | OptionsType | null;
  required?: boolean;
  select?: boolean;
  options?: OptionsType[];
  onChange?: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;

  onChangeSelect?: (key: string, value: OptionsType | null) => void;
}) => {
  const {
    inputKey,
    value,
    select,
    required,
    options,
    onChange,
    onChangeSelect,
  } = props;
  /* Hooks */
  const classes = useStyles();

  const { t } = useTranslation();
  const hasGroupBy = options?.every((option) => option.groupBy);
  return (
    <li className={classes.formRow}>
      <label htmlFor={inputKey}>
        {t(inputKey).toUpperCase()}
        {required ? "*" : ""}
      </label>
      {select ? (
        <Autocomplete
          options={options ?? []}
          id={`dropdown-${inputKey}`}
          disableClearable
          noOptionsText={t("no_options")}
          groupBy={hasGroupBy ? (option) => option.groupBy ?? "" : undefined}
          getOptionLabel={(option: OptionsType) =>
            options?.find((_option) => _option.id === option.id)?.label ?? ""
          }
          value={value as OptionsType | undefined}
          onChange={(_, val) => onChangeSelect && onChangeSelect(inputKey, val)}
          getOptionSelected={(option, value) => option.id === value.id}
          blurOnSelect
          autoHighlight
          renderOption={(option) => (
            <React.Fragment>
              {inputKey === "country" && (
                <span className={classes.flagIcon}>
                  {countryToFlag(option.id)}
                </span>
              )}
              {option.label}
            </React.Fragment>
          )}
          classes={{
            root: classes.rowInput,
            groupLabel:
              inputKey === "country" ? classes.subheaderRoot : undefined,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={value === null ? t("select_an_option") : ""}
            />
          )}
        />
      ) : (
        <Input
          classes={{ root: classes.rowInput }}
          id={inputKey}
          value={value !== null ? (value as string) : ""}
          error={required ? value === null || value === "" : false}
          onChange={onChange && onChange(inputKey)}
        />
      )}
    </li>
  );
};

export const DropdownInputWithTooltip = (props: {
  id: string;
  value: string | undefined;
  disabled?: boolean;
  handleClick: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
}) => {
  const { id, value, disabled, handleClick } = props;
  const classes = useStyles();
  return (
    <>
      {value && value.length > MIN_WIDTH_FOR_TOOLTIP ? (
        <CustomTooltip title={value} placement="top" interactive>
          <Input
            id={id}
            type="text"
            value={value ?? ""}
            disabled={disabled}
            classes={{
              input: classes.truncateEllipsis,
            }}
            onClick={!disabled ? (e) => handleClick(e, id) : undefined}
            className={classes.inputWidth}
            endAdornment={
              !disabled ? (
                <InputAdornment id="role" position="end">
                  <IconButton aria-label={`toggle ${id} picker`}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              ) : undefined
            }
          />
        </CustomTooltip>
      ) : (
        <Input
          id={id}
          type="text"
          value={value ?? ""}
          disabled={disabled}
          classes={{
            input: classes.truncateEllipsis,
          }}
          onClick={!disabled ? (e) => handleClick(e, id) : undefined}
          className={classes.inputWidth}
          endAdornment={
            !disabled ? (
              <InputAdornment id="role" position="end">
                <IconButton aria-label={`toggle ${id} picker`}>
                  <ArrowDropDownIcon />
                </IconButton>
              </InputAdornment>
            ) : undefined
          }
        />
      )}
    </>
  );
};

enum FormFieldIDs {
  unitType = "unit-type",
  unitName = "unit-name",
  firstName = "first-name",
  lastName = "last-name",
  addressOne = "address-one",
  addressTwo = "address-two",
  city = "city",
  state = "state",
  zipCode = "zip-code",
  dateOfBirth = "date-of-birth",
  phoneNumberOne = "phone-number-one",
  phoneNumberTwo = "phone-number-two",
  timeZone = "time-zone",
}

type FormState = {
  [FormFieldIDs.unitType]?: string | null;
  [FormFieldIDs.unitName]?: string | null;
  [FormFieldIDs.firstName]?: string | null;
  [FormFieldIDs.lastName]?: string | null;
  [FormFieldIDs.addressOne]?: string | null;
  [FormFieldIDs.addressTwo]?: string | null;
  [FormFieldIDs.city]?: string | null;
  [FormFieldIDs.state]?: string | null;
  [FormFieldIDs.zipCode]?: string | null;
  [FormFieldIDs.dateOfBirth]?: string | null;
  [FormFieldIDs.phoneNumberOne]: UnitPhoneNumber;
  [FormFieldIDs.phoneNumberTwo]: UnitPhoneNumber;
  [FormFieldIDs.timeZone]?: OptionsType | null;
};

export const AddUnitForm = () => {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const units = useAppSelector((state) => state.headerState.units);
  const hasUnits = useAppSelector(
    (state) =>
      state.headerState.units !== undefined &&
      state.headerState.units.length > 0
  );
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const community = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );
  const communityCountry = useAppSelector(
    (state) => state.headerState.selectedCommunity?.country
  );
  const defaultRegionCode = useAppSelector(
    (state) => state.headerState.user?.country ?? DefaultRegionCode
  );

  const allHelpAtHome = units?.every((unit) => isHelpAtHome(unit));

  const errorCode = useAppSelector((state) => state.settingsState.errorCode);

  const handleChangeDropdown = (key: string, value: OptionsType | null) => {
    setFormValues((prevVal) => ({
      ...prevVal,
      [FormFieldIDs.timeZone]: value,
    }));
  };

  /* ---- Effects ---- */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.NAME_CONFLICT:
          const isHelpAtHome =
            formValues[FormFieldIDs.unitType] === UnitTypes.HELP_AT_HOME;
          dispatch(handledError());
          setFormError((prevVal) => ({
            ...prevVal,
            name_conflict: isHelpAtHome
              ? t("resident_city_name_conflict")
              : t("unit_name_conflict"),
          }));
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  /* State */
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [popoverID, setPopoverID] = useState("");
  const [formError, setFormError] = useState({
    [FormFieldIDs.unitName]: "",
    [FormFieldIDs.firstName]: "",
    [FormFieldIDs.lastName]: "",
    [FormFieldIDs.city]: "",
    name_conflict: "",
  });

  const [formValues, setFormValues] = useState<FormState>({
    [FormFieldIDs.unitType]: null,
    [FormFieldIDs.unitName]: null,
    [FormFieldIDs.firstName]: null,
    [FormFieldIDs.lastName]: null,
    [FormFieldIDs.addressOne]: null,
    [FormFieldIDs.addressTwo]: null,
    [FormFieldIDs.city]: null,
    [FormFieldIDs.state]: null,
    [FormFieldIDs.zipCode]: null,
    [FormFieldIDs.phoneNumberOne]: {
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.HOME,
      phone_number: null,
    },
    [FormFieldIDs.phoneNumberTwo]: {
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.MOBILE,
      phone_number: null,
    },
    [FormFieldIDs.timeZone]: null,
  });

  /* Effects */
  // Initialize unit type in the form
  useEffect(() => {
    if (hasUnits) {
      setFormValues((prevVal) => ({
        ...prevVal,
        [FormFieldIDs.unitType]: allHelpAtHome
          ? UnitTypes.HELP_AT_HOME
          : UnitTypes.RESIDENT,
      }));
    } else {
      setFormValues((prevVal) => ({
        ...prevVal,
        [FormFieldIDs.unitType]: UnitTypes.RESIDENT,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (community !== undefined) {
      let timezoneOption: OptionsType | null = null;
      if (community?.time_zone !== null) {
        timezoneOption =
          timezoneFormated.find(
            (time_zone) => time_zone.id === community?.time_zone
          ) ?? null;
      }

      setFormValues((prevVal) => ({
        ...prevVal,
        [FormFieldIDs.timeZone]: timezoneOption,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  /* Methods */

  // Open dropdown list for some of the input elements
  const handleOpenFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(id);
  };

  const handleChange = (value: string, formField: FormFieldIDs) => {
    setFormValues((prevVal) => ({
      ...prevVal,
      [formField]: value.trimStart(),
    }));
    setFormError((prevVal) => ({
      ...prevVal,
      name_conflict: "",
    }));
    setAnchorEl(null);
  };

  const handleChangePhone = (
    value: UnitPhoneNumber,
    formField: FormFieldIDs
  ) => {
    setFormValues((prevVal) => ({ ...prevVal, [formField]: value }));
  };

  const handleChangeDateOfBirth = (date: string) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [FormFieldIDs.dateOfBirth]: date,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const unitType = formValues[FormFieldIDs.unitType];
    if (communityID === undefined) return;
    if (unitType === undefined || unitType === null) return;
    const requestData: ICreateUnitData = {
      community_id: communityID,
      unit_type: unitType,
      name: "",
      time_zone: "",
    };
    if (requestData.unit_type === UnitTypes.RESIDENT) {
      requestData.name = formValues[FormFieldIDs.unitName] ?? "";
    }
    requestData.time_zone = formValues[FormFieldIDs.timeZone]?.id ?? "";
    if (requestData.unit_type === UnitTypes.HELP_AT_HOME) {
      const residentData: { [key: string]: any } = {
        first_name: formValues[FormFieldIDs.firstName] ?? "",
        last_name: formValues[FormFieldIDs.lastName] ?? "",
        city: formValues[FormFieldIDs.city],
        postal_code: formValues[FormFieldIDs.zipCode],
        state: formValues[FormFieldIDs.state],
        date_of_birth: formValues[FormFieldIDs.dateOfBirth],
        street: formValues[FormFieldIDs.addressOne],
        street2: formValues[FormFieldIDs.addressTwo],
      };
      Object.keys(residentData).forEach((key) => {
        if (residentData[key] === null) {
          delete residentData[key];
        }
      });

      requestData["resident"] = residentData as typeof requestData["resident"];

      const phone1 = formValues[FormFieldIDs.phoneNumberOne];
      const phone2 = formValues[FormFieldIDs.phoneNumberTwo];

      const adjustPhoneNumber = (phone: UnitPhoneNumber): UnitPhoneNumber => {
        const adjustedPhone = { ...phone };
        if (
          adjustedPhone.phone_number === null ||
          adjustedPhone.phone_number === undefined
        )
          return phone;

        const phoneNumber = parsePhoneNumber(
          adjustedPhone.phone_number,
          adjustedPhone.country_code ?? DefaultRegionCode
        );
        adjustedPhone.user_type = "resident";
        adjustedPhone.phone_number = phoneNumber.getNumber("significant");
        if (adjustedPhone.country_code) {
          adjustedPhone.country_code = String(
            getCountryCodeForRegionCode(adjustedPhone.country_code)
          );
        }
        return adjustedPhone;
      };

      let phoneNumbers: typeof requestData["phoneNumbers"] = [];
      if (phone1.phone_number) {
        phoneNumbers.push(adjustPhoneNumber(phone1));
      }
      if (phone2.phone_number) {
        phoneNumbers.push(adjustPhoneNumber(phone2));
      }

      if (phoneNumbers.length > 0) {
        phoneNumbers = phoneNumbers.map((val, index) => ({
          ...val,
          priority: index + 1,
        }));
        requestData.phoneNumbers = phoneNumbers;
      }
    }
    dispatch(createUnit(requestData));
  };
  /* List values */
  const unitTypesList = [
    { id: UnitTypes.HELP_AT_HOME, label: "unit_type_hah_title" },
    { id: UnitTypes.RESIDENT, label: "unit_type_resident_title" },
  ].sort((a, b) => sortAlphabetical(t(a.label), t(b.label)));

  const getUnitTypeLabel = (unitTypeID: string | null) => {
    if (unitTypeID === null) return "-";
    const unitType = unitTypesList.find(
      (unitType) => unitType.id === unitTypeID
    );
    return unitType?.label ?? "-";
  };

  const stateList = useMemo(() => getStatesList(communityCountry), [
    communityCountry,
  ]);

  const getStateLabel = (id: string | null) => {
    if (id === null) return "-";
    return stateList.find((state) => state.id === id)?.label ?? "-";
  };

  // Validate if required field has value
  const validateNotEmpty = (id: FormFieldIDs) => {
    if (formValues[id] === null || formValues[id] === "") {
      setFormError((prevVal) => ({ ...prevVal, [id]: "requiredVal" }));
    } else {
      setFormError((prevVal) => ({ ...prevVal, [id]: "" }));
    }
  };

  // Enable save button
  const checkFormValidity = (values: FormState) => {
    let enabled = true;
    if (values[FormFieldIDs.unitType] === UnitTypes.RESIDENT) {
      if (
        values[FormFieldIDs.unitName] === null ||
        values[FormFieldIDs.unitName] === ""
      ) {
        enabled = false;
      }
    }
    if (values[FormFieldIDs.unitType] === UnitTypes.HELP_AT_HOME) {
      if (
        values[FormFieldIDs.firstName] === null ||
        values[FormFieldIDs.firstName] === ""
      ) {
        enabled = false;
      }
      if (
        values[FormFieldIDs.lastName] === null ||
        values[FormFieldIDs.lastName] === ""
      ) {
        enabled = false;
      }
      if (
        values[FormFieldIDs.city] === null ||
        values[FormFieldIDs.city] === ""
      ) {
        enabled = false;
      }
    }
    if (values[FormFieldIDs.timeZone] === null) {
      enabled = false;
    }
    return enabled;
  };

  const enableSubmit = checkFormValidity(formValues);

  return (
    <Paper className={classes.addUnitFormContainer} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("add_unit")}</h2>
      <form
        id="add-unit-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.fieldList}>
          <FormRow inputKey="unit_type">
            <DropdownInputWithTooltip
              id={FormFieldIDs.unitType}
              value={
                formValues[FormFieldIDs.unitType]
                  ? t(
                      getUnitTypeLabel(
                        formValues[FormFieldIDs.unitType] as string
                      )
                    )
                  : undefined
              }
              disabled={hasUnits}
              handleClick={handleOpenFilter}
            />
          </FormRow>
          {formValues[FormFieldIDs.unitType] === UnitTypes.RESIDENT && (
            <FormRow inputKey="name" required>
              <Input
                id={FormFieldIDs.unitName}
                type={"text"}
                className={classes.inputWidth}
                value={formValues[FormFieldIDs.unitName] ?? ""}
                onChange={(e) => {
                  handleChange(e.currentTarget.value, FormFieldIDs.unitName);
                }}
                error={
                  (formError[FormFieldIDs.unitName] !== "" &&
                    formError[FormFieldIDs.unitName] !== null) ||
                  formError["name_conflict"] !== ""
                }
                onBlur={() => validateNotEmpty(FormFieldIDs.unitName)}
              />
              {formError["name_conflict"] && (
                <FormHelperText className={classes.helperText}>
                  {formError["name_conflict"]}
                </FormHelperText>
              )}
            </FormRow>
          )}
          <InputRow
            inputKey="time_zone"
            value={formValues[FormFieldIDs.timeZone] ?? ""}
            select
            options={timezoneFormated}
            onChangeSelect={handleChangeDropdown}
          />
          {formValues[FormFieldIDs.unitType] === UnitTypes.HELP_AT_HOME && (
            <>
              <FormRow inputKey="first_name" required>
                <Input
                  id={FormFieldIDs.firstName}
                  type={"text"}
                  className={classes.inputWidth}
                  value={formValues[FormFieldIDs.firstName] ?? ""}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, FormFieldIDs.firstName);
                  }}
                  error={
                    (formError[FormFieldIDs.firstName] !== "" &&
                      formError[FormFieldIDs.firstName] !== null) ||
                    formError["name_conflict"] !== ""
                  }
                  onBlur={() => validateNotEmpty(FormFieldIDs.firstName)}
                  inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
                />
                {formError["name_conflict"] && (
                  <FormHelperText className={classes.helperText}>
                    {formError["name_conflict"]}
                  </FormHelperText>
                )}
              </FormRow>
              <FormRow inputKey="last_name" required>
                <Input
                  id={FormFieldIDs.lastName}
                  type={"text"}
                  className={classes.inputWidth}
                  value={formValues[FormFieldIDs.lastName] ?? ""}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, FormFieldIDs.lastName);
                  }}
                  error={
                    (formError[FormFieldIDs.lastName] !== "" &&
                      formError[FormFieldIDs.lastName] !== null) ||
                    formError["name_conflict"] !== ""
                  }
                  onBlur={() => validateNotEmpty(FormFieldIDs.lastName)}
                  inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
                />
              </FormRow>
              <FormRow inputKey="street">
                <Input
                  id={FormFieldIDs.addressOne}
                  type={"text"}
                  className={classes.inputWidth}
                  value={formValues[FormFieldIDs.addressOne] ?? ""}
                  onChange={(e) => {
                    handleChange(
                      e.currentTarget.value,
                      FormFieldIDs.addressOne
                    );
                  }}
                />
              </FormRow>
              <FormRow inputKey="street2">
                <Input
                  id={FormFieldIDs.addressTwo}
                  type={"text"}
                  className={classes.inputWidth}
                  value={formValues[FormFieldIDs.addressTwo] ?? ""}
                  onChange={(e) => {
                    handleChange(
                      e.currentTarget.value,
                      FormFieldIDs.addressTwo
                    );
                  }}
                />
              </FormRow>
              <FormRow inputKey="city" required>
                <Input
                  id={FormFieldIDs.city}
                  type={"text"}
                  className={classes.inputWidth}
                  value={formValues[FormFieldIDs.city] ?? ""}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, FormFieldIDs.city);
                  }}
                  error={
                    (formError[FormFieldIDs.city] !== "" &&
                      formError[FormFieldIDs.city] !== null) ||
                    formError["name_conflict"] !== ""
                  }
                  onBlur={() => validateNotEmpty(FormFieldIDs.city)}
                />
              </FormRow>
              <FormRow inputKey="state">
                <DropdownInputWithTooltip
                  id={FormFieldIDs.state}
                  value={
                    formValues[FormFieldIDs.state]
                      ? getStateLabel(formValues[FormFieldIDs.state] as string)
                      : ""
                  }
                  handleClick={handleOpenFilter}
                />
              </FormRow>
              <FormRow inputKey="zip_code">
                <Input
                  id={FormFieldIDs.zipCode}
                  type={"text"}
                  className={classes.inputWidth}
                  value={formValues[FormFieldIDs.zipCode] ?? ""}
                  onChange={(e) => {
                    handleChange(e.currentTarget.value, FormFieldIDs.zipCode);
                  }}
                />
              </FormRow>
              <FormRow inputKey="date_of_birth">
                <DateOfBirthDatePicker
                  dateOfBirth={formValues[FormFieldIDs.dateOfBirth] ?? ""}
                  saveDateChange={handleChangeDateOfBirth}
                />
              </FormRow>
              <div className={classes.phoneNumbersTitle}>
                {t("phone_numbers").toUpperCase()}
              </div>
              <div className={classes.phoneNumbersContainer}>
                <PhoneNumberPicker
                  fieldID={FormFieldIDs.phoneNumberOne}
                  labelComplement={"1."}
                  defaultCountryCode={defaultRegionCode}
                  phoneNumber={formValues[FormFieldIDs.phoneNumberOne]}
                  formFull={true}
                  onChange={(value: UnitPhoneNumber) =>
                    handleChangePhone(value, FormFieldIDs.phoneNumberOne)
                  }
                />
                <PhoneNumberPicker
                  fieldID={FormFieldIDs.phoneNumberTwo}
                  labelComplement={"2."}
                  defaultCountryCode={defaultRegionCode}
                  phoneNumber={formValues[FormFieldIDs.phoneNumberTwo]}
                  formFull={true}
                  onChange={(value: UnitPhoneNumber) =>
                    handleChangePhone(value, FormFieldIDs.phoneNumberTwo)
                  }
                />
              </div>
            </>
          )}
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("add")}
          </Button>
        </div>
      </form>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1200 }}
      >
        <>
          {popoverID === FormFieldIDs.unitType && (
            <GenericPicker
              selectedValue={formValues["unit-type"] ?? null}
              optionList={unitTypesList}
              localize={true}
              handleChange={(option) =>
                handleChange(option, FormFieldIDs.unitType)
              }
            />
          )}
          {popoverID === FormFieldIDs.state && (
            <GenericPicker
              selectedValue={formValues["state"] ?? null}
              optionList={stateList}
              localize={false}
              handleChange={(option) =>
                handleChange(option, FormFieldIDs.state)
              }
            />
          )}
          {popoverID === "" && null}
        </>
      </Popover>
    </Paper>
  );
};
