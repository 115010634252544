import { useEffect, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/appHooks";
import { getFirebaseDBReference } from "../../helpers/firebase";
import {
  failedFirebaseReports,
  loadCommunityUnits,
  loadFirebaseReports,
} from "./reportsSlice";
import { FloatingNotificationPanel } from "../common/FloatingNotificationPanel";

import { UnitList } from "./UnitsList";
import { ReportsViewer } from "./ReportsViewer";
import { minToolbarHeight } from "../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    reportsContainer: {
      display: "flex",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    unitListContainer: {
      display: "flex",
      flex: "0 0 300px",
      backgroundColor: theme.palette.light.light,
    },
    reportsViewerContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      overflowY: "hidden",
      overflowX: "hidden",
      "@media (max-height: 830px)": {
        overflowY: "auto",
      },
      [theme.breakpoints.down("md")]: {
        overflowY: "auto",
      },
    },
  })
);

export default function Analytics() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const dispatch = useDispatch();

  /* ---- Selectors ---- */
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);

  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  /* ---- Refs ---- */
  const containerRef = useRef<HTMLDivElement | null>(null);

  /* ---- Effects ---- */
  useEffect(() => {
    if (firebaseAuth) {
      dispatch(loadFirebaseReports());
      getFirebaseDBReference()
        .ref(`communities/${communityID}/units`)
        .once(
          "value",
          (snapshot) => {
            const vals = snapshot.val();
            if (vals !== null) {
              let units = Object.keys(vals).map((unitID) => {
                const temp = vals[unitID].settings;
                let residents: any = [];
                if (vals[unitID].residents !== undefined) {
                  residents = Object.keys(vals[unitID].residents).map(
                    (residentID) => vals[unitID].residents[residentID]
                  );
                }
                temp.residents = residents;
                return temp;
              });
              dispatch(loadCommunityUnits(units));
            }
          },
          (error) => {
            dispatch(failedFirebaseReports());
          }
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityID, firebaseAuth]);

  return (
    <div className={classes.reportsContainer}>
      <div className={classes.unitListContainer}>
        <UnitList />
      </div>
      <div
        className={classes.reportsViewerContainer}
        ref={containerRef}
        id="viewer-container"
      >
        <ReportsViewer />
      </div>
      <FloatingNotificationPanel />
    </div>
  );
}
