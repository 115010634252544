import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EventTypes,
  FilterOrder,
  ReadStatus,
  ReducerStates,
} from "../../helpers/constants";
import { NOTIFICATIONS_LIMIT } from "../../services/constants";
import {
  dashboardServices,
  IEvent,
  IEventNotes,
} from "../../services/dashboard.services";
import { notificationsServices } from "../../services/notifications.services";
import { hideSoftNotification } from "../app/appSlice";
import {
  assignEventResponder,
  postNewComment,
  resolveEventWithPassword,
  resolveEventWithPin,
  toggleUnitPaused,
} from "../app/asyncThunkActions";
import {
  updateEventDetails,
  updateEventNotes,
} from "../dashboard/dashboardSlice";
import {
  changeSelectedCommunity,
  setAllNotifications,
} from "../header/headerSlice";
import { logout } from "../login/loginSlice";
import { SortState } from "./NotificationTable";

export const validEventType = (eventEl: IEvent) =>
  eventEl.event_type !== EventTypes.unpause_notification;

export const filteredEvents = (eventEl: IEvent) =>
  eventEl.event_type !== EventTypes.hand_off_notification;

interface FilterData {
  [key: string]: boolean;
}

export interface INotificationsState {
  notifications?: IEvent[];
  unreadEvents?: IEvent[];
  page: number;
  more: boolean;
  selectedNotificationID?: string;
  updateNotifications: boolean;
  state: ReducerStates;
  firebaseState: ReducerStates;
  errorCode: string;
  errorMessage?: string;
  openTray: boolean;
  minimizedTray: boolean;
  toggleFilter: boolean;
  sort: SortState[];
  filters: { [key: string]: FilterData };
}

export const initialNotificationsState: INotificationsState = {
  notifications: undefined,
  unreadEvents: undefined,
  page: 0,
  more: true,
  selectedNotificationID: undefined,
  updateNotifications: false,
  state: ReducerStates.IDLE,
  firebaseState: ReducerStates.IDLE,
  errorCode: "",
  errorMessage: "",
  openTray: false,
  minimizedTray: false,
  toggleFilter: false,
  sort: [{ order: FilterOrder.desc, orderBy: "created" }],
  filters: {},
};

// Get an event by id
export const getEventDataNotifications = createAsyncThunk(
  "dashboard/getEventDataNotifications",
  async (eventID: string) => {
    const response = await dashboardServices.getEvent(eventID);
    return response;
  }
);

// Gets the community units and events for the community
export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (params: {
    community_id: string;
    page: number;
    sort: SortState[];
    filters: { [key: string]: FilterData };
    allHelpAtHome: boolean;
  }) => {
    const events = await notificationsServices.notificationEvents({
      community_id: params.community_id,
      page: params.page,
      sort: params.sort,
      filters: params.filters,
      allHelpAtHome: params.allHelpAtHome,
    });
    if (typeof events !== "boolean") {
      return events;
    } else {
      return [];
    }
  }
);

// Gets the community units and events for the community
export const getMoreNotifications = createAsyncThunk(
  "notifications/getMoreNotifications",
  async (params: {
    community_id: string;
    page: number;
    sort: SortState[];
    filters: { [key: string]: FilterData };
    allHelpAtHome: boolean;
  }) => {
    const events = await notificationsServices.notificationEvents({
      community_id: params.community_id,
      page: params.page,
      sort: params.sort,
      filters: params.filters,
      allHelpAtHome: params.allHelpAtHome,
    });
    return events;
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialNotificationsState,
  reducers: {
    selectNotification(state, action) {
      const notificationID = action.payload;
      state.selectedNotificationID = notificationID;
      state.openTray = true;
      state.minimizedTray = false;
    },
    updateNotification(state, action) {
      const incomingEvent: IEvent = action.payload;
      if (state.notifications === undefined) return;
      const eventIndex = state.notifications.findIndex(
        (event) => event.id === incomingEvent.id
      );
      if (eventIndex !== -1) {
        const oldData = state.notifications[eventIndex].data;
        state.notifications[eventIndex] = {
          ...state.notifications[eventIndex],
          ...incomingEvent,
        };
        state.notifications[eventIndex].data = oldData;
      }
    },
    minimizeNotificationsTray(state) {
      state.openTray = false;
      state.minimizedTray = true;
    },
    maximizeNotificationsTray(state) {
      state.openTray = true;
      state.minimizedTray = false;
    },
    closeNotificationsTray(state) {
      state.selectedNotificationID =
        initialNotificationsState.selectedNotificationID;
      state.openTray = initialNotificationsState.openTray;
      state.minimizedTray = initialNotificationsState.minimizedTray;
    },
    applySort(state, action: PayloadAction<SortState[]>) {
      state.sort = action.payload;
    },
    clearSort(state) {
      state.sort = initialNotificationsState.sort;
    },
    toggleFilter(state) {
      state.toggleFilter = !state.toggleFilter;
    },
    applyFilters(
      state,
      action: PayloadAction<{ key: string; values: FilterData }>
    ) {
      // Check if every value is true, if it is and we already have a filter, delete from the node
      if (
        Object.values(action.payload.values).every((val) => val) &&
        action.payload.key in state.filters
      ) {
        if (action.payload.key !== "notification") {
          delete state.filters[action.payload.key];
        } else {
          state.filters[action.payload.key] = action.payload.values;
        }
      } else {
        state.filters[action.payload.key] = action.payload.values;
      }
    },
    clearFilter(state, action) {
      delete state.filters[action.payload];
    },
    clearFilters(state) {
      state.filters = initialNotificationsState.filters;
    },
    setUnreadEvents(state, action: PayloadAction<IEvent[]>) {
      state.unreadEvents = action.payload;

      if (state.notifications === undefined) return;
      const eventsToUpdate = state.unreadEvents.flatMap((_event) => _event.id);
      state.notifications = state.notifications.map((notif) =>
        eventsToUpdate.includes(notif.id)
          ? { ...notif, read_status: ReadStatus.UNREAD }
          : notif
      );
    },
    removeUnreadEvent(state, action: PayloadAction<string>) {
      if (state.unreadEvents === undefined) return;
      const eventID = action.payload;
      // Delete from unread node
      const findIndex = state.unreadEvents.findIndex(
        (_event) => _event.id === eventID
      );
      if (findIndex !== -1) {
        state.unreadEvents = state.unreadEvents.splice(findIndex, 1);
      }

      //Mark as unread if it exists in the events node
      if (state.notifications === undefined) return;
      const findIndexEvents = state.notifications.findIndex(
        (_event) => _event.id === eventID
      );
      if (findIndexEvents !== -1) {
        state.notifications[findIndexEvents].read_status = ReadStatus.READ;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state, action) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialNotificationsState.errorCode;
        state.errorMessage = initialNotificationsState.errorMessage;
        state.page = action.meta.arg.page;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        let events: IEvent[] = action.payload;
        if (events.length < NOTIFICATIONS_LIMIT) {
          state.more = false;
        } else {
          state.more = true;
        }

        if (state.unreadEvents !== undefined && state.unreadEvents.length > 0) {
          const eventsToUpdate = state.unreadEvents.flatMap(
            (_event) => _event.id
          );

          events = events.map((notif) =>
            eventsToUpdate.includes(notif.id)
              ? { ...notif, read_status: ReadStatus.UNREAD }
              : notif
          );
        }

        // If an event is selected, update the new event list to have the
        // event notes for the selected event
        if (state.selectedNotificationID && state.notifications) {
          const selectNotification = state.notifications.find(
            (notification) => notification.id === state.selectedNotificationID
          );
          events = events.map((notif) =>
            notif.id === state.selectedNotificationID
              ? { ...notif, event_notes: selectNotification?.event_notes }
              : notif
          );
        }
        state.notifications = events;

        if (state.selectedNotificationID !== undefined) {
          const selectedNotification = state.notifications.find(
            (notificaton) => notificaton.id === state.selectedNotificationID
          );
          if (selectedNotification === undefined) {
            state.selectedNotificationID =
              initialNotificationsState.selectedNotificationID;
            state.openTray = initialNotificationsState.openTray;
          }
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(getMoreNotifications.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialNotificationsState.errorCode;
        state.errorMessage = initialNotificationsState.errorMessage;
      })
      .addCase(getMoreNotifications.fulfilled, (state, action) => {
        const events: IEvent[] = action.payload;

        if (events.length < NOTIFICATIONS_LIMIT) state.more = false;

        if (state.notifications) {
          state.notifications = state.notifications?.concat(events);
        } else {
          state.notifications = events;
        }

        state.page = action.meta.arg.page;
        state.state = ReducerStates.SUCCEEDED;

        if (state.unreadEvents === undefined) return;
        const eventsToUpdate = state.unreadEvents.flatMap(
          (_event) => _event.id
        );
        state.notifications = state.notifications.map((notif) =>
          eventsToUpdate.includes(notif.id)
            ? { ...notif, read_status: ReadStatus.UNREAD }
            : notif
        );
      })
      .addCase(getMoreNotifications.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(changeSelectedCommunity, (state) => {
        state.state = ReducerStates.IDLE;
        state.firebaseState = ReducerStates.IDLE;
        state.openTray = initialNotificationsState.openTray;
        state.minimizedTray = initialNotificationsState.minimizedTray;
        state.notifications = initialNotificationsState.notifications;
        state.selectedNotificationID =
          initialNotificationsState.selectedNotificationID;
        state.more = initialNotificationsState.more;
        state.toggleFilter = initialNotificationsState.toggleFilter;
        state.page = initialNotificationsState.page;
        state.sort = initialNotificationsState.sort;
        state.filters = initialNotificationsState.filters;
      })
      .addCase(setAllNotifications, (state) => {
        state.state = ReducerStates.IDLE;
        state.firebaseState = ReducerStates.IDLE;
        state.openTray = initialNotificationsState.openTray;
        state.minimizedTray = initialNotificationsState.minimizedTray;
        state.notifications = initialNotificationsState.notifications;
        state.selectedNotificationID =
          initialNotificationsState.selectedNotificationID;
        state.more = initialNotificationsState.more;
        state.toggleFilter = initialNotificationsState.toggleFilter;
        state.page = initialNotificationsState.page;
        state.sort = initialNotificationsState.sort;
        state.filters = initialNotificationsState.filters;
      })
      .addCase(hideSoftNotification, (state) => {
        state.errorCode = initialNotificationsState.errorCode;
        state.errorMessage = initialNotificationsState.errorMessage;
      })

      .addCase(assignEventResponder.pending, (state, action) => {
        const fromNotifications = action.meta.arg.fromNotifications;
        state.updateNotifications = fromNotifications;
        if (fromNotifications) state.state = ReducerStates.PENDING;
        state.errorCode = initialNotificationsState.errorCode;
        state.errorMessage = initialNotificationsState.errorMessage;
      })
      .addCase(assignEventResponder.fulfilled, (state, action) => {
        const newEvent: IEvent = action.payload;
        const eventIndex = state.notifications?.findIndex(
          (event) => event.id === newEvent.id
        );
        if (
          state.notifications !== undefined &&
          eventIndex !== -1 &&
          eventIndex !== undefined
        ) {
          state.notifications[eventIndex] = newEvent;
        }

        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;
        state.updateNotifications =
          initialNotificationsState.updateNotifications;
      })
      .addCase(assignEventResponder.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialNotificationsState.updateNotifications;
        }
      })
      .addCase(resolveEventWithPin.pending, (state, action) => {
        const fromNotifications = action.meta.arg.fromNotifications;
        state.updateNotifications = fromNotifications;
        if (fromNotifications) state.state = ReducerStates.PENDING;
        state.errorCode = initialNotificationsState.errorCode;
        state.errorMessage = initialNotificationsState.errorMessage;
      })
      .addCase(resolveEventWithPin.fulfilled, (state, action) => {
        if (action.payload) {
          const newEvent = (action.payload as unknown) as IEvent;
          const eventIndex = state.notifications?.findIndex(
            (event) => event.id === newEvent.id
          );
          if (
            state.notifications !== undefined &&
            eventIndex !== -1 &&
            eventIndex !== undefined
          ) {
            state.notifications[eventIndex] = newEvent;
          }
        }
        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;
        state.updateNotifications =
          initialNotificationsState.updateNotifications;
      })
      .addCase(resolveEventWithPin.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialNotificationsState.updateNotifications;
        }
      })
      .addCase(resolveEventWithPassword.pending, (state, action) => {
        const fromNotifications = action.meta.arg.fromNotifications;
        if (fromNotifications) {
          state.updateNotifications = fromNotifications;
          state.state = ReducerStates.PENDING;
          state.errorCode = initialNotificationsState.errorCode;
          state.errorMessage = initialNotificationsState.errorMessage;
        }
      })
      .addCase(resolveEventWithPassword.fulfilled, (state, action) => {
        if (action.payload) {
          const newEvent = (action.payload as unknown) as IEvent;
          const eventIndex = state.notifications?.findIndex(
            (event) => event.id === newEvent.id
          );
          if (
            state.notifications !== undefined &&
            eventIndex !== -1 &&
            eventIndex !== undefined
          ) {
            state.notifications[eventIndex] = newEvent;
          }
        }
        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;
        state.updateNotifications =
          initialNotificationsState.updateNotifications;
      })
      .addCase(resolveEventWithPassword.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialNotificationsState.updateNotifications;
        }
      })
      .addCase(postNewComment.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialNotificationsState.errorCode;
        state.errorMessage = initialNotificationsState.errorMessage;
      })
      .addCase(postNewComment.fulfilled, (state) => {
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(postNewComment.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(toggleUnitPaused.pending, (state, action) => {
        const fromNotifications = action.meta.arg.fromNotifications;
        if (fromNotifications) {
          state.updateNotifications = fromNotifications;
          state.state = ReducerStates.PENDING;
          state.errorCode = initialNotificationsState.errorCode;
          state.errorMessage = initialNotificationsState.errorMessage;
        }
      })
      .addCase(toggleUnitPaused.fulfilled, (state, action) => {
        if (action.payload) {
          const { event } = action.payload;
          const eventIndex = state.notifications?.findIndex(
            (current) => current.id === event.id
          );
          if (
            state.notifications !== undefined &&
            eventIndex !== -1 &&
            eventIndex !== undefined
          ) {
            state.notifications[eventIndex] = event;
          }
        }
        if (state.updateNotifications) state.state = ReducerStates.SUCCEEDED;
        state.updateNotifications =
          initialNotificationsState.updateNotifications;
      })
      .addCase(toggleUnitPaused.rejected, (state, action) => {
        if (state.updateNotifications) {
          state.state = ReducerStates.FAILED;
          state.errorMessage = action.error.message;
          state.updateNotifications =
            initialNotificationsState.updateNotifications;
        }
      })
      .addCase(getEventDataNotifications.pending, (state) => {
        state.state = ReducerStates.PENDING;
        state.errorCode = initialNotificationsState.errorCode;
        state.errorMessage = initialNotificationsState.errorMessage;
      })
      .addCase(getEventDataNotifications.fulfilled, (state, action) => {
        const event: IEvent = action.payload;
        if (state.notifications !== undefined) {
          const localEventIndex = state.notifications.findIndex(
            (_event) => _event.id === event.id
          );
          if (localEventIndex !== -1) {
            state.notifications[localEventIndex] = {
              ...state.notifications[localEventIndex],
              ...event,
            };
          } else {
            state.notifications.push(event);
          }
        }
        state.state = ReducerStates.SUCCEEDED;
      })
      .addCase(getEventDataNotifications.rejected, (state, action) => {
        state.state = ReducerStates.FAILED;
        state.errorMessage = action.error.message;
      })
      .addCase(
        updateEventDetails.type,
        (state, action: PayloadAction<IEvent>) => {
          const incomingEvent: IEvent = action.payload;
          if (state.notifications === undefined) return;
          const eventIndex = state.notifications.findIndex(
            (event) => event.id === incomingEvent.id
          );
          if (eventIndex !== -1) {
            state.notifications[eventIndex].status = incomingEvent.status;
            state.notifications[eventIndex].responder_id =
              incomingEvent.responder_id;

            if (state.notifications[eventIndex].time_assigned !== undefined)
              state.notifications[eventIndex].time_assigned =
                incomingEvent.time_assigned;
            if (state.notifications[eventIndex].time_resolved !== undefined)
              state.notifications[eventIndex].time_resolved =
                incomingEvent.time_resolved;
            if (
              state.notifications[eventIndex].event_resolution_id !== undefined
            )
              state.notifications[eventIndex].event_resolution_id =
                incomingEvent.event_resolution_id;
          }
        }
      )
      .addCase(
        updateEventNotes.type,
        (
          state,
          action: PayloadAction<{ eventID: string; eventNotes: IEventNotes[] }>
        ) => {
          const eventID = action.payload.eventID;
          const eventNotes: IEventNotes[] = action.payload.eventNotes;
          if (state.notifications === undefined) return;
          const eventIndex = state.notifications.findIndex(
            (event) => event.id === eventID
          );
          // If event exists
          if (eventIndex !== -1) {
            const notes = state.notifications[eventIndex].event_notes;
            // If event doesn't have notes, replace them directly
            if (notes === undefined) {
              state.notifications[eventIndex].event_notes = eventNotes;
            } else {
              // Search for new notes and add them at the beggining
              eventNotes.forEach((note) => {
                const noteIndex = notes.findIndex(
                  (localNote) => localNote.id === note.id
                );
                if (noteIndex === -1) {
                  notes.unshift(note);
                }
              });
            }
          }
        }
      )
      .addCase(logout.type, (state) => {
        return initialNotificationsState;
      });
  },
});

const { reducer } = notificationsSlice;
export const {
  selectNotification,
  updateNotification,
  minimizeNotificationsTray,
  maximizeNotificationsTray,
  closeNotificationsTray,
  toggleFilter,
  applySort,
  clearSort,
  applyFilters,
  clearFilters,
  setUnreadEvents,
  removeUnreadEvent,
  clearFilter,
} = notificationsSlice.actions;
export default reducer;
