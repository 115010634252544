import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppDispatch } from "../../app/appHooks";
import { useAppSelector } from "../../app/appHooks";
import { Collapse, Grid, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { getFirebaseDBReference } from "../../../helpers/firebase";
import { setCommunityEvents } from "../dashboardSlice";
import { getEventsToUpdateFirebase } from "../../../helpers/utils";
import { TabTypes } from "../../../helpers/constants";
import { IEvent } from "../../../services/dashboard.services";
import { DateTimeISO, DateTimeNow } from "../../../helpers/datetime";
import { EventsIndicator } from "../activeNotificationPanel/EventsIndicator";
import { RecentEventRow } from "./RecentEventRow";
import useEventTypesCount from "../hooks/useEventTypes";
import clsx from "clsx";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    communityCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.light.light,
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      borderRadius: "5px",
    },
    cardTitle: {
      display: "flex",
      width: "100%",
      cursor: "pointer",
    },
    disabledCard: {
      cursor: "default",
      pointerEvents: "none",
    },
    disabledButton: {
      color: theme.palette.action.disabled,
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    unitContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      position: "relative",
      zIndex: 2,
      alignContent: "flex-start",
      padding: theme.spacing(2, 2),
    },
  })
);

const getTimeframeISO = (id: number) => {
  const defaultDate = DateTimeNow().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const options = { suppressMilliseconds: true, includeOffset: false };
  switch (id) {
    case 0:
      return defaultDate.toISO(options);
    case 1:
      return defaultDate.minus({ days: 1 }).toISO(options);
    case 2:
      return defaultDate.minus({ days: 2 }).toISO(options);
    case 3:
      return defaultDate.minus({ days: 4 }).toISO(options);
    case 4:
      return defaultDate.minus({ days: 6 }).toISO(options);
    case 5:
      return defaultDate.minus({ days: 14 }).toISO(options);
    case 6:
      return defaultDate.minus({ days: 29 }).toISO(options);
    default:
      return defaultDate.toISO(options);
  }
};

const getEventsByDate = (
  events: IEvent[] | undefined,
  communityTimezone?: string
) => {
  if (events === undefined) return undefined;
  const eventsByDate: { [key: string]: IEvent[] } = {};
  events.forEach((event) => {
    const eventDate: string = DateTimeISO(
      event.time_created,
      communityTimezone
    ).toISODate();
    if (eventsByDate.hasOwnProperty(eventDate)) {
      eventsByDate[eventDate].push(event);
    } else {
      eventsByDate[eventDate] = [event];
    }
  });
  const ordered = Object.keys(eventsByDate)
    .sort()
    .reduce((obj: { [key: string]: IEvent[] }, key) => {
      obj[key] = eventsByDate[key];
      return obj;
    }, {});
  return ordered;
};

export const CommunityCard = (props: IProps) => {
  const { communityID, name, timeframe, addTab } = props;

  /* ---- State ---- */
  const [open, setOpen] = useState(true);
  const [communitiesLength, setCommunitiesLength] = useState<number>(0);

  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const communityTimezone = useCommunityUnitTimezone(communityID);

  /* ---- Selector ---- */
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);

  const communityEvents = useAppSelector((state) => {
    const eventsTimeframe = getTimeframeISO(timeframe);
    const events = state.dashboardState.communityEvents
      ?.filter((_event) => _event.community_id === communityID)
      .filter(
        (event) =>
          DateTimeISO(event.time_created).toISO({
            suppressMilliseconds: true,
            includeOffset: false,
          }) >= eventsTimeframe
      );
    return events;
  });

  const { criticalEventsCount, warningEventsCount } = useEventTypesCount(
    communityEvents
  );

  /* ---- Effects ---- */
  // Get community events
  useEffect(() => {
    if (firebaseAuth && communityID) {
      const eventsTimeframe = getTimeframeISO(timeframe);
      getFirebaseDBReference()
        .ref(`communities/${communityID}/events`)
        .orderByChild("time_created")
        .startAfter(eventsTimeframe)
        .once("value", (snapshot) => {
          const vals = snapshot.val();
          let _records = getEventsToUpdateFirebase(vals);
          dispatch(setCommunityEvents(_records));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityID, timeframe, firebaseAuth]);

  useEffect(() => {
    if (communityEvents !== undefined && communityEvents.length > 0) {
      setCommunitiesLength(communityEvents.length);
    } else {
      setCommunitiesLength(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, communityEvents]);

  useEffect(() => {
    if (communitiesLength > 0) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, communitiesLength]);

  useEffect(() => {
    if (communitiesLength === 0) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, communitiesLength]);

  /* ---- Methods ---- */
  const handleClick = () => {
    setOpen(!open);
  };

  const eventsByDate = getEventsByDate(communityEvents, communityTimezone);

  const disableCollapse =
    communityEvents === undefined || communityEvents.length === 0;

  return (
    <div className={classes.communityCard}>
      <div
        className={clsx(
          classes.cardTitle,
          disableCollapse && classes.disabledCard
        )}
        onClick={handleClick}
      >
        <Typography className={classes.title} variant="h5">
          {open ? (
            <ArrowDropDownIcon
              className={clsx(disableCollapse && classes.disabledButton)}
              style={{ fontSize: 40 }}
            />
          ) : (
            <ArrowRightIcon
              className={clsx(disableCollapse && classes.disabledButton)}
              style={{ fontSize: 40 }}
            />
          )}
          {name}
        </Typography>
        <EventsIndicator
          amountCriticalEvents={criticalEventsCount}
          amountWarningEvents={warningEventsCount}
        />
      </div>
      <Collapse in={open} timeout="auto">
        <div className={classes.unitContainer}>
          <Grid container spacing={3}>
            {eventsByDate !== undefined &&
              Object.keys(eventsByDate)
                .reverse()
                .map((key, index) => {
                  return (
                    <RecentEventRow
                      key={index}
                      communityID={communityID}
                      eventsDate={key}
                      events={eventsByDate[key]}
                      timeframe={timeframe}
                      addTab={addTab}
                    />
                  );
                })}
          </Grid>
        </div>
      </Collapse>
    </div>
  );
};

interface IProps {
  communityID: string;
  name: string;
  timeframe: number;
  addTab: (id: string, type: TabTypes) => void;
}
