import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Paper,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { roleLabels } from "./common/RolePicker";
import { FormRow } from "./common/FormRow";
import { ICommunityGroup } from "../../services/header.services";
import { IUnit } from "../../services/dashboard.services";
import { getResidentName, isHelpAtHome } from "../dashboard/dashboardSlice";
import {
  CommunityTypes,
  RoleTypes,
  sortAlphabetical,
} from "../../helpers/constants";
import { IAddCommunityUserData } from "../../services/settings.services";
import { addCommunityUser } from "../app/asyncThunkActions";
import i18n from "../../i18n";
import { CommunityUsersPopovers } from "./common/CommunityUserPopovers";
import { emailValidation } from "../../helpers/utils";
import { loadCommunityUsers } from "../header/headerThunks";
import { ErrorCodes } from "../../services/constants";
import { handledError, showSoftNotification } from "../app/appSlice";
import { SoftNotificationTypes } from "../common/AppSnack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    EditUserContainer: {
      padding: theme.spacing(6, 7),
      width: "550px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    wrapper: {
      listStyle: "none",
      padding: 0,
      borderRadius: "3px",
    },
    rowInput: {
      flex: "1.5",
      fontSize: "1rem",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1em .5em 0",
        flex: " 1",
        textAlign: "right",
        color: theme.palette.paused.main,
      },
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputWidth: {
      width: "100%",
    },
  })
);

interface FormState {
  email: string | null;
  role: string | null;
  notification_groups: string[];
  accessible_units: string[];
}

export const getPreSelectedGroups = (
  roleOption: string,
  communityGroups: ICommunityGroup[]
) => {
  let notificationGroups: string[] = [];
  if (roleOption === RoleTypes.pro_family) {
    notificationGroups = communityGroups.map((group) => group.id);
  }

  // Pre select Admins caregroup, otherwise none
  if (roleOption === RoleTypes.admin) {
    const preSelectGroup = communityGroups.find(
      (group) => group.name === "Admins"
    );

    if (preSelectGroup) {
      notificationGroups = [preSelectGroup.id];
    }
  }
  // Pre select Caregivers caregroup, otherwise none
  if (roleOption === RoleTypes.caregiver && communityGroups) {
    notificationGroups = communityGroups.map((group) => group.id);
    const preSelectGroup = communityGroups.find(
      (group) => group.name === "Caregivers"
    );

    if (preSelectGroup) {
      notificationGroups = [preSelectGroup.id];
    }
  }
  return notificationGroups;
};

export function AddCommunityUser() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverID, setPopoverID] = useState("");
  const [userValues, setUserValues] = useState<FormState>({
    email: null,
    role: null,
    notification_groups: [],
    accessible_units: [],
  });
  const [allUnits, setAllUnits] = useState(false);
  const [formError, setFormError] = useState("");

  /* Selectors */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const errorCode = useAppSelector((state) => state.settingsState.errorCode);

  const userRoles = useAppSelector((state) => {
    const communityType = state.headerState.selectedCommunity?.community_type;
    if (communityType === undefined) return undefined;
    if (state.headerState.userRoles === undefined) return undefined;

    if (communityType === CommunityTypes.pro) {
      // remove pro: from family
      return state.headerState.userRoles.filter(
        (role) => role.name !== RoleTypes.family
      );
    } else if (communityType === CommunityTypes.aip) {
      return state.headerState.userRoles.filter(
        (role) => role.name !== RoleTypes.pro_family
      );
    }
    return state.headerState.userRoles;
  });

  const communityGroups = useAppSelector((state) => {
    if (state.headerState.communityGroups !== undefined) {
      return JSON.parse(
        JSON.stringify(state.headerState.communityGroups)
      ) as ICommunityGroup[];
    }
    return state.headerState.communityGroups;
  })?.sort((a, b) => sortAlphabetical(a.name, b.name));

  const units = useAppSelector((state) => state.headerState.units);
  const installerRole = useAppSelector(
    (state) => state.headerState.installerRole
  );

  /* Effects */
  // Init values
  useEffect(() => {
    let roleOption: string | null = null;
    if (installerRole) {
      roleOption = RoleTypes.pro_family;
    } else if (userRoles && userRoles.length > 0) {
      roleOption =
        userRoles.find((role) => role.name === RoleTypes.admin)?.name ?? null;
    }

    let notificationGroups: string[] = [];

    if (communityGroups && roleOption !== null) {
      notificationGroups = getPreSelectedGroups(roleOption, communityGroups);
    }

    let authorizedUnits: string[] = [];
    if (!installerRole) {
      // If user.role.all_units is null or true, user has access to all units
      if (units) {
        setAllUnits(true);
        authorizedUnits = units.map((unit) => unit.id);
      }
    }

    setUserValues({
      email: null,
      role: roleOption,
      notification_groups: notificationGroups,
      accessible_units: authorizedUnits,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.INTEGRITY_ERROR:
        case ErrorCodes.EMAIL_ALREADY_EXISTS:
          dispatch(handledError());
          dispatch(
            showSoftNotification(
              SoftNotificationTypes.EMAIL_ALREADY_EXIST_ERROR
            )
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  /* Methods */
  // Picker methods
  const handleOpenFilter = (event: any, popoverID: string) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(popoverID);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  // Component methods
  const getNotificationGroupText = (
    notificationGroups: string[] | null,
    communityGroups?: ICommunityGroup[]
  ) => {
    let notificationGroupDisplay: string[] | undefined = undefined;
    if (notificationGroups !== null) {
      const nameList = notificationGroups.map((notifGroup) => {
        const notifGroupName = communityGroups?.find(
          (communityGroup) => communityGroup.id === notifGroup
        );
        if (notifGroupName !== undefined) {
          return notifGroupName.name ?? "";
        }
        return "";
      });
      // no_notification_groups
      notificationGroupDisplay = nameList.length > 0 ? nameList : undefined;
    }
    return notificationGroupDisplay;
  };

  const getUnitsText = (
    userRole: string | null,
    authorizedUnits: string[],
    allUnits: boolean,
    units?: IUnit[]
  ) => {
    let notificationGroupDisplay = "";
    if (userRole === RoleTypes.pro_family && authorizedUnits.length === 0) {
      return (notificationGroupDisplay = i18n.t("select_unit"));
    }
    if (allUnits && userRole !== RoleTypes.pro_family) {
      return (notificationGroupDisplay = i18n.t("all_units_accessible"));
    }
    if (units !== undefined) {
      const filteredArray = units.filter((option) =>
        authorizedUnits.includes(option.id)
      );

      const nameList = filteredArray.map((unit) => {
        const isHelpAtHomeUnit = isHelpAtHome(unit);

        if (isHelpAtHomeUnit) {
          return unit
            ? getResidentName(unit.residents, {
                fullFirstName: false,
              })
            : "-";
        } else {
          return unit ? unit.name : "-";
        }
      });

      notificationGroupDisplay =
        nameList.length > 0
          ? nameList.sort((a, b) => sortAlphabetical(a, b)).join(", ")
          : i18n.t("no_units_selected");
    } else {
      notificationGroupDisplay = i18n.t("no_units_selected");
    }
    return notificationGroupDisplay;
  };

  const onChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserValues((prevState) => ({
      ...prevState,
      [key]: event.target.value.trimStart(),
    }));
  };

  const handleRoleChange = (value: string) => {
    if (value === userValues.role) {
      handleCloseFilter();
      return;
    }
    if (value === RoleTypes.pro_family) {
      setUserValues((prevVal) => ({
        ...prevVal,
        role: value,
        notification_groups: [],
        accessible_units: allUnits
          ? []
          : prevVal.accessible_units.length !== 0
          ? prevVal.accessible_units
          : [],
      }));
    } else {
      setUserValues((prevVal) => {
        if (communityGroups === undefined) return { ...prevVal };
        if (units === undefined) return { ...prevVal };

        let newAccessibleUnits: string[] = [];
        if (prevVal.accessible_units.length > 0) {
          newAccessibleUnits = prevVal.accessible_units;
        } else {
          setAllUnits(true);
          newAccessibleUnits = units.map((unit) => unit.id);
        }

        return {
          ...prevVal,
          role: value,
          notification_groups: getPreSelectedGroups(value, communityGroups),
          accessible_units: newAccessibleUnits,
        };
      });
    }
    handleCloseFilter();
  };

  const handleGroupsChange = (value: string[]) => {
    setUserValues((prevVal) => ({ ...prevVal, notification_groups: value }));
    handleCloseFilter();
  };
  const handleUnitsChange = (value: string[]) => {
    const allSelected = value.length === units?.length;
    setUserValues((prevVal) => ({ ...prevVal, accessible_units: value }));
    setAllUnits(allSelected);
    handleCloseFilter();
  };

  const checkFormValidity = (newVals: FormState) => {
    let validForm = true;

    if (
      newVals.email === null ||
      newVals.email === "" ||
      emailValidation(newVals.email)
    ) {
      validForm = false;
    }

    if (newVals.role === RoleTypes.pro_family) {
      validForm = newVals.accessible_units.length > 0;
    }

    return validForm;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (userValues.email === null) return;
    if (userValues.role === null) return;
    if (communityID === undefined) return;

    let userData: IAddCommunityUserData | undefined = undefined;

    userData = {
      name: userValues.role,
      email: userValues.email,
      community_id: communityID,
      unit_ids: userValues.accessible_units,
      all_units: allUnits,
      group_ids: userValues.notification_groups,
    };

    if (userValues.role === RoleTypes.pro_family) {
      delete userData.all_units;
    } else {
      if (allUnits) {
        delete userData.unit_ids;
      }
    }

    if (allUnits && userValues.role !== RoleTypes.pro_family) {
      delete userData.unit_ids;
    }

    const addCommunityRoleResult = await dispatch(addCommunityUser(userData));
    if (addCommunityUser.fulfilled.match(addCommunityRoleResult)) {
      dispatch(loadCommunityUsers(communityID));
    }
  };

  const open = Boolean(anchorEl);

  let notificationGroupDisplay = getNotificationGroupText(
    userValues.notification_groups,
    communityGroups
  );

  let authorizedUnitsDisplay = getUnitsText(
    userValues.role,
    userValues.accessible_units,
    allUnits,
    units
  );

  const allGroups =
    userValues.notification_groups?.sort().join(",") ===
    communityGroups
      ?.map((group) => group.id)
      .sort()
      .join(",");

  const validateEmail = () => {
    if (
      userValues.email === null ||
      userValues.email === "" ||
      emailValidation(userValues.email)
    ) {
      setFormError(t("invalid_email"));
    } else {
      setFormError("");
    }
  };

  const enableSubmit = checkFormValidity(userValues);

  return (
    <Paper className={classes.EditUserContainer} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("new_user")}</h2>
      <form
        id="add-new-user-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.wrapper}>
          <FormRow inputKey="email" required>
            <Input
              id="user-email"
              type={"text"}
              className={classes.inputWidth}
              value={
                userValues.email !== null ? (userValues.email as string) : ""
              }
              error={formError !== "" || errorCode !== ""}
              onChange={onChange("email")}
              onBlur={validateEmail}
            />
          </FormRow>
          <FormRow inputKey="role">
            <Input
              id="edit-community-user-role"
              type={"text"}
              value={
                userValues.role !== null &&
                t(`${roleLabels[userValues.role] ?? ""}`)
              }
              onClick={(e) =>
                !installerRole ? handleOpenFilter(e, "role") : ""
              }
              className={classes.inputWidth}
              disabled={installerRole}
              endAdornment={
                <InputAdornment id="role" position="end">
                  <IconButton
                    aria-label="toggle role picker"
                    disabled={installerRole}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormRow>
          {userValues.role !== RoleTypes.pro_family && (
            <FormRow inputKey="notification_groups">
              <Input
                id="edit-community-user-notification_groups"
                type={"text"}
                readOnly
                disabled={userValues.role === RoleTypes.pro_family}
                onClick={
                  userValues.role !== RoleTypes.pro_family
                    ? (e) => handleOpenFilter(e, "notification_groups")
                    : undefined
                }
                className={classes.inputWidth}
                value={
                  !allGroups
                    ? notificationGroupDisplay !== undefined
                      ? notificationGroupDisplay.join(", ")
                      : t("no_notification_groups")
                    : t("all_notification_groups")
                }
                endAdornment={
                  <InputAdornment id="notification_groups" position="end">
                    <IconButton
                      disabled={userValues.role === RoleTypes.pro_family}
                      aria-label="toggle notification groups picker"
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormRow>
          )}
          <FormRow inputKey="accessible_units">
            <Input
              id="edit-community-user-notification_groups"
              type={"text"}
              readOnly
              error={
                userValues.role === RoleTypes.pro_family &&
                userValues.accessible_units.length === 0
              }
              onClick={(e) => handleOpenFilter(e, "accessible_units")}
              className={classes.inputWidth}
              value={authorizedUnitsDisplay}
              endAdornment={
                <InputAdornment id="accessible_units" position="end">
                  <IconButton aria-label="toggle accessible units picker">
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormRow>
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("add")}
          </Button>
        </div>
      </form>
      <CommunityUsersPopovers
        popoverID={popoverID}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        userValues={userValues}
        handleRoleChange={handleRoleChange}
        handleGroupsChange={handleGroupsChange}
        handleUnitsChange={handleUnitsChange}
      />
    </Paper>
  );
}
