import React, { useEffect, useRef } from "react";
import {
  getEventDataDashboard,
  IEnhancedUnit as IUnit,
  isHardEvent,
  isSoftEvent,
  toggleUnitWatchFlag,
  updateUnit,
} from "./dashboardSlice";
import { IEvent } from "../../services/dashboard.services";
import Popover, { PopoverActions } from "@material-ui/core/Popover/Popover";
import {
  EventStatus,
  EventTypes,
  iconByEventType,
  ReadStatus,
  TabTypes,
  UnitStatus,
} from "../../helpers/constants";
import Icons from "../../helpers/iconImports";
import UnitEventList from "./unitDashboard/UnitEventsList";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import { getFirebaseDBReference } from "../../helpers/firebase";
import { UnitLayout } from "./UnitLayout";
import { getUnitTitle } from "./CommunityUnit";

const filterPausedEvents = (events?: IEvent[]) =>
  events?.filter((eventEl) => !isPausedEvent(eventEl));

export const isPausedEvent = (eventEl: IEvent) =>
  eventEl.event_type === EventTypes.pause_notification;

const validateBadgeAmount = (amount?: number) => {
  const eventAmount = Number(amount);
  return String(!Number.isNaN(eventAmount) ? eventAmount : "");
};

// Determines which icon to display or if a badge replaces the icon
const unitIcon = (
  unitStatus?: string,
  softEvents?: IEvent[],
  hardEvents?: IEvent[]
) => {
  let unitIcon = "";
  let hasBadge = false;
  let isAssigned = false;
  let isResolved = false;
  let event = undefined;

  const filteredHardEvents = filterPausedEvents(hardEvents);
  const filteredSoftEvents = filterPausedEvents(softEvents);

  const hasHardEvents =
    filteredHardEvents !== undefined && filteredHardEvents.length > 0;
  const hasSoftEvents =
    filteredSoftEvents !== undefined && filteredSoftEvents.length > 0;

  // if has both, we would have at least 2 events, which means show badge
  if (hasHardEvents && hasSoftEvents) {
    const eventAmount =
      Number(filteredSoftEvents?.length) + Number(filteredHardEvents?.length);
    unitIcon = validateBadgeAmount(eventAmount);
    hasBadge = true;
    // only hard
  } else if (hasHardEvents) {
    if (filteredHardEvents !== undefined) {
      if (filteredHardEvents.length === 1) {
        event = filteredHardEvents[0];
        unitIcon = iconByEventType(event.event_type);
        isAssigned =
          event.status !== EventStatus.UNASSIGNED && isHardEvent(event);
        isResolved =
          event.status === EventStatus.RESOLVED && isHardEvent(event);
      } else {
        const eventAmount = filteredHardEvents?.length;
        unitIcon = validateBadgeAmount(eventAmount);
        hasBadge = true;
      }
    }
    // only soft
  } else if (hasSoftEvents) {
    if (filteredSoftEvents !== undefined) {
      if (filteredSoftEvents.length === 1) {
        event = filteredSoftEvents[0];
        unitIcon = iconByEventType(event.event_type);
      } else {
        const eventAmount = filteredSoftEvents?.length;
        unitIcon = validateBadgeAmount(eventAmount);
        hasBadge = true;
      }
    }
  } else if (unitStatus === UnitStatus.paused) {
    unitIcon = Icons.PausedUnitIcon;
  }
  return { unitIcon, hasBadge, isAssigned, isResolved, event };
};

export function OrganizationUnit(props: IProps) {
  const { events, communityID, onClickOpenTab } = props;
  const {
    id,
    notVisible,
    is_notifications_paused,
    is_watched,
    time_updated,
  } = props.unit;
  /* Hooks */
  const dispatch = useDispatch();

  /* ---- State ---- */
  const [anchorEl, setAnchorEl] = React.useState(null);

  /* ---- Selectors ---- */
  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );

  const softEvents = events?.filter(
    (eventEl) => id === eventEl.unit_id && isSoftEvent(eventEl)
  );

  const hardEvents = events?.filter(
    (eventEl) => id === eventEl.unit_id && isHardEvent(eventEl)
  );

  const installerRole = useSelector(
    (state: IAppState) => state.headerState.installerRole
  );

  /* ---- Effects ---- */
  useEffect(() => {
    if (communityID !== undefined && firebaseAuth) {
      const unitRef = getFirebaseDBReference().ref(
        `communities/${communityID}/units/${id}/settings`
      );
      const listener = unitRef.on("value", (snapshot) => {
        const updatedUnit = snapshot.val();
        if (updatedUnit === null) return;
        if (
          updatedUnit.time_updated === undefined ||
          updatedUnit.time_updated === null
        )
          return;
        if (updatedUnit.time_updated !== time_updated)
          dispatch(updateUnit(updatedUnit));
      });
      return () => unitRef.off("value", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityID, firebaseAuth]);

  /* ---- Methods ---- */
  const handleOpenDropdown = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleOpenTab = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let eventId = undefined;
    if (is_notifications_paused) {
      eventId = hardEvents?.find(
        (event) => event.event_type === EventTypes.pause_notification
      )?.id;
    }
    if (eventId) onClickOpenTab(eventId, TabTypes.PAUSED);
    else onClickOpenTab(id, TabTypes.UNIT);
  };

  const handleOpenEventTab = (
    e: React.MouseEvent<HTMLDivElement>,
    unitEvent: IEvent
  ) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(getEventDataDashboard(unitEvent.id));
    if (isHardEvent(unitEvent)) {
      if (unitEvent.event_type === EventTypes.pause_notification) {
        onClickOpenTab(unitEvent.id, TabTypes.PAUSED);
      } else {
        onClickOpenTab(unitEvent.id, TabTypes.CRITICAL);
      }
    } else {
      onClickOpenTab(unitEvent.id, TabTypes.WARNING);
    }
  };

  const handleWatchFlagClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    unitID: string
  ) => {
    e.stopPropagation();
    const watchFlagData = { unitID, isWatched: !isWatched };
    dispatch(toggleUnitWatchFlag(watchFlagData));
  };

  // Defines the behavior of the unit when clicked
  const handleUnitClick = (
    event: React.MouseEvent<HTMLDivElement>,
    iconData: ReturnType<typeof unitIcon>
  ) => {
    if (iconData.hasBadge) return handleOpenDropdown(event);
    if (iconData.unitIcon !== "" && iconData.event !== undefined)
      return handleOpenEventTab(event, iconData.event);

    return handleOpenTab(event);
  };

  const filteredHardEvents = filterPausedEvents(hardEvents);
  const filteredSoftEvents = filterPausedEvents(softEvents);

  // Unit Status
  const isCritical =
    filteredHardEvents !== undefined &&
    filteredHardEvents.length > 0 &&
    filteredHardEvents.some(
      (event) =>
        event.status === EventStatus.ASSIGNED ||
        event.status === EventStatus.UNASSIGNED
    );

  const isCriticalResolved =
    filteredHardEvents !== undefined &&
    filteredHardEvents.length > 0 &&
    filteredHardEvents.some((event) => event.status === EventStatus.RESOLVED);

  const isWarning =
    filteredSoftEvents !== undefined &&
    filteredSoftEvents.length > 0 &&
    filteredSoftEvents.some((event) => event.read_status === ReadStatus.UNREAD);

  const isWarningRead =
    filteredSoftEvents !== undefined &&
    filteredSoftEvents.length > 0 &&
    filteredSoftEvents.some((event) => event.read_status === ReadStatus.READ);

  const isWatched = is_watched;

  const actionRef = useRef() as
    | React.MutableRefObject<PopoverActions>
    | undefined;

  const unitStatus = isCritical
    ? UnitStatus.critical
    : isWarning
    ? UnitStatus.warning
    : isCriticalResolved
    ? UnitStatus.criticalResolved
    : isWarningRead
    ? UnitStatus.warningRead
    : undefined;

  if (unitStatus === undefined) return null;
  // Icon information
  const unitIconInformation = unitIcon(unitStatus, softEvents, hardEvents);

  const open = Boolean(anchorEl);
  const unitEventPopoverID = open ? "unit-event-popover" : undefined;

  // Obtains the information to display, if its a care at home unit it shows resident and city
  const unitTexts = getUnitTitle(props.unit);

  return (
    <React.Fragment>
      <UnitLayout
        title={unitTexts.unitTitle}
        subtitle={unitTexts.unitSubtitle}
        eventType={unitIconInformation.event?.event_type ?? ""}
        isWatched={isWatched}
        isAssigned={unitIconInformation.isAssigned}
        isResolved={unitIconInformation.isResolved}
        notVisible={!!notVisible}
        hasBadge={unitIconInformation.hasBadge}
        unitIcon={unitIconInformation.unitIcon}
        status={unitStatus}
        toggleFlag={(e) => handleWatchFlagClick(e, id)}
        onClick={(e) => handleUnitClick(e, unitIconInformation)}
        organization={true}
        installerRole={installerRole}
      />
      <Popover
        id={unitEventPopoverID}
        open={open}
        anchorEl={anchorEl}
        action={actionRef}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <UnitEventList
          unitId={id}
          addTab={onClickOpenTab}
          onClose={handleCloseDropdown}
          shouldShowCritical={isCritical || isCriticalResolved}
          shouldShowWarning={isWarning || isWarningRead}
          organization={true}
          events={events}
          updatePopover={() => actionRef?.current?.updatePosition()}
        />
      </Popover>
    </React.Fragment>
  );
}

interface IProps {
  unit: IUnit;
  communityID: string;
  events?: IEvent[];
  onClickOpenTab: (id: string, type: TabTypes) => void;
}
