import React, { useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { SettingListKeys, sortAlphabetical } from "../../helpers/constants";
import {
  addNotificationGroup,
  setSettingsGroups,
  setSettingsGroupsConfig,
} from "./settingsSlice";
import { ICommunityGroup } from "../../services/header.services";
import { AddNewItemButton } from "./common/AddNewItemButton";
import { NotificationGroupsMenu } from "./NotificationGroupsMenu";
import { NotificationGroupsConfigMenu } from "./NotificationGroupConfigMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      position: "relative",
      height: "100%",
      width: "50%",
    },
    notificationGroupsListContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      background: theme.palette.light.main,
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(3, 0),
      overflowY: "auto",
      paddingBottom: theme.spacing(8),
    },
    listContainer: {
      background: "#FFF",
      padding: theme.spacing(0, 1),
    },
    optionsContainer: {
      width: "100%",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
    },
  })
);

export function NotificationGroupsList() {
  /* Hooks */
  const classes = useStyles();
  const dispatch = useAppDispatch();

  /* Refs */
  const ref = useRef<HTMLDivElement>(null);

  /* Selectors */
  const selectedOption = useAppSelector(
    (state) => state.settingsState.selectedOption
  );

  const selectedGroup = useAppSelector(
    (state) => state.settingsState.selectedGroup
  );

  const selectedGroupConfig = useAppSelector(
    (state) => state.settingsState.selectedGroupConfig
  );

  const communityGroups = useAppSelector((state) => {
    if (state.headerState.communityGroups !== undefined) {
      return JSON.parse(
        JSON.stringify(state.headerState.communityGroups)
      ) as ICommunityGroup[];
    }
    return state.headerState.communityGroups;
  })?.sort((a, b) => sortAlphabetical(a.name, b.name));

  /* Methods */
  const handleSelectGroup = (groupID: string) => {
    dispatch(setSettingsGroups(groupID));
  };
  const handleSelectConfig = () => {
    dispatch(setSettingsGroupsConfig());
  };
  const handleAddUser = () => {
    dispatch(addNotificationGroup());
  };

  return (
    <React.Fragment>
      {selectedOption === SettingListKeys.notification_groups &&
        communityGroups !== undefined && (
          <div className={classes.mainContainer}>
            <div ref={ref} className={classes.notificationGroupsListContainer}>
              <NotificationGroupsConfigMenu
                selected={selectedGroupConfig}
                onClick={handleSelectConfig}
              />
              <NotificationGroupsMenu
                groups={communityGroups}
                selected={selectedGroup}
                onClickGroup={handleSelectGroup}
              />
            </div>
            <AddNewItemButton parentRef={ref} handleAddAction={handleAddUser} />
          </div>
        )}
    </React.Fragment>
  );
}
