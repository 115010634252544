import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    itemLabel: {
      fontSize: theme.typography.caption.fontSize,
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    expandedItem: {
      fontSize: theme.typography.body2.fontSize,
      display: "flex",
      alignItems: "center",
      color: (props) =>
        props.highlightPrimary !== undefined
          ? props.highlightPrimary
            ? theme.palette.primary.main
            : theme.palette.paused.light
          : theme.palette.text.primary,
    },
  })
);

export const EventConfigLabel = (props: IProps) => {
  const { expanded, label, value } = props;
  /* Hooks */
  const classes = useStyles(props);
  return (
    <div className={classes.itemLabel}>
      {label}
      {!expanded && <div className={classes.expandedItem}>{value}</div>}
    </div>
  );
};

interface IProps {
  expanded: boolean;
  highlightPrimary?: boolean;
  label: string;
  value: string;
}
