import React, { useEffect } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import { IEvent } from "../../services/dashboard.services";
import { EventStatus } from "../../helpers/constants";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import { isHardEvent } from "../dashboard/dashboardSlice";
import Box from "@material-ui/core/Box/Box";
import { Palette } from "@material-ui/core/styles/createPalette";
import { getFirebaseDBReference } from "../../helpers/firebase";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import { updateNotification } from "./notificationsSlice";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    notificationRow: {
      cursor: "pointer",
      borderTopStyle: "none",
      borderBottomStyle: "none",
      height: "100%",
      maxHeight: "50px",
      padding: theme.spacing(1),
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: `${theme.palette.primary.main}4D !important `,
        "& > .MuiTableCell-root": {
          fontFamily: theme.typography.secondaryFontFamily,
        },
      },
      "&.MuiTableRow-hover:hover": {
        backgroundColor: `${theme.palette.primary.main}09`,
      },
      "&.MuiTableRow-hover:hover td": {
        borderColor: `${theme.palette.primary.main}4D`,
        borderBottom: `1px solid ${theme.palette.primary.main}4D`,
        borderTop: `1px solid ${theme.palette.primary.main}4D`,
      },
    },
    indicatorCell: {
      padding: "0px",
      margin: "1px",
    },
    rowBackground: {
      backgroundColor: (props) =>
        getRowBackgroundColor(theme, props.notification),
      opacity: 1,
    },
    typeIndicator: {
      backgroundColor: (props) =>
        getTypeIndicatorBGColor(theme, props.notification),
      opacity: 1,
    },
  })
);

export const getTypeIndicatorBGColor = (
  theme: Theme,
  notification?: IEvent
) => {
  if (notification === undefined) return "";
  const isRead = notification.read_status === "read";
  const isResolved =
    notification && notification.status === EventStatus.RESOLVED;

  const isHard = isHardEvent(notification);

  const typeIndicator = clsx(
    isResolved && isHard && theme.palette.error.light,
    !isResolved && isHard && theme.palette.error.main,
    isRead && !isHard && theme.palette.warning.light,
    !isRead && !isHard && theme.palette.warning.main
  );
  return typeIndicator;
};

export const getTypeIndicatorTextColor = (
  palette: Palette,
  notification?: IEvent
) => {
  if (notification === undefined) return "";
  const isRead = notification.read_status === "read";
  const isResolved =
    notification && notification.status === EventStatus.RESOLVED;

  const isHard = isHardEvent(notification);

  const typeIndicator = clsx(
    isResolved && isHard && palette.dark.dark,
    !isResolved && isHard && palette.light.light,
    isRead && !isHard && palette.dark.dark,
    !isRead && !isHard && palette.light.light
  );
  return typeIndicator;
};

export const getRowBackgroundColor = (theme: Theme, notification: IEvent) => {
  const isRead = notification.read_status === "read";
  const isResolved =
    notification && notification.status === EventStatus.RESOLVED;

  const isHard = isHardEvent(notification);

  const backgroundClass = clsx(
    isResolved && isHard && theme.palette.light.main,
    isRead && !isHard && theme.palette.light.main,
    !isResolved && isHard && theme.palette.light.light,
    !isRead && !isHard && theme.palette.light.light
  );
  return backgroundClass;
};
export const getRowBorderColor = (theme: Theme, notification?: IEvent) => {
  if (notification === undefined) return "";
  const isRead = notification.read_status === "read";
  const isResolved =
    notification && notification.status === EventStatus.RESOLVED;

  const isHard = isHardEvent(notification);

  const backgroundClass = clsx(
    isResolved && isHard && theme.palette.error.main,
    isRead && !isHard && theme.palette.warning.main,
    !isResolved && isHard && theme.palette.error.main,
    !isRead && !isHard && theme.palette.warning.main
  );
  return backgroundClass;
};

export const NotificationRow = (props: IProps) => {
  const { notification, children, onClick, selected } = props;
  /* ---- Hooks ---- */
  const classes = useStyles(props);
  const dispatch = useDispatch();

  /* ---- Selectors ---- */
  const firebaseAuth = useSelector(
    (state: IAppState) => state.loginState.firebaseAuth
  );
  const selectedCommunityID = useSelector(
    (state: IAppState) => state.headerState.selectedCommunity?.id
  );

  const isHard = isHardEvent(notification);

  useEffect(() => {
    if (!firebaseAuth) return;
    if (selectedCommunityID === undefined) return;
    if (notification === undefined) return;
    const firebaseKey = `${notification.time_created.replace("T", " ")}-${
      notification.id
    }`;
    const eventRef = getFirebaseDBReference().ref(
      `communities/${selectedCommunityID}/events/${firebaseKey}`
    );

    // Updates event if status changes or if it was reassigned
    const listener = eventRef.on("value", (snapshot) => {
      const eventData = snapshot.val();
      if (eventData === null) return;
      const statusChanged = eventData.status !== notification.status;

      let responderChanged = false;
      if (
        eventData !== undefined &&
        typeof eventData.time_assigned === "string" &&
        typeof notification.time_assigned === "string"
      ) {
        responderChanged =
          eventData?.time_assigned !== notification?.time_assigned;
      }

      if (
        eventData !== undefined &&
        typeof eventData.time_assigned === "string" &&
        notification.time_assigned === null
      ) {
        responderChanged =
          eventData.time_assigned !== notification.time_assigned;
      }

      if (eventData !== null && (statusChanged || responderChanged)) {
        eventData.data = JSON.stringify(eventData.data);
        dispatch(updateNotification(eventData));
      }
    });
    return () => eventRef.off("value", listener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID]);

  return (
    <TableRow
      classes={{
        root: clsx(classes.notificationRow, classes.rowBackground),
      }}
      onClick={() => onClick(notification.id, isHard)}
      tabIndex={-1}
      hover
      selected={selected === notification.id}
    >
      <TableCell className={classes.indicatorCell}>
        <Box width="12px" height="50px" className={classes.typeIndicator}></Box>
      </TableCell>
      {children}
    </TableRow>
  );
};

interface IProps {
  notification: IEvent;
  selected: string;
  children?: React.ReactNode;
  onClick: (eventID: string, isHard: boolean) => void;
}
