import React, { useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  eventValueByEventType,
  helperTextByEventType,
  summaryByEventType,
  detailColorByEventType,
  shouldShowIconByEventType,
} from "../eventDetailHelpers";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import { DateTimeShortFormat } from "../../../helpers/datetime";
import CriticalNotificationsIcon from "../../../assets/img/icon.alert.critical.svg";
import WarningNotificationsIcon from "../../../assets/img/icon.alert.warning.svg";
import { IEvent } from "../../../services/dashboard.services";
import { isHardEvent, updateEventDetails } from "../dashboardSlice";
import { getFirebaseDBReference } from "../../../helpers/firebase";
import { useDispatch } from "react-redux";
import { IAppState } from "../../../helpers/store";
import ResidentContacts from "./ResidentContacts";
import { Views } from "../../../helpers/constants";
import { useLocation } from "react-router-dom";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";
import { useAppSelector } from "../../app/appHooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailsTitle: {
      opacity: "0.54",
    },
    eventValue: {
      fontFamily: theme.typography.tertiaryFontFamily,
    },
    eventDate: {
      color: theme.palette.dark.dark,
      opacity: "0.8",
    },
    criticalIcon: {
      display: "flex",
      margin: theme.spacing(0, 1),
      height: "23px",
    },
  })
);
export default function EventDetail(props: IProps) {
  const { event } = props;

  const selectedCommunityID = useAppSelector(
    (state: IAppState) => state.headerState.selectedCommunity?.id
  );

  const communityID = event?.community_id ?? selectedCommunityID ?? undefined;

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const communityUnitTimezone = useCommunityUnitTimezone(
    communityID,
    event?.unit_id
  );

  /* ---- Selectors ---- */

  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);

  const eventUnit = useAppSelector((state) => {
    const selectedCommunityID = state.headerState.selectedCommunity;
    const selectedOrganizationID = state.headerState.selectedOrganization;

    if (selectedCommunityID !== undefined) {
      const source =
        location.pathname === Views.DASHBOARD
          ? "dashboardState"
          : "headerState";
      return state[source].units?.find((unit) => unit.id === event?.unit_id);
    }
    if (
      selectedOrganizationID !== undefined &&
      event !== undefined &&
      event.community_id
    ) {
      return state.dashboardState.orgUnits[event.community_id]?.find(
        (unit) => unit.id === event.unit_id
      );
    }
    return undefined;
  });

  /* ---- Effects ---- */
  useEffect(() => {
    if (!firebaseAuth) return;
    if (event === undefined) return;
    if (communityID === undefined) return;
    const firebaseKey = `${event.time_created.replace("T", " ")}-${event.id}`;
    const eventRef = getFirebaseDBReference().ref(
      `communities/${communityID}/events/${firebaseKey}`
    );
    const listener = eventRef.on("value", (snapshot) => {
      const eventData = snapshot.val();
      if (eventData !== null) dispatch(updateEventDetails(eventData));
    });
    return () => eventRef.off("value", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get formated creation time
  let formatedTime = "";
  if (event && event.time_created) {
    formatedTime = DateTimeShortFormat(
      event.time_created,
      communityUnitTimezone
    );
  }

  const eventSummary = summaryByEventType(
    event?.event_type || "",
    event?.data || ""
  );

  const detailColor = detailColorByEventType(event?.event_type || "", theme);
  const shouldShowIcon = shouldShowIconByEventType(event?.event_type || "");

  const eventHelperText = helperTextByEventType(
    event?.event_type || "",
    event?.time_created || "",
    event?.data || "",
    event?.zone_id || "",
    communityUnitTimezone
  );

  const eventValue = eventValueByEventType(
    event?.event_type || "",
    event?.time_created || "",
    event?.data || "",
    communityUnitTimezone
  );

  const eventIcon =
    event && isHardEvent(event)
      ? CriticalNotificationsIcon
      : WarningNotificationsIcon;

  // Get resident in the unit, if is not defined, then the card doesn't show
  const resident =
    eventUnit !== undefined &&
    eventUnit.residents &&
    eventUnit.residents.length > 0
      ? eventUnit.residents[0]
      : undefined;
  return (
    <Box marginBottom={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.detailsTitle}>
          {t("issue").toUpperCase()}
        </Typography>
        {resident && eventUnit && eventUnit.community_id && (
          <ResidentContacts
            residentID={resident.id}
            communityID={eventUnit.community_id}
            unitID={eventUnit.id}
          />
        )}
      </Box>
      <Box display="flex" marginTop={0.5} flexWrap="wrap">
        <Box display="flex" flex="2 0 auto" flexDirection="column">
          <Box className={classes.eventDate} paddingY={0.5}>
            {formatedTime}
          </Box>

          <Box color={detailColor} fontSize="h5.fontSize" paddingY={0.5}>
            {eventSummary}
          </Box>
        </Box>
        {eventValue.value !== "" && (
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box display="flex" alignItems="center">
              {shouldShowIcon && (
                <img
                  src={eventIcon}
                  alt={
                    event && isHardEvent(event)
                      ? t("alt_text_critical_notification_icon")
                      : t("alt_text_warning_notification_icon")
                  }
                  draggable={false}
                  className={classes.criticalIcon}
                />
              )}
              <Box
                color={detailColor}
                fontSize="h4.fontSize"
                fontWeight="fontWeightBold"
                className={classes.eventValue}
              >
                {eventValue.value}
              </Box>
            </Box>
            {eventValue.complementText !== "" && (
              <Box
                color={detailColor}
                fontSize="body1.fontSize"
                className={classes.eventValue}
              >
                {eventValue.complementText}
              </Box>
            )}
          </Box>
        )}
        <Box flex="1 0 100%" paddingY={0.5}>
          {eventHelperText}
        </Box>
      </Box>
    </Box>
  );
}

interface IProps {
  event?: IEvent;
}
