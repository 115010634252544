import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/appHooks";
import { useHistory } from "react-router-dom";
import {
  ACTIVE_PANEL_WIDTH,
  ReducerStates,
  TabTypes,
  Views,
} from "../../helpers/constants";
import { CSSTransition } from "react-transition-group";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { IconButton } from "@material-ui/core";
import "./index.css";
import Notifications from "../dashboard/activeNotificationPanel/Notifications";
import clsx from "clsx";
import {
  addTab,
  consumeAddedEvent,
  getCommunityData,
} from "../dashboard/dashboardSlice";
import { useTranslation } from "react-i18next";
import { minToolbarHeight } from "../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    floatingPanelOpen: {
      position: "fixed",
      bottom: 0,
      right: 0,
      opacity: 0,
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      width: `${ACTIVE_PANEL_WIDTH}px`,
      backgroundColor: theme.palette.light.light,
      display: "flex",
      boxShadow: "-3px 0px 2px -1px rgb(0 0 0 / 12%)",
      zIndex: -1,
    },
    openFloatingPanelButton: {
      position: "relative",
      zIndex: 2,
      height: "100%",
      width: "10px",
      backgroundColor: theme.palette.light.light,
      display: "flex",
      alignItems: "center",
      transition: "all 0.3s ease-in-out",
      boxShadow: "-3px 0px 2px -1px rgb(0 0 0 / 12%)",
      "&::after": {
        position: "absolute",
        width: "100%",
        height: "100%",
        content: "''",
        boxShadow: "-4px 0px 2px -1px rgb(0 0 0 / 15%)",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
      },
      "&:hover::after": {
        opacity: 1,
      },
    },
    closeFloatingPanelButton: {
      position: "relative",
      height: "100%",
      width: "10px",
      backgroundColor: theme.palette.light.light,
      display: "flex",
      alignItems: "center",
      transition: "all 0.3s ease-in-out",
    },
    iconButtonRoot: {
      display: "flex",
      padding: theme.spacing(0, 0.5),
      borderRadius: "unset",
      fontSize: theme.typography.caption.fontSize,
      "&:hover": {
        backgroundColor: theme.palette.light.light,
      },
    },
    content: {
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
    },
    hide: {
      opacity: 0,
      zIndex: -1,
    },
  })
);

export const FloatingNotificationPanel = () => {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);
  /* Hooks */
  const classes = useStyles(showBanner);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  /* State */
  const [showPanel, setShowPanel] = useState(false);

  /* ---- Selectors ---- */
  const dashboardStatus = useAppSelector((state) => state.dashboardState.state);

  const selectedCommunity = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );

  const headerStatus = useAppSelector((state) => state.headerState.state);

  const addedEvent = useAppSelector((state) => state.dashboardState.addedEvent);

  /* Effects */
  useEffect(() => {
    if (
      dashboardStatus === ReducerStates.IDLE &&
      headerStatus === ReducerStates.SUCCEEDED
    ) {
      if (selectedCommunity !== undefined) {
        dispatch(getCommunityData(selectedCommunity.id));
      }
    }
  }, [dashboardStatus, headerStatus, selectedCommunity, dispatch]);

  useEffect(() => {
    if (addedEvent) {
      setShowPanel(addedEvent);
      dispatch(consumeAddedEvent());
    }
  }, [addedEvent, dispatch]);

  /* Methods */
  const openPanel = () => {
    setShowPanel(true);
  };
  const closePanel = () => {
    setShowPanel(false);
  };
  const handleRedirectAndAddTab = (id: string, type: TabTypes) => {
    history.push(Views.DASHBOARD);
    dispatch(addTab({ id, type }));
  };

  return (
    <React.Fragment>
      <IconButton
        id="floating-panel-closed"
        className={clsx(
          classes.openFloatingPanelButton,
          showPanel && classes.hide
        )}
        onClick={openPanel}
        aria-label={t("alt_text_open_floating_panel")}
        classes={{
          root: classes.iconButtonRoot,
        }}
      >
        <KeyboardArrowLeftIcon fontSize="inherit" />
      </IconButton>

      <CSSTransition
        key={"active-notification-panel-open"}
        in={showPanel}
        timeout={300}
        classNames="panel"
      >
        <div
          id="floating-panel-open"
          className={clsx(classes.floatingPanelOpen)}
        >
          <IconButton
            id="floating-panel-closed"
            className={classes.closeFloatingPanelButton}
            onClick={closePanel}
            aria-label={t("alt_text_close_floating_panel")}
            classes={{
              root: classes.iconButtonRoot,
            }}
          >
            <KeyboardArrowRightIcon fontSize="inherit" />
          </IconButton>
          <div className={classes.content}>
            <Notifications addTab={handleRedirectAndAddTab} />
          </div>
        </div>
      </CSSTransition>
    </React.Fragment>
  );
};
