import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button, Input, Paper, TextField } from "@material-ui/core";
import i18n from "../../i18n";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countries from "i18n-iso-countries";
import timezones from "timezones-list";
import { SupportedLanguages, sortAlphabetical } from "../../helpers/constants";
import { useAppSelector } from "../app/appHooks";
import { ICommunities } from "../../services/header.services";
import { useDispatch } from "react-redux";
import { submitCommunityInfoChanges } from "../app/asyncThunkActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    communityInfoContainer: {
      padding: theme.spacing(6, 7),
      width: "550px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    sectionTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    wrapper: {
      listStyle: "none",
      padding: 0,
      borderRadius: "3px",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1em .5em 0",
        flex: " 1",
        textAlign: "right",
        color: theme.palette.paused.main,
      },
    },
    rowInput: {
      flex: "1.5",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    flagIcon: {
      marginRight: theme.spacing(0.5),
    },
    option: {
      whiteSpace: "nowrap",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    subheaderRoot: {
      lineHeight: "16px",
    },
  })
);

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export interface OptionsType {
  id: string;
  label: string;
  groupBy?: string;
}

const InputRow = (props: {
  inputKey: string;
  value: string | OptionsType | null;
  required?: boolean;
  select?: boolean;
  options?: OptionsType[];
  onChange?: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;

  onChangeSelect?: (key: string, value: OptionsType | null) => void;
}) => {
  const {
    inputKey,
    value,
    select,
    required,
    options,
    onChange,
    onChangeSelect,
  } = props;
  /* Hooks */
  const classes = useStyles();

  const { t } = useTranslation();
  const hasGroupBy = options?.every((option) => option.groupBy);
  return (
    <li className={classes.formRow}>
      <label htmlFor={inputKey}>
        {t(inputKey).toUpperCase()}
        {required ? "*" : ""}
      </label>
      {select ? (
        <Autocomplete
          options={options ?? []}
          id={`dropdown-${inputKey}`}
          disableClearable
          noOptionsText={t("no_options")}
          groupBy={hasGroupBy ? (option) => option.groupBy ?? "" : undefined}
          getOptionLabel={(option: OptionsType) =>
            options?.find((_option) => _option.id === option.id)?.label ?? ""
          }
          value={value as OptionsType | undefined}
          onChange={(_, val) => onChangeSelect && onChangeSelect(inputKey, val)}
          getOptionSelected={(option, value) => option.id === value.id}
          blurOnSelect
          autoHighlight
          renderOption={(option) => (
            <React.Fragment>
              {inputKey === "country" && (
                <span className={classes.flagIcon}>
                  {countryToFlag(option.id)}
                </span>
              )}
              {option.label}
            </React.Fragment>
          )}
          classes={{
            root: classes.rowInput,
            groupLabel:
              inputKey === "country" ? classes.subheaderRoot : undefined,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={value === null ? t("select_an_option") : ""}
            />
          )}
        />
      ) : (
        <Input
          classes={{ root: classes.rowInput }}
          id={inputKey}
          value={value !== null ? (value as string) : ""}
          error={required ? value === null || value === "" : false}
          onChange={onChange && onChange(inputKey)}
        />
      )}
    </li>
  );
};

export const timezoneFormated: OptionsType[] = timezones.map((timezone) => ({
  id: timezone.tzCode,
  label: `${timezone.tzCode}`,
  groupBy: `GMT${timezone.utc}`,
}));

interface InfoState {
  id: string | null;
  name: string | null;
  postal_code: string | null;
  country: OptionsType | null;
  language: OptionsType | null;
  time_zone: OptionsType | null;
}

export function CommunityInfo() {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* State */
  const [infoValues, setInfoValues] = useState<InfoState>({
    id: null,
    name: null,
    postal_code: null,
    country: null,
    time_zone: null,
    language: null,
  });

  /* Selectors */
  const community = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );

  /* Methods */
  const handleChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInfoValues((prevState) => ({
      ...prevState,
      [key]: event.target.value.trimStart(),
    }));
  };

  const handleChangeDropdown = (key: string, value: OptionsType | null) => {
    setInfoValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const supportedLanguages = SupportedLanguages.map((lang) => ({
    id: lang.id,
    label: t(lang.label),
  })).sort((a, b) => sortAlphabetical(a.label, b.label));

  const loadLanguage =
    countries.langs().find((item) => item === i18n.language) ?? "en";

  const countryList = countries.getNames(loadLanguage, { select: "official" });
  const countryListFormated: OptionsType[] = Object.keys(countryList).map(
    (_key) => ({
      id: _key,
      label: countryList[_key],
      groupBy: "\u00a0",
    })
  );

  const extra = countryListFormated
    .filter(
      (country) =>
        country.id === "US" || country.id === "GB" || country.id === "CA"
    )
    .map((_option) => ({ ..._option, groupBy: "\u2000" }))
    .sort((a, b) => sortAlphabetical(a.label, b.label));

  countryListFormated.unshift(...extra);

  /* Hooks */
  useEffect(() => {
    if (community !== undefined) {
      let countryOption: OptionsType | null = null;
      if (community.country) {
        countryOption =
          countryListFormated.find(
            (countryOption) => countryOption.id === community.country
          ) ?? null;
      }

      let languageOption: OptionsType | null = null;
      if (community.language) {
        languageOption =
          supportedLanguages.find(
            (languageOption) => languageOption.id === community.language
          ) ?? null;
      }

      let timezoneOption: OptionsType | null = null;
      if (community?.time_zone !== null) {
        timezoneOption =
          timezoneFormated.find(
            (time_zone) => time_zone.id === community?.time_zone
          ) ?? null;
      }

      let communityName = null;
      let postalCode = null;
      if (community !== undefined) {
        communityName = community?.name;
        postalCode = community?.postal_code;
      }

      setInfoValues({
        ...infoValues,
        name: communityName,
        postal_code: postalCode,
        language: languageOption,
        country: countryOption,
        time_zone: timezoneOption,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  const checkFormChange = (newVals: InfoState, oldVals?: ICommunities) => {
    if (oldVals === undefined) return false;

    let formChanged = false;
    let isEmpty = false;
    if (newVals.name !== null && oldVals.name !== newVals.name) {
      formChanged = true;
    }

    if (
      newVals.postal_code !== null &&
      oldVals.postal_code !== newVals.postal_code
    ) {
      formChanged = true;
    }

    if (oldVals.country !== newVals.country?.id) {
      formChanged = true;
    }

    if (oldVals.language !== newVals.language?.id) {
      formChanged = true;
    }

    if (oldVals.time_zone !== newVals.time_zone?.id) {
      formChanged = true;
    }

    if (
      !(oldVals.name !== "" && newVals.name === "") &&
      !(oldVals.postal_code !== "" && newVals.postal_code === "") &&
      !(oldVals.country !== null && newVals.country === null) &&
      !(oldVals.language !== null && newVals.language === null) &&
      !(oldVals.time_zone !== null && newVals.time_zone === null)
    ) {
      isEmpty = true;
    }

    return formChanged && isEmpty;
  };

  const checkNullValues = (newVals: InfoState, oldVals?: ICommunities) => {
    if (oldVals === undefined) return false;

    let nullValues = false;

    if (
      oldVals.name !== null &&
      (newVals.name === null ||
        newVals.name === "" ||
        newVals.name.trim() === "")
    ) {
      nullValues = true;
    }

    if (
      oldVals.postal_code !== null &&
      (newVals.postal_code === null ||
        newVals.postal_code === "" ||
        newVals.postal_code.trim() === "")
    ) {
      nullValues = true;
    }

    return nullValues;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const myInfoData = {
      id: community?.id,
      name: infoValues.name ?? "",
      postal_code: infoValues.postal_code ?? "",
      country: infoValues.country?.id,
      language: infoValues.language?.id,
      time_zone: infoValues.time_zone?.id,
    };
    if (myInfoData["country"] === null) {
      delete myInfoData["country"];
    }
    if (myInfoData["language"] === null) {
      delete myInfoData["language"];
    }
    if (myInfoData["time_zone"] === null) {
      delete myInfoData["time_zone"];
    }

    dispatch(submitCommunityInfoChanges(myInfoData));
  };

  const enableSubmit =
    checkFormChange(infoValues, community) &&
    !checkNullValues(infoValues, community);
  return (
    <Paper className={classes.communityInfoContainer} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("community_info")}</h2>
      <form
        id="login-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.wrapper}>
          <InputRow
            inputKey="name"
            value={infoValues.name}
            required
            onChange={handleChange}
          />
          <InputRow
            inputKey="postal_code"
            value={infoValues.postal_code}
            required
            onChange={handleChange}
          />
          <InputRow
            inputKey="country"
            value={infoValues.country}
            select
            options={countryListFormated}
            onChangeSelect={handleChangeDropdown}
          />
          <InputRow
            inputKey="time_zone"
            value={infoValues.time_zone}
            select
            options={timezoneFormated}
            onChangeSelect={handleChangeDropdown}
          />
          <InputRow
            inputKey="language"
            value={infoValues.language}
            select
            options={supportedLanguages}
            onChangeSelect={handleChangeDropdown}
          />
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("save")}
          </Button>
        </div>
      </form>
    </Paper>
  );
}
