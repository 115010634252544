import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { PhoneNumberField } from "../../common/PhoneNumberField";
import { IPhoneNumber } from "../../../services/header.services";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../../helpers/constants";
import { getUserName } from "../UsersSettingsList";
import { labelByContactType } from "../../dashboard/eventDetails/ResidentContacts";
import React from "react";
import { useAppSelector } from "../../app/appHooks";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "40px",
      border: `1px solid #D6D6D6`,
      borderRadius: "5px",
      userSelect: "none",
      backgroundColor: theme.palette.light.light,
      padding: theme.spacing(2, 1.5),
    },
    cursor: {
      cursor: (props) => (props.dragOverlay ? "grabbing" : "grab"),
    },
    sectionOne: {
      display: "inline-flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    bold: {
      fontWeight: "bold",
    },
  })
);

export function WrappedResidentContactItem(props: IProps) {
  const { id } = props;

  const contact = useAppSelector((state) => {
    let contact = undefined;
    contact = state.settingsState.residentEmergencyContacts?.find(
      (item) => item.id === id
    );
    if (contact !== undefined) {
      return contact;
    }
    contact = state.settingsState.emergencyContactsState?.find(
      (item) => item.id === id
    );
    if (contact !== undefined) {
      return contact;
    }
    return contact;
  });

  /* Hooks */
  const classes = useStyles(props);
  const { t } = useTranslation();

  if (contact === undefined) return null;
  const contactType = contact.contact_type
    ? labelByContactType(contact.contact_type)
    : "";

  const userName = getUserName(contact);
  const userType =
    contact.contact_type === undefined || contact.contact_type === "user"
      ? t("user")
      : t("contact");
  const phoneNumbers = contact.phone_numbers;
  let phoneNumber: IPhoneNumber | undefined = undefined;
  if (phoneNumbers && phoneNumbers.length > 0) {
    phoneNumber = phoneNumbers[0];
  }

  return (
    <div className={clsx(classes.item, classes.cursor)}>
      <Grid container>
        <Grid item xs={6}>
          <div className={clsx(classes.sectionOne, classes.bold)}>
            {capitalize(userName)}
            <Typography variant="caption">{userType}</Typography>
          </div>
        </Grid>
        <Grid item xs={1}>
          {contactType ? t(contactType) : ""}
        </Grid>
        <Grid item xs={5}>
          {phoneNumber !== undefined && (
            <PhoneNumberField
              phoneNumber={phoneNumber}
              notClickable
              noPadding
              rightAlign
              noTruncate
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export const ResidentContactItem = React.memo(WrappedResidentContactItem);

interface IProps {
  id: string;
  dragOverlay?: boolean;
}
