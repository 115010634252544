// CommunityList.tsx
import React from "react";
import { List, ListItem, Typography } from "@material-ui/core";
import { GreenCheckbox } from "../notifications/filters/GreenCheckBox";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IUserDetails } from "../../services/header.services";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      transform: "scale(0.85)", // Adjust the scale value as needed
      marginRight: theme.spacing(1), // Space between checkbox and text
    },
    userItem: {
      padding: theme.spacing(0.1, 1),
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    userName: {
      fontSize: "0.95rem",
    },
    userList: {},
  })
);

export const CommunityUserFilterList = (props: IProps) => {
  const { users, selectedUsers, onSelectUser } = props;
  const classes = useStyles();

  return (
    <List className={classes.userList}>
      {users &&
        users.map((user) => (
          <ListItem key={user.id} className={classes.userItem}>
            <div className={classes.checkboxContainer}>
              <GreenCheckbox
                edge="start"
                checked={selectedUsers.includes(user.id)}
                tabIndex={-1}
                disableRipple
                onChange={() => onSelectUser(user.id)}
              />
            </div>
            <Typography className={classes.userName}>
              {`${user.first_name || ""} ${user.last_name || ""}`.trim()}
            </Typography>
          </ListItem>
        ))}
    </List>
  );
};

interface IProps {
  users: IUserDetails[] | undefined;
  selectedUsers: string[];
  onSelectUser: (id: string) => void;
}
