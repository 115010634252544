import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { NotificationIcons } from "../../../helpers/iconImports";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxIcon: {
      height: "22px",
      width: "23px",
    },
  })
);

const CustomCheckbox = withStyles(({ palette }) => ({
  root: {
    padding: 4,
    minWidth: "25px",
    color: palette.primary.main,
    backgroundColor: `unset`,
    "&$checked": {
      color: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `unset`,
    },
    "&$checked:hover": {
      backgroundColor: `unset`,
    },
  },
  checked: {},
}))(Checkbox);

const CheckBoxIcon = (props: {
  icon: "CheckNone" | "CheckMixed" | "CheckAll" | "Selected" | "Unselected";
}) => {
  const classes = useStyles();
  return (
    <img
      src={NotificationIcons[props.icon]}
      alt=""
      draggable={false}
      className={clsx(classes.checkboxIcon)}
    />
  );
};

export const CheckBoxIndicator = (props: IProps) => {
  return (
    <CustomCheckbox
      {...props}
      icon={<CheckBoxIcon icon="CheckNone" />}
      checkedIcon={<CheckBoxIcon icon="CheckAll" />}
      indeterminateIcon={<CheckBoxIcon icon="CheckMixed" />}
    />
  );
};

export const GreenCheckbox = (props: IProps) => {
  return (
    <CustomCheckbox
      {...props}
      icon={<CheckBoxIcon icon="Unselected" />}
      checkedIcon={<CheckBoxIcon icon="Selected" />}
    />
  );
};

interface IProps extends CheckboxProps {
  mixed?: boolean;
}
