import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBar: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
  })
);

// Display a modal to make the user acknowledge something
const YesNoDialog = (props: iProps) => {
  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    toggle,
    title,
    message,
    possitiveText,
    negativeText,
    positiveAction,
    negativeAction,
    onClose,
  } = props;

  const components = {
    title: title !== undefined,
    content: message !== undefined,
    actions: true,
    positive: true,
    backButton: true,
  };

  return (
    <div>
      <Dialog
        open={toggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        {components?.title && (
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        )}
        {components?.content && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message ? t(message) : ""}
            </DialogContentText>
          </DialogContent>
        )}
        {components?.actions && (
          <DialogActions className={classes.buttonBar}>
            <Button
              onClick={() => {
                negativeAction();
                onClose();
              }}
              color="default"
              variant="contained"
            >
              {negativeText ? t(negativeText) : ""}
            </Button>
            <Button
              onClick={() => {
                positiveAction();
                onClose();
              }}
              color="primary"
              variant="contained"
            >
              {possitiveText ? t(possitiveText) : ""}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
export default YesNoDialog;

export interface iProps {
  toggle: boolean;
  title: string | undefined;
  message: string | undefined;
  possitiveText: string | undefined;
  negativeText: string | undefined;
  positiveAction: () => void;
  negativeAction: () => void;
  onClose: () => void;
}
