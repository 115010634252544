import {
  Button,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Popover,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormRow } from "./common/FormRow";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  DefaultRegionCode,
  NAME_CHARACTER_LIMIT,
  PhoneTypes,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { PhoneNumberPicker, UnitPhoneNumber } from "./common/PhoneNumberPicker";
import { GenericPicker } from "./common/GenericPicker";
import { addResident, ICreateResident } from "./settingsThunks";
import {
  getCountryCodeForRegionCode,
  parsePhoneNumber,
} from "awesome-phonenumber";
import { getStatesList } from "./common/helpers";
import { ErrorCodes } from "../../services/constants";
import { handledError } from "../app/appSlice";
import { DateOfBirthDatePicker } from "./DateOfBirthDatePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editResidentSettings: {
      padding: theme.spacing(6, 7),
      width: "550px",
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    fieldList: {
      listStyle: "none",
      padding: 0,
      width: "80%",
    },
    inputWidth: {
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    phoneNumbersContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "110%",
    },
    phoneNumbersTitle: {
      marginTop: theme.spacing(7),
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: ".5em",
    },
    helperText: {
      minHeight: "20px",
      lineHeight: "1.2",
      color: theme.palette.error.main,
    },
  })
);

const DropdownInput = (props: {
  id: string;
  value: string | undefined;
  disabled?: boolean;
  handleClick: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
}) => {
  const { id, value, disabled, handleClick } = props;
  const classes = useStyles();
  return (
    <Input
      id={id}
      type="text"
      value={value ?? ""}
      disabled={disabled}
      onClick={!disabled ? (e) => handleClick(e, id) : undefined}
      className={classes.inputWidth}
      endAdornment={
        !disabled ? (
          <InputAdornment id="role" position="end">
            <IconButton aria-label={`toggle ${id} picker`}>
              <ArrowDropDownIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
    />
  );
};

enum FormFieldIDs {
  firstName = "first-name",
  lastName = "last-name",
  addressOne = "address-one",
  addressTwo = "address-two",
  city = "city",
  state = "state",
  zipCode = "zip-code",
  dateOfBirth = "date-of-birth",
  phoneNumberOne = "phone-number-one",
  phoneNumberTwo = "phone-number-two",
}

type FormState = {
  [FormFieldIDs.firstName]?: string | null;
  [FormFieldIDs.lastName]?: string | null;
  [FormFieldIDs.addressOne]?: string | null;
  [FormFieldIDs.addressTwo]?: string | null;
  [FormFieldIDs.city]?: string | null;
  [FormFieldIDs.state]?: string | null;
  [FormFieldIDs.zipCode]?: string | null;
  [FormFieldIDs.dateOfBirth]?: string | null;
  [FormFieldIDs.phoneNumberOne]: UnitPhoneNumber;
  [FormFieldIDs.phoneNumberTwo]: UnitPhoneNumber;
};

// Adds required types to the phone number by the server
const adjustPhoneNumber = <T extends UnitPhoneNumber>(phone: T): T => {
  const adjustedPhone = { ...phone };
  if (
    adjustedPhone.phone_number === null ||
    adjustedPhone.phone_number === undefined
  )
    return phone;

  const phoneNumber = parsePhoneNumber(
    adjustedPhone.phone_number,
    adjustedPhone.country_code ?? DefaultRegionCode
  );
  adjustedPhone.user_type = "resident";
  adjustedPhone.phone_number = phoneNumber.getNumber("significant");
  if (adjustedPhone.country_code) {
    adjustedPhone.country_code = String(
      getCountryCodeForRegionCode(adjustedPhone.country_code)
    );
  }
  return adjustedPhone;
};

export const AddResidentSettings = (props: IProps) => {
  const { homeCareCommunity } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const communityCountry = useAppSelector(
    (state) => state.headerState.selectedCommunity?.country
  );
  const selectedUnitID = useAppSelector(
    (state) => state.settingsState.selectedUnit
  );

  const defaultRegionCode = useAppSelector(
    (state) => state.headerState.user?.country ?? DefaultRegionCode
  );

  const errorCode = useAppSelector((state) => state.settingsState.errorCode);

  /* ---- Effects ---- */
  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.NAME_CONFLICT:
          dispatch(handledError());
          setFormError((prevVal) => ({
            ...prevVal,
            name_conflict: t("resident_city_name_conflict"),
          }));
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, t, dispatch]);

  /* State */
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [popoverID, setPopoverID] = useState("");
  const [formError, setFormError] = useState({
    [FormFieldIDs.firstName]: "",
    [FormFieldIDs.lastName]: "",
    [FormFieldIDs.city]: "",
    name_conflict: "",
  });

  const [formValues, setFormValues] = useState<FormState>({
    [FormFieldIDs.firstName]: null,
    [FormFieldIDs.lastName]: null,
    [FormFieldIDs.addressOne]: null,
    [FormFieldIDs.addressTwo]: null,
    [FormFieldIDs.city]: null,
    [FormFieldIDs.state]: null,
    [FormFieldIDs.zipCode]: null,
    [FormFieldIDs.phoneNumberOne]: {
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.HOME,
      phone_number: null,
    },
    [FormFieldIDs.phoneNumberTwo]: {
      country_code: defaultRegionCode,
      phone_type: PhoneTypes.MOBILE,
      phone_number: null,
    },
  });

  /* Effects */

  /* Methods */

  // Open dropdown list for some of the input elements
  const handleOpenFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverID(id);
  };

  const handleChange = (value: string, formField: FormFieldIDs) => {
    setFormValues((prevVal) => ({
      ...prevVal,
      [formField]: value.trimStart(),
    }));
    setFormError((prevVal) => ({
      ...prevVal,
      name_conflict: "",
    }));
    setAnchorEl(null);
  };

  const handleChangePhone = (
    value: UnitPhoneNumber,
    formField: FormFieldIDs
  ) => {
    setFormValues((prevVal) => ({ ...prevVal, [formField]: value }));
  };

  const handleChangeDateOfBirth = (date: string) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [FormFieldIDs.dateOfBirth]: date,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (communityID === undefined) return;

    const residentData: { [key: string]: any } = {
      first_name: formValues[FormFieldIDs.firstName] ?? "",
      last_name: formValues[FormFieldIDs.lastName] ?? "",
      city: formValues[FormFieldIDs.city],
      postal_code: formValues[FormFieldIDs.zipCode],
      state: formValues[FormFieldIDs.state],
      date_of_birth: formValues[FormFieldIDs.dateOfBirth],
      street: formValues[FormFieldIDs.addressOne],
      street2: formValues[FormFieldIDs.addressTwo],
    };
    Object.keys(residentData).forEach((key) => {
      if (residentData[key] === null) {
        delete residentData[key];
      }
    });

    const newResident: ICreateResident["resident"] = {
      ...residentData,
      first_name: residentData.first_name ?? "",
      last_name: residentData.last_name ?? "",
      unit_id: selectedUnitID,
    };

    const phone1 = formValues[FormFieldIDs.phoneNumberOne];
    const phone2 = formValues[FormFieldIDs.phoneNumberTwo];

    let phoneNumbers: ICreateResident["phoneNumbers"] = [];
    if (phone1.phone_number) {
      phoneNumbers.push(adjustPhoneNumber(phone1));
    }
    if (phone2.phone_number) {
      phoneNumbers.push(adjustPhoneNumber(phone2));
    }

    if (phoneNumbers.length > 0) {
      phoneNumbers = phoneNumbers.map((val, index) => ({
        ...val,
        priority: index + 1,
      }));
    }
    dispatch(
      addResident({
        community_id: communityID,
        resident: newResident,
        phoneNumbers,
      })
    );
  };

  /* List values */
  const stateList = useMemo(() => getStatesList(communityCountry), [
    communityCountry,
  ]);

  const getStateLabel = (id: string | null) => {
    if (id === null) return "-";
    return stateList.find((state) => state.id === id)?.label ?? "-";
  };

  // Validate if required field has value
  const validateNotEmpty = (id: FormFieldIDs) => {
    if (formValues[id] === null || formValues[id] === "") {
      setFormError((prevVal) => ({ ...prevVal, [id]: "requiredVal" }));
    } else {
      setFormError((prevVal) => ({ ...prevVal, [id]: "" }));
    }
  };

  // Enable save button
  const checkFormValidity = (values: FormState, homeCareCommunity: boolean) => {
    let enabled = true;

    if (
      values[FormFieldIDs.firstName] === null ||
      values[FormFieldIDs.firstName] === ""
    ) {
      enabled = false;
    }
    if (
      values[FormFieldIDs.lastName] === null ||
      values[FormFieldIDs.lastName] === ""
    ) {
      enabled = false;
    }
    if (
      homeCareCommunity &&
      (values[FormFieldIDs.city] === null || values[FormFieldIDs.city] === "")
    ) {
      enabled = false;
    }

    return enabled;
  };

  // Enable save button

  const enableSubmit = checkFormValidity(formValues, homeCareCommunity);

  return (
    <Paper className={classes.editResidentSettings} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("resident_settings")}</h2>
      <form
        id="resident-settings-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.fieldList}>
          <FormRow inputKey="first_name" required>
            <Input
              id={FormFieldIDs.firstName}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.firstName] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.firstName);
              }}
              error={
                (formError[FormFieldIDs.firstName] !== "" &&
                  formError[FormFieldIDs.firstName] !== null) ||
                formError["name_conflict"] !== ""
              }
              onBlur={() => validateNotEmpty(FormFieldIDs.firstName)}
              inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
            />
            {formError["name_conflict"] && (
              <FormHelperText className={classes.helperText}>
                {formError["name_conflict"]}
              </FormHelperText>
            )}
          </FormRow>
          <FormRow inputKey="last_name" required>
            <Input
              id={FormFieldIDs.lastName}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.lastName] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.lastName);
              }}
              error={
                (formError[FormFieldIDs.lastName] !== "" &&
                  formError[FormFieldIDs.lastName] !== null) ||
                formError["name_conflict"] !== ""
              }
              onBlur={() => validateNotEmpty(FormFieldIDs.lastName)}
              inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
            />
          </FormRow>
          <FormRow inputKey="street">
            <Input
              id={FormFieldIDs.addressOne}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.addressOne] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.addressOne);
              }}
            />
          </FormRow>
          <FormRow inputKey="street2">
            <Input
              id={FormFieldIDs.addressTwo}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.addressTwo] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.addressTwo);
              }}
            />
          </FormRow>
          <FormRow inputKey="city" required={homeCareCommunity}>
            <Input
              id={FormFieldIDs.city}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.city] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.city);
              }}
              error={
                (formError[FormFieldIDs.city] !== "" &&
                  formError[FormFieldIDs.city] !== null) ||
                formError["name_conflict"] !== ""
              }
              onBlur={() => {
                if (homeCareCommunity) validateNotEmpty(FormFieldIDs.city);
              }}
            />
          </FormRow>
          <FormRow inputKey="state">
            <DropdownInput
              id={FormFieldIDs.state}
              value={
                formValues[FormFieldIDs.state]
                  ? getStateLabel(formValues[FormFieldIDs.state] as string)
                  : ""
              }
              handleClick={handleOpenFilter}
            />
          </FormRow>
          <FormRow inputKey="zip_code">
            <Input
              id={FormFieldIDs.zipCode}
              type={"text"}
              className={classes.inputWidth}
              value={formValues[FormFieldIDs.zipCode] ?? ""}
              onChange={(e) => {
                handleChange(e.currentTarget.value, FormFieldIDs.zipCode);
              }}
            />
          </FormRow>
          <FormRow inputKey="date_of_birth">
            <DateOfBirthDatePicker
              dateOfBirth={formValues[FormFieldIDs.dateOfBirth] ?? ""}
              saveDateChange={handleChangeDateOfBirth}
            />
          </FormRow>
          <div className={classes.phoneNumbersTitle}>
            {t("phone_numbers").toUpperCase()}
          </div>
          <div className={classes.phoneNumbersContainer}>
            <PhoneNumberPicker
              fieldID={FormFieldIDs.phoneNumberOne}
              labelComplement={"1."}
              defaultCountryCode={defaultRegionCode}
              phoneNumber={formValues[FormFieldIDs.phoneNumberOne]}
              formFull={true}
              onChange={(value: UnitPhoneNumber) =>
                handleChangePhone(value, FormFieldIDs.phoneNumberOne)
              }
            />
            <PhoneNumberPicker
              fieldID={FormFieldIDs.phoneNumberTwo}
              labelComplement={"2."}
              defaultCountryCode={defaultRegionCode}
              phoneNumber={formValues[FormFieldIDs.phoneNumberTwo]}
              formFull={true}
              onChange={(value: UnitPhoneNumber) =>
                handleChangePhone(value, FormFieldIDs.phoneNumberTwo)
              }
            />
          </div>
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("add")}
          </Button>
        </div>
      </form>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 1200 }}
      >
        <>
          {popoverID === FormFieldIDs.state && (
            <GenericPicker
              selectedValue={formValues["state"] ?? null}
              optionList={stateList}
              localize={false}
              handleChange={(option) =>
                handleChange(option, FormFieldIDs.state)
              }
            />
          )}
          {popoverID === "" && null}
        </>
      </Popover>
    </Paper>
  );
};

interface IProps {
  homeCareCommunity: boolean;
}
