import React, { useMemo, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { debounce, InputBase, ListItem, ListItemIcon } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { CheckBoxIndicator } from "./GreenCheckBox";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      "&$selected:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
      borderRight: (props) =>
        props.withApplyButton ? "1px solid rgba(0, 0, 0, 0.12)" : "unset",
    },
    selected: {},
    search: {
      position: "relative",
      display: "flex",
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 0),
      height: "100%",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
    },
    inputRoot: {
      color: "inherit",
      fontSize: theme.typography.body2.fontSize,
      flexGrow: 1,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      marginLeft: theme.spacing(1),
    },
    inputInput: {
      padding: theme.spacing(0),
      width: "100%",
      height: "24px",
    },
    listIcon: {
      minWidth: "25px",
    },
  })
);

export const FilterSearchField = (props: IProps) => {
  const {
    placeholder,
    allSelected,
    noneSelected,
    selectAllCheckbox,
    disabled,
    handleSearchField,
    handleSelectAll,
  } = props;
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles(props);

  /* ---- Refs ---- */
  const textInput = useRef<any | null>(null);

  /* ---- State ---- */
  const [searchActive, setSearchActive] = useState(false);

  /* ---- Methods ---- */
  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchActive(searchValue !== "");
    handleSearchField(searchValue);
  };

  const handleClearSearch = () => {
    if (textInput !== null && textInput.current !== null) {
      textInput.current.value = "";
      handleSearchField("");
    }
    setSearchActive(false);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisabled = disabled !== undefined ? disabled : false;
  return (
    <ListItem
      className={classes.listRoot}
      key={"search-select-all"}
      id={"search-select-all"}
      disabled={isDisabled}
    >
      {selectAllCheckbox && (
        <ListItemIcon
          classes={{
            root: classes.listIcon,
          }}
        >
          <CheckBoxIndicator
            edge="start"
            checked={allSelected}
            indeterminate={!(allSelected || noneSelected)}
            tabIndex={-1}
            disableRipple
            disabled={isDisabled}
            onClick={handleSelectAll}
          />
        </ListItemIcon>
      )}

      <div className={classes.searchIcon}>
        <SearchIcon color={searchActive ? "primary" : undefined} />
      </div>
      <InputBase
        placeholder={`${t(placeholder)}`}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        fullWidth
        disabled={isDisabled}
        inputRef={textInput}
        inputProps={{ "aria-label": t(placeholder) }}
        onChange={debouncedResults}
        endAdornment={
          searchActive && (
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleClearSearch}
            />
          )
        }
      />
    </ListItem>
  );
};

interface IProps {
  withApplyButton: boolean;
  placeholder: string;
  allSelected: boolean;
  noneSelected: boolean;
  selectAllCheckbox: boolean;
  disabled?: boolean;
  handleSelectAll: () => void;
  handleSearchField: (searchValue: string) => void;
}
