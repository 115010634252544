import React, { useEffect, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAppState } from "../../../helpers/store";
import { DateTime } from "luxon";
import clsx from "clsx";
import SectionHeader from "./SectionHeader";
import {
  IDailyTemperatures,
  ITemperatureChartData,
  ITemperatureData,
  ITemperaturePoints,
} from "../eventDataTypes";
import LineChart from "../../common/LineChart";
import {
  EventThresholds,
  formatTemperature,
  graphColorPicker,
  sortAlphabetical,
  Temperature,
} from "../../../helpers/constants";
import {
  DateFromIsoString,
  DateTimeFromMillis,
} from "../../../helpers/datetime";
import { IEvent } from "../../../services/dashboard.services";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentsTitle: {
      opacity: "0.54",
    },
    container: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    containerA: {
      height: "100%",
      width: "100%",
      minWidth: "300px",
    },
    table: {
      width: "100%",
      minWidth: "300px",
      margin: "auto",
      tableLayout: "fixed",
      borderSpacing: "0px",
      fontSize: theme.typography.caption.fontSize,
      lineHeight: 1,
      overflowX: "auto",
    },
    temperatureTableContainer: {
      margin: "auto",
      width: "90%",
      [theme.breakpoints.up("lg")]: {
        width: "70%",
      },
    },
    headerCell: {
      fontWeight: "normal",
      textAlign: "center",
    },
    cell: {
      textAlign: "center",
    },
    currentCell: {
      fontFamily: theme.typography.secondaryFontFamily,
      textAlign: "center",
    },
    warningThreshold: {
      color: theme.palette.error.main,
    },
  })
);
const isAbnormal = (
  value: number,
  maxTreshold: number,
  minThreshold: number,
  threshold: EventThresholds | null
) => {
  switch (threshold) {
    case null:
      return false;
    case EventThresholds.UPPER:
      return value >= maxTreshold;
    case EventThresholds.LOWER:
      return value <= minThreshold;
    default:
      return false;
  }
};

const LineCanvas = (props: { index: number }) => {
  const { index } = props;
  const { borderColor, dashlineStyle } = graphColorPicker(index);
  const refContainer = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (refContainer !== null) {
      const canvas = refContainer.current as HTMLCanvasElement;
      const ctx = canvas.getContext("2d");
      if (ctx !== null) {
        ctx.beginPath();
        ctx.setLineDash(dashlineStyle);
        ctx.strokeStyle = borderColor;
        ctx.lineWidth = 3;
        ctx.moveTo(0, 10);
        ctx.lineTo(40, 10);
        ctx.stroke();
      }
    }
  });

  return (
    <React.Fragment>
      <canvas
        ref={refContainer}
        width="130"
        height="15"
        id={`canvas${index}`}
      />
    </React.Fragment>
  );
};

export const TemperatureSummary = (props: {
  temperatureData: IDailyTemperatures[];
  threshold?: EventThresholds | null;
  maxTreshold: number;
  minThreshold: number;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { temperatureData, maxTreshold, minThreshold, threshold } = props;

  const heatIndexExists = temperatureData.every(
    (data) => data.heat_index !== undefined && data.heat_index !== null
  );

  const getTableNames = () => {
    if (heatIndexExists) {
      return (
        <>
          <th className={classes.headerCell}>{t("temperature_abbrev")}</th>
          <th className={classes.headerCell}>{t("humidity_abbrev")}</th>
          <th className={classes.currentCell}>{t("feels_like_abbrev")}</th>
        </>
      );
    } else {
      return (
        <>
          <th className={classes.currentCell}>{t("current")}</th>
          <th className={classes.headerCell}>{t("low")}</th>
          <th className={classes.headerCell}>{t("high")}</th>
        </>
      );
    }
  };

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th colSpan={2}></th>
          <th></th>
          {getTableNames()}
        </tr>
      </thead>
      <tbody>
        {temperatureData.map((data, index) => {
          const dataThreshold = threshold !== undefined ? threshold : null;
          const currentClassName = clsx(
            classes.cell,
            classes.currentCell,
            data.current &&
              isAbnormal(
                data.current,
                maxTreshold,
                minThreshold,
                dataThreshold
              ) &&
              classes.warningThreshold
          );
          const lowClassName = clsx(
            classes.cell,
            data.low &&
              isAbnormal(data.low, maxTreshold, minThreshold, dataThreshold) &&
              classes.warningThreshold
          );
          const highClassName = clsx(
            classes.cell,
            data.high &&
              isAbnormal(data.high, maxTreshold, minThreshold, dataThreshold) &&
              classes.warningThreshold
          );
          const heatIndexClassName = clsx(
            classes.cell,
            classes.currentCell,
            data.heat_index &&
              isAbnormal(
                data.heat_index,
                maxTreshold,
                minThreshold,
                dataThreshold
              ) &&
              classes.warningThreshold
          );
          const temperatureClassName = clsx(classes.cell, data.temperature);
          const humidityClassName = clsx(classes.cell, data.temperature);

          return heatIndexExists ? (
            <tr key={`${data.name}-${index}`}>
              <td colSpan={2}>{data.name}</td>
              <td className={classes.cell}>
                <LineCanvas index={index} />
              </td>
              <td className={temperatureClassName}>
                {data.temperature ? formatTemperature(data.temperature) : "-"}
              </td>
              <td className={humidityClassName}>
                {data.humidity ? `${data.humidity}%` : "-"}
              </td>
              <td className={heatIndexClassName}>
                {data.heat_index ? formatTemperature(data.heat_index) : "-"}
              </td>
            </tr>
          ) : (
            <tr key={`${data.name}-${index}`}>
              <td colSpan={2}>{data.name}</td>
              <td className={classes.cell}>
                <LineCanvas index={index} />
              </td>
              <td className={currentClassName}>
                {data.current ? formatTemperature(data.current) : "-"}
              </td>
              <td className={lowClassName}>
                {data.low ? formatTemperature(data.low) : "-"}
              </td>
              <td className={highClassName}>
                {data.high ? formatTemperature(data.high) : "-"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default function HeatIndexWarningChart(props: IProps) {
  const { event } = props;
  const communityID = event.community_id ?? undefined;
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const communityUnitTimezone = useCommunityUnitTimezone(
    communityID,
    event.unit_id
  );

  /* Selectors */
  const configTemperature = useSelector((state: IAppState) => {
    return state.appState.config.temperature;
  });

  // Get min and max values for line chart
  const getMinMaxTempData = (
    chartData: ITemperatureChartData[],
    temperature: Temperature
  ) => {
    let min: number = chartData[0]?.temperature_data[0]?.heat_index || 0;
    let max: number = chartData[0]?.temperature_data[0]?.heat_index || 0;
    chartData.forEach((zoneTempData) => {
      zoneTempData.temperature_data.forEach((tempData) => {
        let value = tempData.heat_index || 0;
        min = value < min ? value : min;
        max = value > max ? value : max;
      });
    });

    const yMinBuffer = temperature === Temperature.CELSIUS ? 5 : 10;
    min =
      Math.ceil((Number(formatTemperature(min, false)) - yMinBuffer) / 5) * 5;
    max = Math.ceil(Number(formatTemperature(max, false)) / 5) * 5;
    return [min, max];
  };

  // Get an array of arrays that contains objects with cartesian points used to
  // draw the temperature chart
  const getDataSets = (chartData: ITemperatureChartData[]) => {
    const dataSets: { x: DateTime | string; y: number | null }[][] = [];

    chartData.forEach((zoneTempData) => {
      // Copy and dereference array
      const tempData = (JSON.parse(
        JSON.stringify(zoneTempData.temperature_data)
      ) as ITemperaturePoints[]).sort((a, b) => {
        return Number(a.timestamp) - Number(b.timestamp);
      });
      // Build point using Luxon DateTime object and formated temperature
      const data = tempData.flatMap((temperatureData) => {
        return {
          x: DateTimeFromMillis(
            temperatureData.timestamp || 0,
            communityUnitTimezone
          ),
          y: temperatureData.heat_index
            ? Number(formatTemperature(temperatureData.heat_index, false))
            : null,
        };
      });
      dataSets.push(data);
    });
    return dataSets;
  };

  const eventData = JSON.parse(event?.data || "") as ITemperatureData;

  const dataThreshold = eventData.threshold;
  // Current temperatures table data
  const currentTempData = (JSON.parse(
    JSON.stringify(eventData.daily_temps)
  ) as IDailyTemperatures[]).sort((a, b) => sortAlphabetical(a.name, b.name));
  // Obtain min&max temperature values for the line chart
  const minThreshold = eventData.typical_chart.typ_low_2 || 0;
  const maxThreshold = eventData.typical_chart.typ_high_2 || 0;

  // Line graph data
  const lineChartData = JSON.parse(
    JSON.stringify(eventData.chart_data)
  ) as ITemperatureChartData[];

  // Add missing zones to chart data using daily temperatures zones
  currentTempData.forEach((currTemp) => {
    const indexLineChart = lineChartData.findIndex(
      (lineCh) => lineCh.name === currTemp.name
    );
    if (indexLineChart === -1)
      lineChartData.push({ name: currTemp.name, temperature_data: [] });
  });

  lineChartData.sort((a, b) => sortAlphabetical(a.name, b.name));

  const [minTemp, maxTemp] = getMinMaxTempData(
    lineChartData,
    configTemperature
  );
  const datasets = getDataSets(lineChartData);

  const maxDateTime = DateFromIsoString(
    props.event.time_created,
    communityUnitTimezone
  );
  let minDateTime = maxDateTime;
  minDateTime = minDateTime.minus({ hour: 3 });

  const zone = currentTempData.map((item) => item.name);
  const missingHeatIndex = currentTempData.some((temp) => !temp.heat_index);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <SectionHeader
          text={t(
            missingHeatIndex ? "temperature" : "feels_like"
          ).toUpperCase()}
        />
        <div className={classes.containerA}>
          <div className={classes.temperatureTableContainer}>
            <TemperatureSummary
              temperatureData={currentTempData}
              threshold={dataThreshold}
              minThreshold={minThreshold}
              maxTreshold={maxThreshold}
            />
          </div>
          <LineChart
            min={minTemp}
            max={maxTemp}
            labels={zone}
            values={datasets}
            animation={false}
            communityUnitTimezone={communityUnitTimezone}
            maxTime={maxDateTime}
            minTime={minDateTime}
            useDefaultTimeConfig={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

interface IProps {
  event: IEvent;
}
