import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { TextField, Theme, Typography } from "@material-ui/core";
import { updateZoneName } from "./devicesThunks";
import { useAppDispatch } from "../app/appHooks";
import { Device } from "../../services/devices.services";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBar: {
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
      display: "flex",
      justifyContent: "space-between",
    },
    dialogBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dialogTitleBox: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      paddingLeft: "130px",
      paddingRight: "130px",
    },
    dialogTitle: {
      fontWeight: "bold",
      textAlign: "center",
    },
    dialogContentBox: {
      paddingTop: "30px",
      marginLeft: "25px",
      marginRight: "25px",
    },
    dialogContentText: {
      fontWeight: "bold",
    },
    dialogButtons: {
      width: "120px",
      height: "35px",
    },
  })
);

// Display a modal to make the user acknowledge something
export const RenameRoomDialog = (props: iProps) => {
  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const { toggle, onClose } = props;
  const dispatch = useAppDispatch();

  const [newName, setNewName] = useState("");

  useEffect(() => {
    // Update newName when the dialog is opened
    if (
      props.selectedDevice?.zone_name !== null &&
      props.selectedDevice?.zone_name !== undefined
    ) {
      setNewName(props.selectedDevice?.zone_name);
    }
  }, [props.toggle, props.selectedDevice?.zone_name]);

  const handleSubmit = () => {
    if (props.selectedDevice && props.selectedDevice.zone_id) {
      dispatch(
        updateZoneName({
          unitID: props.unitID,
          zoneID: props.selectedDevice?.zone_id,
          zoneName: newName,
        })
      );
    }
    onClose();
  };

  const closeDialog = () => {
    onClose();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let { value } = e.target;
    setNewName(value);
  };

  return (
    <div>
      <Dialog
        open={toggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle className={classes.dialogTitleBox} id="alert-dialog-title">
          <Typography className={classes.dialogTitle}>
            {t("rename_room")}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContentBox}>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogContentText}
          >
            {t("enter_new_room_name")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            onChange={handleNameChange}
            defaultValue={newName}
          />
        </DialogContent>
        <DialogActions className={classes.buttonBar}>
          <Button
            onClick={() => {
              closeDialog();
            }}
            color="default"
            variant="contained"
            className={classes.dialogButtons}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            className={classes.dialogButtons}
            disabled={
              newName === props.selectedDevice?.zone_name ||
              newName.trim() === ""
            }
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default RenameRoomDialog;

export interface iProps {
  toggle: boolean;
  unitID: string;
  selectedDevice: Device | null;
  onClose: () => void;
}
