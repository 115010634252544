import { AnalyticsChartTypes } from "../../../helpers/constants";
import { ChartMode } from "../AnalyticsViewerCharts";
import { UnitActivityChart } from "./UnitActivityChart";
import { UnitBathroomChart } from "./UnitBathroomChart";
import { UnitKitchenActivity } from "./UnitKitchenActivity";
import { UnitSleepChart } from "./UnitSleepChart";
import { UnitTemperatureChart } from "./UnitTemperatureChart";

export const ChartPicker = (props: {
  type: string;
  data: any;
  mode: ChartMode;
  pagination?: { startTime: string; endTime: string };
  lastUpdated?: string;
  isPrintable: boolean;
  multipleBathrooms?: boolean;
}) => {
  const {
    type,
    data,
    mode,
    pagination,
    lastUpdated,
    isPrintable,
    multipleBathrooms,
  } = props;
  switch (type) {
    case AnalyticsChartTypes.sleep:
      return (
        <UnitSleepChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
        />
      );
    case AnalyticsChartTypes.bathroom:
      return (
        <UnitBathroomChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
          multipleBathrooms={multipleBathrooms}
        />
      );
    case AnalyticsChartTypes.activity:
      return (
        <UnitActivityChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
        />
      );
    case AnalyticsChartTypes.temperature:
      return (
        <UnitTemperatureChart
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
        />
      );
    case AnalyticsChartTypes.kitchen:
      return (
        <UnitKitchenActivity
          chartData={data}
          lastUpdatedTime={lastUpdated}
          mode={mode}
          pagination={pagination}
          isPrintable={isPrintable}
        />
      );
    default:
      return null;
  }
};
