import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Icon } from "@material-ui/core";
import Icons from "../../helpers/iconImports";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    iconColor: {
      color: "inherit",
    },
    listItem: {
      margin: theme.spacing(1, 0),
    },
    preventOverflow: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    imageIcon: {
      paddingBottom: "5px",
    },
  })
);

export default function ExternalLink(props: IProps) {
  const { icon, primary, disabled, visible, link } = props;
  const classes = useStyles();

  const handleClick = () => {
    window.open(link, "_blank", "noopener");
  };

  return (
    <React.Fragment>
      {visible ? (
        <li className={classes.listItem}>
          <ListItem button disabled={disabled} onClick={handleClick}>
            {icon ? (
              <ListItemIcon className={classes.iconColor}>{icon}</ListItemIcon>
            ) : null}
            <ListItemText
              primaryTypographyProps={{ variant: "h6" }}
              primary={primary}
              classes={{ primary: classes.preventOverflow }}
            />
            <Icon>
              <img
                className={classes.imageIcon}
                src={Icons.PopOutIcon}
                alt={""}
                draggable={false}
              />
            </Icon>
          </ListItem>
        </li>
      ) : null}
    </React.Fragment>
  );
}

interface IProps {
  icon?: React.ReactElement;
  primary: string;
  disabled: boolean;
  visible: boolean;
  link: string;
}
