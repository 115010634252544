import { getGeolocation, request } from "./utils";
import firebase from "firebase/app";
import "firebase/auth";
import { getClientID } from "../features/login/loginSlice";

export const loginServices = {
  login,
  refreshToken,
  requestCode,
  resetPassword,
  authorize,
  authToFirebase,
  logoutFirebase,
  validatePin,
  validatePassword,
  createAccount,
  verifyEmail,
  resendVerificationEmail,
  createPIN,
  getUserGeolocation,
  changePassword,
};

export interface IToken {
  access_expiration: string;
  access_token: string;
  refresh_expiration: string;
  refresh_token: string;
  token_type: string;
  user_id: string;
}

export interface IFailedRequest {
  code?: string;
  message: string;
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface IAlexaData {
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  state: string;
}

export interface IPasswordResetData {
  email: string;
  code: string;
  newPassword: string;
}

export interface IPinValidationData {
  email: string;
  pin: string;
}
export interface IPasswordValidatonData {
  email: string;
  password: string;
}

export interface IValidateData {
  is_valid: boolean;
}

export type IAuthorizeData = ILoginData & IAlexaData;

export interface ICreateAccountData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  language: string | null;
  country: string | null;
  token: string;
}

export interface IVerifyEmailData {
  email: string;
  code: string;
}

export interface IPasswordChangeData {
  email: string;
  oldPassword: string;
  newPassword: string;
}

/**
 * Login service, uses username and password to obtain an auth token
 *
 * @param {ILoginData} loginData user's credentials
 * @returns {IToken} A json containing tokens
 */
async function login(loginData: ILoginData, clientID: string): Promise<IToken> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      grant_type: "password",
      client_id: clientID,
      ...loginData,
    }),
  };

  const data = await request("/oauth2/tokens", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as IToken);
  }
}

/**
 * Authorize 3rd party services, receives
 *
 * @param {IAuthorizeData} authorizeData
 * @returns {string} A json containing tokens
 */
async function authorize(authorizeData: IAuthorizeData) {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      ...authorizeData,
    }),
  };

  const data = await request("/oauth2/authorize", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data.redirect_uri as unknown) as string);
  }
}

/**
 * Refreshes auth information using the provided refresh token
 *
 * @param {string} refreshToken refresh token
 * @return {IToken} refreshed token object
 */
async function refreshToken(
  refreshToken: string,
  clientID: string
): Promise<IToken> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: clientID,
    }),
  };

  const data = await request("/oauth2/tokens", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    authToFirebase(((data as unknown) as IToken).access_token);
    return Promise.resolve((data as unknown) as IToken);
  }
}

/**
 * Request code to be sent to the provided email
 *
 * @param {string} email account username to login
 * @returns {boolean} response confirming the sent code
 */
async function requestCode(email: string): Promise<boolean | IFailedRequest> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
  };

  const data = await request("/login/password/reset", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as boolean);
  }
}

/**
 * Login service, uses username and password to obtain an auth token
 *
 * @param {IPasswordResetData} passwordResetData contains the needed information to reset password
 * @returns {IToken} A json containing the updated token
 */
async function resetPassword(
  passwordResetData: IPasswordResetData
): Promise<IToken | IFailedRequest> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: JSON.stringify({
      email: passwordResetData.email,
      password: passwordResetData.newPassword,
      token: passwordResetData.code,
    }),
  };

  const data = await request("/login/password/reset", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as IToken);
  }
}

/**
 * Logs in to firebase using the provided token from auth server
 *
 * @param {string} accessToken user's credentials
 */
async function authToFirebase(accessToken: string) {
  const data = await firebase.auth().signInWithCustomToken(accessToken);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data as firebase.auth.UserCredential);
  }
}

/**
 * Logs out of firebase
 */
function logoutFirebase() {
  firebase.auth().signOut();
}

/**
 * Validates user pin
 *
 * @param {IPinValidationData} pinValidationData contains the needed information to validate the pin
 * @returns {boolean} A boolean indicading if is valid or not
 */
async function validatePin(
  pinValidationData: IPinValidationData
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      email: pinValidationData.email,
      pin: pinValidationData.pin,
    }),
  };

  const data = await request("/login/pin", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data.is_valid as boolean);
  }
}

/**
 * Validates user password
 *
 * @param {IPasswordValidatonData} passwordValidatonData contains the needed information to validate password
 * @returns {boolean} A boolean indicading if is valid or not
 */
async function validatePassword(
  passwordValidatonData: IPasswordValidatonData
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      email: passwordValidatonData.email,
      password: passwordValidatonData.password,
    }),
  };

  const data = await request("/login/password", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve(data.is_valid as boolean);
  }
}

/**
 * Creates an user account
 *
 * @param {ICreateAccountData} createAccountData data for account creation
 * @returns {IToken} A json containing tokens
 */
async function createAccount(
  createAccountData: ICreateAccountData
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      first_name: createAccountData.firstName,
      last_name: createAccountData.lastName,
      email: createAccountData.email,
      password: createAccountData.password,
      country: createAccountData.country,
      language: createAccountData.language,
      token: createAccountData.token,
    }),
  };

  const data = await request("/register/aip?type=aip", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as boolean);
  }
}

/**
 * Verifies the user's email using the code sent
 *
 * @param {IVerifyEmailData} verifyEmailData data for email verification
 * @returns {boolean} confirmation of request
 */
async function verifyEmail(
  verifyEmailData: IVerifyEmailData
): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      email: verifyEmailData.email,
      verification_token: verifyEmailData.code,
    }),
  };

  const data = await request("/verify", requestOptions, false);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as boolean);
  }
}

/**
 * Verifies the user's email using the code sent
 *
 * @param {string} email data for email verification
 */
async function resendVerificationEmail(email: string): Promise<IToken> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      email,
    }),
  };
  return await request("/register/resend_verification", requestOptions, false);
}

/**
 * Creates a PIN number for the account
 *
 * @param {string} pin PIN number to set for the account
 * @returns {boolean} confirmation of request
 */
async function createPIN(pin: string): Promise<boolean> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      pin,
    }),
  };

  const data = await request("/login/pin/create", requestOptions);

  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as boolean);
  }
}

interface IGeolocationData {
  city: string;
  region: string;
  country: string;
}

/**
 * Creates a PIN number for the account
 *
 * @returns {IGeolocationData} confirmation of request
 */
async function getUserGeolocation(): Promise<IGeolocationData> {
  const data = await getGeolocation();

  if (data instanceof Error) {
    const error = new Error("Could not get user geolocation");
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as IGeolocationData);
  }
}

/**
 * @param {IPasswordChangeData} passwordResetData contains the needed information to reset password
 * @returns {IToken} A json containing the updated token
 */
async function changePassword(
  passwordChangeData: IPasswordChangeData
): Promise<IToken> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: JSON.stringify({
      email: passwordChangeData.email,
      new_password: passwordChangeData.newPassword,
      old_password: passwordChangeData.oldPassword,
      client_id: getClientID(),
    }),
  };

  const data = await request("/login/password/change", requestOptions);
  if (data instanceof Error) {
    const error: IFailedRequest = JSON.parse(data.message);
    return Promise.reject(error);
  } else {
    return Promise.resolve((data as unknown) as IToken);
  }
}
