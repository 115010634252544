import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import LoginForm from "./LoginForm";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as QueryString from "query-string";
import alexaBadge from "../../assets/img/works_with_alexa_logo.png";
import { IAlexaData } from "../../services/login.services";
import { authorizeMode, LoginModes, resetSliceState } from "./loginSlice";
import { validateAlexaAuth } from "../../helpers/utils";
import { IAppState } from "../../helpers/store";
import { ReducerStates } from "../../helpers/constants";
import { MainContainer, LeftPanel, RightPanel } from "./LoginLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      display: "block",
      height: "auto",
    },
    subtitleContainer: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& img": {
        maxWidth: "100%",
        margin: "auto",
      },
    },
    bottomText: {
      textAlign: "center",
      position: "absolute",
      bottom: "16px",
      padding: theme.spacing(0, 2),
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  })
);

export const getFormattedProject = (version: String, project: String) => {
  if (version === "") {
    return project;
  }

  return `${version}-dot-${project}`;
};

/**
 * Login component
 * @param {string} community Active community
 */

export default function Login() {
  /* ---- Hooks ---- */
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const params = QueryString.parse(location.search);
  const showEnvironment = process.env.REACT_APP_ENVIRONMENT !== "production";

  // Validate if URL has params that match an auth flow from Alexa
  const mode = validateAlexaAuth(params) ? LoginModes.ALEXA : LoginModes.LOGIN;
  const title = `${mode}_title`;

  /* ---- Selectors ---- */
  const loginState: ReducerStates = useSelector(
    (state: IAppState) => state.loginState.state
  );

  const authRedirectURL: string = useSelector(
    (state: IAppState) => state.loginState.authRedirectURL
  );

  const getProject = useSelector(
    (state: IAppState) => state.appState.environment
  );

  const getVersion = useSelector((state: IAppState) => state.appState.version);

  // Set the auth mode
  useEffect(() => {
    if (mode === LoginModes.ALEXA) {
      dispatch(authorizeMode({ mode: mode, authParams: location.search }));
    }
  }, [mode, dispatch, location.search]);

  // Set the auth mode
  useEffect(() => {
    if (
      mode === LoginModes.ALEXA &&
      ReducerStates[loginState] === ReducerStates.SUCCEEDED &&
      authRedirectURL !== ""
    ) {
      window.location.href = authRedirectURL;
      dispatch(resetSliceState());
    }
  }, [mode, loginState, dispatch, authRedirectURL]);

  return (
    <MainContainer>
      <LeftPanel>
        <Typography variant="h4" component="h1" align="center">
          {t(title)}
        </Typography>
        {mode === LoginModes.LOGIN && (
          <div className={classes.subtitleContainer}>
            <br />
            <Typography variant="body1" align="center">
              {t("login_subtitle")}
            </Typography>
            <br />
            <Typography variant="body1" align="center">
              {t("login_subtitle2")}
            </Typography>
          </div>
        )}
        {mode === LoginModes.ALEXA && (
          <div className={classes.subtitleContainer}>
            <br />
            <SubtitleAlexa />
          </div>
        )}
        {showEnvironment && mode === LoginModes.LOGIN && (
          <Typography
            variant="body2"
            align="center"
            className={classes.bottomText}
          >
            {getFormattedProject(getVersion, getProject)}
          </Typography>
        )}
      </LeftPanel>
      <RightPanel>
        <LoginForm
          mode={mode}
          params={(params as unknown) as IAlexaData}
          subtitleComponent={<SubtitleAlexa />}
        />
      </RightPanel>
    </MainContainer>
  );
}

const SubtitleAlexa = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Typography variant="body1" align="center">
        {t("alexa_subtitle")}
      </Typography>
      <img
        src={alexaBadge}
        alt="Alexa logo"
        className={classes.badge}
        draggable={false}
      />
    </React.Fragment>
  );
};
