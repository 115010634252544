import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TrayEvent from "../dashboard/tabTray/TrayEvent";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box/Box";
import { useSelector } from "react-redux";
import { IAppState } from "../../helpers/store";
import { IEvent, IUnit } from "../../services/dashboard.services";
import {
  getRowBorderColor,
  getTypeIndicatorBGColor,
  getTypeIndicatorTextColor,
} from "./NotificationRow";
import { getResidentCity, getResidentName } from "../dashboard/dashboardSlice";
import { i18textByEventType } from "../../helpers/constants";
import { isPausedEvent } from "../dashboard/CommunityUnit";
import TrayPausedEvent from "../dashboard/tabTray/TrayPausedEvent";
import { Card } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MaximizeIcon from "@material-ui/icons/ExpandLess";
import MinimizeIcon from "@material-ui/icons/ExpandMore";
import { viewerHeaderHeight } from "./Notifications";
import useHelpAtHome from "./hooks/useHelpAtHome";

const useStyles = makeStyles<Theme, { selectedNotification?: IEvent }>(
  (theme: Theme) => ({
    viewerContainer: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      height: "100%",
    },
    criticalBorder: {
      borderBottom: `17px solid ${theme.palette.error.main}`,
    },
    warningBorder: {
      borderBottom: `17px solid ${theme.palette.warning.main}`,
    },
    viewerTrayContent: {
      height: `calc(100% - 45.5px)`,
      position: "relative",
      padding: theme.spacing(3, 3, 1, 3),
    },
    notificationViewerHeader: {
      width: "100%",
      height: viewerHeaderHeight,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      fontFamily: theme.typography.tertiaryFontFamily,
      backgroundColor: (props) =>
        getTypeIndicatorBGColor(theme, props.selectedNotification),
      color: (props) =>
        getTypeIndicatorTextColor(theme.palette, props.selectedNotification),
      borderTop: (props) =>
        `2px solid ${getRowBorderColor(theme, props.selectedNotification)}`,
      padding: theme.spacing(1, 0),
    },
    trayOptions: {
      position: "absolute",
      top: "8px",
      right: 0,
      width: "60px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-evenly",
      cursor: "pointer",
    },
    tabTrayContent: {
      height: `calc(100% - ${viewerHeaderHeight})`,
      position: "relative",
    },
    systemIssues: {
      fontFamily: theme.typography.primaryFontFamily,
    },
  })
);

export const NotificationViewer = React.forwardRef((props: IProps, ref) => {
  const {
    units,
    openTray,
    minimizedTray,
    maximizeNotificationsTray,
    minimizeNotificationsTray,
    closeNotificationsTray,
  } = props;

  /* ---- Selectors ---- */
  const selectedNotificationID = useSelector(
    (state: IAppState) => state.notificationsState.selectedNotificationID
  );

  const selectedNotification = useSelector((state: IAppState) =>
    state.notificationsState.notifications?.find(
      (notification) => notification.id === selectedNotificationID
    )
  );
  /* ---- Hooks ---- */
  const classes = useStyles({ selectedNotification });
  const { t } = useTranslation();
  const [allHelpAtHome] = useHelpAtHome(units);

  // Regular title "<notification_title> - <unit name> - <resident first_name last_initial>"
  // Help at home title "<notification_title> - <resident name> - <city>"
  const getViewerTitle = (
    units?: IUnit[],
    event?: IEvent,
    allHelpAtHome?: boolean
  ) => {
    // Event unit
    const unit = units?.find((unit) => unit.id === event?.unit_id);
    const headerArr = [];

    // Add event type
    if (event) {
      const notificationTitle = t(i18textByEventType(event.event_type));
      notificationTitle !== "-" && headerArr.push(notificationTitle);
    }
    // Add event type
    if (unit && event) {
      const residentName = getResidentName(unit?.residents, {
        fullFirstName: true,
      });
      if (allHelpAtHome) {
        const city = unit && getResidentCity(unit.residents);
        unit && residentName !== "" && headerArr.push(residentName);
        unit && city !== "" && headerArr.push(city);
      } else {
        unit && unit.name !== "" && headerArr.push(unit.name);
        unit && residentName !== "" && headerArr.push(residentName);
      }
    }
    return headerArr.join(" - ");
  };

  const getSystemIssueText = (event?: IEvent) => {
    let headerResult = "";
    if (event) {
      if (event.system_issues) {
        headerResult = `(${t("system_issues")})`;
      } else if (event.is_delayed) {
        headerResult = `(${t("delayed")})`;
      }
    }
    return headerResult;
  };

  const systemIssueText = getSystemIssueText(selectedNotification);

  const viewerHeaderText = getViewerTitle(
    units,
    selectedNotification,
    allHelpAtHome
  );

  let isPaused = false;
  if (selectedNotification) {
    isPaused = isPausedEvent(selectedNotification);
  }

  return (
    <Card className={classes.viewerContainer} ref={ref}>
      {selectedNotificationID !== undefined &&
        selectedNotification !== undefined && (
          <React.Fragment>
            <Box
              fontSize="h6.fontSize"
              className={classes.notificationViewerHeader}
            >
              {viewerHeaderText}
              {systemIssueText !== "" && (
                <span className={classes.systemIssues}>
                  &ensp;{systemIssueText}
                </span>
              )}
              <Box className={classes.trayOptions}>
                {openTray && !minimizedTray ? (
                  <MinimizeIcon
                    className={classes.pointer}
                    onClick={() => minimizeNotificationsTray()}
                  />
                ) : (
                  <MaximizeIcon
                    className={classes.pointer}
                    onClick={() => maximizeNotificationsTray()}
                  />
                )}
                <CloseIcon
                  className={classes.pointer}
                  onClick={() => closeNotificationsTray()}
                />
              </Box>
            </Box>
            {openTray && !minimizedTray && (
              <Box className={classes.tabTrayContent}>
                <Box height="100%" padding={2}>
                  {!isPaused && <TrayEvent eventID={selectedNotificationID} />}
                  {isPaused && (
                    <TrayPausedEvent eventID={selectedNotificationID} />
                  )}
                </Box>
              </Box>
            )}
          </React.Fragment>
        )}
    </Card>
  );
});

interface IProps {
  units?: IUnit[];
  openTray: boolean;
  minimizedTray: boolean;
  maximizeNotificationsTray: () => void;
  minimizeNotificationsTray: () => void;
  closeNotificationsTray: () => void;
}
