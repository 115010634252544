import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Link, Theme, Tooltip, withStyles } from "@material-ui/core";
import { ContactIcons } from "../../helpers/iconImports";
import { ellipsis, EMAIL_CHAR_LIMIT } from "../../helpers/constants";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    emailFieldContainer: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.main,
      padding: "0px 0px 4px 0px",
    },
    emailFieldIcon: {
      display: "inline-table",
      height: "20px",
      paddingRight: theme.spacing(1),
    },
    emailFieldLabel: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1rem",
    },
  })
);

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const EmailField = (props: IProps) => {
  const { email } = props;

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const phoneIcon = ContactIcons.Email;
  const displayEmail = ellipsis(email, EMAIL_CHAR_LIMIT);

  return (
    <CustomTooltip title={email} placement="top" interactive>
      <Link
        className={classes.emailFieldContainer}
        color="primary"
        href={`mailto: ${email}`}
      >
        <img
          src={phoneIcon}
          alt={t("alt_text_email_icon")}
          draggable={false}
          className={classes.emailFieldIcon}
        />
        <span className={classes.emailFieldLabel}>{displayEmail}</span>
      </Link>
    </CustomTooltip>
  );
};

interface IProps {
  email: string;
}
