import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { ReducerStates } from "../../helpers/constants";
import {
  closeNotificationsTray,
  getMoreNotifications,
  getNotifications,
  maximizeNotificationsTray,
  minimizeNotificationsTray,
  setUnreadEvents,
  removeUnreadEvent,
} from "./notificationsSlice";
import { NotificationsTable } from "./NotificationTable";
import { NotificationViewer } from "./NotificationViewer";
import { Box, Slide } from "@material-ui/core";
import clsx from "clsx";
import { FloatingNotificationPanel } from "../common/FloatingNotificationPanel";
import useHelpAtHome from "./hooks/useHelpAtHome";
import { useAppSelector } from "../app/appHooks";
import { getFirebaseDBReference } from "../../helpers/firebase";
import { getEventsToUpdateFirebase } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import { minToolbarHeight } from "../app/App";

export const viewerHeaderHeight = "45px";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    notifications: {
      display: "flex",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    notificationsContainer: {
      display: "flex",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      flexGrow: 1,
    },
    notificationsTableContainer: {
      display: "flex",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      width: "100%",
    },
    unassignedNotification: {
      color: theme.palette.error.main,
    },
    trayContainer: {
      display: "flex",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "calc(100% - 10px)",
    },
    viewerTray: {
      width: "calc(100% - 10px)",
      height: (showBanner) =>
        `calc(50vh - ${minToolbarHeight(showBanner, theme)} + 50px)`,
      zIndex: 3,
    },
    minimizedTray: {
      zIndex: 3,
    },
    closedTray: {
      display: "none",
    },
    trayHeightMaximized: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)} - 50vh + 50px)`,
    },
    trayHeightMinimized: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(
          showBanner,
          theme
        )} - ${viewerHeaderHeight})`,
    },
  })
);

export default function Notifications() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /* ---- Selectors ---- */
  const notificationsStatus = useAppSelector(
    (state) => state.notificationsState.state
  );

  const headerStatus = useAppSelector((state) => state.headerState.state);

  const selectedCommunity = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );

  const notificationEvents = useAppSelector(
    (state) => state.notificationsState.notifications
  );

  const units = useAppSelector((state) => state.headerState.units);

  const selectedCommunityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );

  const openTray = useAppSelector((state) => state.notificationsState.openTray);
  const minimizedTray = useAppSelector(
    (state) => state.notificationsState.minimizedTray
  );

  const sort = useAppSelector((state) => state.notificationsState.sort);

  const toggleFilter = useAppSelector(
    (state) => state.notificationsState.toggleFilter
  );
  const filters = useAppSelector((state) => state.notificationsState.filters);
  const page = useAppSelector((state) => state.notificationsState.page);
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);

  const currentUser = useAppSelector((state) => state.headerState.user);

  const [allHelpAtHome] = useHelpAtHome(units);
  const notifications =
    notificationEvents !== undefined ? notificationEvents : [];

  /* ---- Effects ---- */

  // Get initial events
  useEffect(() => {
    if (
      notificationsStatus === ReducerStates.IDLE &&
      headerStatus === ReducerStates.SUCCEEDED
    ) {
      if (selectedCommunity !== undefined) {
        dispatch(
          getNotifications({
            community_id: selectedCommunity.id,
            page: 0,
            sort: sort,
            filters: filters,
            allHelpAtHome: !!allHelpAtHome,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsStatus, headerStatus, selectedCommunity, dispatch]);

  // If sort or filters change, get information again
  useEffect(() => {
    if (notificationsStatus === ReducerStates.IDLE) return;
    if (selectedCommunity === undefined) return;

    dispatch(
      getNotifications({
        community_id: selectedCommunity.id,
        page: 0,
        sort: sort,
        filters: toggleFilter ? filters : {},
        allHelpAtHome: !!allHelpAtHome,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filters, selectedCommunity, toggleFilter, dispatch]);

  /* ---- Methods ---- */
  const handleGetMoreNotifications = () => {
    if (selectedCommunity !== undefined) {
      dispatch(
        getMoreNotifications({
          community_id: selectedCommunity.id,
          page: page + 1,
          sort: sort,
          filters: filters,
          allHelpAtHome: !!allHelpAtHome,
        })
      );
    }
  };

  // Maintain a list of unread events
  useEffect(() => {
    if (!firebaseAuth) return;
    if (selectedCommunityID === undefined) return;
    if (currentUser === undefined) return;

    const unreadEventsRef = getFirebaseDBReference().ref(
      `communities/${selectedCommunityID}/unread_events_soft/${currentUser.id}`
    );

    const listener = unreadEventsRef.on("value", (snapshot) => {
      const vals = snapshot.val();
      let _records = getEventsToUpdateFirebase(vals, true);
      dispatch(setUnreadEvents(_records));
    });
    return () => unreadEventsRef.off("value", listener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID, firebaseAuth, currentUser]);

  useEffect(() => {
    if (!firebaseAuth) return;
    if (selectedCommunityID === undefined) return;
    if (currentUser === undefined) return;
    const removedEventsRef = getFirebaseDBReference().ref(
      `communities/${selectedCommunityID}/unread_events_soft/${currentUser.id}`
    );
    const listener = removedEventsRef.on("child_removed", (snapshot) => {
      const resolvedEvent = snapshot.val();
      if (resolvedEvent === null) return;
      dispatch(removeUnreadEvent(resolvedEvent.id));
    });
    return () => removedEventsRef.off("child_removed", listener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityID, firebaseAuth, currentUser]);

  const handleMinimizeNotificationsTray = () => {
    dispatch(minimizeNotificationsTray());
  };

  const handleMaximizeNotificationsTray = () => {
    dispatch(maximizeNotificationsTray());
  };

  const handleCloseNotificationsTray = () => {
    dispatch(closeNotificationsTray());
  };

  return (
    <div className={classes.notifications}>
      <div className={classes.notificationsContainer}>
        <div
          className={clsx(
            classes.notificationsTableContainer,
            openTray ? classes.trayHeightMaximized : "",
            minimizedTray ? classes.trayHeightMinimized : ""
          )}
        >
          {units !== undefined ? (
            <NotificationsTable
              notifications={notifications}
              units={units}
              next={handleGetMoreNotifications}
            />
          ) : (
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
              padding="1"
            >
              <h1>{`${t("loading")}`}</h1>
            </Box>
          )}
        </div>
        <div
          className={`${classes.trayContainer} ${clsx(
            openTray ? classes.viewerTray : minimizedTray,
            selectedCommunityID !== undefined
              ? classes.minimizedTray
              : classes.closedTray
          )}`}
        >
          {openTray && (
            <Slide direction="up" in={openTray && !minimizedTray}>
              <NotificationViewer
                units={units}
                openTray={openTray}
                minimizedTray={minimizedTray}
                maximizeNotificationsTray={handleMaximizeNotificationsTray}
                minimizeNotificationsTray={handleMinimizeNotificationsTray}
                closeNotificationsTray={handleCloseNotificationsTray}
              />
            </Slide>
          )}
          {minimizedTray && (
            <Slide direction="up" in={minimizedTray && !openTray}>
              <NotificationViewer
                units={units}
                openTray={openTray}
                minimizedTray={minimizedTray}
                maximizeNotificationsTray={handleMaximizeNotificationsTray}
                minimizeNotificationsTray={handleMinimizeNotificationsTray}
                closeNotificationsTray={handleCloseNotificationsTray}
              />
            </Slide>
          )}
        </div>
      </div>
      <FloatingNotificationPanel />
    </div>
  );
}
