import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import {
  i18textByEventType,
  SettingListKeys,
  sortAlphabetical,
} from "../../helpers/constants";
import { List, Typography } from "@material-ui/core";
import { SettingsListItem } from "./common/SettingsListItem";
import { useTranslation } from "react-i18next";
import { EventType } from "../../services/settings.services";
import { loadEventConfig } from "./settingsThunks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationSettingsContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "50%",
      background: theme.palette.light.main,
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(3, 0, 0, 0),
      overflowY: "auto",
    },
    listContainer: {
      background: "#FFF",
    },
    optionsContainer: {
      width: "100%",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
    },
    secondaryListContainer: {
      display: "flex",
      flex: 1,
      width: "100%",
      flexDirection: "column",
      padding: theme.spacing(0, 1),
    },
    primaryTextContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    userRelation: {
      textAlign: "right",
      fontSize: theme.typography.caption.fontSize,
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    isPendingUser: {
      fontStyle: "italic",
    },
    emailText: {
      fontSize: theme.typography.caption.fontSize,
    },
    categoryTitle: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: theme.typography.h6.fontSize,
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0, 1),
    },
  })
);

export function NotificationSettingsList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const selectedOption = useAppSelector(
    (state) => state.settingsState.selectedOption
  );
  const communityEventTypes = useAppSelector(
    (state) => state.settingsState.communityEventTypes
  );
  const communityEventConfigs = useAppSelector(
    (state) => state.settingsState.communityEventConfigs
  );

  const selectedEventType = useAppSelector(
    (state) => state.settingsState.selectedEventType
  );

  /* Methods */
  const handleSelectEventType = (eventTypeID: string) => {
    const eventTypeConfig = communityEventConfigs?.find(
      (configEvent) => configEvent.event_type === eventTypeID
    );

    if (
      eventTypeConfig !== undefined &&
      eventTypeConfig.community_id &&
      eventTypeConfig.event_type &&
      eventTypeConfig.id
    ) {
      const requestData = {
        communityID: eventTypeConfig.community_id,
        eventType: eventTypeConfig.event_type,
        eventConfigID: eventTypeConfig.id,
      };
      dispatch(loadEventConfig(requestData));
    }
  };

  const settingsCategories: { category: string; events: EventType[] }[] = [];

  communityEventTypes?.forEach((eventType) => {
    const categoryIndex = settingsCategories.findIndex(
      (item) => item.category === eventType.category
    );

    if (categoryIndex === -1) {
      settingsCategories.push({
        category: eventType.category ?? "",
        events: [eventType],
      });
    } else {
      settingsCategories[categoryIndex].events.push(eventType);
    }
  });
  const sortCategories = (
    settingsCategories: { category: string; events: EventType[] }[]
  ) => {
    const sortedArray = (JSON.parse(
      JSON.stringify(settingsCategories)
    ) as typeof settingsCategories).sort((a, b) =>
      sortAlphabetical(t(a.category), t(b.category))
    );

    sortedArray.forEach((category) => {
      category.events.sort((a, b) => {
        if (a.position === null) return 1;
        if (b.position === null) return -1;

        const posEventA = a.position;
        const posEventB = b.position;

        const i18EventA = t(i18textByEventType(a.type));
        const i18EventB = t(i18textByEventType(b.type));

        if (posEventA > posEventB) {
          return 1;
        }
        if (posEventA < posEventB) {
          return -1;
        }
        if (i18EventA > i18EventB) {
          return 1;
        }
        if (i18EventA < i18EventB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    });
    return sortedArray;
  };
  const sortedSettingCategories = sortCategories(settingsCategories);
  return (
    <React.Fragment>
      {selectedOption === SettingListKeys.notification_settings && (
        <div className={classes.notificationSettingsContainer}>
          {sortedSettingCategories.map((category) => {
            return (
              <List
                key={category.category}
                className={classes.optionsContainer}
              >
                <span className={classes.categoryTitle}>
                  {t(category.category).toUpperCase()}
                </span>
                {category.events.map((eventType) => {
                  const displayName =
                    i18textByEventType(eventType.type) !== "-"
                      ? t(i18textByEventType(eventType.type))
                      : eventType.name;
                  return (
                    <SettingsListItem
                      button
                      key={eventType.type}
                      divider
                      disableGutters
                      onClick={() => {
                        handleSelectEventType(eventType.type);
                      }}
                      selected={eventType.id === selectedEventType}
                      className={classes.listContainer}
                    >
                      <Typography variant="subtitle2">{displayName}</Typography>
                    </SettingsListItem>
                  );
                })}
              </List>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}
