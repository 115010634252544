import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import {
  getResidentCity,
  getResidentName,
  isHelpAtHome,
} from "../../dashboard/dashboardSlice";
import { IUnit } from "../../../services/dashboard.services";
import { FilterSearchField } from "../../notifications/filters/FilterSearchField";
import { ApplyFilterButton } from "../../notifications/filters/ApplyFilterButton";
import { GreenCheckbox } from "../../notifications/filters/GreenCheckBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContainer: {
      maxWidth: "300px",
      minWidth: "370px",
      maxHeight: "350px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      "&$selected:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
    },
    listLabel: {
      fontWeight: "bold",
      paddingRight: theme.spacing(0.5),
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    listIcon: {
      minWidth: "25px",
    },
    popoverHeader: {
      display: "flex",
    },
    selected: {},
  })
);

export const AccessibleUnitsPicker = (props: IProps) => {
  const { value, units, atLeastOne, handleChange } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();

  // Create controlled list options
  let defaultState: { [key: string]: boolean } = {};
  if (units !== undefined) {
    units.forEach((unit) => {
      if (value !== null) {
        defaultState[unit.id] = value.includes(unit.id);
      } else {
        defaultState[unit.id] = false;
      }
    });
  }

  /* ---- State ---- */
  const [filterSelection, setFilterSelection] = useState(defaultState);
  const [filteredOptions, setFilteredOptions] = useState<IUnit[]>([]);

  /* ---- Effects ---- */
  useEffect(() => {
    setFilteredOptions(units ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Obtain keys for searched for values
  const filteredOptionKeys = filteredOptions.map((option) => option.id);

  // Check if all searched for values are selected
  const allSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => filterSelection[val]);

  // Check if all searched for values are not selected
  const noneSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => !filterSelection[val]);

  /* ---- Methods ---- */
  const handleChangeSelection = (unitID: string) => {
    setFilterSelection({
      ...filterSelection,
      [unitID]: !filterSelection[unitID],
    });
  };

  const handleSelectAll = () => {
    setFilterSelection((prevState) => {
      const keysToUpdate = Object.keys(prevState).filter((option) =>
        filteredOptionKeys.includes(option)
      );
      const newState: {
        [key: string]: boolean;
      } = {};
      if (allSelected) {
        keysToUpdate.forEach((option) => (newState[option as string] = false));
      } else {
        keysToUpdate.forEach((option) => (newState[option as string] = true));
      }
      return { ...prevState, ...newState };
    });
  };

  const handleSearchField = (searchVal: string) => {
    const filteredArray = units?.filter((option) => {
      const isHelpAtHomeUnit = isHelpAtHome(option);
      const unitName = option ? option.name : "-";
      const resident = option
        ? getResidentName(option.residents, {
            fullFirstName: false,
            fullLastName: true,
          })
        : "-";
      const city = option ? getResidentCity(option.residents) : "-";

      if (isHelpAtHomeUnit) {
        return (
          resident.toLowerCase().includes(searchVal) ||
          city.toLowerCase().includes(searchVal)
        );
      } else {
        return (
          unitName.toLowerCase().includes(searchVal) ||
          resident.toLowerCase().includes(searchVal)
        );
      }
    });
    setFilteredOptions(filteredArray ?? []);
  };

  const handleApplyButton = () => {
    const values = Object.keys(filterSelection).filter(
      (key) => filterSelection[key]
    );

    handleChange(values);
  };

  const atLeastOneSelected = atLeastOne
    ? Object.values(filterSelection).some((val) => val)
    : undefined;
  return (
    <React.Fragment>
      <div className={classes.popoverHeader}>
        <FilterSearchField
          withApplyButton
          placeholder="search_resident"
          allSelected={allSelected}
          noneSelected={noneSelected}
          selectAllCheckbox
          handleSelectAll={handleSelectAll}
          handleSearchField={handleSearchField}
        />
        <ApplyFilterButton
          defaultOptions={defaultState}
          currentOptions={filterSelection}
          atLeastOne={atLeastOneSelected}
          onApplyFilter={handleApplyButton}
        />
      </div>
      <Divider />

      <List aria-label="unit list" className={classes.popoverContainer}>
        {filteredOptions.map((option) => {
          const isHelpAtHomeUnit = isHelpAtHome(option);
          const unitName = option ? option.name : "-";
          const resident = option
            ? getResidentName(option.residents, { fullLastName: true })
            : "-";
          const city = option ? getResidentCity(option.residents) : "-";

          const firstField = isHelpAtHomeUnit ? resident : unitName;
          const secondField = isHelpAtHomeUnit ? city : resident;

          return (
            <ListItem
              classes={{ root: classes.listRoot }}
              key={option.id}
              id={option.id}
              button
              onClick={() => handleChangeSelection(option.id)}
            >
              <ListItemIcon
                classes={{
                  root: classes.listIcon,
                }}
              >
                <GreenCheckbox
                  edge="start"
                  checked={filterSelection[option.id]}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={5}>
                      <div
                        className={clsx(
                          classes.listLabel,
                          classes.truncateEllipsis
                        )}
                      >
                        {firstField !== "" ? firstField : "-"}
                      </div>
                    </Grid>

                    <Grid item xs={7}>
                      <div
                        className={clsx(
                          classes.listLabel,
                          classes.truncateEllipsis
                        )}
                      >
                        {secondField !== "" ? secondField : "-"}
                      </div>
                    </Grid>
                  </Grid>
                }
                disableTypography
              />
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  value: string[] | null;
  units: IUnit[];
  atLeastOne: boolean;
  handleChange: (ids: string[]) => void;
}
