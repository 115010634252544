import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IEvent } from "../../services/dashboard.services";
import { useTranslation } from "react-i18next";
import { i18textByEventType } from "../../helpers/constants";
import { Box, Typography } from "@material-ui/core";
import { DateTimeLongFormat } from "../../helpers/datetime";
import { isHardEvent } from "./dashboardSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      maxHeight: "100px",
      margin: theme.spacing(1, 0),
    },
    critical: {
      color: theme.palette.error.contrastText,
    },
    warning: {
      color: theme.palette.warning.contrastText,
    },
    eventDetailsContainer: {
      display: "flex",
      justifyContent: "space-between",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    timeInformationContainer: {
      opacity: "0.8",
      display: "flex",
      fontFamily: "GothamRoundedBook",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "left",
      opacity: "0.8",
    },
  })
);

export default function NotificationHistoryItem(props: IProps) {
  const { notificationEvent, communityUnitTimezone } = props;
  const isHard = isHardEvent(notificationEvent);

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  // Determine notification event style
  const eventStatus: "critical" | "warning" | "" = isHard
    ? "critical"
    : "warning";

  // Determine tab type when clicked
  const eventStyle = classes[eventStatus];

  // Get event display text
  const eventType = t(i18textByEventType(notificationEvent.event_type));

  // Get formated creation time
  const formatedTime = DateTimeLongFormat(
    notificationEvent.time_created,
    communityUnitTimezone
  );

  return (
    <Box className={classes.rootContainer}>
      <Typography
        variant="body2"
        component="div"
        className={classes.eventDetailsContainer}
      >
        <div className={classes.timeInformationContainer}>
          <Box fontSize="caption">{formatedTime}</Box>
        </div>
      </Typography>
      <Box className={`${eventStyle} ${classes.titleContainer}`}>
        {eventType}
      </Box>
    </Box>
  );
}

interface IProps {
  notificationEvent: IEvent;
  communityUnitTimezone?: string;
}
