import React from "react";
import { useAppSelector } from "../../app/appHooks";
import { Popover } from "@material-ui/core";
import { RolePicker } from "./RolePicker";
import { NotificationGroupsPicker } from "./NotificationGroupsPicker";
import { AccessibleUnitsPicker } from "./AccessibleUnitsPicker";
import {
  CommunityTypes,
  RoleTypes,
  sortAlphabetical,
} from "../../../helpers/constants";
import { ICommunityGroup } from "../../../services/header.services";

export const CommunityUsersPopovers = (props: IProps) => {
  const {
    popoverID,
    open,
    anchorEl,
    onClose,
    userValues,
    handleRoleChange,
    handleGroupsChange,
    handleUnitsChange,
  } = props;

  const userRoles = useAppSelector((state) => {
    const communityType = state.headerState.selectedCommunity?.community_type;
    if (communityType === undefined) return undefined;
    if (state.headerState.userRoles === undefined) return undefined;

    if (communityType === CommunityTypes.pro) {
      // remove pro: from family
      return state.headerState.userRoles.filter(
        (role) => role.name !== RoleTypes.family
      );
    } else if (communityType === CommunityTypes.aip) {
      return state.headerState.userRoles.filter(
        (role) => role.name !== RoleTypes.pro_family
      );
    }
    return state.headerState.userRoles;
  });

  const communityGroups = useAppSelector((state) => {
    if (state.headerState.communityGroups !== undefined) {
      return JSON.parse(
        JSON.stringify(state.headerState.communityGroups)
      ) as ICommunityGroup[];
    }
    return state.headerState.communityGroups;
  })?.sort((a, b) => sortAlphabetical(a.name, b.name));

  const units = useAppSelector((state) => state.headerState.units);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{ zIndex: 1200 }}
    >
      <React.Fragment>
        {popoverID === "role" && (
          <RolePicker
            value={userValues.role}
            userRoles={userRoles}
            handleChange={handleRoleChange}
          />
        )}
        {popoverID === "notification_groups" && (
          <NotificationGroupsPicker
            value={userValues.notification_groups}
            communityGroups={communityGroups}
            handleChange={handleGroupsChange}
          />
        )}
        {popoverID === "accessible_units" && (
          <AccessibleUnitsPicker
            value={userValues.accessible_units}
            units={units ?? []}
            atLeastOne={userValues.role === RoleTypes.pro_family}
            handleChange={handleUnitsChange}
          />
        )}
        {popoverID === "" && null}
      </React.Fragment>
    </Popover>
  );
};

interface IProps {
  popoverID: string;
  open: boolean;
  anchorEl: any;
  onClose: () => void;
  userValues: {
    email?: string | null;
    role: string | null;
    notification_groups: string[];
    accessible_units: string[];
  };
  handleRoleChange: (value: string) => void;
  handleGroupsChange: (value: string[]) => void;
  handleUnitsChange: (value: string[]) => void;
}
