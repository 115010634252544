import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import EventComments from "../eventComments/EventComments";
import EventDetail from "../eventDetails/EventDetail";
import EventChart from "../charts/EventChart";
import PausedEventWorkflow from "../PausedEventWorkflow";
import { useSelector } from "react-redux";
import { IAppState } from "../../../helpers/store";
import { Views } from "../../../helpers/constants";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  containerFullHeight: {
    height: "100%",
    "&>*": {
      height: "100%",
    },
  },
  chartsContainer: {
    padding: theme.spacing(0, 3),
  },
}));

export default function TrayEvent(props: IProps) {
  const { eventID } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const location = useLocation();

  /* ---- Selectors ---- */
  const dashboardEvent = useSelector((state: IAppState) => {
    return state.dashboardState.communityEvents?.find(
      (event) => event.id === eventID
    );
  });
  const notificationsEvent = useSelector((state: IAppState) => {
    return state.notificationsState.notifications?.find(
      (event) => event.id === eventID
    );
  });

  let event = undefined;
  if (location.pathname === Views.DASHBOARD) {
    event = dashboardEvent;
  } else if (location.pathname === Views.NOTIFICATIONS) {
    event = notificationsEvent;
  }

  const pathname = location.pathname;

  return (
    <Grid container className={classes.containerFullHeight}>
      <Grid item xs={3}>
        <EventDetail event={event} />
        <PausedEventWorkflow event={event} layout={pathname} />
      </Grid>
      <Grid item xs={6} className={classes.chartsContainer}>
        <EventChart event={event} />
      </Grid>
      <Grid item xs={3}>
        <EventComments event={event} />
      </Grid>
    </Grid>
  );
}

interface IProps {
  eventID: string;
}
