import { IToken } from "../services/login.services";
import { StorageKeys } from "../services/constants";

export function saveAuthState(tokenObj: IToken | undefined) {
  try {
    if (tokenObj?.refresh_expiration) {
      localStorage.setItem(StorageKeys.USER, JSON.stringify(tokenObj));
    } else {
      localStorage.removeItem(StorageKeys.USER);
    }
  } catch (err) {
    return undefined;
  }
}
