import { useState, useEffect } from "react";
import { EventStatus, ReadStatus } from "../../../helpers/constants";
import { IEvent } from "../../../services/dashboard.services";
import { isHardEvent, isSoftEvent } from "../dashboardSlice";
import { isPausedEvent } from "../CommunityUnit";

function useEventTypesCount(events?: IEvent[], organization: boolean = false) {
  const [criticalEventsCount, setCriticalEventsCount] = useState<number>(0);
  const [warningEventsCount, setWarningEventsCount] = useState<number>(0);

  useEffect(() => {
    const criticalEvents =
      events &&
      events
        .filter((eventEl) => isHardEvent(eventEl))
        .filter((eventEl) =>
          organization ? organization : eventEl.status !== EventStatus.RESOLVED
        );

    let amountCriticalEvents = 0;
    if (criticalEvents !== undefined) {
      amountCriticalEvents = criticalEvents.filter(
        (eventEl) => !isPausedEvent(eventEl)
      ).length;
      setCriticalEventsCount(amountCriticalEvents);
    }
  }, [events, organization]);

  useEffect(() => {
    const warningEvents =
      events &&
      events
        .filter((eventEl) => isSoftEvent(eventEl))
        .filter((eventEl) =>
          organization
            ? organization
            : eventEl.read_status === ReadStatus.UNREAD
        );

    let amountWarningEvents = 0;
    if (warningEvents !== undefined) {
      amountWarningEvents = warningEvents.filter(
        (eventEl) => !isPausedEvent(eventEl)
      ).length;
      setWarningEventsCount(amountWarningEvents);
    }
  }, [events, organization]);

  return {
    criticalEventsCount,
    warningEventsCount,
  };
}

export default useEventTypesCount;
