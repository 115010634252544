import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ResetPasswordForm from "./ResetPasswordForm";
import { IAppState } from "../../helpers/store";
import { Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { LoginModes, resetPasswordInit } from "../login/loginSlice";
import { LeftPanel, MainContainer, RightPanel } from "./LoginLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginLink: {
      display: "block",
      cursor: "pointer",
      textDecoration: "underline",
    },
    resetTitle: {
      marginTop: "-50px",
    },
    bottomText: {
      textAlign: "center",
      position: "absolute",
      bottom: "16px",
      padding: theme.spacing(0, 2),
    },
  })
);

/**
 * Reset password component
 */

export default function ResetPassword() {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  /* ---- Selectors ---- */

  const passwordResetSteps = useSelector(
    (state: IAppState) => state.loginState.passwordResetSteps
  );

  const loginMode = useSelector((state: IAppState) => state.loginState.mode);

  const authParams = useSelector(
    (state: IAppState) => state.loginState.authParams
  );

  /* ---- Methods ---- */

  const handleGoBackToLogin = () => {
    dispatch(resetPasswordInit());
  };

  return (
    <MainContainer>
      <LeftPanel>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          className={classes.resetTitle}
        >
          {t("password_reset")}
        </Typography>
        <br />
        <Typography variant="body1" align="center">
          {passwordResetSteps === 0
            ? t("password_reset_subtitle")
            : passwordResetSteps === 1
            ? t("password_reset_subtitle3")
            : ""}
        </Typography>
        <br />
        <Typography variant="body1" align="center">
          {passwordResetSteps === 0
            ? t("password_reset_subtitle2")
            : passwordResetSteps === 1
            ? t("password_reset_subtitle4")
            : ""}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          className={classes.bottomText}
        >
          {loginMode !== LoginModes.ALEXA && t("already_have_account")}
          <Link
            className={classes.loginLink}
            onClick={handleGoBackToLogin}
            color="inherit"
            component={RouterLink}
            to={
              loginMode === LoginModes.ALEXA ? `/login${authParams}` : "/login"
            }
          >
            {loginMode === LoginModes.ALEXA
              ? t("continue_3rd_party_auth")
              : t("click_here_login")}
          </Link>
        </Typography>
      </LeftPanel>
      <RightPanel>
        <ResetPasswordForm />
      </RightPanel>
    </MainContainer>
  );
}
