import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/appHooks";
import { AccountCreationForm } from "./AccountCreationForm";
import { EmailVerificationForm } from "./EmailVerificationForm";
import { CreatePinForm } from "./CreatePinForm";
import { SelectCommunityForm } from "./SelectCommunityForm";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Link } from "@material-ui/core";
import { AccountCreationSteps, Views } from "../../../helpers/constants";
import { accountCreationInit } from "../loginSlice";
import { LeftPanel, MainContainer, RightPanel } from "../LoginLayout";
import { AccountCreationInstructions } from "./AccountCreationInstructions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitleContainer: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& img": {
        maxWidth: "100%",
        margin: "auto",
      },
    },
    leftTitleContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    stepTitle: {
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.primaryFontFamily,
      margin: 0,
      textAlign: "center",
      fontWeight: "bold",
      width: "100px",
    },
    bottomText: {
      textAlign: "center",
      position: "absolute",
      bottom: "48px",
      padding: theme.spacing(0, 2),
    },
    loginLink: {
      display: "block",
      cursor: "pointer",
      textDecoration: "underline",
    },
    sectionSubtitle: {
      textAlign: "center",
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.h5.fontWeight,
    },
    sectionHelpText: {
      textAlign: "center",
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.body1.fontWeight,
    },
  })
);

const AccountCreationStepper = (props: { step: number }) => {
  const { step } = props;

  const ComponentByStep = () => {
    switch (step) {
      case AccountCreationSteps.Instructions:
        return <AccountCreationInstructions />;
      case AccountCreationSteps.AccountCreation:
        return <AccountCreationForm />;
      case AccountCreationSteps.EmailVerification:
        return <EmailVerificationForm />;
      case AccountCreationSteps.CreatePin:
        return <CreatePinForm />;
      case AccountCreationSteps.SelectCommunity:
        return <SelectCommunityForm />;
      default:
        return null;
    }
  };
  return ComponentByStep();
};

/**
 * Account creation component
 */

export default function AccountCreation() {
  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  /* ---- Selectors ---- */
  const accountCreationStep = useAppSelector(
    (state) => state.loginState.accountCreationSteps
  );

  const isLogged = useAppSelector(
    (state) =>
      state.loginState.userToken !== undefined && state.loginState.isLogged
  );

  /* ---- Effects ---- */
  // Redirects the user to the dashboard if the try to access account creation while logged
  useEffect(() => {
    if (isLogged) {
      history.push(Views.DASHBOARD);
    }
  }, [isLogged, history]);

  /* ---- Methods ---- */
  const handleGoBackToLogin = () => {
    dispatch(accountCreationInit());
  };

  return (
    <MainContainer>
      <LeftPanel>
        <div className={classes.leftTitleContainer}>
          <h1 className={classes.stepTitle}>{t("account_creation")}</h1>

          <span className={classes.sectionSubtitle}>
            {`${t("step")} ${
              accountCreationStep - 1 > 0 ? accountCreationStep - 1 : 1
            }`}
          </span>
        </div>

        <div className={classes.subtitleContainer}>
          <p className={classes.sectionHelpText}>
            {accountCreationStep - 1 > 0
              ? t(`account_creation_helper_text${accountCreationStep - 1}`)
              : ""}
          </p>
        </div>
        <Typography
          variant="body2"
          component="div"
          className={classes.bottomText}
        >
          {t("already_have_account")}
          <Link
            className={classes.loginLink}
            onClick={handleGoBackToLogin}
            color="inherit"
            component={RouterLink}
            to={Views.LOGIN}
          >
            {t("click_here_login")}
          </Link>
        </Typography>
      </LeftPanel>
      <RightPanel>
        <AccountCreationStepper step={accountCreationStep} />
      </RightPanel>
    </MainContainer>
  );
}
