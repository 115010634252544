import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  EventStatus,
  EventTypes,
  EVENT_CARD_HEIGHT,
  i18textByEventType,
  iconByEventType,
  ReadStatus,
  TabTypes,
} from "../../helpers/constants";
import Grid from "@material-ui/core/Grid/Grid";
import { Box, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  DateTimeNow,
  DateTimeISO,
  DateTimeShortFormat,
} from "../../helpers/datetime";
import { detailsByEventType } from "./eventDetailHelpers";
import {
  getEventDataDashboard,
  getResidentName,
  isHelpAtHome,
} from "./dashboardSlice";
import Icons from "../../helpers/iconImports";
import { useAppSelector } from "../app/appHooks";
import useCommunityUnitTimezone from "./hooks/useCommunityUnitTimezone";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    criticalNotifications: {
      display: "flex",
      width: "100%",
      height: `${EVENT_CARD_HEIGHT}px`,
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(1),
      cursor: "pointer",
    },
    critical: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.error.main,
      border: `2px solid ${theme.palette.error.main}`,
    },
    criticalResolved: {
      color: theme.palette.paused.main,
      backgroundColor: theme.palette.error.lightTrans,
      border: `2px solid ${theme.palette.error.main}`,
    },
    warning: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.warning.main,
      border: `2px solid ${theme.palette.warning.main}`,
    },
    warningRead: {
      color: theme.palette.paused.main,
      backgroundColor: theme.palette.warning.lightTrans,
      border: `2px solid ${theme.palette.warning.main}`,
    },
    paused: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.paused.light,
      border: "none",
    },
    eventIcon: {
      display: "flex",
      margin: "auto",
      height: "30px",
    },
    eventContainer: {},
    eventDetailsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    iconContainer: {
      display: "flex",
      position: "relative",
      alignItems: "center",
    },
    timeInformationContainer: {
      display: "flex",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: "bold",
    },
    responder: {
      display: "flex",
      padding: theme.spacing(0.5),
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.light.light,
      color: theme.palette.primary.main,
      borderRadius: "5px",
      fontFamily: theme.typography.tertiaryFontFamily,
      fontSize: "smaller",
    },
    eventStatusIcon: {
      display: "flex",
      position: "absolute",
      bottom: "45%",
      right: "5px",
      height: "25px",
      fontSize: theme.typography.caption.fontSize,
    },
    statusImage: {
      filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.30))",
    },
    bold: {
      fontWeight: "bold",
    },
    caption: {
      fontSize: theme.typography.caption.fontSize,
    },
  })
);

const minDays = 3;
const maxDays = 999;

const calculateTimeLeft = (
  eventCreationTime: string,
  timeFormat: { year: string; day: string; hour: string; minute: string },
  communityUnitTimezone?: string
) => {
  const eventCreationDate = DateTimeISO(
    eventCreationTime,
    communityUnitTimezone
  );
  const currentTime = DateTimeNow(communityUnitTimezone);
  const timeDiff = currentTime.diff(eventCreationDate).toMillis();
  const elapsedTimeDays = timeDiff / (1000 * 60 * 60 * 24);
  // If elapsed time is greater than max days, then display as years
  if (elapsedTimeDays > maxDays) {
    const years = Math.floor(elapsedTimeDays / 365);
    return `${String(years)}${timeFormat.year}`;
  }
  // If elapsed time is between min days and max days, display as days
  else if (elapsedTimeDays > minDays && elapsedTimeDays <= maxDays) {
    const days = Math.floor(elapsedTimeDays);
    return `${String(days)}${timeFormat.day}`;
  }
  // If is less than min days, display as h:m
  else {
    let elapsedTimeHours = elapsedTimeDays * 24;
    // If there is at least one hour, calculate minutes and return hours and minutes
    if (elapsedTimeHours > 1) {
      elapsedTimeHours = Math.floor(elapsedTimeHours);
      return `${String(elapsedTimeHours)}${timeFormat.hour}`;
    }
    // If not, just convert to minutes
    else {
      const elapsedTimeMinutes = Math.floor(elapsedTimeHours * 60);
      return `${String(elapsedTimeMinutes)}${timeFormat.minute}`;
    }
  }
};

const isResolvedEvent = (status: string) => status === EventStatus.RESOLVED;

const isReadEvent = (readStatus: string | null) =>
  readStatus === ReadStatus.READ;

const isHardEvent = (notificationType: string | null) =>
  notificationType === "hard";
const isSoftEvent = (notificationType: string | null) =>
  notificationType === "soft";

export default function NotificationEvent(props: IProps) {
  const {
    id,
    unit_id,
    responder_id,
    notification_type,
    status,
    event_type,
    data,
    time_created,
    community_id,
    read_status,
    onClickOpenTab,
  } = props;
  const isHard = isHardEvent(notification_type);
  const isSoft = isSoftEvent(notification_type);
  const isResolved = isResolvedEvent(status);
  const isRead = isReadEvent(read_status);

  const isPaused = event_type === EventTypes.pause_notification;

  const communityID = community_id ?? undefined;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /* ---- Memos ---- */
  const memoizedTimeFormat = useMemo(() => {
    const timeFormat: {
      year: string;
      day: string;
      hour: string;
      minute: string;
    } = {
      year: t("year_short"),
      day: t("day_short"),
      hour: t("hour_short"),
      minute: t("minute_short"),
    };
    return timeFormat;
  }, [t]);

  const communityUnitTimezone = useCommunityUnitTimezone(communityID, unit_id);

  /* ---- State ---- */
  const [timer, setTimer] = useState(isHard);
  const [elapsedTime, setElapsedTime] = useState(
    isHard
      ? calculateTimeLeft(
          time_created,
          memoizedTimeFormat,
          communityUnitTimezone
        )
      : ""
  );

  /* ---- Selectors ---- */
  const unitID = unit_id;
  const eventUnit = useAppSelector((state) => {
    if (state.headerState.selectedCommunity !== undefined) {
      return state.dashboardState.units?.find((unit) => unit.id === unitID);
    }
    if (state.headerState.selectedOrganization !== undefined && community_id) {
      return state.dashboardState.orgUnits[community_id]?.find(
        (unit) => unit.id === unitID
      );
    }
    return undefined;
  });

  const responder = useAppSelector((state) =>
    state.headerState.users?.find((user) => user.id === responder_id)
  );

  /* ---- Effects ---- */
  // Set a time to update elapsed time
  useEffect(() => {
    if (isHard && timer) {
      const timeLeft = calculateTimeLeft(
        time_created,
        memoizedTimeFormat,
        communityUnitTimezone
      );
      setElapsedTime(timeLeft);
      setTimer(false);
    }
  }, [isHard, timer, time_created, memoizedTimeFormat, communityUnitTimezone]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimer(true);
    }, 30000);
    return () => clearTimeout(timer);
  });

  /* ---- Methods ---- */
  const handleOpenTab = (eventID: string, type: TabTypes) => {
    dispatch(getEventDataDashboard(eventID));
    onClickOpenTab(id, type);
  };

  // Determine if unit from which the event was generated is help at home

  const isHelpAtHomeUnit = isHelpAtHome(eventUnit);

  // Determine notification event style
  const eventStatus = isHard
    ? isPaused
      ? "paused"
      : isResolved
      ? "criticalResolved"
      : "critical"
    : isSoft
    ? isRead
      ? "warningRead"
      : "warning"
    : undefined;

  if (eventStatus === undefined) return null;

  const tabType = isHard
    ? isPaused
      ? TabTypes.PAUSED
      : TabTypes.CRITICAL
    : TabTypes.WARNING;
  // Determine tab type when clicked
  const eventStyle = classes[eventStatus];

  // Get icon to display
  const icon = iconByEventType(event_type !== null ? event_type : "");
  // Get event display text
  const eventType = t(i18textByEventType(event_type));

  // Get event details according to event type
  const eventDetails = detailsByEventType(
    event_type,
    data,
    time_created,
    communityUnitTimezone
  );

  // Get formated creation time
  const formatedTime = DateTimeShortFormat(time_created, communityUnitTimezone);

  // Get responder initials
  const isAssigned = status === EventStatus.ASSIGNED;

  let responderInitials = "";
  if ((isAssigned || isResolved) && responder !== undefined) {
    if (
      responder.first_name !== null &&
      responder.first_name !== undefined &&
      responder.first_name !== ""
    ) {
      // Get user full name or the first name if user does not have last name
      if (
        responder.last_name !== null &&
        responder.last_name !== undefined &&
        responder.last_name !== ""
      ) {
        const firstName = responder.first_name[0].slice(0, 1).toUpperCase();
        const lastName = responder.last_name[0].slice(0, 1).toUpperCase();
        responderInitials = `${firstName}${lastName}`;
      } else {
        const firstName = responder.first_name[0].slice(0, 1).toUpperCase();
        responderInitials = `${firstName}`;
      }
      // No name or last name information for user, use email instead, Uppercase first letter
    } else {
      responderInitials = responder.email[0].slice(0, 1).toUpperCase();
    }
  }
  // Determine which name for the unit should be displayed
  let unitDisplayName = "";
  if (eventUnit !== undefined) {
    if (isHelpAtHomeUnit) {
      unitDisplayName = getResidentName(eventUnit.residents, {
        fullFirstName: false,
      });
    } else {
      unitDisplayName = eventUnit.name;
    }
  }

  return (
    <div
      className={`${eventStyle} ${classes.criticalNotifications}`}
      id={`${eventStyle}-notification-${id}`}
      onClick={() => {
        handleOpenTab(id, tabType);
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid className={classes.iconContainer} item xs={2}>
          {icon !== "" && (
            <React.Fragment>
              <img
                src={icon}
                alt={t("alt_text_event_type_icon", { event_type: eventType })}
                draggable={false}
                className={classes.eventIcon}
              />
              {isHard && isAssigned && (
                <div className={classes.eventStatusIcon}>
                  <img
                    src={Icons.Assigned}
                    alt={t("alt_text_assigned_icon")}
                    draggable={false}
                    className={classes.statusImage}
                  />
                </div>
              )}
              {isHard && isResolved && (
                <div className={classes.eventStatusIcon}>
                  <img
                    src={Icons.Resolved}
                    alt={t("alt_text_resolved_icon")}
                    draggable={false}
                    className={classes.statusImage}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={10} className={classes.eventContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.bold}>{eventType}</div>
            {isHard && (isAssigned || isResolved) && responderInitials !== "" && (
              <Box boxShadow="1" className={classes.responder}>
                {responderInitials}
              </Box>
            )}
          </div>
          <div className={classes.bold}>
            {eventDetails ? eventDetails : "-"}
          </div>
          <Typography
            variant="body2"
            component="div"
            className={classes.eventDetailsContainer}
          >
            <div className={clsx(classes.bold, classes.caption)}>
              {unitDisplayName}
            </div>
            <div className={classes.timeInformationContainer}>
              {isHard && elapsedTime !== "" && (
                <Typography variant="caption">
                  <div className={clsx(classes.bold)}>{`(${elapsedTime})`}</div>
                </Typography>
              )}

              <div className={clsx(classes.bold, classes.caption)}>
                {`\u00A0 ${formatedTime}`}
              </div>
            </div>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

interface IProps {
  /*id
   * notification_type
   * status
   * read_status
   * community_id
   * time_created
   * unit_id
   * event_type
   * responder_id
   * */
  id: string;
  unit_id: string;
  event_type: string;
  responder_id: string | null;
  status: string;
  data: string;
  time_created: string;
  notification_type: string | null;
  community_id?: string | null;
  read_status: string | null;
  onClickOpenTab: (id: string, type: TabTypes) => void;
}
