import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppDispatch } from "../app/appHooks";
import { useAppSelector } from "../app/appHooks";
import { Typography } from "@material-ui/core";
import { getFirebaseDBReference } from "../../helpers/firebase";
import { setCommunityEvents, setResolvedEvents } from "./dashboardSlice";
import { getEventsToUpdateFirebase } from "../../helpers/utils";
import {
  RESOLVED_CLEANUP_FREQUENCY,
  RESOLVED_EVENT_WINDOW,
  sortAlphabetical,
  TabTypes,
} from "../../helpers/constants";
import { NotificationLayout } from "./NotificationLayout";
import logo from "../../assets/img/stackcare_logo.transparent.png";
import { useTranslation } from "react-i18next";
import { getPastTimestampUTC } from "../../helpers/datetime";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    communityCard: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.light.light,
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      borderRadius: "5px",
    },
    cardTitle: {
      display: "flex",
      width: "100%",
      cursor: "pointer",
    },
    disabledCard: {
      cursor: "default",
      pointerEvents: "none",
    },
    disabledButton: {
      color: theme.palette.action.disabled,
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    unitContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 220px)",
      gap: "15px",
      width: "100%",
      marginTop: "20px",
      justifyContent: "center",
    },
    logoContainer: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "220px",
    },
    logoImg: {
      maxWidth: "100%",
      height: "300px",
      opacity: 0.5,
    },
    emptyText: {
      opacity: 0.5,
    },
    notificationWrapper: {
      width: "220px",
      height: "140px",
    },
  })
);

export const NotificationCard = (props: IProps) => {
  const { addTab } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  /* ---- Selector ---- */
  const firebaseAuth = useAppSelector((state) => state.loginState.firebaseAuth);

  const communities = useAppSelector(
    (states) => states.headerState.communities
  );
  const allUnits = useAppSelector((state) => state.dashboardState.allUnits);
  const communityEvents = useAppSelector(
    (state) => state.dashboardState.communityEvents
  );
  const resolvedEvents = useAppSelector(
    (state) => state.dashboardState.resolvedEvents
  );
  const communityFilter = useAppSelector(
    (state) => state.dashboardState.communityFilter
  );
  const statusFilter = useAppSelector(
    (state) => state.dashboardState.statusFilter
  );

  const userFilter = useAppSelector((state) => state.dashboardState.userFilter);
  const communityIds = new Set(communityFilter);
  const statuses = new Set(statusFilter);
  const userIds = new Set(userFilter);

  const isCommunityFiltered = useAppSelector(
    (states) => states.dashboardState.isCommunityFiltered
  );
  const isUserFiltered = useAppSelector(
    (states) => states.dashboardState.isUserFiltered
  );
  const user = useAppSelector((state) => state.headerState.user);

  const allEvents = [...(communityEvents || []), ...(resolvedEvents || [])];
  const filteredEvents = allEvents
    ? allEvents.filter((event) => {
        if (!event.community_id) return false;

        const isResponderValid =
          event.responder_id === undefined ||
          event.responder_id === null ||
          userIds.has(event.responder_id);

        return (
          (!isCommunityFiltered || communityIds.has(event.community_id)) &&
          statuses.has(event.status) &&
          (!isUserFiltered || isResponderValid)
        );
      })
    : communityEvents || [];

  const sortedFilteredEvents = filteredEvents.sort((a, b) =>
    sortAlphabetical(b.time_created, a.time_created)
  );

  /* ---- Effects ---- */
  useEffect(() => {
    if (firebaseAuth && communities && user && user.roles) {
      let allCommunityEvents: any[] = [];
      let batchTimeout: any = null;
      const tempEvents: any[] = [];
      const communityIds = new Set();
      const unitIds = new Set();
      user.roles.forEach((role) => {
        if (role.role.all_units) {
          communityIds.add(role.role.community_id);
        } else {
          if (role.units) {
            role.units.forEach((unit) => {
              unitIds.add(unit.id);
            });
          }
        }
      });
      const listeners: any[] = [];
      communities.forEach(({ id: communityID }) => {
        const ref = getFirebaseDBReference()
          .ref(`communities/${communityID}/unresolved_events`)
          .orderByChild("time_created");
        const listener = ref.on("child_added", (snapshot) => {
          const event = snapshot.val();
          let records = getEventsToUpdateFirebase([event]);
          records = records.filter((record) => {
            const eventUnitID = record.unit_id;
            const eventCommunityID = record.community_id;
            return (
              communityIds.has(eventCommunityID) || unitIds.has(eventUnitID)
            );
          });

          if (records.length > 0) {
            tempEvents.push(...records);
            if (!batchTimeout) {
              batchTimeout = setTimeout(() => {
                dispatch(
                  setCommunityEvents([...allCommunityEvents, ...tempEvents])
                );
                tempEvents.length = 0;
                batchTimeout = null;
              }, 200);
            }
          }
        });
        listeners.push(listener);
      });

      return () => {
        listeners.forEach((listener, index) => {
          communities.forEach(({ id: communityID }) => {
            getFirebaseDBReference()
              .ref(`communities/${communityID}/unresolved_events`)
              .off("child_added", listener);
          });
        });

        if (batchTimeout) {
          clearTimeout(batchTimeout);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communities, firebaseAuth, user?.roles]);

  useEffect(() => {
    if (firebaseAuth && communities && user && user.roles) {
      let resolvedEvents: any[] = [];
      let batchTimeout: any = null;
      const tempEvents: any[] = [];
      const communityIds = new Set();
      const unitIds = new Set();
      user.roles.forEach((role) => {
        if (role.role.all_units) {
          communityIds.add(role.role.community_id);
        } else {
          if (role.units) {
            role.units.forEach((unit) => {
              unitIds.add(unit.id);
            });
          }
        }
      });

      const listeners: any[] = [];
      const startWindow = getPastTimestampUTC(RESOLVED_EVENT_WINDOW);
      communities.forEach(({ id: communityID }) => {
        const ref = getFirebaseDBReference()
          .ref(`communities/${communityID}/events`)
          .orderByChild("time_created")
          .startAt(startWindow);

        const listener = ref.on("child_added", (snapshot) => {
          const event = snapshot.val();
          let records = getEventsToUpdateFirebase([event]);
          records = records.filter((record) => {
            const eventUnitID = record.unit_id;
            const eventCommunityID = record.community_id;
            const hasAccess =
              communityIds.has(eventCommunityID) || unitIds.has(eventUnitID);
            const isResolved = record.status === "resolved";
            return hasAccess && isResolved;
          });
          if (records.length > 0) {
            tempEvents.push(...records);
            if (!batchTimeout) {
              batchTimeout = setTimeout(() => {
                resolvedEvents = [...resolvedEvents, ...tempEvents];
                dispatch(setResolvedEvents(resolvedEvents));
                tempEvents.length = 0;
                batchTimeout = null;
              }, 200);
            }
          }
        });
        listeners.push(listener);
      });

      return () => {
        listeners.forEach((listener, index) => {
          communities.forEach(({ id: communityID }) => {
            getFirebaseDBReference()
              .ref(`communities/${communityID}/events`)
              .off("child_added", listener);
          });
        });

        if (batchTimeout) {
          clearTimeout(batchTimeout);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communities, firebaseAuth, user?.roles]);

  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      if (resolvedEvents) {
        const startWindow = getPastTimestampUTC(RESOLVED_EVENT_WINDOW);
        const updatedResolvedEvents = resolvedEvents.filter((event) => {
          return event.time_created > startWindow;
        });
        dispatch(setResolvedEvents(updatedResolvedEvents));
      }
    }, RESOLVED_CLEANUP_FREQUENCY);

    return () => clearInterval(cleanupInterval);
  }, [resolvedEvents, dispatch]);

  return (
    <div>
      <div className={classes.unitContainer}>
        {sortedFilteredEvents !== undefined &&
          sortedFilteredEvents.length > 0 &&
          sortedFilteredEvents.map((event) => {
            const unit = allUnits[event.unit_id];
            if (!unit || !unit.residents) return null;
            return (
              <div key={event.id} className={classes.notificationWrapper}>
                <NotificationLayout
                  event={event}
                  unit={unit}
                  onClickOpenTab={addTab}
                />
              </div>
            );
          })}
      </div>
      {(communityEvents === undefined ||
        communityEvents.length === 0 ||
        filteredEvents.length === 0) && (
        <div className={classes.logoContainer}>
          <img
            src={logo}
            alt="StackCare Logo"
            className={classes.logoImg}
            draggable={false}
          />
          <Typography className={classes.emptyText}>
            {communityEvents && communityEvents.length > 0
              ? t("no_filtered_active_notifications")
              : t("no_active_notifications")}
          </Typography>
        </div>
      )}
    </div>
  );
};

interface IProps {
  addTab: (id: string, type: TabTypes) => void;
}
