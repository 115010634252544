import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  Input,
  Paper,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countries from "i18n-iso-countries";
import timezones from "timezones-list";
import {
  NAME_CHARACTER_LIMIT,
  ReducerStates,
  sortAlphabetical,
  SupportedLanguages,
  Temperature,
  TimeFormat,
} from "../../helpers/constants";
import { useAppSelector } from "../app/appHooks";
import { IUserDetails } from "../../services/header.services";
import { useDispatch } from "react-redux";
import {
  IPasswordChangeData,
  IProfileData,
} from "../../services/settings.services";
import { submitMyProfileChanges } from "../app/asyncThunkActions";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { ErrorCodes } from "../../services/constants";
import { passwordValidation } from "../../helpers/utils";
import { changePassword, resetSliceState } from "../login/loginSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    myProfileContainer: {
      padding: theme.spacing(6, 7),
      width: "550px",
      overflow: "auto",
      maxHeight: "100vh",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(4),
    },
    sectionTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(3),
      textAlign: "center",
    },
    inputControl: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      listStyle: "none",
      padding: 0,
      borderRadius: "3px",
    },
    formRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: ".5em",
      "& > label": {
        padding: ".5em 1em .5em 0",
        flex: " 1",
        textAlign: "right",
        color: theme.palette.paused.main,
      },
    },
    rowInput: {
      flex: "1.5",
    },
    buttonRoot: {
      fontFamily: theme.typography.secondaryFontFamily,
      fontSize: "1.1rem !important",
      minWidth: "120px",
    },
    flagIcon: {
      marginRight: theme.spacing(0.5),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    subheaderRoot: {
      lineHeight: "16px",
    },
    changePasswordButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    gridContainer: {
      alignItems: "center",
      gap: theme.spacing(2),
    },
    changePasswordLabel: {
      textAlign: "right",
    },
    passwordText: {
      fontSize: "0.80rem",
      paddingBottom: "6px",
    },
    changePasswordBox: {
      display: "block",
      padding: "0",
      marginTop: theme.spacing(2),
    },
    textField: {
      minHeight: "52px",
    },
    helperText: {
      fontSize: "0.60rem",
    },
    accordionHeader: {
      borderRadius: "0",
    },
    accordionHeaderText: {
      fontWeight: "bold",
    },
  })
);

const Accordion = withStyles({
  root: {
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
      marginLeft: 2,
      marginRight: 2,
      height: "fit-content",
      marginBottom: 20,
    },
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontWeight: "bold",
    marginBottom: 20,
    marginLeft: 2,
    marginRight: 2,
    boxShadow: "none",
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 60,
    "&$expanded": {
      minHeight: 60,
    },
    alignItems: "baseline",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    borderBottom: "1px solid #ccc",
  },
}))(MuiAccordionDetails);

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

interface OptionsType {
  id: string;
  label: string;
  groupBy?: string;
}

const InputRow = (props: {
  inputKey: string;
  value: string | OptionsType | null;
  select?: boolean;
  required?: boolean;
  options?: OptionsType[];
  onChange?: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;

  onChangeSelect?: (key: string, value: OptionsType | null) => void;
}) => {
  const {
    inputKey,
    value,
    select,
    options,
    required,
    onChange,
    onChangeSelect,
  } = props;
  /* Hooks */
  const classes = useStyles();

  const { t } = useTranslation();
  const hasGroupBy = options?.every((option) => option.groupBy);
  const inputProps: { [key: string]: any } = {};
  if (inputKey === "first_name" || inputKey === "last_name") {
    inputProps["maxLength"] = NAME_CHARACTER_LIMIT;
  }
  return (
    <li className={classes.formRow}>
      <label htmlFor={inputKey}>{`${t(inputKey).toUpperCase()}${
        required ? "*" : ""
      }`}</label>
      {select ? (
        <Autocomplete
          options={options ?? []}
          id={`dropdown-${inputKey}`}
          disableClearable
          noOptionsText={t("no_options")}
          groupBy={hasGroupBy ? (option) => option.groupBy ?? "" : undefined}
          getOptionLabel={(option: OptionsType) =>
            options?.find((_option) => _option.id === option.id)?.label ?? ""
          }
          value={value as OptionsType | undefined}
          onChange={(_, val) => onChangeSelect && onChangeSelect(inputKey, val)}
          getOptionSelected={(option, value) => option.id === value.id}
          blurOnSelect
          autoHighlight
          renderOption={(option) => (
            <React.Fragment>
              {inputKey === "country" && (
                <span className={classes.flagIcon}>
                  {countryToFlag(option.id)}
                </span>
              )}
              {option.label}
            </React.Fragment>
          )}
          classes={{
            root: classes.rowInput,
            groupLabel:
              inputKey === "country" ? classes.subheaderRoot : undefined,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={value === null ? t("select_an_option") : ""}
            />
          )}
        />
      ) : (
        <Input
          classes={{ root: classes.rowInput }}
          id={inputKey}
          readOnly={inputKey === "email"}
          disableUnderline={inputKey === "email"}
          error={required ? value === null || value === "" : false}
          value={value !== null ? (value as string) : ""}
          onChange={onChange && onChange(inputKey)}
          inputProps={inputProps}
        />
      )}
    </li>
  );
};

interface FormState {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  country: OptionsType | null;
  language: OptionsType | null;
  time_zone: OptionsType | null;
  temperature_unit: OptionsType | null;
  is_twenty_four_time: OptionsType | null;
}

export function MyProfile() {
  /* Hooks */
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [countryListFormmatted, setCountryListFormatted] = useState<
    OptionsType[]
  >([]);
  const [supportedLanguages, setSupportedLanguages] = useState<OptionsType[]>(
    []
  );
  const [temperature, setTemperature] = useState<OptionsType[]>([]);
  const [timeFormat, setTimeFormat] = useState<OptionsType[]>([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [codeError, setCodeError] = useState("");

  /* Selectors */
  const user = useAppSelector((state) => state.headerState.user);
  const errorCode = useAppSelector((state) => state.loginState.errorCode);
  const email = useAppSelector((state) => state.headerState.user?.email);
  const loginState = useAppSelector((state) => state.loginState.state);

  const timezoneFormated: OptionsType[] = timezones.map((timezone) => ({
    id: timezone.tzCode,
    label: `${timezone.tzCode}`,
    groupBy: `GMT${timezone.utc}`,
  }));

  /* State */
  const [profileValues, setProfileValues] = useState<FormState>({
    first_name: null,
    last_name: null,
    email: null,
    country: null,
    language: null,
    time_zone: null,
    temperature_unit: null,
    is_twenty_four_time: null,
  });

  /* Hooks */
  useEffect(() => {
    if (user !== undefined) {
      let countryOption: OptionsType | null = null;
      if (user.country) {
        countryOption =
          countryListFormmatted.find(
            (countryOption) => countryOption.id === user.country
          ) ?? null;
      }

      let languageOption: OptionsType | null = null;
      if (user.language) {
        languageOption =
          supportedLanguages.find(
            (languageOption) => languageOption.id === user.language
          ) ?? null;
      }

      let temperatureUnitOption: OptionsType | null = null;
      if (user.temperature_unit !== null) {
        temperatureUnitOption =
          temperature.find(
            (temperature_unit) => temperature_unit.id === user.temperature_unit
          ) ?? null;
      }
      let timezoneOption: OptionsType | null = null;
      if (user.time_zone !== null) {
        timezoneOption =
          timezoneFormated.find(
            (time_zone) => time_zone.id === user.time_zone
          ) ?? null;
      }

      let twentyFourOption: OptionsType | null = null;
      if (user.is_twenty_four_time !== null) {
        const option = user.is_twenty_four_time
          ? TimeFormat.TIME24H
          : TimeFormat.TIME12H;
        twentyFourOption =
          timeFormat.find((timeFormat) => timeFormat.id === option) ?? null;
      }

      setProfileValues({
        ...profileValues,
        first_name: user.first_name ?? null,
        last_name: user.last_name ?? null,
        email: user.email,
        country: countryOption,
        language: languageOption,
        time_zone: timezoneOption,
        temperature_unit: temperatureUnitOption,
        is_twenty_four_time: twentyFourOption,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, countryListFormmatted]);

  useEffect(() => {
    if (ErrorCodes.hasOwnProperty(errorCode)) {
      switch (ErrorCodes[errorCode]) {
        case ErrorCodes.INVALID_EMAIL_PASSWORD:
          setCodeError(t("current_password_is_incorrect"));
          break;
        default:
          setCodeError("");
          break;
      }
    }
  }, [errorCode, t, dispatch]);

  useEffect(() => {
    const loadLanguage =
      countries.langs().find((item) => item === i18n.language) ?? "en";

    const countryList = countries.getNames(loadLanguage, {
      select: "official",
    });

    const countryListFormated: OptionsType[] = Object.keys(countryList).map(
      (_key) => ({
        id: _key,
        label: countryList[_key],
        groupBy: "\u00a0",
      })
    );

    const extra = countryListFormated
      .filter(
        (country) =>
          country.id === "US" || country.id === "GB" || country.id === "CA"
      )
      .map((_option) => ({ ..._option, groupBy: "\u2000" }))
      .sort((a, b) => sortAlphabetical(a.label, b.label));

    countryListFormated.unshift(...extra);

    const supportedLanguages = SupportedLanguages.map((lang) => ({
      id: lang.id,
      label: t(lang.label),
    })).sort((a, b) => sortAlphabetical(a.label, b.label));

    const temperature = [
      { id: Temperature.CELSIUS, label: "Celsius (°C)" },
      { id: Temperature.FAHRENHEIT, label: "Fahrenheit (°F)" },
    ].sort((a, b) => sortAlphabetical(a.label, b.label));
    const timeFormat = [
      { id: TimeFormat.TIME24H, label: t("24h_label") },
      { id: TimeFormat.TIME12H, label: t("12h_label") },
    ].sort((a, b) => sortAlphabetical(a.label, b.label));

    setCountryListFormatted(countryListFormated);
    setSupportedLanguages(supportedLanguages);
    setTemperature(temperature);
    setTimeFormat(timeFormat);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (ReducerStates[loginState] === ReducerStates.SUCCEEDED) {
      setExpanded(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setCodeError("");
      setNewPasswordError("");
      setConfirmPasswordError("");
      dispatch(resetSliceState);
    }
  }, [dispatch, loginState]);

  const checkFormChange = (newVals: FormState, oldVals?: IUserDetails) => {
    if (oldVals === undefined) return false;

    let formChanged = false;

    if (
      oldVals.first_name !== null &&
      newVals.first_name !== null &&
      oldVals.first_name !== newVals.first_name
    ) {
      formChanged = true;
    }

    if (
      oldVals.last_name !== null &&
      newVals.last_name !== null &&
      oldVals.last_name !== newVals.last_name
    ) {
      formChanged = true;
    }

    if (oldVals.country === null && newVals.country?.id !== null) {
      formChanged = true;
    }
    if (newVals.country !== null && oldVals.country !== newVals.country?.id) {
      formChanged = true;
    }
    if (oldVals.language === null && newVals.language !== null) {
      formChanged = true;
    }
    if (
      newVals.language !== null &&
      oldVals.language !== newVals.language?.id
    ) {
      formChanged = true;
    }
    if (oldVals.time_zone === null && newVals.time_zone !== null) {
      formChanged = true;
    }
    if (
      newVals.time_zone !== null &&
      oldVals.time_zone !== newVals.time_zone?.id
    ) {
      formChanged = true;
    }
    if (
      oldVals.temperature_unit === null &&
      newVals.temperature_unit !== null
    ) {
      formChanged = true;
    }
    if (
      newVals.temperature_unit !== null &&
      oldVals.temperature_unit !== newVals.temperature_unit?.id
    ) {
      formChanged = true;
    }
    const twentyFourOptionOld =
      oldVals.is_twenty_four_time !== null
        ? oldVals.is_twenty_four_time
          ? TimeFormat.TIME24H
          : TimeFormat.TIME12H
        : null;
    const twentyFourOptionNew =
      newVals.is_twenty_four_time !== null
        ? newVals.is_twenty_four_time.id
        : null;

    if (twentyFourOptionOld === null && twentyFourOptionNew !== null) {
      formChanged = true;
    }
    if (
      twentyFourOptionNew !== null &&
      twentyFourOptionOld !== twentyFourOptionNew
    ) {
      formChanged = true;
    }

    return formChanged;
  };

  const checkNullValues = (newVals: FormState, oldVals?: IUserDetails) => {
    if (oldVals === undefined) return false;

    let nullValues = false;

    if (
      oldVals.first_name !== null &&
      (newVals.first_name === null ||
        newVals.first_name === "" ||
        newVals.first_name.trim() === "")
    ) {
      nullValues = true;
    }

    if (
      oldVals.last_name !== null &&
      (newVals.last_name === null ||
        newVals.last_name === "" ||
        newVals.last_name.trim() === "")
    ) {
      nullValues = true;
    }

    if (oldVals.country !== null && newVals.country === null) {
      nullValues = true;
    }

    const oldLanguageLocal =
      supportedLanguages.find(
        (languageOption) => languageOption.id === oldVals.language
      )?.id ?? null;
    if (oldLanguageLocal !== null && newVals.language === null) {
      nullValues = true;
    }
    if (oldVals.time_zone !== null && newVals.time_zone === null) {
      nullValues = true;
    }
    if (
      oldVals.temperature_unit !== null &&
      newVals.temperature_unit === null
    ) {
      nullValues = true;
    }
    const twentyFourOptionOld =
      oldVals.is_twenty_four_time !== null
        ? oldVals.is_twenty_four_time
          ? TimeFormat.TIME24H
          : TimeFormat.TIME12H
        : null;
    const twentyFourOptionNew =
      newVals.is_twenty_four_time !== null
        ? newVals.is_twenty_four_time.id
        : null;

    if (twentyFourOptionOld !== null && twentyFourOptionNew === null) {
      nullValues = true;
    }

    return nullValues;
  };

  /* Methods */

  const isTwentyFourHourOption = (val: OptionsType) =>
    String(val.id === TimeFormat.TIME24H);

  const handleChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProfileValues((prevState) => ({
      ...prevState,
      [key]: event.target.value.trimStart(),
    }));
  };

  const handleChangeDropdown = (key: string, value: OptionsType | null) => {
    setProfileValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const myProfileData: IProfileData = {};
    Object.keys(profileValues).forEach((item) => {
      const val = profileValues[item as keyof typeof profileValues];
      if (val !== null && user) {
        if (
          typeof val === "string" &&
          val !== user[item as keyof typeof user]
        ) {
          myProfileData[item as keyof typeof myProfileData] = val;
        } else if (
          typeof val !== "string" &&
          val.id !== user[item as keyof typeof user]
        ) {
          if (item === "is_twenty_four_time") {
            myProfileData[
              item as keyof typeof myProfileData
            ] = isTwentyFourHourOption(val);
          } else {
            myProfileData[item as keyof typeof myProfileData] = val.id;
          }
        }
      }
    });
    dispatch(submitMyProfileChanges(myProfileData));
  };

  const handleSubmitPasswordChange = (
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    if (email) {
      const passwordChangeData: IPasswordChangeData = {
        email,
        oldPassword,
        newPassword,
      };
      dispatch(changePassword(passwordChangeData));
    }
  };

  const validatePasswordFields = () => {
    const validationMessage = passwordValidation(newPassword);
    if (validationMessage !== "") {
      setNewPasswordError(t(validationMessage));
    } else {
      setNewPasswordError("");
    }

    const confirmValidationMessage = passwordValidation(
      newPassword,
      confirmPassword
    );
    if (
      confirmValidationMessage !== "" &&
      confirmValidationMessage !== "password_requirements"
    ) {
      setConfirmPasswordError(t(confirmValidationMessage));
    } else {
      setConfirmPasswordError("");
    }
  };

  const enableSubmit =
    checkFormChange(profileValues, user) &&
    !checkNullValues(profileValues, user);

  const enableChangePasswordSubmit =
    !oldPassword ||
    !newPassword ||
    !confirmPassword ||
    newPasswordError !== "" ||
    confirmPasswordError !== "";

  return (
    <Paper className={classes.myProfileContainer} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("my_profile")}</h2>
      <form
        id="login-form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className={classes.formContainer}
      >
        <ul className={classes.wrapper}>
          <InputRow
            inputKey="first_name"
            value={profileValues.first_name}
            required
            onChange={handleChange}
          />
          <InputRow
            inputKey="last_name"
            value={profileValues.last_name}
            required
            onChange={handleChange}
          />
          <InputRow
            inputKey="email"
            value={profileValues.email}
            onChange={handleChange}
          />
          <InputRow
            inputKey="country"
            value={profileValues.country}
            select
            options={countryListFormmatted}
            onChangeSelect={handleChangeDropdown}
          />
          <InputRow
            inputKey="language"
            value={profileValues.language}
            select
            options={supportedLanguages}
            onChangeSelect={handleChangeDropdown}
          />
          <InputRow
            inputKey="time_zone"
            value={profileValues.time_zone}
            select
            options={timezoneFormated}
            onChangeSelect={handleChangeDropdown}
          />
          <InputRow
            inputKey="temperature_unit"
            value={profileValues.temperature_unit}
            select
            options={temperature}
            onChangeSelect={handleChangeDropdown}
          />
          <InputRow
            inputKey="is_twenty_four_time"
            value={profileValues.is_twenty_four_time}
            select
            options={timeFormat}
            onChangeSelect={handleChangeDropdown}
          />
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            variant={!enableSubmit ? "outlined" : "contained"}
            color="primary"
            disabled={!enableSubmit}
            classes={{
              root: classes.buttonRoot,
            }}
          >
            {t("save")}
          </Button>
        </div>
      </form>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="change-password-content"
          id="change-password-header"
          className={classes.accordionHeader}
        >
          <Typography className={classes.accordionHeaderText}>
            {t("change_password")}
          </Typography>
        </AccordionSummary>
        <form
          id="change-password-form"
          autoComplete="off"
          onSubmit={handleSubmitPasswordChange}
        >
          <AccordionDetails className={classes.changePasswordBox}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={5} className={classes.changePasswordLabel}>
                <Typography className={classes.passwordText}>
                  {t("current_password").toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  type="password"
                  variant="standard"
                  fullWidth
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  onFocus={() => setCodeError("")}
                  FormHelperTextProps={{ className: classes.helperText }}
                  error={codeError !== ""}
                  helperText={codeError}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={5} className={classes.changePasswordLabel}>
                <Typography className={classes.passwordText}>
                  {t("new_password").toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  type="password"
                  variant="standard"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onFocus={() => setCodeError("")}
                  onBlur={validatePasswordFields}
                  FormHelperTextProps={{ className: classes.helperText }}
                  error={newPasswordError !== ""}
                  helperText={newPasswordError}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={5} className={classes.changePasswordLabel}>
                <Typography className={classes.passwordText}>
                  {t("confirm_password").toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  type="password"
                  variant="standard"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onFocus={() => setCodeError("")}
                  onBlur={validatePasswordFields}
                  FormHelperTextProps={{ className: classes.helperText }}
                  error={confirmPasswordError !== ""}
                  helperText={confirmPasswordError}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
          <div className={classes.changePasswordButtonContainer}>
            <Button
              onClick={() => {
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
                setCodeError("");
                setNewPasswordError("");
                setConfirmPasswordError("");
                setExpanded(false); // Add this line to close the accordion
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={enableChangePasswordSubmit}
            >
              {t("save")}
            </Button>
          </div>
        </form>
      </Accordion>
    </Paper>
  );
}
