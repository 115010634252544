import React from "react";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";
import useTheme from "@material-ui/core/styles/useTheme";
import Box from "@material-ui/core/Box/Box";
import { FormHelperText, Typography } from "@material-ui/core";

const PinValidation = (props: IProps) => {
  const { onChange, error, label } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  // Methods
  const _onChange = (value: string) => {
    onChange(value);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      margin="8px"
    >
      {label !== undefined && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="8px"
        >
          <Typography align="center" variant="subtitle1">{`${
            label === "" ? t("enter_pin") : ""
          }`}</Typography>
        </Box>
      )}
      <PinInput
        length={4}
        initialValue=""
        secret
        type="numeric"
        inputMode="number"
        style={{ margin: "auto" }}
        inputStyle={{
          width: "40px",
          height: "40px",
          margin: "0px 10px",
          ...(error !== undefined &&
            error !== "" && {
              border: `1px solid ${theme.palette.error.main}`,
            }),
        }}
        inputFocusStyle={{
          border: `1px solid ${theme.palette.primary.main}`,
        }}
        onChange={_onChange}
      />
      {error !== undefined && (
        <FormHelperText
          style={{
            minHeight: "20px",
            marginTop: "6px",
            marginBottom: "6px",
          }}
          error={error !== ""}
        >
          {error !== "" ? t(error) : ""}
        </FormHelperText>
      )}
    </Box>
  );
};

interface IProps {
  label?: string;
  error?: string;
  onChange: (value: string) => void;
}

export default PinValidation;
