import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { IBathroomEventArray } from "../eventDataTypes";
import { DateFromIsoString } from "../../../helpers/datetime";
import StackedBarChart, { IDateVisitValue } from "../../common/StackedBarChart";
import { IEvent } from "../../../services/dashboard.services";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";
import { LayoutChartContainer } from "./LayoutChartContainer";
import {
  isDayOutlier,
  isTotalOutlier,
  isNightOutlier,
} from "../../analytics/charts/UnitBathroomChart";

export default function BathroomVisitCountChart(props: IProps) {
  const { event } = props;
  const communityID = event.community_id ?? undefined;

  /* Hooks */
  const { t } = useTranslation();
  const communityTimezone = useCommunityUnitTimezone(
    communityID,
    event.unit_id
  );

  const valuesDay: number[] = [];
  const valuesNight: number[] = [];
  const valuesTotals: IDateVisitValue[] = [];
  const dayOutliers: boolean[] = [];
  const nightOutliers: boolean[] = [];
  const labels: (string | string[])[] = [];
  const eventData = JSON.parse(event?.data || "") as IBathroomEventArray;
  let max = 0;
  const data = eventData.data.sort((a, b) => {
    const time1 = DateFromIsoString(a.date, communityTimezone).millisecond;
    const time2 = DateFromIsoString(b.date, communityTimezone).millisecond;
    return time1 - time2;
  });

  data.map((item, index) => {
    const totalOutlier = isTotalOutlier(item);
    const dayOutlier = isDayOutlier(item);
    const nightOutlier = isNightOutlier(item);
    valuesDay.push(item.day?.visits || 0);
    valuesNight.push(item.night?.visits || 0);
    valuesTotals.push({
      visitCount: (item.day?.visits || 0) + (item.night?.visits || 0),
      isOutlier: item.total ? totalOutlier : false,
    });
    dayOutliers.push(item.day ? dayOutlier : false);
    nightOutliers.push(item.night ? nightOutlier : false);
    const dateObj = DateTime.fromISO(item.date);
    labels.push([dateObj.toFormat("EEE"), dateObj.toFormat("dd")]);
    max = Math.max(max, (item.day?.visits || 0) + (item.night?.visits || 0));
    return index;
  });
  max = Math.floor((max + 10) / 10) * 10;

  return (
    <LayoutChartContainer
      title={t("recently").toUpperCase()}
      unitID={event.unit_id}
      eventTimeCreated={event.time_created}
      eventType={event.event_type}
      emptyData={labels.length === 0}
    >
      <StackedBarChart
        labels={labels}
        valuesDay={valuesDay}
        valuesNight={valuesNight}
        dayOutliers={dayOutliers}
        nightOutliers={nightOutliers}
        valuesTotals={valuesTotals}
        max={max}
      />
    </LayoutChartContainer>
  );
}

interface IProps {
  event: IEvent;
}
