import { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { i18textByEventType } from "../../helpers/constants";
import { useAppSelector } from "../app/appHooks";
import { EventConfigForm } from "./EventConfigForm";
import { UnitsEventConfigList } from "./UnitsEventConfigList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    titleContainer: {
      padding: theme.spacing(2, 0),
    },
    title: {
      fontFamily: theme.typography.secondaryFontFamily,
    },
    templateContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: theme.spacing(2),
    },
    firstSection: {
      flexBasis: "30%",
      fontWeight: "bold",
    },
  })
);

export const NotificationSettings = () => {
  /* Hooks */
  const { t } = useTranslation();

  /* State */
  const [heightList, setHeightList] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();

  /* Selectors */
  const selectedEventType = useAppSelector(
    (state) => state.settingsState.selectedEventType
  );

  /* Refs */
  const ref = useRef<HTMLDivElement | null>(null);
  const templateRef = useRef<HTMLDivElement | null>(null);

  /* Effects */
  useEffect(() => {
    // Set unit list height (if it expands, should be less)
    setTimeout(() => {
      // do something 1 sec after clicked has changed
      const parentEl = ref.current;
      const parentHeight = parentEl?.clientHeight;
      const templateHeight = templateRef.current?.clientHeight;

      if (parentHeight && templateHeight) {
        setHeightList(parentHeight - templateHeight);
      }
    }, 300);
  }, [selectedEventType, expanded]);

  const templateTitle = `${t("event_template", {
    eventType: t(i18textByEventType(selectedEventType ?? "")),
  })} `;

  return (
    <div id="content-container" ref={ref} className={classes.contentContainer}>
      {selectedEventType !== undefined && (
        <>
          <div
            ref={templateRef}
            id="template-container"
            className={classes.templateContainer}
          >
            <div className={classes.titleContainer}>
              <Typography className={classes.title} component="span">
                {templateTitle}
              </Typography>
              <Typography component="span">{t("default_new_units")}</Typography>
            </div>

            <EventConfigForm
              eventType={selectedEventType}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </div>
          {heightList !== 0 && (
            <UnitsEventConfigList
              eventType={selectedEventType}
              expanded={expanded}
              height={heightList}
            />
          )}
        </>
      )}
    </div>
  );
};
