import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { colorByTabType } from "./EventTab";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) => ({
  labelContainer: {
    width: "100%",
    margin: theme.spacing(0, 1.5, 0, 0),
    position: "relative",
    background: (props) => {
      return colorByTabType(props.tabType, props.selected, theme);
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: 0,
      right: "-36px",
      width: "30%",
      height: "100%",
      backgroundImage: (props) => {
        return `linear-gradient(90deg, ${colorByTabType(
          props.tabType,
          props.selected,
          theme
        )}00 0%, ${colorByTabType(
          props.tabType,
          props.selected,
          theme
        )} 70%, ${colorByTabType(props.tabType, props.selected, theme)} 100%)`;
      },

      pointerEvents: "none",
    },
  },
  eventIcon: {
    display: "flex",
    marginRight: theme.spacing(1),
    height: "27px",
  },
  primaryText: {
    fontFamily: theme.typography.secondaryFontFamily,
    whiteSpace: "nowrap",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

export const TabLabel = (props: IProps) => {
  const classes = useStyles(props);
  return (
    <React.Fragment>
      <Box
        className={classes.labelContainer}
        display="flex"
        alignItems="center"
      >
        {props.icon !== "" && (
          <img
            alt="event-type icon"
            src={props.icon}
            draggable={false}
            className={classes.eventIcon}
          />
        )}
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          <Box fontWeight="fontWeightBold" className={classes.primaryText}>
            {props.primaryText}
          </Box>
          <Typography variant="subtitle2" className={classes.noWrap}>
            <Box>{props.secondaryText}</Box>
          </Typography>
        </Box>
      </Box>
      {props.closeIcon}
    </React.Fragment>
  );
};

interface IProps {
  primaryText: string;
  secondaryText: string;
  tabType: string;
  selected: boolean;
  icon: any;
  closeIcon: React.ReactNode;
}
