import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import logo from "../../assets/img/logo_vertical.png";
import { useTranslation } from "react-i18next";

// Component styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      display: "flex",
      alignItems: "center",
      maxWidth: "60%",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "80%",
        display: "flex",
        alignItems: "center",
      },
    },
    logoImg: {
      maxWidth: "100%",
      display: "block",
      height: "auto",
    },
  })
);

export const StackLogo = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <img
        src={logo}
        alt={t("stackcare_logo")}
        className={classes.logoImg}
        draggable={false}
      />
    </div>
  );
};
