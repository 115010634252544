import React, { useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TabTypes } from "../../../helpers/constants";
import { tabBarHeight, trayHeight } from "../Dashboard";
import clsx from "clsx";
import { useAppSelector } from "../../app/appHooks";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CommunityCard } from "./CommunityCard";
import { TimeframeSelector } from "./TimeframeSelector";
import { StorageKeys } from "../../../services/constants";
import { minToolbarHeight } from "../../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      position: "relative",
      zIndex: 2,
      alignContent: "flex-start",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
      overflowY: "auto",
      padding: theme.spacing(2, 2),
    },
    openTrayHeight: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)} - ${trayHeight})`,
      paddingBottom: tabBarHeight,
    },
    minimizedTrayHeight: {
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)} )`,
      paddingBottom: tabBarHeight,
    },
    sectionHeader: {
      width: "100%",
      display: "flex",
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
    },
    communitiesContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    timeframeSelect: {
      minWidth: 120,
    },
    selectOption: {
      padding: theme.spacing(1),
    },
  })
);

export default function Organization(props: IProps) {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  const { addTab, openTray, minimizedTray } = props;

  /* ---- State ---- */
  const [selectedTimeframe, setSelectedTimeframe] = React.useState<number>(0);

  /* ---- Hooks ---- */
  const classes = useStyles(showBanner);
  const { t } = useTranslation();

  /* ---- Selectors ---- */
  const orgCommunities = useAppSelector((state) =>
    state.headerState.communities?.filter(
      (community) =>
        community.organization_id === state.headerState.selectedOrganization
    )
  );

  /* ---- Effects ---- */
  useEffect(() => {
    const lsSelectedTimeframe = localStorage.getItem(
      StorageKeys.SELECTED_TIMEFRAME
    );
    if (lsSelectedTimeframe !== null && !isNaN(Number(lsSelectedTimeframe))) {
      setSelectedTimeframe(Number(lsSelectedTimeframe));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ---- Methods ---- */
  const handleTimeframeChange = (id: number) => {
    setSelectedTimeframe(id);
    localStorage.setItem(StorageKeys.SELECTED_TIMEFRAME, String(id));
  };

  return (
    <div
      className={clsx(
        classes.container,
        openTray && classes.openTrayHeight,
        minimizedTray && classes.minimizedTrayHeight
      )}
      id="organization-container"
    >
      <div className={classes.sectionHeader}>
        <Typography variant="h5">{t("recent_notifications")}</Typography>
        <TimeframeSelector
          selected={selectedTimeframe}
          handleSelect={handleTimeframeChange}
        />
      </div>
      <div className={classes.communitiesContainer}>
        {orgCommunities?.map((community) => {
          return (
            <CommunityCard
              key={community.id}
              communityID={community.id}
              name={community.name}
              timeframe={selectedTimeframe}
              addTab={addTab}
            />
          );
        })}
      </div>
    </div>
  );
}

interface IProps {
  openTray: boolean;
  minimizedTray: boolean;
  addTab: (id: string, type: TabTypes) => void;
}
