import { useEffect, useState } from "react";
import { EVENT_CARD_HEIGHT } from "../../../../helpers/constants";

function usePanelsHeight(
  warningEventLength: number,
  criticalEventLength: number,
  containerEl: HTMLDivElement | null,
  popup: boolean = false
) {
  const [criticalPanelHeight, setCriticalPanelHeight] = useState(0);
  const [warningPanelHeight, setWarningPanelHeight] = useState(0);

  const notificationPanelHeight = containerEl?.clientHeight;

  let fullPanelHeight = notificationPanelHeight || 0;

  const markReadHeight = popup ? 0 : 23;

  let notificationpanelMaxHeight = 0;
  if (popup && containerEl) {
    const popupCSS = getComputedStyle(containerEl);
    notificationpanelMaxHeight = parseInt(popupCSS.maxHeight, 10);

    const totalPopupHeight =
      criticalEventLength * (EVENT_CARD_HEIGHT + 5) +
      warningEventLength * (EVENT_CARD_HEIGHT + 5);

    if (totalPopupHeight > notificationpanelMaxHeight) {
      fullPanelHeight = notificationpanelMaxHeight;
    } else {
      fullPanelHeight = totalPopupHeight;
    }
  }

  useEffect(() => {
    if (warningEventLength > 0 && criticalEventLength > 0) {
      const criticalContainerHeight =
        criticalEventLength * (EVENT_CARD_HEIGHT + 5);

      const criticalPanelMaxHeight = (fullPanelHeight ?? 0) * 0.7;

      if (criticalContainerHeight > criticalPanelMaxHeight) {
        setCriticalPanelHeight(criticalPanelMaxHeight);
        setWarningPanelHeight(
          fullPanelHeight - criticalPanelMaxHeight - markReadHeight
        );
      } else {
        setCriticalPanelHeight(criticalContainerHeight);
        setWarningPanelHeight(
          fullPanelHeight - criticalContainerHeight - markReadHeight
        );
      }
    } else if (warningEventLength === 0 && criticalEventLength > 0) {
      setCriticalPanelHeight(fullPanelHeight);
      setWarningPanelHeight(0);
    } else if (warningEventLength > 0 && criticalEventLength === 0) {
      // There are no critical events
      setCriticalPanelHeight(0);
      setWarningPanelHeight(fullPanelHeight - markReadHeight);
    } else {
      setCriticalPanelHeight(0);
      setWarningPanelHeight(0);
    }
  }, [
    warningEventLength,
    criticalEventLength,
    fullPanelHeight,
    notificationPanelHeight,
    markReadHeight,
  ]);

  return {
    criticalPanelHeight,
    warningPanelHeight,
  };
}

export default usePanelsHeight;
