import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalize, PIN_CODE_LENGTH } from "../../../helpers/constants";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Theme,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  IEventResolution,
  IUserDetails,
} from "../../../services/header.services";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import PinValidation from "../../common/PinValidation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../helpers/store";
import {
  resolveEventWithPassword,
  resolveEventWithPin,
} from "../../app/asyncThunkActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      backgroundColor: theme.palette.light.light,
    },
    buttonRoot: {
      minWidth: "110px",
    },
    popoverContent: {
      height: "100%",
      minHeight: "150px",
      overflowY: "auto",
    },
    responderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    listHeader: {
      backgroundColor: theme.palette.light.light,
    },
    popoverTitle: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2, 0),
    },
    bold: {
      fontWeight: "bolder",
    },
    buttonCancel: {
      border: `2px solid ${theme.palette.dark.main}`,
    },
    containedPrimary: {
      color: theme.palette.light.light,
    },
  })
);

interface FormState {
  resolution: string;
  pinCode: string;
  password: string;
}

export const ResolveNotification = (props: IProps) => {
  const {
    onClose,
    eventID,
    currentUser,
    resolutionID,
    eventResolutions,
    fromDashboard,
    fromNotifications,
  } = props;

  /* ---- State ---- */
  const [values, setValues] = useState<FormState>({
    resolution: "",
    pinCode: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState("password");
  const [pinError, setPinError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  /* ---- References ---- */
  const passwordRef = useRef(document.createElement("input"));

  /* ---- Effects ---- */
  // Set cursor when showing/hiding passwords
  useEffect(() => {
    // Moving cursor to the end
    if (passwordRef.current && passwordRef.current.value) {
      passwordRef.current.selectionStart = passwordRef.current.value.length;
      passwordRef.current.selectionEnd = passwordRef.current.value.length;
    }
  }, [showPassword]);

  // Initialize resolution when events is resolved
  useEffect(() => {
    if (resolutionID && resolutionID !== "") {
      const initialResolution = {
        resolution: resolutionID,
        pinCode: "",
        password: "",
      };
      setValues(initialResolution);
    }
  }, [resolutionID]);

  /* ---- Methods ---- */
  const toggleShowPassword = useCallback(() => {
    setShowPassword((current) =>
      showPassword === "text" ? "password" : "text"
    );
    // Setting focus here
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, [showPassword, passwordRef]);

  const handleChange = (prop: keyof FormState) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [prop]: event.target.value });
    setPasswordError("");
    setPinError("");
  };

  const handleChangePinCode = (value: string) => {
    setValues({ ...values, pinCode: value });
    setPinError("");
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (eventID && currentUser?.email && values.pinCode !== "") {
      if (values.pinCode.length === PIN_CODE_LENGTH) {
        const resolveData = {
          resolveEventData: {
            email: currentUser.email,
            pin: values.pinCode,
            eventID: eventID,
            resolutionID: values.resolution,
          },
          fromDashboard: fromDashboard,
          fromNotifications: fromNotifications,
        };
        const resultAction = await dispatch(resolveEventWithPin(resolveData));
        if (resolveEventWithPin.fulfilled.match(resultAction)) {
          // if request finished correctly, close modal
          onClose();
          return;
        } else if (typeof resultAction.payload === "boolean") {
          // display pin error
          setPinError("PIN_is_incorrect");
          return;
        }
      } else {
        // display pin error
        setPinError("PIN_is_incorrect");
        return;
      }
    } else if (eventID && currentUser?.email && values.password !== "") {
      const resolveData = {
        resolveEventData: {
          email: currentUser.email,
          password: values.password,
          eventID: eventID,
          resolutionID: values.resolution,
        },
        fromDashboard: fromDashboard,
        fromNotifications: fromNotifications,
      };

      const resultAction = await dispatch(
        resolveEventWithPassword(resolveData)
      );
      if (resolveEventWithPassword.fulfilled.match(resultAction)) {
        // if request finished correctly, close modal
        onClose();
        return;
      } else if (typeof resultAction.payload === "boolean") {
        // display pin error
        setPasswordError("password_is_incorrect");
        return;
      }
    }
  };

  const listTitle = `${t("resolve")} ${t("notification")} `;
  const resolvedIsEnabled =
    values.resolution !== "" &&
    ((values.pinCode !== "" && values.pinCode.length === PIN_CODE_LENGTH) ||
      values.password !== "");

  return (
    <React.Fragment>
      <Box
        className={classes.popoverTitle}
        fontWeight="fontWeightBold"
        fontSize="large"
      >
        {listTitle}
      </Box>
      <Box className={classes.popoverContent}>
        <form className={classes.formContainer} autoComplete="off">
          <FormControl component="fieldset">
            <FormLabel>
              <Box marginBottom={0.5} color="text.primary" fontWeight="bolder">
                {t("this_notification_was")}
              </Box>
            </FormLabel>
            <RadioGroup
              aria-label="event-resolution"
              name="event-resolution"
              value={values.resolution}
              onChange={handleChange("resolution")}
            >
              {eventResolutions?.map((resolution) => {
                return (
                  <FormControlLabel
                    key={resolution.id}
                    value={resolution.id}
                    control={<Radio color="primary" />}
                    color="primary"
                    label={
                      <Box fontWeight="bolder">
                        {resolution.name
                          ? t(`${resolution.name}_description`)
                          : ""}
                      </Box>
                    }
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          <Typography variant="subtitle1">
            <Box marginTop={1} fontWeight="bolder">
              {currentUser ? currentUser.email : ""}
            </Box>
          </Typography>

          <PinValidation onChange={handleChangePinCode} error={pinError} />
          <Box margin="auto">
            <Typography variant="subtitle1">
              {capitalize(`${t("or")}`)}
            </Typography>
          </Box>
          <Box
            marginBottom="16px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl error={passwordError !== ""}>
              <InputLabel htmlFor="standard-adornment-password">
                {t("password")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword}
                value={values.password}
                onChange={handleChange("password")}
                inputRef={passwordRef}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword === "text" ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="password-error-helper">
                {passwordError !== "" ? t(passwordError) : ""}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <Button
              id="cancel-button"
              classes={{
                root: classes.buttonRoot,
                outlined: classes.buttonCancel,
              }}
              color="default"
              variant="outlined"
              onClick={onClose}
            >
              {t("cancel")}
            </Button>

            <Button
              id="submit"
              disabled={!resolvedIsEnabled}
              classes={{
                root: classes.buttonRoot,
                containedPrimary: classes.containedPrimary,
              }}
              color="primary"
              variant={!resolvedIsEnabled ? "outlined" : "contained"}
              onClick={handleSubmit}
            >
              {t("resolve")}
            </Button>
          </Box>
        </form>
      </Box>
    </React.Fragment>
  );
};

interface IProps {
  currentUser?: IUserDetails;
  resolutionID?: string | null;
  eventResolutions?: IEventResolution[];
  eventID?: string;
  fromDashboard: boolean;
  fromNotifications: boolean;
  onClose: () => void;
}
