import React from "react";
import Box from "@material-ui/core/Box";

export default function TabPanel(props: IProps) {
  const { children, value, index, className } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={className}
    >
      {value === index && (
        <Box height="100%" padding="16px 16px 8px 16px">
          {children}
        </Box>
      )}
    </div>
  );
}

interface IProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className: any;
}
