import React from "react";
import { useTranslation } from "react-i18next";
import {
  IActivityData,
  IActivityTime,
  IContact,
  IMonitorData,
} from "../eventDataTypes";
import { sortAlphabetical, StackTypes } from "../../../helpers/constants";
import FloatBarChart, {
  IFloatingBarDataFormat,
} from "../../common/FloatBarChart";
import {
  DateFromIsoString,
  DateTimeFromMillis,
} from "../../../helpers/datetime";
import { checkForContactSensor, checkForMotionSensor } from "./chartHelpers";
import { IEvent } from "../../../services/dashboard.services";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";
import { LayoutChartContainer } from "./LayoutChartContainer";
import { Theme, useTheme } from "@material-ui/core/styles";

export default function SimpleMotionChart(props: IProps) {
  const { event } = props;
  const communityID = event.community_id ?? undefined;

  /* Hooks */
  const { t } = useTranslation();
  const communityUnitTimezone = useCommunityUnitTimezone(
    communityID,
    event.unit_id
  );
  const theme = useTheme();

  const eventData = JSON.parse(event?.data || "") as IMonitorData;
  const eventCreationDate = event ? event.time_created : "";

  const motionChartData = (JSON.parse(
    JSON.stringify(eventData.activity_data ? eventData.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForMotionSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const contactChartData = (JSON.parse(
    JSON.stringify(eventData.activity_data ? eventData.activity_data : [])
  ) as IActivityData[])
    .filter((activityData) => checkForContactSensor(activityData))
    .sort((a, b) => sortAlphabetical(a.zone_name, b.zone_name));

  const motionLabels = motionChartData.reduce((result: string[], element) => {
    if (element.activity !== undefined) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const contactLabels = contactChartData.reduce((result: string[], element) => {
    if (element.contact !== undefined) {
      result.push(element.zone_name || "");
    }
    return result;
  }, []);

  const labels = [...motionLabels, ...contactLabels];

  const getActivityDatasets = (
    activityData: IActivityData,
    theme: Theme,
    communityUnitTimezone?: string
  ) => {
    // Copy and dereference array and orders it ascendingly
    const orderedTimeIntervals = (JSON.parse(
      JSON.stringify(activityData.activity ? activityData.activity : [])
    ) as IActivityTime[]).sort((a, b) => {
      return Number(a.start_time) - Number(b.end_time);
    });

    // Array of time intervals ordered
    const activityDatasets = orderedTimeIntervals.map((data) => {
      const startDate = DateTimeFromMillis(
        data.start_time || 0,
        communityUnitTimezone
      );
      let endDate = startDate.plus({ minute: 3 });
      if (data.end_time) {
        endDate = DateTimeFromMillis(data.end_time, communityUnitTimezone);
      }
      return [startDate.toISO(), endDate.toISO()];
    });
    return {
      color: theme.palette.activityMotion.main,
      stack: StackTypes.MOTION,
      datasets: activityDatasets.length > 0 ? activityDatasets : [[""]],
    };
  };

  const getContactDatasets = (
    activityData: IActivityData,
    creationTime: string,
    communityUnitTimezone?: string
  ) => {
    const sensors = activityData.sensors ? activityData.sensors : [];
    // Copy and dereference array and orders it ascendingly
    const contactData = JSON.parse(
      JSON.stringify(activityData.contact ? activityData.contact : [])
    ) as IContact[];
    const contactDatasets = contactData.reduce(
      (result: IFloatingBarDataFormat[], contact) => {
        const sensorID = contact.sensor_id;
        const contactSensor = sensors.find(
          (sensor) => sensor.sensor_id === sensorID
        );
        if (contact.activity && contactSensor !== undefined) {
          const orderedTimeIntervals = contact.activity.sort((a, b) => {
            return Number(a.start_time) - Number(b.end_time);
          });

          // Array of time intervals ordered
          const contactDatasets = orderedTimeIntervals.map((data, index) => {
            const startDate = DateTimeFromMillis(
              data.start_time || 0,
              communityUnitTimezone
            ).minus({
              minute: 1,
            });
            let endDate = startDate.plus({ minute: 2 });
            if (data.end_time) {
              endDate = DateTimeFromMillis(
                data.end_time,
                communityUnitTimezone
              ).plus({ minute: 2 });
            } else if (
              data.end_time === null &&
              index === orderedTimeIntervals.length - 1
            ) {
              endDate = DateFromIsoString(creationTime, communityUnitTimezone);
            }
            return [startDate.toISO(), endDate.toISO()];
          });
          result.push({
            color: theme.palette.contactBlue.main,
            stack: StackTypes.CONTACT,
            datasets: contactDatasets.length > 0 ? contactDatasets : [[""]],
          });
        }
        return result;
      },
      []
    );

    return contactDatasets;
  };

  const getDataSets = (
    motionOrderedZones: IActivityData[],
    contactOrderedZones: IActivityData[],
    theme: Theme,
    communityUnitTimezone?: string
  ) => {
    const dataSets: IFloatingBarDataFormat[] = [];

    // Iterate through each zone and get the datasets
    const activityDatasets: IFloatingBarDataFormat[] = motionOrderedZones.map(
      (activityData) =>
        getActivityDatasets(activityData, theme, communityUnitTimezone)
    );

    const contactDatasets: IFloatingBarDataFormat[] = contactOrderedZones.reduce(
      (result: IFloatingBarDataFormat[], contactData) => {
        if (contactData.contact !== undefined) {
          result.push(
            ...getContactDatasets(
              contactData,
              eventCreationDate,
              communityUnitTimezone
            )
          );
        }
        return result;
      },
      []
    );
    dataSets.push(...activityDatasets, ...contactDatasets);
    return dataSets;
  };

  const datasets = getDataSets(
    motionChartData,
    contactChartData,
    theme,
    communityUnitTimezone
  );

  // Calculate max and min chart limits
  const maxDateTime = DateFromIsoString(
    eventCreationDate,
    communityUnitTimezone
  );
  const minDateTime = maxDateTime.minus({ hours: 6 });

  return (
    <LayoutChartContainer
      title={t("room_activity").toUpperCase()}
      unitID={event.unit_id}
      eventTimeCreated={event.time_created}
      eventType={event.event_type}
      emptyData={labels.length === 0}
    >
      <FloatBarChart
        max={maxDateTime}
        min={minDateTime}
        labels={labels}
        values={datasets}
        communityUnitTimezone={communityUnitTimezone}
      />
    </LayoutChartContainer>
  );
}

interface IProps {
  event: IEvent;
}
