import BarChart from "../../common/BarChart";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { DateTime } from "luxon";
import { IMorningMotionData } from "../eventDataTypes";
import {
  DateTimeNowHourMinute,
  DateTimeShortFormat,
  ShortDayFormatNoTZ,
  TimeShortFormat,
} from "../../../helpers/datetime";
import { IEvent } from "../../../services/dashboard.services";
import useCommunityUnitTimezone from "../hooks/useCommunityUnitTimezone";
import { LayoutChartContainer } from "./LayoutChartContainer";

export default function UpAndAboutBarChart(props: IProps) {
  const { event } = props;
  const communityID = event.community_id ?? undefined;
  /* Hooks */
  const { t } = useTranslation();
  const communityUnitTimezone = useCommunityUnitTimezone(
    communityID,
    event.unit_id
  );

  const eventData = JSON.parse(event?.data || "") as IMorningMotionData;
  const minTimeD = DateTimeNowHourMinute(eventData.local_time_start);
  const maxTimeD = DateTimeNowHourMinute(eventData.local_time_end);
  const labels = eventData.wakeup_data.flatMap((item) => [
    ShortDayFormatNoTZ(item.date),
  ]);
  const values = eventData.wakeup_data.flatMap((item, index) => {
    const wakeTime = item.wakeup_time;
    let ans = null;
    if (wakeTime) {
      const wakeTimeD = DateTimeNowHourMinute(wakeTime);
      if (wakeTimeD.hour >= maxTimeD.hour) ans = maxTimeD;
      else ans = wakeTimeD;
    } else {
      if (index === 0) ans = maxTimeD;
    }
    return ans;
  });
  let isRecent = false;
  if (event?.time_created && eventData.last_motion_time) {
    const hourDiff =
      (DateTime.fromISO(event?.time_created).millisecond -
        eventData.last_motion_time) /
      3600000; // milliseconds to hours
    if (hourDiff < 24) isRecent = true;
  }

  let lastMotionText = "";
  if (eventData.last_motion_time) {
    const lastMotion = DateTime.fromMillis(eventData.last_motion_time).toISO();
    const room = eventData.zone_name;
    const time = isRecent
      ? TimeShortFormat(lastMotion, communityUnitTimezone)
      : DateTimeShortFormat(lastMotion, communityUnitTimezone);
    lastMotionText = i18n.t("last_motion_was_seen_in_at", {
      room: room,
      time: time,
    });
  }

  return (
    <LayoutChartContainer
      title={t("recent_wake_up_times").toUpperCase()}
      endText={lastMotionText}
      unitID={event.unit_id}
      eventTimeCreated={event.time_created}
      eventType={event.event_type}
      emptyData={labels.length === 0}
    >
      <BarChart
        id={event.id}
        min={minTimeD}
        max={maxTimeD}
        labels={labels}
        values={values}
      />
    </LayoutChartContainer>
  );
}

interface IProps {
  event: IEvent;
}
