import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { capitalize, TabTypes } from "../../../helpers/constants";
import { IEvent } from "../../../services/dashboard.services";
import { DateTimeLocaleISO, DateTimeNow } from "../../../helpers/datetime";
import { Grid } from "@material-ui/core";
import { useAppSelector } from "../../app/appHooks";
import { OrganizationUnit } from "../OrganizationUnit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowLabel: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      fontSize: "1.12rem",
    },
    unitContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
  })
);

const getRowLabelDate = (ISODate: string, weekday: boolean) => {
  const today = DateTimeNow();
  const rowDate = DateTimeLocaleISO(ISODate);
  if (today.day === rowDate.day) {
    return `${capitalize(rowDate.toRelativeCalendar() || "")}`;
  } else {
    if (weekday) {
      return `${rowDate.weekdayLong}`;
    } else {
      return `${rowDate.toFormat(`LLL dd`)}`;
    }
  }
};

export const RecentEventRow = (props: IProps) => {
  const { eventsDate, timeframe, communityID, events, addTab } = props;
  const classes = useStyles();

  const communityUnits = useAppSelector(
    (state) => state.dashboardState.orgUnits[communityID]
  );
  // Timeframe is less than key of week
  const weekday = timeframe < 4;

  const rowLabel = getRowLabelDate(eventsDate, weekday);
  return (
    <React.Fragment>
      <Grid item xs={2} xl={1} className={classes.rowLabel}>
        {rowLabel}
      </Grid>
      <Grid item xs={10} xl={11} className={classes.unitContainer}>
        {communityUnits &&
          communityUnits.map((unit, index) => {
            const unitEvents = events?.filter(
              (event) => event.unit_id === unit.id
            );
            return (
              <OrganizationUnit
                key={index}
                unit={unit}
                communityID={communityID}
                events={unitEvents}
                onClickOpenTab={addTab}
              />
            );
          })}
      </Grid>
    </React.Fragment>
  );
};

interface IProps {
  communityID: string;
  eventsDate: string;
  events: IEvent[];
  timeframe: number;
  addTab: (id: string, type: TabTypes) => void;
}
