import { Grid, List, Tooltip, Typography } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { useTranslation } from "react-i18next";
import { RoleTypes, SettingListKeys } from "../../helpers/constants";
import React, { useEffect, useRef, useState } from "react";
import MuiListItem from "@material-ui/core/ListItem";
import { addCommunityUser, setSettingsUser } from "./settingsSlice";
import { IUserDetails } from "../../services/header.services";
import { getResidentName, isActiveUser } from "../dashboard/dashboardSlice";
import useHelpAtHome from "../notifications/hooks/useHelpAtHome";
import clsx from "clsx";
import { AddNewItemButton } from "./common/AddNewItemButton";
import { getRoleLabel } from "./settingsHelpers";
import { sortUsers } from "./common/helpers";
import { DateTimeDiffHoursDays } from "../../helpers/datetime";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      position: "relative",
      height: "100%",
      width: "50%",
    },
    userListContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      background: theme.palette.light.main,
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
      padding: theme.spacing(3, 0),
      overflowY: "auto",
      paddingBottom: theme.spacing(8),
    },
    listContainer: {
      background: "#FFF",
    },
    optionsContainer: {
      width: "100%",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
    },
    secondaryListContainer: {
      display: "flex",
      flex: 1,
      width: "100%",
      flexDirection: "column",
      padding: theme.spacing(0, 1),
    },
    primaryTextContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    userRelation: {
      textAlign: "right",
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.gray.main,
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    isPendingUser: {
      fontStyle: "italic",
    },
    emailText: {
      fontSize: theme.typography.caption.fontSize,
    },
  })
);

const UserSettingsListItem = withStyles(({ palette, spacing }) => ({
  root: {
    paddingBottom: spacing(1),
    paddingTop: spacing(1),
    width: "100%",
    borderRadius: "0px",
    "&$selected": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
  },
  selected: {},
  divider: {},
}))(MuiListItem);

// Get a unit resident's name based on an array of IResidents
export const getUserName = <
  T extends {
    first_name?: string | null;
    last_name?: string | null;
  }
>(
  user?: T
) => {
  if (user === undefined) return "";

  let firstName =
    user.first_name !== null &&
    user.first_name !== undefined &&
    user.first_name !== ""
      ? user.first_name.split(" ")[0]
      : "";
  firstName = firstName.slice(0, 10);

  let lastName =
    user.last_name !== null &&
    user.last_name !== undefined &&
    user.last_name !== ""
      ? user.last_name
      : "";

  let fullName = "";
  if (firstName !== "") {
    fullName = firstName;
  }
  if (lastName !== "") {
    if (fullName !== "") {
      fullName = fullName.concat(" ", lastName);
    } else {
      fullName = lastName;
    }
  }
  return fullName;
};
const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const UserGroupList = (props: {
  users: IUserDetails[];
  isHelpAtHome: boolean;
  selectedUser?: string;
  handleSelectUser: (userID: string) => void;
}) => {
  const { users, isHelpAtHome, selectedUser, handleSelectUser } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List className={classes.optionsContainer}>
      {users.map((user) => {
        const roleName = getRoleLabel(user?.role?.name);
        const userRelation = user.units?.reduce((prevVal, currentVal) => {
          if (isHelpAtHome) {
            return prevVal === ""
              ? getResidentName(currentVal.residents, {
                  fullFirstName: false,
                })
              : `${prevVal}, ${getResidentName(currentVal.residents, {
                  fullFirstName: false,
                })}`;
          } else {
            return prevVal === "" && currentVal.name !== null
              ? currentVal.name
              : `${prevVal}, ${currentVal.name}`;
          }
        }, "");

        const isPending = !isActiveUser(user);
        const userName = !isPending ? getUserName(user) : "-";
        const timeDifference = user?.last_active_time
          ? DateTimeDiffHoursDays(user?.last_active_time)
          : "";
        const showSecondaryText =
          roleName === RoleTypes.family || isPending || timeDifference;

        return (
          <UserSettingsListItem
            button
            key={user.id}
            divider
            disableGutters
            onClick={() => handleSelectUser(user.id)}
            selected={user.id === selectedUser}
            className={classes.listContainer}
          >
            <div
              className={clsx(
                classes.secondaryListContainer,
                isPending && classes.isPendingUser
              )}
            >
              <div className={classes.primaryTextContainer}>
                <Typography variant="subtitle2">{userName}</Typography>
                <Typography variant="caption">
                  {`${roleName !== "" ? t(roleName) : ""}${
                    isPending && userRelation !== ""
                      ? ""
                      : roleName === RoleTypes.family
                      ? ` ${t("of")}`
                      : ""
                  }`}
                </Typography>
              </div>

              <Grid container>
                <Grid item xs={showSecondaryText ? 8 : 12}>
                  <div
                    className={clsx(
                      classes.emailText,
                      classes.truncateEllipsis
                    )}
                  >
                    {user.email}
                  </div>
                </Grid>

                {showSecondaryText && (
                  <CustomTooltip
                    title={userRelation}
                    placement="top"
                    interactive
                    disableHoverListener={isPending}
                    disableFocusListener={isPending}
                    disableTouchListener={isPending}
                  >
                    <Grid item xs={4}>
                      <div
                        className={clsx(
                          classes.userRelation,
                          classes.truncateEllipsis
                        )}
                      >
                        {isPending
                          ? t("pending")
                          : userRelation === ""
                          ? timeDifference
                          : userRelation}
                      </div>
                    </Grid>
                  </CustomTooltip>
                )}
              </Grid>
            </div>
          </UserSettingsListItem>
        );
      })}
    </List>
  );
};

export function UsersSettingsList() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  /* State */
  const [adminList, setAdminList] = useState<IUserDetails[]>([]);
  const [caregiversList, setCaregiversList] = useState<IUserDetails[]>([]);
  const [installerList, setInstallerList] = useState<IUserDetails[]>([]);
  const [familyList, setFamilyList] = useState<IUserDetails[]>([]);

  /* Refs */
  const ref = useRef<HTMLDivElement>(null);

  /* Selectors */
  const selectedOption = useAppSelector(
    (state) => state.settingsState.selectedOption
  );

  const selectedUser = useAppSelector(
    (state) => state.settingsState.selectedUser
  );

  const users = useAppSelector((state) => state.headerState.users);
  const units = useAppSelector((state) => state.headerState.units);

  const [allHelpAtHome] = useHelpAtHome(units);

  /* Effects */
  useEffect(() => {
    if (users === undefined) return;

    setAdminList(
      sortUsers(users.filter((user) => user.role.name === RoleTypes.admin))
    );
    setCaregiversList(
      sortUsers(users.filter((user) => user.role.name === RoleTypes.caregiver))
    );
    setInstallerList(
      sortUsers(users.filter((user) => user.role.name === RoleTypes.installer))
    );
    setFamilyList(
      sortUsers(
        users.filter(
          (user) =>
            user.role.name === RoleTypes.pro_family ||
            user.role.name === RoleTypes.family
        )
      )
    );
  }, [users]);

  /* Methods */
  const handleSelectUser = (userID: string) => {
    dispatch(setSettingsUser(userID));
  };
  const handleAddUser = () => {
    dispatch(addCommunityUser());
  };

  return (
    <React.Fragment>
      {selectedOption === SettingListKeys.community_users && (
        <div className={classes.mainContainer}>
          <div ref={ref} className={classes.userListContainer}>
            {users !== undefined && (
              <React.Fragment>
                {adminList.length > 0 && (
                  <UserGroupList
                    users={adminList}
                    handleSelectUser={handleSelectUser}
                    isHelpAtHome={!!allHelpAtHome}
                    selectedUser={selectedUser}
                  />
                )}
                {caregiversList.length > 0 && (
                  <UserGroupList
                    users={caregiversList}
                    handleSelectUser={handleSelectUser}
                    isHelpAtHome={!!allHelpAtHome}
                    selectedUser={selectedUser}
                  />
                )}
                {installerList.length > 0 && (
                  <UserGroupList
                    users={installerList}
                    handleSelectUser={handleSelectUser}
                    isHelpAtHome={!!allHelpAtHome}
                    selectedUser={selectedUser}
                  />
                )}
                {familyList.length > 0 && (
                  <UserGroupList
                    users={familyList}
                    handleSelectUser={handleSelectUser}
                    isHelpAtHome={!!allHelpAtHome}
                    selectedUser={selectedUser}
                  />
                )}
              </React.Fragment>
            )}
          </div>
          <AddNewItemButton parentRef={ref} handleAddAction={handleAddUser} />
        </div>
      )}
    </React.Fragment>
  );
}
