import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";

import { loadDevicePlacementPhoto } from "./devicesThunks";
import { useAppDispatch } from "../app/appHooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      maxWidth: 800,
      maxHeight: 500,
      backgroundColor: theme.palette.dark.dark,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
    placementPhotoContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    placementPhoto: {
      cursor: "pointer",
    },
  })
);

export const DevicePlacementPhoto = (props: IProps) => {
  const {
    deviceID,
    photoStatus,
    onPhotoLoadSuccess,
    setPhotoStatusFalse,
  } = props;

  const [open, setOpen] = useState(false);

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [src, setSrc] = useState<undefined | string>(undefined);

  useEffect(() => {
    let ignore = false;
    const setImgSrc = async (deviceID: string) => {
      const imgURL = await dispatch(loadDevicePlacementPhoto(deviceID));
      if (!ignore && typeof imgURL.payload === "string") {
        setSrc(imgURL.payload);
        onPhotoLoadSuccess(deviceID);
      }
    };
    setImgSrc(deviceID);
    return () => {
      ignore = true;
    };
  }, [deviceID, dispatch, onPhotoLoadSuccess, setPhotoStatusFalse]);

  useEffect(() => {
    if (photoStatus[deviceID] === false) {
      setSrc(undefined); // Clear the photo if status is false
      return;
    }
  }, [deviceID, photoStatus]);

  const handleOpen = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPhotoStatusFalse(deviceID);
  };

  return (
    <>
      {src !== undefined && (
        <div className={classes.placementPhotoContainer}>
          <img
            src={src}
            onClick={handleOpen}
            className={classes.placementPhoto}
            alt={t("alt_text_device_photo")}
            height={150}
          />
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <img src={src} alt={t("alt_text_device_photo")} height={450} />
        </div>
      </Modal>
    </>
  );
};

interface IProps {
  deviceID: string;
  photoStatus: { [key: string]: boolean };
  onPhotoLoadSuccess: (deviceID: string) => void;
  setPhotoStatusFalse: (deviceID: string) => void;
}
