import { ICommunities } from "../../../../services/header.services";
import { useAppSelector } from "../../../app/appHooks";

function useOrganization() {
  const selectedOrganizationID: string | undefined = useAppSelector(
    (state) => state.headerState.selectedOrganization
  );

  const orgsCommunities: ICommunities[] | undefined = useAppSelector(
    (state) => {
      const { selectedOrganization, communities } = state.headerState;
      if (
        selectedOrganization &&
        communities !== undefined &&
        communities.length > 0
      ) {
        return communities.filter(
          (community) => community.organization_id === selectedOrganizationID
        );
      }
      return undefined;
    }
  );

  return {
    selectedOrganizationID,
    orgsCommunities,
  };
}

export default useOrganization;
