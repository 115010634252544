import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import { getUnitTitle } from "../dashboard/CommunityUnit";
import { IUnit } from "../../services/dashboard.services";
import { eachMonthOfInterval, startOfMonth, sub } from "date-fns";
import { ReportItem } from "./ReportItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportsContainer: {
      padding: theme.spacing(6, 7),
      width: "850px",
    },
    sectionTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    reportItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid",
      borderRadius: "4px",
      height: "35px",
      marginBottom: "10px",
      borderColor: theme.palette.light.main,
    },
  })
);

export function ReportsList(props: IProps) {
  const { selectedUnit } = props;
  /* Hooks */
  const classes = useStyles();
  const listItemText = getUnitTitle(selectedUnit);
  const defaultStartDate = new Date();
  defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 1);

  const timeCreated = selectedUnit.time_created
    ? new Date(selectedUnit.time_created)
    : defaultStartDate;

  const datesList = eachMonthOfInterval({
    start: new Date(timeCreated.getFullYear(), timeCreated.getMonth(), 1),
    end: startOfMonth(sub(new Date(), { months: 1 })),
  }).reverse();

  const getTitle = (title: string, subtitle: string) => {
    if (subtitle === "") {
      return title;
    }

    return title + " - " + subtitle;
  };

  return (
    <Paper className={classes.reportsContainer} square>
      <Typography className={classes.sectionTitle}>
        {getTitle(listItemText.unitTitle, listItemText.unitSubtitle)}
      </Typography>
      {datesList?.map((date, index) => {
        return (
          <ReportItem key={index} date={date} selectedUnit={selectedUnit} />
        );
      })}
    </Paper>
  );
}

interface IProps {
  selectedUnit: IUnit;
}
