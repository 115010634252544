import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

export const StackSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 48,
      height: 24,
      padding: 0,
    },
    switchBase: {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",

      "&$checked": {
        transform: "translateX(24px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 0,
        },
        "&$disabled + $track": {
          opacity: 0.5,
        },
      },
      "&$focusVisible $thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&disabled  $thumb": {
        color: theme.palette.grey[100],
      },
      "&$disabled + $track": {
        opacity: 0.7,
      },
    },
    thumb: {
      boxSizing: "border-box",
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.lightGrey.main,
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: IProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

interface IProps extends SwitchProps {
  classes: Styles;
}
