import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card/Card";
import Grid from "@material-ui/core/Grid/Grid";
import {
  DeviceStatus,
  UnitStatus,
  UnitStatusInstaller,
} from "../../helpers/constants";
import Icons from "../../helpers/iconImports";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { getFilterToHex } from "../../helpers/colorUtils";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    root: {
      margin: "6px",
      minHeight: "100px",
      maxHeight: "130px",
    },
    communityTile: {
      [theme.breakpoints.down("sm")]: {
        flex: "1 0 15%",
      },
      [theme.breakpoints.up("sm")]: {
        flex: "1 0 16%",
      },
      [theme.breakpoints.up("md")]: {
        flex: "1 0 17%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "1 0 8.5%",
      },
      [theme.breakpoints.up("xl")]: {
        flex: "1 0 8.9%",
      },
    },
    organizationTile: {
      maxWidth: "135px",
    },
    unitTitle: {
      fontSize: 14,
      fontFamily: theme.typography.secondaryFontFamily,
    },
    hidden: {
      visibility: "hidden",
    },
    critical: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.error.main,
      border: "none",
      cursor: "pointer",
    },
    criticalResolved: {
      color: theme.palette.paused.main,
      backgroundColor: theme.palette.error.lightTrans,
      border: `2px solid ${theme.palette.error.main}`,
      cursor: "pointer",
    },
    warning: {
      color: theme.palette.light.light,
      backgroundColor: theme.palette.warning.main,
      border: "none",
      cursor: "pointer",
    },
    warningRead: {
      color: theme.palette.paused.main,
      backgroundColor: theme.palette.warning.lightTrans,
      border: `2px solid ${theme.palette.warning.main}`,
      cursor: "pointer",
    },
    paused: {
      color: theme.palette.action.disabled,
      border: `1px solid ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.light.main,
      cursor: "pointer",
    },
    notInstalled: {
      color: theme.palette.action.disabled,
      border: `1px solid ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.light.main,
    },
    notInstalledInstaller: {
      color: theme.palette.action.disabled,
      border: `1px solid ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.light.main,
      cursor: "pointer",
    },
    normal: {
      border: `1px solid ${theme.palette.normal.main}`,
      cursor: "pointer",
    },
    deviceOffline: {
      border: `1px solid ${theme.palette.error.main}`,
      cursor: "pointer",
    },
    batteryLow: {
      border: `1px solid ${theme.palette.warning.main}`,
      cursor: "pointer",
    },
    addNewUnit: {
      backgroundColor: theme.palette.light.main,
      color: theme.palette.action.disabled,
      border: `1px solid ${theme.palette.action.disabledBackground}`,
      cursor: "pointer",
    },
    watchedUnitIcon: {
      height: "20px",
      width: "20px",
    },
    enabledFlag: {
      filter: `${
        getFilterToHex(theme.palette.indicator.main) !== null
          ? `brightness(0) saturate(100%) ${getFilterToHex(
              theme.palette.indicator.main
            )}`
          : ""
      }`,
    },
    watchedUnitContainer: {
      display: "flex",
      alignItems: "flex-start",
    },
    cardContent: {
      display: "flex",
      height: "100%",
      padding: theme.spacing(1.2),
    },
    residentNameContainer: {
      display: "flex",
      alignItems: "flex-end",
    },
    unitIcon: {
      display: "flex",
      margin: "auto",
      height: "30px",
    },
    badgeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      margin: "auto",
      height: "25px",
      width: "25px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
    },
    criticalBadgeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      margin: "auto",
      height: "25px",
      width: "25px",
      backgroundColor: theme.palette.error.main,
      borderRadius: "50%",
    },
    warningBadgeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      margin: "auto",
      height: "25px",
      width: "25px",
      backgroundColor: theme.palette.warning.main,
      borderRadius: "50%",
    },
    badgeIcon: {
      margin: "0",
      color: theme.palette.light.light,
      position: "absolute",
      top: "54%",
      transform: "translateY(-50%)",
      fontWeight: "bold",
    },
    iconContainer: {
      display: "flex",
      position: "relative",
      alignItems: "center",
    },
    statusIcon: {
      display: "flex",
      position: "absolute",
      bottom: "40%",
      right: "30%",
      height: "25px",
      fontSize: theme.typography.caption.fontSize,
    },
    statusImage: {
      filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.30))",
    },
    iconButtonRoot: {
      padding: theme.spacing(0),
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    boldFont: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
  })
);

function WrappedUnitLayout(props: IProps) {
  const {
    title,
    subtitle,
    eventType,
    isWatched,
    isAssigned,
    isResolved,
    notVisible,
    unitIcon,
    hasBadge,
    status,
    organization,
    toggleFlag,
    onClick,
    installerRole,
    hubStatus,
    offlineDevices,
    batteryLowDevices,
  } = props;

  /* Hooks */
  const classes = useStyles(props);
  const { t } = useTranslation();

  const statusStyle = classes[status];

  const installerStyle = hubStatus
    ? classes[DeviceStatus.DEVICE_OFFLINE]
    : offlineDevices
    ? classes[DeviceStatus.DEVICE_OFFLINE]
    : batteryLowDevices
    ? classes[DeviceStatus.BATTERY_LOW]
    : status === UnitStatusInstaller.NOT_INSTALLED
    ? classes[UnitStatusInstaller.NOT_INSTALLED_INSTALLER]
    : classes[status];

  const highlightUnit =
    status === UnitStatus.critical ||
    status === UnitStatus.warning ||
    status === UnitStatus.criticalResolved ||
    status === UnitStatus.warningRead;

  const altIcon = hubStatus
    ? t("hub_offline")
    : offlineDevices
    ? t("device_offline")
    : batteryLowDevices
    ? t("battery_low")
    : status === UnitStatusInstaller.NOT_INSTALLED
    ? t("not_installed")
    : "";

  return (
    <Card
      className={clsx(
        classes.root,
        organization && classes.organizationTile,
        !organization && classes.communityTile,
        installerRole ? installerStyle : statusStyle,
        notVisible && classes.hidden
      )}
      elevation={highlightUnit ? 5 : 0}
      onClick={(event) => onClick(event)}
    >
      <div className={classes.cardContent}>
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <div
              className={clsx(
                classes.unitTitle,
                classes.truncateEllipsis,
                title === "" && classes.hidden
              )}
            >
              {title || "placeholder"}
            </div>
          </Grid>
          {!installerRole && !(status === UnitStatus.addNewUnit) && (
            <Grid item xs={2} className={classes.watchedUnitContainer}>
              <IconButton
                classes={{ root: classes.iconButtonRoot }}
                onClick={(event) => toggleFlag(event)}
                aria-label="toggle-watch-unit"
              >
                <img
                  src={
                    isWatched ? Icons.WatchflagEnabled : Icons.WatchflagDisabled
                  }
                  alt={
                    isWatched
                      ? t("alt_text_on_watched_unit")
                      : t("alt_text_off_watched_unit")
                  }
                  draggable={false}
                  className={clsx(
                    classes.watchedUnitIcon,
                    isWatched && classes.enabledFlag
                  )}
                />
              </IconButton>
            </Grid>
          )}
          {installerRole ? (
            <Grid className={classes.iconContainer} item xs={12}>
              {hasBadge ? (
                <div
                  className={
                    offlineDevices
                      ? classes.criticalBadgeContainer
                      : classes.warningBadgeContainer
                  }
                >
                  <div className={classes.badgeIcon}>{unitIcon}</div>
                </div>
              ) : unitIcon !== "" ? (
                <React.Fragment>
                  <img
                    src={unitIcon}
                    alt={altIcon}
                    draggable={false}
                    className={classes.unitIcon}
                  />
                </React.Fragment>
              ) : (
                <div />
              )}
            </Grid>
          ) : (
            <Grid className={classes.iconContainer} item xs={12}>
              {hasBadge ? (
                <div className={classes.badgeContainer}>
                  <div className={classes.badgeIcon}>{unitIcon}</div>
                </div>
              ) : unitIcon !== "" ? (
                <React.Fragment>
                  <img
                    src={unitIcon}
                    alt={t("alt_text_event_type_icon", {
                      event_type: eventType || "event type",
                    })}
                    draggable={false}
                    className={classes.unitIcon}
                  />
                  {isAssigned && !isResolved && (
                    <div className={classes.statusIcon}>
                      <img
                        src={Icons.Assigned}
                        alt={t("alt_text_assigned_icon")}
                        draggable={false}
                        className={classes.statusImage}
                      />
                    </div>
                  )}
                  {isAssigned && isResolved && (
                    <div className={classes.statusIcon}>
                      <img
                        src={Icons.Resolved}
                        alt={t("alt_text_resolved_icon")}
                        draggable={false}
                        className={classes.statusImage}
                      />
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <div />
              )}
            </Grid>
          )}

          <Grid item xs={12} className={classes.residentNameContainer}>
            <div
              className={clsx(
                classes.truncateEllipsis,
                subtitle === "" && classes.hidden
              )}
            >
              <div
                className={
                  status === UnitStatus.critical ||
                  status === UnitStatus.warning
                    ? classes.boldFont
                    : classes.truncateEllipsis
                }
              >
                {subtitle || "placeholder"}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}

export const UnitLayout = React.memo(WrappedUnitLayout);

interface IProps {
  title: string;
  subtitle: string;
  eventType: string;
  isWatched: boolean;
  isAssigned: boolean;
  isResolved: boolean;
  notVisible: boolean;
  hasBadge: boolean;
  unitIcon: string;
  status:
    | "paused"
    | "critical"
    | "criticalResolved"
    | "warning"
    | "warningRead"
    | "normal"
    | "notInstalled"
    | "addNewUnit";
  organization?: boolean;
  toggleFlag: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  installerRole: boolean;
  hubStatus?: string;
  offlineDevices?: number;
  batteryLowDevices?: number;
}
