import { useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { AccordionForm } from "./common/AccordionForm";
import { GreenCheckbox } from "../notifications/filters/GreenCheckBox";
import {
  updateCommunityEventConfig,
  updateUnitConfigByEventType,
} from "./settingsThunks";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import {
  ConfigLevelTwo,
  EventsWithCountConfig,
  EventsWithDurationConfig,
  EventsWithTimeConfig,
  EventsWithTriggerTime,
  getCountConfigByEventType,
  getDurationConfigByEventType,
  getTriggerConfigByEventType,
  ISO_TIME_SAVE_FORMAT,
  TimeFormat,
} from "../../helpers/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTime, Duration } from "luxon";
import {
  EventTypeConfig,
  EventTypeLevelConfig,
  ICommunityConfigData,
  IUnitEventConfigData,
} from "../../services/settings.services";
import i18n from "../../i18n";
import {
  convertTimeHourMinute,
  DateTimeNow,
  DateTimeNowHourMinute,
  HourMinuteFormat,
  loadTime,
  timeConfigurations,
} from "../../helpers/datetime";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { EventConfigLabel } from "./common/EventConfigLabel";
import { showSoftNotification } from "../app/appSlice";
import { SoftNotificationTypes } from "../common/AppSnack";
import { numberRange } from "./common/helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstSection: {
      flexBasis: "30%",
      fontWeight: "bold",
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
    formContainer: {
      display: "flex",
      flexBasis: "70%",
      "&>*": {
        alignItems: "center",
      },
    },
    rowInput: {
      width: "clamp(85px,200px, 200px)",
    },
    autocompleteInput: {
      minWidth: "85px",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      "&>*": {
        paddingBottom: theme.spacing(1),
      },
      "&>:last-child": {
        paddingBottom: 0,
      },
    },
    gridItem: {
      display: "flex",
      alignItems: "flex-start",
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("lg")]: {
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.up("lg")]: {
        height: "100%",
        paddingBottom: 0,
      },
    },
    columnRow: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(2),
    },
    columnRowMultiple: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(4),
    },
    columnRowMultipleItem: {
      display: "flex",
      gap: theme.spacing(2),
    },
    enabledCheckbox: {
      padding: 0,
      paddingLeft: 4,
    },
    timePicker: {
      fontSize: theme.typography.body2.fontSize,
      width: "140px",
    },
  })
);

interface IConfigEventForm {
  is_enabled: boolean;
  duration?: number | null;
  count?: number | null;
  all_day?: boolean;
  local_time_start?: DateTime | null;
  local_time_end?: DateTime | null;
}

const durationOptions = (start: number, stop: number) => {
  const duration = stop - start;

  const durationValues1 = [5, 10, 15, 20, 30, 45, 60, 75, 90, 105, 120];
  const durationValues2 = [5, 10, 15, 20, 30, 45, 60];
  // if less than 1h, then increment every 5 minutes
  if (duration <= 60) {
    return numberRange(start, stop, 5);
  } else if (duration > 60 && duration <= 120) {
    return durationValues1.filter((val) => val >= start);
  }
  // if greater than 2h, then increment every 5 minutes until 45 minutes,
  //then increment to 1h and then increment in 30 minutes intervals
  else {
    return [
      ...durationValues2.filter((val) => val >= start),
      ...numberRange(90, stop, 30),
    ];
  }
};

const countOptions = (start: number, stop: number) => {
  return Array.from({ length: (stop - start) / 1 + 1 }, (_, i) =>
    String(start + i * 1)
  );
};

export const getFormatWithMinutes = (minutes: number) => {
  return Duration.fromObject({ minutes: minutes }).toFormat(
    minutes >= 60
      ? minutes % 60 === 0
        ? `h '${i18n.t("hour", { count: minutes / 60 }).toLowerCase()}'`
        : `h '${i18n.t("hour_short")}' m '${i18n.t("minute_short")}'`
      : `m '${i18n.t("minutes").toLowerCase()}'`
  );
};

const DurationPicker = (props: {
  value?: number | null;
  eventType: string;
  updateValue: (value: number | null) => void;
}) => {
  const { value, eventType, updateValue } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const options = useMemo(() => {
    const durationConfig = getDurationConfigByEventType(eventType);
    return durationOptions(durationConfig.min, durationConfig.max);
  }, [eventType]);

  const [valueIndex, setValueIndex] = useState(
    options.findIndex((option) => option === value)
  );

  useEffect(
    function updateValIndex() {
      setValueIndex(options.findIndex((option) => option === value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const localizedOptions = useMemo(() => {
    return options.map((option) => `${getFormatWithMinutes(option)}`);
  }, [options]);

  return (
    <Autocomplete
      options={localizedOptions ?? []}
      id={`dropdown-${eventType}`}
      disableClearable
      noOptionsText={t("no_options")}
      blurOnSelect
      classes={{
        root: classes.rowInput,
        input: classes.autocompleteInput,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value === null ? t("select_an_option") : ""}
        />
      )}
      value={localizedOptions[valueIndex] ?? ""}
      onChange={(event: any, newValue: string | null) => {
        // Find actual value to update using the common index
        updateValue(
          options[localizedOptions.findIndex((el) => el === newValue)]
        );
      }}
      inputValue={localizedOptions[valueIndex]}
    />
  );
};

const CountPicker = (props: {
  value?: number | null;
  eventType: string;
  updateValue: (value: number | null) => void;
}) => {
  const { value, eventType, updateValue } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const options = useMemo(() => {
    const countConfig = getCountConfigByEventType(eventType);
    return countOptions(countConfig.min, countConfig.max);
  }, [eventType]);

  return (
    <Autocomplete
      options={options ?? []}
      id={`dropdown-${eventType}`}
      disableClearable
      noOptionsText={t("no_options")}
      blurOnSelect
      classes={{
        root: classes.rowInput,
        input: classes.autocompleteInput,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value === null ? t("select_an_option") : ""}
        />
      )}
      value={value !== null ? String(value) : ""}
      onChange={(event: any, newValue: string | null) => {
        // Find actual value to update using the common index
        updateValue(
          Number(options[options.findIndex((el) => el === newValue)])
        );
      }}
    />
  );
};

const TimeTriggerPicker = (props: {
  value?: number | null;
  eventType: string;
  updateValue: (value: DateTime | null) => void;
}) => {
  const { value, eventType, updateValue } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const options = useMemo(() => {
    const triggerConfig = getTriggerConfigByEventType(eventType);
    return numberRange(triggerConfig.min, triggerConfig.max, 0.5).map((val) =>
      String(val)
    );
  }, [eventType]);

  const formatFunc = (val: string) => {
    const hour = Number(val) - (Number(val) % 1);
    const minute = (Number(val) % 1) * 60;
    return DateTime.fromObject({ hour, minute }).toFormat(HourMinuteFormat());
  };
  return (
    <Autocomplete
      options={options ?? []}
      id={`dropdown-${eventType}`}
      disableClearable
      noOptionsText={t("no_options")}
      blurOnSelect
      getOptionLabel={(val) => formatFunc(val)}
      classes={{
        root: classes.timePicker,
        input: classes.autocompleteInput,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value === null ? t("select_an_option") : ""}
        />
      )}
      value={value !== null ? String(value) : ""}
      onChange={(event: any, newValue: string | number | null) => {
        // Find actual value to update using the common index
        const val = Number(options[options.findIndex((el) => el === newValue)]);
        const hour = Number(val) - (Number(val) % 1);
        const minute = (Number(val) % 1) * 60;
        updateValue(DateTime.fromObject({ hour, minute }));
      }}
    />
  );
};

const defaultFormValues = (
  eventTypeConfig: EventTypeConfig,
  eventType: string
) => {
  const hasDuration = EventsWithDurationConfig.includes(eventType);
  const hasCount = EventsWithCountConfig.includes(eventType);
  const hasTime = EventsWithTimeConfig.includes(eventType);
  const hasTrigger = EventsWithTriggerTime.includes(eventType);

  const configuration: EventTypeLevelConfig = JSON.parse(
    eventTypeConfig.configuration ?? "{}"
  );
  const initialFormState: IConfigEventForm = {
    is_enabled: !!eventTypeConfig.is_enabled,
  };

  const configLevelTwo = configuration[ConfigLevelTwo];

  if (hasDuration && configLevelTwo !== undefined) {
    initialFormState.duration =
      configLevelTwo.duration !== undefined ? configLevelTwo.duration : null;
  }
  if (hasCount && configLevelTwo !== undefined) {
    initialFormState.count =
      configLevelTwo.count !== undefined ? configLevelTwo.count : null;
  }
  if (hasTime && configLevelTwo !== undefined) {
    initialFormState.all_day =
      configLevelTwo.all_day !== undefined ? configLevelTwo.all_day : false;
    initialFormState.local_time_start =
      configLevelTwo.local_time_start !== undefined
        ? DateTimeNowHourMinute(configLevelTwo.local_time_start)
        : DateTimeNowHourMinute("23:00");
    initialFormState.local_time_end =
      configLevelTwo.local_time_end !== undefined
        ? DateTimeNowHourMinute(configLevelTwo.local_time_end)
        : DateTimeNowHourMinute("05:00");
  }
  if (hasTrigger && configLevelTwo !== undefined) {
    initialFormState.local_time_end =
      configLevelTwo.local_time_end !== undefined
        ? DateTimeNowHourMinute(configLevelTwo.local_time_end)
        : DateTimeNowHourMinute("11:00");
  }

  return initialFormState;
};

export const EventConfigForm = (props: IProps) => {
  const { eventType, expanded, setExpanded } = props;

  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [eventConfig, setEventConfig] = useState<IConfigEventForm | undefined>(
    undefined
  );

  const [confirmApply, setConfirmApply] = useState(false);

  const hasDuration = EventsWithDurationConfig.includes(eventType);
  const hasCount = EventsWithCountConfig.includes(eventType);
  const hasTime = EventsWithTimeConfig.includes(eventType);
  const hasTrigger = EventsWithTriggerTime.includes(eventType);

  /* Selectors */
  const selectedEventTypeConfig = useAppSelector(
    (state) => state.settingsState.selectedEventConfig
  );

  const community = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );
  const is24HourTime = useAppSelector(
    (state) => state.appState.config.timeFormat === TimeFormat.TIME24H
  );
  const configSelectedUnits = useAppSelector(
    (state) => state.settingsState.configSelectedUnits
  );

  const { timeFormat } = timeConfigurations();

  /* Effects */
  useEffect(() => {
    if (selectedEventTypeConfig !== undefined) {
      setExpanded(false);

      const initialFormState: IConfigEventForm = defaultFormValues(
        selectedEventTypeConfig,
        eventType
      );
      setEventConfig(initialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType, selectedEventTypeConfig]);

  /* Methods */
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const handleCancel = () => {
    if (selectedEventTypeConfig !== undefined) {
      setExpanded(false);

      const initialFormState: IConfigEventForm = defaultFormValues(
        selectedEventTypeConfig,
        eventType
      );
      if (
        selectedEventTypeConfig !== undefined &&
        selectedEventTypeConfig.is_enabled !== null
      ) {
        setEventConfig(initialFormState);
      }
    }
  };

  const handleApplyToSelected = async () => {
    if (configSelectedUnits === undefined) return;
    if (configSelectedUnits.length === 0) return;

    const configUnitsData: IUnitEventConfigData = {
      eventType: eventType,
      unitIDs: configSelectedUnits,
    };

    // Add enabled
    if (eventConfig && eventConfig.is_enabled !== undefined) {
      configUnitsData.is_enabled = eventConfig.is_enabled;
    }

    // Add configuration
    const configToUpdate: IUnitEventConfigData["configuration"] = {};

    if (eventConfig) {
      // Save duration
      if (hasDuration && eventConfig.duration) {
        configToUpdate.duration = eventConfig.duration;
        configToUpdate.level = ConfigLevelTwo;
      }
      // Save count
      if (hasCount && eventConfig.count) {
        configToUpdate.count = eventConfig.count;
        configToUpdate.level = ConfigLevelTwo;
      }

      const allDay = !!eventConfig.all_day;

      // Save all day
      if (hasTime && eventConfig.all_day !== undefined) {
        configToUpdate.all_day = eventConfig.all_day;
        configToUpdate.level = ConfigLevelTwo;
      }
      // Save time start
      if (hasTime && !allDay && eventConfig.local_time_start !== undefined) {
        configToUpdate.local_time_start = convertTimeHourMinute(
          eventConfig.local_time_start,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        );
        configToUpdate.level = ConfigLevelTwo;
      }
      // Save time end
      if (hasTime && !allDay && eventConfig.local_time_end) {
        configToUpdate.local_time_end = convertTimeHourMinute(
          eventConfig.local_time_end,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        );
        configToUpdate.level = ConfigLevelTwo;
      }
      // Trigger time
      if (hasTrigger && eventConfig.local_time_end) {
        configToUpdate.local_time_end = eventConfig.local_time_end.toFormat(
          ISO_TIME_SAVE_FORMAT
        );
        configToUpdate.level = ConfigLevelTwo;
      }

      if (Object.keys(configToUpdate).length > 0) {
        configUnitsData.configuration = configToUpdate;
      }
    }

    const updateConfigResult = await dispatch(
      updateUnitConfigByEventType(configUnitsData)
    );
    if (updateUnitConfigByEventType.fulfilled.match(updateConfigResult)) {
      dispatch(
        showSoftNotification(SoftNotificationTypes.APPLIED_CONFIG_CONFIRMATION)
      );
    }
  };

  const handleSave = () => {
    if (selectedEventTypeConfig === undefined || eventConfig === undefined)
      return;

    const defaultVal = defaultFormValues(selectedEventTypeConfig, eventType);
    const configData: ICommunityConfigData = {
      eventConfigID: selectedEventTypeConfig.id,
    };

    // Add enabled
    if (
      eventConfig &&
      eventConfig.is_enabled !== undefined &&
      defaultVal.is_enabled !== eventConfig.is_enabled
    ) {
      configData.is_enabled = eventConfig.is_enabled;
    }

    // Add configuration
    const configToUpdate: ICommunityConfigData["configuration"] = {};
    if (eventConfig) {
      // Save duration
      if (
        hasDuration &&
        eventConfig.duration &&
        defaultVal.duration !== eventConfig.duration
      ) {
        configToUpdate.duration = eventConfig.duration;
        configToUpdate.level = ConfigLevelTwo;
      }
      // Save count
      if (
        hasCount &&
        eventConfig.count &&
        defaultVal.count !== eventConfig.count
      ) {
        configToUpdate.count = eventConfig.count;
        configToUpdate.level = ConfigLevelTwo;
      }

      const allDay = !!eventConfig.all_day;

      // Save all day
      if (
        hasTime &&
        eventConfig.all_day !== undefined &&
        defaultVal.all_day !== eventConfig.all_day
      ) {
        configToUpdate.all_day = eventConfig.all_day;
        configToUpdate.level = ConfigLevelTwo;
      }
      // Save time start
      if (
        hasTime &&
        !allDay &&
        eventConfig.local_time_start !== undefined &&
        defaultVal.local_time_start?.toISO() !==
          eventConfig.local_time_start?.toISO()
      ) {
        configToUpdate.local_time_start = convertTimeHourMinute(
          eventConfig.local_time_start,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        );
        configToUpdate.level = ConfigLevelTwo;
      }
      // Save time end
      if (
        hasTime &&
        !allDay &&
        eventConfig.local_time_end &&
        defaultVal.local_time_end?.toISO() !==
          eventConfig.local_time_end?.toISO()
      ) {
        configToUpdate.local_time_end = convertTimeHourMinute(
          eventConfig.local_time_end,
          ISO_TIME_SAVE_FORMAT,
          community?.time_zone
        );
        configToUpdate.level = ConfigLevelTwo;
      }
      // Trigger time
      if (
        hasTrigger &&
        eventConfig.local_time_end &&
        defaultVal.local_time_end?.toISO() !==
          eventConfig.local_time_end?.toISO()
      ) {
        configToUpdate.local_time_end = eventConfig.local_time_end.toFormat(
          ISO_TIME_SAVE_FORMAT
        );
        configToUpdate.level = ConfigLevelTwo;
      }

      if (Object.keys(configToUpdate).length > 0) {
        configData.configuration = configToUpdate;
      }
    }

    dispatch(updateCommunityEventConfig(configData));
  };

  /* Form */
  const handleEnabledChange = () => {
    setEventConfig((prevVal) => ({
      ...prevVal,
      is_enabled: !prevVal?.is_enabled,
    }));
  };
  const handlePeriodChange = (value: number | null) => {
    setEventConfig((prevVal) => {
      if (prevVal === undefined) return prevVal;
      return {
        ...prevVal,
        duration: value,
      };
    });
  };

  const handleCountChange = (value: number | null) => {
    setEventConfig((prevVal) => {
      if (prevVal === undefined) return prevVal;
      return {
        ...prevVal,
        count: value,
      };
    });
  };

  const handleAllDayChange = () => {
    setEventConfig((prevVal) => {
      if (prevVal === undefined) return prevVal;
      return {
        ...prevVal,
        all_day: !prevVal.all_day,
      };
    });
  };

  const handleStartTimeChange = (date: DateTime | null) => {
    setEventConfig((prevVal) => {
      if (prevVal === undefined) return prevVal;
      return {
        ...prevVal,
        local_time_start: date,
      };
    });
  };

  const handleEndTimeChange = (date: DateTime | null) => {
    setEventConfig((prevVal) => {
      if (prevVal === undefined) return prevVal;
      return {
        ...prevVal,
        local_time_end: date,
      };
    });
  };
  const handleTriggerEndTimeChange = (date: DateTime | null) => {
    const hour = date?.hour;
    const minHour = 7;
    const maxHour = 14;
    if (hour !== undefined && hour >= minHour && hour <= maxHour) {
      setEventConfig((prevVal) => {
        if (prevVal === undefined) return prevVal;
        return {
          ...prevVal,
          local_time_end: date,
        };
      });
    } else {
      const formatInitial = convertTimeHourMinute(
        DateTimeNow().set({ hour: minHour, minute: 0, second: 0 }),
        timeFormat
      );
      const formatfinal = convertTimeHourMinute(
        DateTimeNow().set({ hour: maxHour, minute: 0, second: 0 }),
        timeFormat
      );
      dispatch(
        showSoftNotification(
          t("trigger_time_range_validation", {
            initial: formatInitial,
            final: formatfinal,
          })
        )
      );
    }
  };

  const enableSave = (
    oldEventConfig: IConfigEventForm,
    newEventConfig: typeof eventConfig
  ) => {
    if (oldEventConfig === undefined || newEventConfig === undefined)
      return false;

    let enable = false;

    if (oldEventConfig.is_enabled !== newEventConfig.is_enabled) enable = true;
    if (
      oldEventConfig.duration !== undefined &&
      oldEventConfig.duration !== newEventConfig.duration
    )
      enable = true;
    if (
      oldEventConfig.count !== undefined &&
      oldEventConfig.count !== newEventConfig.count
    )
      enable = true;
    if (
      oldEventConfig.all_day !== undefined &&
      oldEventConfig.all_day !== newEventConfig.all_day
    )
      enable = true;

    if (
      oldEventConfig.local_time_start !== undefined &&
      oldEventConfig.local_time_start?.toISO() !==
        newEventConfig.local_time_start?.toISO()
    )
      enable = true;

    if (
      oldEventConfig.local_time_end?.toISO() !==
      newEventConfig.local_time_end?.toISO()
    )
      enable = true;

    return enable;
  };

  const enabled =
    expanded &&
    selectedEventTypeConfig !== undefined &&
    enableSave(
      defaultFormValues(selectedEventTypeConfig, eventType),
      eventConfig
    );

  const allDayStartDateTime = DateTimeNowHourMinute("00:00");
  const allDayEndDateTime = DateTimeNowHourMinute("0:00");

  // Check if at least one is selected
  const enableApply =
    configSelectedUnits !== undefined && configSelectedUnits.length > 0;

  return (
    <>
      <AccordionForm
        expand={expanded}
        toggleAccordion={toggleAccordion}
        handleCancel={handleCancel}
        handleApplyToSelected={() => setConfirmApply(true)}
        handleSave={handleSave}
        enableButtons={enabled}
        enableApply={enableApply}
      >
        <span className={classes.firstSection}>{t("template")}</span>
        <ConfirmDialog
          dialogType={DialogTypes.informative}
          toggle={confirmApply}
          title={t("apply_changes")}
          message={t("confirm_apply_changes", {
            selected: configSelectedUnits?.length ?? 0,
          })}
          possitiveText={t("apply")}
          negativeText={t("cancel")}
          positiveAction={handleApplyToSelected}
          negativeAction={() => void 0}
          onClose={() => setConfirmApply(false)}
        />
        <form className={classes.formContainer}>
          <Grid container>
            <Grid item xs={10} lg={5} className={classes.gridItem}>
              <div className={classes.columnRow}>
                <EventConfigLabel
                  expanded={expanded}
                  highlightPrimary={!!eventConfig?.is_enabled}
                  label={
                    expanded
                      ? t("notification_enabled").toUpperCase()
                      : t("notification").toUpperCase()
                  }
                  value={eventConfig?.is_enabled ? t("enabled") : t("disabled")}
                />
                {expanded && (
                  <GreenCheckbox
                    readOnly={!expanded}
                    checked={eventConfig?.is_enabled}
                    classes={{ root: classes.enabledCheckbox }}
                    tabIndex={-1}
                    disableRipple
                    onClick={handleEnabledChange}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <div className={classes.column}>
                {hasDuration && (
                  <div className={classes.columnRow}>
                    <EventConfigLabel
                      expanded={expanded}
                      label={t("period").toUpperCase()}
                      value={
                        eventConfig !== undefined && eventConfig.duration
                          ? getFormatWithMinutes(eventConfig.duration)
                          : t("select_an_option")
                      }
                    />
                    {expanded && eventConfig !== undefined && (
                      <DurationPicker
                        value={eventConfig.duration}
                        updateValue={handlePeriodChange}
                        eventType={eventType}
                      />
                    )}
                  </div>
                )}
                {hasCount && (
                  <div className={classes.columnRow}>
                    <EventConfigLabel
                      expanded={expanded}
                      label={t("count").toUpperCase()}
                      value={
                        eventConfig !== undefined && eventConfig.count
                          ? `${eventConfig.count}`
                          : t("select_an_option")
                      }
                    />
                    {expanded && eventConfig !== undefined && (
                      <CountPicker
                        value={eventConfig.count}
                        updateValue={handleCountChange}
                        eventType={eventType}
                      />
                    )}
                  </div>
                )}
                {hasTime && (
                  <>
                    <div className={classes.columnRow}>
                      <EventConfigLabel
                        expanded={expanded}
                        label={t("all_day").toUpperCase()}
                        highlightPrimary={!!eventConfig?.all_day}
                        value={
                          eventConfig?.all_day ? t("enabled") : t("disabled")
                        }
                      />
                      {expanded && eventConfig !== undefined && (
                        <GreenCheckbox
                          readOnly={!expanded}
                          checked={eventConfig?.all_day}
                          classes={{ root: classes.enabledCheckbox }}
                          tabIndex={-1}
                          disableRipple
                          onClick={handleAllDayChange}
                        />
                      )}
                    </div>
                    <div className={classes.columnRowMultiple}>
                      <div className={classes.columnRowMultipleItem}>
                        <EventConfigLabel
                          expanded={expanded}
                          highlightPrimary={
                            eventConfig?.all_day !== undefined
                              ? eventConfig.all_day
                                ? !eventConfig.all_day
                                : undefined
                              : undefined
                          }
                          label={t("start_time").toUpperCase()}
                          value={
                            eventConfig !== undefined &&
                            eventConfig.local_time_start
                              ? convertTimeHourMinute(
                                  eventConfig.all_day
                                    ? allDayStartDateTime
                                    : eventConfig.local_time_start,
                                  timeFormat
                                ) ?? ""
                              : t("select_an_option")
                          }
                        />
                        {expanded && eventConfig !== undefined && (
                          <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <TimePicker
                              ampm={is24HourTime ? false : true}
                              disabled={eventConfig.all_day}
                              value={loadTime(
                                eventConfig.all_day
                                  ? allDayStartDateTime
                                  : eventConfig.local_time_start ?? null
                              )}
                              format={timeFormat}
                              onClick={(e) => e.stopPropagation()}
                              onChange={handleStartTimeChange}
                              minutesStep={5}
                              className={classes.timePicker}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      </div>

                      <div className={classes.columnRowMultipleItem}>
                        <EventConfigLabel
                          expanded={expanded}
                          highlightPrimary={
                            eventConfig?.all_day !== undefined
                              ? eventConfig.all_day
                                ? !eventConfig.all_day
                                : undefined
                              : undefined
                          }
                          label={t("end_time").toUpperCase()}
                          value={
                            eventConfig !== undefined &&
                            eventConfig.local_time_end
                              ? convertTimeHourMinute(
                                  eventConfig.all_day
                                    ? allDayEndDateTime
                                    : eventConfig.local_time_end,
                                  timeFormat
                                ) ?? ""
                              : t("select_an_option")
                          }
                        />
                        {expanded && eventConfig !== undefined && (
                          <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <TimePicker
                              ampm={is24HourTime ? false : true}
                              disabled={eventConfig.all_day}
                              value={loadTime(
                                eventConfig.all_day
                                  ? allDayEndDateTime
                                  : eventConfig.local_time_end ?? null
                              )}
                              format={timeFormat}
                              onClick={(e) => e.stopPropagation()}
                              onChange={handleEndTimeChange}
                              minutesStep={5}
                              className={classes.timePicker}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {hasTrigger && (
                  <div className={classes.columnRow}>
                    <EventConfigLabel
                      expanded={expanded}
                      label={t("trigger_time").toUpperCase()}
                      value={
                        eventConfig !== undefined && eventConfig.local_time_end
                          ? convertTimeHourMinute(
                              eventConfig.local_time_end,
                              timeFormat
                            ) ?? ""
                          : t("select_an_option")
                      }
                    />
                    {expanded && eventConfig !== undefined && (
                      <TimeTriggerPicker
                        value={
                          eventConfig.local_time_end
                            ? eventConfig.local_time_end?.hour +
                              eventConfig.local_time_end?.minute / 60
                            : 0
                        }
                        updateValue={handleTriggerEndTimeChange}
                        eventType={eventType}
                      />
                    )}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </AccordionForm>
    </>
  );
};

interface IProps {
  eventType: string;
  expanded: boolean;
  setExpanded: (val: boolean) => void;
}
