import { DateTime } from "luxon";
import { capitalize, TimeFormat } from "./constants";
import i18n from "../i18n";
import store from "./store";

const defaultTimeZone = "America/Los_Angeles";
const time12h = "h:mm a";
const time24h = "HH:mm";
const timeZoneFormat = "ZZZZ";

const init = (timezone?: string) => {
  const communityTimezone = store.getState().headerState.selectedCommunity
    ?.time_zone;

  const showTimezones = store.getState().headerState.showTimezones;

  const locale = i18n.language;
  const configuredTimeFormat = store.getState().appState.config.timeFormat;

  const timeFormat =
    configuredTimeFormat === TimeFormat.TIME24H ? time24h : time12h;

  const timeZone = timezone ?? communityTimezone ?? defaultTimeZone;
  const sameTimeZone = DateTime.now().zoneName === timeZone;

  return {
    timeZone,
    locale,
    timeFormat,
    timeZoneFormat,
    sameTimeZone,
    configuredTimeFormat,
    showTimezones,
  };
};
/**
 * Returns an object containing time configurations and formats
 *
 * @returns A object which contains time related app-wide configurations.
 */
export const timeConfigurations = (timezone?: string) => init(timezone);

/**
 * Returns the current datetime in the community timezone
 *
 * @returns A Luxon `DateTime` object with current datetime
 *
 */
export const DateTimeNow = (timezone?: string) => {
  const { timeZone, locale } = init(timezone);

  return DateTime.now().setZone(timeZone).setLocale(locale);
};

/**
 * Returns the hour and minute datetime in the community timezone
 *
 * @param hourMinute - String in a formar HH:MM
 * @param timezone - Time zone name
 * @returns A Luxon `DateTime` object with hour and minute datetime
 *
 */
export const DateTimeNowHourMinute = (
  hourMinute: string,
  timezone?: string
) => {
  const { timeZone, locale } = init(timezone);
  const splitHourMinute = hourMinute.split(":");
  const hour = parseInt(splitHourMinute[0]);
  const minute = parseInt(splitHourMinute[1]);

  return DateTime.now()
    .setZone(timeZone)
    .setLocale(locale)
    .set({ hour: hour })
    .set({ minute: minute })
    .set({ second: 0 })
    .set({ millisecond: 0 });
};

/**
 * Returns the datetime converted to 24 hour time in the given format
 *
 * @param selectedTime - Date object
 * @param format - Format for the time string
 * @param timezone - Time zone name
 * @returns A string in the given format
 *
 */
export const convertTimeHourMinute = (
  selectedTime: DateTime | null,
  format: string,
  timezone?: string
) => {
  const { timeZone } = init(timezone);

  let stringTime = null;
  if (selectedTime !== null) {
    let convertedTime = DateTime.fromISO(selectedTime.toISOTime(), {
      zone: timeZone,
    });
    stringTime = convertedTime.toFormat(format);
  }
  return stringTime;
};

/**
 * Returns the datetime in the community timezone
 *
 * @param ISOstring - Datetime ISO string
 * @returns A Luxon `DateTime` object with datetime based on an ISO string
 *
 */
export const DateTimeISO = (ISOstring: string, timezone?: string) => {
  const { timeZone } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });

  return timeUTC.setZone(timeZone);
};

/**
 * Returns the datetime in the community timezone from a datetime object
 *
 * @param startTime - Luxon Datetime object
 * @returns A Luxon `DateTime` object with datetime based on a Luxon `DateTime`
 *
 */
export const loadTime = (startTime: DateTime | null, timezone?: string) => {
  const { timeZone, locale } = init(timezone);
  if (startTime) {
    return DateTimeISO(startTime.toISOTime());
  }

  return DateTime.now().setZone(timeZone).setLocale(locale);
};

/**
 * Returns the datetime in the community timezone using the browser locale
 *
 * @param ISOstring - Datetime ISO string
 * @returns A Luxon `DateTime` object with datetime based on an ISO string
 *
 */
export const DateTimeLocaleISO = (ISOstring: string) => {
  const { timeZone, locale } = init();
  const dateCommTimezone = DateTime.fromISO(ISOstring, {
    zone: timeZone,
  });

  return dateCommTimezone.setLocale(locale);
};

export const getPastTimestampUTC = (hours: number) => {
  const now = DateTime.now().setZone("utc");
  const pastDateTime = now.minus({ hours });
  return pastDateTime.toFormat("yyyy-MM-dd'T'HH:mm:ss");
};

/**
 * Returns a formatted shortened datetime based on an ISO string
 *
 * @param ISOstring - Datetime ISO string
 * @returns A formatted datetime string. When day is the same as `now()`, it will print a localized version of `Today` instead of month and day.
 * @example
 * Here is an example for a different timezone from the user's web agent, 12h format and current date 2021-05-19:
 * ```
 * // Prints "Feb 23, 06:48 AM PST":
 * DateTimeShortFormat('2021-02-23T14:48:14');
 * ```
 * @example
 * Here's an example using the same timezone of the user's web agent, 24h format and current date 2021-02-23:
 * ```
 * // Prints "Today, 06:48":
 * DateTimeShortFormat('2021-02-23T14:48:14');
 * ``` *
 */
export const DateTimeShortFormat = (ISOstring: string, timezone?: string) => {
  const {
    timeZone,
    locale,
    timeFormat,
    timeZoneFormat,
    sameTimeZone,
    showTimezones,
  } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const today = DateTime.now().setZone(timeZone);
  const date = timeUTC.setZone(timeZone).setLocale(locale);

  if (today.toISODate() === date.toISODate()) {
    return `${capitalize(
      date.setLocale(locale).toRelativeCalendar() || ""
    )}, ${date
      .setLocale(locale)
      .toFormat(
        `${timeFormat}  ${!sameTimeZone || showTimezones ? timeZoneFormat : ""}`
      )}`;
  } else {
    return date
      .setLocale(locale)
      .toFormat(
        `LLL dd',' ${timeFormat} ${
          !sameTimeZone || showTimezones ? timeZoneFormat : ""
        }`
      );
  }
};

/**
 * Returns a formatted shortened datetime based on an ISO string
 *
 * @param ISOstring - Datetime ISO string
 * @returns A formatted datetime string. When day is the same as `now()`, it will print a localized version of `Today` instead of month and day.
 * @example
 * Here is an example for a different timezone from the user's web agent, 12h format and current date 2021-05-19:
 * ```
 * // Prints "2021-02-23 06:48 AM PST":
 * DateTimeShortFormatYMD('2021-02-23T14:48:14');
 */
export const DateTimeShortFormatYMD = (
  ISOstring: string,
  timezone?: string
) => {
  const {
    timeZone,
    locale,
    timeFormat,
    timeZoneFormat,
    sameTimeZone,
    showTimezones,
  } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);
  return date
    .setLocale(locale)
    .toFormat(
      `yyyy-MM-dd'' ${timeFormat} ${
        !sameTimeZone || showTimezones ? timeZoneFormat : ""
      }`
    );
};

/**
 * Returns a formatted shortened datetime based on an ISO string
 *
 * @param ISOstring - Datetime ISO string
 * @returns A formatted datetime string. When day is the same as `now()`
 * @example
 * Here is an example for a different timezone from the user's web agent, 12h format and current date 2021-05-19:
 * ```
 * // Prints "06:48 AM PST":
 * DateTimeShortFormatTZ('2021-02-23T14:48:14');
 */
export const DateTimeShortFormatTZ = (ISOstring: string, timezone?: string) => {
  const {
    timeZone,
    locale,
    timeFormat,
    timeZoneFormat,
    sameTimeZone,
    showTimezones,
  } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);
  return date
    .setLocale(locale)
    .toFormat(
      `${timeFormat} ${!sameTimeZone || showTimezones ? timeZoneFormat : ""}`
    );
};

/**
 * Returns a formatted shortened datetime based on an ISO string but making no timezone comparisons
 *
 * @param ISOstring - Datetime ISO string
 * @returns A formatted datetime string. When day is the same as `now()`, it will print a localized version of `Today` instead of month and day.
 * */
export const DateTimeShortFormatNoTZ = (ISOstring: string) => {
  const { locale, timeFormat } = init();
  const timeUTC = DateTime.fromISO(ISOstring);
  const today = DateTime.now();
  const date = timeUTC.setLocale(locale);

  if (today.day === date.day) {
    return `${capitalize(
      date.setLocale(locale).toRelativeCalendar() || ""
    )}, ${date.setLocale(locale).toFormat(`${timeFormat}`)}`;
  } else {
    return date.setLocale(locale).toFormat(`LLL dd',' ${timeFormat}`);
  }
};
/*
 * Returns the month and day eg Sep 28
 * @param Luxon Date
 * @returns A formatted date string.
 */
export const DateMonthDay = (date: DateTime) => {
  const { locale } = init();
  return date.setLocale(locale).toFormat(`LLL dd`);
};

/*
 * Returns the relative date, like "today", or "yesterday" or the date
 * @param ISOstring - Datetime ISO string
 * @returns A formatted date string.
 */
export const DateRelative = (ISOstring: string) => {
  const { timeZone, locale } = init();
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const today = DateTime.now().setZone(timeZone);
  const date = timeUTC.setZone(timeZone).setLocale(locale);

  if (today.month === date.month && today.day === date.day) {
    return capitalize(date.setLocale(locale).toRelativeCalendar() || "");
  } else {
    return date.setLocale(locale).toFormat(`yyyy LLL dd`);
  }
};

/*
 * Returns the relative date, like "today" or the month and day
 * @param ISODate- Date ISO string
 * @returns A formatted date string.
 */
export const ISODateRelativeMonthDay = (ISODate: DateTime) => {
  const { timeZone, locale } = init();
  // const date = DateTime.fromISO(ISODate).setZone(timeZone).setLocale(locale);
  const today = DateTime.now().setZone(timeZone);

  if (today.month === ISODate.month && today.day === ISODate.day) {
    return capitalize(ISODate.setLocale(locale).toRelativeCalendar() || "");
  } else {
    return DateMonthDay(ISODate);
  }
};

/*
 * Returns the relative date, like "today" or the month and day or year month and day
 * @param ISOstring - Datetime ISO string
 * @returns A formatted date string.
 */
export const DateTimeRelativeFormat = (
  ISOstring: string,
  yearFormat: boolean = false,
  timezone?: string
) => {
  const { locale } = init(timezone);
  const date = DateTimeISO(ISOstring);

  const today = DateTimeNow();

  if (yearFormat) {
    return date.toLocaleString(DateTime.DATE_MED);
  } else {
    if (today.month === date.month && today.day === date.day) {
      return capitalize(date.setLocale(locale).toRelativeCalendar() || "");
    } else {
      return DateMonthDay(date);
    }
  }
};

/*
 * Returns the relative date, like "today", "yesterday", or month day year format
 * @param ISOstring - Datetime ISO string
 * @returns A formatted date string.
 */
export const DateTimeRelativeYearFormat = (
  ISOstring: string,
  timezone?: string
) => {
  const { locale } = init(timezone);
  const date = DateTimeISO(ISOstring);

  const today = DateTimeNow();
  const yesterday = today.minus({ days: 1 });

  if (today.month === date.month && today.day === date.day) {
    return capitalize(date.setLocale(locale).toRelativeCalendar() || "");
  } else if (yesterday.month === date.month && yesterday.day === date.day) {
    return capitalize(date.setLocale(locale).toRelativeCalendar() || "");
  } else {
    return date.setLocale(locale).toFormat(`LLL dd, yyyy`);
  }
};

/*
 * Returns the time difference since the string in hours or days
 * @param ISOstring - Datetime ISO string
 * @returns A formatted date string.
 */
export const DateTimeDiffHoursDays = (ISOstring: string) => {
  const date = DateTimeISO(ISOstring);
  const today = DateTimeNow();

  // Calculate the time difference in hours and days
  const diffInHours = Math.floor(today.diff(date, "hours").hours);
  const diffInDays = Math.floor(today.diff(date, "days").days);

  let timeDifference = "";
  if (diffInHours < 24) {
    timeDifference = `(${diffInHours}h)`;
  } else {
    timeDifference = `(${diffInDays}d)`;
  }
  return timeDifference;
};

export const DateRelativeFormat = (
  ISOstring: string,
  yearFormat: boolean = false,
  timezone?: string
) => {
  const { timeZone, locale } = init(timezone);
  const date = DateTime.fromISO(ISOstring, { zone: timeZone });
  const today = DateTimeNow();

  if (yearFormat) {
    return date.toLocaleString(DateTime.DATE_MED);
  } else {
    if (today.month === date.month && today.day === date.day) {
      return capitalize(date.setLocale(locale).toRelativeCalendar() || "");
    } else {
      return DateMonthDay(date);
    }
  }
};

/**
 * Returns a formatted shortened datetime based on an ISO string
 *
 * @param ISOstring - Datetime ISO string
 * @returns A formatted datetime string. It will NOT print a localized version of `Today` instead of month and day.
 */
export const DateTimeShortFormatAlt = (
  ISOstring: string,
  timezone?: string
) => {
  const {
    timeZone,
    locale,
    timeFormat,
    timeZoneFormat,
    sameTimeZone,
    showTimezones,
  } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);
  return date
    .setLocale(locale)
    .toFormat(
      `LLL dd',' ${timeFormat} ${
        !sameTimeZone || showTimezones ? timeZoneFormat : ""
      }`
    );
};

/**
 * Returns a formatted lengthened datetime based on an ISO string
 *
 * @param ISOstring - Datetime ISO string
 * @returns A formatted datetime string which includes year, month, day and time according to meridian and timezone configuration.
 * @example
 * Here is an example for a different timezone from the user's web agent, 24h format:
 * ```
 * // Prints "2021 May 12, 11:03 PDT" when timezone is and current date is one or more days ahead of current date:
 * DateTimeShortFormat('2021-05-12T14:43:51');
 * ```
 */
export const DateTimeLongFormat = (ISOstring: string, timezone?: string) => {
  const { timeZone, locale, timeFormat, sameTimeZone, showTimezones } = init(
    timezone
  );
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);

  return date
    .setLocale(locale)
    .toFormat(
      `yyyy LLL dd',' ${timeFormat}  ${
        !sameTimeZone || showTimezones ? timeZoneFormat : ""
      }`
    );
};

/**
 * Returns a formatted hour without timezone in local time according to user's configurations
 *
 * @param ISOstring - Datetime ISO string
 * @returns A string with hour format e.g.,`11:00`.
 *
 */
export const TimeShortFormatNoTZ = (ISOstring: string) => {
  const { locale, timeFormat } = init();
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setLocale(locale);
  return date.toFormat(timeFormat);
};

// Formats an hour in local time according to user's configurations
export const TimeShortFormat = (ISOstring: string, timezone?: string) => {
  const { locale, timeFormat, timeZone, sameTimeZone, showTimezones } = init(
    timezone
  );
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);

  return date.toFormat(
    `${timeFormat} ${!sameTimeZone || showTimezones ? timeZoneFormat : ""}`
  );
};

// Formats an hour in local time according to user's configurations
export const TimeShortFormatWithoutTZ = (
  ISOstring: string,
  timezone?: string
) => {
  const { locale, timeFormat, timeZone } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);

  return date.toFormat(`${timeFormat}`);
};

// Based on the date, this method returns a timezone string if the timezone of the date is different from the machine, otherwise null
export const OptionalTimeZoneString = (
  ISOstring: string,
  timezone?: string
) => {
  const { locale, timeZone, sameTimeZone } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);

  return sameTimeZone ? null : date.toFormat(timeZoneFormat);
};

export const DateFromIsoString = (ISOstring: string, timezone?: string) => {
  const { locale, timeZone } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  return timeUTC.setZone(timeZone).setLocale(locale);
};

export const DateTimeFromMillis = (millis: number, timezone?: string) => {
  const { locale, timeZone } = init(timezone);
  const timeUTC = DateTime.fromMillis(millis, {
    zone: "utc",
  });
  return timeUTC.setZone(timeZone).setLocale(locale);
};

/**
 * Returns a string with just the hour formats
 *
 * @returns If configured time format is 24h, returns a string to format hours as `14:00`, otherwise formats hours as `2 PM`
 *
 */
export const HourFormat = () => {
  const { configuredTimeFormat } = init();
  return configuredTimeFormat === TimeFormat.TIME24H ? "HH:00" : "h a";
};
/**
 * Returns a string hour/minute format , according to user config
 *
 * @returns If configured time format is 24h, returns a string to format hours as `14:00`, otherwise formats hours as `2 PM`
 *
 */
export const HourMinuteFormat = () => {
  const { timeFormat } = init();
  return timeFormat;
};

/**
 * Returns a formatted datetime with day based on an ISO string
 *
 * @param ISOstring - Datetime ISO string
 * @returns A formatted datetime string. Prints day of the and then day, month and year.
 */
export const DateTimeShortFormatDay = (
  ISOstring: string,
  timezone?: string
) => {
  const {
    timeZone,
    locale,
    timeFormat,
    timeZoneFormat,
    sameTimeZone,
    showTimezones,
  } = init(timezone);
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  const date = timeUTC.setZone(timeZone).setLocale(locale);

  return date
    .setLocale(locale)
    .toFormat(
      `ccc, d LLL y ${timeFormat} ${
        !sameTimeZone || showTimezones ? timeZoneFormat : ""
      }`
    );
};

export const ShortDayFormatNoTZ = (ISOstring: string) => {
  const { locale } = init();
  const timeUTC = DateTime.fromISO(ISOstring);
  const date = timeUTC.setLocale(locale);

  return date.setLocale(locale).toFormat(`EEE dd`);
};

/**
 * Returns a list of days localized to the given locale
 *
 * @param localeName - The name of the locale
 * @returns A list of localized days of the week
 */
export function daysForLocale(localeName = "en-US", isAbbreviated = false) {
  let weekday = isAbbreviated ? ("short" as const) : ("long" as const);
  let now = new Date();
  const format = new Intl.DateTimeFormat(localeName, { weekday }).format;
  let result = [...Array(7).keys()].map((day) =>
    format(new Date().getTime() - (now.getDay() - day) * 86400000)
  );
  var day = result.splice(0, 1);
  result = result.concat(day);
  return result;
}

/**
 * Returns a string formated with date, time and timezone
 * the format of time depends on user preferences
 *
 * @param dateObj - The name of the locale
 * @returns A formatted date and time string
 */
export const DateTimeLong = (dateObj: DateTime) => {
  const { timeFormat, timeZoneFormat } = init();

  return `${dateObj.toLocaleString(DateTime.DATE_SHORT)}, ${dateObj.toFormat(
    `${timeFormat} ${timeZoneFormat}`
  )}`;
};

/**
 * Returns a luxon DateTime object from an ISO string in UTC timezone
 *
 * @param ISOstring - ISO string in UTC timezone
 * @param customTimeZone - A custom timezone for the DateTime object, if there is not any
 * a community timezone is set for the object
 * @returns A formatted date and time string
 */
export const DateFromISOStringUTC = (
  ISOstring: string,
  customTimeZone?: string | null
) => {
  const { locale, timeZone: commTimeZone } = init();
  const timeUTC = DateTime.fromISO(ISOstring, {
    zone: "utc",
  });
  return timeUTC.setZone(customTimeZone ?? commTimeZone).setLocale(locale);
};

export const timeSince = (timeCreated: string) => {
  const hasTimezone = timeCreated.includes("Z") || timeCreated.includes("+");
  const createdTime = new Date(
    hasTimezone ? timeCreated : `${timeCreated}Z`
  ).getTime();
  const now = new Date().getTime();
  const diffInMinutes = Math.floor((now - createdTime) / 60000);

  if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  } else if (diffInMinutes < 2880) {
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours}h${minutes > 0 ? minutes + "m" : ""}`;
  } else {
    const diffInDays = Math.floor(diffInMinutes / (60 * 24));
    return `${diffInDays}d`;
  }
};
