import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import { TableFields } from "./helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unassignedNotification: {
      color: theme.palette.error.contrastText,
    },
    cellClass: {
      display: "flex",
      alignItems: "center",
    },
    iconContainer: {
      position: "relative",
      display: "inline-block",
      paddingRight: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    eventIcon: {
      height: "30px",
    },
    warningOverlay: {
      position: "absolute",
      left: "15px",
      height: "20px",
      top: "-5px",
    },
  })
);

export const CustomTableCell = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(1, 1),
  },
}))(TableCell);

export const NotificationCell = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isEventType = props.fieldType === "notification";
  const isResponder = props.fieldType === "responder";
  return (
    <CustomTableCell className={clsx(isResponder && props.className)}>
      {isEventType ? (
        <div className={classes.cellClass}>
          {props.icon !== "" && (
            <div className={classes.iconContainer}>
              <img
                src={props.icon}
                alt={t("alt_text_event_type_icon", {
                  event_type: isEventType,
                })}
                draggable={false}
                className={classes.eventIcon}
              />
              {props.issuesIcon !== "" && (
                <img
                  src={props.issuesIcon}
                  alt={t("system_issues")}
                  draggable={false}
                  className={classes.warningOverlay}
                />
              )}
            </div>
          )}
          {props.children}
        </div>
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </CustomTableCell>
  );
};

interface IProps {
  fieldType: TableFields;
  icon: string;
  issuesIcon: string;
  className: string;
  children: React.ReactNode;
}
